import isNil from 'lodash/isNil';

import { OBSERVABLE_ENTITY_TYPES, TAggregate, TObservableEntity } from '@pt-cybsi/api-interfaces';

export const getUniqObservableEntityId = (entity: TObservableEntity): string => {
    const uuid = entity?.uuid;
    const keyValue = entity?.keys[0]?.value;

    return uuid || keyValue;
};

export const isObservableEntity = (entity: unknown): entity is TObservableEntity =>
    !isNil(entity) && OBSERVABLE_ENTITY_TYPES.includes((entity as TObservableEntity).type);

export const isAggregate = (entity: unknown): entity is TAggregate =>
    isObservableEntity(entity) && !isNil((entity as TAggregate).sections);
