import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { createControlValidator, IFormViewModelBuilder, TFormViewModelProps } from '@pt-cybsi/shared';

import {
    validateUserEmailLength,
    validateUserLoginPattern,
    validateUserLoginLength,
    validateUserNameLength
} from '../../validators';

import { TUserFormConfig, TUserFormData, UserFormConfig, UserFormViewModel } from './user-form.view-model';

@Injectable()
export class UserFormBuilder implements IFormViewModelBuilder<TUserFormData, TUserFormConfig> {
    formConfig = UserFormConfig;

    constructor(private fb: UntypedFormBuilder) {}

    build(props: TFormViewModelProps<TUserFormData>): UserFormViewModel {
        const form = this.buildFormGroup();

        return new UserFormViewModel(props, form, this.formConfig);
    }

    private buildFormGroup(): UntypedFormGroup {
        const { id, eTag, serverId, login, fullName, email, password, accessLevel, roles } = this.formConfig;

        const required = Validators.required;

        return this.fb.group({
            [id.controlName]: undefined,
            [serverId.controlName]: undefined,
            [eTag.controlName]: undefined,

            [login.controlName]: [
                undefined,
                {
                    validators: [
                        required,
                        createControlValidator(validateUserLoginLength, login.errorNames.length),
                        createControlValidator(validateUserLoginPattern, login.errorNames.pattern)
                    ]
                }
            ],
            [fullName.controlName]: [
                undefined,
                {
                    validators: [createControlValidator(validateUserNameLength, fullName.errorNames.length)]
                }
            ],
            [email.controlName]: [
                undefined,
                {
                    validators: [createControlValidator(validateUserEmailLength, email.errorNames.length)]
                }
            ],
            [password.controlName]: [
                undefined,
                {
                    validators: []
                }
            ],
            [accessLevel.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ],
            [roles.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ]
        });
    }
}
