import { DateTime } from 'luxon';

import { ILicense, LicenseStatus } from '@pt-cybsi/api-interfaces';

import { LicenseViewState } from '../types';

export const getLicenseState = (license: ILicense): LicenseViewState => {
    const { status, expiresAt } = license;

    if (status.includes(LicenseStatus.Inactive)) {
        return LicenseViewState.Inactive;
    }

    if (status.includes(LicenseStatus.Expired)) {
        return LicenseViewState.Expired;
    }

    if (getDaysToExpiredLicense(DateTime.fromISO(expiresAt)) < MAX_DAYS_FOR_EXPIRES) {
        return LicenseViewState.Expires;
    }

    return LicenseViewState.Active;
};

export const getDaysToExpiredLicense = (expiresAt: DateTime) => Math.floor(expiresAt.diffNow('days').days);

const MAX_DAYS_FOR_EXPIRES = 30;
