export enum ObservableEntityType {
    DomainName = 'DomainName',
    IPAddress = 'IPAddress',
    EmailAddress = 'EmailAddress',
    URL = 'URL',
    PhoneNumber = 'PhoneNumber',
    Identity = 'Identity',
    File = 'File'
}

export type TDNSWhoisObservableEntityType = ObservableEntityType.DomainName | ObservableEntityType.IPAddress;

export const OBSERVABLE_ENTITY_TYPES = Object.keys(ObservableEntityType).map<ObservableEntityType>(
    (type) => ObservableEntityType[type]
);
