import { Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import isNil from 'lodash/isNil';

import { ObservableEntityType, TBaseEntityKeyType } from '@pt-cybsi/api-interfaces';
import { TimePeriodService, TimePeriodType } from '@pt-cybsi/shared';

import { ExternalDBFormViewModel } from '../../forms';
import { MAX_TASK_ATTEMPTS_COUNT, MAX_TASK_EXECUTION_TIMEOUT } from '../../validators';

@Component({
    selector: 'external-db-form',
    templateUrl: './external-db-form.component.html',
    styleUrls: ['./external-db-form.component.scss'],
    host: {
        class: 'mc-form-vertical'
    }
})
export class ExternalDBFormComponent implements OnInit {
    @Input() form: ExternalDBFormViewModel;

    periodTaskExecutionTimeout: TimePeriodType;

    webPageURLEntityType: ObservableEntityType = ObservableEntityType.URL;
    webPageURLKeyType: TBaseEntityKeyType = 'String';
    availableEntityTypes = [
        ObservableEntityType.DomainName,
        ObservableEntityType.IPAddress,
        ObservableEntityType.EmailAddress,
        ObservableEntityType.URL,
        ObservableEntityType.PhoneNumber,
        ObservableEntityType.File,
        ObservableEntityType.Identity
    ];

    invalidTaskAttemptsCountMessage: string = this.translocoService.translate(
        'enrichment.Enrichment.Validation.Text.InvalidTaskExecutionAttemptsCount',
        {
            maxCount: MAX_TASK_ATTEMPTS_COUNT
        }
    );

    private invalidTaskExecutionTimeoutTranslateKeys = {
        [TimePeriodType.Seconds]: 'enrichment.Enrichment.Validation.Text.InvalidTaskExecutionSecondsTimeout',
        [TimePeriodType.Minutes]: 'enrichment.Enrichment.Validation.Text.InvalidTaskExecutionMinutesTimeout',
        [TimePeriodType.Hours]: 'enrichment.Enrichment.Validation.Text.InvalidTaskExecutionHoursTimeout'
    };

    constructor(private translocoService: TranslocoService, private timePeriodService: TimePeriodService) {}

    ngOnInit(): void {
        const taskExecutionTimeout = this.form.currentData.taskExecutionTimeout;

        if (isNil(taskExecutionTimeout)) {
            this.periodTaskExecutionTimeout = TimePeriodType.Minutes;
        } else {
            const period = this.timePeriodService.toMaxEntirePeriod(taskExecutionTimeout, [
                TimePeriodType.Seconds,
                TimePeriodType.Minutes,
                TimePeriodType.Hours
            ]);

            this.periodTaskExecutionTimeout = period?.type;
        }
    }

    handleChangePeriodTaskExecutionTimeout(periodType: TimePeriodType): void {
        this.periodTaskExecutionTimeout = periodType;
    }

    getInvalidTaskExecutionTimeoutMessage(periodType: TimePeriodType): string {
        const currentMaxPeriod = this.timePeriodService.toPeriod(MAX_TASK_EXECUTION_TIMEOUT, periodType, [
            TimePeriodType.Seconds,
            TimePeriodType.Minutes,
            TimePeriodType.Hours
        ]);

        const translateKey = this.invalidTaskExecutionTimeoutTranslateKeys[currentMaxPeriod.type];

        return this.translocoService.translate(translateKey, { maxTimeout: currentMaxPeriod.value });
    }
}
