import { ArtifactType, EnrichmentType, ObservableEntityType } from '@pt-cybsi/api-interfaces';
import { buildFormConfiguration, FormViewModel } from '@pt-cybsi/shared';

import { TEnrichmentRuleFormData } from '../../mappers';

/**
 * @ViewModel EnrichmentRuleCreationForm
 *
 * @description
 * Model of Angular Reactive Form with additional features for a enrichment rule creation and edit form.
 * Contains extended information about a form state, a working mode, and a saving error.
 * Also contains available values of entity types and artifact types fields.
 *
 * For creation FormViewModel need to create a factory `EnrichmentRuleFormBuilder`
 */
export class EnrichmentRuleFormViewModel extends FormViewModel<TEnrichmentRuleFormData, TEnrichmentRuleFormConfig> {
    availableEnrichmentTypes: EnrichmentType[] = [];
    availableArtifactTypes: ArtifactType[] = [];
    availableEntityTypes: ObservableEntityType[] = [];

    updateAvailableEnrichmentTypes(types: EnrichmentType[]): void {
        this.availableEnrichmentTypes = [...types];
    }

    updateAvailableArtifactTypes(types: ArtifactType[]): void {
        this.availableArtifactTypes = [...types];
    }

    updateAvailableEntityTypes(types: ObservableEntityType[]): void {
        this.availableEntityTypes = [...types];
    }
}

const FormConfig = {
    id: {
        controlName: 'id',
        errorNames: null
    },
    serverId: {
        controlName: 'serverId',
        errorNames: null
    },
    eTag: {
        controlName: 'eTag',
        errorNames: null
    },
    enrichment: {
        controlName: 'enrichment',
        errorNames: {
            required: 'required'
        }
    },
    triggers: {
        controlName: 'triggers',
        errorNames: {
            required: 'required'
        }
    },
    name: {
        controlName: 'name',
        errorNames: {
            required: 'required',
            length: 'length'
        }
    },
    isDisabled: {
        controlName: 'isDisabled',
        errorNames: null
    },
    isBuiltin: {
        controlName: 'isBuiltin',
        errorNames: null
    },
    artifactTypes: {
        controlName: 'artifactTypes',
        errorNames: {
            required: 'required'
        }
    },
    entityTypes: {
        controlName: 'entityTypes',
        errorNames: {
            required: 'required'
        }
    },
    sources: {
        controlName: 'sources',
        errorNames: {
            required: 'required'
        }
    },
    triggerSources: {
        controlName: 'triggerSources',
        errorNames: null
    },
    throttlingInterval: {
        controlName: 'throttlingInterval',
        errorNames: {
            required: 'required',
            min: 'min'
        }
    }
} as const;

export type TEnrichmentRuleFormConfig = typeof FormConfig;

export const EnrichmentRuleFormConfig = buildFormConfiguration<TEnrichmentRuleFormData, TEnrichmentRuleFormConfig>(
    FormConfig
);
