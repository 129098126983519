import uniqueId from 'lodash/uniqueId';

import { Model } from '@pt-cybsi/shared';

interface ISourceTypeModelProps {
    serverId: string;
    eTag: string;
    name: string;
    shortName: string;
    confidence: number;
}

export class SourceTypeModel extends Model<ISourceTypeModelProps> {
    get isNew(): boolean {
        return this.props.serverId === null;
    }

    constructor(props: ISourceTypeModelProps, uid?: string) {
        super(props, uid);
    }

    static create(props: ISourceTypeModelProps, uid?: string): SourceTypeModel {
        return new SourceTypeModel({ ...props }, uid);
    }

    static createNew(): SourceTypeModel {
        return new SourceTypeModel({
            serverId: null,
            eTag: null,
            name: null,
            shortName: null,
            confidence: null
        });
    }

    diff(comparedModel: SourceTypeModel): SourceTypeModel {
        const changedProps = this.getChangedProps(comparedModel, ['serverId', 'eTag']);

        return SourceTypeModel.create(changedProps, this._uid);
    }

    protected generateId(): string {
        return uniqueId('source-type-');
    }
}
