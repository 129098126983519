import { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type Opaque<T, K> = T & { __opaque__: K };

// prettier-ignore
export type Unpacked<T> =
    T extends (infer U)[] ? U :
    T extends (...args: unknown[]) => infer U ? U :
    T extends Promise<infer U> ? U :
    T extends Observable<infer U> ? U :
    T;
