import { Observable } from 'rxjs';

import { IServerError } from '@pt-cybsi/api-interfaces';

import { FormState, TBaseModel } from '../models';
import { FormMode } from '../types';

export interface IFormRepository<FormData extends TBaseModel> {
    loadData(serverId: string): Observable<FormData>;

    add(mode: FormMode, data: FormData): void;
    delete(id: string): void;

    getState(id: string): FormState;
    updateState(id: string, state: FormState): void;

    getInitialData(id: string): FormData;
    updateInitialData(data: FormData): void;

    getCurrentData(id: string): FormData;
    updateCurrentData(data: FormData): void;

    updateSavingError(id: string, error: IServerError): void;
    resetSavingError(id: string): void;

    isTouched(id: string): boolean;

    disable(id: string, fields?: (keyof FormData['props'])[]): void;

    enable(id: string, fields?: (keyof FormData['props'])[]): void;

    showControls(id: string, names: (keyof FormData['props'])[]): void;

    hideControls(id: string, names: (keyof FormData['props'])[]): void;

    validate(id: string): Observable<boolean>;

    save(data: FormData): Observable<string>;
}
