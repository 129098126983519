import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { McModalService, ModalSize } from '@ptsecurity/mosaic/modal';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class ConfirmModalService {
    constructor(private translocoService: TranslocoService, private modalService: McModalService) {}

    confirmLeaveForm(textContent?: string): Observable<boolean> {
        let confirm = false;

        return this.modalService
            .create({
                mcTitle: this.translocoService.translate('common.Common.ConfirmLeavingForm.Title.LeaveForm'),
                mcContent: textContent
                    ? textContent
                    : this.translocoService.translate('common.Common.ConfirmLeavingForm.Text.LeaveForm'),
                mcOkText: this.translocoService.translate('common.Common.ConfirmLeavingForm.Button.Confirm'),
                mcOnOk: () => (confirm = true),
                mcCancelText: this.translocoService.translate('common.Common.ConfirmLeavingForm.Button.Cancel'),
                mcClosable: false,
                mcSize: ModalSize.Small
            })
            .afterClose.pipe(
                map(() => confirm),
                take(1)
            );
    }
}
