import { ApiResource, PermissionType } from '@pt-cybsi/api-interfaces';
import { buildPermissionString, RootPermissionsService } from '@pt-cybsi/domain-core/account';

const Read = PermissionType.Read;
const Write = PermissionType.Write;

export class SourcesPermissionsService {
    static readonly viewListPermissions = RootPermissionsService.viewSourcesPermissions;

    static readonly viewTypePermissions = [buildPermissionString(ApiResource.DataSources, [Read])];

    static readonly viewPermissions = [buildPermissionString(ApiResource.DataSources, [Read])];

    static readonly editTypePermissions = [buildPermissionString(ApiResource.DataSources, [Read, Write])];

    static readonly createPermissions = [buildPermissionString(ApiResource.DataSources, [Read, Write])];

    static readonly editPermissions = [buildPermissionString(ApiResource.DataSources, [Read, Write])];

    static readonly viewUsersPermissions = [buildPermissionString(ApiResource.Users, [Read])];
}
