<input
    #mcInput
    mcInput
    type="text"
    autocomplete="off"
    [ngModel]="value"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [required]="required"
    (focus)="handleInputFocus()"
    (blur)="handleInputBlur()"
    (ngModelChange)="handleInputChange($event)" />
