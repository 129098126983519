import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { McDividerModule } from '@ptsecurity/mosaic/divider';
import { McDropdownModule } from '@ptsecurity/mosaic/dropdown';
import { McNavbarModule } from '@ptsecurity/mosaic/navbar';

import { CoreModule } from '@pt-cybsi/core';
import {
    DomainCoreAccountModule,
    PermissionGuard,
    SessionExpiredRedirectPreventersStore
} from '@pt-cybsi/domain-core/account';
import { DomainCoreLicenseModule } from '@pt-cybsi/domain-core/license';
import { DomainCoreStatisticsModule } from '@pt-cybsi/domain-core/statistics';
import { DomainCoreUsersModule } from '@pt-cybsi/domain-core/users';
import { FeatureErrorModule } from '@pt-cybsi/feature/error';
import { FeatureSystemModule } from '@pt-cybsi/feature/system';
import { I18nModule } from '@pt-cybsi/i18n';
import { LegacyApplicationModule } from '@pt-cybsi/legacy-application';
import { SharedModule } from '@pt-cybsi/shared';
import { StoreClientConfigModule } from '@pt-cybsi/store/client-config';
import { StoreRouterModule } from '@pt-cybsi/store/router';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './containers';
import { LeaveDirtyLegacyPageGuard } from './guards';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        StoreModule.forRoot(
            {},
            {
                metaReducers: !environment.production ? [] : [],
                runtimeChecks: {
                    strictActionImmutability: true,
                    strictStateImmutability: true
                }
            }
        ),
        EffectsModule.forRoot([]),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        McNavbarModule,
        McDropdownModule,
        McDividerModule,

        AppRoutingModule,
        CoreModule,
        SharedModule,
        I18nModule.forRoot({ prodMode: environment.production }, environment.BUILD_KEY), // eslint-disable-line  @typescript-eslint/no-unsafe-argument
        StoreRouterModule,
        StoreClientConfigModule,
        LegacyApplicationModule,
        DomainCoreLicenseModule,
        DomainCoreAccountModule,
        FeatureErrorModule,
        FeatureSystemModule,
        DomainCoreUsersModule,
        DomainCoreStatisticsModule
    ],
    declarations: [AppComponent, NavBarComponent],
    providers: [SessionExpiredRedirectPreventersStore, PermissionGuard, LeaveDirtyLegacyPageGuard],
    bootstrap: [AppComponent]
})
export class AppModule {}
