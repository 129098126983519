import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component,
    Input,
    OnChanges,
    DoCheck,
    OnDestroy,
    Optional,
    Self,
    ViewChild,
    forwardRef,
    Directive
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import {
    CanDisable,
    CanDisableCtor,
    CanUpdateErrorState,
    CanUpdateErrorStateCtor,
    ErrorStateMatcher,
    mixinDisabled,
    mixinErrorState
} from '@ptsecurity/mosaic/core';
import { McFormFieldControl, McValidateDirective } from '@ptsecurity/mosaic/form-field';
import { McSelect } from '@ptsecurity/mosaic/select';
import { BehaviorSubject, Observable } from 'rxjs';

import { SHARE_LEVELS, ShareLevel } from '@pt-cybsi/api-interfaces';

import { McFieldBase } from '../../helpers';

let nextUniqueId = 0;

const ShareLevelSelectMixinBase: CanDisableCtor & CanUpdateErrorStateCtor & typeof McFieldBase = mixinDisabled(
    mixinErrorState(McFieldBase)
);

@Component({
    selector: 'share-level-select',
    templateUrl: './share-level-select.component.html',
    providers: [
        {
            provide: McFormFieldControl,
            useExisting: forwardRef(() => ShareLevelSelectComponent)
        }
    ]
})
export class ShareLevelSelectComponent
    extends ShareLevelSelectMixinBase
    implements
        McFormFieldControl<ShareLevel>,
        ControlValueAccessor,
        OnChanges,
        DoCheck,
        OnDestroy,
        CanDisable,
        CanUpdateErrorState
{
    @Input()
    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value || this.uid;
        this.stateChanges.next();
    }

    @Input()
    get value(): ShareLevel {
        return this._value;
    }

    set value(value: ShareLevel) {
        if (value !== this.value) {
            this._value = value;
            this.stateChanges.next();
        }
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);

        if (this.focused) {
            this.focused = false;
            this.stateChanges.next();
        }
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);

        this.stateChanges.next();
    }

    @Input()
    get focused(): boolean {
        return this.mcSelect ? this.mcSelect.focused : this._focused;
    }

    set focused(value: boolean) {
        this._focused = coerceBooleanProperty(value);

        if (this.mcSelect) {
            this.mcSelect.focused = this._focused;
        }

        this.stateChanges.next();
    }

    @Input() placeholder: string;

    get empty(): boolean {
        return this._value === null || this._value === undefined;
    }

    get levels$(): Observable<ShareLevel[]> {
        return this.availableLevels$;
    }

    errorState: boolean;
    controlType = 'share-level-select';

    onChange: (value: ShareLevel) => void;
    onTouched: () => void;

    @ViewChild('mcSelect') mcSelect: McSelect;

    private _id: string;
    private _value: ShareLevel;
    private _disabled = false;
    private _focused = false;
    private _required = false;

    private availableLevels$: BehaviorSubject<ShareLevel[]> = new BehaviorSubject(SHARE_LEVELS);

    private readonly uid = `share-level-select-${nextUniqueId++}`;

    constructor(
        public defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() public parentForm: NgForm,
        @Optional() public parentFormGroup: FormGroupDirective,
        @Self() public ngControl: NgControl
    ) {
        super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

        this.ngControl.valueAccessor = this;

        // eslint-disable-next-line no-self-assign
        this.id = this.id;
    }

    ngOnChanges() {
        this.stateChanges.next();
    }

    ngDoCheck() {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }

    handleChangeValue(value: ShareLevel) {
        this.value = value;

        this.onChange(value);
    }

    updateAvailableLevels(levels: ShareLevel[]): void {
        this.availableLevels$.next(levels);

        this.normalizeValue();
    }

    onContainerClick(): void {
        this.focus();
    }

    focus(): void {
        if (!this.disabled) {
            this.focused = true;

            this.mcSelect.focus();
        }
    }

    writeValue(value: ShareLevel): void {
        this.value = value;

        this.normalizeValue();
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private normalizeValue(): void {
        const levels = this.availableLevels$.value;
        const isAvailableShareLevel = levels.indexOf(this.value) !== -1;

        if (this.value && !isAvailableShareLevel) {
            const maxAvailableShareLevel = levels[levels.length - 1];

            this.handleChangeValue(maxAvailableShareLevel);
        }
    }
}

@Directive({
    selector: 'share-level-select',
    exportAs: 'ShareLevelSelectMcValidate'
})
export class ShareLevelSelectMcValidateDirective extends McValidateDirective {}
