import { TViewModelProps } from '@pt-cybsi/shared';

export type TDataSourceView = TViewModelProps<IDataSourceInstanceView | IDataSourceFullView>;
export interface IDataSourceTypeView extends IDataSourceTypeParams {
    format: DataSourceViewFormat.Type;
}

export interface IDataSourceInstanceView extends IDataSourceInstanceParams {
    format: DataSourceViewFormat.Instance;
}

export interface IDataSourceFullView {
    format: DataSourceViewFormat.Full;
    type: IDataSourceTypeParams;
    instance: IDataSourceInstanceParams;
}

export enum DataSourceViewFormat {
    Type = 'Type',
    Instance = 'Instance',
    Full = 'Full'
}

interface IDataSourceTypeParams {
    id: string;
    name: string;
    confidence: number;
}

interface IDataSourceInstanceParams {
    id: string;
    name: string;
    confidence: number;
}
