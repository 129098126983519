export enum Role {
    Administrator = 'Administrator',
    UserAdministrator = 'UserAdministrator',
    ConfigReader = 'ConfigReader',
    FeedAdministrator = 'FeedAdministrator',
    FeedDataReader = 'FeedDataReader',
    EnrichmentRunner = 'EnrichmentRunner',
    EnrichmentTaskReader = 'EnrichmentTaskReader',
    ReportRegistrant = 'ReportRegistrant',
    ReportReader = 'ReportReader',
    EntityRegistrant = 'EntityRegistrant',
    EntityReader = 'EntityReader',
    ArtifactReader = 'ArtifactReader',
    ArtifactRegistrant = 'ArtifactRegistrant',
    ArtifactContentReader = 'ArtifactContentReader',
    Searcher = 'Searcher',
    DictionaryReader = 'DictionaryReader',
    DictionaryRegistrant = 'DictionaryRegistrant'
}

export const ROLES = Object.keys(Role).map((role: string): string => Role[role] as string);

export enum ApiResource {
    EnrichmentConfig = 'EnrichmentConfig',
    DataSources = 'DataSources',
    Feeds = 'Feeds',
    SearchFilters = 'SearchFilters',
    FeedsData = 'FeedsData',
    Search = 'Search',
    Observable = 'Observable',
    EnrichmentTasks = 'EnrichmentTasks',
    Reports = 'Reports',
    Observations = 'Observations',
    RawReports = 'RawReports',
    Annotation = 'Annotation',
    Artifacts = 'Artifacts',
    ArtifactsContent = 'ArtifactsContent',
    ReputationLists = 'ReputationLists',
    ReputationListsContent = 'ReputationListsContent',
    StoredQuery = 'StoredQuery',
    Users = 'Users',
    ApiKeys = 'APIKeys',
    Dictionaries = 'Dictionaries',
    EntityView = 'EntityView',
    License = 'License'
}

export enum UsersGroup {
    Root = 'Root',
    Administrator = 'Administrator',
    Operator = 'Operator',
    Analyst = 'Analyst',
    Guest = 'Guest'
}
export const USERS_GROUPS = Object.keys(UsersGroup).map((group: string): string => UsersGroup[group] as string);

export const GROUPS_ROLES_MAP: Record<UsersGroup, Role[]> = {
    [UsersGroup.Root]: ROLES as Role[],
    [UsersGroup.Administrator]: [
        Role.Administrator,
        Role.UserAdministrator,
        Role.FeedAdministrator,
        Role.EntityReader,
        Role.ArtifactReader,
        Role.ArtifactContentReader,
        Role.DictionaryReader,
        Role.DictionaryRegistrant
    ],
    [UsersGroup.Operator]: [
        Role.ConfigReader,
        Role.FeedDataReader,
        Role.EnrichmentRunner,
        Role.EnrichmentTaskReader,
        Role.ReportRegistrant,
        Role.ReportReader,
        Role.EntityRegistrant,
        Role.EntityReader,
        Role.ArtifactReader,
        Role.ArtifactRegistrant,
        Role.ArtifactContentReader,
        Role.Searcher,
        Role.DictionaryReader,
        Role.DictionaryRegistrant
    ],
    [UsersGroup.Analyst]: [
        Role.ConfigReader,
        Role.FeedDataReader,
        Role.EnrichmentRunner,
        Role.EnrichmentTaskReader,
        Role.ReportReader,
        Role.EntityReader,
        Role.ArtifactReader,
        Role.Searcher,
        Role.DictionaryReader,
        Role.DictionaryRegistrant
    ],
    [UsersGroup.Guest]: [
        Role.ConfigReader,
        Role.EnrichmentTaskReader,
        Role.ReportReader,
        Role.EntityReader,
        Role.ArtifactReader,
        Role.Searcher,
        Role.DictionaryReader
    ]
};

export const API_RESOURCES = Object.keys(ApiResource).map((r: string): string => ApiResource[r] as string);

export enum PermissionType {
    Read = 'r',
    Write = 'w'
}

export const PERMISSION_TYPES = Object.keys(PermissionType).map((t: string): string => PermissionType[t] as string);

const Read = PermissionType.Read;
const Write = PermissionType.Write;

export const ROLES_PERMISSIONS_MAP: Record<Role, [ApiResource, PermissionType[]][]> = {
    [Role.Administrator]: [
        [ApiResource.License, [Write]],
        [ApiResource.EnrichmentConfig, [Read, Write]],
        [ApiResource.DataSources, [Read, Write]],
        [ApiResource.Users, [Read]]
    ],
    [Role.UserAdministrator]: [
        [ApiResource.Users, [Read, Write]],
        [ApiResource.ApiKeys, [Read, Write]],
        [ApiResource.DataSources, [Read]]
    ],
    [Role.ConfigReader]: [
        [ApiResource.EnrichmentConfig, [Read]],
        [ApiResource.DataSources, [Read]]
    ],
    [Role.FeedAdministrator]: [
        [ApiResource.Feeds, [Read, Write]],
        [ApiResource.SearchFilters, [Read, Write]],
        [ApiResource.StoredQuery, [Read, Write]],
        [ApiResource.FeedsData, [Read]],
        [ApiResource.ReputationLists, [Read, Write]],
        [ApiResource.ReputationListsContent, [Read]],
        [ApiResource.Search, [Read]],
        [ApiResource.DataSources, [Read]],
        [ApiResource.Observable, [Read]],
        [ApiResource.Users, [Read]]
    ],
    [Role.FeedDataReader]: [
        [ApiResource.FeedsData, [Read]],
        [ApiResource.Feeds, [Read]],
        [ApiResource.ReputationLists, [Read]],
        [ApiResource.ReputationListsContent, [Read]],
        [ApiResource.SearchFilters, [Read]],
        [ApiResource.StoredQuery, [Read]],
        [ApiResource.DataSources, [Read]],
        [ApiResource.EntityView, [Read]],
        [ApiResource.Users, [Read]]
    ],
    [Role.EnrichmentRunner]: [
        [ApiResource.EnrichmentTasks, [Read, Write]],
        [ApiResource.DataSources, [Read]]
    ],
    [Role.EnrichmentTaskReader]: [
        [ApiResource.EnrichmentTasks, [Read]],
        [ApiResource.DataSources, [Read]]
    ],
    [Role.ReportRegistrant]: [
        [ApiResource.Reports, [Write]],
        [ApiResource.Observations, [Write]]
    ],
    [Role.ReportReader]: [
        [ApiResource.Reports, [Read]],
        [ApiResource.Observations, [Read]],
        [ApiResource.DataSources, [Read]],
        [ApiResource.RawReports, [Read]]
    ],
    [Role.EntityRegistrant]: [[ApiResource.Observable, [Write]]],
    [Role.EntityReader]: [
        [ApiResource.Observable, [Read]],
        [ApiResource.DataSources, [Read]]
    ],
    [Role.ArtifactReader]: [
        [ApiResource.Artifacts, [Read]],
        [ApiResource.DataSources, [Read]]
    ],
    [Role.ArtifactRegistrant]: [[ApiResource.Artifacts, [Write]]],
    [Role.ArtifactContentReader]: [
        [ApiResource.Artifacts, [Read]],
        [ApiResource.ArtifactsContent, [Read]]
    ],
    [Role.Searcher]: [
        [ApiResource.Observable, [Read]],
        [ApiResource.Search, [Read]],
        [ApiResource.SearchFilters, [Read, Write]],
        [ApiResource.DataSources, [Read]]
    ],
    [Role.DictionaryReader]: [[ApiResource.Dictionaries, [Read]]],
    [Role.DictionaryRegistrant]: [[ApiResource.Dictionaries, [Write]]]
};
