import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { DateAdapter } from '@ptsecurity/cdk/datetime';
import { TimeFormats } from '@ptsecurity/mosaic/timepicker';
import { FlatTreeControl, McTreeFlatDataSource, McTreeFlattener, McTreeOption } from '@ptsecurity/mosaic/tree';
import { McTreeSelect, McTreeSelectChange } from '@ptsecurity/mosaic/tree-select';
import { DateTime } from 'luxon';

import { DestroyService, selectHiddenItemsTextFormatter } from '@pt-cybsi/shared';

import { ApiKeyFormViewModel } from '../../forms';
import {
    buildPermissionsTree,
    getChildren,
    getLevel,
    getValue,
    getViewValue,
    IPermissionFlatTreeNode,
    IPermissionTreeNode,
    isExpandable,
    transformer
} from '../../helpers';

@Component({
    selector: 'api-key-form',
    templateUrl: './api-key-form.component.html',
    styleUrls: ['./api-key-form.component.scss'],
    providers: [DestroyService]
})
export class ApiKeyFormComponent implements OnInit {
    @Input() form: ApiKeyFormViewModel;
    @Input() userPermissions: string[] = [];

    @ViewChild(McTreeSelect) permissionsTreeSelect: McTreeSelect;

    get permissionsPlaceholder(): string {
        return this.userPermissions.length === 0 ? this.permissionsPlaceholderText : '';
    }

    TimeFormats = TimeFormats;
    minDate = this.adapter.today();
    filterDate = this.minDate.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
    });

    treeControl: FlatTreeControl<IPermissionFlatTreeNode>;
    treeFlattener: McTreeFlattener<IPermissionTreeNode, IPermissionFlatTreeNode>;
    dataSource: McTreeFlatDataSource<IPermissionTreeNode, IPermissionFlatTreeNode>;

    selectHiddenItemsTextFormatter = selectHiddenItemsTextFormatter;

    private permissionsPlaceholderText = translate('users.Users.ApiKey.Text.EmptyPermissionsPlaceholder');

    constructor(private adapter: DateAdapter<DateTime>) {
        this.datepickerFilter = this.datepickerFilter.bind(this);
    }

    ngOnInit() {
        this.initPermissionsControl();
        this.toggleExpiresAtControls();
        this.togglePermissionsControl();
    }

    toggleExpiresAtControls() {
        const isPerpetualApiKey = this.form.getControl('isPerpetual').value as boolean;

        if (isPerpetualApiKey) {
            this.form.hideControls(['expiresAt']);
        } else {
            this.form.showControls(['expiresAt']);
        }
    }

    togglePermissionsControl() {
        const isInheritPermissions = this.form.getControl('isInheritPermissions').value as boolean;

        if (isInheritPermissions) {
            this.form.hideControls(['permissions']);
        } else {
            this.form.showControls(['permissions']);
        }

        if (!isInheritPermissions && this.userPermissions.length === 0) {
            this.form.disable(['permissions']);
        }
    }

    isTreeNodeHasChild(_index: number, nodeData: IPermissionFlatTreeNode) {
        return nodeData.expandable;
    }

    onPermissionsChange(event: McTreeSelectChange) {
        const option: McTreeOption = event.value;

        if (option.isExpandable) {
            this.toggleChildren(option);
        }
    }

    datepickerFilter(date: DateTime | null): boolean {
        return date >= this.filterDate;
    }

    onDateChange() {
        const expiresAt = this.form.getControl('expiresAt');

        if (expiresAt.value.hour === 0 && expiresAt.value.minute === 0) {
            const expiresAtDate = expiresAt.value.set({
                hour: 23,
                minute: 59
            });

            expiresAt.setValue(expiresAtDate);
        }
    }

    private toggleChildren(option: McTreeOption) {
        const node = option.data as unknown as IPermissionFlatTreeNode;
        const valuesToChange = this.treeControl.getDescendants(node);
        const valuesWithChildren = valuesToChange.filter((i) => i.expandable);
        const valuesWithoutChildren = valuesToChange.filter((i) => !i.expandable);

        this.permissionsTreeSelect.selectionModel.deselect(node);

        valuesWithChildren.forEach((childNode) => {
            this.permissionsTreeSelect.selectionModel.deselect(childNode);
        });

        const hasNotSelected = valuesWithoutChildren.some(
            (i) => !this.permissionsTreeSelect.selectionModel.isSelected(i)
        );

        if (!option.isExpanded || hasNotSelected) {
            this.permissionsTreeSelect.selectionModel.select(...valuesWithoutChildren);
        } else {
            this.permissionsTreeSelect.selectionModel.deselect(...valuesWithoutChildren);
        }

        this.treeControl.expand(node);
        this.treeControl.expandDescendants(node);
    }

    private initPermissionsControl() {
        this.treeFlattener = new McTreeFlattener(transformer, getLevel, isExpandable, getChildren);
        this.treeControl = new FlatTreeControl<IPermissionFlatTreeNode>(getLevel, isExpandable, getValue, getViewValue);
        this.dataSource = new McTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.dataSource.data = buildPermissionsTree(this.userPermissions);
    }
}
