import { ObservableEntityType } from '../common';

export interface IAttributeSearchGeneralParams extends IAttributeSearchQuery, IAttributeSearchFilter {}

export interface IIdentitySpecificSearchFilter {
    name: string;
}

export interface IFileSpecificSearchFilter {
    name?: string;
    description?: string;
}

export interface IUrlSpecificSearchFilter {
    containedHost?: string;
}

export interface IDomainDNSSpecificSearchFilter {
    resolvesToIP?: string[];
    nameServer?: string;
    canonicalName?: string;
    mailExchange?: string;
    textStrings?: string;
    soaPrimaryNameServer?: string;
    soaResponsibleEmail?: string;
}

export interface IDomainWhoisSpecificSearchFilter {
    registration?: {
        createdSince?: string;
        createdTill?: string;
        paidTillFrom?: string;
        paidTillTo?: string;
    };
    registrant?: {
        names?: string[];
        emails?: string[];
        privatePerson?: FilterBooleanFields;
    };
    otherWhoisEmails?: string[];
}

export interface IDomainSpecificSearchFilter {
    dns?: IDomainDNSSpecificSearchFilter;
    whois?: IDomainWhoisSpecificSearchFilter;
}

export interface ICommonAttributeSearchFilter {
    labels?: string[];
    types?: ObservableEntityType[];
    statuses?: string[];
    shareLevels?: string[];
    nodeRoles?: string[];
    sourceGroups?: string[];
    sourceIDs?: string[];
    excludeTrusted?: boolean;
    isIoC?: boolean;
}

export interface IAttributeSearchFilter extends ICommonAttributeSearchFilter {
    identity?: IIdentitySpecificSearchFilter;
    file?: IFileSpecificSearchFilter;
    url?: IUrlSpecificSearchFilter;
    domainName?: IDomainSpecificSearchFilter;
}

export interface IAttributeSearchQuery extends IAttributeSearchFilter {
    searchString: string;
    seenFrom?: string;
    seenTill?: string;
    seenLast?: string;
}

export interface IAttributeSearchParams {
    limit: number;
    cursor: string;
    general: IAttributeSearchGeneralParams;
    file?: IFileSpecificSearchFilter;
    url?: IUrlSpecificSearchFilter;
    identity?: IIdentitySpecificSearchFilter;
    domainName?: IDomainSpecificSearchFilter;
}

export interface IAttributeSearchResult {
    objects: { id: string; type: ObservableEntityType }[];
    cursor: string;
}

export type TAttributeSearchFilter =
    | ICommonAttributeSearchFilter
    | IIdentitySpecificSearchFilter
    | IDomainSpecificSearchFilter['dns']
    | IDomainSpecificSearchFilter['whois']
    | IDomainSpecificSearchFilter['whois']['registrant']
    | IDomainSpecificSearchFilter['whois']['registration']
    | IFileSpecificSearchFilter
    | IUrlSpecificSearchFilter;

export type TSpecificAttributeSearchFilters = IIdentitySpecificSearchFilter &
    IDomainSpecificSearchFilter &
    IFileSpecificSearchFilter &
    IUrlSpecificSearchFilter;

export type TAttributeSearchFilters = TSpecificAttributeSearchFilters & ICommonAttributeSearchFilter;

export enum FilterBooleanFields {
    True = 'true',
    False = 'false'
}
