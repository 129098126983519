import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import {
    AsyncValidationService,
    createControlValidator,
    IFormViewModelBuilder,
    TFormViewModelProps
} from '@pt-cybsi/shared';

import { validateDictionarySynonymFormat, validateDictionarySynonymLength } from '../../validators';

import { TAddSynonymFormData } from './add-synonym-form.mapper';
import { AddSynonymFormConfig, AddSynonymFormViewModel, TAddSynonymFormConfig } from './add-synonym-form.view-model';

/**
 * @factory AddSynonymFormBuilder
 *
 * @description
 * Provides a factory method to create SynonymFormViewModel.
 * Creates a reactive form of synonym addition form and wrap it in FormViewModel.
 */
@Injectable()
export class AddSynonymFormBuilder implements IFormViewModelBuilder<TAddSynonymFormData, TAddSynonymFormConfig> {
    formConfig = AddSynonymFormConfig;

    constructor(private fb: UntypedFormBuilder, private asyncValidationService: AsyncValidationService) {}

    build(props: TFormViewModelProps<TAddSynonymFormData>): AddSynonymFormViewModel {
        const form = this.buildFormGroup();

        return new AddSynonymFormViewModel(props, form, this.formConfig, this.asyncValidationService);
    }

    private buildFormGroup(): UntypedFormGroup {
        const { id, serverId, synonym } = this.formConfig;

        const dictionarySynonymLengthValidator = createControlValidator(
            validateDictionarySynonymLength,
            synonym.errorNames.length
        );

        const dictionarySynonymFormatValidator = createControlValidator(
            validateDictionarySynonymFormat,
            synonym.errorNames.format
        );

        return this.fb.group({
            [id.controlName]: [undefined],

            [serverId.controlName]: [undefined],

            [synonym.controlName]: [
                undefined,
                {
                    validators: [dictionarySynonymLengthValidator, dictionarySynonymFormatValidator]
                }
            ]
        });
    }
}
