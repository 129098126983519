import { AddDictionaryItemToSynonymsErrorCode, IServerError } from '@pt-cybsi/api-interfaces';
import { buildFormConfiguration, FormViewModel } from '@pt-cybsi/shared';

import { AddSynonymFormSavingError } from '../../types';

import { TAddSynonymFormData } from './add-synonym-form.mapper';

/**
 * @ViewModel AddSynonymForm
 *
 * @description
 * Model of Angular Reactive Form with additional features for a synonym addition form.
 * Contains extended information about a form state, a working mode, and a saving error.
 *
 * For creation FormViewModel need to create a factory `AddSynonymFormBuilder`
 */
export class AddSynonymFormViewModel extends FormViewModel<TAddSynonymFormData, TAddSynonymFormConfig> {
    clearForm() {
        this.updateCurrentData({ ...this.currentData, synonym: null });
    }

    updateSavingError(error: IServerError): void {
        super.updateSavingError(error);

        if (this.savingError?.code === AddSynonymFormSavingError.ExistedSynonymError) {
            this.setError('synonym', 'unique');
        }

        if (this.savingError?.code === AddDictionaryItemToSynonymsErrorCode.InvalidSynonym) {
            this.setError('synonym', 'self');
        }

        if (this.savingError?.code === AddDictionaryItemToSynonymsErrorCode.InvalidDictionary) {
            this.setError('synonym', 'anotherDictionary');
        }
    }
}

const FormConfig = {
    id: {
        controlName: 'id',
        errorNames: null
    },
    serverId: {
        controlName: 'serverId',
        errorNames: null
    },
    synonym: {
        controlName: 'synonym',
        errorNames: {
            length: 'length',
            format: 'format',
            unique: 'unique',
            self: 'self',
            anotherDictionary: 'anotherDictionary'
        }
    }
} as const;

export type TAddSynonymFormConfig = typeof FormConfig;

export const AddSynonymFormConfig = buildFormConfiguration<TAddSynonymFormData, TAddSynonymFormConfig>(FormConfig);
