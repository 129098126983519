import { Injectable, Provider } from '@angular/core';

import { IFormRepository, ValidateFormUseCase } from '@pt-cybsi/shared';

import { SourceTypeModel } from '../../models';
import { SourceTypeFormRepository, SourceTypeFormRepositoryProvider } from '../../repositories';

@Injectable()
export class ValidateSourceTypeUseCase extends ValidateFormUseCase<SourceTypeModel> {
    constructor(repository: IFormRepository<SourceTypeModel>) {
        super(repository);
    }
}

export const ValidateSourceTypeUseCaseProvider: Provider = [
    SourceTypeFormRepositoryProvider,
    {
        provide: ValidateSourceTypeUseCase,
        useFactory: (repository: SourceTypeFormRepository) => new ValidateSourceTypeUseCase(repository),
        deps: [SourceTypeFormRepository]
    }
];
