import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as RouterActions from './router.actions';
import * as RouterSelectors from './router.selectors';

@Injectable()
export class RouterFacade {
    legacyUrl$ = this.store.pipe(select(RouterSelectors.getLegacyUrl));
    isLegacyMode$ = this.store.pipe(select(RouterSelectors.getIsLegacyMode));
    isNavbarVisible$ = this.store.pipe(select(RouterSelectors.getIsNavbarVisible));
    isChangedForm$ = this.store.pipe(select(RouterSelectors.getIsChangedForm));
    routeParams$ = this.store.pipe(select(RouterSelectors.getRouteParams));
    queryParams$ = this.store.pipe(select(RouterSelectors.getQueryParams));
    pageTitle$ = this.store.pipe(select(RouterSelectors.getPageTitle));

    constructor(private store: Store) {}

    setIsLegacyMode(isLegacyMode: boolean) {
        this.store.dispatch(RouterActions.setIsLegacyMode({ isLegacyMode }));
    }

    setLegacyUrl(legacyUrl: string) {
        this.store.dispatch(RouterActions.setLegacyUrl({ legacyUrl }));
    }

    setPageTitle(pageTitle: string) {
        this.store.dispatch(RouterActions.setPageTitle({ pageTitle }));
    }

    setIsChangedForm(isChangedForm: boolean) {
        this.store.dispatch(RouterActions.setIsChangedForm({ isChangedForm }));
    }
}
