import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class LicenseNavigationService {
    static routes = {
        view: ''
    };

    readonly root = '/license';

    constructor(private router: Router) {}

    goToViewPage(): void {
        const path = this.getPathOfViewRoute();

        this.router.navigate([path]);
    }

    getPathOfViewRoute(): string {
        return `${this.root}`;
    }
}
