import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(data: unknown[], predicate: (item: unknown) => boolean): unknown[] {
        if (Array.isArray(data)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            return [...data].filter(predicate);
        }

        return data;
    }
}
