<mc-modal-title>
    {{ 'sources.Sources.SourceSelectModal.Title.SelectSource' | transloco }}
</mc-modal-title>
<mc-modal-body>
    <progress-container class="source-select__progress-container" [loadingFlag]="isLoaderVisible$ | async">
        <div *ngIf="isErrorVisible$ | async" class="source-select__error">
            <ng-container *ngIf="isSelectedSourcePreconditionFailed$ | async; else defaultError">
                <div id="source-select-precondition-error" class="mc-alert mc-alert_error mc-alert_dismissible">
                    <i mc-icon="mc-error_16" class="mc-alert__icon"></i>
                    <div>{{ 'users.Users.Edit.Error.AlreadyChanged' | transloco }}</div>
                </div>
            </ng-container>
            <ng-template #defaultError>
                <div *ngIf="isSourcesLoadingFailed$ | async" class="mc-alert mc-alert_error">
                    <i mc-icon="mc-error_16" class="mc-alert__icon"></i>
                    <div>
                        <div>{{ 'sources.Sources.SourceSelectModal.Error.LoadingSources' | transloco }}</div>
                        <button mc-link pseudo class="mc-link clear-button" (click)="reloadSources()">
                            <span class="mc-link__text">{{ 'common.Common.Loading.Text.RetryButton' | transloco }}</span>
                        </button>
                    </div>
                </div>
                <div *ngIf="isSelectSourceFailed$ | async" id="source-select-error" class="mc-alert mc-alert_error mc-alert_dismissible">
                    <i mc-icon="mc-error_16" class="mc-alert__icon"></i>
                    <div>{{ 'sources.Sources.SourceSelectModal.Error.SelectSource' | transloco }}</div>
                    <button class="mc-alert__close" (click)="hideSelectSourcesError()">
                        <i class="mc-icon_light" mc-icon="mc-close-M_16" color="second"></i>
                    </button>
                </div>
            </ng-template>
        </div>
        <div *ngIf="isSourcesLoaded$ | async">
            <mc-form-field mcFormFieldWithoutBorders class="source-select__search-form-field">
                <i mcPrefix mc-icon="mc-search_16" color="second"></i>
                <input type="text"
                       mcInput
                       [placeholder]="'sources.Sources.SearchFilter.Text.NamePlaceholder' | transloco"
                       (ngModelChange)="onSearchChange($event)"
                       [(ngModel)]="searchValue">
            </mc-form-field>
            <mc-divider></mc-divider>
            <div class="source-select__tree-container"
                 [ngClass]="{'source-select__tree-container_after-error': isErrorVisible$ | async}">
                <mc-tree-selection
                    [(ngModel)]="selectedSourceIdInTree"
                    [dataSource]="dataSource"
                    [treeControl]="treeControl">
                    <mc-tree-option *mcTreeNodeDef="let node" mcTreeNodePadding >
                        <div class="pt-text-overflow" pt-title [id]="node.id" [innerHTML]="treeControl.getViewValue(node) | mcHighlight: treeControl.filterValue.value"></div>
                    </mc-tree-option>

                    <mc-tree-option *mcTreeNodeDef="let node; when: hasChild" mcTreeNodePadding [disabled]="true">
                        <mc-tree-node-toggle [node]="node"></mc-tree-node-toggle>
                        <div class="pt-text-overflow" pt-title [innerHTML]="treeControl.getViewValue(node) | mcHighlight: treeControl.filterValue.value"></div>
                    </mc-tree-option>
                </mc-tree-selection>
            </div>
            <div *ngIf="isEmptySearchResult" class="text-disabled padding_16">
                {{ 'common.Common.Search.Title.NotFound' | transloco }}
            </div>
        </div>
    </progress-container>
</mc-modal-body>
<mc-modal-footer>
    <button mc-button color="primary"
            [disabled]="isDisabledSelectBtn"
            (click)="selectSource()">
        {{ 'common.Common.Pseudo.Button.Select' | transloco }}
    </button>
    <button mc-button color="second" (click)="closeModal()">
        {{ 'common.Common.Pseudo.Button.Cancel' | transloco }}
    </button>
</mc-modal-footer>
