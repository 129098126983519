import { IRelationLink, RelationDirection, TAggregate, TObservableEntity } from '@pt-cybsi/api-interfaces';

import { EntityFullInfoModel, RelationModel } from '../models';
import { IRelationMetadata } from '../types';

import { RelationMetadataMapper } from './relation-metadata.mapper';

export class RelationModelMapper {
    static fromRelationLink<EntityFormat extends TObservableEntity | TAggregate = TObservableEntity>(
        relationLink: IRelationLink<EntityFormat>
    ): RelationModel {
        const { link, confidence } = relationLink;

        return new RelationModel(
            link.relationKind,
            link.direction,
            EntityFullInfoModel.createFromRawData(link.relatedEntity),
            confidence
        );
    }

    static fromRelationMetadata<EntityFormat extends TObservableEntity | TAggregate = TObservableEntity>(
        relation: IRelationMetadata<EntityFormat>
    ): RelationModel {
        return new RelationModel(
            relation.config.kind,
            relation.config.direction,
            EntityFullInfoModel.createFromRawData(relation.targetEntity),
            relation.confidence
        );
    }

    static invertModel(model: RelationModel, sourceEntity: EntityFullInfoModel): RelationModel {
        const relation = RelationMetadataMapper.fromRelationModel(model, {
            sourceEntity
        });

        const invertedRelation =
            relation.config.direction === RelationDirection.Forward
                ? RelationMetadataMapper.toReverse(relation)
                : RelationMetadataMapper.toForward(relation);

        return new RelationModel(
            invertedRelation.config.kind,
            invertedRelation.config.direction,
            sourceEntity,
            invertedRelation.confidence
        );
    }
}
