import { IServerError, Role, ShareLevel } from '@pt-cybsi/api-interfaces';
import { buildFormConfiguration, FormViewModel, TFormData } from '@pt-cybsi/shared';

import { UserFormSavingError } from './user-form.types';

export class UserFormViewModel extends FormViewModel<TUserFormData, TUserFormConfig> {
    updateSavingError(error: IServerError) {
        super.updateSavingError(error);

        if (error?.code === UserFormSavingError.DuplicateLogin) {
            this.setError('login', 'unique');
        }
    }

    getChangedData(): Partial<TUserFormData> {
        return super.getChangedData(['serverId', 'eTag']);
    }
}

const FormConfig = {
    id: {
        controlName: 'id',
        errorNames: null
    },
    serverId: {
        controlName: 'serverId',
        errorNames: null
    },
    eTag: {
        controlName: 'eTag',
        errorNames: null
    },
    login: {
        controlName: 'login',
        errorNames: {
            required: 'required',
            length: 'length',
            pattern: 'pattern',
            unique: 'unique'
        }
    },
    fullName: {
        controlName: 'fullName',
        errorNames: {
            length: 'length'
        }
    },
    email: {
        controlName: 'email',
        errorNames: {
            length: 'length'
        }
    },
    password: {
        controlName: 'password',
        errorNames: {
            // устанавливается в Mosaic при использовании McPasswordHint
            passwordStrength: 'passwordStrength'
        }
    },
    accessLevel: {
        controlName: 'accessLevel',
        errorNames: {
            required: 'required'
        }
    },
    roles: {
        controlName: 'roles',
        errorNames: {
            required: 'required'
        }
    }
};

export type TUserFormData = TFormData<{
    serverId: string;
    eTag: string;
    login: string;
    fullName: string;
    email: string;
    password: string;
    accessLevel: ShareLevel;
    roles: Role[];
}>;

export type TUserFormConfig = typeof FormConfig;

export const UserFormConfig = buildFormConfiguration<TUserFormData, TUserFormConfig>(FormConfig);
