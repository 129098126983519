import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

import { IRelationConfig } from '@pt-cybsi/api-interfaces';

@Pipe({
    name: 'translateRelationLinkType'
})
export class TranslateRelationLinkTypePipe implements PipeTransform {
    transform(config: IRelationConfig): string {
        return translate(
            `entities.ObservableEntities.RelationLinkType.Text.${config.source}-${config.kind}-${config.target}-${config.direction}`
        );
    }
}
