import { ApiResource, PermissionType } from '@pt-cybsi/api-interfaces';
import { buildPermissionString, RootPermissionsService } from '@pt-cybsi/domain-core/account';

const Read = PermissionType.Read;
const Write = PermissionType.Write;

// prettier-ignore
export class UsersPermissionsService {
    static readonly viewListPermissions = RootPermissionsService.viewUsersPermissions;

    static readonly viewPermissions = [
        buildPermissionString(ApiResource.Users, [Read])
    ];

    static readonly createPermissions = [
        buildPermissionString(ApiResource.Users, [Write])
    ];

    static readonly editPermissions = [
        buildPermissionString(ApiResource.Users, [Write])
    ];

    static readonly editUserDisabledPermissions = [
        buildPermissionString(ApiResource.Users, [Write])
    ];

    static readonly viewUserDataSourcePermissions = [
        buildPermissionString(ApiResource.Users, [Read]),
        buildPermissionString(ApiResource.DataSources, [Read])
    ];

    static readonly editUserDataSourcePermissions = [
        buildPermissionString(ApiResource.Users, [Write]),
        buildPermissionString(ApiResource.DataSources, [Read])
    ];

    static readonly changeUserPasswordPermissions = [
        buildPermissionString(ApiResource.Users, [Write])
    ];
}
