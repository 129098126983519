import { buildFormConfiguration, FormViewModel, TFormData } from '@pt-cybsi/shared';

export class PasswordFormViewModel extends FormViewModel<TPasswordFormData, TPasswordFormConfig> {}

const FormConfig = {
    id: {
        controlName: 'id',
        errorNames: null
    },
    oldPassword: {
        controlName: 'oldPassword',
        errorNames: null
    },
    newPassword: {
        controlName: 'newPassword',
        errorNames: {
            passwordStrength: 'passwordStrength'
        }
    }
};

export type TPasswordFormData = TFormData<{
    oldPassword: string;
    newPassword: string;
}>;

export type TPasswordFormConfig = typeof FormConfig;

export const PasswordFormConfig = buildFormConfiguration<TPasswordFormData, TPasswordFormConfig>(FormConfig);
