import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { IPaginationResponse } from '@pt-cybsi/api-interfaces';
import { AsyncState, LazyDataState, LazyDataStateStore } from '@pt-cybsi/shared';

import { ITableApiKeyData } from '../types';

interface IUserApiKeysState {
    loadingState: AsyncState;
    data: ITableApiKeyData[];
    cursor: string;
}

export interface IUserApiKeysLoadParams {
    userId?: string;
    cursor?: string;
}

export abstract class AbstractUserApiKeysLoadService {
    abstract load(params?: IUserApiKeysLoadParams): Observable<IPaginationResponse<ITableApiKeyData[]>>;
}

const DEFAULT_STATE: IUserApiKeysState = {
    loadingState: AsyncState.Loading,
    data: [],
    cursor: null
};

@Injectable()
export class UserApiKeysStore extends ComponentStore<IUserApiKeysState> {
    readonly loadingState$: Observable<AsyncState> = this.select((state) => state.loadingState);
    readonly lazyLoadingState$: Observable<LazyDataState> = this.lazyDataStateStore.dataState$;
    readonly data$: Observable<ITableApiKeyData[]> = this.select((state) => state.data);
    readonly cursor$: Observable<string> = this.select((state) => state.cursor);

    readonly updateLoadingState = this.updater((state, loadingState: AsyncState) => ({
        ...state,
        loadingState
    }));

    readonly saveResponse = this.updater((state, response: IPaginationResponse<ITableApiKeyData[]>) => ({
        ...state,
        cursor: response.cursor,
        data: response.data
    }));

    readonly concatResponse = this.updater((state, response: IPaginationResponse<ITableApiKeyData[]>) => {
        const { cursor, data } = response;

        const currentData = state.data;

        return {
            ...state,
            cursor,
            data: currentData.concat(data)
        };
    });

    readonly load = this.effect((loadParams$: Observable<IUserApiKeysLoadParams>) =>
        loadParams$.pipe(
            tap(() => {
                this.updateLoadingState(AsyncState.Loading);
                this.lazyDataStateStore.setCompleteState(false);
            }),
            switchMap((loadParams) =>
                this.userApiKeysLoadService.load(loadParams).pipe(
                    tapResponse(
                        (response) => {
                            this.saveResponse(response);
                            this.updateLoadingState(AsyncState.Success);
                            this.updateLazyLoadingState(response);
                        },
                        () => this.updateLoadingState(AsyncState.Failure)
                    )
                )
            ),
            takeUntil(this.destroy$)
        )
    );

    readonly loadNextPage = this.effect((loadParams$: Observable<IUserApiKeysLoadParams>) =>
        loadParams$.pipe(
            tap(() => this.lazyDataStateStore.setLoadingState()),
            withLatestFrom(this.cursor$),
            switchMap(([loadParams, cursor]) =>
                this.userApiKeysLoadService.load({ ...loadParams, cursor }).pipe(
                    tapResponse(
                        (response) => {
                            this.concatResponse(response);
                            this.updateLazyLoadingState(response);
                        },
                        () => this.lazyDataStateStore.setErrorState()
                    )
                )
            ),
            takeUntil(this.destroy$)
        )
    );

    constructor(
        protected userApiKeysLoadService: AbstractUserApiKeysLoadService,
        protected lazyDataStateStore: LazyDataStateStore
    ) {
        super(DEFAULT_STATE);
    }

    private updateLazyLoadingState(response: IPaginationResponse<ITableApiKeyData[]>): void {
        const isEndReached = response.cursor === null;

        this.lazyDataStateStore.setCompleteState(isEndReached);
    }
}
