import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component,
    Directive,
    DoCheck,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { McAutocomplete, McAutocompleteSelectedEvent } from '@ptsecurity/mosaic/autocomplete';
import { CanDisable, CanUpdateErrorState, ErrorStateMatcher } from '@ptsecurity/mosaic/core';
import { McFormFieldControl, McValidateDirective } from '@ptsecurity/mosaic/form-field';
import { McInput } from '@ptsecurity/mosaic/input';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
    catchError,
    debounceTime,
    delay,
    filter,
    map,
    switchMap,
    takeUntil,
    tap,
    withLatestFrom
} from 'rxjs/operators';

import { DictionariesApiService } from '@pt-cybsi/api';
import { IDictionaryItemBrief } from '@pt-cybsi/api-interfaces';
import { AutocompleteFieldMixinBase, DestroyService, IExistedItem, INewItem, isNewItem } from '@pt-cybsi/shared';

import { SYNONYM_MAX_LENGTH, SYNONYM_MIN_LENGTH } from '../../validators';

export interface INewDictionaryItem extends INewItem {
    value: { name: string };
}

export interface IExistedDictionaryItem extends IExistedItem {
    value: IDictionaryItemBrief;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export type TAutocompletedDictionaryItem = INewDictionaryItem | IExistedDictionaryItem;

let nextUniqueId = 0;

/**
 * @component DictionaryItemAutocompleteField
 *
 * @description
 * Form field for entering dictionary item name and select type from autocompleted data
 *
 * @param id - unique id of field. Optional, will be generated automatically
 * @param ngModel - name of source type
 * @param dictionaryId - dictionary id. Required, used for autocomplete
 * @param placeholder - placeholder text. Optional, will be used empty text
 * @param disabled - boolean value for marking the field as disabled. Optional, will be used `false`
 * @param required - boolean value for marking the field as required. Optional, will be used `false`
 * @param selectDictionaryItem - event, emitted after selection dictionary item from autocompleted data
 * @param clear - event, emitted after removed name from field
 *
 * @example
 * ```html
 * <mc-form-field>
 *      <dictionary-item-autocomplete-field
 *          [(ngModel)]="dictionaryItemName"
 *          (selectDictionaryItem)="handleSelectDictionaryItem($event)"
 *          (clear)="handleClear()">
 *      </dictionary-item-autocomplete-field>
 * </mc-form-field>
 *
 * <mc-form-field>
 *      <dictionary-item-autocomplete-field
 *          [formControl]="dictionaryItemNameControl"
 *          (selectDictionaryItem)="handleSelectDictionaryItem($event)"
 *          (clear)="handleClear()">
 *      </dictionary-item-autocomplete-field>
 * </mc-form-field>
 *
 * <mc-form-field>
 *      <dictionary-item-autocomplete-field
 *          [id]="'some-id'"
 *          [placeholder]="'Enter a name of dictionary item'"
 *          [(ngModel)]="dictionaryItemName"
 *          [required]="true"
 *          [disabled]="true"
 *          (selectDictionaryItem)="handleSelectDictionaryItem($event)"
 *          (clear)="handleClear()">
 *      </dictionary-item-autocomplete-field>
 * </mc-form-field>
 * ```
 */
@Component({
    selector: 'dictionary-item-autocomplete-field',
    templateUrl: './dictionary-item-autocomplete-field.component.html',
    styleUrls: ['./dictionary-item-autocomplete-field.component.scss'],
    providers: [DestroyService, { provide: McFormFieldControl, useExisting: DictionaryItemAutocompleteFieldComponent }],
    // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[attr.id]': 'id',
        '[attr.disabled]': 'disabled || null',
        '[attr.required]': 'required || null'
    }
})
export class DictionaryItemAutocompleteFieldComponent
    extends AutocompleteFieldMixinBase
    implements
        McFormFieldControl<string>,
        ControlValueAccessor,
        OnInit,
        OnChanges,
        DoCheck,
        OnDestroy,
        CanDisable,
        CanUpdateErrorState
{
    @Input()
    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value || this.uid;
        this.stateChanges.next();
    }

    @Input()
    get value(): string {
        return this._value;
    }

    set value(value: string) {
        if (value !== this.value) {
            if (this.inputValue === undefined || !value) {
                this.inputValue = value;

                if (this.mcInput) {
                    this.mcInput.value = value;
                }
            }

            if (!value) {
                this.loadAutocompleteOptions$.next({ name: '' });
            }

            this._value = value;
            this.stateChanges.next();
        }
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);

        if (this.focused) {
            this.focused = false;
            this.stateChanges.next();
        }
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);

        this.stateChanges.next();
    }

    @Input()
    get focused(): boolean {
        return this.mcInput ? this.mcInput.focused : this._focused;
    }

    set focused(value: boolean) {
        this._focused = coerceBooleanProperty(value);

        if (this.mcInput) {
            this.mcInput.focused = this._focused;
        }

        this.stateChanges.next();
    }

    @Input() placeholder: string;
    @Input() dictionaryId: string;

    @Output() selectDictionaryItem: EventEmitter<TAutocompletedDictionaryItem> =
        new EventEmitter<TAutocompletedDictionaryItem>();
    @Output() clear: EventEmitter<void> = new EventEmitter<void>();

    get empty(): boolean {
        return this._value === null || this._value === undefined;
    }

    errorState: boolean;
    controlType = 'dictionary-item-autocomplete-field';

    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isLoadingFailed$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isLoaded$: Observable<boolean> = this.isLoading$.pipe(
        withLatestFrom(this.isLoadingFailed$),
        map(([isLoading, isLoadingFailed]) => !isLoading && !isLoadingFailed)
    );

    inputValue: string;
    autocompleteOptions: TAutocompletedDictionaryItem[] = [];

    onChange: (value: string) => void;
    onTouched: () => void;

    @ViewChild('mcInput', { read: McInput }) mcInput: McInput;

    @ViewChild('autocompleteTypes', { read: McAutocomplete }) autocompleteTypes: McAutocomplete;

    private _id: string;
    private _value: string;
    private _disabled = false;
    private _focused = false;
    private _required = false;

    private loadAutocompleteOptions$: Subject<{ name: string }> = new Subject();
    private closeAutocomplete$: Subject<void> = new Subject();

    private readonly uid = `${this.controlType}-${nextUniqueId++}`;

    private createLabel: string = this.translocoService.translate('common.Common.Autocomplete.Text.Create');

    constructor(
        public defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() public parentForm: NgForm,
        @Optional() public parentFormGroup: FormGroupDirective,
        @Self() public ngControl: NgControl,
        private dictionariesApiService: DictionariesApiService,
        private translocoService: TranslocoService,
        private destroyed$: DestroyService
    ) {
        super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

        this.ngControl.valueAccessor = this;

        // eslint-disable-next-line no-self-assign
        this.id = this.id;

        this.autocompleteDisplayFn = this.autocompleteDisplayFn.bind(this);
    }

    ngOnInit(): void {
        this.loadAutocompleteOptions$
            .pipe(
                switchMap(({ name }) => {
                    if (name) {
                        this.toLoadingState();

                        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                        return of({ name }).pipe(delay(250));
                    }

                    return of({ name });
                }),

                switchMap(({ name }) => {
                    const nameLength = name.length;

                    if (nameLength === 0) {
                        return of([] as TAutocompletedDictionaryItem[]);
                    } else if (nameLength <= SYNONYM_MIN_LENGTH || nameLength > SYNONYM_MAX_LENGTH) {
                        return of([this.createNewItemAutocompleteOption(name)] as TAutocompletedDictionaryItem[]);
                    } else {
                        return this.getAutocompleteOptions(name).pipe(takeUntil(this.closeAutocomplete$));
                    }
                }),

                tap((options) => this.toLoadedState(options)),

                // Need delay for selectFirstItem
                switchMap((options) => (options.length > 0 ? of(options).pipe(delay(1)) : of(options))),

                filter(() => this.autocompleteTypes.isOpen),

                takeUntil(this.destroyed$)
            )
            .subscribe((options) => {
                if (options.length > 0) {
                    this.selectFirstItem();
                } else {
                    this.clear.emit();
                }
            });
    }

    ngOnChanges(): void {
        this.stateChanges.next();
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
    }

    handleInputChange(value: string | TAutocompletedDictionaryItem): void {
        if (typeof value === 'string') {
            this.value = value;
            this.loadAutocompleteOptions$.next({ name: value.trim() });
        } else {
            this.value = this.getDictionaryItemName(value);
            this.mcInput.value = this.value;
        }

        this.onChange(this.value);
    }

    handleRetry() {
        this.loadAutocompleteOptions$.next({ name: this.value });
    }

    handleInputFocus(): void {
        if (!this.disabled) {
            this.focused = true;
        }
    }

    handleInputBlur(): void {
        this.focused = false;

        this.validateControl();
    }

    handleSelectOption($event: McAutocompleteSelectedEvent): void {
        const option = $event.option.value as TAutocompletedDictionaryItem;

        this.selectDictionaryItem.emit(option);
    }

    handleOpenAutocomplete(): void {
        const isLoading = this.isLoading$.getValue();
        const hasSelectedOption = this.autocompleteTypes.options.find((option) => option.selected);

        if (!isLoading && !hasSelectedOption) {
            this.selectFirstItem(false);
        }
    }

    handleCloseAutocomplete(): void {
        const isLoading = this.isLoading$.getValue();

        const options = isLoading
            ? this.value
                ? [this.createNewItemAutocompleteOption(this.value)]
                : []
            : this.autocompleteOptions;

        this.toLoadedState(options);

        const selectedOption = this.autocompleteTypes.options.find((option) => option.selected);

        if (!selectedOption && this.autocompleteOptions.length > 0) {
            this.selectDictionaryItem.emit(this.autocompleteOptions[0]);
        }

        this.handleInputBlur();

        this.closeAutocomplete$.next();
    }

    onContainerClick(): void {
        this.focus();
    }

    focus(): void {
        if (!this.disabled) {
            this.focused = true;

            this.mcInput.focus();
        }
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    autocompleteDisplayFn(option: TAutocompletedDictionaryItem | string): string {
        if (typeof option === 'string') {
            return option;
        }

        return (option && this.getDictionaryItemName(option)) || '';
    }

    getAutocompleteOptionText(option: TAutocompletedDictionaryItem): string {
        return isNewItem(option)
            ? `${this.createLabel}: ${this.getDictionaryItemName(option)}`
            : this.getDictionaryItemName(option);
    }

    getDictionaryItemName(option: TAutocompletedDictionaryItem): string {
        return isNewItem(option)
            ? (option as unknown as INewDictionaryItem).value.name
            : (option.value as unknown as IDictionaryItemBrief).key;
    }

    private selectFirstItem(emit: boolean = true): void {
        const firstItem = this.autocompleteTypes.options.first;

        firstItem.select();

        if (emit) {
            this.autocompleteTypes.emitSelectEvent(firstItem);
        }
    }

    private getAutocompleteOptions(prefix: string): Observable<TAutocompletedDictionaryItem[]> {
        return this.dictionariesApiService
            .getDictionaryItems(this.dictionaryId, {
                prefix
            })
            .pipe(
                map(({ data }) => {
                    const options = data.map<TAutocompletedDictionaryItem>((dictionaryItem) => ({
                        isNew: false,
                        value: dictionaryItem
                    }));

                    const nameOfFirstOption = options.length > 0 ? this.getDictionaryItemName(options[0]) : null;

                    const isFullMatchByName = nameOfFirstOption && prefix === nameOfFirstOption;

                    return isFullMatchByName ? options : [this.createNewItemAutocompleteOption(prefix), ...options];
                }),
                catchError(() => {
                    this.toFailedState();

                    return of([]);
                })
            );
    }

    private toLoadingState(): void {
        this.isLoadingFailed$.next(false);
        this.isLoading$.next(true);
        this.autocompleteOptions = [];
    }

    private toLoadedState(data: TAutocompletedDictionaryItem[]): void {
        this.isLoading$.next(false);
        this.autocompleteOptions = data;
    }

    private toFailedState(): void {
        this.isLoading$.next(false);
        this.isLoadingFailed$.next(true);
    }

    private createNewItemAutocompleteOption(name: string): INewDictionaryItem {
        return { isNew: true, value: { name } };
    }

    private validateControl(): void {
        if (this.ngControl && this.ngControl.control) {
            const control = this.ngControl.control;

            control.updateValueAndValidity({ emitEvent: false });
            (control.statusChanges as EventEmitter<string>).emit(control.status);
        }
    }
}

@Directive({
    selector: 'dictionary-item-autocomplete-field',
    exportAs: 'DictionaryItemAutocompleteFieldMcValidateDirective'
})
export class DictionaryItemAutocompleteFieldMcValidateDirective extends McValidateDirective {}
