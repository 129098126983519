import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { McAutocompleteModule } from '@ptsecurity/mosaic/autocomplete';
import { McFormFieldModule } from '@ptsecurity/mosaic/form-field';
import { McInputModule } from '@ptsecurity/mosaic/input';
import { McLoaderOverlayModule } from '@ptsecurity/mosaic/loader-overlay';
import { McPopoverModule } from '@ptsecurity/mosaic/popover';
import { McProgressSpinnerModule } from '@ptsecurity/mosaic/progress-spinner';

import { SharedModule } from '@pt-cybsi/shared';

import {
    DictionaryItemPreviewComponent,
    DictionaryItemAutocompleteFieldComponent,
    DictionaryItemAutocompleteFieldMcValidateDirective,
    DictionaryItemsGridComponent
} from './components';
import { TranslateDictionariesNamePipe, TranslateDictionaryNamePipe } from './pipes';
import { DictionariesItemsNavigationService, DictionariesPermissionsService } from './services';

const modules = [
    CommonModule,
    RouterModule,
    SharedModule,
    McFormFieldModule,
    McAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    McProgressSpinnerModule,
    McInputModule,
    McLoaderOverlayModule,
    McPopoverModule
];
const services = [DictionariesItemsNavigationService, DictionariesPermissionsService];
const components = [
    DictionaryItemPreviewComponent,
    DictionaryItemAutocompleteFieldComponent,
    DictionaryItemsGridComponent
];
const directives = [DictionaryItemAutocompleteFieldMcValidateDirective];
const pipes = [TranslateDictionaryNamePipe, TranslateDictionariesNamePipe];

@NgModule({
    imports: [...modules],
    declarations: [...components, ...directives, ...pipes],
    providers: [...services, ...pipes],
    exports: [...components, ...directives, ...pipes]
})
export class DomainCoreDictionariesModule {}
