import { DateTime } from 'luxon';

export enum RelativeDateType {
    Days = 'Days'
}

export interface IRelativeDateRange {
    type: RelativeDateType;
    value: number;
}

export interface IAbsoluteDateRange {
    from?: DateTime;
    to?: DateTime;
}

export type TDateRange = IRelativeDateRange | IAbsoluteDateRange;

export const isRelativeDateRange = (r: TDateRange): r is IRelativeDateRange => r && 'type' in r && 'value' in r;

export const isAbsoluteDateRange = (r: TDateRange): r is IAbsoluteDateRange => r && ('from' in r || 'to' in r);
