import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { McModalRef } from '@ptsecurity/mosaic/modal';
import { finalize } from 'rxjs/operators';

import { AuthApiService } from '@pt-cybsi/api';
import { AuthenticationErrorCodes, HttpStatusCode } from '@pt-cybsi/api-interfaces';
import { LocationService } from '@pt-cybsi/core';
import { AccountFacade } from '@pt-cybsi/store/account';

@Component({
    selector: 'sign-out-modal',
    templateUrl: './sign-out-modal.component.html'
})
export class SignOutModalComponent {
    get isSignOutProgress(): boolean {
        return this._isSignOutProgress;
    }

    set isSignOutProgress(value: boolean) {
        this._isSignOutProgress = value;
    }

    private _isSignOutProgress = false;

    constructor(
        private modalRef: McModalRef,
        private accountFacade: AccountFacade,
        private authApiService: AuthApiService,
        private locationService: LocationService,
        private window: Window
    ) {}

    closeModal() {
        this.modalRef.destroy();
    }

    signOut() {
        this.isSignOutProgress = true;

        this.authApiService
            .signOut()
            .pipe(
                finalize(() => {
                    this.accountFacade.reset();
                    this.locationService.resetEntryUrl();
                    this.closeModal();
                })
            )
            .subscribe({
                // eslint-disable-next-line @typescript-eslint/naming-convention
                next: ({ authProviderURL }) => {
                    if (authProviderURL) {
                        this.window.location.replace(authProviderURL);
                    } else {
                        this.locationService.redirectToSignInPage({ skipDirtyChecking: true });
                    }
                },
                error: (response: unknown) => {
                    if (response instanceof HttpErrorResponse && response.status === HttpStatusCode.NOT_FOUND) {
                        this.locationService.redirectToSignInPage({ skipDirtyChecking: true });

                        return;
                    }

                    this.locationService.redirectToErrorPage(AuthenticationErrorCodes.SignOut);
                }
            });
    }
}
