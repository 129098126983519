import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import {
    createChangedControlValidator,
    createControlValidator,
    IFormViewModelBuilder,
    TFormViewModelProps
} from '@pt-cybsi/shared';

import { TSourceFormData } from '../../mappers';
import {
    validateConfidenceAccuracy,
    validateConfidenceRange,
    validateSourceNameLength,
    validateSourceShortNameFormat,
    validateSourceShortNameLength
} from '../../validators';

import { SourceFormConfig, SourceFormViewModel, TSourceFormConfig } from './source-form.view-model';

/**
 * @factory SourceFormBuilder
 *
 * @description
 * Provides a factory method to create SourceFormViewModel.
 * Creates a reactive form of source form and wrap it in FormViewModel.
 */
@Injectable()
export class SourceFormBuilder implements IFormViewModelBuilder<TSourceFormData, TSourceFormConfig> {
    formConfig = SourceFormConfig;

    constructor(private fb: UntypedFormBuilder) {}

    build(props: TFormViewModelProps<TSourceFormData>): SourceFormViewModel {
        const form = this.buildFormGroup();

        return new SourceFormViewModel(props, form, this.formConfig);
    }

    private buildFormGroup(): UntypedFormGroup {
        const {
            id,
            typeId,
            analyserId,
            externalDBId,
            eTag,
            serverId,
            name,
            shortName,
            confidence,
            isMatchWithTypeConfidence,
            isAnalyser,
            isExternalDB
        } = this.formConfig;

        const nameLengthValidator = createControlValidator(validateSourceNameLength, name.errorNames.length);

        const shortNameLengthValidator = createControlValidator(
            validateSourceShortNameLength,
            shortName.errorNames.length
        );

        const shortNameFormatValidator = createControlValidator(
            validateSourceShortNameFormat,
            shortName.errorNames.format
        );

        const confidenceRangeValidator = createControlValidator(validateConfidenceRange, confidence.errorNames.range);

        const confidenceAccuracyValidator = createChangedControlValidator(
            validateConfidenceAccuracy,
            confidence.errorNames.accuracy
        );

        const required = Validators.required;

        return this.fb.group({
            [id.controlName]: [undefined],
            [typeId.controlName]: [undefined],
            [analyserId.controlName]: [undefined],
            [externalDBId.controlName]: [undefined],
            [serverId.controlName]: [undefined],
            [eTag.controlName]: [undefined],
            [name.controlName]: [
                undefined,
                {
                    validators: [required, nameLengthValidator]
                }
            ],
            [shortName.controlName]: [
                undefined,
                {
                    validators: [required, shortNameLengthValidator, shortNameFormatValidator]
                }
            ],
            [confidence.controlName]: [
                undefined,
                {
                    validators: [required, confidenceRangeValidator, confidenceAccuracyValidator]
                }
            ],
            [isMatchWithTypeConfidence.controlName]: [undefined],
            [isAnalyser.controlName]: [undefined],
            [isExternalDB.controlName]: [undefined]
        });
    }
}
