import { IDataSource, IOldDictionaries, IUser } from '@pt-cybsi/api-interfaces';

export interface ICommonData {
    dictionaries: IOldDictionaries;
    dataSources: {
        data: IDataSource[];
        currentCursor: string;
    };
}

export enum MessageType {
    DispatchAction = 'DispatchAction',
    ChildAppReady = 'ChildAppReady',
    ChildAppBackdrop = 'ChildAppBackdrop',
    OpenConfirmLeavePageModal = 'OpenConfirmLeavePageModal',
    OpenSessionExpiredWarning = 'OpenSessionExpiredWarning',
    ConfirmLeavePage = 'ConfirmLeavePage',
    LeavePage = 'LeavePage',
    ReloadPage = 'ReloadPage',
    SetToken = 'SetToken',
    SetUser = 'SetUser',
    SetCommonData = 'SetCommonData',
    SetFirstAvailablePageUrl = 'SetFirstAvailablePageUrl',
    SetEntryUrl = 'SetEntryUrl'
}

export interface IMessageEventData<P> {
    type: MessageType;
    payload?: P;
}

/**
 * Enum with all available actions needed to communicate between applications
 * Contains string duplications of action types from different parts of the store
 */
export enum AllowedActionType {
    SetUrl = '[Router] set legacy url',
    SetPageTitle = '[Router] set page title',
    SetToken = '[Account] Set Token',
    SetError = '[Error] Set error',
    SetDataSources = '[Load API data] SUCCESS'
}

export interface IDispatchAction {
    type: AllowedActionType;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [P: string]: any;
}

export interface IDispatchActionMessage extends IMessageEventData<IDispatchAction> {
    type: MessageType.DispatchAction;
    payload: IDispatchAction;
}

export interface IChildAppReadyMessage extends IMessageEventData<boolean> {
    type: MessageType.ChildAppReady;
    payload: boolean;
}

export interface IChildAppBackdropMessage extends IMessageEventData<boolean> {
    type: MessageType.ChildAppBackdrop;
    payload: boolean;
}

export interface IOpenConfirmLeavePageModalMessage extends IMessageEventData<never> {
    type: MessageType.OpenConfirmLeavePageModal;
}

export interface IOpenSessionExpiredWarningMessage extends IMessageEventData<never> {
    type: MessageType.OpenSessionExpiredWarning;
}

export interface IConfirmLeavePageMessage extends IMessageEventData<boolean> {
    type: MessageType.ConfirmLeavePage;
    payload: boolean;
}

export interface ILeavePageMessage extends IMessageEventData<never> {
    type: MessageType.LeavePage;
}

export interface IReloadPageMessage extends IMessageEventData<never> {
    type: MessageType.ReloadPage;
}

export interface ISetTokenMessage extends IMessageEventData<string> {
    type: MessageType.SetToken;
    payload: string;
}

export interface ISetUserMessage extends IMessageEventData<IUser> {
    type: MessageType.SetUser;
    payload: IUser;
}

export interface ISetCommonDataMessage extends IMessageEventData<ICommonData> {
    type: MessageType.SetCommonData;
    payload: ICommonData;
}

export interface ISetFirstAvailablePageUrlMessage extends IMessageEventData<string> {
    type: MessageType.SetFirstAvailablePageUrl;
    payload: string;
}

export interface ISetEntryUrlMessage extends IMessageEventData<string> {
    type: MessageType.SetEntryUrl;
    payload: string;
}

export type AllowedMessage =
    | IDispatchActionMessage
    | IChildAppReadyMessage
    | IChildAppBackdropMessage
    | IOpenConfirmLeavePageModalMessage
    | IOpenSessionExpiredWarningMessage
    | IConfirmLeavePageMessage
    | IReloadPageMessage
    | ILeavePageMessage
    | ISetTokenMessage
    | ISetUserMessage
    | ISetCommonDataMessage
    | ISetFirstAvailablePageUrlMessage
    | ISetEntryUrlMessage;
