import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import isEmpty from 'lodash/isEmpty';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { EnrichmentApiService } from '@pt-cybsi/api';
import {
    HttpStatus,
    IApiItemPreview,
    IEnrichmentAnalyzerCreateParams,
    IEnrichmentAnalyzerUpdateParams,
    IServerError,
    TEditableEnrichmentAnalyzerResponse
} from '@pt-cybsi/api-interfaces';
import { SourceFormRepository, SourceFormRepositoryProvider, SourceModel } from '@pt-cybsi/domain-core/sources';
import { FormMode, FormSavingError, FormsStore, FormState, IFormRepository, isServerError } from '@pt-cybsi/shared';

import { AnalysersFormsStore, AnalysersFormsStoreProvider } from '../forms';
import { AnalyserModelMapper, TAnalyserFormData } from '../mappers';
import { AnalyserModel } from '../models';

@Injectable()
export class AnalyserFormRepository implements IFormRepository<AnalyserModel> {
    constructor(
        private sourceFormRepository: IFormRepository<SourceModel>,
        private apiService: IAnalysersApiService,
        private store: FormsStore<TAnalyserFormData>
    ) {}

    add(mode: FormMode, data: AnalyserModel): void {
        const formData = AnalyserModelMapper.toFormData(data);

        this.store.addForm(mode, formData);
    }

    delete(id: string): void {
        this.store.deleteForm(id);
    }

    getState(id: string): FormState {
        const stateValue = this.store.getState(id);

        return FormState.create(stateValue);
    }

    updateState(id: string, state: FormState): void {
        this.store.updateState(id, state.value);
    }

    getInitialData(id: string): AnalyserModel {
        const data = this.store.getInitialData(id);

        return AnalyserModelMapper.fromFormData(data);
    }

    updateInitialData(data: AnalyserModel): void {
        const formData = AnalyserModelMapper.toFormData(data);

        this.store.updateInitialData(formData);
    }

    getCurrentData(id: string): AnalyserModel {
        const data = this.store.getCurrentData(id);

        return AnalyserModelMapper.fromFormData(data);
    }

    updateCurrentData(data: AnalyserModel): void {
        const formData = AnalyserModelMapper.toFormData(data);

        this.store.updateCurrentData(formData);
    }

    updateSavingError(id: string, error: IServerError): void {
        this.store.updateSavingError(id, error);
    }

    resetSavingError(id: string): void {
        this.store.updateSavingError(id, null);
    }

    disable(id: string, fields?: (keyof AnalyserModel['props'])[]): void {
        this.store.disable(id, fields);
    }

    enable(id: string, fields?: (keyof AnalyserModel['props'])[]): void {
        this.store.enable(id, fields);
    }

    showControls(id: string, names: (keyof AnalyserModel['props'])[]): void {
        this.store.showControls(id, names);
    }

    hideControls(id: string, names: (keyof AnalyserModel['props'])[]): void {
        this.store.hideControls(id, names);
    }

    isTouched(id: string): boolean {
        return this.store.isTouched(id);
    }

    validate(id: string): Observable<boolean> {
        return this.store.validate(id);
    }

    loadData(id: string): Observable<AnalyserModel> {
        return this.apiService
            .getEditableAnalyzer(id)
            .pipe(map((analyser) => AnalyserModelMapper.fromEditableAnalyser(analyser)));
    }

    save(model: AnalyserModel): Observable<string> {
        const formMode = this.store.getFormMode(model.uid);

        return formMode === FormMode.Creation ? this.register(model) : this.update(model);
    }

    private register(model: AnalyserModel): Observable<string> {
        const sourceModel = this.sourceFormRepository.getCurrentData(model.props.sourceId);

        const params = AnalyserModelMapper.toRegisterParams(model, sourceModel);

        return this.apiService.createAnalyzer(params).pipe(
            map((response) => response.uuid),
            catchError(() => throwError({ code: FormSavingError.Common }))
        );
    }

    private update(model: AnalyserModel): Observable<string> {
        const { serverId, eTag } = model.props;

        const params = AnalyserModelMapper.toUpdateParams(model);

        if (isEmpty(params)) {
            return of(serverId);
        }

        return this.apiService.updateAnalyzer(serverId, eTag, params).pipe(
            map(() => serverId),
            catchError((response: unknown) => {
                const errorResponse = response as HttpErrorResponse;
                const error = errorResponse.error as unknown;

                if (isServerError(error) && error.code === HttpStatus.PRECONDITION_FAILED) {
                    return throwError({ code: FormSavingError.AlreadyChanged });
                }

                return throwError({ code: FormSavingError.Common });
            })
        );
    }
}

interface IAnalysersApiService {
    getEditableAnalyzer(serverId: string): Observable<TEditableEnrichmentAnalyzerResponse>;
    createAnalyzer(params: IEnrichmentAnalyzerCreateParams): Observable<IApiItemPreview>;
    updateAnalyzer(uuid: string, eTag: string, params: IEnrichmentAnalyzerUpdateParams): Observable<void>;
}

export const AnalyserFormRepositoryProvider: Provider = [
    SourceFormRepositoryProvider,
    EnrichmentApiService,
    AnalysersFormsStoreProvider,
    {
        provide: AnalyserFormRepository,
        useFactory: (repository: SourceFormRepository, apiService: EnrichmentApiService, store: AnalysersFormsStore) =>
            new AnalyserFormRepository(repository, apiService, store),
        deps: [SourceFormRepository, EnrichmentApiService, AnalysersFormsStore]
    }
];
