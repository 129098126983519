import { Injectable } from '@angular/core';
import { NavigationBehaviorOptions, NavigationExtras, Router } from '@angular/router';
import isEmpty from 'lodash/isEmpty';

export interface IDictionaryQueryParams {
    prefix?: string;
    substring?: string;
}

@Injectable({ providedIn: 'root' })
export class DictionariesNavigationService {
    static routes = {
        list: '',
        view: 'view/:dictionaryId'
    };

    readonly root = '/dictionaries';

    constructor(private router: Router) {}

    goToListPage(): void {
        const path = this.getPathOfListRoute();

        this.router.navigate([path]);
    }

    goToViewPage(
        dictionaryId: string,
        queryParams: IDictionaryQueryParams = {},
        navigationBehaviorOptions: NavigationBehaviorOptions = {}
    ): void {
        const path = this.getPathOfViewRoute(dictionaryId);
        const hasQueryParams = !isEmpty(queryParams);

        const extras: NavigationExtras = hasQueryParams ? { queryParams } : {};

        this.router.navigate([path], { ...extras, ...navigationBehaviorOptions });
    }

    getPathOfListRoute(): string {
        return `${this.root}/${DictionariesNavigationService.routes.list}`;
    }

    getPathOfViewRoute(dictionaryId: string): string {
        return `${this.root}/${DictionariesNavigationService.routes.view.replace(':dictionaryId', dictionaryId)}`;
    }
}
