import { IRelation, IRelationLink, RelationDirection, TObservableEntity } from '@pt-cybsi/api-interfaces';

import { EntityFullInfoModel, RelationModel } from '../models';
import { IRelationMetadata, TAvailableRelationEntityFormat } from '../types';

import { RelationConfigMapper } from './relation-config.mapper';

export interface IRelationViewFromLinkMappingMetadata<
    EntityFormat extends TAvailableRelationEntityFormat = TObservableEntity
> {
    sourceEntity: EntityFormat;
}

export interface IRelationViewFromRelationMappingMetadata {
    direction: RelationDirection;
}

export interface IRelationViewFromRelationModelMappingMetadata {
    sourceEntity: EntityFullInfoModel;
}

export class RelationMetadataMapper {
    static fromRelationLink<EntityFormat extends TAvailableRelationEntityFormat = TObservableEntity>(
        relationLink: IRelationLink<EntityFormat>,
        metadata: IRelationViewFromLinkMappingMetadata<EntityFormat>
    ): IRelationMetadata<EntityFormat> {
        const config = RelationConfigMapper.fromRelationLink(relationLink, metadata);
        const id = RelationConfigMapper.toRelationTypeId(config);

        return {
            id,
            config,
            sourceEntity: metadata.sourceEntity,
            targetEntity: relationLink.link.relatedEntity,
            confidence: relationLink.confidence
        };
    }

    static fromRelation<EntityFormat extends TAvailableRelationEntityFormat = TObservableEntity>(
        relation: IRelation<EntityFormat>,
        metadata: IRelationViewFromRelationMappingMetadata
    ): IRelationMetadata<EntityFormat> {
        const config = RelationConfigMapper.fromRelation(relation, metadata);
        const id = RelationConfigMapper.toRelationTypeId(config);

        return {
            id,
            config,
            sourceEntity: relation.sourceEntity,
            targetEntity: relation.targetEntity
        };
    }

    static fromRelationModel(
        relation: RelationModel,
        metadata: IRelationViewFromRelationModelMappingMetadata
    ): IRelationMetadata<EntityFullInfoModel> {
        const config = RelationConfigMapper.fromRelationModel(relation, metadata);
        const id = RelationConfigMapper.toRelationTypeId(config);

        return {
            id,
            config,
            sourceEntity: metadata.sourceEntity,
            targetEntity: relation.relatedEntity,
            confidence: relation.confidence
        };
    }

    static toForward<EntityFormat extends TAvailableRelationEntityFormat = TObservableEntity>(
        relation: IRelationMetadata<EntityFormat>
    ): IRelationMetadata<EntityFormat> {
        if (relation.config.direction === RelationDirection.Forward) {
            return relation;
        }

        const config = RelationConfigMapper.toForward(relation.config);
        const id = RelationConfigMapper.toRelationTypeId(config);

        return {
            id,
            config,
            sourceEntity: relation.targetEntity,
            targetEntity: relation.sourceEntity,
            confidence: relation.confidence
        };
    }

    static toReverse<EntityFormat extends TAvailableRelationEntityFormat = TObservableEntity>(
        relation: IRelationMetadata<EntityFormat>
    ): IRelationMetadata<EntityFormat> {
        if (relation.config.direction === RelationDirection.Reverse) {
            return relation;
        }

        const config = RelationConfigMapper.toReverse(relation.config);
        const id = RelationConfigMapper.toRelationTypeId(config);

        return {
            id,
            config,
            sourceEntity: relation.targetEntity,
            targetEntity: relation.sourceEntity,
            confidence: relation.confidence
        };
    }
}
