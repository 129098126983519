import { IServerError } from '@pt-cybsi/api-interfaces';
import { buildFormConfiguration, FormViewModel } from '@pt-cybsi/shared';

import { TSourceFormData } from '../../mappers';
import { SourceFormSavingError } from '../../types';

/**
 * @ViewModel SourceForm
 *
 * @description
 * Model of Angular Reactive Form with additional features for a source creation and edit form.
 * Contains extended information about a form state, a working mode, and a saving error.
 *
 * For creation FormViewModel need to create a factory `SourceFormBuilder`
 */
export class SourceFormViewModel extends FormViewModel<TSourceFormData, TSourceFormConfig> {
    updateSavingError(error: IServerError): void {
        super.updateSavingError(error);

        if (this.savingError?.code === SourceFormSavingError.DuplicateShortName) {
            this.setError('shortName', 'unique');
        }
    }

    disable(fields?: (keyof TSourceFormData)[]): void {
        super.disable(fields);

        if (fields?.includes('analyserId')) {
            this.getControl('isAnalyser').disable();
        }

        if (fields?.includes('externalDBId')) {
            this.getControl('isExternalDB').disable();
        }
    }

    enable(fields?: (keyof TSourceFormData)[]): void {
        super.enable(fields);

        if (fields?.includes('analyserId')) {
            this.getControl('isAnalyser').enable();
        }

        if (fields?.includes('externalDBId')) {
            this.getControl('isExternalDB').enable();
        }
    }
}

const FormConfig = {
    id: {
        controlName: 'id',
        errorNames: null
    },
    typeId: {
        controlName: 'typeId',
        errorNames: null
    },
    analyserId: {
        controlName: 'analyserId',
        errorNames: null
    },
    externalDBId: {
        controlName: 'externalDBId',
        errorNames: null
    },
    serverId: {
        controlName: 'serverId',
        errorNames: null
    },
    eTag: {
        controlName: 'eTag',
        errorNames: null
    },
    name: {
        controlName: 'name',
        errorNames: {
            required: 'required',
            length: 'length'
        }
    },
    shortName: {
        controlName: 'shortName',
        errorNames: {
            required: 'required',
            length: 'length',
            format: 'format',
            unique: 'unique'
        }
    },
    confidence: {
        controlName: 'confidence',
        errorNames: {
            required: 'required',
            range: 'range',
            accuracy: 'accuracy'
        }
    },
    isAnalyser: {
        controlName: 'isAnalyser',
        errorNames: null
    },
    isExternalDB: {
        controlName: 'isExternalDB',
        errorNames: null
    },
    isMatchWithTypeConfidence: {
        controlName: 'isMatchWithTypeConfidence',
        errorNames: null
    }
} as const;

export type TSourceFormConfig = typeof FormConfig;

export const SourceFormConfig = buildFormConfiguration<TSourceFormData, TSourceFormConfig>(FormConfig);
