import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { PasswordRules } from '@ptsecurity/mosaic/form-field';

import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from '@pt-cybsi/domain-core/users';
import { generatePassword } from '@pt-cybsi/shared';

import { PasswordFormViewModel } from '../../forms';

@Component({
    selector: 'password-form',
    templateUrl: './password-form.component.html',
    styleUrls: ['./password-form.component.scss']
})
export class PasswordFormComponent {
    @Input() form: PasswordFormViewModel;

    @ViewChild('newPasswordInput', { read: ElementRef }) newPasswordInput: ElementRef<HTMLInputElement>;
    @ViewChild('passwordToggle', { read: ElementRef }) passwordToggle: ElementRef<HTMLElement>;

    PasswordRules = PasswordRules;
    passwordLengthRequirements = {
        min: PASSWORD_MIN_LENGTH,
        max: PASSWORD_MAX_LENGTH
    };

    invalidPasswordLengthMessage = this.translocoService.translate('common.Common.Validation.Text.StringLengthMin', {
        min: PASSWORD_MIN_LENGTH
    });

    constructor(private translocoService: TranslocoService) {}

    handleGeneratePassword() {
        this.form.getControl('newPassword').setValue(generatePassword());
        this.newPasswordInput.nativeElement.focus();

        if (this.newPasswordInput.nativeElement.type === 'password') {
            this.passwordToggle.nativeElement.click();
        }
    }
}
