import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ClientConfigApiService } from '@pt-cybsi/api';

import * as ClientConfigActions from './client-config.actions';

@Injectable()
export class ClientConfigEffects {
    fetchClientConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ClientConfigActions.fetchClientConfig),
            switchMap(() =>
                this.clientConfigApiService.getConfig().pipe(
                    map((config) => ClientConfigActions.fetchClientConfigSuccess({ config })),
                    catchError(() => of(ClientConfigActions.fetchClientConfigFailure()))
                )
            )
        )
    );

    constructor(private actions$: Actions, private clientConfigApiService: ClientConfigApiService) {}
}
