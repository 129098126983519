import { Observable } from 'rxjs';

import { IPaginationResponse } from '@pt-cybsi/api-interfaces';

export abstract class AbstractSearchPageDataService<FilterParamsFormat, DataFormat> {
    abstract load(): Observable<IPaginationResponse<DataFormat[]>>;

    abstract filter(
        params: SearchPageDataFilterParams<Partial<FilterParamsFormat>>
    ): Observable<IPaginationResponse<DataFormat[]>>;
}

export interface SearchPageDataFilterParams<FilterParamsFormat> {
    filterParams: FilterParamsFormat;
    paginationParams?: {
        cursor: string;
    };
}
