import { Injectable } from '@angular/core';

export enum TimePeriodType {
    Seconds = 'Seconds',
    Minutes = 'Minutes',
    Hours = 'Hours'
}

export interface ITimePeriod {
    type: TimePeriodType;
    value: number;
}

const MS_IN_SECONDS = 1000;
const MS_IN_PERIOD = {
    [TimePeriodType.Seconds]: MS_IN_SECONDS,
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    [TimePeriodType.Minutes]: MS_IN_SECONDS * 60, // 60 = 1 minute in seconds
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    [TimePeriodType.Hours]: MS_IN_SECONDS * 3600 // 3600 = 1 hour in seconds
};

@Injectable({ providedIn: 'root' })
export class TimePeriodService {
    toPeriod(milliseconds: number, period: TimePeriodType, availablePeriods: TimePeriodType[]): ITimePeriod {
        if (milliseconds === null || milliseconds === undefined) {
            return null;
        }

        const isAvailablePeriod = availablePeriods.indexOf(period) !== -1;

        if (isAvailablePeriod && this.isCanConvertToEntirePeriod(milliseconds, period)) {
            return this.convertMsToPeriod(milliseconds, period);
        }

        return this.toMaxEntirePeriod(milliseconds, availablePeriods);
    }

    toMaxEntirePeriod(milliseconds: number, availablePeriods: TimePeriodType[]): ITimePeriod {
        if (milliseconds === null || milliseconds === undefined) {
            return null;
        }

        const maxCeilPeriod = this.getMaxCeilPeriod(milliseconds, availablePeriods);

        return this.convertMsToPeriod(milliseconds, maxCeilPeriod);
    }

    toMilliseconds(period: ITimePeriod): number {
        return period.value * MS_IN_PERIOD[period.type];
    }

    private getMaxCeilPeriod(milliseconds: number, availablePeriods: TimePeriodType[]): TimePeriodType {
        const periods = [...availablePeriods].reverse();

        return periods.find((period: TimePeriodType) => this.isCanConvertToEntirePeriod(milliseconds, period));
    }

    private convertMsToPeriod(milliseconds: number, periodType: TimePeriodType): ITimePeriod {
        return periodType
            ? {
                  type: periodType,
                  value: milliseconds / MS_IN_PERIOD[periodType]
              }
            : null;
    }

    private isCanConvertToEntirePeriod(milliseconds: number, period: TimePeriodType): boolean {
        return milliseconds % MS_IN_PERIOD[period] === 0;
    }
}
