import { DateTime } from 'luxon';

import { buildFormConfiguration, FormViewModel, TFormData } from '@pt-cybsi/shared';

export class ApiKeyFormViewModel extends FormViewModel<TApiKeyFormData, TApiKeyFormConfig> {}

const FormConfig = {
    id: {
        controlName: 'id',
        errorNames: null
    },
    description: {
        controlName: 'description',
        errorNames: null
    },
    expiresAt: {
        controlName: 'expiresAt',
        errorNames: {
            required: 'required',
            min: 'min'
        }
    },
    isPerpetual: {
        controlName: 'isPerpetual',
        errorNames: null
    },
    permissions: {
        controlName: 'permissions',
        errorNames: {
            required: 'required'
        }
    },
    isInheritPermissions: {
        controlName: 'isInheritPermissions',
        errorNames: null
    }
};

export type TApiKeyFormData = TFormData<{
    description: string;
    expiresAt: DateTime;
    isPerpetual: boolean;
    permissions: string[];
    isInheritPermissions: boolean;
}>;

export type TApiKeyFormConfig = typeof FormConfig;

export const ApiKeyFormConfig = buildFormConfiguration<TApiKeyFormData, TApiKeyFormConfig>(FormConfig);
