import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';

import {
    IDataSourceCreateParams,
    IDataSourceUpdateParams,
    TEditableDataSourceResponse
} from '@pt-cybsi/api-interfaces';
import { TFormData } from '@pt-cybsi/shared';

import { SourceModel, SourceTypeModel } from '../models';

export class SourceModelMapper {
    static toFormData(model: SourceModel): TSourceFormData {
        return {
            id: model.uid,
            isAnalyser: model.isAnalyser,
            isExternalDB: model.isExternalDB,
            isMatchWithTypeConfidence: model.isMatchWithTypeConfidence,
            ...model.props
        };
    }

    static fromFormData(data: TSourceFormData): SourceModel {
        const modelProps = omit(data, ['isAnalyser', 'isExternalDB', 'isMatchWithTypeConfidence']);

        return SourceModel.create(modelProps, data.id);
    }

    static fromEditableApiData(source: TEditableDataSourceResponse): SourceModel {
        const { data, eTag } = source;

        return SourceModel.create({
            serverId: data.uuid,
            eTag,
            typeId: null,
            analyserId: null,
            externalDBId: null,
            name: data.longName,
            shortName: data.name,
            confidence: data.manualConfidence
        });
    }

    static toRegisterParams(sourceModel: SourceModel, sourceTypeModel: SourceTypeModel): IDataSourceCreateParams {
        const sourceTypeProps = sourceTypeModel.props;
        const sourceProps = sourceModel.props;

        const hasConfidence = !isNil(sourceProps.confidence);

        return {
            typeUUID: sourceTypeProps.serverId,
            longName: sourceProps.name,
            name: sourceProps.shortName,
            ...(hasConfidence ? { manualConfidence: sourceProps.confidence } : {})
        };
    }

    static toUpdateParams(model: SourceModel): IDataSourceUpdateParams {
        const { name: longName, confidence: manualConfidence } = model.props;

        return {
            ...(isEmpty(longName) ? {} : { longName }),
            ...(isUndefined(manualConfidence) ? {} : { manualConfidence })
        };
    }
}

export type TSourceFormData = TFormData<{
    id: string;
    typeId: string;
    analyserId: string;
    externalDBId: string;
    serverId: string;
    eTag: string;
    name: string;
    shortName: string;
    confidence: number;
    isAnalyser: boolean;
    isExternalDB: boolean;
    isMatchWithTypeConfidence: boolean;
}>;
