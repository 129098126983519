import { Injectable } from '@angular/core';

import { IAuthProvider, IAuthSession, ISignInParams } from '@pt-cybsi/api-interfaces';

import { BaseApiService } from './base-api.service';

@Injectable()
export class AuthApiService extends BaseApiService {
    static readonly urls = {
        providers: 'auth/providers',
        session: 'auth/session'
    };

    getProviders() {
        return this.get<IAuthProvider[]>(AuthApiService.urls.providers);
    }

    getSession() {
        return this.get<IAuthSession>(AuthApiService.urls.session);
    }

    signIn(params: ISignInParams) {
        return this.put<IAuthSession>(AuthApiService.urls.session, params);
    }

    signOut() {
        return this.delete<IAuthSession>(AuthApiService.urls.session);
    }
}
