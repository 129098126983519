import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
    AfterViewChecked
} from '@angular/core';
import { Options, Point } from 'highcharts';

import * as Tokens from '../../../../../../apps/cybsi-web/src/styles/default-theme/tokens';

export interface IDonutChartItem {
    y: number;
    meta: {
        name: string;
        count: number;
        originalPercentage: number;
    };
}

const pieColors = [
    Tokens.PaletteBlue600,
    Tokens.PaletteBlue560,
    Tokens.PaletteBlue500,
    Tokens.PaletteBlue400,
    Tokens.PaletteBlue300,
    Tokens.PaletteBlue200,
    Tokens.PaletteBlue100,
    Tokens.PaletteBlue60
];

@Component({
    selector: 'donut-chart',
    templateUrl: './donut-chart.component.html',
    styleUrls: ['./donut-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonutChartComponent implements AfterViewInit, AfterViewChecked {
    config: Options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            marginBottom: 32
        },
        title: {
            text: null
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            pie: {
                innerSize: '82%',
                colors: pieColors,
                center: ['50%', '50%'],
                size: '80%',
                showInLegend: false,
                borderWidth: 4,
                states: {
                    hover: {
                        enabled: false
                    }
                },
                dataLabels: {
                    useHTML: false,
                    connectorColor: Tokens.LightColorSchemeForegroundBorder,
                    softConnector: false,
                    distance: 50,
                    formatter() {
                        const point = this.point as Point & IDonutChartItem;
                        const { name, count, originalPercentage } = point.meta;
                        const percentage = originalPercentage < 1 ? '< 1%' : `${Math.floor(originalPercentage)}%`;

                        //С выключенным флагом 'useHTML' следует шаблон писать в одну строку,
                        // т.к. учитываются любые переносы строк в коде, что приводит к смещению контента.
                        return `
                            <span>${name}</span><br><span style="color: ${Tokens.LightColorSchemeForegroundTextLessContrast}">${percentage} (${count})</span>
                        `.trim();
                    },
                    style: {
                        color: Tokens.LightColorSchemeForegroundText,
                        fontSize: Tokens.TypographySmallTextFontSize,
                        fontWeight: 'normal',
                        textOutline: 'none',
                        fontFamily: Tokens.FontFamilyBase,
                        textShadow: 'none',
                        textDecoration: 'none'
                    }
                }
            },
            series: {
                states: {
                    hover: {
                        enabled: false
                    },
                    inactive: {
                        opacity: 1
                    }
                }
            }
        },
        tooltip: {
            enabled: true,
            useHTML: true,
            formatter() {
                const point = this.point as Point & IDonutChartItem;
                const { name, count, originalPercentage } = point.meta;
                const percentage = originalPercentage < 1 ? '< 1%' : `${Math.floor(originalPercentage)}%`;

                return `
                    <span>${name}</span>
                    <br>
                    <span style="color: ${Tokens.LightColorSchemeForegroundTextLessContrast}">
                        ${percentage} (${count})
                    </span>
                `.trim();
            },
            borderWidth: 0,
            backgroundColor: Tokens.LightColorSchemeForegroundText,
            borderRadius: 4,
            shadow: true,
            style: {
                opacity: 1,
                color: Tokens.LightColorSchemeBackgroundBackground
            }
        },
        series: []
    };

    @Input() title: string;

    @Input() set data(data: IDonutChartItem[]) {
        if (data) {
            this.config = {
                ...this.config,
                series: [
                    {
                        type: 'pie',
                        name: 'Share',
                        data,
                        innerSize: '82%'
                    }
                ]
            };
        }
    }

    @Output() titleClick = new EventEmitter<void>();

    ngAfterViewInit() {
        this.hideChartDefaultDataLabelTitle();
    }

    ngAfterViewChecked() {
        this.hideChartDefaultDataLabelTitle();
    }

    handleTitleClick(): void {
        this.titleClick.emit();
    }

    private hideChartDefaultDataLabelTitle(): void {
        document.querySelectorAll('.highcharts-data-label title').forEach((t) => (t.innerHTML = ''));
    }
}
