<div class="layout-row layout-align-start-center mc-alert mc-alert_small"
     [ngClass]="cssClassOfType"
     [class.mc-alert_dismissible]="params.closable">
    <i *ngIf="params.icon"
       mc-icon class="mc-alert__icon"
       [ngClass]="params.icon">
    </i>
    <span>{{ params.text }}</span>
    <button *ngIf="params.closable" class="mc-alert__close" (click)="handleClickClose()">
        <i class="mc-icon_light" mc-icon="mc-close-M_16" [color]="'second'"></i>
    </button>
</div>
