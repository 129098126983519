import { Inject, Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import { IUser } from '@pt-cybsi/api-interfaces';

import { LEGACY_FRAME_PROVIDER_TOKEN, LegacyFrameProvider } from '../tokens/legacy-frame.token';
import {
    AllowedMessage,
    ICommonData,
    IDispatchAction,
    IDispatchActionMessage,
    IMessageEventData,
    MessageType
} from '../types/message';

@Injectable({ providedIn: 'root' })
export class LegacyCommunicationService {
    childAppReady$: Observable<boolean>;
    childAppBackdrop$: Observable<boolean>;
    openConfirmLeavePageModal$: Observable<void>;
    openSessionExpiredWarning$: Observable<void>;
    reloadPage$: Observable<void>;
    setEntryUrl$: Observable<string>;
    dispatchAction$: Observable<IDispatchAction>;

    private allowedIncomingMessageTypes: readonly MessageType[] = [
        MessageType.ChildAppReady,
        MessageType.ChildAppBackdrop,
        MessageType.OpenConfirmLeavePageModal,
        MessageType.OpenSessionExpiredWarning,
        MessageType.ReloadPage,
        MessageType.SetEntryUrl,
        MessageType.DispatchAction
    ];

    constructor(
        private window: Window,
        @Inject(LEGACY_FRAME_PROVIDER_TOKEN) private legacyFrameProvider: LegacyFrameProvider
    ) {
        const legacyFrameMessage$: Observable<AllowedMessage> = fromEvent<MessageEvent>(this.window, 'message').pipe(
            map((messageEvent: MessageEvent) => messageEvent.data as IMessageEventData<unknown>),
            filter((message: IMessageEventData<unknown>) => this.allowedIncomingMessageTypes.includes(message?.type)),
            map((message: IMessageEventData<unknown>) => message as AllowedMessage),
            shareReplay({ bufferSize: 1, refCount: true })
        );

        this.childAppReady$ = legacyFrameMessage$.pipe(
            filter((message: AllowedMessage) => message.type === MessageType.ChildAppReady),
            map((message: AllowedMessage) => message.payload as boolean)
        );

        this.childAppBackdrop$ = legacyFrameMessage$.pipe(
            filter((message: AllowedMessage) => message.type === MessageType.ChildAppBackdrop),
            map((message: AllowedMessage) => message.payload as boolean)
        );

        this.openConfirmLeavePageModal$ = legacyFrameMessage$.pipe(
            filter((message: AllowedMessage) => message.type === MessageType.OpenConfirmLeavePageModal),
            map((): void => null)
        );

        this.openSessionExpiredWarning$ = legacyFrameMessage$.pipe(
            filter((message: AllowedMessage) => message.type === MessageType.OpenSessionExpiredWarning),
            map((): void => null)
        );

        this.setEntryUrl$ = legacyFrameMessage$.pipe(
            filter((message: AllowedMessage) => message.type === MessageType.SetEntryUrl),
            map((message: AllowedMessage) => message.payload as string)
        );

        this.reloadPage$ = legacyFrameMessage$.pipe(
            filter((message: AllowedMessage) => message.type === MessageType.ReloadPage),
            map((): void => null)
        );

        this.dispatchAction$ = legacyFrameMessage$.pipe(
            filter((message: AllowedMessage) => message.type === MessageType.DispatchAction),
            map((message: IDispatchActionMessage) => message.payload)
        );
    }

    sendDispatchActionMessageToChild(action: IDispatchAction): void {
        const message: IDispatchActionMessage = {
            type: MessageType.DispatchAction,
            payload: {
                ...action,
                from: 'newApp'
            }
        };

        this.postMessageToChild(message);
    }

    sendSetToken(token: string): void {
        this.postMessageToChild({
            type: MessageType.SetToken,
            payload: token
        });
    }

    sendSetUser(user: IUser): void {
        this.postMessageToChild({
            type: MessageType.SetUser,
            payload: user
        });
    }

    sendSetCommonData(commonData: ICommonData): void {
        this.postMessageToChild({
            type: MessageType.SetCommonData,
            payload: commonData
        });
    }

    sendFirstAvailablePageUrl(url: string): void {
        this.postMessageToChild({
            type: MessageType.SetFirstAvailablePageUrl,
            payload: url
        });
    }

    sendConfirmationLeavePageResult(result: boolean) {
        this.postMessageToChild({
            type: MessageType.ConfirmLeavePage,
            payload: result
        });
    }

    sendLeavePage() {
        this.postMessageToChild({ type: MessageType.LeavePage });
    }

    private postMessageToChild(message: AllowedMessage): void {
        this.legacyFrameProvider()?.contentWindow.postMessage(message, '*');
    }
}
