import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { AuthApiService } from '@pt-cybsi/api';
import { HttpStatusCode } from '@pt-cybsi/api-interfaces';
import { AccountFacade } from '@pt-cybsi/store/account';

import { addToken } from '../utils';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    isTokenRefreshing: boolean;

    constructor(private accountFacade: AccountFacade) {
        this.accountFacade.isTokenRefreshing$.subscribe((isTokenRefreshing) => {
            this.isTokenRefreshing = isTokenRefreshing;
        });
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((error: unknown) => {
                const isSignInRequest = req.method === 'PUT' && req.url.includes(AuthApiService.urls.session);
                const isUnauthorizedRequest =
                    error instanceof HttpErrorResponse && error.status === HttpStatusCode.UNAUTHORIZED;

                if (isUnauthorizedRequest && !isSignInRequest) {
                    return this.refreshTokenAndRetry(req, error, next);
                }

                return throwError(error);
            })
        );
    }

    private refreshTokenAndRetry(
        req: HttpRequest<unknown>,
        error: HttpErrorResponse,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (!this.isTokenRefreshing) {
            this.accountFacade.refreshToken();
        }

        const isTokenRefreshing$ = this.accountFacade.isTokenRefreshing$.pipe(
            filter((isTokenRefreshing) => !isTokenRefreshing),
            take(1)
        );

        const token$ = this.accountFacade.token$.pipe(take(1));

        return isTokenRefreshing$.pipe(
            switchMap(() => token$),
            switchMap((token) => {
                if (token) {
                    return next.handle(addToken(req, token));
                }

                return throwError(error);
            })
        );
    }
}
