import { ICreateApiKeyData, IUpdateApiKeyData } from '@pt-cybsi/api-interfaces';
import { TApiKeyFormData } from '@pt-cybsi/domain-core/users';

export class ApiKeyFormMapper {
    static toCreateParams(formData: TApiKeyFormData): ICreateApiKeyData {
        return {
            description: formData.description || '',
            expiresAt: formData.isPerpetual ? null : formData.expiresAt.toUTC().toISO(),
            permissions:
                formData.isInheritPermissions || formData.permissions.length === 0
                    ? null
                    : formData.permissions.filter((permission) => permission.split(':').length > 1)
        };
    }

    static toUpdateParams(formData: TApiKeyFormData): IUpdateApiKeyData {
        return {
            description: formData.description || ''
        };
    }
}
