import { Component, Input } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { DictionariesApiService } from '@pt-cybsi/api';
import { IDictionaryItem, IDictionaryItemBrief } from '@pt-cybsi/api-interfaces';
import { PermissionsService } from '@pt-cybsi/domain-core/account';
import { AsyncState, isAsyncStateFailure, isAsyncStateLoading, isAsyncStateSuccess } from '@pt-cybsi/shared';

import { DictionariesItemsNavigationService, DictionariesPermissionsService } from '../../services';

export enum DictionaryItemPreviewFormat {
    Link = 'Link',
    PseudoLink = 'PseudoLink'
}

@Component({
    selector: 'dictionary-item-preview',
    templateUrl: './dictionary-item-preview.component.html',
    styleUrls: ['./dictionary-item-preview.component.scss']
})
export class DictionaryItemPreviewComponent {
    @Input() dictionaryItem: IDictionaryItemBrief;
    @Input() format: DictionaryItemPreviewFormat;

    DictionaryItemPreviewFormat = DictionaryItemPreviewFormat;

    loadingDataState$ = new BehaviorSubject<AsyncState>(AsyncState.Idle);

    isLoadingData$ = this.loadingDataState$.pipe(map(isAsyncStateLoading));
    isSuccessLoadingData$ = this.loadingDataState$.pipe(map(isAsyncStateSuccess));
    isFailureLoadingData$ = this.loadingDataState$.pipe(map(isAsyncStateFailure));

    dictionaryItemData: IDictionaryItem;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    maxDisplayedSynonyms = 3;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    maxDescriptionLength = 200;

    get previewFormat(): DictionaryItemPreviewFormat {
        return this.format || this.defaultFormat;
    }

    get hasViewPermissions(): boolean {
        return this.permissionsService.hasAllPermissions(DictionariesPermissionsService.viewPermissions);
    }

    get isLinkFormat(): boolean {
        return this.hasViewPermissions && this.previewFormat === DictionaryItemPreviewFormat.Link;
    }

    get isPseudoLinkFormat(): boolean {
        return this.hasViewPermissions && this.previewFormat === DictionaryItemPreviewFormat.PseudoLink;
    }

    get isTextFormat(): boolean {
        return !this.isLinkFormat && !this.isPseudoLinkFormat;
    }

    get hasLoadedData(): boolean {
        return !!this.dictionaryItemData;
    }

    get description(): string {
        return this.dictionaryItemData?.description;
    }

    get synonyms(): IDictionaryItemBrief[] {
        return this.dictionaryItemData?.synonyms;
    }

    get hasDescription(): boolean {
        return !!this.description;
    }

    get hasSynonyms(): boolean {
        return this.synonyms && this.synonyms.length > 0;
    }

    get isTotalSynonymsTextVisible(): boolean {
        return this.synonyms.length > this.maxDisplayedSynonyms;
    }

    private defaultFormat: DictionaryItemPreviewFormat = DictionaryItemPreviewFormat.Link;

    private popoverDestroy$ = new Subject<void>();

    constructor(
        private permissionsService: PermissionsService,
        private dictionariesApiService: DictionariesApiService,
        private dictionariesItemsNavigationService: DictionariesItemsNavigationService
    ) {}

    handlePopoverVisibleChange(isOpened: boolean): void {
        if (isOpened) {
            this.loadDictionaryItemData();
        } else {
            this.cancelLoadDictionaryItemData();
        }
    }

    handleRetryLoadData(): void {
        this.loadDictionaryItemData();
    }

    getDictionaryItemEditRoute(uuid: string): string {
        return this.dictionariesItemsNavigationService.getPathOfViewRoute(uuid);
    }

    private loadDictionaryItemData(): void {
        this.loadingDataState$.next(AsyncState.Loading);

        this.dictionariesApiService
            .getDictionaryItem(this.dictionaryItem.uuid)
            .pipe(take(1), takeUntil(this.popoverDestroy$))
            .subscribe({
                next: (dictionaryItem) => {
                    const description = dictionaryItem.description;

                    this.dictionaryItemData = {
                        ...dictionaryItem,
                        description:
                            description?.length > this.maxDescriptionLength
                                ? `${description.slice(0, this.maxDescriptionLength)}...`
                                : description
                    };

                    this.loadingDataState$.next(AsyncState.Success);
                },
                error: () => this.loadingDataState$.next(AsyncState.Failure)
            });
    }

    private cancelLoadDictionaryItemData(): void {
        this.popoverDestroy$.next();
        this.loadingDataState$.next(AsyncState.Idle);

        this.dictionaryItemData = null;
    }
}
