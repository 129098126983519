import { IDataSource, IDataSourceType } from '@pt-cybsi/api-interfaces';

import { createDataSourceTypeMock, defaultDataSourceType } from './data-source-type.mock';

export const createDataSourceMock = (
    id: number | string = 0,
    typeProps: Partial<IDataSourceType> = defaultDataSourceType
): IDataSource => ({
    uuid: createMockDataSourceId(id),
    url: `url-${id}`,
    name: `name-${id}`,
    longName: `long-name-${id}`,
    uniqueName: `unique-name-${id}`,
    confidence: 0.9,
    manualConfidence: 0.9,
    type: createDataSourceTypeMock(typeProps)
});

export const createMockDataSourceId = (id: number | string = 0) => `uuid-${id}`;
