import { IDictionary, IDictionaryItem, IDictionaryItemBrief } from '@pt-cybsi/api-interfaces';
import { AsyncState } from '@pt-cybsi/shared';

import { IDictionaryItemsGridItem } from '../components';
import { dictionaryItemSortComparator } from '../helpers';
import { IDictionaryItemView } from '../types';

export interface IDeletableSynonym extends IDictionaryItemBrief {
    state: AsyncState;
}

export class DictionaryItemMapper {
    static toDeletableSynonyms(dictionaryItem: IDictionaryItem): IDeletableSynonym[] {
        return dictionaryItem.synonyms.sort(dictionaryItemSortComparator).map((synonym) => ({
            uuid: synonym.uuid,
            key: synonym.key,
            state: AsyncState.Idle
        }));
    }

    static toDictionaryItemView(dictionaryItem: IDictionaryItem, dictionary: IDictionary): IDictionaryItemView {
        return {
            uuid: dictionaryItem.uuid,
            name: dictionaryItem.key,
            dictionaryId: dictionary.uuid,
            dictionaryName: dictionary.name,
            isClosedDictionary: dictionary.isClosed,
            description: dictionaryItem.description,
            synonyms: dictionaryItem.synonyms.sort(dictionaryItemSortComparator)
        };
    }

    static toDictionaryItemsGridFormat(dictionaryItems: IDictionaryItemBrief[]): IDictionaryItemsGridItem[] {
        return dictionaryItems.map((dictionaryItem) => this.toDictionaryItemGridFormat(dictionaryItem));
    }

    static toDictionaryItemGridFormat(dictionaryItem: IDictionaryItemBrief): IDictionaryItemsGridItem {
        return {
            id: dictionaryItem.uuid,
            key: dictionaryItem.key
        };
    }
}
