import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface IDropdownFilterItem<ValueType> {
    name: string;
    value: ValueType;
}

export type TCommonDropdownFilterItem = IDropdownFilterItem<string>;

/**
 * @component Dropdown Filter
 *
 * @description
 * Used for creating a filters-panel filter item for filtering data by single.
 *
 * @param title - title of filter, displayed in filter-button as main title
 * @param valueTitle - title of applied filter value
 * @param appliedValue - list of applied filter items in `IDropdownFilterItem[]` format
 * @param dropdownItems - list of available filter items in `IDropdownFilterItem[]` format, displayed in a dropdown list
 * @param applyFilter - event, emitted after clicking apply filter button
 * @param openFilter - event, emitted after open popover
 * @param closeFilter - event, emitted after close popover
 *
 * @example
 * ```html
 * <dropdown-filter
 *     [title]="title"
 *     [valueTitle]="valueTitle"
 *     [appliedValue]="appliedValue"
 *     [dropdownItems]="dropdownItems"
 *
 *     (applyFilter)="handleApply($event)"
 *     (openFilter)="handleOpen()"
 *     (closeFilter)="handleClose()">
 * </dropdown-filter>
 * ```
 */
@Component({
    selector: 'dropdown-filter',
    templateUrl: './dropdown-filter.component.html'
})
export class DropdownFilterComponent<T> {
    @Input() title: string;
    @Input() valueTitle: string;
    @Input() appliedValue: IDropdownFilterItem<T>;
    @Input() dropdownItems: IDropdownFilterItem<T>[];

    @Output() applyFilter: EventEmitter<IDropdownFilterItem<T>> = new EventEmitter<IDropdownFilterItem<T>>();
    @Output() openFilter: EventEmitter<void> = new EventEmitter<void>();
    @Output() closeFilter: EventEmitter<void> = new EventEmitter<void>();

    isDropdownClosed = true;

    handleSelectItem(item: IDropdownFilterItem<T>) {
        this.applyFilter.emit(item);
    }

    handleCloseDropdown() {
        this.isDropdownClosed = true;

        this.closeFilter.emit();
    }

    handleOpenDropdown() {
        this.isDropdownClosed = false;

        this.openFilter.emit();
    }
}
