import { Observable, of } from 'rxjs';

import { IObservableUseCase, IUseCase } from '../types';

export class FakeUseCase<Params, Result = void> implements IUseCase<Params, Result> {
    execute(params: Params): Result {
        return;
    }
}

export class FakeObservableUseCase<Params, Result = void> implements IObservableUseCase<Params, Result> {
    execute(params: Params): Observable<Result> {
        return of();
    }
}
