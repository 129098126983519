import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { McFormFieldModule } from '@ptsecurity/mosaic/form-field';

import { SharedModule } from '@pt-cybsi/shared';
import { StoreLicenseModule } from '@pt-cybsi/store/license';

import { LicenseFormComponent, LicenseViewStateComponent } from './components';
import { TranslateLicenseViewStatePipe } from './pipes';

// prettier-ignore
const modules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    McFormFieldModule,
    StoreLicenseModule,
    SharedModule
];

// prettier-ignore
const pipes = [
    TranslateLicenseViewStatePipe,
];

// prettier-ignore
const components = [
    LicenseFormComponent,
    LicenseViewStateComponent,
];

@NgModule({
    imports: [...modules],
    declarations: [...components, ...pipes],
    providers: [...pipes],
    exports: [...components]
})
export class DomainCoreLicenseModule {}
