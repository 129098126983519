import {
    AfterContentInit,
    Component,
    Directive,
    Inject,
    Input,
    OnInit,
    Optional,
    Self,
    TemplateRef
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    FormGroupDirective,
    NgControl,
    NgForm
} from '@angular/forms';
import { McValidationOptions, MC_VALIDATION, toBoolean } from '@ptsecurity/mosaic/core';
import { McFormFieldControl, McValidateDirective } from '@ptsecurity/mosaic/form-field';
import { takeUntil } from 'rxjs/operators';

import { DestroyService } from '../../services';

let nextUniqueId = 0;

/**
 * @component CheckboxGroupField
 *
 * @description
 * Form field for selecting list of values in checkbox view
 *
 * @param id - Unique id of field. Optional, will be generated automatically
 * @param items - list of available string values
 * @param checkboxTemplate - TemplateRef of checkbox item
 * @param ngModel - list of selected string values
 * @param disabled - Boolean value for marking the field as disabled. Optional, will be used `false`
 * @param required - Boolean value for marking the field as required. Optional, will be used `false`
 *
 * @example
 * ```html
 * <checkbox-group-field
 *      [items]="availableItems"
 *      [checkboxTemplate]="checkboxTemplate"
 *      [(ngModel)]="value">
 * </checkbox-group-field>
 *
 * <ng-template #checkboxTemplate let-control="checkboxControl" let-data="data">
 *      <mc-checkbox [formControl]="control">{{ data }}</mc-checkbox>
 * </ng-template>
 *
 * <checkbox-group-field
 *      [items]="availableItems"
 *      [checkboxTemplate]="checkboxTemplate"
 *      [formControl]="formControl">
 *   <mc-hint>Caption or validation error</mc-hint>
 * </checkbox-group-field>
 *
 * <ng-template #checkboxTemplate let-control="checkboxControl" let-data="data">
 *      <mc-checkbox [formControl]="control">{{ data }}</mc-checkbox>
 * </ng-template>
 * ```
 */
@Component({
    selector: 'checkbox-group-field',
    templateUrl: './checkbox-group-field.component.html',
    styleUrls: ['./checkbox-group-field.component.scss'],
    providers: [
        {
            provide: McFormFieldControl,
            useExisting: CheckboxGroupFieldComponent
        },
        DestroyService
    ],
    host: {
        class: 'checkbox-group-field'
    }
})
export class CheckboxGroupFieldComponent implements ControlValueAccessor, OnInit {
    @Input() items: string[];
    @Input() checkboxTemplate: TemplateRef<unknown>;

    @Input()
    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value || this.uid;
    }

    @Input()
    get value(): string[] {
        return this._value;
    }

    set value(value: string[]) {
        this._value = value;

        this.updateCheckboxes();
        this.onChanged(this._value);
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        if (value !== this.required) {
            this._required = toBoolean(value);
        }
    }

    @Input()
    get disabled() {
        return this._disabled;
    }

    set disabled(value: boolean) {
        if (value !== this.disabled) {
            this._disabled = toBoolean(value);

            if (value) {
                this.checkboxesForm?.disable({ emitEvent: false });
            } else {
                this.checkboxesForm?.enable({ emitEvent: false });
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: () => void = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChanged: (value: string[]) => void = () => {};

    controlType = 'checkbox-group-field';

    private _id: string;
    private _value: string[];
    private _required: boolean;
    private _disabled = false;

    private readonly uid = `${this.controlType}-${nextUniqueId++}`;

    private checkboxesForm: UntypedFormGroup;

    constructor(
        @Optional() public parentForm: NgForm,
        @Optional() public parentFormGroup: FormGroupDirective,
        @Self() public ngControl: NgControl,
        private fb: UntypedFormBuilder,
        private destroyed$: DestroyService
    ) {
        this.ngControl.valueAccessor = this;

        // eslint-disable-next-line no-self-assign
        this.id = this.id;
    }

    ngOnInit(): void {
        this.checkboxesForm = this.buildCheckboxesForm();

        this.startSyncCheckboxesWithValue();
    }

    getCheckboxControl(item: string): AbstractControl {
        return this.checkboxesForm?.get(item);
    }

    writeValue(value: string[]): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string[]) => void): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private buildCheckboxesForm(): UntypedFormGroup {
        const formGroup = this.fb.group({});

        const selectedItems = this.value || [];

        this.items.forEach((item) => {
            const isSelectedItem = selectedItems.indexOf(item) !== -1;

            formGroup.addControl(item, new UntypedFormControl(isSelectedItem));
        });

        if (this.disabled) {
            formGroup.disable();
        }

        return formGroup;
    }

    private startSyncCheckboxesWithValue(): void {
        this.checkboxesForm.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((itemsStates: Record<string, boolean>) => {
                const selectedTypes = Object.keys(itemsStates).filter((item) => itemsStates[item]);

                this.value = selectedTypes.length ? selectedTypes : null;

                this.onTouched();
            });
    }

    private updateCheckboxes(): void {
        const selectedItems = this.value || [];

        this.items.forEach((item) => {
            const isSelectedItem = selectedItems.indexOf(item) !== -1;

            const control = this.getCheckboxControl(item);

            if (control && control.value !== isSelectedItem) {
                control.setValue(isSelectedItem);
            }
        });
    }
}

@Directive({
    selector: 'checkbox-group-field',
    exportAs: 'CheckboxGroupFieldMcValidate'
})
export class CheckboxGroupFieldMcValidateDirective extends McValidateDirective {}
