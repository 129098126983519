import { IFilterInfo } from '../attribute-search';

export interface IFeed {
    id: string;
    name: string;
    shortName: string;
    refreshPeriod: number;
    refreshedAt: string;
    versions: IFeedVersion[];
    filter: IFilterInfo;
}

export type TFeedBrief = Omit<IFeed, 'filter'>;

export interface IFeedVersion {
    // eslint-disable-next-line id-blacklist
    number: number;
    state: FeedVersionState;
    schemaID: string;
    formats: string[];
    comment?: string;
}

export interface IFeedSchema {
    type: string;
    attributes: {
        name: string;
        group?: string;
    }[];
    relations?: {
        name: string;
        objects: string[];
    }[];
}

export interface ICreateFeedParams extends Omit<IFeed, 'id' | 'refreshedAt' | 'versions'> {
    activated: boolean;
    formats: string[];
}

export interface IUpdateFeedParams {
    refreshPeriod: number;
}

export interface IUpdateFeedVersionParams {
    comment: string;
    activated: boolean;
}

export enum FeedVersionState {
    Draft = 'Draft',
    Activated = 'Activated',
    // Disactivated can't be fixed to Deactivated because of APIv1
    Disactivated = 'Disactivated'
}
