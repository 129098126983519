import { EnrichmentType, EnrichmentTriggerType, ArtifactType, ObservableEntityType } from '@pt-cybsi/api-interfaces';

export interface IEnrichmentRuleMeta {
    type: EnrichmentType;
    triggerTypes: EnrichmentTriggerType[];
    artifactTypes: ArtifactType[];
    entityTypes: ObservableEntityType[];
    /**
     * Milliseconds of throttling interval
     */
    throttlingInterval: number | null;
    dataSources: string[];
    triggerDataSources: string[];
}
