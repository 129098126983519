import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import isEmpty from 'lodash/isEmpty';

import { EnrichmentType, IEnrichmentRulesGetParams } from '@pt-cybsi/api-interfaces';

export type IEnrichmentRulesFilterParams = Partial<
    Pick<
        IEnrichmentRulesGetParams,
        | 'dataSourceUUID'
        | 'triggerDataSourceUUID'
        | 'enrichmentType'
        | 'entityType'
        | 'artifactType'
        | 'triggerType'
        | 'isDisabled'
        | 'name'
    >
>;

export interface ICreateRulePageQueryParams {
    enrichmentType: EnrichmentType;
    sourceId: string;
}

@Injectable({ providedIn: 'root' })
export class EnrichmentRulesNavigationService {
    static routes = {
        list: '',
        create: 'create',
        view: 'view/:ruleId',
        edit: 'edit/:ruleId'
    };

    readonly root = '/enrichment-rules';

    constructor(private router: Router) {}

    goToListPage(queryParams?: IEnrichmentRulesFilterParams): void {
        const path = this.getPathOfListRoute();
        const hasQueryParams = !isEmpty(queryParams);

        const extras: NavigationExtras = hasQueryParams ? { queryParams } : undefined;

        this.router.navigate([path], extras);
    }

    goToViewPage(ruleId: string): void {
        const path = this.getPathOfViewRoute(ruleId);

        this.router.navigate([path]);
    }

    goToCreatePage(params?: ICreateRulePageQueryParams): void {
        const path = this.getPathOfCreateRoute();

        this.router.navigate([path], { queryParams: params || {} });
    }

    getPathOfListRoute(): string {
        return `${this.root}/${EnrichmentRulesNavigationService.routes.list}`;
    }

    getPathOfCreateRoute(): string {
        return `${this.root}/${EnrichmentRulesNavigationService.routes.create}`;
    }

    getPathOfViewRoute(ruleId: string): string {
        return `${this.root}/${EnrichmentRulesNavigationService.routes.view.replace(':ruleId', ruleId)}`;
    }

    getPathOfEditRoute(ruleId: string): string {
        return `${this.root}/${EnrichmentRulesNavigationService.routes.edit.replace(':ruleId', ruleId)}`;
    }
}
