<list-filter [title]="title"
             [valueTitle]="valueTitle$ | async | arrayAsString"
             [appliedItems]="appliedValues$ | async"
             [availableItems]="availableItems"
             [state]="filterState"
             [itemTemplate]="itemTemplate"

             (applyFilter)="handleApply($event)"
             (openFilter)="handleOpen()"
             (resetFilter)="handleReset()"
></list-filter>

<ng-template #itemTemplate let-item="item">
    <span>{{ item.value | translateEnrichmentTriggerType }}</span>
</ng-template>
