import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { McSidepanelPosition, McSidepanelService } from '@ptsecurity/mosaic/sidepanel';

import { CollectionListStyle } from '../../types';
import {
    ILabelsCollectionSidepanelData,
    LabelsCollectionSidepanelComponent
} from '../labels-collection-sidepanel/labels-collection-sidepanel.component';

const DEFAULT_LIMIT = 10;
const DEFAULT_RENDER_STYLE = CollectionListStyle.GRID_ROW;

/**
 * @component LabelsCollection
 *
 * @description
 * Displays a collection of labels
 *
 * @param collection - Collection of labels.
 * @param collectionTitle - Title that wil be displayed in sidepanel.
 * @param itemTemplate - Item template for collection and sidepanel. There is default component for that template - `labels-collection-item`
 * @param limit - Max amount of items displayed, rest will be shown in sidepanel. By default, only 10 items are displayed.
 * @param renderStyle - CollectionListStyle value for changing collection display style.
 * @param editable - Turn on/off collection editable mode.
 * @param edit - event, emitted by click on edit button.
 *
 *
 * @example
 *
 * ```html
 * <ng-template #itemTemplate let-value="item">
 *      <a class="layout-row" href="#">
 *          <labels-collection-item [value]="value"></labels-collection-item>
 *      </a>
 * </ng-template>
 *
 * <limited-collection [collection]="collection"
 *                     [collectionTitle]="collectionTitle"
 *                     [editable]="true"
 *                     [itemTemplate]="itemTemplate"
 *                     [limit]="limit"
 *                     (edit)="handleEdit()">
 * </limited-collection>
 * ```
 */
@Component({
    selector: 'labels-collection',
    templateUrl: './labels-collection.component.html',
    styleUrls: ['./labels-collection.component.scss']
})
export class LabelsCollectionComponent {
    @Input() collection: string[];
    @Input() collectionTitle?: string;
    @Input() itemTemplate: TemplateRef<unknown>;
    @Input() renderStyle?: CollectionListStyle;
    @Input() limit?: number;
    @Input() editable?: boolean;

    @Output() edit = new EventEmitter<void>();

    @ViewChild('editButtonTemplate') editButtonTemplate: TemplateRef<unknown>;

    get collectionRenderStyle(): CollectionListStyle {
        return this.renderStyle || DEFAULT_RENDER_STYLE;
    }

    get collectionLimit(): number {
        return this.limit || DEFAULT_LIMIT;
    }

    get actionsTemplate(): TemplateRef<unknown> {
        return this.editable && this.editButtonTemplate ? this.editButtonTemplate : null;
    }

    get hasItems(): boolean {
        return this.collection.length > 0;
    }

    constructor(private sidepanelService: McSidepanelService) {}

    handleShowMore(): void {
        this.sidepanelService.open<LabelsCollectionSidepanelComponent, ILabelsCollectionSidepanelData>(
            LabelsCollectionSidepanelComponent,
            {
                data: {
                    collection: this.collection,
                    collectionTitle: this.collectionTitle,
                    itemTemplate: this.itemTemplate,
                    editable: this.editable,
                    edit: this.edit
                },
                requiredBackdrop: true,
                position: McSidepanelPosition.Left,
                overlayPanelClass: 'sidepanel'
            }
        );
    }
}
