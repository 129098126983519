<mc-sidepanel-header [closeable]="true" class="enrichment-rule__title">
    <page-content-header>
        <div page-content-header-title>
            <div class="page-content-header-title__base">
                {{ 'enrichment.Enrichment.Rule.Title.EnrichmentRule' | transloco }}
            </div>
            <div pt-title class="pt-text-overflow">
                {{ data.rule?.name }}
            </div>
        </div>
    </page-content-header>
</mc-sidepanel-header>
<mc-sidepanel-body class="padding-top_16">
    <enrichment-rule-meta
        *ngIf="data.rule"
        [meta]="metaInfo">
    </enrichment-rule-meta>
</mc-sidepanel-body>
<mc-sidepanel-footer class="enrichment-rule-sidepanel__footer">
    <mc-sidepanel-actions class="enrichment-rule-sidepanel__actions">
        <a mc-button
           color='primary'
           [routerLink]="enrichmentRuleLink">
            {{ 'enrichment.Enrichment.RulesSidepanel.Title.GoToRule' | transloco }}
        </a>
        <a *hasPermissions="editRulePermissions"
           class="margin-left_16"
           mc-button
           [routerLink]="editEnrichmentRuleLink">
            {{ 'common.Common.Pseudo.Button.Change' | transloco }}
        </a>
    </mc-sidepanel-actions>
</mc-sidepanel-footer>
