<mc-form-field class="time-period-field__value mc-group-item">
    <input
        #mcInput
        mcInput
        type="number"
        autocomplete="off"
        [formControl]="periodValueControl"
        [placeholder]="placeholder"
        (paste)='handleInputPaste($event)'
        (keydown)="handleInputKeyDown($event)"
        (focus)="handleFocusInput()"
        (blur)="handleBlurInput($event)">
</mc-form-field>
<mc-form-field class="time-period-field__period mc-group-item">
    <mc-select
        #mcSelect
        [formControl]="periodTypeControl"
        (focus)="handleFocusSelect()"
        (blur)="handleBlurSelect($event)"
        (openedChange)="handleOpenedChange()">
        <mc-option
            *ngFor="let period of periodTypeSelectItems"
            [value]="period.type">
            {{ period.label }}
        </mc-option>
        <mc-select-trigger>
            {{ translatePeriodType(periodType) }}
        </mc-select-trigger>
    </mc-select>
</mc-form-field>
