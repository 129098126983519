import { DefaultErrorCodes, IServerError } from '@pt-cybsi/api-interfaces';
import { buildFormConfiguration, FormViewModel, TFormData } from '@pt-cybsi/shared';

/**
 * @ViewModel SignInForm
 *
 * @description
 * Model of Angular Reactive Form with additional features for a sign-in form.
 * Contains extended information about a form state, a working mode, and a saving error.
 *
 * For creation FormViewModel need to create a factory `SignInFormBuilder`
 */
export class SignInFormViewModel extends FormViewModel<TSignInFormData, TSignInFormConfig> {
    setAuthProvider(providerId: string): void {
        this.updateCurrentData({ ...this.currentData, providerId });
    }

    updateSavingError(error: IServerError): void {
        super.updateSavingError(error);

        if (this.savingError?.code === DefaultErrorCodes.UNAUTHORIZED) {
            this.setError('login', 'unknown');
            this.setError('password', 'unknown');
        }
    }
}

const FormConfig = {
    id: {
        controlName: 'id',
        errorNames: null
    },
    providerId: {
        controlName: 'providerId',
        errorNames: null
    },
    login: {
        controlName: 'login',
        errorNames: {
            required: 'required',
            unknown: 'unknown'
        }
    },
    password: {
        controlName: 'password',
        errorNames: {
            required: 'required',
            unknown: 'unknown'
        }
    },
    rememberMe: {
        controlName: 'rememberMe',
        errorNames: null
    }
} as const;

export type TSignInFormConfig = typeof FormConfig;

export const SignInFormConfig = buildFormConfiguration<TSignInFormData, TSignInFormConfig>(FormConfig);

export type TSignInFormData = TFormData<{
    providerId: string;
    login: string;
    password: string;
    rememberMe: boolean;
}>;
