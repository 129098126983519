import { Directive, Host, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { takeUntil } from 'rxjs/operators';

import { AgGridDetailsComponent, IDetailsRendererParams, LazyGridComponent } from '../../components';
import { defaultGridOptions } from '../../helpers';
import { DestroyService } from '../../services';

@Directive({
    selector: '[lazyGridAgGridDetails]',
    providers: [DestroyService],
    host: {
        class: 'lazy-grid-ag-grid-details'
    }
})
export class LazyGridAgGridDetailsDirective implements OnChanges {
    @Input('lazyGridAgGridDetails')
    detailsTemplate: TemplateRef<unknown>;

    @Input()
    agGridOptions: Partial<GridOptions>;

    @Input()
    columns: ColDef[];

    detailsParams: Partial<IDetailsRendererParams> = {
        template: null,
        getDetailsHeight: (root: HTMLElement) => {
            const gridViewport = root.querySelector<HTMLElement>('.ag-center-cols-viewport');

            return gridViewport?.offsetHeight || 0;
        }
    };

    gridOptions: GridOptions = {
        frameworkComponents: {
            detailsRenderComponent: AgGridDetailsComponent
        },
        masterDetail: true,
        isRowMaster: (dataItem) => !dataItem.isLoader,
        detailRowHeight: defaultGridOptions.rowHeight,
        getRowHeight: (params) =>
            params.node.rowHeight > defaultGridOptions.rowHeight ? params.node.rowHeight : defaultGridOptions.rowHeight,
        detailCellRenderer: 'detailsRenderComponent',
        detailCellRendererParams: {
            ...this.detailsParams
        }
    };

    constructor(@Host() private host: LazyGridComponent, private destroyed$: DestroyService) {
        this.updateGridOptions(this.gridOptions);
    }

    ngOnChanges(changes: SimpleChanges) {
        const isOptionsChanged =
            changes.agGridOptions?.currentValue &&
            changes.agGridOptions.currentValue !== changes.agGridOptions.previousValue;

        const isColumnsChanged =
            changes.columns?.currentValue && changes.columns.currentValue !== changes.columns.previousValue;

        const isDetailsTemplateChanged =
            changes.detailsTemplate?.currentValue &&
            changes.detailsTemplate.currentValue !== changes.detailsTemplate.previousValue;

        if (isOptionsChanged) {
            this.updateGridOptions(this.agGridOptions);
        }

        if (isDetailsTemplateChanged) {
            this.updateTemplate();
        }

        if (isColumnsChanged) {
            this.checkGroupColumnExisting();
        }
    }

    expandRows(rowIds: string[]): void {
        if (this.host.isAgGridInit) {
            this.expandAgGridRows(rowIds);
        } else {
            this.host.onGridInit$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.expandAgGridRows(rowIds));
        }
    }

    private expandAgGridRows(rowIds: string[]): void {
        setTimeout(() =>
            rowIds.forEach((rowId) => {
                const row = this.host.gridApi.getRowNode(rowId);

                if (!row.expanded) {
                    row.setExpanded(true);
                }
            })
        );
    }

    private updateGridOptions(options: Partial<GridOptions>) {
        this.gridOptions = {
            ...this.gridOptions,
            ...options,
            frameworkComponents: {
                ...this.gridOptions.frameworkComponents,
                ...options.frameworkComponents
            },
            detailCellRendererParams: {
                ...this.gridOptions.detailCellRendererParams,
                ...options.detailCellRendererParams
            }
        };

        this.host.updateGridOptions(this.gridOptions);
    }

    private updateTemplate() {
        const detailCellRendererParams: Partial<IDetailsRendererParams> = {
            template: this.detailsTemplate
        };

        this.updateGridOptions({ detailCellRendererParams });
    }

    private checkGroupColumnExisting(): void {
        const hasGroupColumn =
            (Array.isArray(this.columns) && this.columns.length === 0) ||
            this.columns.some((column) => column.cellRenderer === 'agGroupCellRenderer');

        if (!hasGroupColumn) {
            console.warn(
                "LazyGridDetails: For use row grouping, one of a column should be rendered with 'agGroupCellRenderer'"
            );
        }
    }
}
