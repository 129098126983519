import { IEntitiesAggregatesMap } from '@pt-cybsi/api';
import { TEntityFullInfoModel } from '@pt-cybsi/domain-core/observable-entities';

import { EntityFullInfoModel } from '../models';

export interface IEntitiesFullInfoModelMap {
    [entityId: string]: TEntityFullInfoModel;
}

export class EntitiesFullInfoMapMapper {
    static fromEntitiesAggregatesMap(entities: IEntitiesAggregatesMap): IEntitiesFullInfoModelMap {
        const entitiesFullInfoModels: IEntitiesFullInfoModelMap = {};

        Object.keys(entities).forEach((uuid) => {
            entitiesFullInfoModels[uuid] = EntityFullInfoModel.createFromRawData(entities[uuid]);
        });

        return entitiesFullInfoModels;
    }
}
