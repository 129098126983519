import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { translate } from '@ngneat/transloco';

import { IIdentityKey } from '@pt-cybsi/api-interfaces';

/**
 * @component IdentityKeys
 *
 * @description
 * Component for displaying identity natural keys
 *
 * @param keys - List of identity natural keys
 *
 * @example
 * ```html
 *
 * <identity-keys [keys]="keys"></identity-keys>
 *
 * ```
 */
@Component({
    selector: 'identity-keys',
    templateUrl: './identity-keys.component.html',
    styleUrls: ['./identity-keys.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdentityKeysComponent {
    @Input() keys: IIdentityKey[];

    get hasNicHandles(): boolean {
        return this.getNicHandleKeysValues().length > 0;
    }

    get hasIanaId(): boolean {
        return this.getIanaIdKeyValue() !== null;
    }

    get ianaIdKeyTitle(): string {
        return translate('entities.ObservableEntities.Identity.Text.IanaId');
    }

    get nicHandlesKeyTitle(): string {
        return translate('entities.ObservableEntities.Identity.Text.NicHandlesPlural', {
            count: this.getNicHandleKeysValues().length
        });
    }

    get nicHandlesCollectionTitle(): string {
        return translate('entities.ObservableEntities.Identity.Text.NicHandlesCount', {
            count: this.getNicHandleKeysValues().length
        });
    }

    getNicHandleKeysValues(): string[] {
        return this.keys.reduce<string[]>((values, key) => {
            if (key.type === 'NICHandle') {
                values.push(key.value);
            }

            return values;
        }, []);
    }

    getIanaIdKeyValue(): string {
        const ianaKey = this.keys.find((key) => key.type === 'IANAID');

        return ianaKey?.value || null;
    }
}
