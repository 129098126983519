import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component,
    Directive,
    DoCheck,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormControl,
    UntypedFormGroup,
    FormGroupDirective,
    NgControl,
    NgForm,
    ValidatorFn
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import {
    CanDisable,
    CanDisableCtor,
    CanUpdateErrorState,
    CanUpdateErrorStateCtor,
    ErrorStateMatcher,
    mixinDisabled,
    mixinErrorState
} from '@ptsecurity/mosaic/core';
import { McFormFieldControl, McValidateDirective } from '@ptsecurity/mosaic/form-field';
import { McInput } from '@ptsecurity/mosaic/input';
import { McSelect } from '@ptsecurity/mosaic/select';
import capitalize from 'lodash/capitalize';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { McFieldBase } from '../../helpers';
import { DestroyService, TimePeriodService, TimePeriodType } from '../../services';

type TTimePeriodFieldValue = number;

interface IPeriodTypeSelectItem {
    type: TimePeriodType;
    label: string;
}

let nextUniqueId = 0;

const TimePeriodFieldMixinBase: CanDisableCtor & CanUpdateErrorStateCtor & typeof McFieldBase = mixinDisabled(
    mixinErrorState(McFieldBase)
);

const createControlValidator =
    (context: TimePeriodFieldComponent): ValidatorFn =>
    () =>
        (context.parentForm?.submitted || context.ngControl?.touched) && context.ngControl?.invalid && !context.focused
            ? { timePeriodValue: true }
            : null;

/**
 * @component TimePeriodField
 *
 * @description
 * Form field for enter a time period. Works with milliseconds as input and output value
 * Can be used with ReactiveFormsModule. Examples of use can be found in Storybook
 *
 * @param id - Unique id of field. Optional, will be generated automatically
 * @param ngModel - Milliseconds of period.
 * @param periodType - Current selected period in select field. Optional, will be used first available period
 * @param availablePeriods - List of periods displayed in select field. Optional, will be used all available periods
 * @param placeholder - Placeholder text displayed inside input field. Optional, will be used empty text
 * @param disabled - Boolean value for marking the field as disabled. Optional, will be used `false`
 * @param required - Boolean value for marking the field as required. Optional, will be used `false`
 * @param periodTypeChanged - Event of period change handling in a select field
 *
 * @example
 * ```html
 * <mc-form-field class="form-field-group">
 *      <time-period-field [(ngModel)]="milliseconds"></time-period-field>
 * </mc-form-field>
 *
 * <mc-form-field class="form-field-group">
 *      <time-period-field [formControl]="timePeriodControl"></time-period-field>
 * </mc-form-field>
 *
 * <mc-form-field class="form-field-group">
 *      <time-period-field
 *          [id]="'some-id'"
 *          [periodType]="selectedPeriod"
 *          [availablePeriods]="availablePeriods"
 *          [placeholder]="'Enter a period value'"
 *          [(ngModel)]="milliseconds"
 *          [required]="true"
 *          [disabled]="true"
 *          (periodTypeChanged)="handlePeriodTypeChange($event)">
 *      </time-period-field>
 * </mc-form-field>
 * ```
 */
@Component({
    selector: 'time-period-field',
    templateUrl: './time-period-field.component.html',
    styleUrls: ['./time-period-field.component.scss'],
    providers: [DestroyService, { provide: McFormFieldControl, useExisting: TimePeriodFieldComponent }],
    host: {
        class: 'mc-group',
        '[attr.id]': 'id',
        '[attr.disabled]': 'disabled || null',
        '[attr.required]': 'required || null'
    }
})
export class TimePeriodFieldComponent
    extends TimePeriodFieldMixinBase
    implements
        McFormFieldControl<number>,
        ControlValueAccessor,
        OnInit,
        OnChanges,
        DoCheck,
        OnDestroy,
        CanDisable,
        CanUpdateErrorState
{
    @Input()
    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value || this.uid;
        this.stateChanges.next();
    }

    @Input()
    get availablePeriods(): TimePeriodType[] {
        return this._availablePeriods;
    }

    set availablePeriods(availablePeriods: TimePeriodType[]) {
        if (availablePeriods !== this.availablePeriods) {
            this._availablePeriods = availablePeriods;

            const isAvailablePeriod = this.availablePeriods.indexOf(this._periodType) !== -1;

            this.periodType = isAvailablePeriod ? this.periodType : availablePeriods[0];
        }
    }

    @Input()
    get periodType(): TimePeriodType {
        return this._periodType || this.availablePeriods[0];
    }

    set periodType(periodType: TimePeriodType) {
        if (periodType && periodType !== this.periodType) {
            const isAvailablePeriod = this.availablePeriods.indexOf(periodType) !== -1;

            this._periodType = isAvailablePeriod ? periodType : this.availablePeriods[0];

            this.stateChanges.next();
        }
    }

    @Input()
    get value(): TTimePeriodFieldValue {
        return this._value;
    }

    set value(value: TTimePeriodFieldValue) {
        if (value !== this.value) {
            this._value = value;

            this.updateControlsGroupValues();

            this.stateChanges.next();
        }
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);

        if (this.focused) {
            this.focused = false;
            this.stateChanges.next();
        }

        if (this.controlsGroup) {
            if (this._disabled) {
                this.controlsGroup.disable();
            } else {
                this.controlsGroup.enable();
            }
        }
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);

        this.stateChanges.next();
    }

    get focused(): boolean {
        return this.mcInput?.focused || this.mcSelect?.focused || this._focused;
    }

    set focused(value: boolean) {
        this._focused = coerceBooleanProperty(value);

        this.stateChanges.next();
    }

    get empty(): boolean {
        return this._value === null || this._value === undefined;
    }

    get isInvalid(): boolean {
        return this.ngControl.invalid;
    }

    @Input()
    placeholder: string;

    @Output()
    periodTypeChanged = new EventEmitter<TimePeriodType>();

    errorState: boolean;
    controlType = 'time-period-field';

    periodTypeControl = new UntypedFormControl(undefined, [createControlValidator(this)]);
    periodValueControl = new UntypedFormControl(undefined, [createControlValidator(this)]);

    onChange: (value: TTimePeriodFieldValue) => void;
    onTouched: () => void;

    periodTypeSelectItems: IPeriodTypeSelectItem[] = [];

    @ViewChild('mcSelect', { read: McSelect }) mcSelect: McSelect;
    @ViewChild('mcSelect', { read: ElementRef }) mcSelectRef: ElementRef<HTMLElement>;
    @ViewChild('mcInput', { read: McInput }) mcInput: McInput;
    @ViewChild('mcInput', { read: ElementRef }) mcInputRef: ElementRef<HTMLElement>;

    private _id: string;
    private _availablePeriods: TimePeriodType[] = [
        TimePeriodType.Seconds,
        TimePeriodType.Minutes,
        TimePeriodType.Hours
    ];
    private _value: TTimePeriodFieldValue;
    private _periodType: TimePeriodType = this._availablePeriods[0];
    private _disabled = false;
    private _focused = false;
    private _required = false;

    private readonly uid = `${this.controlType}-${nextUniqueId++}`;

    private controlsGroup: UntypedFormGroup = new UntypedFormGroup({
        periodType: this.periodTypeControl,
        periodValue: this.periodValueControl
    });

    private readonly controlsGroupChange$ = this.controlsGroup.valueChanges.pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
    );

    private readonly periodTypeChange$ = this.periodTypeControl.valueChanges.pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
    );

    private readonly periodValueChange$ = this.periodValueControl.valueChanges.pipe(
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
    );

    private readonly translatePeriodTypeKeys = {
        [TimePeriodType.Seconds]: 'common.Common.Pseudo.Text.PluralSeconds',
        [TimePeriodType.Minutes]: 'common.Common.Pseudo.Text.PluralMinutes',
        [TimePeriodType.Hours]: 'common.Common.Pseudo.Text.PluralHours'
    };

    constructor(
        public defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() public parentForm: NgForm,
        @Optional() public parentFormGroup: FormGroupDirective,
        @Self() public ngControl: NgControl,
        private translocoService: TranslocoService,
        private timePeriodService: TimePeriodService,
        private destroyed$: DestroyService
    ) {
        super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

        this.ngControl.valueAccessor = this;

        // eslint-disable-next-line no-self-assign
        this.id = this.id;
    }

    ngOnInit() {
        this.periodTypeChange$.subscribe((periodType: TimePeriodType) => {
            this.periodValueControl.updateValueAndValidity({ emitEvent: false });

            if (this.periodType !== periodType) {
                this.periodType = periodType;
                this.periodTypeChanged.emit(periodType);
            }
        });

        this.periodValueChange$.subscribe(() => {
            this.periodTypeControl.updateValueAndValidity({ emitEvent: false });
        });

        this.controlsGroupChange$.subscribe(() => {
            this.validateControl();

            this.value = this.getMilliseconds();

            this.onChange(this.value);
        });

        this.updatePeriodTypeSelectItems();
        this.updateControlsGroupValues();
    }

    ngOnChanges() {
        this.updateControlsState();

        this.stateChanges.next();
    }

    ngDoCheck() {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }

    handleInputPaste($event: ClipboardEvent): void {
        $event.preventDefault();

        const parsedValue = this.parseValue($event.clipboardData.getData('text'));

        this.value = this.timePeriodService.toMilliseconds({
            type: this.periodType,
            value: parsedValue
        });

        this.periodValueControl.setValue(parsedValue);
    }

    handleInputKeyDown($event: KeyboardEvent): void {
        if ($event.key === '.' || $event.key === ',') {
            $event.preventDefault();
        }
    }

    handleFocusInput(): void {
        this.focus();
    }

    handleFocusSelect(): void {
        this.focus();
    }

    handleBlurInput($event: FocusEvent): void {
        this.blur();

        const isMoveFocusToPeriodSelect = $event.relatedTarget === this.mcSelectRef.nativeElement;

        if (!isMoveFocusToPeriodSelect) {
            this.validateControl();
        }
    }

    handleBlurSelect($event): void {
        this.blur();

        const isMoveFocusToInput = $event.relatedTarget === this.mcInputRef.nativeElement;

        if (!isMoveFocusToInput) {
            this.validateControl();
        }
    }

    handleOpenedChange(): void {
        this.updatePeriodTypeSelectItems();
        this.validateControl();
    }

    onContainerClick(): void {
        this.focus();
        this.onTouched();
    }

    focus(): void {
        if (!this.disabled) {
            this.focused = true;
        }
    }

    blur(): void {
        this.focused = false;
    }

    writeValue(value: TTimePeriodFieldValue): void {
        this.value = value;
    }

    registerOnChange(fn: (value: TTimePeriodFieldValue) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    translatePeriodType(periodType: TimePeriodType): string {
        const key = this.translatePeriodTypeKeys[periodType];

        return key
            ? capitalize(
                  this.translocoService.translate(key, {
                      count: (this.periodValueControl.value as number) || 0
                  })
              )
            : periodType.toString();
    }

    private getMilliseconds(): TTimePeriodFieldValue {
        const periodType = this.periodTypeControl.value as TimePeriodType;
        const periodValue = this.periodValueControl.value as number;

        return periodValue !== undefined && periodValue !== null
            ? this.timePeriodService.toMilliseconds({ type: periodType, value: periodValue })
            : null;
    }

    private parseValue(value: string): number {
        // Remove all non-digit symbols
        return parseInt(value.replace(/\D+/g, ''));
    }

    private validateControl(): void {
        if (this.ngControl && this.ngControl.control) {
            const control = this.ngControl.control;

            control.updateValueAndValidity({ emitEvent: false });
            (control.statusChanges as EventEmitter<string>).emit(control.status);

            this.periodValueControl.updateValueAndValidity({ emitEvent: false });
            this.periodTypeControl.updateValueAndValidity({ emitEvent: false });
        }
    }

    private updateControlsState(): void {
        if (this.disabled) {
            this.periodValueControl.disable();
            this.periodTypeControl.disable();
        } else {
            this.periodValueControl.enable();
            this.periodTypeControl.enable();
        }
    }

    private updateControlsGroupValues(): void {
        const period = this.timePeriodService.toPeriod(this.value, this.periodType, this.availablePeriods);

        const periodValue = period?.value ? { periodValue: period?.value } : {};

        this.controlsGroup.patchValue(
            {
                periodType: period?.type || this.periodType,
                ...periodValue
            },
            { emitEvent: false }
        );
    }

    private updatePeriodTypeSelectItems(): void {
        this.periodTypeSelectItems = this.availablePeriods.map((type) => ({
            type,
            label: this.translatePeriodType(type)
        }));
    }
}

@Directive({
    selector: 'time-period-field',
    exportAs: 'TimePeriodFieldMcValidate'
})
export class TimePeriodFieldMcValidateDirective extends McValidateDirective {}
