import { ArtifactType, IArtifact } from '../artifacts';
import { IPaginationParams, ObservableEntityType, ShareLevel } from '../common';
import { IDataSource } from '../data-sources';
import { IServerError } from '../errors';
import { TObservableEntity } from '../observable';
import { TObservation } from '../observations';
import { TReportBrief } from '../reports';

import { EnrichmentTriggerType, EnrichmentType } from './enrichment';
import { EnrichmentTaskPriority, EnrichmentTaskStatus } from './task';

export interface IEnrichmentRulesGetParams extends IPaginationParams {
    dataSourceUUID?: string[];
    triggerDataSourceUUID?: string[];
    enrichmentType?: EnrichmentType[];
    entityType?: ObservableEntityType[];
    artifactType?: ArtifactType[];
    triggerType?: EnrichmentTriggerType[];
    isDisabled?: boolean;
    name?: string;
}

export interface IEnrichmentRuleCreateParams {
    name: string;
    isDisabled?: boolean;
    triggers: EnrichmentTriggerType[];
    triggerDataSourceUUIDs?: string[];
    artifactTypes?: ArtifactType[];
    entityTypes?: ObservableEntityType[];
    dataSourceUUIDs: string[];
    throttlingInterval?: number;
    enrichment: EnrichmentType;
}

export interface IEnrichmentRuleUpdateParams {
    name?: string;
    isDisabled?: boolean;
    triggers?: EnrichmentTriggerType[];
    triggerDataSourceUUIDs?: string[];
    artifactTypes?: ArtifactType[];
    entityTypes?: ObservableEntityType[];
    dataSourceUUIDs?: string[];
    throttlingInterval?: number;
    enrichment?: EnrichmentType;
}

export interface IEnrichmentAnalyzersGetParams extends IPaginationParams {
    dataSourceUUID?: string;
    artifactType?: ArtifactType[];
}

export interface IEnrichmentAnalyzerCreateParams {
    artifactTypes: ArtifactType[];
    artifactSizeLimit?: number;
    dashboardURL?: string;
    taskExecutionTimeout?: number;
    taskExecutionAttemptsCount?: number;
    dataSourceUUID: string;
}

export interface IEnrichmentAnalyzerUpdateParams {
    artifactTypes?: ArtifactType[];
    artifactSizeLimit?: number;
    dashboardURL?: string;
    taskExecutionTimeout?: number;
    taskExecutionAttemptsCount?: number;
}

export interface IEnrichmentExternalDBsGetParams extends IPaginationParams {
    dataSourceUUID?: string;
    entityType?: ObservableEntityType[];
}

export interface IEnrichmentExternalDBCreateParams {
    entityTypes: ObservableEntityType[];
    webPageURL?: string;
    taskExecutionTimeout?: number;
    taskExecutionAttemptsCount?: number;
    dataSourceUUID: string;
}

export interface IEnrichmentExternalDBUpdateParams {
    entityTypes?: ObservableEntityType[];
    webPageURL?: string;
    taskExecutionTimeout?: number;
    taskExecutionAttemptsCount?: number;
}

export interface IEnrichmentTasksGetParams {
    artifactUUID?: string;
    entityUUID?: string;
}

export interface ICreateDNSLookupEnrichmentTaskParams {
    type: EnrichmentType.DNSLookup;
    dataSource?: {
        uuid: string;
    };
    params: {
        entity: {
            uuid: string;
        };
    };
}

export interface ICreateWhoisLookupEnrichmentTaskParams {
    type: EnrichmentType.WhoisLookup;
    params: {
        entity: {
            uuid: string;
        };
    };
}

export interface ICreateExternalDBLookupEnrichmentTaskParams {
    type: EnrichmentType.ExternalDBLookup;
    dataSource: {
        uuid: string;
    };
    params: {
        entity: {
            uuid: string;
        };
    };
}

export interface ICreateArtifactDownloadEnrichmentTaskParams {
    type: EnrichmentType.ArtifactDownload;
    params: {
        url: string;
        type: ArtifactType;
        shareLevel: ShareLevel;
    };
}

export interface ICreateArtifactAnalysisEnrichmentTaskParams {
    type: EnrichmentType.ArtifactAnalysis;
    dataSource: {
        uuid: string;
    };
    params: {
        artifact: {
            uuid: string;
        };
        imageID?: string;
    };
}

export interface ICreateArchiveUnpackEnrichmentTaskParams {
    type: EnrichmentType.ArchiveUnpack;
    params: {
        artifact: {
            uuid: string;
        };
        password?: string;
        registerNestedArchiveAsFileSample?: boolean;
    };
}

export type TCreateEnrichmentTaskParams =
    | ICreateDNSLookupEnrichmentTaskParams
    | ICreateWhoisLookupEnrichmentTaskParams
    | ICreateExternalDBLookupEnrichmentTaskParams
    | ICreateArtifactDownloadEnrichmentTaskParams
    | ICreateArtifactAnalysisEnrichmentTaskParams
    | ICreateArchiveUnpackEnrichmentTaskParams;

export interface IEnrichmentTask {
    uuid: string;
    url?: string;
    type: EnrichmentType;
    priority: EnrichmentTaskPriority;
    dataSource: IDataSource;
    status: EnrichmentTaskStatus;
    error: IServerError;
    createdAt: string;
    updatedAt: string;
    params: TEnrichmentTaskParams;
    result: IEnrichmentTaskResult;
}

interface IBaseEnrichmentTask {
    uuid: string;
    url?: string;
    priority: EnrichmentTaskPriority;
    dataSource: IDataSource;
    status: EnrichmentTaskStatus;
    error: IServerError;
    createdAt: string;
    updatedAt: string;
}

export interface IDNSLookupEnrichmentTask extends IBaseEnrichmentTask {
    type: EnrichmentType.DNSLookup;
    params: IDNSLookupEnrichmentTaskParams;
    result: IObservationEnrichmentTaskResult;
}

export interface IWhoisLookupEnrichmentTask extends IBaseEnrichmentTask {
    type: EnrichmentType.WhoisLookup;
    params: IDNSLookupEnrichmentTaskParams;
    result: IObservationEnrichmentTaskResult;
}

export interface IExternalDBLookupEnrichmentTask extends IBaseEnrichmentTask {
    type: EnrichmentType.ExternalDBLookup;
    params: IDNSLookupEnrichmentTaskParams;
    result: IObservationEnrichmentTaskResult;
}

export interface IArtifactDownloadEnrichmentTask extends IBaseEnrichmentTask {
    type: EnrichmentType.ArtifactDownload;
    params: {
        url: string;
        type: ArtifactType;
    };
    result: IArtifactEnrichmentTaskResult;
}

export interface IArtifactAnalysisEnrichmentTask extends IBaseEnrichmentTask {
    type: EnrichmentType.ArtifactAnalysis;
    params: IArtifactEnrichmentTaskResult;
    result: IReportEnrichmentTaskResult;
}

export interface IArchiveUnpackEnrichmentTask extends IBaseEnrichmentTask {
    type: EnrichmentType.ArchiveUnpack;
    params: IArtifactEnrichmentTaskResult;
    result: IObservationEnrichmentTaskResult;
}

export type TEnrichmentTask =
    | IDNSLookupEnrichmentTask
    | IWhoisLookupEnrichmentTask
    | IExternalDBLookupEnrichmentTask
    | IArtifactDownloadEnrichmentTask
    | IArtifactAnalysisEnrichmentTask
    | IArchiveUnpackEnrichmentTask;

export interface IDNSLookupEnrichmentTaskParams {
    entity: TObservableEntity<ObservableEntityType.DomainName> | TObservableEntity<ObservableEntityType.IPAddress>;
}

export interface IWhoisLookupEnrichmentTaskParams {
    entity: TObservableEntity<ObservableEntityType.DomainName> | TObservableEntity<ObservableEntityType.IPAddress>;
}

export interface IExternalDBLookupEnrichmentTaskParams {
    entity: TObservableEntity;
}

export type ILookupEnrichmentTaskParams =
    | IDNSLookupEnrichmentTaskParams
    | IWhoisLookupEnrichmentTaskParams
    | IExternalDBLookupEnrichmentTaskParams;

export interface IArtifactDownloadEnrichmentTaskParams {
    url: string;
    type: ArtifactType;
    shareLevel: ShareLevel;
}

export interface IArtifactAnalysisEnrichmentTaskParams {
    artifact: IArtifact;
    imageID?: string;
}

export interface IArchiveUnpackEnrichmentTaskParams {
    artifact: IArtifact;
    password?: string;
    registerNestedArchiveAsFileSample?: boolean;
}

export type TEnrichmentTaskParams =
    | IDNSLookupEnrichmentTaskParams
    | IWhoisLookupEnrichmentTaskParams
    | IExternalDBLookupEnrichmentTaskParams
    | IArtifactDownloadEnrichmentTaskParams
    | IArtifactAnalysisEnrichmentTaskParams
    | IArchiveUnpackEnrichmentTaskParams;

export interface IArtifactEnrichmentTaskResult {
    artifact: IArtifact;
}

export interface IReportEnrichmentTaskResult {
    report: TReportBrief;
}

export interface IObservationEnrichmentTaskResult {
    observation: TObservation;
}

export type IEnrichmentTaskResult =
    | IArtifactEnrichmentTaskResult
    | IReportEnrichmentTaskResult
    | IObservationEnrichmentTaskResult;
