import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { ObservableEntityType, RelationType } from '@pt-cybsi/api-interfaces';

/**
 * @pipe TranslateGraphRelation
 *
 * @description Converts relation type to localized string
 */
@Pipe({
    name: 'translateGraphRelation'
})
export class TranslateGraphRelationPipe implements PipeTransform {
    constructor(private translocoService: TranslocoService) {}

    transform(source: ObservableEntityType, kind: RelationType, target: ObservableEntityType): string {
        const relationName = this.translocoService.translate(
            `entities.ObservableEntities.Relation.Text.${source}-${kind}-${target}`
        );

        return relationName;
    }
}
