import { Injectable, Provider } from '@angular/core';

import { IFormRepository, ValidateFormUseCase } from '@pt-cybsi/shared';

import { AnalyserModel } from '../../models';
import { AnalyserFormRepository, AnalyserFormRepositoryProvider } from '../../repositories';

@Injectable()
export class ValidateAnalyserUseCase extends ValidateFormUseCase<AnalyserModel> {
    constructor(repository: IFormRepository<AnalyserModel>) {
        super(repository);
    }
}

export const ValidateAnalyserUseCaseProvider: Provider = [
    AnalyserFormRepositoryProvider,
    {
        provide: ValidateAnalyserUseCase,
        useFactory: (repository: AnalyserFormRepository) => new ValidateAnalyserUseCase(repository),
        deps: [AnalyserFormRepository]
    }
];
