<div class="mc-form__row">
    <mc-form-field>
        <input
            mcInput
            [formControl]="form.getControl('login')"
            [placeholder]="'common.Common.Pseudo.Text.Login' | transloco">
    </mc-form-field>
</div>

<div class="mc-form__row">
    <mc-form-field>
        <input
            mcInputPassword
            [formControl]="form.getControl('password')"
            [placeholder]="'common.Common.Pseudo.Text.Password' | transloco">
        <mc-password-toggle
            [mcTooltipNotHidden]="'common.Common.Pseudo.Text.PasswordHide' | transloco"
            [mcTooltipHidden]="'common.Common.Pseudo.Text.PasswordShow' | transloco">
        </mc-password-toggle>
    </mc-form-field>
</div>

<mc-checkbox [formControl]="form.getControl('rememberMe')">
    {{ 'account.Account.SignInForm.Label.RememberMe' | transloco }}
</mc-checkbox>
