import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'status-badge',
    templateUrl: './status-badge.component.html',
    styleUrls: ['./status-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusBadgeComponent {
    @Input() statusTranslationKey: string;
}
