import { Data, Params } from '@angular/router';
import * as fromNgrxRouter from '@ngrx/router-store';
import { Action, createReducer, on } from '@ngrx/store';

import * as RouterActions from './router.actions';
import { IMergedRouterState } from './router.models';

export const ROUTER_FEATURE_KEY = 'route-data';

export interface IActivatedRouterStateSnapshot {
    url: string;
    queryParams: Params;
    params: Params;
    data: Data;
}

export interface IState extends fromNgrxRouter.RouterReducerState<IActivatedRouterStateSnapshot> {
    isLegacyMode: boolean;
    legacyUrl: string;
    isNavbarVisible: boolean;
    isChangedForm: boolean;
    pageTitle: string;
}

export const initialState: IState = {
    isLegacyMode: false,
    legacyUrl: '',
    isNavbarVisible: false,
    isChangedForm: false,
    pageTitle: undefined,
    navigationId: 0,
    state: {
        url: '',
        queryParams: {},
        params: {},
        data: {}
    }
};

const routerReducer = createReducer(
    initialState,
    on(RouterActions.setIsLegacyMode, (state, { isLegacyMode }) => ({
        ...state,
        isLegacyMode
    })),
    on(RouterActions.setLegacyUrl, (state, { legacyUrl }) => ({
        ...state,
        legacyUrl
    })),
    on(RouterActions.setIsNavbarVisible, (state, { isNavbarVisible }) => ({
        ...state,
        isNavbarVisible
    })),
    on(RouterActions.setIsChangedForm, (state, { isChangedForm }) => ({
        ...state,
        isChangedForm
    })),
    on(RouterActions.setPageTitle, (state, { pageTitle }) => ({
        ...state,
        pageTitle
    })),
    on(fromNgrxRouter.routerNavigatedAction, (state) => ({
        ...state,
        isChangedForm: false
    })),
    on(fromNgrxRouter.routerNavigatedAction, (state, { payload }) => ({
        ...state,
        // TODO: Разобраться с автоматическим выведением типа
        state: payload.routerState as unknown as IMergedRouterState
    })),
    on(
        fromNgrxRouter.routerNavigatedAction,
        fromNgrxRouter.routerErrorAction,
        fromNgrxRouter.routerCancelAction,
        (state, { payload }) => ({
            ...state,
            navigationId: payload.event.id
        })
    )
);

export function reducer(state: IState | undefined, action: Action) {
    return routerReducer(state, action);
}
