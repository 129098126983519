import { Injectable } from '@angular/core';
import { Observable, of, forkJoin, combineLatest, BehaviorSubject } from 'rxjs';
import { shareReplay, catchError, finalize } from 'rxjs/operators';

import { SystemInfoApiService } from '@pt-cybsi/api';
import { IVersion, IProductVersionJson } from '@pt-cybsi/api-interfaces';
import { BrandingConfig } from '@pt-cybsi/shared';

export interface IComponentsVersions {
    system?: string;
    api?: string;
    backend?: string;
    frontend?: string;
    adapter?: string;
}

@Injectable()
export class SystemInfoService {
    isDocumentationVisible = BrandingConfig.DocumentationVisibility;

    versions: IComponentsVersions = {};
    releaseNotesUrl: string;
    userGuideUrl: string;

    hasReleaseNotes$: Observable<boolean>;
    hasUserGuide$: Observable<boolean>;

    isReloadingVersions$ = new BehaviorSubject<boolean>(false);
    hasDocumentation$ = new BehaviorSubject<boolean>(false);

    private apiVersion$: Observable<IVersion>;
    private jsonVersion$: Observable<IProductVersionJson>;

    constructor(private systemApiService: SystemInfoApiService) {
        this.initDocumentationUrls();
        this.initObservables();
        this.initVersions();
    }

    initDocumentationUrls() {
        this.releaseNotesUrl = this.systemApiService.getReleaseNotesUrl();
        this.userGuideUrl = this.systemApiService.getUserGuideUrl();
    }

    initObservables() {
        this.hasReleaseNotes$ = this.isDocumentationVisible
            ? this.systemApiService.hasDocumentation(this.releaseNotesUrl)
            : of(false);
        this.hasUserGuide$ = this.isDocumentationVisible
            ? this.systemApiService.hasDocumentation(this.userGuideUrl)
            : of(false);

        combineLatest([this.hasReleaseNotes$, this.hasUserGuide$]).subscribe(([hasReleaseNotes, hasUserGuide]) => {
            this.hasDocumentation$.next(hasReleaseNotes || hasUserGuide);
        });

        this.apiVersion$ = this.systemApiService.getSystemVersion().pipe(
            shareReplay({ bufferSize: 1, refCount: true }),
            catchError(() => of(undefined))
        );
        this.jsonVersion$ = this.systemApiService.getProductVersion().pipe(
            shareReplay({ bufferSize: 1, refCount: true }),
            catchError(() => of(undefined))
        );
    }

    initVersions() {
        this.loadVersions().subscribe(([versions, versionFromJson]: [IVersion, IProductVersionJson]) => {
            this.updateDataFromAPI(versions);
            this.updateDataFromJSON(versionFromJson);
        });
    }

    reloadVersions(): void {
        this.isReloadingVersions$.next(true);

        this.loadVersions()
            .pipe(finalize(() => this.isReloadingVersions$.next(false)))
            .subscribe(([versions, versionFromJson]: [IVersion, IProductVersionJson]) => {
                this.updateDataFromAPI(versions);
                this.updateDataFromJSON(versionFromJson);
            });
    }

    private loadVersions(): Observable<[IVersion, IProductVersionJson]> {
        return forkJoin([this.apiVersion$, this.jsonVersion$]);
    }

    private updateDataFromAPI(versions: IVersion) {
        this.versions.api = versions?.apiVersion;
        this.versions.backend = versions?.serverVersion;
    }

    private updateDataFromJSON(versions: IProductVersionJson) {
        this.versions.system = versions?.productVersion;
        this.versions.frontend = versions?.webClientVersion;
        this.versions.adapter = versions?.adapterVersion;
    }
}
