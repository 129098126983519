import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const createControlValidator =
    (isValid: (value: unknown) => boolean, errorName: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors =>
        isValid(control.value) ? null : { [errorName]: true };

export const createChangedControlValidator =
    (isValid: (value: unknown) => boolean, errorName: string): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
        const isChanged = control.dirty;

        return !isChanged || isValid(control.value) ? null : { [errorName]: true };
    };
