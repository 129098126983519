import { Injectable, Provider } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IServerError } from '@pt-cybsi/api-interfaces';
import { FormStateValue, IFormRepository, IObservableUseCase } from '@pt-cybsi/shared';

import { AnalyserModel } from '../../models';
import { AnalyserFormRepository, AnalyserFormRepositoryProvider } from '../../repositories';

export interface IUpdateAnalyserParams {
    id: string;
}

export type TUpdatedAnalyserId = string;

@Injectable()
export class UpdateAnalyserUseCase implements IObservableUseCase<IUpdateAnalyserParams, TUpdatedAnalyserId> {
    constructor(private repository: IFormRepository<AnalyserModel>) {}

    execute(params: IUpdateAnalyserParams): Observable<TUpdatedAnalyserId> {
        const { id } = params;

        const analyser = this.repository.getCurrentData(id);
        const currentFormState = this.repository.getState(id);

        if (currentFormState.value === FormStateValue.Saved) {
            return of(analyser.props.serverId);
        }

        this.repository.resetSavingError(id);

        this.switchState(id, FormStateValue.Saving);

        const initialAnalyser = this.repository.getInitialData(id);

        const analyserWithChangedData = analyser.diff(initialAnalyser);

        return this.repository.save(analyserWithChangedData).pipe(
            tap(() => {
                this.switchState(id, FormStateValue.Saved);

                this.repository.disable(id);
            }),
            catchError((error: unknown) => {
                this.switchState(id, FormStateValue.SavingFailure);

                this.repository.updateSavingError(id, error as IServerError);

                return throwError(error);
            })
        );
    }

    private switchState(id: string, state: FormStateValue): void {
        const formState = this.repository.getState(id);

        const nextFormState = formState.to(state);

        this.repository.updateState(id, nextFormState);
    }
}

export const UpdateAnalyserUseCaseProvider: Provider = [
    AnalyserFormRepositoryProvider,
    {
        provide: UpdateAnalyserUseCase,
        useFactory: (repository: AnalyserFormRepository) => new UpdateAnalyserUseCase(repository),
        deps: [AnalyserFormRepository]
    }
];
