import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AsyncValidationService, IFormViewModelBuilder, TFormViewModelProps } from '@pt-cybsi/shared';

import { TReputationListFormData } from './reputation-list-form.mapper';
import {
    ReputationListFormConfig,
    ReputationListFormViewModel,
    TReputationListFormConfig
} from './reputation-list-form.view-model';

/**
 * @factory ReputationListFormBuilder
 *
 * @description
 * Provides a factory method to create ReputationListFormBuilder.
 * Creates a reactive form of reputation list form and wrap it in FormViewModel.
 */
@Injectable()
export class ReputationListFormBuilder
    implements IFormViewModelBuilder<TReputationListFormData, TReputationListFormConfig>
{
    formConfig = ReputationListFormConfig;

    constructor(private fb: UntypedFormBuilder, private asyncValidationService: AsyncValidationService) {}

    build(props: TFormViewModelProps<TReputationListFormData>): ReputationListFormViewModel {
        const form = this.buildFormGroup();

        return new ReputationListFormViewModel(props, form, this.formConfig, this.asyncValidationService);
    }

    private buildFormGroup(): UntypedFormGroup {
        const { id, eTag, serverId, name, shareLevel, text } = this.formConfig;

        const QUERY_NAME_MAX_LENGTH = 250;

        const queryNameMaxLength = Validators.maxLength(QUERY_NAME_MAX_LENGTH);

        const required = Validators.required;

        return this.fb.group({
            [id.controlName]: [undefined],

            [serverId.controlName]: [undefined],

            [eTag.controlName]: [undefined],

            [name.controlName]: [
                undefined,
                {
                    validators: [required, queryNameMaxLength]
                }
            ],

            [shareLevel.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ],

            [text.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ]
        });
    }
}
