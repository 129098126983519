import { ShareLevel } from '../common';
import { IUser } from '../users';

export interface IReputationList extends IReputationListBase {
    updatedAt: string;
    status: ReputationListStatus;
}

export interface IReputationListBase {
    uuid: string;
    url?: string;
    query: IReputationListQueryPreview;
    author: IUser;
    shareLevel: ShareLevel;
    isEnabled: boolean;
}

export interface IReputationListQueryPreview {
    uuid: string;
    url?: string;
    name: string;
}

export enum ReputationListStatus {
    Initializing = 'Initializing',
    Active = 'Active',
    Inactive = 'Inactive'
}

export const REPUTATION_LISTS_STATUSES = Object.values(ReputationListStatus);
