import {
    Component,
    ContentChildren,
    Directive,
    EventEmitter,
    Input,
    Output,
    QueryList,
    Self,
    ViewEncapsulation
} from '@angular/core';

export abstract class FiltersPanelBaseItem {
    abstract reset(): void;
}

@Directive({
    selector: '[filters-panel-item]',
    host: {
        class: 'filters-panel-item'
    }
})
export class FiltersPanelItemDirective {
    constructor(@Self() private component: FiltersPanelBaseItem) {}

    reset() {
        this.component.reset();
    }
}

/**
 * @component FiltersPanel
 *
 * @description
 * Displays a panel for filters passed through the content projection with reset button
 *
 * All filters should have filters-panel-item directive
 *
 * @param isResetDisabled - Boolean value for reset button [disabled] value
 * @param onResetAll - Event of reset all button click handling
 *
 * @example
 *
 * ```html
 * <filters-panel [isResetDisabled]="isResetDisabled"
 *                (onResetAll)="onResetClick()">
 *     <panel-item-1 filters-panel-item></panel-item-1>
 *     <panel-item-2 filters-panel-item></panel-item-2>
 * </filters-panel>
 * ```
 *
 * When you creating a panel item component remember to extend FiltersPanelBaseItem class and to add
 *
 * ```ts
 * providers: [
 *     {
 *         provide: FiltersPanelBaseItem,
 *         useExisting: forwardRef(() => PanelItemComponent)
 *      }
 * ]
 * ```
 */
@Component({
    selector: 'filters-panel',
    templateUrl: './filters-panel.component.html',
    styleUrls: ['./filters-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FiltersPanelComponent {
    @Input() isResetDisabled: boolean;
    @Output() onResetAll: EventEmitter<void> = new EventEmitter();

    @ContentChildren(FiltersPanelItemDirective) contentChildren!: QueryList<FiltersPanelItemDirective>;

    handleClickResetAll(): void {
        this.contentChildren.forEach((contentChild) => {
            contentChild.reset();
        });

        this.onResetAll.emit();
    }
}
