<ng-container *transloco="let tUsers; read: 'users'">
    <div class="layout-row layout-align-start-center margin-bottom_16">
        <div class="cybsi-subheading">{{ tUsers('Users.ApiKey.Title.ApiKey') }}</div>

        <button *ngIf="createApiKeyPermissions || isCurrentUser"
                mc-button color="second" class="margin-left_16"
                [disabled]="user.isDisabled"
                (click)="openCreateApiKeyModal()">
            {{ 'common.Common.Pseudo.Button.Create' | transloco }}
        </button>
    </div>

    <progress-container [loadingFlag]="isLoading$ | async">
        <div *ngIf="isErrorLoadingVisible$ | async">
            <ng-container *ngTemplateOutlet="errorAlert"></ng-container>
        </div>

        <div *ngIf="isApiKeysTableVisible$ | async">
            <lazy-data-container
                [state]="lazyLoadingState$ | async"
                [errorTitle]="tUsers('Users.ApiKey.Error.LazyLoading')"
                (reload)="handleLoadMore()">
                <user-api-keys-table
                    #userApiKeysTable

                    [apiKeys]="apiKeys$ | async"
                    [isCurrentUser]="isCurrentUser"
                    (editKey)="openEditKeyModal($event)">
                </user-api-keys-table>
            </lazy-data-container>
        </div>
    </progress-container>

    <ng-template #errorAlert>
        <div class="user-api-keys__error-alert">
            <div class="mc-alert mc-alert_error">
                <i mc-icon="mc-error_16" class="mc-alert__icon"></i>
                <div class="layout-row layout-align-start-start">
                    <div>{{ tUsers('Users.ApiKey.Error.Loading') | transloco | translateCut:0 }}</div>
                    <button mc-link pseudo class="mc-link clear-button" (click)="reload()">
                        {{ tUsers('Users.ApiKey.Error.Loading') | transloco | translateCut:1 }}
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>
