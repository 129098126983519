import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IEntitiesRelationsMap, ObservableEntitiesApiService } from '@pt-cybsi/api';
import { AggregateSection, IRelationLink, TAggregate, TObservableEntity } from '@pt-cybsi/api-interfaces';

@Injectable()
export class RelationsResolverService {
    constructor(private observableEntitiesApiService: ObservableEntitiesApiService) {}

    resolveMapOfRelations(
        relations: IEntitiesRelationsMap,
        aggregateSections: AggregateSection[]
    ): Observable<IEntitiesResolvedRelationsMap> {
        const relatedEntitiesIds: string[] = [];
        const relationsOfEntities = Object.values(relations);

        relationsOfEntities.forEach((relationsOfEntity: IRelationLink[]) => {
            relatedEntitiesIds.push(
                ...relationsOfEntity.reduce(
                    (
                        ids: string[],
                        {
                            link: {
                                relatedEntity: { uuid: entityId }
                            }
                        }
                    ) => (entityId ? ids.concat(entityId) : ids),
                    []
                )
            );
        });

        return this.observableEntitiesApiService
            .getEntitiesAggregatesMap(relatedEntitiesIds, aggregateSections)
            .pipe(map((aggregates) => this.resolveMap(relations, new Map(Object.entries(aggregates)))));
    }

    resolveRelations(
        relations: IRelationLink<TObservableEntity>[],
        aggregateSections: AggregateSection[]
    ): Observable<IRelationLink<TAggregate>[]> {
        const relatedEntitiesIds: string[] = relations.map((relation) => relation.link.relatedEntity.uuid);

        return this.observableEntitiesApiService
            .getEntitiesAggregatesMap(relatedEntitiesIds, aggregateSections)
            .pipe(map((aggregates) => this.resolveList(relations, new Map(Object.entries(aggregates)))));
    }

    private resolveList(relations: IRelationLink[], aggregates: Map<string, TAggregate>): IRelationLink<TAggregate>[] {
        return relations.map<IRelationLink<TAggregate>>((relation) =>
            this.replaceEntityByAggregate(relation, aggregates)
        );
    }

    private resolveMap(
        relations: IEntitiesRelationsMap,
        aggregates: Map<string, TAggregate>
    ): IEntitiesResolvedRelationsMap {
        const entities = Object.keys(relations);
        const resolvedRelations: IEntitiesResolvedRelationsMap = {};

        entities.forEach((entityId) => {
            const links = relations[entityId];

            resolvedRelations[entityId] = this.resolveList(links, aggregates);
        });

        return resolvedRelations;
    }

    private replaceEntityByAggregate(
        relationLink: IRelationLink,
        aggregates: Map<string, TAggregate>
    ): IRelationLink<TAggregate> {
        return {
            ...relationLink,
            link: {
                ...relationLink.link,
                relatedEntity: aggregates.get(relationLink.link.relatedEntity.uuid)
            }
        };
    }
}

export interface IEntitiesResolvedRelationsMap {
    [entityId: string]: IRelationLink<TAggregate>[];
}
