import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { McPopoverTrigger } from '@ptsecurity/mosaic/popover';
import { FlatTreeControl, McTreeFlatDataSource, McTreeFlattener } from '@ptsecurity/mosaic/tree';
import debounce from 'lodash/debounce';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { DataSourcesOrderType, IDataSource } from '@pt-cybsi/api-interfaces';
import { AsyncState, FiltersPanelBaseItem } from '@pt-cybsi/shared';
import { DataSourcesFacade } from '@pt-cybsi/store/data-sources';

import {
    buildSourcesTree,
    getChildren,
    getLevel,
    getValue,
    getViewValue,
    isExpandable,
    ISourceFlatTreeNode,
    ISourceTreeNode,
    transformer
} from '../../helpers';
import { DataSourceMapper } from '../../mappers';
import { SourceViewModel } from '../../models';
import { SourcesSyncService } from '../../services';

/**
 * @component Sources Filter
 *
 * @description
 * Used for filtering data by sources. Can be put into a filters-panel component
 *
 * @param title - title of filter, displayed in filter-button as main title (optional)
 * @param value - applied values of filter in `string[]` format
 * @param applyFilter - event, emitted after clicking apply filter button
 * @param resetFilter - event, emitted after clicking reset filter button
 *
 * @example
 * ```html
 * <sources-filter
 *     [title]="title"
 *     [value]="value"
 *     (applyFilter)="handleApply($event)"
 *     (resetFilter)="handleReset()">
 * </sources-filter>
 * ```
 */
@Component({
    selector: 'sources-filter',
    templateUrl: './sources-filter.component.html',
    styleUrls: ['./sources-filter.component.scss'],
    providers: [
        {
            provide: FiltersPanelBaseItem,
            useExisting: forwardRef(() => SourcesFilterComponent)
        }
    ],
    encapsulation: ViewEncapsulation.None
})
export class SourcesFilterComponent extends FiltersPanelBaseItem implements OnInit, OnChanges {
    @Input() title: string;
    @Input() set value(value: string[]) {
        this.selectedSources = value;
        this.initialSelectedSources = value;
    }

    @Output() applyFilter = new EventEmitter<string[]>();
    @Output() resetFilter = new EventEmitter<void>();

    @ViewChild('popover', { read: McPopoverTrigger }) popover: McPopoverTrigger;

    valueTitle: Observable<string>;
    searchValue: string;
    selectedSources: string[];
    initialSelectedSources: string[];
    treeControl: FlatTreeControl<ISourceFlatTreeNode>;
    treeFlattener: McTreeFlattener<ISourceTreeNode, ISourceFlatTreeNode>;
    dataSource: McTreeFlatDataSource<ISourceTreeNode, ISourceFlatTreeNode>;

    appliedValues$ = new BehaviorSubject<string[]>([]);

    loadingSourcesState$ = new BehaviorSubject<AsyncState>(AsyncState.Loading);

    isLoaderVisible$ = this.loadingSourcesState$.pipe(map((state) => state === AsyncState.Loading));

    isSourcesLoaded$ = this.loadingSourcesState$.pipe(map((state) => state === AsyncState.Success));
    isErrorVisible$ = this.loadingSourcesState$.pipe(map((state) => state === AsyncState.Failure));

    private readonly debounceSearch: number = 250;

    get filterTitle(): string {
        return this.title || this.defaultTitle;
    }

    get isEmptySearchResult(): boolean {
        return this.searchValue && this.treeControl.filterModel.isEmpty();
    }

    private readonly defaultTitle: string = this.translocoService.translate('sources.Sources.Pseudo.Text.Source');

    constructor(
        private translocoService: TranslocoService,
        private sourcesSyncService: SourcesSyncService,
        private dataSourcesFacade: DataSourcesFacade
    ) {
        super();
    }

    ngOnInit() {
        this.treeFlattener = new McTreeFlattener(transformer, getLevel, isExpandable, getChildren);
        this.treeControl = new FlatTreeControl<ISourceFlatTreeNode>(getLevel, isExpandable, getValue, getViewValue);
        this.dataSource = new McTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.valueTitle = this.getValueTitle$();

        this.onSearchChange = debounce(this.onSearchChange.bind(this), this.debounceSearch);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const isValueChanged = changes.value?.currentValue !== changes.value?.previousValue;

        if (isValueChanged) {
            this.appliedValues$.next(this.selectedSources);
        }
    }

    hasChild(_index: number, nodeData: ISourceFlatTreeNode): boolean {
        return nodeData.expandable;
    }

    handleReset(): void {
        this.treeControl.filterNodes('');
        this.resetFilter.emit();
        this.popover.hide();
    }

    handleApply(): void {
        this.popover.hide();
        this.applyFilter.emit(this.selectedSources);
    }

    handleCancel(): void {
        this.selectedSources = this.initialSelectedSources;
        this.popover.hide();
    }

    onSearchChange(searchValue: string): void {
        this.treeControl.filterNodes(searchValue);
    }

    loadSources(): void {
        this.loadingSourcesState$.next(AsyncState.Loading);

        this.sourcesSyncService
            .loadAndSyncSources({ orderBy: DataSourcesOrderType.FullName })
            .pipe(
                tap(() => {
                    this.loadingSourcesState$.next(AsyncState.Success);
                }),
                switchMap((sourcesIds: string[]) => this.dataSourcesFacade.selectSources(sourcesIds)),
                catchError(() => {
                    this.loadingSourcesState$.next(AsyncState.Failure);

                    return of([] as IDataSource[]);
                })
            )
            .pipe(take(1))
            .subscribe((sources: IDataSource[]) => {
                this.dataSource.data = buildSourcesTree(sources);

                this.treeControl.filterNodes('');
                this.treeControl.expandAll();

                if (this.selectedSources.length) {
                    this.scrollToElement(this.selectedSources[0]);
                }
            });
    }

    reloadSources(): void {
        this.loadSources();
    }

    reset(): void {
        this.resetFilter.emit();
    }

    private getValueTitle$(): Observable<string> {
        return this.appliedValues$.pipe(
            switchMap((sources: string[]) =>
                sources.length > 1
                    ? of(sources.length.toString())
                    : this.dataSourcesFacade.selectSource(this.selectedSources[0]).pipe(
                          map((dataSource) => {
                              if (!dataSource) {
                                  return '';
                              }

                              const sourceView = SourceViewModel.create(DataSourceMapper.toFullView(dataSource));

                              return sourceView.getFullName();
                          })
                      )
            )
        );
    }

    handlePopoverVisibleChange(isOpened: boolean): void {
        if (isOpened) {
            this.loadSources();
        } else {
            this.searchValue = '';
        }
    }

    private scrollToElement(elementId: string) {
        setTimeout(() => {
            const nodeElement = document.getElementById(elementId);

            nodeElement.scrollIntoView();
        });
    }
}
