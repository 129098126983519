<filter-button
    [title]="title"
    [valueTitle]="valueTitle"
    [allowTooltip]="isDropdownClosed"
    (click)="handleOpenDropdown()"

    #filterDropdownTrigger="mcDropdownTrigger"
    [mcDropdownTriggerFor]="filterDropdown"
    [class.mc-active]="filterDropdownTrigger.opened">
</filter-button>

<mc-dropdown #filterDropdown xPosition="before" hasBackdrop="true" (closed)="handleCloseDropdown()">
    <div *ngFor="let item of dropdownItems">
        <button mc-dropdown-item
                (click)="handleSelectItem(item)"
                [class.mc-selected]="item?.value === appliedValue?.value">
            {{ item.name }}
        </button>
    </div>
</mc-dropdown>
