<div class="mc-form__row">
    <label class="mc-form__label">
        {{ 'common.Common.FormFields.Label.Name' | transloco }}
    </label>
    <mc-form-field #name [autoFocusedField]="name" class="source-type-form__name">
        <source-type-autocomplete-field
            *ngIf="isAutocompleteAvailable"
            [formControl]="form.getControl('name')"
            (selectType)="handleSelectType($event)"
            (clear)="handleClearType()">
        </source-type-autocomplete-field>
        <input
            *ngIf="!isAutocompleteAvailable"
            [formControl]="form.getControl('name')"
            mcInput
            autocomplete="off">
        <mc-hint *ngIf="form.hasErrors('name')" color="error">
            <div *ngIf="form.hasError('name', 'length')">{{ invalidNameLengthMessage }}</div>
        </mc-hint>
    </mc-form-field>
</div>

<div class="mc-form__row">
    <label class="mc-form__label">
        {{ 'sources.Sources.FormFields.Label.UniqName' | transloco }}
    </label>
    <mc-form-field class="source-type-form__uniq-name">
        <input
            [formControl]="form.getControl('shortName')"
            mcInput
            autocomplete="off">
        <mc-hint *ngIf="form.hasErrors('shortName')" color="error">
            <div *ngIf="form.hasError('shortName', 'length')">{{ invalidShortNameLengthMessage }}</div>
        </mc-hint>
        <mc-hint *ngIf="isShortNameHintVisible" color="second">
            {{ 'sources.Sources.FormFields.Hint.UniqName' | transloco }}
        </mc-hint>
    </mc-form-field>
</div>

<div class="mc-form__row">
    <label class="mc-form__label">
        {{ 'sources.Sources.FormFields.Label.Confidence' | transloco }}
    </label>
    <mc-form-field class="source-type-form__confidence">
        <input
            confidenceInput
            mcInput
            type="number"
            [formControl]="form.getControl('confidence')">
        <mc-stepper></mc-stepper>
        <mc-hint color="second">
            {{ 'sources.Sources.FormFields.Hint.Confidence' | transloco }}
        </mc-hint>
    </mc-form-field>
</div>
