import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { filter, map } from 'rxjs/operators';

import { IMergedRouterState } from '@pt-cybsi/store/router';

import * as ErrorActions from './error.actions';

@Injectable()
export class ErrorEffects {
    resetError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATION),
            map((action: RouterNavigationAction<IMergedRouterState>) => action.payload.routerState),
            filter((routerState) => routerState.url.indexOf('/error') === -1),
            map(() => ErrorActions.resetError())
        )
    );

    setErrorFromRouteParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            map((action: RouterNavigationAction<IMergedRouterState>) => action.payload.routerState),
            filter((routerState) => routerState?.queryParams?.errorCode),
            map((routerState) => ErrorActions.setError({ code: routerState.queryParams.errorCode }))
        )
    );

    constructor(private actions$: Actions) {}
}
