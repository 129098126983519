<span *ngIf="form.isVisibleControl('isBuiltin')"
      class="enrichment-rule-form__builtin mc-badge mc-badge_default mc-badge_solid mc-badge_transparent">
    {{ builtinLabelKey | transloco }}
</span>

<div *ngIf="form.isVisibleControl('name')" class="mc-form__row">
    <label class="mc-form__label">
        {{ 'common.Common.FormFields.Label.Name' | transloco }}
    </label>
    <mc-form-field #name [autoFocusedField]="name" class="enrichment-rule-form__name">
        <input
            [formControl]="form.getControl('name')"
            mcInput
            autocomplete="off">
        <mc-hint *ngIf="form.hasErrors('name')" color="error">
            <div *ngIf="form.hasError('name', 'length')">{{ invalidNameLengthMessage }}</div>
        </mc-hint>
    </mc-form-field>
</div>

<div *ngIf="form.isVisibleControl('enrichment')" class="mc-form__row">
    <label class="mc-form__label">
        {{ 'enrichment.Enrichment.Pseudo.Text.Type' | transloco }}
    </label>
    <mc-radio-group
        class="enrichment-rule-form__enrichment-type"
        [formControl]="form.getControl('enrichment')">
        <mc-radio-button
            class="enrichment-rule-form__enrichment-type-value"
            *ngFor="let type of availableEnrichmentTypes | sort : enrichmentTypeSortComparator"
            [value]="type">
            {{ type | translateEnrichmentType }}
        </mc-radio-button>
    </mc-radio-group>
    <mc-hint *ngIf="hasEnrichmentTypeRequiredErrors" color="error" class="enrichment-rule-form__enrichment-type-errors">
        {{ 'enrichment.Enrichment.RuleValidation.Text.RequiredType' | transloco }}
    </mc-hint>
</div>

<div class="margin-bottom_16"
     [class.enrichment-rule-form__invalid-fieldset]="hasMisconfiguredDataSourceError">
    <div *ngIf="form.isVisibleControl('artifactTypes')" class="mc-form__row">
        <label class="mc-form__label">
            {{ 'artifacts.Artifacts.Pseudo.Text.ArtifactType' | transloco }}
        </label>
        <mc-form-field class="enrichment-rule-form__artifact-types">
            <artifact-types-select
                multiple

                [formControl]="form.getControl('artifactTypes')"
                [availableTypes]="availableArtifactTypes">
            </artifact-types-select>
        </mc-form-field>
    </div>

    <div *ngIf="form.isVisibleControl('entityTypes')" class="mc-form__row">
        <label class="mc-form__label">
            {{ 'entities.ObservableEntities.Pseudo.Text.Type' | transloco }}
        </label>
        <mc-form-field class="enrichment-rule-form__entity-types">
            <entity-types-select
                multiple

                [formControl]="form.getControl('entityTypes')"
                [availableEntityTypes]="availableEntityTypes">
            </entity-types-select>
        </mc-form-field>
    </div>

    <div *ngIf="form.isVisibleControl('sources')" class="mc-form__row">
        <label class="mc-form__label">
            {{ sourcesLabel }}
        </label>
        <mc-form-field class="enrichment-rule-form__sources">
            <sources-select
                multiple
                [formControl]="form.getControl('sources')"
                [loadSourcesFunction]="sourcesSelectLoadFn">
            </sources-select>
            <mc-hint *ngIf="hasMisconfiguredDataSourceError" color="error">
                {{ misconfiguredDataSourceErrorMessage }}
            </mc-hint>
        </mc-form-field>
    </div>
</div>

<div *ngIf="form.isVisibleControl('triggers')" class="mc-form__row">
    <label class="mc-form__label">
        {{ 'enrichment.Enrichment.Pseudo.Text.TriggerType' | transloco }}
    </label>

    <checkbox-group-field
        class="enrichment-rule-form__triggers"

        [items]="availableTriggers | sort : triggerSortComparator"
        [checkboxTemplate]="triggerCheckboxTemplate"
        [formControl]="form.getControl('triggers')">
        <mc-hint *ngIf="form.hasErrors('triggers')" color="error">
            <div *ngIf="form.hasError('triggers', 'required')">
                {{ 'enrichment.Enrichment.RuleValidation.Text.RequiredTrigger' | transloco }}
            </div>
        </mc-hint>
    </checkbox-group-field>

    <ng-template #triggerCheckboxTemplate let-control="checkboxControl" let-trigger="data">
        <mc-checkbox [formControl]="control">{{ trigger | translateEnrichmentTriggerType }}</mc-checkbox>
    </ng-template>
</div>

<div *ngIf="form.isVisibleControl('triggerSources')" class="mc-form__row">
    <label class="mc-form__label">
        {{ 'enrichment.Enrichment.Pseudo.Text.TriggerSources' | transloco }}
    </label>
    <mc-form-field class="enrichment-rule-form__trigger-sources">
        <sources-select
            multiple
            [formControl]="form.getControl('triggerSources')">
        </sources-select>
    </mc-form-field>
</div>

<div *ngIf="form.isVisibleControl('throttlingInterval')" class="mc-form__row">
    <label class="mc-form__label">
        {{ 'enrichment.Enrichment.Pseudo.Text.ThrottlingInterval' | transloco }}
    </label>

    <mc-form-field
        mcFormFieldWithoutBorders
        cybsiFormFieldWithoutBorders
        class="enrichment-rule-form__throttling-interval">
        <time-period-field
            [formControl]="form.getControl('throttlingInterval')"
            [periodType]="periodThrottlingInterval"
            (periodTypeChanged)="handleChangePeriodThrottlingInterval($event)"
        ></time-period-field>
        <mc-hint color="second">
            {{ 'enrichment.Enrichment.RuleForm.Hint.ThrottlingInterval' | transloco }}
        </mc-hint>
    </mc-form-field>
</div>
