import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component,
    Directive,
    DoCheck,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    AsyncValidatorFn,
    ControlValueAccessor,
    FormGroupDirective,
    NgControl,
    NgForm,
    ValidationErrors
} from '@angular/forms';
import {
    CanDisable,
    CanDisableCtor,
    CanUpdateErrorState,
    CanUpdateErrorStateCtor,
    ErrorStateMatcher,
    mixinDisabled,
    mixinErrorState
} from '@ptsecurity/mosaic/core';
import { McFormFieldControl, McValidateDirective } from '@ptsecurity/mosaic/form-field';
import { McInput } from '@ptsecurity/mosaic/input';
import { ConnectableObservable, Observable, of } from 'rxjs';
import { catchError, map, publish, take, takeUntil, tap } from 'rxjs/operators';

import { ObservableEntitiesApiService } from '@pt-cybsi/api';
import { ObservableEntityType, TObservableEntityKey, TObservableEntityKeyType } from '@pt-cybsi/api-interfaces';
import { AsyncValidationService, DestroyService, McFieldBase } from '@pt-cybsi/shared';

let nextUniqueId = 0;

const EntityKeyFieldMixinBase: CanDisableCtor & CanUpdateErrorStateCtor & typeof McFieldBase = mixinDisabled(
    mixinErrorState(McFieldBase)
);

/**
 * @component EntityKeyField
 *
 * @description
 * Form field for enter a single key of observable entity.
 *
 * Has build-in async validation of key triggered by a blur of a field. Sets `invalidKey` error if validation is failure.
 * If the received key during async validation is not equal entered key, then it would be replaced by the received key.
 *
 * @param id - Unique id of field. Optional, will be generated automatically
 * @param ngModel - key of observable entity
 * @param entityType - type of observable entity
 * @param keyType - type of observable entity key
 * @param placeholder - Placeholder text. Optional, will be used empty text
 * @param disabled - Boolean value for marking the field as disabled. Optional, will be used `false`
 * @param required - Boolean value for marking the field as required. Optional, will be used `false`
 *
 * @example
 * ```html
 * <mc-form-field>
 *      <entity-key-field
 *          [entityType]="entityType"
 *          [keyType]="keyType"
 *          [(ngModel)]="key">
 *      </entity-key-field>
 * </mc-form-field>
 *
 * <mc-form-field>
 *      <entity-key-field
 *          [entityType]="entityType"
 *          [keyType]="keyType"
 *          [formControl]="keyControl">
 *      </entity-key-field>
 * </mc-form-field>
 *
 * <mc-form-field>
 *      <entity-key-field
 *          [id]="'some-id'"
 *          [entityType]="entityType"
 *          [keyType]="keyType"
 *          [placeholder]="'Enter an entity key'"
 *          [(ngModel)]="key"
 *          [required]="true"
 *          [disabled]="true">
 *      </entity-key-field>
 * </mc-form-field>
 * ```
 */
@Component({
    selector: 'entity-key-field',
    templateUrl: './entity-key-field.component.html',
    // prettier-ignore
    providers: [
        DestroyService,
        { provide: McFormFieldControl, useExisting: EntityKeyFieldComponent }
    ],
    host: {
        '[attr.id]': 'id',
        '[attr.disabled]': 'disabled || null',
        '[attr.required]': 'required || null'
    }
})
export class EntityKeyFieldComponent
    extends EntityKeyFieldMixinBase
    implements
        McFormFieldControl<string>,
        ControlValueAccessor,
        OnInit,
        OnChanges,
        DoCheck,
        OnDestroy,
        CanDisable,
        CanUpdateErrorState
{
    @Input()
    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value || this.uid;
        this.stateChanges.next();
    }

    @Input()
    get value(): string {
        return this._value;
    }

    set value(value: string) {
        if (value !== this.value) {
            this._value = value;
            this.stateChanges.next();
        }
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);

        if (this.focused) {
            this.focused = false;
            this.stateChanges.next();
        }
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);

        this.stateChanges.next();
    }

    @Input()
    get focused(): boolean {
        return this.mcInput ? this.mcInput.focused : this._focused;
    }

    set focused(value: boolean) {
        this._focused = coerceBooleanProperty(value);

        if (this.mcInput) {
            this.mcInput.focused = this._focused;
        }

        this.stateChanges.next();
    }

    @Input() placeholder: string;
    @Input() entityType: ObservableEntityType;
    @Input() keyType: TObservableEntityKeyType;

    get empty(): boolean {
        return this._value === null || this._value === undefined;
    }

    errorState: boolean;
    controlType = 'entity-key-field';

    onChange: (value: string) => void;
    onTouched: () => void;

    @ViewChild('mcInput', { read: McInput }) mcInput: McInput;

    private _id: string;
    private _value: string;
    private _disabled = false;
    private _focused = false;
    private _required = false;

    private startKeyValidation: () => void;

    private readonly uid = `${this.controlType}-${nextUniqueId++}`;

    constructor(
        public defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() public parentForm: NgForm,
        @Optional() public parentFormGroup: FormGroupDirective,
        @Self() public ngControl: NgControl,
        private asyncValidationService: AsyncValidationService,
        private observableEntitiesApiService: ObservableEntitiesApiService,
        private destroyed$: DestroyService
    ) {
        super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

        this.ngControl.valueAccessor = this;

        // eslint-disable-next-line no-self-assign
        this.id = this.id;
    }

    ngOnInit() {
        this.initAsyncValidators();
    }

    ngOnChanges() {
        this.stateChanges.next();
    }

    ngDoCheck() {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }

    handleInputChange(value: string) {
        this.value = value;

        this.onChange(value);
    }

    handleInputFocus() {
        if (!this.disabled) {
            this.focused = true;
        }
    }

    handleInputBlur() {
        this.focused = false;

        this.startKeyValidation();
    }

    onContainerClick(): void {
        this.focus();
    }

    focus(): void {
        if (!this.disabled) {
            this.focused = true;

            this.mcInput.focus();
        }
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private initAsyncValidators(): void {
        const validator = this.createKeyValidator();

        const { validation$, validate: startKeyValidation } = this.asyncValidationService.registerValidator(
            this.ngControl.control,
            validator
        );

        this.startKeyValidation = startKeyValidation;

        (validation$.pipe(takeUntil(this.destroyed$), publish()) as ConnectableObservable<string>).connect();
    }

    private createKeyValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> =>
            this.validateKey(control.value as string).pipe(map((isValid) => (isValid ? null : { invalidKey: true })));
    }

    private validateKey(value: string): Observable<boolean> {
        return this.observableEntitiesApiService.getCanonicalKey(this.entityType, this.keyType, value).pipe(
            tap((key) => this.updateValueByCanonicalKey(key)),
            map(() => true),
            catchError(() => of(false)),
            take(1),
            takeUntil(this.destroyed$)
        );
    }

    private updateValueByCanonicalKey(key: TObservableEntityKey): void {
        if (key.value !== this.value) {
            this.value = key.value;

            this.onChange(key.value);
        }
    }
}

@Directive({
    selector: 'entity-key-field',
    exportAs: 'EntityKeyFieldMcValidate'
})
export class EntityKeyFieldMcValidateDirective extends McValidateDirective {}
