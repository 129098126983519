import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FormMode, FormStateValue } from '@pt-cybsi/shared';

import { SignInFormConfig, SignInFormViewModel, TSignInFormData } from './sign-in-form.view-model';

@Injectable()
export class SignInFormBuilder {
    formConfig = SignInFormConfig;

    constructor(private fb: UntypedFormBuilder) {}

    build(data: TSignInFormData): SignInFormViewModel {
        const form = this.buildFormGroup();

        return new SignInFormViewModel(
            {
                mode: FormMode.Creation,
                state: FormStateValue.Initializing,
                initialData: data,
                currentData: data,
                savingError: null
            },
            form,
            this.formConfig
        );
    }

    private buildFormGroup(): UntypedFormGroup {
        const { id, providerId, login, password, rememberMe } = this.formConfig;

        const required = Validators.required;

        return this.fb.group({
            [id.controlName]: undefined,
            [providerId.controlName]: undefined,
            [login.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ],
            [password.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ],
            [rememberMe.controlName]: undefined
        });
    }
}
