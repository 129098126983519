export interface IApiKey {
    id: string;
    url?: string;
    description: string;
    createdAt: string;
    expiresAt: string;
    lastUsedAt: string;
    revoked: boolean;
    permissions: string[];
}

export interface ICreateApiKeyData {
    description?: string;
    expiresAt?: string;
    permissions?: string[];
}

export interface ICreateApiKeyResponse {
    id: string;
    url: string;
    key: string;
}

export interface IUpdateApiKeyData {
    description?: string;
    revoked?: boolean;
}
