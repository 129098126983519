import { ApiResource, PermissionType } from '@pt-cybsi/api-interfaces';
import { buildPermissionString, RootPermissionsService } from '@pt-cybsi/domain-core/account';

const Read = PermissionType.Read;
const Write = PermissionType.Write;

export class DictionariesPermissionsService {
    static readonly viewListPermissions = RootPermissionsService.viewDictionariesPermissions;

    static readonly viewPermissions = [buildPermissionString(ApiResource.Dictionaries, [Read])];

    static readonly editPermissions = [buildPermissionString(ApiResource.Dictionaries, [Write])];
}
