import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { takeUntil } from 'rxjs/operators';

import { AuthenticationErrorCodes, DefaultErrorCodes, HttpStatus } from '@pt-cybsi/api-interfaces';
import { LocationService } from '@pt-cybsi/core';
import { DestroyService, getBrowserTitle } from '@pt-cybsi/shared';
import { ErrorFacade } from '@pt-cybsi/store/error';
import { RouterFacade } from '@pt-cybsi/store/router';

@Component({
    selector: 'error-view',
    providers: [DestroyService],
    templateUrl: './error-view.component.html',
    styleUrls: ['./error-view.component.scss']
})
export class ErrorViewComponent implements OnInit {
    errorCode;

    get hasDescription(): boolean {
        return this.errorDescriptionKey !== undefined;
    }

    get errorTitleTranslationKey(): string {
        switch (this.errorCode) {
            case HttpStatus.NOT_FOUND:
                return '404_ERROR';
            case DefaultErrorCodes.VIEW_FORBIDDEN:
                return 'errors.Errors.ErrorView.Text.ForbiddenPage';
            case DefaultErrorCodes.EMPTY_PERMISSIONS:
                return 'errors.Errors.ErrorView.Title.EmptyPermissions';
            case AuthenticationErrorCodes.LicenseRequestFailure:
            case AuthenticationErrorCodes.EmptyProviders:
            case AuthenticationErrorCodes.UnknownProvider:
            case AuthenticationErrorCodes.Default:
                return 'errors.Errors.ErrorView.Title.AuthError';
            case AuthenticationErrorCodes.InvalidToken:
            case AuthenticationErrorCodes.BadSessionRequest:
            case AuthenticationErrorCodes.SignOut:
            default:
                return 'BAD_REQUEST';
        }
    }

    get errorDescriptionKey() {
        switch (this.errorCode) {
            case HttpStatus.NOT_FOUND:
            case AuthenticationErrorCodes.SignOut:
            case DefaultErrorCodes.VIEW_FORBIDDEN:
                return;
            case DefaultErrorCodes.EMPTY_PERMISSIONS:
            case AuthenticationErrorCodes.InvalidToken:
            case AuthenticationErrorCodes.EmptyProviders:
            case AuthenticationErrorCodes.UnknownProvider:
                return 'ContactAdmin';
            case AuthenticationErrorCodes.Default:
                return 'RetryOrContactAdmin';
            case AuthenticationErrorCodes.LicenseRequestFailure:
                return 'RefreshOrContactAdmin';
            case AuthenticationErrorCodes.ProvidersRequestFailure:
            case AuthenticationErrorCodes.BadSessionRequest:
                return 'Refresh';
            default:
                return 'Default';
        }
    }

    constructor(
        private errorFacade: ErrorFacade,
        private routerFacade: RouterFacade,
        private locationService: LocationService,
        private translocoService: TranslocoService,
        private window: Window,
        private destroyed$: DestroyService
    ) {}

    ngOnInit(): void {
        this.errorFacade.errorCode$.pipe(takeUntil(this.destroyed$)).subscribe((errorCode) => {
            this.errorCode = errorCode;

            if (this.errorCode) {
                this.routerFacade.setPageTitle(
                    getBrowserTitle(this.translocoService.translate(this.errorTitleTranslationKey))
                );
            }
        });
    }

    refreshPage() {
        this.window.location.reload();
    }

    goHomePage() {
        this.locationService.redirectToEntryUrl();
    }

    retrySignIn() {
        this.locationService.redirectToSignInPage();
    }
}
