import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ROUTER_FEATURE_KEY, IState } from './router.reducer';

export const getRouterState = createFeatureSelector<IState>(ROUTER_FEATURE_KEY);
export const getRouteState = createSelector(getRouterState, (state) => state.state);

export const getRouteParams = createSelector(getRouteState, (state) => state.params);
export const getQueryParams = createSelector(getRouteState, (state) => state.queryParams);
export const getIsLegacyMode = createSelector(getRouterState, (state) => state.isLegacyMode);
export const getLegacyUrl = createSelector(getRouterState, (state) => state.legacyUrl);
export const getIsNavbarVisible = createSelector(getRouterState, (state) => state.isNavbarVisible);
export const getIsChangedForm = createSelector(getRouterState, (state) => state.isChangedForm);
export const getPageTitle = createSelector(getRouterState, (state) => state.pageTitle);
