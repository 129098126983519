import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import isEmpty from 'lodash/isEmpty';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EnrichmentApiService } from '@pt-cybsi/api';
import {
    AddEnrichmentRuleErrorCode,
    ArtifactType,
    EnrichmentType,
    HttpStatus,
    HttpStatusCode,
    IApiItemPreview,
    IEnrichmentRuleCreateParams,
    IEnrichmentRuleUpdateParams,
    IServerError,
    ObservableEntityType,
    TEditableEnrichmentRuleResponse,
    UpdateEnrichmentRuleErrorCode
} from '@pt-cybsi/api-interfaces';
import { FormMode, FormSavingError, FormsStore, FormState, IFormRepository, isServerError } from '@pt-cybsi/shared';

import { EnrichmentRulesFormsStore, EnrichmentRulesFormsStoreProvider } from '../forms';
import { EnrichmentRuleModelMapper, TEnrichmentRuleFormData } from '../mappers';
import { EnrichmentRuleModel } from '../models';
import { EnrichmentRuleFormSavingError } from '../types';

@Injectable()
export class EnrichmentRuleFormRepository implements IEnrichmentRuleFormRepository {
    constructor(private apiService: IEnrichmentRulesApiService, private store: IEnrichmentRulesFormsStore) {}

    add(mode: FormMode, data: EnrichmentRuleModel): void {
        const formData = EnrichmentRuleModelMapper.toFormData(data);

        this.store.addForm(mode, formData);
    }

    delete(id: string): void {
        this.store.deleteForm(id);
    }

    getState(id: string): FormState {
        const stateValue = this.store.getState(id);

        return FormState.create(stateValue);
    }

    updateState(id: string, state: FormState): void {
        this.store.updateState(id, state.value);
    }

    getInitialData(id: string): EnrichmentRuleModel {
        const data = this.store.getInitialData(id);

        return EnrichmentRuleModelMapper.fromFormData(data);
    }

    updateInitialData(data: EnrichmentRuleModel): void {
        const formData = EnrichmentRuleModelMapper.toFormData(data);

        this.store.updateInitialData(formData);
    }

    getCurrentData(id: string): EnrichmentRuleModel {
        const data = this.store.getCurrentData(id);

        return EnrichmentRuleModelMapper.fromFormData(data);
    }

    updateCurrentData(data: EnrichmentRuleModel): void {
        const formData = EnrichmentRuleModelMapper.toFormData(data);

        this.store.updateCurrentData(formData);
    }

    updateSavingError(id: string, error: IServerError): void {
        this.store.updateSavingError(id, error);
    }

    resetSavingError(id: string): void {
        this.store.updateSavingError(id, null);
    }

    disable(id: string, fields?: (keyof EnrichmentRuleModel['props'])[]): void {
        this.store.disable(id, fields);
    }

    enable(id: string, fields?: (keyof EnrichmentRuleModel['props'])[]): void {
        this.store.enable(id, fields);
    }

    showControls(id: string, names: (keyof EnrichmentRuleModel['props'])[]): void {
        this.store.showControls(id, names);
    }

    hideControls(id: string, names: (keyof EnrichmentRuleModel['props'])[]): void {
        this.store.hideControls(id, names);
    }

    isTouched(id: string): boolean {
        return this.store.isTouched(id);
    }

    validate(id: string): Observable<boolean> {
        return this.store.validate(id);
    }

    updateAvailableEnrichmentTypes(id: string, types: EnrichmentType[]): void {
        this.store.updateAvailableEnrichmentTypes(id, types);
    }

    updateAvailableArtifactTypes(id: string, types: ArtifactType[]): void {
        this.store.updateAvailableArtifactTypes(id, types);
    }

    updateAvailableEntityTypes(id: string, types: ObservableEntityType[]): void {
        this.store.updateAvailableEntityTypes(id, types);
    }

    loadData(id: string): Observable<EnrichmentRuleModel> {
        return this.apiService
            .getEditableRule(id)
            .pipe(map((editableRule) => EnrichmentRuleModelMapper.fromEditableRule(editableRule)));
    }

    save(model: EnrichmentRuleModel): Observable<string> {
        const formMode = this.store.getFormMode(model.uid);

        return formMode === FormMode.Creation ? this.registerRule(model) : this.updateRule(model);
    }

    private registerRule(model: EnrichmentRuleModel): Observable<string> {
        const params = EnrichmentRuleModelMapper.toRegisterParams(model);

        return this.apiService.createRule(params).pipe(
            map((response) => response.uuid),
            catchError((response: unknown) => {
                const errorResponse = response as HttpErrorResponse;
                const error = errorResponse.error as unknown;

                if (isServerError(error) && error.code === AddEnrichmentRuleErrorCode.MisconfiguredDataSource) {
                    return throwError({ code: EnrichmentRuleFormSavingError.MisconfiguredDataSource });
                }

                return throwError({ code: FormSavingError.Common });
            })
        );
    }

    private updateRule(model: EnrichmentRuleModel): Observable<string> {
        const { serverId, eTag } = model.props;

        const params = EnrichmentRuleModelMapper.toUpdateParams(model);

        if (isEmpty(params)) {
            return of(serverId);
        }

        return this.apiService.updateRule(serverId, eTag, params).pipe(
            map(() => serverId),
            catchError((response: unknown) => {
                const errorResponse = response as HttpErrorResponse;
                const error = errorResponse.error as unknown;

                if (isServerError(error) && error.code === HttpStatus.PRECONDITION_FAILED) {
                    return throwError({ code: FormSavingError.AlreadyChanged });
                }

                if (isServerError(error) && error.code === UpdateEnrichmentRuleErrorCode.MisconfiguredDataSource) {
                    return throwError({ code: EnrichmentRuleFormSavingError.MisconfiguredDataSource });
                }

                return throwError({ code: FormSavingError.Common });
            })
        );
    }
}

export const EnrichmentRuleFormRepositoryProvider: Provider = [
    EnrichmentRulesFormsStoreProvider,
    {
        provide: EnrichmentRuleFormRepository,
        useFactory: (apiService: EnrichmentApiService, store: EnrichmentRulesFormsStore) =>
            new EnrichmentRuleFormRepository(apiService, store),
        deps: [EnrichmentApiService, EnrichmentRulesFormsStore]
    }
];

interface IEnrichmentRulesApiService {
    getEditableRule(uuid: string): Observable<TEditableEnrichmentRuleResponse>;
    createRule(params: IEnrichmentRuleCreateParams): Observable<IApiItemPreview>;
    updateRule(uuid: string, eTag: string, params: IEnrichmentRuleUpdateParams): Observable<void>;
}

export interface IEnrichmentRuleFormRepository extends IFormRepository<EnrichmentRuleModel> {
    updateAvailableEnrichmentTypes(id: string, types: EnrichmentType[]): void;
    updateAvailableArtifactTypes(id: string, types: ArtifactType[]): void;
    updateAvailableEntityTypes(id: string, types: ObservableEntityType[]): void;
}

export interface IEnrichmentRulesFormsStore extends FormsStore<TEnrichmentRuleFormData> {
    updateAvailableEnrichmentTypes(id: string, types: EnrichmentType[]): void;
    updateAvailableArtifactTypes(id: string, types: ArtifactType[]): void;
    updateAvailableEntityTypes(id: string, types: ObservableEntityType[]): void;
}
