import { IPaginationParams } from '../common';

export interface IReportsFilterParams extends IPaginationParams {
    fileUUID?: string;
    entityUUID?: string;
    analyzedArtifactUUID?: string;
    reporterUUID?: string[];
    dataSourceUUID?: string[];
    title?: string;
    label?: string[];
    createdAfter?: string;
    createdBefore?: string;
    updatedAfter?: string;
    updatedBefore?: string;
}

export interface ISimilarReportsFilterParams extends IPaginationParams {
    reporterUUID?: string;
    dataSourceUUID?: string;
}

export interface IReportsLabelsFilterParams extends IPaginationParams {
    prefix: string;
}
