import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { Role } from '@pt-cybsi/api-interfaces';
import { createASCSortComparator } from '@pt-cybsi/shared';

/**
 * @pipe TranslateRole
 *
 * @description Converts role to localized string
 */
@Pipe({
    name: 'translateRole'
})
export class TranslateRolePipe implements PipeTransform {
    constructor(private translocoService: TranslocoService) {}

    transform(role: Role): string {
        return this.translocoService.translate(`users.Users.Pseudo.Role.${role}`);
    }
}

/**
 * @pipe TranslateRoles
 *
 * @description Converts roles to ASC sorted localized strings array
 */
@Pipe({
    name: 'translateRoles'
})
export class TranslateRolesPipe implements PipeTransform {
    private readonly sortComparator = createASCSortComparator<string>((item) => item);

    constructor(private translateRole: TranslateRolePipe) {}

    transform(roles: Role[]): string[] {
        const translatedRoles = roles.map((role) => this.translateRole.transform(role));

        return translatedRoles.sort(this.sortComparator);
    }
}
