import { Injectable, Provider } from '@angular/core';

import { IFormRepository, ValidateFormUseCase } from '@pt-cybsi/shared';

import { SourceModel } from '../../models';
import { SourceFormRepository, SourceFormRepositoryProvider } from '../../repositories';

@Injectable()
export class ValidateSourceUseCase extends ValidateFormUseCase<SourceModel> {
    constructor(repository: IFormRepository<SourceModel>) {
        super(repository);
    }
}

export const ValidateSourceUseCaseProvider: Provider = [
    SourceFormRepositoryProvider,
    {
        provide: ValidateSourceUseCase,
        useFactory: (repository: SourceFormRepository) => new ValidateSourceUseCase(repository),
        deps: [SourceFormRepository]
    }
];
