import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';

import {
    IEditableResource,
    IEnrichmentExternalDB,
    IEnrichmentExternalDBCreateParams,
    IEnrichmentExternalDBUpdateParams,
    ObservableEntityType
} from '@pt-cybsi/api-interfaces';
import { SourceModel } from '@pt-cybsi/domain-core/sources';
import { TFormData } from '@pt-cybsi/shared';

import { ExternalDBModel } from '../models';

const milliseconds = 1000;

export class ExternalDBModelMapper {
    static toFormData(model: ExternalDBModel): TExternalDBFormData {
        return { id: model.uid, ...model.props };
    }

    static fromFormData(data: TExternalDBFormData): ExternalDBModel {
        return ExternalDBModel.create({ ...data }, data.id);
    }

    static fromEditableExternalDB(externalDB: IEditableResource<IEnrichmentExternalDB>): ExternalDBModel {
        const { eTag, data } = externalDB;

        return ExternalDBModel.create({
            serverId: data.uuid,
            eTag,
            sourceId: null,
            entityTypes: data.entityTypes,
            webPageURL: data.webPageURL,
            taskExecutionTimeout: data.taskExecutionTimeout ? data.taskExecutionTimeout * milliseconds : null,
            taskExecutionAttemptsCount: data.taskExecutionAttemptsCount
        });
    }

    static toRegisterParams(
        externalDBModel: ExternalDBModel,
        sourceModel: SourceModel
    ): IEnrichmentExternalDBCreateParams {
        const { entityTypes, webPageURL, taskExecutionTimeout, taskExecutionAttemptsCount } = externalDBModel.props;

        const sourceProps = sourceModel.props;

        return {
            dataSourceUUID: sourceProps.serverId,
            entityTypes,
            ...(isEmpty(webPageURL) ? {} : { webPageURL }),
            ...(isNil(taskExecutionTimeout) ? {} : { taskExecutionTimeout: taskExecutionTimeout / milliseconds }),
            ...(isNil(taskExecutionAttemptsCount) ? {} : { taskExecutionAttemptsCount })
        };
    }

    static toUpdateParams(externalDBModel: ExternalDBModel): IEnrichmentExternalDBUpdateParams {
        const { entityTypes, webPageURL, taskExecutionTimeout, taskExecutionAttemptsCount } = externalDBModel.props;

        return {
            ...(isUndefined(entityTypes) ? {} : { entityTypes }),
            ...(isUndefined(webPageURL) ? {} : { webPageURL: webPageURL || null }),
            ...(isUndefined(taskExecutionTimeout)
                ? {}
                : { taskExecutionTimeout: taskExecutionTimeout / milliseconds || null }),
            ...(isUndefined(taskExecutionAttemptsCount)
                ? {}
                : { taskExecutionAttemptsCount: taskExecutionAttemptsCount || null })
        };
    }
}

export type TExternalDBFormData = TFormData<{
    serverId: string;
    eTag: string;
    sourceId: string;
    entityTypes: ObservableEntityType[];
    webPageURL: string;
    taskExecutionTimeout: number;
    taskExecutionAttemptsCount: number;
}>;
