<property-row [title]="'enrichment.Enrichment.Pseudo.Text.Type' | transloco">
    <span property-row-content>
        <span>{{ type }}</span>
    </span>
</property-row>

<property-row
    *ngIf="isSourcesVisible"
    [title]="sourcesTitle">
    <limited-collection
        property-row-content
        *ngIf="meta.dataSources.length else emptySourcesTemplate"
        [collection]="meta.dataSources"
        [collectionTitle]= "sourcesTitleCollection"
        [itemTemplate]="sourceTemplate">
    </limited-collection>
</property-row>

<property-row
    *ngIf="hasArtifactTypes"
    [title]="'artifacts.Artifacts.Pseudo.Text.ArtifactTypes' | transloco">
    <span property-row-content>
        <span>{{ artifactTypes }}</span>
    </span>
</property-row>

<property-row
    *ngIf="hasEntityTypes"
    [title]="'entities.ObservableEntities.Pseudo.Text.Types' | transloco">
    <span property-row-content>
        <span>{{ entityTypes }}</span>
    </span>
</property-row>

<property-row [title]="'enrichment.Enrichment.Pseudo.Text.TriggerType' | transloco">
    <span property-row-content>
        <span>{{ triggerTypes }}</span>
    </span>
</property-row>

<div *ngIf="isTriggerParamsVisible" class="margin-top_8">
    <mc-divider class="mc-divider_bold"></mc-divider>

    <h3 class="cybsi-subheading margin-top-bottom_16">
        {{ 'enrichment.Enrichment.Rule.Title.TriggerByRegistrationParams' | transloco }}
    </h3>

    <property-row
        *ngIf="meta.triggerDataSources.length"
        [title]="'enrichment.Enrichment.Pseudo.Text.TriggerSources' | transloco">
        <limited-collection
            property-row-content
            [collection]="meta.triggerDataSources"
            [collectionTitle]= "'enrichment.Enrichment.SidePanel.Title.CountTriggerSources' | transloco: { count: meta.triggerDataSources.length }"
            [itemTemplate]="sourceTemplate">
        </limited-collection>
    </property-row>

    <property-row
        *ngIf="hasThrottlingInterval"
        [title]="'enrichment.Enrichment.Pseudo.Text.ThrottlingInterval' | transloco">
        <span property-row-content>
            <span>{{ throttlingInterval }}</span>
        </span>
    </property-row>
</div>

<ng-template #sourceTemplate let-sourceId="item">
    <source-preview [sourceId]="sourceId"></source-preview>
</ng-template>

<ng-template #emptySourcesTemplate>
    <span>{{ 'enrichment.Enrichment.Rule.Text.EmptySources' | transloco }}</span>
</ng-template>
