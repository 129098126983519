import { ChangeDetectorRef, Component, Directive, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { McButtonToggleChange } from '@ptsecurity/mosaic/button-toggle';
import { toBoolean } from '@ptsecurity/mosaic/core';
import { McFormFieldControl, McValidateDirective } from '@ptsecurity/mosaic/form-field';
import { McPopoverTrigger } from '@ptsecurity/mosaic/popover';
import isEqual from 'lodash/isEqual';
import { DateTime } from 'luxon';

import { ToStringDateRangePipe } from '../../pipes';
import { DestroyService } from '../../services';
import {
    IAbsoluteDateRange,
    IRelativeDateRange,
    isAbsoluteDateRange,
    isRelativeDateRange,
    RelativeDateType,
    TDateRange
} from '../../types';

export enum DateRangeFieldItemType {
    Relative = 'Relative',
    Absolute = 'Absolute'
}

export interface IDateRangeFieldRelativeItem {
    type: DateRangeFieldItemType.Relative;
    value: IRelativeDateRange;
}

export interface IDateRangeFieldAbsoluteItem {
    type: DateRangeFieldItemType.Absolute;
    value: IAbsoluteDateRange;
}

export type TDateRangeFieldItem = IDateRangeFieldRelativeItem | IDateRangeFieldAbsoluteItem;

let nextUniqueId = 0;

const DEFAULT_FIELD_ITEMS: TDateRangeFieldItem[] = [
    {
        type: DateRangeFieldItemType.Relative,
        value: { type: RelativeDateType.Days, value: 1 }
    },
    {
        type: DateRangeFieldItemType.Relative,
        value: { type: RelativeDateType.Days, value: 7 }
    },
    {
        type: DateRangeFieldItemType.Relative,
        value: { type: RelativeDateType.Days, value: 30 }
    },
    {
        type: DateRangeFieldItemType.Absolute,
        value: { from: DateTime.now().minus({ months: 2 }).startOf('day'), to: DateTime.now().endOf('day') }
    }
];

/**
 * @component DateRangeField
 *
 * @description
 * Form field for selecting date range (relative or absolute)
 *
 * @param id - Unique id of field. Optional, will be generated automatically
 * @param availableItems - list of available for selecting items in TDateRangeFieldItem format
 * @param ngModel - date range value in TDateRange format
 * @param disabled - Boolean value for marking the field as disabled. Optional, will be used `false`
 * @param required - Boolean value for marking the field as required. Optional, will be used `false`
 *
 * @example
 * ```html
 * <date-range-field
 *      [availableItems]="availableItems"
 *      [(ngModel)]="value">
 * </date-range-field>
 *
 * <date-range-field
 *      [availableItems]="availableItems"
 *      [formControl]="formControl">
 * </date-range-field>
 * ```
 */
@Component({
    selector: 'date-range-field',
    templateUrl: './date-range-field.component.html',
    styleUrls: ['./date-range-field.component.scss'],
    providers: [
        {
            provide: McFormFieldControl,
            useExisting: DateRangeFieldComponent
        },
        DestroyService
    ],
    host: {
        class: 'date-range-field'
    }
})
export class DateRangeFieldComponent implements ControlValueAccessor, OnInit {
    @Input() availableItems: TDateRangeFieldItem[];

    @Input()
    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value || this.uid;
    }

    @Input()
    get value(): TDateRange {
        return this._value;
    }

    set value(value: TDateRange) {
        this._value = value;

        this.updateInnerModels();
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        if (value !== this.required) {
            this._required = toBoolean(value);
        }
    }

    @Input()
    get disabled() {
        return this._disabled;
    }

    set disabled(value: boolean) {
        if (value !== this.disabled) {
            this._disabled = toBoolean(value);
        }
    }

    get fieldItems(): TDateRangeFieldItem[] {
        return this.availableItems || DEFAULT_FIELD_ITEMS;
    }

    get dateRangeFieldAbsoluteItem(): IDateRangeFieldAbsoluteItem {
        return this.fieldItems.find(
            (item): item is IDateRangeFieldAbsoluteItem => item.type === DateRangeFieldItemType.Absolute
        );
    }

    get defaultAbsoluteDateRangeValue(): IAbsoluteDateRange {
        return this.dateRangeFieldAbsoluteItem?.value || null;
    }

    get isApplyAbsoluteRangeDisabled(): boolean {
        return !this.fromDatePickerModel || !this.toDatePickerModel;
    }

    get displayedAbsoluteDateRange(): IAbsoluteDateRange {
        return this.lastAppliedAbsoluteDateRange || this.defaultAbsoluteDateRangeValue;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched: () => void = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChanged: (value: TDateRange) => void = () => {};

    @ViewChild('absoluteDateRangePopover', { read: McPopoverTrigger }) absoluteDateRangePopover: McPopoverTrigger;

    DateRangeFieldItemType = DateRangeFieldItemType;

    controlType = 'date-range-field';

    toggleGroupModel: TDateRangeFieldItem;
    fromDatePickerModel: DateTime;
    toDatePickerModel: DateTime;

    private _id: string;
    private _value: TDateRange;
    private _required: boolean;
    private _disabled = false;

    private lastAppliedAbsoluteDateRange: IAbsoluteDateRange;

    private readonly uid = `${this.controlType}-${nextUniqueId++}`;

    constructor(
        @Optional() public parentForm: NgForm,
        @Optional() public parentFormGroup: FormGroupDirective,
        @Self() public ngControl: NgControl,
        private cd: ChangeDetectorRef,
        private toStringDateRangePipe: ToStringDateRangePipe
    ) {
        this.ngControl.valueAccessor = this;

        // eslint-disable-next-line no-self-assign
        this.id = this.id;
    }

    ngOnInit(): void {
        this.initInnerModels();
    }

    handleSelectRelativeItem($event: McButtonToggleChange): void {
        const item = $event.value as IDateRangeFieldRelativeItem;

        this.updateValue(item.value);
    }

    handleSelectAbsoluteItem($event: McButtonToggleChange): void {
        const item = $event.value as IDateRangeFieldAbsoluteItem;

        this.toggleGroupModel = item;
    }

    handleApplyAbsoluteRange(): void {
        this.absoluteDateRangePopover.hide();

        const range = {
            from: this.fromDatePickerModel ? this.fromDatePickerModel : null,
            to: this.toDatePickerModel ? this.toDatePickerModel : null
        };

        if (!range.from && !range.to) {
            return;
        }

        this.updateValue(range);
    }

    handleCancelAbsoluteRange(): void {
        this.absoluteDateRangePopover.hide();
    }

    handleAbsoluteDateRangePopoverVisibleChange(isOpened: boolean): void {
        if (!isOpened && !isAbsoluteDateRange(this.value)) {
            this.updateInnerModels();
        }

        if (!isOpened) {
            this.fromDatePickerModel = this.displayedAbsoluteDateRange.from;
            this.toDatePickerModel = this.displayedAbsoluteDateRange.to;
        }
    }

    getFieldItemText(item: TDateRangeFieldItem): string {
        switch (item.type) {
            case DateRangeFieldItemType.Relative: {
                return this.toStringDateRangePipe.transform(item.value);
            }

            case DateRangeFieldItemType.Absolute: {
                return translate('common.Common.Pseudo.DateRangeField.SelectedCustomPeriodTitle', {
                    period: this.toStringDateRangePipe.transform(this.displayedAbsoluteDateRange)
                });
            }

            default: {
                return '';
            }
        }
    }

    writeValue(value: TDateRange): void {
        this.value = value;
    }

    registerOnChange(fn: (value: TDateRange) => void): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private updateValue(nextValue: TDateRange): void {
        this.value = nextValue;

        if (isAbsoluteDateRange(this.value)) {
            this.lastAppliedAbsoluteDateRange = this.value;
        }

        this.onChanged(this.value);
    }

    private updateInnerModels(): void {
        if (isAbsoluteDateRange(this.value)) {
            this.toggleGroupModel = this.dateRangeFieldAbsoluteItem;
            this.fromDatePickerModel = this.value.from;
            this.toDatePickerModel = this.value.to;

            this.cd.detectChanges();

            return;
        }

        if (isRelativeDateRange(this.value)) {
            this.toggleGroupModel = this.fieldItems.find(
                (item) => item.type === DateRangeFieldItemType.Relative && isEqual(item.value, this.value)
            );

            this.cd.detectChanges();

            return;
        }
    }

    private initInnerModels(): void {
        if (this.dateRangeFieldAbsoluteItem) {
            this.fromDatePickerModel = this.defaultAbsoluteDateRangeValue.from;
            this.toDatePickerModel = this.defaultAbsoluteDateRangeValue.to;

            this.cd.detectChanges();
        }
    }
}

@Directive({
    selector: 'date-range-field',
    exportAs: 'DateRangeFieldMcValidate'
})
export class DateRangeFieldMcValidateDirective extends McValidateDirective {}
