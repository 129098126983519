import { Pipe, PipeTransform } from '@angular/core';

/**
 * @pipe FormatAsn
 *
 * @description Format ASN number as string with 'AS' prefix
 */
@Pipe({
    name: 'formatAsn'
})
export class FormatAsnPipe implements PipeTransform {
    transform(value: number) {
        return `AS${value}`;
    }
}
