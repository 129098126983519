import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { isApiRequest } from '@pt-cybsi/api';
import { AccountFacade } from '@pt-cybsi/store/account';

import { addToken } from '../utils';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    token: string;

    constructor(private accountFacade: AccountFacade) {
        this.accountFacade.token$.subscribe((token) => (this.token = token));
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (isApiRequest(req) && this.token) {
            return next.handle(addToken(req, this.token));
        }

        return next.handle(req);
    }
}
