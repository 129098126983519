import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import isEmpty from 'lodash/isEmpty';
import { iif, Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { IEditableResource, IPaginationParams, IPaginationResponse } from '@pt-cybsi/api-interfaces';

import { addApiHeader } from '../helpers';

interface IHttpOptions {
    headers?:
        | HttpHeaders
        | {
              [header: string]: string | string[];
          };
    observe: 'body';
    params?:
        | HttpParams
        | {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              [param: string]: any;
          };
    reportProgress?: boolean;
    responseType: 'json';
    withCredentials?: boolean;
}

export interface IFullListResponse<T> {
    currentCursor: string;
    fullList: T[];
}

@Injectable()
export class BaseApiService {
    constructor(protected http: HttpClient) {}

    get<T>(url: string, options = {}): Observable<T> {
        return this.http.get<T>(url, this.getDefaultOptions(this.addSkipUrlParam(options)));
    }

    post<T>(url: string, body: unknown, options = {}): Observable<T> {
        return this.http.post<T>(url, body, this.getDefaultOptions(options));
    }

    put<T>(url: string, body: unknown, options = {}): Observable<T> {
        return this.http.put<T>(url, body, this.getDefaultOptions(options));
    }

    patch<T>(url: string, body: unknown, options = {}): Observable<T> {
        return this.http.patch<T>(url, body, this.getDefaultOptions(options));
    }

    delete<T>(url: string, options = {}): Observable<T> {
        return this.http.delete<T>(url, this.getDefaultOptions(options));
    }

    getEditableResource<T>(url: string, options = {}): Observable<IEditableResource<T>> {
        return this.http
            .get<T>(url, { ...this.getDefaultOptions(this.addSkipUrlParam(options)), observe: 'response' })
            .pipe(
                map((response: HttpResponse<T>) => ({
                    eTag: response.headers.get('etag'),
                    data: response.body
                }))
            );
    }

    getPaginatedCollection<T>(url: string, options = {}): Observable<IPaginationResponse<T>> {
        return this.http
            .get<T>(url, { ...this.getDefaultOptions(this.addSkipUrlParam(options)), observe: 'response' })
            .pipe(
                map((response: HttpResponse<T>) => ({
                    cursor: this.getNextCursor(response.headers),
                    data: response.body
                }))
            );
    }

    getFullList<T>(
        url: string,
        options: { params?: IPaginationParams } & Partial<IHttpOptions> = {}
    ): Observable<IFullListResponse<T>> {
        const requestOptions = {
            ...this.getDefaultOptions(this.addSkipUrlParam(options))
        };
        let data: T[] = [];
        let currentCursor = options.params?.cursor;

        const walkThroughPages = (response: HttpResponse<T[]>): Observable<IFullListResponse<T>> => {
            const nextCursor = this.getNextCursor(response.headers);

            data = [...data, ...response.body];

            if (!isEmpty(nextCursor)) {
                currentCursor = nextCursor;
            }

            return iif(
                () => isEmpty(nextCursor),
                of({ currentCursor, fullList: data }),
                this.http
                    .get<T[]>(url, {
                        ...requestOptions,
                        params: {
                            ...requestOptions.params,
                            cursor: currentCursor
                        },
                        observe: 'response',
                        responseType: 'json'
                    })
                    .pipe(concatMap(walkThroughPages))
            );
        };

        return this.http
            .get<T[]>(url, { ...requestOptions, observe: 'response', responseType: 'json' })
            .pipe(concatMap(walkThroughPages));
    }

    updateResource<T>(url: string, eTag: string, body: T, options: Partial<IHttpOptions> = {}): Observable<void> {
        const updatedOptions: Partial<IHttpOptions> = {
            ...options,
            headers: {
                ...(options.headers || {}),
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'If-Match': eTag
            }
        };

        return this.http.patch<void>(url, body, this.getDefaultOptions(updatedOptions));
    }

    protected getDefaultOptions(options: Partial<IHttpOptions>): IHttpOptions {
        return {
            ...options,
            headers: addApiHeader(
                new HttpHeaders({
                    ...options.headers
                })
            ),
            responseType: 'json',
            observe: options?.observe || 'body'
        };
    }

    protected addResolveRefsParam(options: Partial<IHttpOptions> = {}): Partial<IHttpOptions> {
        return {
            ...options,
            params: {
                ...(options.params || {}),
                resolveRefs: true
            }
        };
    }

    protected addSkipUrlParam(options: Partial<IHttpOptions>): Partial<IHttpOptions> {
        const params = options.params || {};

        return {
            ...options,
            params: {
                embedObjectURL: false,
                ...params
            }
        };
    }

    private getNextCursor(headers: HttpHeaders): string {
        return headers.get('X-Cursor') || null;
    }
}
