import { FormViewModel, TFormConfiguration, TFormData, TFormViewModelProps } from '.';

/**
 * Interface for classes that need to implement a factory method of FormViewModel
 */
export interface IFormViewModelBuilder<
    FormData extends TFormData<unknown>,
    Config extends TFormConfiguration<FormData>
> {
    readonly formConfig: Config;

    build(props: TFormViewModelProps<FormData>): FormViewModel<FormData, Config>;
}
