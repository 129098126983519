import { Component, Input, TemplateRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { AsyncState } from '../../types';

@Component({
    selector: 'async-data',
    templateUrl: './async-data.component.html',
    styleUrls: ['./async-data.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsyncDataComponent {
    @Input()
    state: AsyncState;

    @Input()
    errorTranslationKey: string;

    @Input()
    successTemplate: TemplateRef<unknown>;

    @Input()
    successTemplateData: unknown;

    @Output()
    reload = new EventEmitter<void>();

    get isLoading(): boolean {
        return this.state === AsyncState.Loading;
    }

    get isSuccess(): boolean {
        return this.state === AsyncState.Success;
    }

    get isFailure(): boolean {
        return this.state === AsyncState.Failure;
    }

    handleReload() {
        this.reload.emit();
    }
}
