import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Translation } from '@ngneat/transloco/lib/types';
import { Observable, zip } from 'rxjs';

import { BrandingConfig } from '@pt-cybsi/shared';

@Injectable({ providedIn: 'root' })
export class AppLocaleService {
    constructor(private translocoService: TranslocoService) {}

    loadCommonTranslations(): Observable<Translation> {
        const activeLang = this.translocoService.getActiveLang();

        this.translocoService.setTranslation(
            {
                SoftwareCompany: BrandingConfig.SoftwareCompany,
                LogoBrandLongName: BrandingConfig.LogoBrandLongName,
                LogoBrandName: BrandingConfig.LogoBrandName,
                BrandLongName: BrandingConfig.BrandLongName,
                BrandName: BrandingConfig.BrandName
            },
            `branding/${activeLang}`
        );

        return zip(
            this.translocoService.load(`common/${activeLang}`),
            this.translocoService.load(`account/${activeLang}`),
            this.translocoService.load(`errors/${activeLang}`),
            this.translocoService.load(`system/${activeLang}`),
            this.translocoService.load(`dictionaries/${activeLang}`),
            this.translocoService.load(`replists/${activeLang}`),
            this.translocoService.load(`entities/${activeLang}`),
            this.translocoService.load(`sources/${activeLang}`),
            this.translocoService.load(`artifacts/${activeLang}`),
            this.translocoService.load(`enrichment/${activeLang}`),
            this.translocoService.load(`users/${activeLang}`),
            this.translocoService.load(`dictionaries/${activeLang}`),
            this.translocoService.load(`license/${activeLang}`),
            this.translocoService.load(`statistics/${activeLang}`),
            // загружаем так server.json, чтобы не было scope у ключей
            this.translocoService.load(`${activeLang}`)
        );
    }
}
