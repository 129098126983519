import { ShareLevel } from '../common';
import { IDataSource } from '../data-sources';
import { TObservableEntity } from '../observable';

import { IObservationArchive } from './archive';
import { IObservationDNSLookup } from './dns-lookup';
import { IObservationGeneric } from './generic';
import { IObservationNetworkSession } from './network-session';
import { ObservationType } from './observation-type';
import { IObservationThreat } from './threat';
import { IObservationWhoisLookup } from './whois-lookup';

export interface IObservationHeader<OT = ObservationType> {
    uuid: string;
    url: string;
    dataSource: IDataSource;
    reporter: IDataSource;
    type: OT;
    shareLevel: ShareLevel;
    registeredAt: string;
    seenAt: string;
}

export type TObservation<
    OT = ObservationType,
    EntityFormat extends TObservableEntity = TObservableEntity
> = OT extends ObservationType.Generic
    ? IObservationGeneric<EntityFormat>
    : OT extends ObservationType.NetworkSession
    ? IObservationNetworkSession<EntityFormat>
    : OT extends ObservationType.Threat
    ? IObservationThreat<EntityFormat>
    : OT extends ObservationType.DNSLookup
    ? IObservationDNSLookup<EntityFormat>
    : OT extends ObservationType.WhoisLookup
    ? IObservationWhoisLookup<EntityFormat>
    : OT extends ObservationType.Archive
    ? IObservationArchive<EntityFormat>
    : IObservationGeneric<EntityFormat>;

export type TObservationPreview<OT = ObservationType> = Pick<TObservation<OT>, 'uuid' | 'type' | 'url'>;
export type TObservationBrief<OT = ObservationType> = Omit<TObservation<OT>, 'content'>;
