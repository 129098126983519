import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { LicenseApiService } from '@pt-cybsi/api';
import { AuthenticationErrorCodes, DefaultErrorCodes } from '@pt-cybsi/api-interfaces';
import { LocationService } from '@pt-cybsi/core';
import { isServerError } from '@pt-cybsi/shared';
import { AccountFacade, AccountActions } from '@pt-cybsi/store/account';
import { ErrorFacade } from '@pt-cybsi/store/error';

import * as LicenseStoreActions from './license.actions';

@Injectable()
export class LicenseEffects {
    load$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LicenseStoreActions.loadLicense),
            switchMap(() =>
                this.licenseApiService.getInfo().pipe(
                    map((response) => LicenseStoreActions.loadLicenseSuccess({ data: response })),
                    catchError((response: unknown) => {
                        const errorResponse = response as HttpErrorResponse;

                        const error = isServerError(errorResponse.error)
                            ? errorResponse.error
                            : { code: DefaultErrorCodes.UNKNOWN };

                        if (error.code === DefaultErrorCodes.NOT_FOUND) {
                            return of(LicenseStoreActions.loadLicenseSuccess({ data: null }));
                        }

                        this.locationService.redirectToErrorPage(AuthenticationErrorCodes.LicenseRequestFailure);

                        return of(LicenseStoreActions.loadLicenseFailure());
                    })
                )
            )
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LicenseStoreActions.updateLicense),
            switchMap(() =>
                this.licenseApiService.getInfo().pipe(
                    map((response) => LicenseStoreActions.loadLicenseSuccess({ data: response })),
                    catchError((response: unknown) => {
                        const errorResponse = response as HttpErrorResponse;
                        const error = isServerError(errorResponse.error)
                            ? errorResponse.error
                            : { code: DefaultErrorCodes.UNKNOWN };

                        if (error.code === DefaultErrorCodes.NOT_FOUND) {
                            return of(LicenseStoreActions.loadLicenseSuccess({ data: null }));
                        }

                        this.errorFacade.setError(error.code);

                        return of(LicenseStoreActions.loadLicenseFailure());
                    })
                )
            )
        )
    );

    refresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LicenseStoreActions.refreshLicense),
            switchMap(() => {
                this.accountFacade.fetchUser();

                return this.actions$.pipe(ofType(AccountActions.fetchUserSuccess));
            }),
            switchMap(() =>
                this.licenseApiService.getInfo().pipe(
                    map((response) => LicenseStoreActions.loadLicenseSuccess({ data: response })),
                    catchError((response: unknown) => {
                        const errorResponse = response as HttpErrorResponse;
                        const error = isServerError(errorResponse.error)
                            ? errorResponse.error
                            : { code: DefaultErrorCodes.UNKNOWN };

                        if (error.code === DefaultErrorCodes.NOT_FOUND) {
                            return of(LicenseStoreActions.loadLicenseSuccess({ data: null }));
                        }

                        this.errorFacade.setError(error.code);

                        return of(LicenseStoreActions.loadLicenseFailure());
                    })
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private licenseApiService: LicenseApiService,
        private locationService: LocationService,
        private accountFacade: AccountFacade,
        private errorFacade: ErrorFacade
    ) {}
}
