import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { AgGridEvent, ColDef, GridApi, GridOptions, RowNode } from 'ag-grid-community';
import throttle from 'lodash/throttle';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { defaultGridOptions, isGridEndScrollPosition } from '../../helpers';
import { LazyDataState } from '../../models';
import { DestroyService } from '../../services';

import { LazyGridLoaderComponent } from './lazy-grid-loader.component';
import { ILazyGridProps } from './lazy-grid.types';

const LOADER_ROW_DATA: ILoaderRowData = {
    id: 'LOADER_ROW',
    isLoader: true
};

@Component({
    selector: 'lazy-grid',
    templateUrl: './lazy-grid.component.html',
    styleUrls: ['./lazy-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService]
})
export class LazyGridComponent implements ILazyGridProps, OnChanges {
    @Input()
    state: LazyDataState;

    @Input()
    agGridOptions: Partial<GridOptions>;

    @Input()
    columns: ColDef[];

    @Input()
    data: unknown[];

    @Input()
    errorTitle: string;

    @Input()
    errorDescription: string;

    @Input()
    textReload: string;

    @Output()
    loadMore = new EventEmitter<void>();

    gridApi: GridApi;
    gridOptions: GridOptions = {
        ...defaultGridOptions,
        context: {
            lazyGridContext: this
        },
        frameworkComponents: {
            lazyGridLoader: LazyGridLoaderComponent
        },
        fullWidthCellRenderer: 'lazyGridLoader',
        isFullWidthCell: (row: RowNode) => row.data.isLoader,
        getRowClass: (row) => (row.data.isLoader ? 'row-loader' : ''),
        getRowNodeId: (data) => data?.id
    };
    rowData: unknown[];

    onGridInit$: Subject<void> = new Subject();

    get isLazyLoadingAvailable(): boolean {
        return this.state === LazyDataState.Pending;
    }

    get isAgGridInit(): boolean {
        return this.gridApi !== undefined && this.gridApi !== null;
    }

    constructor(private element: ElementRef, private destroyed$: DestroyService) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        this.handleScrollEnd = throttle(this.handleScrollEnd.bind(this), 100);
    }

    ngOnChanges(changes: SimpleChanges) {
        const isOptionsChanged =
            changes.agGridOptions?.currentValue &&
            changes.agGridOptions.currentValue !== changes.agGridOptions.previousValue;

        const isDataChanged = changes.data?.currentValue && changes.data.currentValue !== changes.data.previousValue;

        const isStateChanged =
            changes.state?.currentValue && changes.state.currentValue !== changes.state.previousValue;

        if (isOptionsChanged) {
            this.updateGridOptions(this.agGridOptions);
        }

        if (isDataChanged) {
            this.rowData = [...this.data];
        }

        if (isStateChanged) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            this.isAgGridInit
                ? this.handleChangeGridState()
                : this.onGridInit$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.handleChangeGridState());
        }
    }

    handleGridReady($event: AgGridEvent) {
        this.gridApi = $event.api;

        this.onGridInit$.next();
    }

    handleScrollEnd() {
        if (this.isLazyLoadingAvailable) {
            this.loadMore.emit();
        }
    }

    updateGridOptions(options: Partial<GridOptions>) {
        Object.assign(
            this.gridOptions,
            options,
            { context: { ...this.gridOptions.context, ...(options.context || {}) } },
            { frameworkComponents: { ...this.gridOptions.frameworkComponents, ...(options.frameworkComponents || {}) } }
        );
    }

    private handleChangeGridState() {
        this.updateLazyLoadingRow();

        setTimeout(() => {
            if (this.isLazyLoadingAvailable && isGridEndScrollPosition(this.element.nativeElement as Element)) {
                this.loadMore.emit();
            }
        });
    }

    private updateLazyLoadingRow() {
        switch (this.state) {
            case LazyDataState.Loading:
            case LazyDataState.Error: {
                this.addLazyLoadingRow();

                break;
            }

            default: {
                this.removeLazyLoadingRow();
            }
        }
    }

    private addLazyLoadingRow() {
        if (!this.hasLoaderRow()) {
            const isNeedScrollToLoader = isGridEndScrollPosition(this.element.nativeElement as Element);

            this.gridApi.applyTransaction({
                add: [LOADER_ROW_DATA],
                addIndex: this.rowData.length
            });

            // We need to say ag-grid, that our row with loader has another height value
            this.updateLoaderRowHeight({ isNeedScrollToLoader });
        }
    }

    private removeLazyLoadingRow() {
        if (this.hasLoaderRow()) {
            const loaderRow = this.getLoaderRow();

            this.gridApi.applyTransaction({
                remove: [loaderRow]
            });
        }
    }

    private updateLoaderRowHeight(options: { isNeedScrollToLoader: boolean }): void {
        const loaderRow = this.getLoaderRow();

        const loaderElement = this.element.nativeElement.querySelector('lazy-grid-loader') as HTMLElement;
        const loaderHeight = loaderElement.offsetHeight;

        const onRowHeightChanged: () => void = this.gridApi.onRowHeightChanged.bind(this.gridApi);

        setTimeout(() => {
            loaderRow.setRowHeight(loaderHeight);
            onRowHeightChanged();

            if (options.isNeedScrollToLoader) {
                this.gridApi.ensureNodeVisible(loaderRow, 'bottom');
            }
        });
    }

    private getLoaderRow(): RowNode {
        return this.gridApi?.getModel().getRowNode(LOADER_ROW_DATA.id);
    }

    private hasLoaderRow(): boolean {
        return !!this.getLoaderRow();
    }
}

export interface ILoaderRowData {
    id: string;
    isLoader: boolean;
}

export const isLoaderRowData = (nodeData: unknown): nodeData is ILoaderRowData => {
    const loaderData = nodeData as ILoaderRowData;

    return loaderData.id === LOADER_ROW_DATA.id;
};
