<div *transloco="let tLicense; read: 'license'">
    <div class="layout-margin-bottom-l">
        {{ tLicense('License.Form.Text.Description') }}
    </div>
    <mc-form-field mcFormFieldWithoutBorders cybsiFormFieldWithoutBorders>
        <select-file-field [formControl]="form.getControl('file')"></select-file-field>

        <mc-hint *ngIf="form.hasError('file', 'licenseFileMissing')" color="error">
            {{ tLicense('License.Form.Error.LicenseFileMissing') }}
        </mc-hint>
        <mc-hint *ngIf="form.hasError('file', 'invalidProductLicense')" color="error">
            {{ tLicense('License.Form.Error.InvalidProductLicense') }}
        </mc-hint>
        <mc-hint *ngIf="form.hasError('file', 'expiredLicense')" color="error">
            {{ tLicense('License.Form.Error.ExpiredLicense') }}
        </mc-hint>
    </mc-form-field>
</div>
