import { UntypedFormGroup } from '@angular/forms';

import { Locale } from '@pt-cybsi/i18n';
import { buildFormConfiguration, FormViewModel, TFormData, TFormViewModelProps } from '@pt-cybsi/shared';

export class CurrentUserFormViewModel extends FormViewModel<TCurrentUserFormData, TCurrentUserFormConfig> {
    constructor(
        public props: TFormViewModelProps<TCurrentUserFormData>,
        public form: UntypedFormGroup,
        public formConfig: TCurrentUserFormConfig
    ) {
        super(props, form, formConfig);

        super.disable(['login']);
    }

    getChangedData(): Partial<TCurrentUserFormData> {
        return super.getChangedData(['eTag']);
    }
}

const FormConfig = {
    id: {
        controlName: 'id',
        errorNames: null
    },
    eTag: {
        controlName: 'eTag',
        errorNames: null
    },
    login: {
        controlName: 'login',
        errorNames: null
    },
    fullName: {
        controlName: 'fullName',
        errorNames: {
            length: 'length'
        }
    },
    email: {
        controlName: 'email',
        errorNames: {
            length: 'length'
        }
    },
    language: {
        controlName: 'language',
        errorNames: null
    }
};

export type TCurrentUserFormData = TFormData<{
    eTag: string;
    login: string;
    fullName: string;
    email: string;
    language: Locale;
}>;

export type TCurrentUserFormConfig = typeof FormConfig;

export const CurrentUserFormConfig = buildFormConfiguration<TCurrentUserFormData, TCurrentUserFormConfig>(FormConfig);
