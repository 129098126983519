import { Component, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { SourceFormViewModel } from '../../forms';
import {
    SOURCE_MAX_LENGTH,
    SOURCE_MIN_LENGTH,
    SOURCE_SHORT_NAME_MAX_LENGTH,
    SOURCE_SHORT_NAME_MIN_LENGTH
} from '../../validators';

@Component({
    selector: 'source-form',
    templateUrl: './source-form.component.html',
    styleUrls: ['./source-form.component.scss'],
    host: {
        class: 'mc-form-vertical'
    }
})
export class SourceFormComponent {
    @Input() form: SourceFormViewModel;

    get isShortNameHintVisible(): boolean {
        return !this.form.hasErrors('shortName') || this.form.hasError('shortName', 'format');
    }

    invalidNameLengthMessage: string = this.getInvalidLengthMessage(SOURCE_MIN_LENGTH, SOURCE_MAX_LENGTH);
    invalidShortNameLengthMessage: string = this.getInvalidLengthMessage(
        SOURCE_SHORT_NAME_MIN_LENGTH,
        SOURCE_SHORT_NAME_MAX_LENGTH
    );

    constructor(private translocoService: TranslocoService) {}

    private getInvalidLengthMessage(min: number, max: number): string {
        return min > 1
            ? this.translocoService.translate('common.Common.Validation.Text.StringLengthBetween', { min, max })
            : this.translocoService.translate('common.Common.Validation.Text.StringLengthMax', { max });
    }
}
