import { Directive, Input, HostBinding } from '@angular/core';

import { ShareLevel } from '@pt-cybsi/api-interfaces';

const ICONS_MAP: Record<ShareLevel, string> = {
    [ShareLevel.White]: 'pt-icons-lock-locked-o_16',
    [ShareLevel.Green]: 'pt-icons-lock-locked_16',
    [ShareLevel.Amber]: 'pt-icons-lock-locked_16',
    [ShareLevel.Red]: 'pt-icons-lock-locked_16'
};

@Directive({
    selector: '[shareLevelIcon]'
})
export class ShareLevelIconDirective {
    @Input() shareLevelIcon: ShareLevel;

    @HostBinding('class')
    get shareLevelClass(): string {
        const modifier = `share-level-icon_${this.shareLevelIcon.toLowerCase()}`;
        const iconClass = ICONS_MAP[this.shareLevelIcon];

        return `pt-icons share-level-icon ${modifier} ${iconClass}`;
    }
}
