import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { FILE_KEY_TYPES, IFileKey, TFileKeyType } from '@pt-cybsi/api-interfaces';
import { IHashSum, HashSumType, sortHashesByPriorityComparator } from '@pt-cybsi/shared';

/**
 * @component FileKeys
 *
 * @description
 * Component for displaying file entity natural keys
 *
 * @param keys - List of file entity natural keys
 *
 * @example
 * ```html
 *
 * <file-keys [keys]="keys"></file-keys>
 *
 * ```
 */
@Component({
    selector: 'file-keys',
    templateUrl: './file-keys.component.html',
    styleUrls: ['./file-keys.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileKeysComponent implements OnChanges {
    @Input() keys: IFileKey[];

    hashSums: IHashSum[] = [];
    sortHashesByPriorityComparator = sortHashesByPriorityComparator;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.keys.currentValue !== changes.keys.previousValue) {
            this.hashSums = FILE_KEY_TYPES.map((type) => ({
                type: mapFileKeyTypeToHashSumType(type),
                value: this.keys.find((key) => key.type === type)?.value || null
            }));
        }
    }
}

const mapFileKeyTypeToHashSumType = (keyType: TFileKeyType): HashSumType => {
    switch (keyType) {
        case 'MD5Hash':
            return HashSumType.MD5;
        case 'SHA1Hash':
            return HashSumType.SHA1;
        case 'SHA256Hash':
            return HashSumType.SHA256;
        default:
            return null;
    }
};
