<mc-progress-spinner
    *ngIf="isLoading"
    class="lazy-loader-progress__spinner"
    mode="indeterminate">
</mc-progress-spinner>

<div *ngIf="isError" class="lazy-loader-progress__error">
    <div class="lazy-loader-progress__error-title margin-bottom_4">
        {{ errorTitle || defaultErrorTitle }}
    </div>

    <div *ngIf="hasDescription" class="lazy-loader-progress__error-descriptions margin-bottom_4">
        {{ errorDescription }}
    </div>

    <button
        mc-link
        pseudo
        class="lazy-loader-progress__error-reload"
        (click)="reload.emit()">
        {{ textReload || defaultTextReload }}
    </button>
</div>
