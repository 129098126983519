import { Pipe, PipeTransform } from '@angular/core';

import { AttributeObservableEntity, ObservableEntityType } from '@pt-cybsi/api-interfaces';

import { getLocalizedAttributeName } from '../helpers';

/**
 * @pipe TranslateAttributeName
 *
 * @description Converts attribute name to localized string
 */
@Pipe({
    name: 'translateAttributeName'
})
export class TranslateAttributeNamePipe implements PipeTransform {
    transform(attributeName: AttributeObservableEntity, entityType: ObservableEntityType): string {
        return getLocalizedAttributeName(attributeName, entityType);
    }
}
