import { ObservableEntityType, ShareLevel, TConfidence } from '../common';
import { IDataSource } from '../data-sources';
import { IDictionaryItemBrief } from '../dictionaries';

export enum AttributeObservableEntity {
    Size = 'Size',
    Names = 'Names',
    DisplayNames = 'DisplayNames',
    Class = 'Class',
    Sectors = 'Sectors',
    NodeRoles = 'NodeRoles',
    IsDGA = 'IsDGA',
    IsIoC = 'IsIoC',
    IsTrusted = 'IsTrusted',
    IsDelegated = 'IsDelegated',
    RegionalInternetRegistry = 'RegionalInternetRegistry',
    ASN = 'ASN',
    Statuses = 'Statuses',
    MalwareClasses = 'MalwareClasses',
    MalwareFamilies = 'MalwareFamilies',
    RelatedMalwareFamilies = 'RelatedMalwareFamilies',
    ThreatCategory = 'ThreatCategory',
    RelatedThreatCategory = 'RelatedThreatCategory',
    MalwareNames = 'MalwareNames',
    ThreatActors = 'ThreatActors',
    Campaigns = 'Campaigns',
    AffectedCountries = 'AffectedCountries',
    ExploitedVulnerabilities = 'ExploitedVulnerabilities',
    TargetedSectors = 'TargetedSectors'
}

export const ATTRIBUTES = Object.keys(AttributeObservableEntity).map<AttributeObservableEntity>(
    (attributeName) => AttributeObservableEntity[attributeName]
);

export type TCollectionAttribute =
    | AttributeObservableEntity.Names
    | AttributeObservableEntity.DisplayNames
    | AttributeObservableEntity.Sectors
    | AttributeObservableEntity.NodeRoles
    | AttributeObservableEntity.Statuses
    | AttributeObservableEntity.MalwareClasses
    | AttributeObservableEntity.MalwareFamilies
    | AttributeObservableEntity.RelatedMalwareFamilies
    | AttributeObservableEntity.MalwareNames;

export type TVerdictAttribute =
    | AttributeObservableEntity.IsIoC
    | AttributeObservableEntity.IsTrusted
    | AttributeObservableEntity.IsDGA
    | AttributeObservableEntity.IsDelegated
    | AttributeObservableEntity.ThreatCategory
    | AttributeObservableEntity.RelatedThreatCategory;

// prettier-ignore
export type TAttributeValueType<AttributeName = AttributeObservableEntity> =
    AttributeName extends AttributeObservableEntity.Size ? number :
    AttributeName extends AttributeObservableEntity.Names ? string :
    AttributeName extends AttributeObservableEntity.DisplayNames ? string :
    AttributeName extends AttributeObservableEntity.Class ? IdentityClass :
    AttributeName extends AttributeObservableEntity.Sectors ? IDictionaryItemBrief :
    AttributeName extends AttributeObservableEntity.NodeRoles ? NodeRole :
    AttributeName extends AttributeObservableEntity.IsDGA ? boolean :
    AttributeName extends AttributeObservableEntity.IsIoC ? boolean :
    AttributeName extends AttributeObservableEntity.IsTrusted ? boolean :
    AttributeName extends AttributeObservableEntity.IsDelegated ? boolean :
    AttributeName extends AttributeObservableEntity.RegionalInternetRegistry ? RegionalInternetRegistry :
    AttributeName extends AttributeObservableEntity.ASN ? number :
    AttributeName extends AttributeObservableEntity.Statuses ? IDictionaryItemBrief :
    AttributeName extends AttributeObservableEntity.MalwareClasses ? IDictionaryItemBrief :
    AttributeName extends AttributeObservableEntity.MalwareFamilies ? IDictionaryItemBrief :
    AttributeName extends AttributeObservableEntity.RelatedMalwareFamilies ? IDictionaryItemBrief :
    AttributeName extends AttributeObservableEntity.ThreatActors ? IDictionaryItemBrief :
    AttributeName extends AttributeObservableEntity.Campaigns ? IDictionaryItemBrief :
    AttributeName extends AttributeObservableEntity.AffectedCountries ? IDictionaryItemBrief :
    AttributeName extends AttributeObservableEntity.ExploitedVulnerabilities ? IDictionaryItemBrief :
    AttributeName extends AttributeObservableEntity.TargetedSectors ? IDictionaryItemBrief :
    AttributeName extends AttributeObservableEntity.ThreatCategory ? ThreatCategory :
    AttributeName extends AttributeObservableEntity.RelatedThreatCategory ? RelatedThreatCategory :
    AttributeName extends AttributeObservableEntity.MalwareNames ? string :
        | boolean
        | number
        | string
        | NodeRole
        | RegionalInternetRegistry
        | ThreatCategory
        | RelatedThreatCategory
        | IDictionaryItemBrief;

export enum NodeRole {
    CnC = 'CnC',
    TorNode = 'TorNode',
    TorExitNode = 'TorExitNode',
    Proxy = 'Proxy',
    NameServer = 'NameServer',
    MailExchanger = 'MailExchanger',
    Phishing = 'Phishing',
    DynDNS = 'DynDNS',
    Cloud = 'Cloud',
    VPN = 'VPN',
    STUN = 'STUN',
    Sinkhole = 'Sinkhole',
    PayloadDelivery = 'PayloadDelivery',
    ExfiltrationStore = 'ExfiltrationStore',
    CDN = 'CDN',
    BitTorrentTracker = 'BitTorrentTracker'
}

export const NODE_ROLES = Object.keys(NodeRole).map<NodeRole>((role) => NodeRole[role]);

export enum RegionalInternetRegistry {
    RIPE = 'RIPE',
    APNIC = 'APNIC',
    ARIN = 'ARIN',
    AFRINIC = 'AFRINIC',
    LACNIC = 'LACNIC'
}

export const REGIONAL_INTERNET_REGISTRIES = Object.keys(RegionalInternetRegistry).map<RegionalInternetRegistry>(
    (registry) => RegionalInternetRegistry[registry]
);

export enum IdentityClass {
    Individual = 'Individual',
    Group = 'Group',
    Organization = 'Organization',
    Class = 'Class'
}

export const IDENTITY_CLASSES = Object.keys(IdentityClass).map<IdentityClass>((className) => IdentityClass[className]);

export enum ThreatStatus {
    Unknown = 'Unknown',
    NonMalicious = 'NonMalicious',
    Malicious = 'Malicious'
}

export const THREAT_STATUSES = Object.keys(ThreatStatus).map<ThreatStatus>((status) => ThreatStatus[status]);

export enum ThreatCategory {
    Malware = 'Malware',
    Adware = 'Adware',
    Riskware = 'Riskware',
    Clean = 'Clean'
}

export const THREAT_CATEGORIES = Object.keys(ThreatCategory).map<ThreatCategory>(
    (category) => ThreatCategory[category]
);

export enum RelatedThreatCategory {
    Malware = 'Malware',
    Adware = 'Adware',
    Riskware = 'Riskware'
}

export const RELATED_THREAT_CATEGORIES = Object.keys(RelatedThreatCategory).map<RelatedThreatCategory>(
    (category) => RelatedThreatCategory[category]
);

export const ATTRIBUTES_BY_ENTITY_TYPE: Record<ObservableEntityType, AttributeObservableEntity[]> = {
    [ObservableEntityType.DomainName]: [
        AttributeObservableEntity.RelatedThreatCategory,
        AttributeObservableEntity.RelatedMalwareFamilies,
        AttributeObservableEntity.NodeRoles,
        AttributeObservableEntity.IsTrusted,
        AttributeObservableEntity.IsDGA,
        AttributeObservableEntity.IsIoC,
        AttributeObservableEntity.IsDelegated,
        AttributeObservableEntity.Statuses,
        AttributeObservableEntity.ThreatActors,
        AttributeObservableEntity.Campaigns,
        AttributeObservableEntity.AffectedCountries,
        AttributeObservableEntity.ExploitedVulnerabilities,
        AttributeObservableEntity.TargetedSectors
    ],

    [ObservableEntityType.IPAddress]: [
        AttributeObservableEntity.RelatedThreatCategory,
        AttributeObservableEntity.RelatedMalwareFamilies,
        AttributeObservableEntity.NodeRoles,
        AttributeObservableEntity.RegionalInternetRegistry,
        AttributeObservableEntity.ASN,
        AttributeObservableEntity.Statuses,
        AttributeObservableEntity.IsTrusted,
        AttributeObservableEntity.IsIoC,
        AttributeObservableEntity.ThreatActors,
        AttributeObservableEntity.Campaigns,
        AttributeObservableEntity.AffectedCountries,
        AttributeObservableEntity.ExploitedVulnerabilities,
        AttributeObservableEntity.TargetedSectors
    ],

    [ObservableEntityType.EmailAddress]: [
        AttributeObservableEntity.RelatedThreatCategory,
        AttributeObservableEntity.RelatedMalwareFamilies,
        AttributeObservableEntity.IsTrusted,
        AttributeObservableEntity.IsIoC,
        AttributeObservableEntity.DisplayNames,
        AttributeObservableEntity.ThreatActors,
        AttributeObservableEntity.Campaigns,
        AttributeObservableEntity.AffectedCountries,
        AttributeObservableEntity.ExploitedVulnerabilities,
        AttributeObservableEntity.TargetedSectors
    ],

    [ObservableEntityType.URL]: [
        AttributeObservableEntity.RelatedThreatCategory,
        AttributeObservableEntity.RelatedMalwareFamilies,
        AttributeObservableEntity.IsTrusted,
        AttributeObservableEntity.IsIoC,
        AttributeObservableEntity.ThreatActors,
        AttributeObservableEntity.Campaigns,
        AttributeObservableEntity.AffectedCountries,
        AttributeObservableEntity.ExploitedVulnerabilities,
        AttributeObservableEntity.TargetedSectors
    ],

    [ObservableEntityType.Identity]: [
        AttributeObservableEntity.Class,
        AttributeObservableEntity.Names,
        AttributeObservableEntity.Sectors
    ],

    [ObservableEntityType.PhoneNumber]: [AttributeObservableEntity.IsIoC],

    [ObservableEntityType.File]: [
        AttributeObservableEntity.ThreatCategory,
        AttributeObservableEntity.MalwareClasses,
        AttributeObservableEntity.MalwareFamilies,
        AttributeObservableEntity.IsTrusted,
        AttributeObservableEntity.IsIoC,
        AttributeObservableEntity.Size,
        AttributeObservableEntity.Names,
        AttributeObservableEntity.MalwareNames,
        AttributeObservableEntity.ThreatActors,
        AttributeObservableEntity.Campaigns,
        AttributeObservableEntity.AffectedCountries,
        AttributeObservableEntity.ExploitedVulnerabilities,
        AttributeObservableEntity.TargetedSectors
    ]
};

export interface IAttributeValueForecast<T = AttributeObservableEntity> {
    hasConflicts: boolean;
    values: IAttributeForecastValue<T>[];
}

export interface IAttributeForecastValue<T = AttributeObservableEntity> {
    value: TAttributeValueType<T>;
    confidence: TConfidence;
    valuableFacts: IAttributeValuableFact<T>[];
}

export interface IAttributeValuableFact<T = AttributeObservableEntity> {
    dataSource: IDataSource;
    shareLevel: ShareLevel;
    seenAt: string;
    confidence: TConfidence;
    finalConfidence: TConfidence;
    value: TAttributeValueType<T>;
}
