import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { createControlValidator, IFormViewModelBuilder, TFormViewModelProps } from '@pt-cybsi/shared';

import { TEnrichmentRuleFormData } from '../../mappers';
import { validateEnrichmentRuleNameLength, validateMinEnrichmentRuleThrottlingInterval } from '../../validators';

import {
    EnrichmentRuleFormConfig,
    EnrichmentRuleFormViewModel,
    TEnrichmentRuleFormConfig
} from './enrichment-rule-form.view-model';

/**
 * @factory EnrichmentRuleFormBuilder
 *
 * @description
 * Provides a factory method to create EnrichmentRuleFormViewModel.
 * Creates a reactive form of enrichment rule creation/edit form and wrap it in FormViewModel.
 */
@Injectable()
export class EnrichmentRuleFormBuilder
    implements IFormViewModelBuilder<TEnrichmentRuleFormData, TEnrichmentRuleFormConfig>
{
    formConfig = EnrichmentRuleFormConfig;

    constructor(private fb: UntypedFormBuilder) {}

    build(props: TFormViewModelProps<TEnrichmentRuleFormData>): EnrichmentRuleFormViewModel {
        const form = this.buildFormGroup();

        return new EnrichmentRuleFormViewModel(props, form, this.formConfig);
    }

    private buildFormGroup(): UntypedFormGroup {
        const {
            id,
            eTag,
            serverId,
            enrichment,
            name,
            isDisabled,
            isBuiltin,
            triggers,
            entityTypes,
            artifactTypes,
            sources,
            triggerSources,
            throttlingInterval
        } = this.formConfig;

        const required = Validators.required;

        const nameLengthValidator = createControlValidator(validateEnrichmentRuleNameLength, name.errorNames.length);

        const throttlingIntervalMinValueValidator = createControlValidator(
            validateMinEnrichmentRuleThrottlingInterval,
            throttlingInterval.errorNames.min
        );

        return this.fb.group({
            [id.controlName]: [undefined],

            [serverId.controlName]: [undefined],

            [eTag.controlName]: [undefined],

            [enrichment.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ],

            [name.controlName]: [
                undefined,
                {
                    validators: [required, nameLengthValidator]
                }
            ],

            [isDisabled.controlName]: [undefined],

            [isBuiltin.controlName]: [undefined],

            [triggers.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ],

            [entityTypes.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ],

            [artifactTypes.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ],

            [sources.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ],

            [triggerSources.controlName]: [undefined],

            [throttlingInterval.controlName]: [
                undefined,
                {
                    validators: [required, throttlingIntervalMinValueValidator]
                }
            ]
        });
    }
}
