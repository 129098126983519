export interface IAuthProvider {
    id: string;
    name: string;
    type: AuthType;
}

export enum AuthType {
    Direct = 'Direct',
    Indirect = 'Indirect'
}

export enum AuthState {
    AuthenticationWait = 'AuthenticationWait',
    Authenticated = 'Authenticated',
    AuthenticationError = 'AuthenticationError'
}

export interface IAuthSession {
    authProviderID: string;
    state: AuthState;
    token?: IBearerToken;
    authProviderURL?: string;
    expiresAt?: string;
}

export interface IBearerToken {
    accessToken: string;
    tokenType: string;
    expiresIn: number;
}

export interface ISignInParams {
    authProviderID: string;
    credentials?: {
        login: string;
        password: string;
    };
    usePersistedSID?: boolean;
    targetPagePath?: string;
}
