import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    IApiItemPreview,
    IEnrichmentAnalyzer,
    IEnrichmentAnalyzerCreateParams,
    IEnrichmentAnalyzersGetParams,
    IEnrichmentAnalyzerUpdateParams,
    IEnrichmentExternalDB,
    IEnrichmentExternalDBCreateParams,
    IEnrichmentExternalDBsGetParams,
    IEnrichmentExternalDBUpdateParams,
    IEnrichmentRule,
    IEnrichmentRuleCreateParams,
    IEnrichmentRulesGetParams,
    IEnrichmentRuleUpdateParams,
    TEditableEnrichmentAnalyzerResponse,
    TEditableEnrichmentExternalDBResponse,
    TEditableEnrichmentRuleResponse,
    TEnrichmentAnalyzersResponse,
    TEnrichmentExternalDBsResponse,
    TEnrichmentRulesResponse
} from '@pt-cybsi/api-interfaces';

import { BaseApiService, IFullListResponse } from './base-api.service';

@Injectable()
export class EnrichmentApiService extends BaseApiService {
    static readonly urls = {
        rules: 'enrichment/config/rules',
        analyzers: 'enrichment/analyzers',
        externalDBs: 'enrichment/external-dbs'
    };

    getAllRules(params: IEnrichmentRulesGetParams): Observable<IFullListResponse<IEnrichmentRule>> {
        const url = EnrichmentApiService.urls.rules;

        return this.getFullList(url, this.addResolveRefsParam({ params }));
    }

    getRules(params?: IEnrichmentRulesGetParams): Observable<TEnrichmentRulesResponse> {
        const url = EnrichmentApiService.urls.rules;

        return this.getPaginatedCollection(url, this.addResolveRefsParam({ params }));
    }

    getRule(uuid: string): Observable<IEnrichmentRule> {
        const url = `${EnrichmentApiService.urls.rules}/${uuid}`;

        return this.get(url, this.addResolveRefsParam());
    }

    getEditableRule(uuid: string): Observable<TEditableEnrichmentRuleResponse> {
        const url = `${EnrichmentApiService.urls.rules}/${uuid}`;

        return this.getEditableResource(url, this.addResolveRefsParam());
    }

    createRule(params: IEnrichmentRuleCreateParams): Observable<IApiItemPreview> {
        const url = EnrichmentApiService.urls.rules;

        return this.post(url, params);
    }

    updateRule(uuid: string, eTag: string, params: IEnrichmentRuleUpdateParams): Observable<void> {
        const url = `${EnrichmentApiService.urls.rules}/${uuid}`;

        return this.updateResource(url, eTag, params);
    }

    getAllAnalyzers(params?: IEnrichmentAnalyzersGetParams): Observable<IFullListResponse<IEnrichmentAnalyzer>> {
        const url = EnrichmentApiService.urls.analyzers;

        return this.getFullList(url, this.addResolveRefsParam({ params }));
    }

    getAnalyzers(params?: IEnrichmentAnalyzersGetParams): Observable<TEnrichmentAnalyzersResponse> {
        const url = EnrichmentApiService.urls.analyzers;

        return this.get(url, this.addResolveRefsParam({ params }));
    }

    getAnalyzer(uuid: string): Observable<IEnrichmentAnalyzer> {
        const url = `${EnrichmentApiService.urls.analyzers}/${uuid}`;

        return this.get(url, this.addResolveRefsParam());
    }

    getEditableAnalyzer(uuid: string): Observable<TEditableEnrichmentAnalyzerResponse> {
        const url = `${EnrichmentApiService.urls.analyzers}/${uuid}`;

        return this.getEditableResource(url, this.addResolveRefsParam());
    }

    createAnalyzer(params: IEnrichmentAnalyzerCreateParams): Observable<IApiItemPreview> {
        const url = EnrichmentApiService.urls.analyzers;

        return this.post(url, params);
    }

    updateAnalyzer(uuid: string, eTag: string, params: IEnrichmentAnalyzerUpdateParams): Observable<void> {
        const url = `${EnrichmentApiService.urls.analyzers}/${uuid}`;

        return this.updateResource(url, eTag, params);
    }

    getAllExternalDBs(params?: IEnrichmentExternalDBsGetParams): Observable<IFullListResponse<IEnrichmentExternalDB>> {
        const url = EnrichmentApiService.urls.externalDBs;

        return this.getFullList(url, this.addResolveRefsParam({ params }));
    }

    getExternalDBs(params?: IEnrichmentExternalDBsGetParams): Observable<TEnrichmentExternalDBsResponse> {
        const url = EnrichmentApiService.urls.externalDBs;

        return this.get(url, this.addResolveRefsParam({ params }));
    }

    getExternalDB(uuid: string): Observable<IEnrichmentExternalDB> {
        const url = `${EnrichmentApiService.urls.externalDBs}/${uuid}`;

        return this.get(url, this.addResolveRefsParam());
    }

    getEditableExternalDB(uuid: string): Observable<TEditableEnrichmentExternalDBResponse> {
        const url = `${EnrichmentApiService.urls.externalDBs}/${uuid}`;

        return this.getEditableResource(url, this.addResolveRefsParam());
    }

    createExternalDB(params: IEnrichmentExternalDBCreateParams): Observable<IApiItemPreview> {
        const url = EnrichmentApiService.urls.externalDBs;

        return this.post(url, params);
    }

    updateExternalDB(uuid: string, eTag: string, params: IEnrichmentExternalDBUpdateParams): Observable<void> {
        const url = `${EnrichmentApiService.urls.externalDBs}/${uuid}`;

        return this.updateResource(url, eTag, params);
    }
}
