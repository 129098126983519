import {
    AfterViewInit,
    Attribute,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { McListSelectionChange } from '@ptsecurity/mosaic/list';
import { McPopoverTrigger } from '@ptsecurity/mosaic/popover';

import { AsyncState } from '../../types';

export interface IListFilterItem<DataFormat> {
    value: string;
    data: DataFormat;
}

export interface IListFilterItemTemplateContext {
    item: IListFilterItem<unknown>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
type TCommonListFilterItem = IListFilterItem<unknown>;

/**
 * @component List Filter
 *
 * @description
 * Used for creating a filters-panel filter item for filtering data by multiple values.
 * Provides a search feature to enable searching available filter values.
 *
 * @param search - html attribute to enabling a search feature
 * @param title - title of filter, displayed in filter-button as main title
 * @param valueTitle - title of applied filter value, displayed in filter-button as value title if count applied values greater than 1
 * @param appliedItems - list of applied filter items in `IListFilterItem<T>[]` format
 * @param availableItems - list of available filter items in `IListFilterItem<T>[]` format, displayed in a popover list
 * @param state - state of loading available items in `AsyncState` format
 * @param itemTemplate - NgTemplate of item for popover list
 * @param search - event, emitted after changing a value in a search field
 * @param retrySearch - event, emitted after clicking to retry search button
 * @param applyFilter - event, emitted after clicking apply filter button
 * @param resetFilter - event, emitted after clicking reset filter button
 * @param cancelFilter - event, emitted after clicking cancel filter button
 * @param openFilter - event, emitted after open popover
 * @param closeFilter - event, emitted after close popover
 *
 * @example
 * ```html
 * <list-filter
 *     search
 *
 *     [title]="title"
 *     [valueTitle]="valueTitle"
 *     [appliedItems]="appliedItems"
 *     [availableItems]="availableItems"
 *     [state]="state"
 *     [itemTemplate]="itemTemplate"
 *
 *     (search)="handleSearch($event)"
 *     (retrySearch)="handleRetrySearch($event)"
 *     (applyFilter)="handleApply($event)"
 *     (resetFilter)="handleReset()"
 *     (cancelFilter)="handleCancel()"
 *     (openFilter)="handleOpen()"
 *     (closeFilter)="handleClose()">
 * </list-filter>
 *
 * <ng-template #itemTemplate let-item="item">{{ item.data }}</ng-template>
 * ```
 */
@Component({
    selector: 'list-filter',
    templateUrl: './list-filter.component.html',
    styleUrls: ['./list-filter.component.scss'],
    // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None
})
export class ListFilterComponent implements OnChanges, AfterViewInit {
    @Input() title: string;
    @Input() valueTitle: string;
    @Input() appliedItems: TCommonListFilterItem[];
    @Input() availableItems: TCommonListFilterItem[];
    @Input() state: AsyncState = AsyncState.Success;
    @Input() itemTemplate: TemplateRef<{ data: TCommonListFilterItem }>;

    @Output() search: EventEmitter<string> = new EventEmitter<string>();
    @Output() retrySearch: EventEmitter<string> = new EventEmitter<string>();
    @Output() applyFilter: EventEmitter<TCommonListFilterItem[]> = new EventEmitter<TCommonListFilterItem[]>();
    @Output() resetFilter: EventEmitter<void> = new EventEmitter<void>();
    @Output() cancelFilter: EventEmitter<void> = new EventEmitter<void>();
    @Output() openFilter: EventEmitter<void> = new EventEmitter<void>();
    @Output() closeFilter: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('popover', { read: McPopoverTrigger }) popover: McPopoverTrigger;

    @ViewChild('header', { read: TemplateRef }) header: TemplateRef<void>;

    selectedItems: Map<TCommonListFilterItem['value'], TCommonListFilterItem> = new Map();

    searchString: string;
    selectedValues: TCommonListFilterItem['value'][] = [];

    get hasSearch(): boolean {
        return this.searchAttribute !== null;
    }

    get hasAvailableItems(): boolean {
        return this.availableItems?.length > 0;
    }

    get headerTemplate(): TemplateRef<void> {
        return this.hasSearch ? this.header : null;
    }

    get appliedValueTitle(): string {
        if (Array.isArray(this.appliedItems) && this.appliedItems.length > 1) {
            return this.appliedItems.length.toString();
        } else {
            return this.valueTitle;
        }
    }

    get isLoadingState(): boolean {
        return this.state === AsyncState.Loading;
    }

    get isSuccessState(): boolean {
        return this.state === AsyncState.Success;
    }

    get isFailureState(): boolean {
        return this.state === AsyncState.Failure;
    }

    constructor(@Attribute('search') private searchAttribute: boolean) {}

    ngOnChanges(changes: SimpleChanges): void {
        const isAppliedItemsChange = changes.appliedItems?.currentValue !== changes.appliedItems?.previousValue;

        const isAvailableItemsChange = changes.availableItems?.currentValue !== changes.availableItems?.previousValue;

        if (isAppliedItemsChange || isAvailableItemsChange) {
            this.updateSelectedValuesFromAppliedItems();
        }
    }

    ngAfterViewInit(): void {
        this.popover.header = this.headerTemplate;
    }

    handleSelectionChange($event: McListSelectionChange): void {
        const selected = $event.option.selected;
        const value = $event.option.value as string;

        const selectedItem = this.availableItems.find((item) => item.value === value);

        if (selected) {
            this.selectedItems.set(value, selectedItem);
        } else {
            this.selectedItems.delete(value);
        }
    }

    handleSearch(searchString: string): void {
        this.searchString = searchString;

        this.search.emit(this.searchString);
    }

    handleRetry(): void {
        this.retrySearch.emit(this.searchString);
    }

    handleApply(): void {
        this.popover.hide();

        this.applyFilter.emit([...this.selectedItems.values()]);
    }

    handleReset(): void {
        this.resetSelectedValues();

        this.resetFilter.emit();
    }

    handleCancel(): void {
        this.popover.hide();

        this.cancelFilter.emit();
    }

    handlePopoverVisibleChange(isOpened: boolean): void {
        if (isOpened) {
            this.updateSelectedValuesFromAppliedItems();

            this.openFilter.emit();
        } else {
            setTimeout(() => {
                this.searchString = null;
                this.resetSelectedValues();

                this.closeFilter.emit();
            });
        }
    }

    getItemTemplateContext(item: TCommonListFilterItem): IListFilterItemTemplateContext {
        return { item };
    }

    private updateSelectedValuesFromAppliedItems(): void {
        for (const appliedItem of this.appliedItems) {
            this.selectedItems.set(appliedItem.value, appliedItem);
        }

        this.selectedValues = [...this.selectedValues, ...this.selectedItems.keys()];
    }

    private resetSelectedValues(): void {
        this.selectedItems.clear();
        this.selectedValues = [];
    }
}
