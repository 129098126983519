import { Component, Input } from '@angular/core';

import { LicenseFormViewModel } from '../../forms';

@Component({
    selector: 'license-form[form]',
    templateUrl: './license-form.component.html'
})
export class LicenseFormComponent {
    @Input() form: LicenseFormViewModel;
}
