import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { McAutocompleteModule } from '@ptsecurity/mosaic/autocomplete';
import { McCheckboxModule } from '@ptsecurity/mosaic/checkbox';
import { McHighlightModule, McPseudoCheckboxModule } from '@ptsecurity/mosaic/core';
import { McDividerModule } from '@ptsecurity/mosaic/divider';
import { McFormFieldModule } from '@ptsecurity/mosaic/form-field';
import { McInputModule } from '@ptsecurity/mosaic/input';
import { McModalModule } from '@ptsecurity/mosaic/modal';
import { McPopoverModule } from '@ptsecurity/mosaic/popover';
import { McProgressSpinnerModule } from '@ptsecurity/mosaic/progress-spinner';
import { McSelectModule } from '@ptsecurity/mosaic/select';
import { McToggleModule } from '@ptsecurity/mosaic/toggle';
import { McToolTipModule } from '@ptsecurity/mosaic/tooltip';
import { McTreeModule } from '@ptsecurity/mosaic/tree';
import { McTreeSelectModule } from '@ptsecurity/mosaic/tree-select';

import { SharedModule } from '@pt-cybsi/shared';
import { StoreDataSourcesModule } from '@pt-cybsi/store/data-sources';

import {
    SourceFormComponent,
    SourceTypeAutocompleteFieldComponent,
    SourcesGridComponent,
    SourcesTreeGridComponent,
    SourcesTreeGridDetailsComponent,
    SourceTypesGridComponent,
    SourceTypeFormComponent,
    SourceTypeAutocompleteFieldMcValidateDirective
} from './components';
import {
    SourcesFilterComponent,
    SourcePreviewComponent,
    SourcesSelectComponent,
    SourceSelectModalComponent,
    SourcesSelectMcValidateDirective
} from './containers';
import { ConfidenceInputDirective } from './directives';
import { SourcesNavigationService, SourcesSyncService } from './services';

const modules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    McToolTipModule,
    McAutocompleteModule,
    McInputModule,
    McCheckboxModule,
    McDividerModule,
    McHighlightModule,
    McModalModule,
    McToggleModule,
    McTreeModule,
    McFormFieldModule,
    McProgressSpinnerModule,
    McSelectModule,
    SharedModule,
    StoreDataSourcesModule,
    McPopoverModule,
    McTreeSelectModule,
    McPseudoCheckboxModule
];

const directives = [
    ConfidenceInputDirective,
    SourcesSelectMcValidateDirective,
    SourceTypeAutocompleteFieldMcValidateDirective
];

const components = [
    SourceFormComponent,
    SourcePreviewComponent,
    SourceTypeAutocompleteFieldComponent,
    SourcesGridComponent,
    SourceTypeFormComponent,
    SourcesTreeGridComponent,
    SourcesTreeGridDetailsComponent,
    SourceTypesGridComponent,
    SourcesFilterComponent,
    SourcesSelectComponent,
    SourceSelectModalComponent
];

const services = [SourcesNavigationService, SourcesSyncService];

@NgModule({
    imports: [...modules],
    declarations: [...components, ...directives],
    providers: [...services],
    exports: [...components, ...directives]
})
export class DomainCoreSourcesModule {}
