import uniqueId from 'lodash/uniqueId';

import { ArtifactType } from '@pt-cybsi/api-interfaces';
import { SourceModel } from '@pt-cybsi/domain-core/sources';
import { Model } from '@pt-cybsi/shared';

interface IAnalyserModelProps {
    serverId: string;
    eTag: string;
    sourceId: string;
    artifactTypes: ArtifactType[];
    artifactSizeLimit: number;
    dashboardURL: string;
    taskExecutionTimeout: number;
    taskExecutionAttemptsCount: number;
}

/**
 * @model Analyser
 *
 * @description
 * Model for working with an analyser data on the client in the domain layer.
 */
export class AnalyserModel extends Model<IAnalyserModelProps> {
    get isNew(): boolean {
        return this.props.serverId === null;
    }

    constructor(props: IAnalyserModelProps, uid?: string) {
        super(props, uid);
    }

    static create(props: IAnalyserModelProps, uid?: string): AnalyserModel {
        return new AnalyserModel({ ...props }, uid);
    }

    static createNew(source: SourceModel): AnalyserModel {
        return new AnalyserModel({
            serverId: null,
            eTag: null,
            sourceId: source.uid,
            artifactTypes: [],
            artifactSizeLimit: null,
            dashboardURL: null,
            taskExecutionTimeout: null,
            taskExecutionAttemptsCount: null
        });
    }

    diff(comparedModel: AnalyserModel): AnalyserModel {
        const changedProps = this.getChangedProps(comparedModel, ['serverId', 'eTag', 'sourceId']);

        return AnalyserModel.create(changedProps, this._uid);
    }

    protected generateId(): string {
        return uniqueId('analyser-');
    }
}
