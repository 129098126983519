import { ShareLevel } from '@pt-cybsi/api-interfaces';
import { IEditableReputationListData } from '@pt-cybsi/feature/reputation-lists';
import { TFormData } from '@pt-cybsi/shared';

export type TReputationListFormData = TFormData<{
    serverId: string;
    eTag: string;
    name: string;
    shareLevel: ShareLevel;
    text: string;
}>;

export class ReputationListFormMapper {
    static fromEditableApiData(reputationList: IEditableReputationListData): TReputationListFormData {
        return {
            id: 'reputation-list',
            serverId: reputationList.listId,
            eTag: reputationList.eTag,
            name: reputationList.name,
            shareLevel: reputationList.shareLevel,
            text: reputationList.text
        };
    }
}
