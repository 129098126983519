import toPairs from 'lodash/toPairs';

import { AttributeObservableEntity } from '../observable';

export class AttributeNameMapper {
    static fromPathValue(value: string): AttributeObservableEntity {
        return ATTRIBUTE_NAME_BY_PATH_ATTRIBUTE[value];
    }

    static toPathValue(value: AttributeObservableEntity): string {
        return PATH_ATTRIBUTE_BY_ATTRIBUTE_NAME[value];
    }
}

const PATH_ATTRIBUTE_BY_ATTRIBUTE_NAME: Record<AttributeObservableEntity, string> = {
    [AttributeObservableEntity.IsDGA]: 'is-dga',
    [AttributeObservableEntity.IsIoC]: 'is-ioc',
    [AttributeObservableEntity.IsTrusted]: 'is-trusted',
    [AttributeObservableEntity.IsDelegated]: 'is-delegated',
    [AttributeObservableEntity.NodeRoles]: 'node-roles',
    [AttributeObservableEntity.MalwareClasses]: 'malware-classes',
    [AttributeObservableEntity.MalwareFamilies]: 'malware-families',
    [AttributeObservableEntity.RelatedMalwareFamilies]: 'related-malware-families',
    [AttributeObservableEntity.DisplayNames]: 'display-names',
    [AttributeObservableEntity.RegionalInternetRegistry]: 'regional-internet-registry',
    [AttributeObservableEntity.ASN]: 'asn',
    [AttributeObservableEntity.Statuses]: 'statuses',
    [AttributeObservableEntity.Size]: 'size',
    [AttributeObservableEntity.Names]: 'names',
    [AttributeObservableEntity.Class]: 'class',
    [AttributeObservableEntity.Sectors]: 'sectors',
    [AttributeObservableEntity.TargetedSectors]: 'targeted-sectors',
    [AttributeObservableEntity.ExploitedVulnerabilities]: 'exploited-vulnerabilities',
    [AttributeObservableEntity.Campaigns]: 'campaigns',
    [AttributeObservableEntity.ThreatActors]: 'threat-actors',
    [AttributeObservableEntity.AffectedCountries]: 'affected-countries',
    [AttributeObservableEntity.MalwareNames]: 'malware-names',
    [AttributeObservableEntity.ThreatCategory]: 'threat-category',
    [AttributeObservableEntity.RelatedThreatCategory]: 'related-threat-category'
};

const attributeNamesAndPaths = toPairs(PATH_ATTRIBUTE_BY_ATTRIBUTE_NAME);

const ATTRIBUTE_NAME_BY_PATH_ATTRIBUTE: Record<string, AttributeObservableEntity> = attributeNamesAndPaths.reduce(
    (result, [attributeName, pathValue]) => ({ ...result, [pathValue]: attributeName }),
    {}
);
