import { Injectable, Provider } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IServerError } from '@pt-cybsi/api-interfaces';
import { FormStateValue, IFormRepository, IObservableUseCase } from '@pt-cybsi/shared';

import { ExternalDBModel } from '../../models';
import { ExternalDBFormRepository, ExternalDBFormRepositoryProvider } from '../../repositories';

export interface IUpdateExternalDBParams {
    id: string;
}

export type TUpdatedExternalDBId = string;

@Injectable()
export class UpdateExternalDBUseCase implements IObservableUseCase<IUpdateExternalDBParams, TUpdatedExternalDBId> {
    constructor(private repository: IFormRepository<ExternalDBModel>) {}

    execute(params: IUpdateExternalDBParams): Observable<TUpdatedExternalDBId> {
        const { id } = params;

        const externalDB = this.repository.getCurrentData(id);
        const currentFormState = this.repository.getState(id);

        if (currentFormState.value === FormStateValue.Saved) {
            return of(externalDB.props.serverId);
        }

        this.repository.resetSavingError(id);

        this.switchState(id, FormStateValue.Saving);

        const initialExternalDB = this.repository.getInitialData(id);

        const externalDBWithChangedData = externalDB.diff(initialExternalDB);

        return this.repository.save(externalDBWithChangedData).pipe(
            tap(() => {
                this.switchState(id, FormStateValue.Saved);

                this.repository.disable(id);
            }),
            catchError((error: unknown) => {
                this.switchState(id, FormStateValue.SavingFailure);

                this.repository.updateSavingError(id, error as IServerError);

                return throwError(error);
            })
        );
    }

    private switchState(id: string, state: FormStateValue): void {
        const formState = this.repository.getState(id);

        const nextFormState = formState.to(state);

        this.repository.updateState(id, nextFormState);
    }
}

export const UpdateExternalDBUseCaseProvider: Provider = [
    ExternalDBFormRepositoryProvider,
    {
        provide: UpdateExternalDBUseCase,
        useFactory: (repository: ExternalDBFormRepository) => new UpdateExternalDBUseCase(repository),
        deps: [ExternalDBFormRepository]
    }
];
