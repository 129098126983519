<span pt-title class="pt-text-overflow">
    <i mc-icon class="pt-icons"
        [class.pt-icons-arrow-small-right_16]="isForward"
        [class.pt-icons-arrow-small-left_16]="isReverse">
    </i>
    <span class="layout-padding-left-xs">{{ config | translateRelationLinkType }}</span>
    <span>{{ ' ' + separator + ' ' }}</span>
    <span>{{ config.target | translateObservableEntityType }}</span>
    <span *ngIf="confidence">{{ ' ' + confidence }}</span>
</span>
