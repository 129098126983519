import { Attribute, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { isNewAutocompletedItem } from '@pt-cybsi/shared';

import { SourceTypeFormViewModel } from '../../forms';
import { SourceTypeModelMapper } from '../../mappers';
import { SourceTypeModel } from '../../models';
import {
    SOURCE_TYPE_MAX_LENGTH,
    SOURCE_TYPE_MIN_LENGTH,
    SOURCE_TYPE_SHORT_NAME_MAX_LENGTH,
    SOURCE_TYPE_SHORT_NAME_MIN_LENGTH
} from '../../validators';
import { TAutocompletedSourceType } from '../source-type-autocomplete-field/source-type-autocomplete-field.component';

@Component({
    selector: 'source-type-form',
    templateUrl: './source-type-form.component.html',
    styleUrls: ['./source-type-form.component.scss'],
    host: {
        class: 'mc-form-vertical'
    }
})
export class SourceTypeFormComponent {
    @Input() form: SourceTypeFormViewModel;

    @Output() selectType: EventEmitter<SourceTypeModel> = new EventEmitter<SourceTypeModel>();

    get isAutocompleteAvailable(): boolean {
        return this.autocomplete !== null;
    }

    get isShortNameHintVisible(): boolean {
        return !this.form.hasErrors('shortName') || this.form.hasError('shortName', 'format');
    }

    invalidNameLengthMessage: string = this.getInvalidLengthMessage(SOURCE_TYPE_MIN_LENGTH, SOURCE_TYPE_MAX_LENGTH);
    invalidShortNameLengthMessage: string = this.getInvalidLengthMessage(
        SOURCE_TYPE_SHORT_NAME_MIN_LENGTH,
        SOURCE_TYPE_SHORT_NAME_MAX_LENGTH
    );

    constructor(
        @Attribute('autocomplete') private autocomplete: boolean,

        private translocoService: TranslocoService
    ) {}

    handleSelectType(type: TAutocompletedSourceType): void {
        const sourceType: SourceTypeModel = isNewAutocompletedItem(type)
            ? SourceTypeModel.create({
                  serverId: null,
                  eTag: null,
                  name: type.value.name,
                  shortName: null,
                  confidence: null
              })
            : SourceTypeModelMapper.fromApiData(type.value);

        this.selectType.emit(sourceType);
    }

    handleClearType(): void {
        this.selectType.emit(SourceTypeModel.createNew());
    }

    private getInvalidLengthMessage(min: number, max: number): string {
        return min > 1
            ? this.translocoService.translate('common.Common.Validation.Text.StringLengthBetween', { min, max })
            : this.translocoService.translate('common.Common.Validation.Text.StringLengthMax', { max });
    }
}
