import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { McModalService, ModalSize } from '@ptsecurity/mosaic/modal';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { LOCAL_AUTH_PROVIDER_ID } from '@pt-cybsi/api-interfaces';
import { PermissionsService } from '@pt-cybsi/domain-core/account';
import { SourceSelectModalComponent, SourcesNavigationService } from '@pt-cybsi/domain-core/sources';
import { AsyncState } from '@pt-cybsi/shared';

import { UsersPermissionsService } from '../../services';
import { IUserViewData } from '../../types';
import { PasswordModalComponent } from '../password-modal/password-modal.component';

@Component({
    selector: 'user-body',
    templateUrl: './user-body.component.html',
    styleUrls: ['./user-body.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserBodyComponent implements OnInit {
    @Input() user: IUserViewData;
    @Input() selectSourceCallback: (sourceId: string) => Observable<unknown>;
    @Input() updatingUserSourceState: AsyncState;
    @Input() eTag: string;
    @Input() isCurrentUser: boolean;

    @Output() updateUserPassword = new EventEmitter<void>();

    get isChangingSource(): boolean {
        return this.updatingUserSourceState === AsyncState.Loading;
    }

    get isReadySource(): boolean {
        return this.updatingUserSourceState !== AsyncState.Loading;
    }

    get isLocalAuthProvider(): boolean {
        return this.user.authProviderID === LOCAL_AUTH_PROVIDER_ID;
    }

    get isLocalUser(): boolean {
        return this.isLocalAuthProvider;
    }

    get isExternalUser(): boolean {
        return !this.isLocalAuthProvider;
    }

    get hasSource(): boolean {
        return !!this.user.dataSourceId;
    }

    get isSourceVisible(): boolean {
        return (this.isCurrentUser || this.isExternalUser) && this.hasSource && this.hasViewUserDataSourcePermissions;
    }

    get isSourceWithActionsVisible(): boolean {
        return (
            !this.isCurrentUser &&
            this.isLocalUser &&
            ((this.hasSource && this.hasViewUserDataSourcePermissions) || this.hasChangeUserDataSourcePermissions)
        );
    }

    get isEditButtonVisible(): boolean {
        return (
            this.isLocalAuthProvider &&
            ((!this.isCurrentUser && this.hasChangePasswordPermissions) ||
                (this.isCurrentUser && this.user.passwordAuthEnabled))
        );
    }

    newPasswordKey: string;
    readonly hasChangePasswordPermissions = this.permissionsService.hasAllPermissions(
        UsersPermissionsService.changeUserPasswordPermissions
    );
    readonly hasViewUserDataSourcePermissions = this.permissionsService.hasAllPermissions(
        UsersPermissionsService.viewUserDataSourcePermissions
    );
    readonly hasChangeUserDataSourcePermissions = this.permissionsService.hasAllPermissions(
        UsersPermissionsService.editUserDataSourcePermissions
    );

    constructor(
        private sourcesNavigationService: SourcesNavigationService,
        private permissionsService: PermissionsService,
        private modalService: McModalService
    ) {}

    ngOnInit() {
        this.newPasswordKey = `Users.ViewPage.Text.${this.isCurrentUser ? 'ChangePassword' : 'NewPassword'}`;
    }

    getSourceLink(sourceId: string): string {
        return this.sourcesNavigationService.getPathOfSourceViewRoute(sourceId);
    }

    handleChangeSource() {
        this.modalService.open<SourceSelectModalComponent>({
            mcComponent: SourceSelectModalComponent,
            mcSize: ModalSize.Small,
            mcComponentParams: {
                selectedSourceId: this.user.dataSourceId,
                selectSourceCallback: this.selectSourceCallback
            }
        });
    }

    deleteUserSource() {
        this.selectSourceCallback(null).pipe(take(1)).subscribe();
    }

    handleChangePassword(): void {
        this.modalService
            .open<PasswordModalComponent>({
                mcComponent: PasswordModalComponent,
                mcSize: ModalSize.Small,
                mcComponentParams: this.isCurrentUser
                    ? null
                    : {
                          userId: this.user.uuid,
                          eTag: this.eTag
                      }
            })
            .afterClose.pipe(
                tap((isSubmit) => {
                    if (isSubmit) {
                        this.updateUserPassword.emit();
                    }
                }),
                take(1)
            )
            .subscribe();
    }
}
