import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as ErrorActions from './error.actions';
import * as ErrorSelectors from './error.selectors';

@Injectable()
export class ErrorFacade {
    errorCode$ = this.store.pipe(select(ErrorSelectors.getError));

    constructor(private store: Store) {}

    setError(code: string) {
        this.store.dispatch(ErrorActions.setError({ code }));
    }

    resetError() {
        this.store.dispatch(ErrorActions.resetError());
    }
}
