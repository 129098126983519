import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { Directive, ElementRef, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { DestroyService } from '../../services';

interface IOffset {
    top: number;
    bottom: number;
}

@Directive({
    selector: '[infinityScrollConsumer]',
    providers: [DestroyService]
})
export class InfinityScrollConsumerDirective implements OnInit {
    @Input() infinityScrollConsumerDistance?: number;

    @Output() infinityScroll = new EventEmitter<void>();

    get isInTriggerArea(): boolean {
        const actualOffset = this.getCurrentOffset();

        return actualOffset.bottom <= this.scrollDistance;
    }

    private get scrollDistance(): number {
        return this.infinityScrollConsumerDistance || 0;
    }

    private cdkScrollable: CdkScrollable;

    private previousOffset: IOffset = { top: 0, bottom: 0 };
    private currentOffset: IOffset = { top: 0, bottom: 0 };

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private scrollDispatcher: ScrollDispatcher,
        private ngZone: NgZone,
        private destroyed$: DestroyService
    ) {}

    ngOnInit(): void {
        this.cdkScrollable = this.scrollDispatcher.getAncestorScrollContainers(this.elementRef)[0];

        this.cdkScrollable
            .elementScrolled()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.previousOffset = this.currentOffset;
                this.currentOffset = this.getCurrentOffset();

                const isEnterToTriggerArea = this.isInTriggerArea && this.previousOffset.bottom >= this.scrollDistance;

                if (isEnterToTriggerArea) {
                    // Run detectChanges. For more information: https://github.com/angular/components/issues/22547
                    this.ngZone.run(() => {
                        this.infinityScroll.emit();
                    });
                }
            });
    }

    private getCurrentOffset(): IOffset {
        return {
            top: Math.floor(this.cdkScrollable.measureScrollOffset('top')),
            bottom: Math.floor(this.cdkScrollable.measureScrollOffset('bottom'))
        };
    }
}
