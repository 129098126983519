import { ApiResource, PermissionType } from '@pt-cybsi/api-interfaces';
import { buildPermissionString, RootPermissionsService } from '@pt-cybsi/domain-core/account';

const Read = PermissionType.Read;
const Write = PermissionType.Write;

export class EnrichmentRulesPermissionsService {
    static readonly viewRulesPermissions = RootPermissionsService.viewEnrichmentRulesPermissions;

    static readonly viewRulePermissions = [
        buildPermissionString(ApiResource.DataSources, [Read]),
        buildPermissionString(ApiResource.EnrichmentConfig, [Read])
    ];

    static readonly createRulePermissions = [
        buildPermissionString(ApiResource.DataSources, [Read]),
        buildPermissionString(ApiResource.EnrichmentConfig, [Write])
    ];

    static readonly editRulePermissions = [
        buildPermissionString(ApiResource.DataSources, [Read]),
        buildPermissionString(ApiResource.EnrichmentConfig, [Write])
    ];

    static readonly changeIsDisabledPermissions = [buildPermissionString(ApiResource.EnrichmentConfig, [Write])];
}
