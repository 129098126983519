import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import { LazyDataStateModel, LazyDataState } from '../models';

interface ILazyDataState {
    state: LazyDataState;
}

@Injectable()
export class LazyDataStateStore extends ComponentStore<ILazyDataState> {
    readonly dataState$: Observable<LazyDataState> = this.select((state) => state.state);

    readonly setLoadingState = this.updater((state) => {
        const model = LazyDataStateModel.create(state.state);

        model.setLoadingState();

        return {
            ...state,
            state: model.currentState
        };
    });

    readonly setCompleteState = this.updater((state, isEndData: boolean) => {
        const model = LazyDataStateModel.create(state.state);

        model.setCompleteState(isEndData);

        return {
            ...state,
            state: model.currentState
        };
    });

    readonly setErrorState = this.updater((state) => {
        const model = LazyDataStateModel.create(state.state);

        model.setErrorState();

        return {
            ...state,
            state: model.currentState
        };
    });

    constructor() {
        super({ state: LazyDataStateModel.create().currentState });
    }
}
