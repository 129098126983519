import { API_RESOURCES, ApiResource, PERMISSION_TYPES, PermissionType } from '@pt-cybsi/api-interfaces';

export interface IPermission {
    resource: ApiResource;
    types: PermissionType[];
}

const SPLITTER = ':';
// Example: /^[rw]+$/
const PERMISSION_TYPES_PATTERN = new RegExp(`^[${PERMISSION_TYPES.join('')}]+$`);

export class PermissionsParserService {
    static parsePermissions(permissions: string[]): IPermission[] {
        return permissions.map((permission) => PermissionsParserService.parse(permission));
    }

    static parse(permission: string): IPermission {
        const resource: ApiResource = PermissionsParserService.getResource(permission);
        const types: PermissionType[] = PermissionsParserService.getTypes(permission);

        return { resource, types };
    }

    private static getResource(permission: string): ApiResource {
        const resource: ApiResource = permission.split(SPLITTER)[0] as ApiResource;
        const isResourceAvailable: boolean = API_RESOURCES.indexOf(resource) >= 0;

        return isResourceAvailable ? resource : undefined;
    }

    private static getTypes(permission: string): PermissionType[] {
        const typesString: string = permission.split(SPLITTER)[1] || '';

        const isValidTypes: boolean = PERMISSION_TYPES_PATTERN.test(typesString);

        return isValidTypes
            ? Object.keys(PermissionType).reduce<PermissionType[]>(
                  (types: PermissionType[], type: string) =>
                      new RegExp(PermissionType[type] as string).test(typesString)
                          ? ([...types, PermissionType[type]] as PermissionType[])
                          : types,
                  []
              )
            : [];
    }
}
