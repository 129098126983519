import { Observable } from 'rxjs';

/**
 * Base interface for implementing sync use cases
 */
export interface IUseCase<ExecuteParams, Result = void> {
    execute(params: ExecuteParams): Result;
}

/**
 * Base interface for implementing async use cases
 */
export interface IObservableUseCase<ExecuteParams, Result = void> {
    execute(params: ExecuteParams): Observable<Result>;
}
