import { Injectable, Provider } from '@angular/core';

import { IFormRepository, IUseCase } from '@pt-cybsi/shared';

import { SourceModel } from '../../models';
import { SourceFormRepository, SourceFormRepositoryProvider } from '../../repositories';

export interface IUseCustomConfidenceParams {
    sourceId: string;
}

@Injectable()
export class UseCustomConfidenceUseCase implements IUseCase<IUseCustomConfidenceParams> {
    constructor(private sourceFormRepository: IFormRepository<SourceModel>) {}

    execute(params: IUseCustomConfidenceParams): void {
        const { sourceId } = params;

        const source = this.sourceFormRepository.getCurrentData(sourceId);

        source.useCustomConfidence(1);

        this.sourceFormRepository.updateCurrentData(source);

        this.sourceFormRepository.showControls(sourceId, ['confidence']);
    }
}

export const UseCustomConfidenceUseCaseProvider: Provider = [
    SourceFormRepositoryProvider,
    {
        provide: UseCustomConfidenceUseCase,
        useFactory: (sourceFormRepository: SourceFormRepository) =>
            new UseCustomConfidenceUseCase(sourceFormRepository),
        deps: [SourceFormRepository]
    }
];
