<span [ngSwitch]="attributeName" class="pt-text-overflow" pt-title>
    <ng-container *ngSwitchCase="AttributeObservableEntity.IsIoC">
        {{ 'entities.ObservableEntities.AttributeName.Text.IsIoC' | transloco }}
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.IsTrusted">
        {{ 'entities.ObservableEntities.AttributeName.Text.IsTrusted' | transloco }}
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.IsDGA">
        {{ 'entities.ObservableEntities.AttributeName.Text.IsDGA' | transloco }}
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.IsDelegated">
        {{ attributeValue | translateIsDelegatedValue }}
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.ThreatCategory">
        {{ attributeValue | translateThreatCategoryValue }}
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.RelatedThreatCategory">
        {{ attributeValue | translateRelatedThreatCategoryValue }}
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.ASN">
        {{ attributeValue | formatAsn }}
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.Class">
        {{ attributeValue | translateIdentityClassValue }}
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.Size">
        <span [mcTooltip]="attributeValue | formatBytes : { unit: FileSizeUnit.B }"
              [mcTooltipDisabled]="isDisabledSizeTooltip">{{ attributeValue | formatBytes }}</span>
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.Sectors">
        <ng-container *ngTemplateOutlet="dictionaryItemTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.TargetedSectors">
        <ng-container *ngTemplateOutlet="dictionaryItemTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.Campaigns">
        <ng-container *ngTemplateOutlet="dictionaryItemTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.ThreatActors">
        <ng-container *ngTemplateOutlet="dictionaryItemTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.AffectedCountries">
        <ng-container *ngTemplateOutlet="dictionaryItemTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.ExploitedVulnerabilities">
        <ng-container *ngTemplateOutlet="dictionaryItemTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.Statuses">
        <ng-container *ngTemplateOutlet="dictionaryItemTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.MalwareClasses">
        <ng-container *ngTemplateOutlet="dictionaryItemTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.MalwareFamilies">
        <ng-container *ngTemplateOutlet="dictionaryItemTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="AttributeObservableEntity.RelatedMalwareFamilies">
        <ng-container *ngTemplateOutlet="dictionaryItemTemplate"></ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
        {{ attributeValue }}
    </ng-container>
</span>

<ng-template #dictionaryItemTemplate>
    <dictionary-item-preview
        [dictionaryItem]="attributeValue"
        [format]="DictionaryItemPreviewFormat.PseudoLink">
    </dictionary-item-preview>
</ng-template>
