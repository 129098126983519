export enum EnrichmentTaskPriority {
    Normal = 'Normal',
    High = 'High'
}

export enum EnrichmentTaskStatus {
    Pending = 'Pending',
    Executing = 'Executing',
    Failed = 'Failed',
    Completed = 'Completed',
    Aborted = 'Aborted'
}
