import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { merge, Observable } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { ObservableEntitiesAttributesApiService } from '@pt-cybsi/api';
import { AttributeObservableEntity, DefaultErrorCodes, TAttributeValueType } from '@pt-cybsi/api-interfaces';
import { SourcesSyncService } from '@pt-cybsi/domain-core/sources';
import { isServerError } from '@pt-cybsi/shared';

import { ForecastViewMapper } from '../mappers';

import { ForecastStore } from './forecast.store';

@Injectable()
export class AttributeValueForecastStore extends ForecastStore {
    readonly load = this.effect(
        (
            params: Observable<{
                entityId: string;
                attributeName: AttributeObservableEntity;
                attributeValue: TAttributeValueType;
            }>
        ) =>
            params.pipe(
                tap(() => this.toLoading()),
                switchMap(({ entityId, attributeName, attributeValue }) =>
                    this.observableEntitiesAttributesApiService.getForecast(entityId, attributeName).pipe(
                        tapResponse(
                            (response) => {
                                const dataSources = response.values.flatMap((value) =>
                                    value.valuableFacts.map((fact) => fact.dataSource)
                                );

                                this.sourcesSyncService.syncSources(dataSources);

                                this.toSuccessLoading(
                                    ForecastViewMapper.fromAttributeValueForecast(response, {
                                        attributeName,
                                        attributeValue
                                    })
                                );
                            },
                            (response: HttpErrorResponse) => {
                                const error = isServerError(response.error)
                                    ? response.error
                                    : { code: DefaultErrorCodes.UNKNOWN };

                                this.toFailureLoading(error);
                            }
                        ),
                        takeUntil(merge(this.destroy$, this.loadingCanceller$))
                    )
                )
            )
    );

    constructor(
        private sourcesSyncService: SourcesSyncService,
        private observableEntitiesAttributesApiService: ObservableEntitiesAttributesApiService
    ) {
        super();
    }
}
