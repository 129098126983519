import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { ApiErrorInterceptor } from './interceptors';
import {
    ApiKeysApiService,
    AuthApiService,
    DataSourcesApiService,
    DictionariesApiService,
    OldDictionariesApiService,
    ObservableEntitiesApiService,
    ObservableEntitiesAttributesApiService,
    ObservableEntitiesRelationsApiService,
    ReputationListsApiService,
    StoredQueryApiService,
    SystemInfoApiService,
    UsersApiService,
    EnrichmentApiService,
    ClientConfigApiService,
    LicenseApiService
} from './services';

@NgModule({
    imports: [HttpClientModule],
    providers: [
        ApiKeysApiService,
        AuthApiService,
        ClientConfigApiService,
        LicenseApiService,
        DataSourcesApiService,
        DictionariesApiService,
        OldDictionariesApiService,
        UsersApiService,
        SystemInfoApiService,
        ReputationListsApiService,
        StoredQueryApiService,
        ObservableEntitiesApiService,
        ObservableEntitiesAttributesApiService,
        ObservableEntitiesRelationsApiService,
        EnrichmentApiService,

        { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true }
    ]
})
export class ApiModule {}
