import {
    Component,
    Input,
    Output,
    EventEmitter,
    forwardRef,
    OnInit,
    SimpleChanges,
    OnChanges,
    ViewChild
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { McTooltipTrigger } from '@ptsecurity/mosaic/tooltip';
import isEmpty from 'lodash/isEmpty';

import { FiltersPanelBaseItem } from '../filters-panel/filters-panel.component';

@Component({
    selector: 'text-filter',
    templateUrl: './text-filter.component.html',
    styleUrls: ['./text-filter.component.scss'],
    providers: [
        {
            provide: FiltersPanelBaseItem,
            useExisting: forwardRef(() => TextFilterComponent)
        }
    ]
})
export class TextFilterComponent extends FiltersPanelBaseItem implements OnInit, OnChanges {
    @Input() value: string;
    @Input() placeholder: string;
    @Input() valueMaxLength: number;

    @Output() applyFilter: EventEmitter<string> = new EventEmitter<string>();
    @Output() resetFilter: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('tooltip', { static: false }) tooltip: McTooltipTrigger;

    filtersForm: UntypedFormGroup = new UntypedFormGroup({
        name: new UntypedFormControl(undefined)
    });

    get textFilterControl(): UntypedFormControl {
        return this.filtersForm.get('name') as UntypedFormControl;
    }

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.filtersForm.patchValue({ name: this.value });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const isValueChanged = changes.value?.currentValue !== changes.value?.previousValue;

        if (isValueChanged) {
            if (isEmpty(this.value)) {
                this.filtersForm.reset();
            } else {
                this.filtersForm.patchValue({ name: this.value });
            }
        }
    }

    reset(): void {
        this.resetFilter.emit();
    }

    handleApplyFilters(): void {
        this.applyFilter.emit(this.textFilterControl.value as string);
    }

    handleInput(event: InputEvent) {
        if (this.valueMaxLength) {
            const element = event.target as HTMLInputElement;

            if (element.value.length > this.valueMaxLength) {
                this.tooltip.show();
            }
            if (element.value.length <= this.valueMaxLength && this.tooltip.tooltipVisible) {
                this.tooltip.hide();
            }
        }
    }
}
