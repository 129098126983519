import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import {
    IDataSourceType,
    IDataSourceTypeCreateParams,
    IDataSourceTypeUpdateParams,
    TEditableDataSourceTypeResponse
} from '@pt-cybsi/api-interfaces';
import { TFormData } from '@pt-cybsi/shared';

import { SourceTypeModel } from '../models';

export class SourceTypeModelMapper {
    static toFormData(model: SourceTypeModel): TSourceTypeFormData {
        return { id: model.uid, ...model.props };
    }

    static fromFormData(data: TSourceTypeFormData): SourceTypeModel {
        return SourceTypeModel.create({ ...data }, data.id);
    }

    static fromApiData(sourceType: IDataSourceType): SourceTypeModel {
        return SourceTypeModel.create({
            serverId: sourceType.uuid,
            eTag: null,
            name: sourceType.longName,
            shortName: sourceType.shortName,
            confidence: sourceType.confidence
        });
    }

    static fromEditableApiData(sourceType: TEditableDataSourceTypeResponse): SourceTypeModel {
        const { data, eTag } = sourceType;

        return SourceTypeModel.create({
            serverId: data.uuid,
            eTag,
            name: data.longName,
            shortName: data.shortName,
            confidence: data.confidence
        });
    }

    static toRegisterParams(model: SourceTypeModel): IDataSourceTypeCreateParams {
        const modelProps = model.props;

        const hasConfidence = !isNil(modelProps.confidence);

        return {
            longName: modelProps.name,
            shortName: modelProps.shortName,
            ...(hasConfidence ? { manualConfidence: modelProps.confidence } : {})
        };
    }

    static toUpdateParams(model: SourceTypeModel): IDataSourceTypeUpdateParams {
        const { name: longName, confidence: manualConfidence } = model.props;

        return {
            ...(isEmpty(longName) ? {} : { longName }),
            ...(isNil(manualConfidence) ? {} : { manualConfidence })
        };
    }
}

export type TSourceTypeFormData = TFormData<{
    id: string;
    serverId: string;
    eTag: string;
    name: string;
    shortName: string;
    confidence: number;
}>;
