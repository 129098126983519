import { NgModule } from '@angular/core';
import { McDividerModule } from '@ptsecurity/mosaic/divider';
import { McDropdownModule } from '@ptsecurity/mosaic/dropdown';
import { McModalModule } from '@ptsecurity/mosaic/modal';
import { McToolTipModule } from '@ptsecurity/mosaic/tooltip';

import { SharedModule } from '@pt-cybsi/shared';

import { SystemInfoModalComponent } from './containers';
import { SystemInfoService } from './services';

// prettier-ignore
@NgModule({
    imports: [
        SharedModule,
        McDividerModule,
        McDropdownModule,
        McToolTipModule,
        McModalModule
    ],
    declarations: [SystemInfoModalComponent],
    providers: [SystemInfoService],
    exports: [SystemInfoModalComponent]
})
export class FeatureSystemModule {}
