import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { DateAdapter } from '@ptsecurity/cdk/datetime';
import { DateFormatter } from '@ptsecurity/mosaic/core';
import { DateTime } from 'luxon';

import { IRelativeDateRange, isRelativeDateRange, RelativeDateType, TDateRange } from '../types';

/**
 * @pipe ToStringDateRange
 *
 * @description Converts date range to localized string
 */
@Pipe({
    name: 'toStringDateRange'
})
export class ToStringDateRangePipe implements PipeTransform {
    constructor(private dateAdapter: DateAdapter<DateTime>, private dateFormatter: DateFormatter<DateTime>) {}

    transform(range: TDateRange): string {
        if (!range) {
            return '';
        }

        if (isRelativeDateRange(range)) {
            return this.toStringRelativeDateRange(range);
        } else {
            return this.dateFormatter.rangeShortDate(
                this.dateAdapter.deserialize(range.from),
                this.dateAdapter.deserialize(range.to)
            );
        }
    }

    private toStringRelativeDateRange(range: IRelativeDateRange): string {
        switch (range.type) {
            case RelativeDateType.Days: {
                if (range.value === 1) {
                    return translate('common.Common.Pseudo.Text.PluralHoursWithValue', { count: 24 });
                }

                return translate('common.Common.Pseudo.Text.PluralDaysWithValue', { count: range.value });
            }

            default: {
                return '';
            }
        }
    }
}
