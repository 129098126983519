import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    TReputationListsRequestParams,
    TReputationListsResponse,
    IReputationList,
    IReputationListStats,
    IReputationListRequestBody,
    IApiItemPreview,
    TReputationListsEntitiesResponse,
    IEditableResource
} from '@pt-cybsi/api-interfaces';

import { BaseApiService } from './base-api.service';

@Injectable()
export class ReputationListsApiService extends BaseApiService {
    static readonly urls = {
        lists: 'replists'
    };

    getReputationLists(params: TReputationListsRequestParams): Observable<TReputationListsResponse> {
        const url = ReputationListsApiService.urls.lists;

        return this.getPaginatedCollection(url, this.addResolveRefsParam({ params }));
    }

    getReputationList(uuid: string): Observable<IEditableResource<IReputationList>> {
        const url = `${ReputationListsApiService.urls.lists}/${uuid}`;

        return this.getEditableResource(url, this.addResolveRefsParam({ params: { embedObjectURL: true } }));
    }

    getReputationListStats(uuid: string): Observable<IReputationListStats> {
        const url = `${ReputationListsApiService.urls.lists}/${uuid}/statistic`;

        return this.get<IReputationListStats>(url);
    }

    getReputationListContent(uuid: string, cursor?: string): Observable<TReputationListsEntitiesResponse> {
        const url = `${ReputationListsApiService.urls.lists}/${uuid}/entities`;

        return this.getPaginatedCollection(url, { params: cursor ? { cursor, limit: 30 } : { limit: 30 } });
    }

    createReputationList(params: IReputationListRequestBody): Observable<IApiItemPreview> {
        const url = ReputationListsApiService.urls.lists;

        return this.post<IApiItemPreview>(url, params);
    }

    changeReputationList(uuid: string, params: Partial<IReputationListRequestBody>, eTag: string): Observable<void> {
        const url = `${ReputationListsApiService.urls.lists}/${uuid}`;

        return this.updateResource(url, eTag, params);
    }
}
