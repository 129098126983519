<div class="mc-form__row">
    <label class="mc-form__label">
        {{ 'enrichment.Enrichment.ExternalDBFormFields.Label.WebPageURL' | transloco }}
    </label>

    <mc-form-field class="external-db-form__dashboard-url">
        <entity-key-field
            [formControl]="form.getControl('webPageURL')"
            [entityType]="webPageURLEntityType"
            [keyType]="webPageURLKeyType">
        </entity-key-field>
        <mc-hint *ngIf="form.hasErrors('webPageURL')" color="error">
            <div *ngIf="form.hasError('webPageURL', 'format')">
                {{ 'common.Common.Validation.Text.InvalidURL' | transloco }}
            </div>
        </mc-hint>
    </mc-form-field>
</div>

<div class="mc-form__row">
    <label class="mc-form__label">
        {{ 'entities.ObservableEntities.Pseudo.Text.Types' | transloco }}
    </label>
    <mc-form-field class="external-db-form__entity-types">
        <entity-types-select
            multiple

            [formControl]="form.getControl('entityTypes')"
            [availableEntityTypes]="availableEntityTypes">
        </entity-types-select>
    </mc-form-field>
</div>

<div class="mc-form__row">
    <label class="mc-form__label">
        {{ 'enrichment.Enrichment.FormFields.Label.TaskExecutionTimeout' | transloco }}
    </label>

    <mc-form-field
        mcFormFieldWithoutBorders
        cybsiFormFieldWithoutBorders
        class="external-db-form__task-execution-timeout">
        <time-period-field
            [formControl]="form.getControl('taskExecutionTimeout')"
            [periodType]="periodTaskExecutionTimeout"
            (periodTypeChanged)="handleChangePeriodTaskExecutionTimeout($event)"
        ></time-period-field>
        <mc-hint *ngIf="form.hasErrors('taskExecutionTimeout')" color="error">
            <div *ngIf="form.hasError('taskExecutionTimeout', 'range')">
                {{ getInvalidTaskExecutionTimeoutMessage(periodTaskExecutionTimeout) }}
            </div>
        </mc-hint>
    </mc-form-field>
</div>

<div class="mc-form__row">
    <label class="mc-form__label">
        {{ 'enrichment.Enrichment.FormFields.Label.TaskExecutionAttemptsCount' | transloco }}
    </label>

    <mc-form-field class="external-db-form__task-execution-attempts-count">
        <input
            [formControl]="form.getControl('taskExecutionAttemptsCount')"
            mcInput
            integerInput
            type="number"
            step="1"
            autocomplete="off">
        <mc-hint *ngIf="form.hasErrors('taskExecutionAttemptsCount')" color="error">
            <div *ngIf="form.hasError('taskExecutionAttemptsCount', 'range')">
                {{ invalidTaskAttemptsCountMessage }}
            </div>
        </mc-hint>
    </mc-form-field>
</div>
