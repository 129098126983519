import {
    AttributeObservableEntity,
    RelatedThreatCategory,
    TAttributeValueType,
    ThreatCategory
} from '@pt-cybsi/api-interfaces';

import { ThreatLevel } from '../types';

export class ThreatLevelMapper {
    static fromThreatCategory(value: TAttributeValueType<AttributeObservableEntity.ThreatCategory>): ThreatLevel {
        switch (value) {
            case ThreatCategory.Malware: {
                return ThreatLevel.Malware;
            }

            case ThreatCategory.Adware: {
                return ThreatLevel.Adware;
            }

            case ThreatCategory.Riskware: {
                return ThreatLevel.Riskware;
            }

            case ThreatCategory.Clean: {
                return ThreatLevel.Clean;
            }

            case null: {
                return ThreatLevel.Unknown;
            }

            default: {
                return undefined;
            }
        }
    }

    static fromRelatedThreatCategory(
        value: TAttributeValueType<AttributeObservableEntity.RelatedThreatCategory>
    ): ThreatLevel {
        switch (value) {
            case RelatedThreatCategory.Malware: {
                return ThreatLevel.Malware;
            }

            case RelatedThreatCategory.Adware: {
                return ThreatLevel.Adware;
            }

            case RelatedThreatCategory.Riskware: {
                return ThreatLevel.Riskware;
            }

            case null: {
                return ThreatLevel.Unknown;
            }

            default: {
                return undefined;
            }
        }
    }
}
