import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { createASCSortComparator } from '@pt-cybsi/shared';

/**
 * @pipe TranslatePermission
 *
 * @description Converts permission to localized string
 */
@Pipe({
    name: 'translatePermission'
})
export class TranslatePermissionPipe implements PipeTransform {
    constructor(private translocoService: TranslocoService) {}

    transform(permission: string): string {
        return this.translocoService.translate(`users.Users.Pseudo.Permission.${permission}`);
    }
}

/**
 * @pipe TranslatePermissions
 *
 * @description Converts permissions to ASC sorted localized strings array
 */
@Pipe({
    name: 'translatePermissions'
})
export class TranslatePermissionsPipe implements PipeTransform {
    private readonly sortComparator = createASCSortComparator<string>((item) => item);

    constructor(private translatePermission: TranslatePermissionPipe) {}

    transform(permissions: string[]): string[] {
        const translatedPermissions = permissions.map((permission) => this.translatePermission.transform(permission));

        return translatedPermissions.sort(this.sortComparator);
    }
}
