import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

/**
 * @pipe TranslateIsDelegatedValue
 *
 * @description Converts IsDelegated value to localized string
 */
@Pipe({
    name: 'translateIsDelegatedValue'
})
export class TranslateIsDelegatedValuePipe implements PipeTransform {
    transform(value: boolean): string {
        return translate(
            value
                ? 'entities.ObservableEntities.IsDelegated.Text.Delegated'
                : 'entities.ObservableEntities.IsDelegated.Text.NotDelegated'
        );
    }
}
