<table class="valuable-facts-table">
    <col style="width:3.4%">
    <col style="width:21.6%">
    <col *ngIf="isDateVisible" style="width:16.6%">
    <col style="width:25.7%">
    <col style="width:8.7%">
    <col style="width:12%">
    <col style="width:12%">

    <tr class="valuable-facts-table__header-row">
        <th class="valuable-facts-table__head"></th>
        <th class="valuable-facts-table__head"></th>
        <th *ngIf="isDateVisible" class="valuable-facts-table__head">
            {{ 'common.Common.Pseudo.Text.Date' | transloco }}
        </th>
        <th class="valuable-facts-table__head">
            {{ 'sources.Sources.Pseudo.Text.Source' | transloco }}
        </th>
        <th class="valuable-facts-table__head">
            {{ 'sources.Sources.Pseudo.Text.SourceConfidence' | transloco }}
        </th>
        <th class="valuable-facts-table__head">
            {{ 'common.Common.Pseudo.Text.ConfidenceBySource' | transloco }}
        </th>
        <th class="valuable-facts-table__head">
            {{ 'common.Common.Pseudo.Text.ConfidenceByBrand' | transloco : { brandName: brandName } }}
        </th>
    </tr>

    <tr *ngFor="let fact of facts" class="valuable-facts-table__row">
        <td class="valuable-facts-table__cell">
            <i class="mc-icon_light" mc-icon [shareLevelIcon]="fact.shareLevel"></i>
        </td>
        <td class="valuable-facts-table__cell scoring-table__cell_type_value">
            <ng-container [ngSwitch]="type">
                <ng-container *ngSwitchCase="ValuableFactsTableType.Attribute">
                    <entity-attribute-badge
                        *ngIf="isVerdictAttribute(fact.attributeName)"
                        [attributeName]="fact.attributeName"
                        [attributeValue]="fact.value">
                    </entity-attribute-badge>

                    <entity-attribute-value
                        *ngIf="!isVerdictAttribute(fact.attributeName)"
                        [attributeName]="fact.attributeName"
                        [attributeValue]="fact.value">
                    </entity-attribute-value>
                </ng-container>
                <ng-container *ngSwitchCase="ValuableFactsTableType.Relation">
                    <entity-preview [entity]="fact.value"></entity-preview>
                </ng-container>
            </ng-container>
        </td>
        <td *ngIf="isDateVisible" class="valuable-facts-table__cell scoring-table__cell_type_date">
            <ng-container *ngIf="fact.seenAt">
                {{ fact.seenAt | formattedDate : { format: 'absoluteShortDateTime' } }}
            </ng-container>
        </td>
        <td class="valuable-facts-table__cell">
            <source-preview [sourceId]="fact.dataSourceId"></source-preview>
        </td>
        <td class="valuable-facts-table__cell">
            {{ fact.dataSourceConfidence | formatConfidence : ConfidenceFormat.Full }}
        </td>
        <td class="valuable-facts-table__cell">
            {{ fact.confidence | formatConfidence : ConfidenceFormat.Full }}
        </td>
        <td class="valuable-facts-table__cell">
            {{ fact.finalConfidence | formatConfidence : ConfidenceFormat.Full }}
        </td>
    </tr>
</table>
