<mc-modal-title>
    {{ 'system.System.SystemInfo.Text.About' | transloco }}
</mc-modal-title>
<mc-modal-body>
    <div *transloco="let tSystem; read: 'system'">
        <table class="versions-table margin-bottom_16" mc-table>
            <tbody>
            <tr>
                <td class="component-name">{{ tSystem('System.SystemInfo.Text.ProductName') }}</td>
                <td>
                    <span *ngIf="versions.system; else failedFetchVersion">{{ versions.system }}</span>
                </td>
            </tr>
            <tr>
                <td class="component-name">{{ tSystem('System.SystemInfo.Text.API') }}</td>
                <td>
                    <span *ngIf="versions.api; else failedFetchVersion">{{ versions.api }}</span>
                </td>
            </tr>
            <tr>
                <td class="component-name">{{ tSystem('System.SystemInfo.Text.Backend') }}</td>
                <td>
                    <span *ngIf="versions.backend; else failedFetchVersion">{{ versions.backend }}</span>
                </td>
            </tr>
            <tr>
                <td class="component-name">{{ tSystem('System.SystemInfo.Text.Frontend') }}</td>
                <td>
                    <span *ngIf="versions.frontend; else failedFetchVersion">{{ versions.frontend }}</span>
                </td>
            </tr>
            <tr>
                <td class="component-name">{{ tSystem('System.SystemInfo.Text.Adapter') }}</td>
                <td>
                    <span *ngIf="versions.adapter; else failedFetchVersion">{{ versions.adapter }}</span>
                </td>
            </tr>
            </tbody>
        </table>
        <ng-template #failedFetchVersion>
            <div class="layout-row layout-align-start-center">
                <div class="margin-right_4 text-error">{{tSystem('System.SystemInfo.Text.FailedFetchVersion')}}</div>
                <button
                    *ngIf="(isReloadingVersions$ | async) === false"
                    mc-button
                    class="reload-version-button mc-button_transparent"
                    (click)="reloadVersion()">
                    <i mc-icon="pt-icons-refresh_16"
                       class="pt-icons action-icon">
                    </i>
                </button>
                <i *ngIf="isReloadingVersions$ | async" mc-icon="pt-icons-loader-circle_16" class="pt-icons mc-spin"></i>
            </div>
        </ng-template>
        <div class="legal-info margin-bottom_16">
            <div class="mc-subheading margin-bottom_8">{{ tSystem('System.SystemInfo.Text.LegalInfo') }}</div>
            <div>
                {{ 'system.System.SystemInfo.Text.UseMaxMind' | transloco | translateCut:0 }}
                <a mc-link target="_blank" href="https://www.maxmind.com">
                    <span class="mc-link__text">{{ 'system.System.SystemInfo.Text.UseMaxMind' | transloco | translateCut:1 }}</span>
                    <i mc-icon class="pt-icons pt-icons-external-link_16"></i>
                </a>
            </div>
        </div>
        <div *ngIf="hasDocumentation$ | async"
             class="reference-info layout-column">
            <div class="mc-subheading margin-bottom_8">{{ tSystem('System.SystemInfo.Text.ReferenceInfo') }}</div>
            <a *ngIf="hasReleaseNotes$ | async"
               mc-link
               class="margin-bottom_8"
               [href]="releaseNotesUrl"
               target="_blank">
                <i mc-icon class="pt-icons pt-icons-file-empty_16"></i>
                <span class="mc-link__text">{{ tSystem('System.SystemInfo.Text.ReleaseNotes') }}</span>
            </a>
            <a *ngIf="hasUserGuide$ | async"
               mc-link
               class="margin-bottom_8"
               target="_blank"
               [href]="userGuideUrl"
               target="_blank">
                <i mc-icon class="pt-icons pt-icons-file-empty_16"></i>
                <span class="mc-link__text">{{ tSystem('System.SystemInfo.Text.UserGuide') }}</span>
            </a>
        </div>
    </div>
</mc-modal-body>
<div mc-modal-footer>
    <button mc-button color="second" (click)="closeModal()">
        {{ 'common.Common.Pseudo.Button.Close' | transloco }}
    </button>
</div>


