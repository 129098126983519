<div class="mc-alert mc-alert_error" *transloco="let tCommon; read: 'common'">
    <i mc-icon="mc-error_16" class="mc-alert__icon"></i>
    <div>
        <div *ngIf="text">{{ text }}</div>
        <div *ngIf="!text">
            <ng-content select="[error-alert-text]"></ng-content>
        </div>
        <button type="button" *ngIf="hasRetry" mc-link pseudo class="mc-link clear-button layout-margin-top-s" (click)="handleRetry()">
            <span class="mc-link__text">{{ retryText || tCommon('Common.Loading.Text.RetryButton') }}</span>
        </button>
    </div>
</div>
