import { DataSourceTypeKind, IDataSourceType } from '@pt-cybsi/api-interfaces';

export const defaultDataSourceType: IDataSourceType = {
    uuid: 'data-source-type-uuid',
    url: 'data-source-type-url',
    longName: 'data-source-type-long-name',
    shortName: 'data-source-type-short-name',
    confidence: 0.9,
    manualConfidence: 0.9,
    kind: DataSourceTypeKind.Analyzer
};

export const createDataSourceTypeMock = (props: Partial<IDataSourceType> = {}): IDataSourceType => ({
    ...defaultDataSourceType,
    ...props
});
