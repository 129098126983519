import { ObservableEntityType } from '../common';
import { TObservableEntity } from '../observable';

import { IObservationHeader } from './observation';
import { ObservationType } from './observation-type';
import { TGetEntityType } from './utils';

export interface IObservationNetworkSession<EntityFormat extends TObservableEntity = TObservableEntity>
    extends IObservationHeader<ObservationType.NetworkSession> {
    content: IObservationNetworkSessionContent<EntityFormat>;
}

export interface IObservationNetworkSessionContent<EntityFormat extends TObservableEntity = TObservableEntity> {
    entity: TGetEntityType<EntityFormat>;
    duration: number;
    protocols: TProtocol<EntityFormat>[];
}

export type TProtocol<EntityFormat extends TObservableEntity = TObservableEntity> =
    | IProtocol<ProtocolType.TCP, EntityFormat>
    | IProtocol<ProtocolType.UDP, EntityFormat>
    | IProtocol<ProtocolType.IP, EntityFormat>
    | IProtocol<ProtocolType.HTTP, EntityFormat>
    | IProtocol<ProtocolType.HTTPS, EntityFormat>;

export interface IProtocol<
    PT extends ProtocolType = ProtocolType,
    EntityFormat extends TObservableEntity = TObservableEntity
> {
    protocol: PT;
    fields: TProtocolFields<PT, EntityFormat>;
}

export type TProtocolFields<T = ProtocolType, EntityFormat extends TObservableEntity = TObservableEntity> = T extends
    | ProtocolType.TCP
    | ProtocolType.UDP
    ? IProtocolFields
    : T extends ProtocolType.IP
    ? IHostProtocolFields<EntityFormat>
    : T extends ProtocolType.HTTP | ProtocolType.HTTPS
    ? IURLProtocolFields<EntityFormat>
    : IProtocolFields | IHostProtocolFields<EntityFormat> | IURLProtocolFields<EntityFormat>;

export interface IProtocolFields {
    destinationPort?: number;
    sourcePort?: number;
}

export interface IHostProtocolFields<EntityFormat extends TObservableEntity = TObservableEntity> {
    destination?: IHostProtocolFieldsDestination<EntityFormat>;
    source?: IHostProtocolFieldsDestination<EntityFormat>;
}

export interface IHostProtocolFieldsDestination<EntityFormat extends TObservableEntity = TObservableEntity> {
    ipAddress?: TGetEntityType<EntityFormat, ObservableEntityType.IPAddress>;
    domainName?: TGetEntityType<EntityFormat, ObservableEntityType.DomainName>;
}

export interface IURLProtocolFields<EntityFormat extends TObservableEntity = TObservableEntity> {
    url: TGetEntityType<EntityFormat, ObservableEntityType.URL>;
    method?: string;
    headers?: { name: string; value: string }[];
    version?: string;
}

export enum ProtocolType {
    IP = 'IP',
    TCP = 'TCP',
    UDP = 'UDP',
    HTTP = 'HTTP',
    HTTPS = 'HTTPS'
}
