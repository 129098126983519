import { Injectable, Provider } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IServerError } from '@pt-cybsi/api-interfaces';
import { FormStateValue, IFormRepository, IObservableUseCase } from '@pt-cybsi/shared';

import { ExternalDBModel } from '../../models';
import { ExternalDBFormRepository, ExternalDBFormRepositoryProvider } from '../../repositories';

export interface IRegisterExternalDBParams {
    id: string;
}

export type TRegisteredExternalDBId = string;

@Injectable()
export class RegisterExternalDBUseCase
    implements IObservableUseCase<IRegisterExternalDBParams, TRegisteredExternalDBId>
{
    constructor(private repository: IFormRepository<ExternalDBModel>) {}

    execute(params: IRegisterExternalDBParams): Observable<TRegisteredExternalDBId> {
        const { id } = params;

        const externalDB = this.repository.getCurrentData(id);
        const currentFormState = this.repository.getState(id);

        if (currentFormState.value === FormStateValue.Saved) {
            return of(externalDB.props.serverId);
        }

        this.repository.resetSavingError(id);

        this.switchState(id, FormStateValue.Saving);

        return this.repository.save(externalDB).pipe(
            tap((serverId: string) => {
                this.switchState(id, FormStateValue.Saved);

                this.updateExternalDB(id, serverId);

                this.repository.disable(id);
            }),
            catchError((error: unknown) => {
                this.switchState(id, FormStateValue.SavingFailure);

                this.repository.updateSavingError(id, error as IServerError);

                return throwError(error);
            })
        );
    }

    private updateExternalDB(id: string, serverId: string): void {
        const externalDB = this.repository.getCurrentData(id);

        externalDB.props.serverId = serverId;

        this.repository.updateCurrentData(externalDB);
    }

    private switchState(id: string, state: FormStateValue): void {
        const formState = this.repository.getState(id);

        const nextFormState = formState.to(state);

        this.repository.updateState(id, nextFormState);
    }
}

export const RegisterExternalDBUseCaseProvider: Provider = [
    ExternalDBFormRepositoryProvider,
    {
        provide: RegisterExternalDBUseCase,
        useFactory: (repository: ExternalDBFormRepository) => new RegisterExternalDBUseCase(repository),
        deps: [ExternalDBFormRepository]
    }
];
