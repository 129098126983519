import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';

const isModel = (v: unknown): v is Model<object> => v instanceof Model;

export abstract class Model<T extends object> {
    readonly props: T;

    get uid() {
        return this._uid;
    }

    protected readonly _uid: string;

    constructor(props: T, uid?: string) {
        this.props = props;
        this._uid = uid || this.generateId();
    }

    equals(model: Model<T>): boolean {
        if (model === null || model === undefined) {
            return false;
        }

        if (!isModel(model)) {
            return false;
        }

        if (this === model) {
            return true;
        }

        return this._uid === model._uid;
    }

    protected getChangedProps(comparedModel: Model<T>, requiredKeys: (keyof T)[]): T {
        const currentData = this.props;
        const comparedData = comparedModel.props;

        const requiredData = pick(currentData, requiredKeys);
        const optionalData = pickBy(currentData, (currentValue, key) => {
            const comparedValue = isNil(comparedData?.[key]) ? null : comparedData?.[key];

            return currentValue !== undefined && !isEqual(currentValue, comparedValue);
        });

        return { ...requiredData, ...optionalData };
    }

    protected abstract generateId(): string;
}

export type TBaseModel = Model<object>;
