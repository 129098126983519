import { ArtifactType } from '../artifacts';
import { IApiItemPreview } from '../common';
import { IDataSource } from '../data-sources';

export interface IEnrichmentAnalyzer extends IApiItemPreview {
    artifactTypes: ArtifactType[];
    artifactSizeLimit: number | null;
    dashboardURL: string;
    taskExecutionTimeout: number;
    taskExecutionAttemptsCount: number;
    dataSource: IDataSource;
}
