import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DefaultErrorCodes, HttpStatus, HttpStatusCode } from '@pt-cybsi/api-interfaces';
import { getLocaleServerErrorByStatusCode, isBadRequestServerError, isInternalServerError } from '@pt-cybsi/shared';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((response: unknown) => {
                if (response instanceof HttpErrorResponse) {
                    const { status } = response;

                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    const isErrorByStatus: boolean = isInternalServerError(status) || isBadRequestServerError(status);

                    if (isErrorByStatus) {
                        return throwError(
                            new HttpErrorResponse({
                                ...response,
                                error: {
                                    code: getLocaleServerErrorByStatusCode(status)
                                }
                            })
                        );
                    }

                    if (status === HttpStatusCode.PRECONDITION_FAILED) {
                        return throwError(
                            new HttpErrorResponse({
                                ...response,
                                error: {
                                    code: HttpStatus.PRECONDITION_FAILED
                                }
                            })
                        );
                    }

                    if (status === HttpStatusCode.FORBIDDEN) {
                        return throwError(
                            new HttpErrorResponse({
                                ...response,
                                error: {
                                    code:
                                        req.method !== 'GET'
                                            ? DefaultErrorCodes.ACTION_FORBIDDEN
                                            : DefaultErrorCodes.VIEW_FORBIDDEN
                                }
                            })
                        );
                    }

                    if (status === HttpStatusCode.NOT_FOUND) {
                        return throwError(
                            new HttpErrorResponse({
                                ...response,
                                error: {
                                    code: HttpStatus.NOT_FOUND
                                }
                            })
                        );
                    }

                    return throwError(
                        new HttpErrorResponse({
                            ...response,
                            error: {
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                                code: response.error?.code || DefaultErrorCodes.UNKNOWN
                            }
                        })
                    );
                }
            })
        );
    }
}
