import { AttributeObservableEntity, ShareLevel, TAttributeValueType, TConfidence } from '@pt-cybsi/api-interfaces';

import { TEntityPreviewData } from './entity-preview.types';

interface IBaseValuableFactView {
    dataSourceId: string;
    dataSourceConfidence: TConfidence;
    shareLevel: ShareLevel;
    seenAt: string;
    confidence: TConfidence;
    finalConfidence: TConfidence;
}

export interface IAttributeValuableFactView<AttributeName = AttributeObservableEntity> extends IBaseValuableFactView {
    attributeName: AttributeName;
    value: TAttributeValueType<AttributeName>;
}

export interface IRelationValuableFactView extends IBaseValuableFactView {
    value: TEntityPreviewData;
}

export type TValuableFactView<AttributeName = AttributeObservableEntity> =
    | IAttributeValuableFactView<AttributeName>
    | IRelationValuableFactView;
