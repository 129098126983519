import { Component } from '@angular/core';
import { AgFrameworkComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { LazyDataState } from '../../models';
import { LazyLoaderProgressState } from '../lazy-loader-progress/lazy-loader-progress.component';

import { ILazyGridProps } from './lazy-grid.types';

@Component({
    selector: 'lazy-grid-loader',
    styles: [':host { display: block; }'],
    template: `
        <lazy-loader-progress
            [state]="loaderState"
            [errorTitle]="errorTitle"
            [errorDescription]="errorDescription"
            [textReload]="textReload"
            (reload)="handleReload()"
        >
        </lazy-loader-progress>
    `
})
export class LazyGridLoaderComponent implements AgFrameworkComponent<ICellRendererParams> {
    get loaderState(): LazyLoaderProgressState {
        if (this.lazyGrid?.state === LazyDataState.Error) {
            return LazyLoaderProgressState.Error;
        } else {
            return LazyLoaderProgressState.Loading;
        }
    }

    get errorTitle(): string {
        return this.lazyGrid?.errorTitle;
    }

    get errorDescription(): string {
        return this.lazyGrid?.errorDescription;
    }

    get textReload(): string {
        return this.lazyGrid?.textReload;
    }

    private lazyGrid: ILazyGridProps;

    agInit(params: ICellRendererParams): void {
        this.lazyGrid = params.context.lazyGridContext;
    }

    handleReload() {
        this.lazyGrid.loadMore.emit();
    }
}
