import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[autofocus]'
})
export class AutofocusDirective implements AfterViewInit {
    @Input() autofocus: boolean;

    constructor(private host: ElementRef) {}

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.autofocus) {
                this.host.nativeElement.focus();
            }
        });
    }
}
