import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ColDef, GridOptions, RowGroupOpenedEvent } from 'ag-grid-community';

import {
    LazyDataState,
    TemplateCellComponent,
    LazyGridAgGridDetailsDirective,
    ConfidenceFormat
} from '@pt-cybsi/shared';

import { SourcesNavigationService } from '../../services';

export type TSourceTypesGridItemId = string;

export interface ISourceTypesGridItem {
    id: TSourceTypesGridItemId;
    type: {
        id: TSourceTypesGridItemId;
        name: string;
    };
    confidence: number;
}

/**
 * @component SourceTypesGrid
 *
 * @param state state of lazy-grid
 * @param sourceTypes list of source types for lazy-grid in a `ISourceTypesGridItem[]` format
 * @param errorTitle message displayed on 'error' state of lazy-grid
 * @param hasDetails flag enabling grouping rows of lazy-grid
 * @param typeDetailsTemplate TemplateRef of content displayed after expanding grouped row
 * @param expanded flag for expanding all rows after initialization
 * @param expandedTypes list of rows ids, that should be expanded
 * @param loadMore event triggered by lazy-grid, when grid scrollbar reaches end of grid and state is 'Pending'
 * @param toggleDetails event triggered by lazy-grid, when user expands a grouped row.
 * In args providing `$event` object with `id` a row and `expanded` flag.
 *
 * @description Is used for display grid of source types. For grid used lazy-grid component.
 *
 * Can be displayed with row grouping and show details section of each source type.
 * For example, in details section can display a list of sources. It's implement in SourcesTreeGrid component.
 *
 * @example
 * Live examples can be viewed in Storybook
 *
 * Example of template:
 * ```html
 *  <source-types-grid
 *      [state]="state"
 *      [sourceTypes]="sourceTypes"
 *      [errorTitle]="errorTitle"
 *      [hasDetails]="true"
 *      [typeDetailsTemplate]="detailsTemplate"
 *      [expandedTypes]="expandedTypes"
 *      (loadMore)="handleLoadMore()"
 *      (toggleDetails)="handleToggleDetails($event)"
 *  ></source-types-grid>
 *  <ng-template #detailsTemplate>
 *      <sources-grid
 *          [state]="Completed"
 *          [sources]="sources"
 *      ></sources-grid>
 *  </ng-template>
 * ```
 */
@Component({
    selector: 'source-types-grid',
    templateUrl: './source-types-grid.component.html',
    styleUrls: ['./source-types-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SourceTypesGridComponent implements AfterViewInit, OnChanges {
    @Input()
    state: LazyDataState;

    @Input()
    sourceTypes: ISourceTypesGridItem[];

    @Input()
    errorTitle: string;

    @Input()
    hasDetails: boolean;

    @Input()
    typeDetailsTemplate: TemplateRef<unknown>;

    @Input()
    expandedTypes: TSourceTypesGridItemId[];

    @Input()
    expanded: boolean;

    @Output()
    loadMore = new EventEmitter<void>();

    @Output()
    toggleDetails = new EventEmitter<{ id: TSourceTypesGridItemId; expanded: boolean }>();

    @ViewChild('typeCell')
    typeCellTemplate: TemplateRef<unknown>;

    @ViewChild('confidenceCell')
    confidenceCellTemplate: TemplateRef<unknown>;

    @ViewChild('lazyGrid', { read: LazyGridAgGridDetailsDirective })
    lazyGridDetails: LazyGridAgGridDetailsDirective;

    columns: ColDef[] = [];

    agGridOptions: GridOptions = {
        onRowGroupOpened: this.handleToggleDetails.bind(this),

        getRowNodeId: (nodeData: ISourceTypesGridItem) => nodeData.id
    };

    ConfidenceFormat = ConfidenceFormat;

    constructor(
        private translocoService: TranslocoService,
        private sourcesNavigationService: SourcesNavigationService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        const isExpandedChanged =
            changes.expanded?.currentValue && changes.expanded.currentValue !== changes.expanded.previousValue;

        const isExpandedTypesChanged =
            !this.expanded &&
            changes.expandedTypes?.currentValue &&
            changes.expandedTypes.currentValue !== changes.expandedTypes.previousValue;

        if (isExpandedChanged) {
            Object.assign(this.agGridOptions, {
                groupDefaultExpanded: this.expanded ? 1 : 0
            });
        }

        if (isExpandedTypesChanged) {
            this.expandTypes(this.expandedTypes);
        }
    }

    ngAfterViewInit(): void {
        this.columns = this.getGridColumnDefs();
        this.expandTypes(this.expandedTypes);
    }

    handleToggleDetails($event: RowGroupOpenedEvent): void {
        const {
            data: { id },
            expanded
        } = $event;

        this.toggleDetails.emit({ id, expanded });
    }

    getSourceTypeLink(sourceTypeId: TSourceTypesGridItemId): string {
        return this.sourcesNavigationService.getPathOfSourceTypeViewRoute(sourceTypeId);
    }

    expandTypes(typeIds: TSourceTypesGridItemId[]): void {
        if (this.hasDetails && typeIds?.length && this.lazyGridDetails) {
            this.lazyGridDetails.expandRows(typeIds);
        }
    }

    private getGridColumnDefs(): ColDef[] {
        const typeColumnRendererConfig = this.hasDetails
            ? {
                  cellRenderer: 'agGroupCellRenderer',
                  cellRendererParams: {
                      template: this.typeCellTemplate,
                      innerRendererFramework: TemplateCellComponent
                  }
              }
            : {
                  cellRendererFramework: TemplateCellComponent,
                  cellRendererParams: {
                      template: this.typeCellTemplate
                  }
              };

        return [
            {
                ...typeColumnRendererConfig,

                field: 'type',
                headerName: this.translocoService.translate('sources.Sources.Pseudo.Text.Source'),
                width: 400
            },
            {
                field: 'confidence',
                headerName: this.translocoService.translate('sources.Sources.Pseudo.Text.Confidence'),
                flex: 1,
                cellRendererFramework: TemplateCellComponent,
                cellRendererParams: {
                    template: this.confidenceCellTemplate
                }
            }
        ];
    }
}
