import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { McCheckboxModule } from '@ptsecurity/mosaic/checkbox';
import { McDividerModule } from '@ptsecurity/mosaic/divider';
import { McInputModule } from '@ptsecurity/mosaic/input';
import { McListModule } from '@ptsecurity/mosaic/list';
import { McModalModule } from '@ptsecurity/mosaic/modal';
import { McProgressSpinnerModule } from '@ptsecurity/mosaic/progress-spinner';
import { McSelectModule } from '@ptsecurity/mosaic/select';
import { McSidepanelModule } from '@ptsecurity/mosaic/sidepanel';
import { McToolTipModule } from '@ptsecurity/mosaic/tooltip';

import { DomainCoreDictionariesModule } from '@pt-cybsi/domain-core/dictionaries';
import { DomainCoreSourcesModule } from '@pt-cybsi/domain-core/sources';
import { SharedModule } from '@pt-cybsi/shared';

import {
    EntityKeyFieldComponent,
    EntityTypesSelectComponent,
    EntityPreviewComponent,
    EntityTypesFilterComponent,
    EntityAttributeBadgeComponent,
    EntityKeyFieldMcValidateDirective,
    EntityTypesSelectMcValidateDirective,
    EntityAttributeValueComponent,
    FileKeysComponent,
    IdentityKeysComponent,
    ContentWithConfidenceComponent,
    EntityMetadataCardComponent,
    ValuableFactsTableComponent,
    RelationTypePreviewComponent,
    ForecastModalComponent
} from './components';
import {
    FormatAsnPipe,
    TranslateAttributeNamePipe,
    TranslateIdentityClassValuePipe,
    TranslateObservableEntityTypePipe,
    TranslateObservableEntityTypesPipe,
    TranslateIsDelegatedValuePipe,
    TranslateRelatedThreatCategoryValuePipe,
    TranslateThreatCategoryValuePipe,
    TranslateRelationLinkTypePipe,
    TranslateGraphRelationPipe
} from './pipes';
import { EntityPreviewDataService, ObservableEntitiesNavigationService, RelationsResolverService } from './services';

// prettier-ignore
const modules = [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    McInputModule,
    McModalModule,
    McSelectModule,
    McToolTipModule,
    McSidepanelModule,
    McCheckboxModule,
    McDividerModule,
    McListModule,
    McProgressSpinnerModule,
    DomainCoreDictionariesModule,
    DomainCoreSourcesModule,
    OverlayModule,
    SharedModule
];

const components = [
    ContentWithConfidenceComponent,
    EntityAttributeBadgeComponent,
    EntityAttributeValueComponent,
    EntityKeyFieldComponent,
    EntityMetadataCardComponent,
    EntityPreviewComponent,
    EntityTypesSelectComponent,
    EntityTypesFilterComponent,
    ForecastModalComponent,
    FileKeysComponent,
    IdentityKeysComponent,
    ValuableFactsTableComponent,
    RelationTypePreviewComponent
];

// prettier-ignore
const directives = [
    EntityKeyFieldMcValidateDirective,
    EntityTypesSelectMcValidateDirective
]

// prettier-ignore
const pipes = [
    FormatAsnPipe,
    TranslateAttributeNamePipe,
    TranslateIdentityClassValuePipe,
    TranslateIsDelegatedValuePipe,
    TranslateObservableEntityTypePipe,
    TranslateObservableEntityTypesPipe,
    TranslateRelatedThreatCategoryValuePipe,
    TranslateThreatCategoryValuePipe,
    TranslateRelationLinkTypePipe,
    TranslateGraphRelationPipe
];
// prettier-ignore
const services = [
    EntityPreviewDataService,
    ObservableEntitiesNavigationService,
    RelationsResolverService
];

@NgModule({
    imports: [...modules],
    declarations: [...components, ...directives, ...pipes],
    providers: [...services, ...pipes],
    exports: [...components, ...directives, ...pipes]
})
export class DomainCoreObservableEntitiesModule {}
