import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { PasswordRules } from '@ptsecurity/mosaic/form-field';

import { Role, ROLES } from '@pt-cybsi/api-interfaces';
import { createASCSortComparator, FormMode, generatePassword, selectHiddenItemsTextFormatter } from '@pt-cybsi/shared';

import { UserFormViewModel, PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from '../../forms';
import { TranslateRolePipe } from '../../pipes';
import { LOGIN_MAX_LENGTH, NAME_MAX_LENGTH, EMAIL_MIN_LENGTH, EMAIL_MAX_LENGTH } from '../../validators';

@Component({
    selector: 'user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent {
    @Input() form: UserFormViewModel;
    @Input() authProviderName: string;

    @ViewChild('passwordInput', { read: ElementRef }) passwordInput: ElementRef<HTMLInputElement>;
    @ViewChild('passwordToggle', { read: ElementRef }) passwordToggle: ElementRef<HTMLElement>;

    availableRoles = ROLES.map((role: Role) => ({
        id: role,
        name: this.translateRolePipe.transform(role)
    })).sort(createASCSortComparator((item) => item.name));
    PasswordRules = PasswordRules;
    passwordLengthRequirements = {
        min: PASSWORD_MIN_LENGTH,
        max: PASSWORD_MAX_LENGTH
    };

    invalidLoginLengthMessage = translate('common.Common.Validation.Text.StringLengthMax', {
        max: LOGIN_MAX_LENGTH
    });
    invalidFullNameLengthMessage = translate('common.Common.Validation.Text.StringLengthMax', {
        max: NAME_MAX_LENGTH
    });
    invalidEmailLengthMessage = translate('common.Common.Validation.Text.StringLengthBetween', {
        min: EMAIL_MIN_LENGTH,
        max: EMAIL_MAX_LENGTH
    });
    invalidPasswordLengthMessage = translate('common.Common.Validation.Text.StringLengthMin', {
        min: PASSWORD_MIN_LENGTH
    });
    selectHiddenItemsTextFormatter = selectHiddenItemsTextFormatter;

    get isModeCreation(): boolean {
        return this.form.mode === FormMode.Creation;
    }

    constructor(private translateRolePipe: TranslateRolePipe) {}

    handleGeneratePassword() {
        this.form.getControl('password').setValue(generatePassword());
        this.passwordInput.nativeElement.focus();

        if (this.passwordInput.nativeElement.type === 'password') {
            this.passwordToggle.nativeElement.click();
        }
    }
}
