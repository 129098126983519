import { IApiItemPreview, TConfidence } from '../common';
import { IEnrichmentAnalyzer, IEnrichmentExternalDB } from '../enrichment';
import { IUser } from '../users';

export interface IDataSource {
    uuid: string;
    url?: string;
    name: string;
    longName: string;
    uniqueName: string; // @deprecated
    confidence: TConfidence;
    manualConfidence: TConfidence;
    type: IDataSourceTypeShortInfo;
}

export interface IDataSourceLinks {
    analyzer: IApiItemPreview | null;
    externalDB: IApiItemPreview | null;
    user: IApiItemPreview | null;
    users: IApiItemPreview[] | null;
}

export interface IDataSourceAssociatedObjects {
    analyzer: IEnrichmentAnalyzer | null;
    externalDB: IEnrichmentExternalDB | null;
    user: IUser | null;
    users: IUser[] | null;
}

export interface IDataSourceTypeShortInfo {
    uuid: string;
    url?: string;
    longName: string;
    confidence: TConfidence;
}

export interface IDataSourceType extends IDataSourceTypeShortInfo {
    shortName: string;
    manualConfidence: TConfidence;
    kind: DataSourceTypeKind; // @deprecated
}

export enum DataSourceTypeKind {
    Analyzer = 'Analyzer',
    ExternalDB = 'ExternalDB',
    ExternalFeed = 'ExternalFeed',
    User = 'User'
}
