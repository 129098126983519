<mc-select
    #mcSelect
    [multiple]="multiple"
    [ngModel]="value"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [required]="required"
    [hiddenItemsTextFormatter]="selectHiddenItemsTextFormatter"

    (focus)="handleFocus()"
    (valueChange)="handleChangeValue($event)"
    (openedChange)="handleOpenedChange($event)">
    <mc-cleaner #mcSelectCleaner></mc-cleaner>

    <mc-form-field mcFormFieldWithoutBorders mcSelectSearch>
        <i mcPrefix mc-icon="mc-search_16"></i>
        <input mcInput [formControl]="searchControl" type="text" autocomplete="off"/>
        <mc-cleaner></mc-cleaner>
    </mc-form-field>

    <mc-option
        *ngIf="isLoaderVisible$ | async"
        [disabled]="true"
        [showCheckbox]="false"
        class="sources-select__spinner">
        <mc-progress-spinner [mode]="'indeterminate'"></mc-progress-spinner>
    </mc-option>

    <mc-option
        *ngIf="isNotFoundData$ | async"
        [disabled]="true"
        [showCheckbox]="false">
        {{ 'common.Common.Search.Title.NotFound' | transloco }}
    </mc-option>

    <mc-option
        *ngIf="isEmptyData$ | async"
        [disabled]="true"
        [showCheckbox]="false">
        {{ 'sources.Sources.Pseudo.Text.EmptyMessage' | transloco }}
    </mc-option>

    <mc-option
        *ngIf="isErrorVisible$ | async"
        [disabled]="true"
        [showCheckbox]="false"
        class="sources-select__error">
        <div>{{ 'common.Common.Loading.Text.ErrorTitle' | transloco }}</div>
        <button
            mc-button
            color="primary"
            class="mc-button_transparent"
            (click)="handleRetryLoadData()">
            {{ 'common.Common.Loading.Text.RetryButton' | transloco }}
        </button>
    </mc-option>

    <div [ngClass]="{ 'hidden': isDataHidden$ | async }">
        <mc-option
            *ngFor="let sourceId of availableSources$ | async"
            [value]="sourceId">
            <source-preview [sourceId]="sourceId" [format]="SourcePreviewFormat.Text"></source-preview>
        </mc-option>
    </div>
</mc-select>
