import { ApiResource, PermissionType } from '@pt-cybsi/api-interfaces';
import { buildPermissionString, RootPermissionsService } from '@pt-cybsi/domain-core/account';

const Read = PermissionType.Read;
const Write = PermissionType.Write;

export class ReputationListsPermissionsService {
    static readonly viewListsPermissions = RootPermissionsService.viewReplistsPermissions;

    static readonly viewListPermissions = [
        buildPermissionString(ApiResource.ReputationLists, [Read]),
        buildPermissionString(ApiResource.StoredQuery, [Read])
    ];

    static readonly createListPermissions = [
        buildPermissionString(ApiResource.ReputationLists, [Write]),
        buildPermissionString(ApiResource.StoredQuery, [Write])
    ];

    static readonly editListPermissions = [
        buildPermissionString(ApiResource.ReputationLists, [Write]),
        buildPermissionString(ApiResource.StoredQuery, [Write])
    ];

    static readonly changeEnablingListPermissions = [buildPermissionString(ApiResource.ReputationLists, [Write])];
}
