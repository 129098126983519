interface IValueObjectProps {
    [index: string]: unknown;
}

export type TValueObjectProps<T> = T & IValueObjectProps;

export abstract class ValueObject<T extends IValueObjectProps> {
    readonly props: T;

    constructor(props: T) {
        this.props = Object.freeze(props);
    }
}
