import { Injectable, Provider } from '@angular/core';

import { FormsStore } from '@pt-cybsi/shared';

import { TAnalyserFormData } from '../../mappers';

import { AnalyserFormBuilder } from './analyser-form.builder';

/**
 * @store AnalysersForms
 *
 * @description
 * Reactive store for analysers forms.
 */
@Injectable()
export class AnalysersFormsStore extends FormsStore<TAnalyserFormData> {
    constructor(
        protected creationFormViewModelBuilder: AnalyserFormBuilder,
        protected editFormViewModelBuilder: AnalyserFormBuilder
    ) {
        super();
    }
}

export const AnalysersFormsStoreProvider: Provider = [
    AnalyserFormBuilder,
    {
        provide: AnalysersFormsStore,
        useFactory: (builder: AnalyserFormBuilder) => new AnalysersFormsStore(builder, builder),
        deps: [AnalyserFormBuilder]
    }
];
