<div class="view-page-container__header flex-grow">
    <ng-content select="[view-page-container-header]"></ng-content>
</div>

<div #body
     class="view-page-container__body flex"
     [class.view-page-container__body_with-sidebar]="sidebar"
     [style.grid-template-columns]="bodyGridTemplate">
    <div #contentWrapper
         class="view-page-container__content-wrapper"
         [style.margin-right]="scrollbarCompensation">
        <div class="view-page-container__content"
             [style.max-width]="maxContentWidth">
            <ng-content select="[view-page-container-content]"></ng-content>
        </div>
    </div>
    <div class='view-page-container__sidebar-wrapper'>
        <div class="view-page-container__sidebar">
            <ng-content select="[view-page-container-sidebar]"></ng-content>
        </div>
    </div>
</div>
