import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ReputationListsNavigationService, ReputationListsPermissionsService } from './services';

// prettier-ignore
const services = [
    ReputationListsNavigationService,
    ReputationListsPermissionsService
];
@NgModule({
    imports: [CommonModule],
    providers: [...services]
})
export class DomainCoreReputationListsModule {}
