import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IDropdownFilterItem } from '../dropdown-filter/dropdown-filter.component';
import { FiltersPanelBaseItem } from '../filters-panel/filters-panel.component';

export enum StatusFilterValue {
    All = 'All',
    Active = 'Active',
    Inactive = 'Inactive'
}
export type TStatusFilterItem = IDropdownFilterItem<boolean | undefined>;

/**
 * @component Status Filter
 *
 * @description
 * Used for filtering data by status. Can be put into a filters-panel component
 *
 * @param value - applied values of filter in boolean
 * @param translationKeyBase - values of translation key base filter in `TStatusFilterItem[]` format
 * @param isValueReversed - boolean flag that tells that value is reversed (true -> false, false -> true)
 * @param applyFilter - event, emitted after clicking apply filter button
 * @param resetFilter - event, emitted after clicking reset filter button
 *
 * @example
 * ```html
 * <status-filter
 *     [value]="value"
 *     [translationKeyBase]="translationKeyBase"
 *     (applyFilter)="handleApply($event)"
 *     (resetFilter)="handleReset()">>
 * </status-filter>
 * ```
 */
@Component({
    selector: 'status-filter',
    templateUrl: './status-filter.component.html',
    providers: [
        {
            provide: FiltersPanelBaseItem,
            useExisting: forwardRef(() => StatusFilterComponent)
        }
    ]
})
export class StatusFilterComponent extends FiltersPanelBaseItem implements OnInit, OnChanges {
    @Input() value: boolean;
    @Input() translationKeyBase: string;
    @Input() isValueReversed = false;

    @Output() applyFilter = new EventEmitter<boolean | undefined>();
    @Output() resetFilter = new EventEmitter<void>();

    dropdownItems: TStatusFilterItem[];
    defaultStatus: TStatusFilterItem = {
        value: undefined,
        name: this.translocoService.translate('common.Common.Pseudo.Text.All')
    };
    appliedValue$: BehaviorSubject<TStatusFilterItem> = new BehaviorSubject(this.defaultStatus);
    valueTitle$: Observable<string>;

    readonly title: string = this.translocoService.translate('common.Common.Pseudo.Text.Status');

    constructor(private translocoService: TranslocoService) {
        super();
    }

    ngOnInit(): void {
        this.dropdownItems = this.getDropdownItems();
        this.valueTitle$ = this.getValueTitle$();
        this.setAppliedValue();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const isValueChanged = changes.value?.currentValue !== changes.value?.previousValue;

        if (isValueChanged && this.dropdownItems) {
            this.setAppliedValue();
        }
    }

    handleApply(value: TStatusFilterItem) {
        this.applyFilter.emit(value.value);
    }

    reset(): void {
        this.resetFilter.emit();
    }

    private setAppliedValue(): void {
        this.appliedValue$.next(this.dropdownItems.find((dropdownItem) => dropdownItem.value === this.value));
    }

    private getDropdownItems(): TStatusFilterItem[] {
        return [
            this.defaultStatus,
            {
                value: this.getDropdownValue(StatusFilterValue.Active),
                name: this.translocoService.translate(`${this.translationKeyBase}.${StatusFilterValue.Active}`)
            },
            {
                value: this.getDropdownValue(StatusFilterValue.Inactive),
                name: this.translocoService.translate(`${this.translationKeyBase}.${StatusFilterValue.Inactive}`)
            }
        ];
    }

    private getValueTitle$(): Observable<string> {
        return this.appliedValue$.pipe(
            map((appliedValue) => {
                if (appliedValue?.value === undefined) {
                    return '';
                }

                return appliedValue.name;
            })
        );
    }

    private getDropdownValue(value: StatusFilterValue): boolean {
        const valueMap: Partial<Record<StatusFilterValue, boolean>> = {
            [StatusFilterValue.Active]: true,
            [StatusFilterValue.Inactive]: false
        };

        return this.isValueReversed ? !valueMap[value] : valueMap[value];
    }
}
