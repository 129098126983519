<lazy-grid
    [agGridOptions]="agGridOptions"
    [columns]="columns"
    [data]="sources"
    [state]="state"
    [errorTitle]="errorTitle"
    (loadMore)="loadMore.emit()"
></lazy-grid>

<ng-template #sourceCell let-data="params.value">
    <div class="source-grid__source-cell">
        <a mc-link pt-title [routerLink]="getSourceLink(data.id)" class="mc-link_overflowed">
            <span class="mc-link__text">{{ data.name }}</span>
        </a>
    </div>
</ng-template>

<ng-template #confidenceCell let-data="params.value">
    {{ data | formatConfidence:ConfidenceFormat.Full }}
</ng-template>
