<ng-container *transloco="let tUsers; read: 'users'">
    <div *ngIf="form.isVisibleControl('oldPassword')" class="mc-form__row margin-bottom_12">
        <label class="mc-form__label">
            {{ tUsers('Users.PasswordForm.Field.OldPassword') }}
        </label>

        <div class="layout-row layout-align-start-start">
            <mc-form-field>
                <input mcInputPassword [autofocus]="true" [formControl]="form.getControl('oldPassword')">
                <mc-password-toggle
                    [mcTooltipNotHidden]="'common.Common.Pseudo.Text.PasswordHide' | transloco"
                    [mcTooltipHidden]="'common.Common.Pseudo.Text.PasswordShow' | transloco">
                </mc-password-toggle>
            </mc-form-field>
        </div>
    </div>
    <div class="mc-form__row">
        <label class="mc-form__label">
            {{ tUsers('Users.PasswordForm.Field.NewPassword') }}
        </label>

        <div class="layout-row layout-align-start-start">
            <mc-form-field>
                <input [autofocus]="!this.form.isVisibleControl('oldPassword')"
                       #newPasswordInput mcInputPassword
                       [formControl]="form.getControl('newPassword')">
                <mc-password-toggle
                    #passwordToggle
                    [mcTooltipNotHidden]="'common.Common.Pseudo.Text.PasswordHide' | transloco"
                    [mcTooltipHidden]="'common.Common.Pseudo.Text.PasswordShow' | transloco">
                </mc-password-toggle>

                <mc-password-hint [rule]="PasswordRules.Length" [min]="passwordLengthRequirements.min" [max]="passwordLengthRequirements.max">
                    {{ invalidPasswordLengthMessage }}
                </mc-password-hint>
                <mc-password-hint [rule]="PasswordRules.UpperLatin">{{ tUsers('Users.UserForm.PasswordHint.UpperLatin') }}</mc-password-hint>
                <mc-password-hint [rule]="PasswordRules.LowerLatin">{{ tUsers('Users.UserForm.PasswordHint.LowerLatin') }}</mc-password-hint>
                <mc-password-hint [rule]="PasswordRules.Digit">{{ tUsers('Users.UserForm.PasswordHint.Digit') }}</mc-password-hint>
                <mc-password-hint [rule]="PasswordRules.LatinAndSpecialSymbols">{{ tUsers('Users.UserForm.PasswordHint.LatinAndSpecialSymbols') }}</mc-password-hint>
            </mc-form-field>

            <div class="password-form__generate-container layout-row layout-align-start-center margin-left_16">
                <button mc-link pseudo type="button" class="clear-button" (click)="handleGeneratePassword()">
                    {{ tUsers('Users.UserForm.Button.Generate') }}
                </button>
            </div>
        </div>
    </div>
</ng-container>
