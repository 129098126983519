import { Unpacked } from '@pt-cybsi/core';

import {
    AttributeObservableEntity,
    RelationType,
    TFactOfAssociatedAttribute,
    TFactOfNaturalAttribute,
    TObservableEntity
} from '../observable';

import { IObservationHeader } from './observation';
import { ObservationType } from './observation-type';
import { TGetEntityType } from './utils';

export interface IObservationGeneric<EntityFormat extends TObservableEntity = TObservableEntity>
    extends IObservationHeader<ObservationType.Generic> {
    content: IObservationGenericContent<EntityFormat>;
}

export interface IObservationGenericContent<EntityFormat extends TObservableEntity = TObservableEntity> {
    entityRelationships: IGenericEntityRelation<EntityFormat>[];
    entityAttributeValues: IGenericEntityAttribute<EntityFormat>[];
}

export interface IGenericEntityRelation<EntityFormat extends TObservableEntity = TObservableEntity> {
    source: TGetEntityType<EntityFormat>;
    target: TGetEntityType<EntityFormat>;
    kind: RelationType;
    confidence: number;
}

export interface IGenericEntityAttribute<EntityFormat extends TObservableEntity = TObservableEntity> {
    attributeName: AttributeObservableEntity;
    value: Unpacked<TFactOfAssociatedAttribute['value']> | Unpacked<TFactOfNaturalAttribute['value']>;
    entity: TGetEntityType<EntityFormat>;
    confidence: number;
}
