import { AgGridEvent, ColDef, GridApi, GridOptions, ICellRendererParams, Module, ModuleNames } from 'ag-grid-community';
import { Observable } from 'rxjs';

export interface IGenCellRendererParams<T> extends ICellRendererParams {
    data: T;
}

export function gridEvent$<T extends AgGridEvent>(api: GridApi, eventType: string): Observable<T> {
    if (!api || !eventType) {
        return undefined;
    }

    return new Observable<T>((observer) => {
        const listener = (event: T) => {
            observer.next(event);
        };

        api.addEventListener(eventType, listener);

        return {
            unsubscribe: () => {
                api.removeEventListener(eventType, listener);
            }
        };
    });
}

export function isGridEndScrollPosition(root: Element): boolean {
    if (root) {
        const viewport = root.querySelector('.ag-body-viewport');

        return Math.floor(viewport.scrollHeight - viewport.clientHeight - viewport.scrollTop) === 0;
    }

    return false;
}

export const defaultColumnDefinition: Partial<ColDef> = {
    suppressMovable: true,
    suppressSizeToFit: true
};

export const defaultGridOptions: Partial<GridOptions> = {
    suppressScrollOnNewData: true,
    immutableData: true,
    rowBuffer: 100,
    headerHeight: 32,
    rowHeight: 36,
    suppressNoRowsOverlay: true,
    suppressLoadingOverlay: true,
    defaultColDef: { ...defaultColumnDefinition }
};

export function createGridColumn(columnDef: Partial<ColDef>): ColDef {
    return {
        ...defaultColumnDefinition,
        ...columnDef
    };
}

export const MasterDetailModule: Module = {
    moduleName: ModuleNames.MasterDetailModule,
    beans: [],
    userComponents: []
};
