import { Pipe, PipeTransform } from '@angular/core';
import { DateFormatter } from '@ptsecurity/mosaic/core';
import { DateTime } from 'luxon';

interface IFormattedDatePipeOptions {
    format: string;
    run: 'sync' | 'async';
}

const DEFAULT_OPTIONS: IFormattedDatePipeOptions = {
    format: 'absoluteLongDateTime',
    run: 'sync'
};

@Pipe({
    name: 'formattedDate'
})
export class FormattedDatePipe implements PipeTransform {
    constructor(private dateFormatter: DateFormatter<unknown>) {}

    transform(value: string, options: IFormattedDatePipeOptions = DEFAULT_OPTIONS): string | Promise<string> {
        const formatOptions: IFormattedDatePipeOptions = {
            ...DEFAULT_OPTIONS,
            ...options
        };

        if (formatOptions.run === 'async') {
            return this.idleTask(() => this.to(value, formatOptions.format));
        }

        return this.to(value, formatOptions.format);
    }

    private to(value: string, format: string): string {
        if (!value) {
            return '';
        }

        const dateTime: DateTime = DateTime.fromISO(value);

        switch (format) {
            case 'absoluteLongDate':
                return this.dateFormatter.absoluteLongDate(dateTime);
            case 'absoluteLongDateTime':
                return this.dateFormatter.absoluteLongDateTime(dateTime);
            case 'absoluteLongDateTimeWithSeconds':
                return this.dateFormatter.absoluteLongDateTime(dateTime, { seconds: true });
            case 'absoluteShortDateTime':
                return this.dateFormatter.absoluteShortDateTime(dateTime);
            case 'absoluteShortDate':
                return this.dateFormatter.absoluteShortDate(dateTime);
            case 'relativeLongDate':
                return this.dateFormatter.relativeLongDate(dateTime);
            default:
                throw new Error('Unknown date format');
        }
    }

    private idleTask(func: () => string): Promise<string> {
        return new Promise((resolve) => {
            setTimeout(() => resolve(func()));
        });
    }
}
