import { Component, Input } from '@angular/core';

@Component({
    selector: 'default-collection-item',
    templateUrl: './default-collection-item.component.html',
    styleUrls: ['./default-collection-item.component.scss']
})
export class DefaultCollectionItemComponent {
    @Input() value: string;
    @Input() isLast: boolean;
}
