import { Component, OnInit, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { zip, BehaviorSubject, Observable } from 'rxjs';

import { Locale } from '../../i18n-config';
import { LocaleService } from '../../locale.service';

@Component({
    selector: 'transloco-provider',
    templateUrl: './transloco-provider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslocoProviderComponent implements OnInit {
    @Input()
    content: TemplateRef<unknown>;

    isTranslationLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get isLoaded$(): Observable<boolean> {
        return this.isTranslationLoaded$;
    }

    constructor(private translocoService: TranslocoService, private localeService: LocaleService) {}

    ngOnInit(): void {
        this.localeService.currentLocale = Locale.RuRu;

        this.loadCommonTranslations().subscribe(() => {
            this.isTranslationLoaded$.next(true);
        });
    }

    private loadCommonTranslations() {
        const activeLang = this.translocoService.getActiveLang();

        return zip(
            this.translocoService.load(`common/${activeLang}`),
            this.translocoService.load(`account/${activeLang}`),
            this.translocoService.load(`errors/${activeLang}`),
            this.translocoService.load(`system/${activeLang}`),
            this.translocoService.load(`dictionaries/${activeLang}`),
            this.translocoService.load(`replists/${activeLang}`),
            this.translocoService.load(`entities/${activeLang}`),
            this.translocoService.load(`sources/${activeLang}`),
            this.translocoService.load(`artifacts/${activeLang}`),
            this.translocoService.load(`enrichment/${activeLang}`),
            this.translocoService.load(`users/${activeLang}`),
            this.translocoService.load(`dictionaries/${activeLang}`),
            this.translocoService.load(`license/${activeLang}`),
            // загружаем так server.json, чтобы не было scope у ключей
            this.translocoService.load(`${activeLang}`)
        );
    }
}
