import { ObservableEntityType, ShareLevel, TConfidence } from '../common';
import { IDataSource } from '../data-sources';

import { TObservableEntity } from './entity';

export interface IRelationLink<RelatedEntityFormat = TObservableEntity> {
    link: {
        direction: RelationDirection;
        relationKind: RelationType;
        relatedEntity: RelatedEntityFormat;
    };
    confidence: TConfidence;
}

export type TRelationsStatistic = IRelationStatistic[];

export interface IRelationForecast {
    relationship: IRelation;
    confidence: TConfidence;
    valuableFacts: IRelationValuableFact[] | null;
}

export interface IRelationValuableFact {
    dataSource: IDataSource;
    shareLevel: ShareLevel;
    seenAt: string;
    confidence: TConfidence;
    finalConfidence: TConfidence;
}

export interface IRelation<RelationEntityFormat = TObservableEntity> {
    sourceEntity: RelationEntityFormat;
    targetEntity: RelationEntityFormat;
    relationKind: RelationType;
}

export interface IRelationStatistic {
    linkType: IRelationStatisticLinkType;
    links: {
        total: number;
        distributionByConfidence: IRelationStatisticDistribution[];
    };
}

export interface IRelationStatisticLinkType {
    linkDirection: RelationDirection;
    relationKind: RelationType;
    relatedEntitiesType: ObservableEntityType;
}

export interface IRelationStatisticDistribution {
    confidenceRange: [TConfidence, TConfidence];
    count: number;
}

export enum RelationType {
    ConnectsTo = 'ConnectsTo',
    Contains = 'Contains',
    Drops = 'Drops',
    Has = 'Has',
    Hosts = 'Hosts',
    Locates = 'Locates',
    Owns = 'Owns',
    ResolvesTo = 'ResolvesTo',
    Serves = 'Serves',
    Supports = 'Supports',
    Uses = 'Uses'
}

export enum RelationDirection {
    Forward = 'Forward',
    Reverse = 'Reverse'
}

const OET = ObservableEntityType;
const RT = RelationType;
const RD = RelationDirection;

export interface IRelationConfig {
    source: ObservableEntityType;
    kind: RelationType;
    target: ObservableEntityType;
    direction: RelationDirection;
}

/**
 * List of available entities relations.
 * Source: https://wiki.ptsecurity.com/pages/viewpage.action?pageId=45890652
 */
export const AVAILABLE_RELATIONS: IRelationConfig[] = [
    { source: OET.DomainName, kind: RT.Hosts, target: OET.File, direction: RD.Forward },
    { source: OET.DomainName, kind: RT.Hosts, target: OET.File, direction: RD.Reverse },
    { source: OET.DomainName, kind: RT.Serves, target: OET.IPAddress, direction: RD.Forward },
    { source: OET.DomainName, kind: RT.Serves, target: OET.IPAddress, direction: RD.Reverse },
    { source: OET.DomainName, kind: RT.Serves, target: OET.DomainName, direction: RD.Forward },
    { source: OET.DomainName, kind: RT.Serves, target: OET.DomainName, direction: RD.Reverse },
    { source: OET.DomainName, kind: RT.Contains, target: OET.DomainName, direction: RD.Forward },
    { source: OET.DomainName, kind: RT.Contains, target: OET.DomainName, direction: RD.Reverse },
    { source: OET.DomainName, kind: RT.ResolvesTo, target: OET.IPAddress, direction: RD.Forward },
    { source: OET.DomainName, kind: RT.ResolvesTo, target: OET.IPAddress, direction: RD.Reverse },
    { source: OET.DomainName, kind: RT.ResolvesTo, target: OET.DomainName, direction: RD.Forward },
    { source: OET.DomainName, kind: RT.ResolvesTo, target: OET.DomainName, direction: RD.Reverse },
    { source: OET.URL, kind: RT.Contains, target: OET.IPAddress, direction: RD.Forward },
    { source: OET.URL, kind: RT.Contains, target: OET.IPAddress, direction: RD.Reverse },
    { source: OET.URL, kind: RT.Contains, target: OET.DomainName, direction: RD.Forward },
    { source: OET.URL, kind: RT.Contains, target: OET.DomainName, direction: RD.Reverse },
    { source: OET.URL, kind: RT.Locates, target: OET.File, direction: RD.Forward },
    { source: OET.URL, kind: RT.Locates, target: OET.File, direction: RD.Reverse },
    { source: OET.Identity, kind: RT.Has, target: OET.PhoneNumber, direction: RD.Forward },
    { source: OET.Identity, kind: RT.Has, target: OET.PhoneNumber, direction: RD.Reverse },
    { source: OET.Identity, kind: RT.Has, target: OET.EmailAddress, direction: RD.Forward },
    { source: OET.Identity, kind: RT.Has, target: OET.EmailAddress, direction: RD.Reverse },
    { source: OET.Identity, kind: RT.Has, target: OET.Identity, direction: RD.Forward },
    { source: OET.Identity, kind: RT.Has, target: OET.Identity, direction: RD.Reverse },
    { source: OET.Identity, kind: RT.Owns, target: OET.IPAddress, direction: RD.Forward },
    { source: OET.Identity, kind: RT.Owns, target: OET.IPAddress, direction: RD.Reverse },
    { source: OET.Identity, kind: RT.Serves, target: OET.DomainName, direction: RD.Forward },
    { source: OET.Identity, kind: RT.Serves, target: OET.DomainName, direction: RD.Reverse },
    { source: OET.Identity, kind: RT.Owns, target: OET.PhoneNumber, direction: RD.Forward },
    { source: OET.Identity, kind: RT.Owns, target: OET.PhoneNumber, direction: RD.Reverse },
    { source: OET.Identity, kind: RT.Owns, target: OET.DomainName, direction: RD.Forward },
    { source: OET.Identity, kind: RT.Owns, target: OET.DomainName, direction: RD.Reverse },
    { source: OET.Identity, kind: RT.Supports, target: OET.DomainName, direction: RD.Forward },
    { source: OET.Identity, kind: RT.Supports, target: OET.DomainName, direction: RD.Reverse },
    { source: OET.Identity, kind: RT.Supports, target: OET.IPAddress, direction: RD.Forward },
    { source: OET.Identity, kind: RT.Supports, target: OET.IPAddress, direction: RD.Reverse },
    { source: OET.PhoneNumber, kind: RT.Contains, target: OET.PhoneNumber, direction: RD.Forward },
    { source: OET.PhoneNumber, kind: RT.Contains, target: OET.PhoneNumber, direction: RD.Reverse },
    { source: OET.File, kind: RT.ConnectsTo, target: OET.DomainName, direction: RD.Forward },
    { source: OET.File, kind: RT.ConnectsTo, target: OET.DomainName, direction: RD.Reverse },
    { source: OET.File, kind: RT.ConnectsTo, target: OET.URL, direction: RD.Forward },
    { source: OET.File, kind: RT.ConnectsTo, target: OET.URL, direction: RD.Reverse },
    { source: OET.File, kind: RT.ConnectsTo, target: OET.IPAddress, direction: RD.Forward },
    { source: OET.File, kind: RT.ConnectsTo, target: OET.IPAddress, direction: RD.Reverse },
    { source: OET.File, kind: RT.Contains, target: OET.File, direction: RD.Forward },
    { source: OET.File, kind: RT.Contains, target: OET.File, direction: RD.Reverse },
    { source: OET.File, kind: RT.Drops, target: OET.File, direction: RD.Forward },
    { source: OET.File, kind: RT.Drops, target: OET.File, direction: RD.Reverse },
    { source: OET.File, kind: RT.Uses, target: OET.EmailAddress, direction: RD.Forward },
    { source: OET.File, kind: RT.Uses, target: OET.EmailAddress, direction: RD.Reverse },
    { source: OET.IPAddress, kind: RT.Hosts, target: OET.File, direction: RD.Forward },
    { source: OET.IPAddress, kind: RT.Hosts, target: OET.File, direction: RD.Reverse },
    { source: OET.IPAddress, kind: RT.ResolvesTo, target: OET.DomainName, direction: RD.Forward },
    { source: OET.IPAddress, kind: RT.ResolvesTo, target: OET.DomainName, direction: RD.Reverse },
    { source: OET.IPAddress, kind: RT.Serves, target: OET.DomainName, direction: RD.Forward },
    { source: OET.IPAddress, kind: RT.Serves, target: OET.DomainName, direction: RD.Reverse },
    { source: OET.EmailAddress, kind: RT.Contains, target: OET.DomainName, direction: RD.Forward },
    { source: OET.EmailAddress, kind: RT.Contains, target: OET.DomainName, direction: RD.Reverse },
    { source: OET.EmailAddress, kind: RT.Contains, target: OET.IPAddress, direction: RD.Forward },
    { source: OET.EmailAddress, kind: RT.Contains, target: OET.IPAddress, direction: RD.Reverse }
];
