import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, ModuleRegistry } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { defaultGridOptions, MasterDetailModule } from '../../helpers';
import { DestroyService } from '../../services';

ModuleRegistry.registerModules([MasterDetailModule], false);

export interface IDetailsRendererParams extends ICellRendererParams {
    template: TemplateRef<unknown>;
    getDetailsHeight?(root: HTMLElement): number;
}

export interface IDetailsTemplateContext<DataType> {
    $implicit: DataType;
    params: IDetailsRendererParams;
    triggers: {
        updateHeight(): void;
    };
}

const DEFAULT_DETAILS_BODY_SELECTOR = '.ag-grid-details-viewport';

/**
 * @component AgGridDetailsComponent
 *
 * @param template TemplateRef of content
 * @param getDetailsHeight Function. Used for calculating height content
 *
 * @description Implementing wrapper for a row with grouping.
 * Rendered template provide params of parent row through params property
 * Used with next ag-grid options
 * ```
 * {
 *      masterDetails: true,
 *      detailCellRenderer: 'detailsRenderComponent',
 *      frameworkComponents: {
 *          detailsRenderComponent: AgGridDetailsComponent
 *      },
 *      detailCellRendererParams: {
 *          template: <template-ref>,
 *          getDetailsHeight: <function>
 *      }
 * }
 * ```
 */
@Component({
    selector: 'ag-grid-details',
    templateUrl: './ag-grid-details.component.html',
    styleUrls: ['./ag-grid-details.component.scss'],
    providers: [DestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgGridDetailsComponent implements ICellRendererAngularComp, AfterViewInit, OnDestroy {
    template: TemplateRef<unknown>;
    templateContext: IDetailsTemplateContext<unknown>;

    params: IDetailsRendererParams;

    private updateHeightTrigger$: Subject<void> = new Subject();

    constructor(private detailsRootElement: ElementRef<HTMLElement>, private destroyed$: DestroyService) {
        this.handleFirstDataRendered = this.handleFirstDataRendered.bind(this);
    }

    agInit(params): void {
        this.params = params as IDetailsRendererParams;

        this.template = this.params.template;
        this.templateContext = {
            $implicit: this.params.data,
            params: this.params,
            triggers: {
                updateHeight: () => this.updateHeightTrigger$.next()
            }
        };

        this.subscribeToGridEvents();
        this.subscribeToUpdateHeightTrigger();
    }

    ngAfterViewInit() {
        if (this.params.context.isFirstRendered) {
            this.updateDetailsRowHeight();
        }
    }

    ngOnDestroy() {
        this.unsubscribeToGridEvents();
    }

    refresh(): boolean {
        return false;
    }

    private subscribeToUpdateHeightTrigger(): void {
        this.updateHeightTrigger$
            .asObservable()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.updateDetailsRowHeight();
            });
    }

    private subscribeToGridEvents(): void {
        /* eslint-disable @typescript-eslint/unbound-method */
        this.params.api.addEventListener('firstDataRendered', this.handleFirstDataRendered);
        /* eslint-enable @typescript-eslint/unbound-method */
    }

    private unsubscribeToGridEvents(): void {
        /* eslint-disable @typescript-eslint/unbound-method */
        this.params.api.removeEventListener('firstDataRendered', this.handleFirstDataRendered);
        /* eslint-enable @typescript-eslint/unbound-method */
    }

    private updateDetailsRowHeight(): void {
        const gridApi = this.params.api;
        const onRowHeightChanged: () => void = gridApi.onRowHeightChanged.bind(gridApi);

        const checkRowSizeFunc = () => {
            const detailsHeight = this.getDetailsHeight();

            this.params.node.setRowHeight(detailsHeight);
            onRowHeightChanged();
        };

        setTimeout(checkRowSizeFunc);
    }

    private getDetailsHeight(): number {
        const root = this.detailsRootElement.nativeElement;
        const rootViewport = root.querySelector<HTMLElement>(DEFAULT_DETAILS_BODY_SELECTOR);

        if (this.params.getDetailsHeight) {
            return Math.max(this.params.getDetailsHeight(root), defaultGridOptions.rowHeight);
        }

        return Math.max(rootViewport.offsetHeight || defaultGridOptions.rowHeight, defaultGridOptions.rowHeight);
    }

    private handleFirstDataRendered() {
        this.params.context.isFirstRendered = true;
        this.updateDetailsRowHeight();
    }
}
