import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IDataSource } from '@pt-cybsi/api-interfaces';

import { DATA_SOURCES_FEATURE_KEY, dataSourcesAdapter, DataSourcesPartialState, State } from './data-sources.reducer';

export const getDataSourcesState = createFeatureSelector<State>(DATA_SOURCES_FEATURE_KEY);

const { selectAll, selectEntities } = dataSourcesAdapter.getSelectors();

export const getDataSourcesLoaded = createSelector(getDataSourcesState, (state: State) => state.loaded);

export const getDataSourcesCursor = createSelector(getDataSourcesState, (state: State) => state.currentCursor);

export const getAllDataSources = createSelector(getDataSourcesState, selectAll);

export const getDataSourcesEntities = createSelector(getDataSourcesState, selectEntities);

export const getSourceById = (sourceId: string) =>
    createSelector(getDataSourcesEntities, (entities: Dictionary<IDataSource>) => entities[sourceId]);

export const getSourcesByIds = (sourceIds: string[]) =>
    createSelector(getDataSourcesEntities, (entities: Dictionary<IDataSource>) =>
        sourceIds.map((sourceId: string) => entities[sourceId])
    );
