import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AsyncState } from '@pt-cybsi/shared';

import { LICENSE_STORE_FEATURE_KEY, TLicenseStoreState } from './license.reducer';

export const getLicenseState = createFeatureSelector<TLicenseStoreState>(LICENSE_STORE_FEATURE_KEY);

export const getLicenseLoadingState = createSelector(getLicenseState, (state) => state.state);

export const getInfo = createSelector(getLicenseState, (state) =>
    state.state !== AsyncState.Failure ? state.data : null
);
