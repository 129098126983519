import { Injectable, Provider } from '@angular/core';

import { EnrichmentType } from '@pt-cybsi/api-interfaces';
import { IUseCase } from '@pt-cybsi/shared';

import { EnrichmentRuleModel } from '../../models';
import {
    EnrichmentRuleFormRepository,
    EnrichmentRuleFormRepositoryProvider,
    IEnrichmentRuleFormRepository
} from '../../repositories';

export interface IChangeEnrichmentTypeUseCaseParams {
    ruleId: string;
    enrichmentType: EnrichmentType;
}

@Injectable()
export class ChangeEnrichmentTypeUseCase implements IUseCase<IChangeEnrichmentTypeUseCaseParams> {
    constructor(private enrichmentRuleFormRepository: IEnrichmentRuleFormRepository) {}

    execute(params: IChangeEnrichmentTypeUseCaseParams): void {
        const { ruleId, enrichmentType } = params;

        const rule = this.enrichmentRuleFormRepository.getCurrentData(ruleId);

        rule.props.enrichment = enrichmentType;
        rule.props.sources = [];
        rule.props.entityTypes = [];
        rule.props.artifactTypes = [];

        this.updateAvailableValues(rule);
        this.updateFieldsVisibility(rule);

        this.enrichmentRuleFormRepository.updateCurrentData(rule);
    }

    private updateAvailableValues(rule: EnrichmentRuleModel): void {
        this.enrichmentRuleFormRepository.updateAvailableArtifactTypes(rule.uid, rule.getAvailableArtifactTypes());

        this.enrichmentRuleFormRepository.updateAvailableEntityTypes(
            rule.uid,
            rule.getAvailableObservableEntityTypes()
        );
    }

    private updateFieldsVisibility(rule: EnrichmentRuleModel): void {
        const currentEnrichmentType = rule.props.enrichment;

        switch (currentEnrichmentType) {
            case EnrichmentType.ArtifactAnalysis: {
                this.enrichmentRuleFormRepository.showControls(rule.uid, ['sources', 'artifactTypes']);
                this.enrichmentRuleFormRepository.hideControls(rule.uid, ['entityTypes']);

                break;
            }

            case EnrichmentType.ExternalDBLookup: {
                this.enrichmentRuleFormRepository.showControls(rule.uid, ['sources', 'entityTypes']);
                this.enrichmentRuleFormRepository.hideControls(rule.uid, ['artifactTypes']);

                break;
            }
        }
    }
}

export const ChangeEnrichmentTypeUseCaseProvider: Provider = [
    EnrichmentRuleFormRepositoryProvider,
    {
        provide: ChangeEnrichmentTypeUseCase,
        useFactory: (enrichmentRuleFormRepository: EnrichmentRuleFormRepository) =>
            new ChangeEnrichmentTypeUseCase(enrichmentRuleFormRepository),
        deps: [EnrichmentRuleFormRepository]
    }
];
