<div *ngIf="isVerdictAttributesSectionVisible" class="layout-margin-bottom-l">
    <button *ngFor="let attribute of data.verdictAttributes | filter : hasAttributeValues"
        class="clear-button layout-margin-bottom-s layout-margin-right-l"
        [class.pointer]="isInteractiveVerdict(attribute)"
        [disabled]="!isInteractiveVerdict(attribute)"
        (click)="handleShowValuableFacts(attribute.attributeName, attribute.data[0])">
        <entity-attribute-badge
            [attributeName]="attribute.attributeName"
            [attributeValue]="attribute.data[0].value"
            [confidence]="attribute.data[0].confidence">
        </entity-attribute-badge>
    </button>
</div>

<div *ngIf="isKeysSectionVisible">
    <property-row [title]="keysSectionTitle">
        <ng-container property-row-content>
            <file-keys *ngIf="isFile" [keys]="data.keys"></file-keys>
            <identity-keys *ngIf="isIdentity" [keys]="data.keys"></identity-keys>
        </ng-container>

        <div property-row-actions>
            <ng-container *ngIf="isUpdateKeysButtonVisible">
                <ng-container *ngTemplateOutlet="updateKeysButtonTemplate"></ng-container>
            </ng-container>
        </div>
    </property-row>

    <ng-template #updateKeysButtonTemplate>
        <button class="mc-link clear-button"
                mc-link
                pseudo
                (click)="updateKeys.emit()">
            {{ 'common.Common.Pseudo.Button.Add' | transloco }}
        </button>
    </ng-template>
</div>

<property-row *ngIf="isFullUrlSectionVisible" [title]="'entities.ObservableEntities.Url.Text.Keys' | transloco">
    <copied-row property-row-content
        [copiedValue]="data.fullUrl"
        [copiedMessage]="'entities.ObservableEntities.Url.Text.KeysCopiedMessage' | transloco">
        <span class="pt-text-overflow" pt-title>{{ data.fullUrl }}</span>
    </copied-row>
</property-row>

<div *ngIf="isAttributesSectionVisible" [class.layout-margin-bottom-xxl]="isLabelsSectionVisible">
    <ng-container *ngFor="let attribute of data.attributes">
        <property-row [title]="attribute.attributeName | translateAttributeName : data.type">
            <ng-container property-row-content>
                <ng-container *ngIf="isCollectionAttribute(attribute); then collectionAttributeTemplate else singleValueAttributeTemplate"></ng-container>

                <ng-template #collectionAttributeTemplate>
                    <limited-collection
                        *ngIf="attribute.data.length > 0; else emptyAttributeTemplate"
                        [collection]="attribute.data"
                        [collectionTitle]= "getAttributeCollectionTitle(attribute)"
                        [itemTemplate]="attributeTemplate">
                    </limited-collection>
                </ng-template>

                <ng-template #singleValueAttributeTemplate>
                    <ng-container *ngIf="attribute.data.length > 0; else emptyAttributeTemplate">
                        <ng-container *ngTemplateOutlet="attributeTemplate; context: { item: attribute.data[0] }"></ng-container>
                    </ng-container>
                </ng-template>

                <ng-template #attributeTemplate let-attributeData="item">
                    <content-with-confidence
                        [format]="contentWithConfidenceFormat"
                        [confidence]="attributeData.confidence"
                        (confidenceClick)="handleShowValuableFacts(attribute.attributeName, attributeData)">
                        <entity-attribute-value
                            [attributeName]="attribute.attributeName"
                            [attributeValue]="attributeData.value">
                        </entity-attribute-value>
                    </content-with-confidence>
                </ng-template>
            </ng-container>
        </property-row>

        <property-row *ngIf="isLocationSectionVisible(attribute)" [title]="'entities.ObservableEntities.IpAddress.Text.Location' | transloco">
            <ng-container property-row-content>
                <div *ngIf="hasLocationData; else emptyAttributeTemplate" class="layout-row">
                    <flag-icon
                        *ngIf="data.location.code"
                        class="layout-margin-right-xxs"
                        [countryCode]="data.location.code">
                    </flag-icon>
                    <span *ngIf="data.location.name">{{ data.location.name }}</span>
                </div>
            </ng-container>
        </property-row>

        <ng-template #emptyAttributeTemplate>
            <span class="text-less-contrast">—</span>
        </ng-template>
    </ng-container>
</div>

<labels-collection
    *ngIf="isLabelsSectionVisible"
    [collection]="data.labels"
    [itemTemplate]="labelTemplate"
    [collectionTitle]="'common.Common.Pseudo.CollectionTitle.Labels' | transloco : { count: data.labels.length }"
    [editable]="isEditableLabels"
    (edit)="updateLabels.emit()"
></labels-collection>

<ng-template #labelTemplate let-value="item">
    <a *ngIf="isInteractiveFormat"
       class="mc-link layout-row"
       [routerLink]="searchByLabelLink"
       [queryParams]="getSearchByLabelLinkQueryParams(value)">
        <labels-collection-item [value]="value"></labels-collection-item>
    </a>

    <labels-collection-item *ngIf="!isInteractiveFormat" [value]="value"></labels-collection-item>
</ng-template>
