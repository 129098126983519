import { Injectable, Provider } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IServerError } from '@pt-cybsi/api-interfaces';
import { FormStateValue, IFormRepository, IObservableUseCase } from '@pt-cybsi/shared';

import { SourceTypeModel } from '../../models';
import { SourceTypeFormRepository, SourceTypeFormRepositoryProvider } from '../../repositories';

export interface IRegisterSourceTypeParams {
    id: string;
}

export type TRegisteredSourceTypeId = string;

@Injectable()
export class RegisterSourceTypeUseCase
    implements IObservableUseCase<IRegisterSourceTypeParams, TRegisteredSourceTypeId>
{
    constructor(private repository: IFormRepository<SourceTypeModel>) {}

    execute(params: IRegisterSourceTypeParams): Observable<TRegisteredSourceTypeId> {
        const { id } = params;

        const sourceType = this.repository.getCurrentData(id);
        const currentFormState = this.repository.getState(id);

        if (currentFormState.value === FormStateValue.Saved) {
            return of(sourceType.props.serverId);
        }

        this.repository.resetSavingError(id);

        this.switchState(id, FormStateValue.Saving);

        return this.repository.save(sourceType).pipe(
            tap((serverId: string) => {
                this.switchState(id, FormStateValue.Saved);

                this.updateSourceType(id, serverId);

                this.repository.disable(id);
            }),
            catchError((error: unknown) => {
                this.switchState(id, FormStateValue.SavingFailure);

                this.repository.updateSavingError(id, error as IServerError);

                return throwError(error);
            })
        );
    }

    private updateSourceType(id: string, serverId: string): void {
        const sourceType = this.repository.getCurrentData(id);

        sourceType.props.serverId = serverId;

        this.repository.updateCurrentData(sourceType);
    }

    private switchState(id: string, state: FormStateValue): void {
        const formState = this.repository.getState(id);

        const nextFormState = formState.to(state);

        this.repository.updateState(id, nextFormState);
    }
}

export const RegisterSourceTypeUseCaseProvider: Provider = [
    SourceTypeFormRepositoryProvider,
    {
        provide: RegisterSourceTypeUseCase,
        useFactory: (repository: SourceTypeFormRepository) => new RegisterSourceTypeUseCase(repository),
        deps: [SourceTypeFormRepository]
    }
];
