import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { MC_SIDEPANEL_DATA } from '@ptsecurity/mosaic/sidepanel';

import { IEnrichmentRule } from '@pt-cybsi/api-interfaces';
import {
    IEnrichmentRuleMeta,
    EnrichmentRulesNavigationService,
    EnrichmentRulesPermissionsService
} from '@pt-cybsi/domain-core/enrichment';
import { SourcesNavigationService } from '@pt-cybsi/domain-core/sources';

export interface IEnrichmentRuleSidepanelData {
    rule: IEnrichmentRule;
}

@Component({
    selector: 'enrichment-rule-sidepanel',
    templateUrl: './enrichment-rule-sidepanel.component.html',
    styleUrls: ['./enrichment-rule-sidepanel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnrichmentRuleSidepanelComponent implements OnInit {
    @Input() rule: IEnrichmentRule;

    get metaInfo(): IEnrichmentRuleMeta {
        return this.data.rule
            ? {
                  type: this.data.rule.enrichment,
                  triggerTypes: this.data.rule.triggers,
                  artifactTypes: this.data.rule.artifactTypes,
                  entityTypes: this.data.rule.entityTypes,
                  throttlingInterval: this.data.rule.throttlingInterval,
                  dataSources: this.data.rule.dataSources.map((dataSource) => dataSource.uuid),
                  triggerDataSources: this.data.rule.triggerDataSources.map((dataSource) => dataSource.uuid)
              }
            : null;
    }

    editRulePermissions = EnrichmentRulesPermissionsService.editRulePermissions;

    enrichmentRuleLink: string;
    editEnrichmentRuleLink: string;

    constructor(
        @Inject(MC_SIDEPANEL_DATA) public data: IEnrichmentRuleSidepanelData,
        private sourcesNavigationService: SourcesNavigationService,
        private enrichmentRulesNavigationService: EnrichmentRulesNavigationService
    ) {}

    ngOnInit(): void {
        this.enrichmentRuleLink = this.enrichmentRulesNavigationService.getPathOfViewRoute(this.data.rule.uuid);
        this.editEnrichmentRuleLink = this.enrichmentRulesNavigationService.getPathOfEditRoute(this.data.rule.uuid);
    }

    getSourceLink(id: string): string {
        return this.sourcesNavigationService.getPathOfSourceViewRoute(id);
    }
}
