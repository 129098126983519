import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    IApiKey,
    ICreateApiKeyData,
    ICreateApiKeyResponse,
    IEditableResource,
    IPaginationParams,
    IPaginationResponse,
    IUpdateApiKeyData
} from '@pt-cybsi/api-interfaces';

import { BaseApiService } from './base-api.service';

@Injectable()
export class ApiKeysApiService extends BaseApiService {
    readonly urls = {
        users: 'users',
        currentUserApiKeys: 'users/me/api-keys',
        apiKeys: 'api-keys'
    };

    getUserApiKeys(userId: string, params?: IPaginationParams): Observable<IPaginationResponse<IApiKey[]>> {
        return this.getPaginatedCollection<IApiKey[]>(`${this.urls.users}/${userId}/${this.urls.apiKeys}`, { params });
    }

    getCurrentUserApiKeys(params?: IPaginationParams): Observable<IPaginationResponse<IApiKey[]>> {
        return this.getPaginatedCollection<IApiKey[]>(this.urls.currentUserApiKeys, { params });
    }

    getEditableApiKey(apiKeyId: string): Observable<IEditableResource<IApiKey>> {
        return this.getEditableResource(`${this.urls.apiKeys}/${apiKeyId}`);
    }

    getCurrentUserEditableApiKey(apiKeyId: string): Observable<IEditableResource<IApiKey>> {
        return this.getEditableResource(`${this.urls.currentUserApiKeys}/${apiKeyId}`);
    }

    createApiKey(userId: string, apiKeyData: ICreateApiKeyData): Observable<ICreateApiKeyResponse> {
        return this.post(`${this.urls.users}/${userId}/${this.urls.apiKeys}`, apiKeyData);
    }

    createCurrentUserApiKey(apiKeyData: ICreateApiKeyData): Observable<ICreateApiKeyResponse> {
        return this.post(this.urls.currentUserApiKeys, apiKeyData);
    }

    updateApiKey(apiKeyId: string, eTag: string, updateData: IUpdateApiKeyData): Observable<void> {
        const url = `${this.urls.apiKeys}/${apiKeyId}`;

        return this.updateResource(url, eTag, updateData);
    }

    updateCurrentUserApiKey(apiKeyId: string, eTag: string, updateData: IUpdateApiKeyData): Observable<void> {
        const url = `${this.urls.currentUserApiKeys}/${apiKeyId}`;

        return this.updateResource(url, eTag, updateData);
    }
}
