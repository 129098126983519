import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { McButtonModule } from '@ptsecurity/mosaic/button';
import { McCheckboxModule } from '@ptsecurity/mosaic/checkbox';
import { McFormFieldModule } from '@ptsecurity/mosaic/form-field';
import { McInputModule } from '@ptsecurity/mosaic/input';
import { McModalModule } from '@ptsecurity/mosaic/modal';

import { ApiModule } from '@pt-cybsi/api';
import { SharedModule } from '@pt-cybsi/shared';
import { StoreAccountModule } from '@pt-cybsi/store/account';

import { SignInFormComponent, SessionExpiredModalComponent } from './components';
import { SignOutModalComponent } from './containers';
import {
    HasPermissionsDirective,
    PreventSessionExpiredRedirectDirective,
    ShareLevelSelectWithUserDirective
} from './directives';
import { TokenInterceptor, UnauthorizedInterceptor } from './interceptors';
import { AccountNavigationService, RootPermissionsService, SessionExpiredNotificationService } from './services';

const modules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ApiModule,
    McButtonModule,
    McCheckboxModule,
    McFormFieldModule,
    McInputModule,
    McModalModule,
    StoreAccountModule
];
// prettier-ignore
const components = [
    SignInFormComponent,
    SessionExpiredModalComponent,
    SignOutModalComponent
];
// prettier-ignore
const directives = [
    HasPermissionsDirective,
    PreventSessionExpiredRedirectDirective,
    ShareLevelSelectWithUserDirective
];
// prettier-ignore
const services = [
    AccountNavigationService,
    RootPermissionsService,
    SessionExpiredNotificationService
];

const interceptors = [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true }
];

@NgModule({
    imports: [...modules],
    declarations: [...components, ...directives],
    providers: [...services, ...interceptors],
    exports: [...components, ...directives]
})
export class DomainCoreAccountModule {}
