import { AfterViewInit, Directive } from '@angular/core';
import { McFormField } from '@ptsecurity/mosaic/form-field';

@Directive({
    selector: '[autoFocusFirstField]'
})
export class AutofocusFirstFieldDirective implements AfterViewInit {
    get formFields(): McFormField[] {
        return this._formFields;
    }

    private _formFields: McFormField[] = [];

    ngAfterViewInit(): void {
        setTimeout(() => {
            // sorting registered fields by position on a page
            const fields = this.formFields.sort((a, b) =>
                (a.elementRef.nativeElement as HTMLElement).getBoundingClientRect().top >
                (b.elementRef.nativeElement as HTMLElement).getBoundingClientRect().top
                    ? 1
                    : -1
            );

            const firstActiveField = fields.find((field) => !field.disabled);

            firstActiveField?.control.focus();
        });
    }

    addFormField(formField: McFormField): void {
        this._formFields.push(formField);
    }
}
