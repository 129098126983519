import { Component, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
    selector: 'notifications-portal',
    templateUrl: './notifications-portal.component.html',
    styleUrls: ['./notifications-portal.component.scss']
})
export class NotificationsPortalComponent {
    @ViewChild('container', { read: ViewContainerRef })
    container: ViewContainerRef;
}
