import {
    Component,
    Input,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    AfterViewInit,
    ChangeDetectionStrategy,
    AfterViewChecked
} from '@angular/core';
import { Options, chart, Chart } from 'highcharts';

@Component({
    selector: 'highchart',
    template: `<div class="highchart" #chart></div>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HighchartComponent implements AfterViewInit, AfterViewChecked {
    @Input() config: Options;

    @ViewChild('chart', { static: false }) chartContainer: ElementRef;

    private chart: Chart;

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        if (this.config && this.chartContainer) {
            this.chart = chart(this.chartContainer.nativeElement as HTMLElement, this.config);

            this.cdr.detectChanges();
        }
    }

    ngAfterViewChecked() {
        if (this.config && this.chartContainer) {
            this.chart.update({ series: this.config.series }, true, true, true);

            this.cdr.detectChanges();
        }
    }
}
