<input
    type="text"
    #mcInput
    mcInput
    [mcAutocomplete]="autocompleteTypes"
    [ngModel]="inputValue"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [required]="required"
    (focus)="handleInputFocus()"
    (blur)="handleInputBlur()"
    (ngModelChange)="handleInputChange($event)"/>

<mc-autocomplete
    #autocompleteTypes="mcAutocomplete"
    class="source-type-autocomplete-field__options"
    [displayWith]="autocompleteDisplayFn"
    [openOnFocus]="true"
    (optionSelected)="handleSelectOption($event)"
    (closed)="handleCloseAutocomplete()"
    (opened)="handleOpenAutocomplete()">
    <mc-option
        *ngIf="isLoading$ | async"
        [disabled]="true">
        <mc-progress-spinner
            [mode]="'indeterminate'"
            class="source-type-autocomplete-field__spinner">
        </mc-progress-spinner>
    </mc-option>
    <ng-container *ngIf="isLoaded$ | async">
        <mc-option *ngFor="let option of autocompleteOptions" [value]="option">
            {{ getAutocompleteOptionText(option) }}
        </mc-option>
    </ng-container>
</mc-autocomplete>
