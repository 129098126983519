import { ArtifactType } from '../artifacts';
import { IApiItemPreview, ObservableEntityType } from '../common';
import { IDataSource } from '../data-sources';

import { EnrichmentType, EnrichmentTriggerType } from './enrichment';

export interface IEnrichmentRule extends IApiItemPreview {
    enrichment: EnrichmentType;
    name: string;
    isDisabled: boolean;
    isBuiltin: boolean;
    triggers: EnrichmentTriggerType[] | null;
    artifactTypes: ArtifactType[];
    entityTypes: ObservableEntityType[] | null;
    dataSources: IDataSource[] | null;
    triggerDataSources: IDataSource[] | null;
    throttlingInterval: number | null;
}
