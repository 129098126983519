import isNil from 'lodash/isNil';

import {
    AttributeObservableEntity,
    IAttributeValueForecast,
    IdentityClass,
    IDictionaryItemBrief,
    IFactOfAttribute,
    NodeRole,
    RegionalInternetRegistry,
    RelatedThreatCategory,
    ShareLevel,
    TAttributeValueType,
    TFactOfAffectedCountries,
    TFactOfCampaigns,
    TFactOfDomainNameIsDelegated,
    TFactOfDomainNameStatuses,
    TFactOfEmailAddressDisplayNames,
    TFactOfExploitedVulnerabilities,
    TFactOfFileMalwareNames,
    TFactOfFileNames,
    TFactOfFileSize,
    TFactOfIdentityClass,
    TFactOfIdentityNames,
    TFactOfIdentitySectors,
    TFactOfIpAddressASN,
    TFactOfIpAddressRegionalInternetRegistry,
    TFactOfIpAddressStatuses,
    TFactOfIsDGA,
    TFactOfIsIoC,
    TFactOfIsTrusted,
    TFactOfMalwareClasses,
    TFactOfMalwareFamilies,
    TFactOfNodeRole,
    TFactOfRelatedMalwareFamilies,
    TFactOfRelatedThreatCategory,
    TFactOfTargetedSectors,
    TFactOfThreatActors,
    TFactOfThreatCategory,
    ThreatCategory
} from '@pt-cybsi/api-interfaces';
import { createDataSourceMock } from '@pt-cybsi/domain-core/sources';

export const buildFactOfAttribute = <AttributeName extends AttributeObservableEntity, ValueType>(
    attributeName: AttributeName,
    values: ValueType[],
    confidences: number[] = []
): IFactOfAttribute<AttributeName, ValueType> => ({
    attributeName,
    hasConflicts: false,
    value: values.filter((value) => !isNil(value))[0],
    values: values
        .filter((value) => !isNil(value))
        .map((value, index) => ({ value, confidence: confidences[index] || 1 / (index + 1) }))
});

export const buildAttributeForecast = <AttributeName extends AttributeObservableEntity>(
    values: TAttributeValueType<AttributeName>[]
): IAttributeValueForecast<AttributeName> => ({
    hasConflicts: false,
    values: values
        .filter((value) => !isNil(value))
        .map((value, index) => ({
            value,
            confidence: 1,
            valuableFacts: [
                {
                    dataSource: createDataSourceMock(),
                    shareLevel: ShareLevel.White,
                    seenAt: new Date().toISOString(),
                    confidence: 1 / (index + 1),
                    finalConfidence: 1 / (index + 1),
                    value
                }
            ]
        }))
});

export const buildFactOfThreatCategory = (value: ThreatCategory, confidence: number = 1): TFactOfThreatCategory =>
    buildFactOfAttribute(AttributeObservableEntity.ThreatCategory, [value], [confidence]);

export const buildFactOfRelatedThreatCategory = (
    value: RelatedThreatCategory,
    confidence: number = 1
): TFactOfRelatedThreatCategory =>
    buildFactOfAttribute(AttributeObservableEntity.RelatedThreatCategory, [value], [confidence]);

export const buildFactOfIsIoC = (value: boolean, confidence: number = 1): TFactOfIsIoC =>
    buildFactOfAttribute(AttributeObservableEntity.IsIoC, [value], [confidence]);

export const buildFactOfIsTrusted = (value: boolean, confidence: number = 1): TFactOfIsTrusted =>
    buildFactOfAttribute(AttributeObservableEntity.IsTrusted, [value], [confidence]);

export const buildFactOfIsDGA = (value: boolean, confidence: number = 1): TFactOfIsDGA =>
    buildFactOfAttribute(AttributeObservableEntity.IsDGA, [value], [confidence]);

export const buildFactOfIsDelegated = (value: boolean, confidence: number = 1): TFactOfDomainNameIsDelegated =>
    buildFactOfAttribute(AttributeObservableEntity.IsDelegated, [value], [confidence]);

export const buildFactOfClass = (value: IdentityClass, confidence: number = 1): TFactOfIdentityClass =>
    buildFactOfAttribute(AttributeObservableEntity.Class, [value], [confidence]);

export const buildFactOfASN = (value: number, confidence: number = 1): TFactOfIpAddressASN =>
    buildFactOfAttribute(AttributeObservableEntity.ASN, [value], [confidence]);

export const buildFactOfRegionalInternetRegistry = (
    value: RegionalInternetRegistry,
    confidence: number = 1
): TFactOfIpAddressRegionalInternetRegistry =>
    buildFactOfAttribute(AttributeObservableEntity.RegionalInternetRegistry, [value], [confidence]);

export const buildFactOfSize = (value: number, confidence: number = 1): TFactOfFileSize =>
    buildFactOfAttribute(AttributeObservableEntity.Size, [value], [confidence]);

export const buildFactOfNames = (
    values: string[],
    confidences: number[] = []
): TFactOfFileNames | TFactOfIdentityNames =>
    buildFactOfAttribute(AttributeObservableEntity.Names, values, confidences);

export const buildFactOfDisplayNames = (
    values: string[],
    confidences: number[] = []
): TFactOfEmailAddressDisplayNames => buildFactOfAttribute(AttributeObservableEntity.DisplayNames, values, confidences);

export const buildFactOfMalwareNames = (values: string[], confidences: number[] = []): TFactOfFileMalwareNames =>
    buildFactOfAttribute(AttributeObservableEntity.MalwareNames, values, confidences);

export const buildFactOfSectors = (
    values: IDictionaryItemBrief[],
    confidences: number[] = []
): TFactOfIdentitySectors => buildFactOfAttribute(AttributeObservableEntity.Sectors, values, confidences);

export const buildFactOfNodeRoles = (values: NodeRole[], confidences: number[] = []): TFactOfNodeRole =>
    buildFactOfAttribute(AttributeObservableEntity.NodeRoles, values, confidences);

export const buildFactOfStatuses = (
    values: IDictionaryItemBrief[],
    confidences: number[] = []
): TFactOfIpAddressStatuses | TFactOfDomainNameStatuses =>
    buildFactOfAttribute(AttributeObservableEntity.Statuses, values, confidences);

export const buildFactOfMalwareClasses = (
    values: IDictionaryItemBrief[],
    confidences: number[] = []
): TFactOfMalwareClasses => buildFactOfAttribute(AttributeObservableEntity.MalwareClasses, values, confidences);

export const buildFactOfMalwareFamilies = (
    values: IDictionaryItemBrief[],
    confidences: number[] = []
): TFactOfMalwareFamilies => buildFactOfAttribute(AttributeObservableEntity.MalwareFamilies, values, confidences);

export const buildFactOfRelatedMalwareFamilies = (
    values: IDictionaryItemBrief[],
    confidences: number[] = []
): TFactOfRelatedMalwareFamilies =>
    buildFactOfAttribute(AttributeObservableEntity.RelatedMalwareFamilies, values, confidences);

export const buildFactOfCampaigns = (values: IDictionaryItemBrief[], confidences: number[] = []): TFactOfCampaigns =>
    buildFactOfAttribute(AttributeObservableEntity.Campaigns, values, confidences);

export const buildFactOfThreatActors = (
    values: IDictionaryItemBrief[],
    confidences: number[] = []
): TFactOfThreatActors => buildFactOfAttribute(AttributeObservableEntity.ThreatActors, values, confidences);

export const buildFactOfAffectedCountries = (
    values: IDictionaryItemBrief[],
    confidences: number[] = []
): TFactOfAffectedCountries => buildFactOfAttribute(AttributeObservableEntity.AffectedCountries, values, confidences);

export const buildFactOfExploitedVulnerabilities = (
    values: IDictionaryItemBrief[],
    confidences: number[] = []
): TFactOfExploitedVulnerabilities =>
    buildFactOfAttribute(AttributeObservableEntity.ExploitedVulnerabilities, values, confidences);

export const buildFactOfTargetedSectors = (
    values: IDictionaryItemBrief[],
    confidences: number[] = []
): TFactOfTargetedSectors => buildFactOfAttribute(AttributeObservableEntity.TargetedSectors, values, confidences);
