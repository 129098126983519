import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { TConfidence } from '@pt-cybsi/api-interfaces';
import {
    AsyncState,
    ConfidenceFormat,
    isAsyncStateFailure,
    isAsyncStateLoading,
    isAsyncStateSuccess
} from '@pt-cybsi/shared';

import { IForecastView, TValuableFactView } from '../../types';
import { ValuableFactsTableType } from '../valuable-facts-table/valuable-facts-table.component';

export enum ForecastType {
    Attribute = ValuableFactsTableType.Attribute,
    Relation = ValuableFactsTableType.Relation
}

@Component({
    selector: 'forecast-modal',
    templateUrl: './forecast-modal.component.html',
    styleUrls: ['./forecast-modal.component.scss']
})
export class ForecastModalComponent implements OnInit {
    @Input() type: ForecastType;
    @Input() loadingState$: Observable<AsyncState>;
    @Input() forecast$: Observable<IForecastView>;
    @Input() defaultConfidence$: Observable<TConfidence>;

    @Input() reload = new EventEmitter<void>();

    ForecastType = ForecastType;
    ConfidenceFormat = ConfidenceFormat;

    isLoaderVisible$: Observable<boolean>;
    isErrorVisible$: Observable<boolean>;
    isTableVisible$: Observable<boolean>;
    confidence$: Observable<TConfidence>;
    valuableFacts$: Observable<TValuableFactView[]>;

    ngOnInit(): void {
        this.isLoaderVisible$ = this.loadingState$.pipe(map(isAsyncStateLoading));
        this.isErrorVisible$ = this.loadingState$.pipe(map(isAsyncStateFailure));
        this.isTableVisible$ = this.loadingState$.pipe(map(isAsyncStateSuccess));

        this.confidence$ = merge(
            this.defaultConfidence$.pipe(take(1)),
            this.forecast$.pipe(
                map((forecast) => forecast?.confidence),
                filter((confidence) => !!confidence)
            )
        );

        this.valuableFacts$ = this.forecast$.pipe(map((forecast) => forecast.facts));
    }
}
