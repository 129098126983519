import { Injectable, Provider } from '@angular/core';

import { FormsStore } from '@pt-cybsi/shared';

import { TSourceFormData } from '../../mappers';

import { SourceFormBuilder } from './source-form.builder';

/**
 * @store SourcesForms
 *
 * @description
 * Reactive store for sources forms (creation and edit).
 */
@Injectable()
export class SourcesFormsStore extends FormsStore<TSourceFormData> {
    constructor(
        protected creationFormViewModelBuilder: SourceFormBuilder,
        protected editFormViewModelBuilder: SourceFormBuilder
    ) {
        super();
    }
}

export const SourcesFormsStoreProvider: Provider = [
    SourceFormBuilder,
    {
        provide: SourcesFormsStore,
        useFactory: (builder: SourceFormBuilder) => new SourcesFormsStore(builder, builder),
        deps: [SourceFormBuilder]
    }
];
