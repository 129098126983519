import { ObservableEntityType, AttributeObservableEntity } from '@pt-cybsi/api-interfaces';

import { EntityFullInfoModel, TEntityFullInfoModel } from '../models';
import {
    TDomainNameMetadata,
    TEmailAddressMetadata,
    TFileMetadata,
    TIdentityMetadata,
    TIpAddressMetadata,
    TPhoneNumberMetadata,
    TUrlMetadata,
    TEntityMetadata,
    IEntityMetadataAttribute
} from '../types';

export class EntityMetadataMapper {
    static fromModel(entity: TEntityFullInfoModel): TEntityMetadata {
        if (entity.is(ObservableEntityType.File)) {
            return EntityMetadataMapper.fromFileModel(entity);
        } else if (entity.is(ObservableEntityType.Identity)) {
            return EntityMetadataMapper.fromIdentityModel(entity);
        } else if (entity.is(ObservableEntityType.IPAddress)) {
            return EntityMetadataMapper.fromIpAddressModel(entity);
        } else if (entity.is(ObservableEntityType.DomainName)) {
            return EntityMetadataMapper.fromDomainNameModel(entity);
        } else if (entity.is(ObservableEntityType.URL)) {
            return EntityMetadataMapper.fromUrlModel(entity);
        } else if (entity.is(ObservableEntityType.EmailAddress)) {
            return EntityMetadataMapper.fromEmailAddressModel(entity);
        } else if (entity.is(ObservableEntityType.PhoneNumber)) {
            return EntityMetadataMapper.fromPhoneNumberModel(entity);
        } else {
            return null;
        }
    }

    static fromFileModel(entity: EntityFullInfoModel<ObservableEntityType.File>): TFileMetadata {
        const keys = entity.getKeys();

        const verdictAttributes: IEntityMetadataAttribute[] = EntityMetadataMapper.getAttributes(entity, [
            AttributeObservableEntity.ThreatCategory,
            AttributeObservableEntity.IsIoC,
            AttributeObservableEntity.IsTrusted
        ]);

        const attributes: IEntityMetadataAttribute[] = EntityMetadataMapper.getAttributes(entity, [
            AttributeObservableEntity.MalwareClasses,
            AttributeObservableEntity.MalwareFamilies,
            AttributeObservableEntity.MalwareNames,
            AttributeObservableEntity.Campaigns,
            AttributeObservableEntity.ThreatActors,
            AttributeObservableEntity.AffectedCountries,
            AttributeObservableEntity.ExploitedVulnerabilities,
            AttributeObservableEntity.TargetedSectors,
            AttributeObservableEntity.Size,
            AttributeObservableEntity.Names
        ]);

        const labels = entity.getLabels();

        return {
            id: entity.getId(),
            type: entity.getType(),
            keys,
            verdictAttributes,
            attributes,
            labels
        };
    }

    static fromIdentityModel(entity: EntityFullInfoModel<ObservableEntityType.Identity>): TIdentityMetadata {
        const keys = entity.getKeys();

        const verdictAttributes: IEntityMetadataAttribute[] = [];

        const attributes: IEntityMetadataAttribute[] = EntityMetadataMapper.getAttributes(entity, [
            AttributeObservableEntity.Class,
            AttributeObservableEntity.Names,
            AttributeObservableEntity.Sectors
        ]);

        const labels = entity.getLabels();

        return {
            id: entity.getId(),
            type: entity.getType(),
            keys,
            verdictAttributes,
            attributes,
            labels
        };
    }

    static fromIpAddressModel(entity: EntityFullInfoModel<ObservableEntityType.IPAddress>): TIpAddressMetadata {
        const verdictAttributes: IEntityMetadataAttribute[] = EntityMetadataMapper.getAttributes(entity, [
            AttributeObservableEntity.RelatedThreatCategory,
            AttributeObservableEntity.IsIoC,
            AttributeObservableEntity.IsTrusted
        ]);

        const attributes: IEntityMetadataAttribute[] = EntityMetadataMapper.getAttributes(entity, [
            AttributeObservableEntity.ASN,
            AttributeObservableEntity.RegionalInternetRegistry,
            AttributeObservableEntity.Statuses,
            AttributeObservableEntity.NodeRoles,
            AttributeObservableEntity.RelatedMalwareFamilies,
            AttributeObservableEntity.Campaigns,
            AttributeObservableEntity.ThreatActors,
            AttributeObservableEntity.AffectedCountries,
            AttributeObservableEntity.ExploitedVulnerabilities,
            AttributeObservableEntity.TargetedSectors
        ]);

        const geoData = entity.getGeoDataOfIpAddress();

        const location =
            !!geoData?.country || !!geoData?.countryCode
                ? {
                      name: geoData?.country,
                      code: geoData?.countryCode
                  }
                : null;

        const labels = entity.getLabels();

        return {
            id: entity.getId(),
            type: entity.getType(),
            verdictAttributes,
            attributes,
            location,
            labels
        };
    }

    static fromDomainNameModel(entity: EntityFullInfoModel<ObservableEntityType.DomainName>): TDomainNameMetadata {
        const verdictAttributes: IEntityMetadataAttribute[] = EntityMetadataMapper.getAttributes(entity, [
            AttributeObservableEntity.RelatedThreatCategory,
            AttributeObservableEntity.IsIoC,
            AttributeObservableEntity.IsTrusted,
            AttributeObservableEntity.IsDGA,
            AttributeObservableEntity.IsDelegated
        ]);

        const attributes: IEntityMetadataAttribute[] = EntityMetadataMapper.getAttributes(entity, [
            AttributeObservableEntity.Statuses,
            AttributeObservableEntity.NodeRoles,
            AttributeObservableEntity.RelatedMalwareFamilies,
            AttributeObservableEntity.Campaigns,
            AttributeObservableEntity.ThreatActors,
            AttributeObservableEntity.AffectedCountries,
            AttributeObservableEntity.ExploitedVulnerabilities,
            AttributeObservableEntity.TargetedSectors
        ]);

        const labels = entity.getLabels();

        return {
            id: entity.getId(),
            type: entity.getType(),
            verdictAttributes,
            attributes,
            labels
        };
    }

    static fromUrlModel(entity: EntityFullInfoModel<ObservableEntityType.URL>): TUrlMetadata {
        const verdictAttributes: IEntityMetadataAttribute[] = EntityMetadataMapper.getAttributes(entity, [
            AttributeObservableEntity.RelatedThreatCategory,
            AttributeObservableEntity.IsIoC,
            AttributeObservableEntity.IsTrusted
        ]);

        const attributes: IEntityMetadataAttribute[] = EntityMetadataMapper.getAttributes(entity, [
            AttributeObservableEntity.RelatedMalwareFamilies,
            AttributeObservableEntity.Campaigns,
            AttributeObservableEntity.ThreatActors,
            AttributeObservableEntity.AffectedCountries,
            AttributeObservableEntity.ExploitedVulnerabilities,
            AttributeObservableEntity.TargetedSectors
        ]);

        const fullUrl = entity.getMainKeys()[0].value;

        const labels = entity.getLabels();

        return {
            id: entity.getId(),
            type: entity.getType(),
            verdictAttributes,
            attributes,
            fullUrl,
            labels
        };
    }

    static fromEmailAddressModel(
        entity: EntityFullInfoModel<ObservableEntityType.EmailAddress>
    ): TEmailAddressMetadata {
        const verdictAttributes: IEntityMetadataAttribute[] = EntityMetadataMapper.getAttributes(entity, [
            AttributeObservableEntity.RelatedThreatCategory,
            AttributeObservableEntity.IsIoC,
            AttributeObservableEntity.IsTrusted
        ]);

        const attributes: IEntityMetadataAttribute[] = EntityMetadataMapper.getAttributes(entity, [
            AttributeObservableEntity.DisplayNames,
            AttributeObservableEntity.RelatedMalwareFamilies,
            AttributeObservableEntity.Campaigns,
            AttributeObservableEntity.ThreatActors,
            AttributeObservableEntity.AffectedCountries,
            AttributeObservableEntity.ExploitedVulnerabilities,
            AttributeObservableEntity.TargetedSectors
        ]);

        const labels = entity.getLabels();

        return {
            id: entity.getId(),
            type: entity.getType(),
            verdictAttributes,
            attributes,
            labels
        };
    }

    static fromPhoneNumberModel(entity: EntityFullInfoModel<ObservableEntityType.PhoneNumber>): TPhoneNumberMetadata {
        const verdictAttributes: IEntityMetadataAttribute[] = EntityMetadataMapper.getAttributes(entity, [
            AttributeObservableEntity.IsIoC
        ]);

        const attributes: IEntityMetadataAttribute[] = [];

        const labels = entity.getLabels();

        return {
            id: entity.getId(),
            type: entity.getType(),
            verdictAttributes,
            attributes,
            labels
        };
    }

    private static getAttributes(
        entity: EntityFullInfoModel,
        attributes: AttributeObservableEntity[]
    ): IEntityMetadataAttribute[] {
        return attributes.reduce<IEntityMetadataAttribute[]>((result, attributeName) => {
            const data = EntityMetadataMapper.getAttributeData(entity, attributeName);

            return [...result, { attributeName, data }];
        }, []);
    }

    private static getAttributeData(
        entity: EntityFullInfoModel,
        attributeName: AttributeObservableEntity
    ): IEntityMetadataAttribute['data'] {
        switch (attributeName) {
            case AttributeObservableEntity.Size:
                return entity.getSizeValues();

            case AttributeObservableEntity.Names:
                return entity.getNamesValues();

            case AttributeObservableEntity.DisplayNames:
                return entity.getEmailDisplayNamesValues();

            case AttributeObservableEntity.Class:
                return entity.getIdentityClassValues();

            case AttributeObservableEntity.Sectors:
                return entity.getIdentitySectorsValues();

            case AttributeObservableEntity.NodeRoles:
                return entity.getNodeRoleValues();

            case AttributeObservableEntity.IsDGA:
                return entity.getIsDGAValues();

            case AttributeObservableEntity.IsIoC:
                return entity.getIsIoCValues();

            case AttributeObservableEntity.IsTrusted:
                return entity.getIsTrustedValues();

            case AttributeObservableEntity.ThreatCategory:
                return entity.getThreatCategoryValues();

            case AttributeObservableEntity.RelatedThreatCategory:
                return entity.getRelatedThreatCategoryValues();

            case AttributeObservableEntity.MalwareClasses:
                return entity.getMalwareClassesValues();

            case AttributeObservableEntity.MalwareFamilies:
                return entity.getMalwareFamiliesValues();

            case AttributeObservableEntity.RelatedMalwareFamilies:
                return entity.getRelatedMalwareFamiliesValues();

            case AttributeObservableEntity.RegionalInternetRegistry:
                return entity.getRegionalInternetRegistryValues();

            case AttributeObservableEntity.IsDelegated:
                return entity.getIsDelegatedValues();

            case AttributeObservableEntity.ASN:
                return entity.getASNValues();

            case AttributeObservableEntity.Statuses:
                return entity.getStatusesValues();

            case AttributeObservableEntity.MalwareNames:
                return entity.getMalwareNamesValues();

            case AttributeObservableEntity.Campaigns:
                return entity.getCampaignsValues();

            case AttributeObservableEntity.ThreatActors:
                return entity.getThreatActorsValues();

            case AttributeObservableEntity.AffectedCountries:
                return entity.getAffectedCountriesValues();

            case AttributeObservableEntity.ExploitedVulnerabilities:
                return entity.getExploitedVulnerabilitiesValues();

            case AttributeObservableEntity.TargetedSectors:
                return entity.getTargetedSectorsValues();

            default:
                return [];
        }
    }
}
