import { Injectable, Provider } from '@angular/core';

import { ArtifactType, EnrichmentType, ObservableEntityType } from '@pt-cybsi/api-interfaces';
import { FormsStore } from '@pt-cybsi/shared';

import { TEnrichmentRuleFormData } from '../../mappers';
import { IEnrichmentRulesFormsStore } from '../../repositories';

import { EnrichmentRuleFormBuilder } from './enrichment-rule-form.builder';
import { EnrichmentRuleFormViewModel } from './enrichment-rule-form.view-model';

/**
 * @store EnrichmentRulesForms
 *
 * @description
 * Reactive store for enrichment rules forms.
 */
@Injectable()
export class EnrichmentRulesFormsStore
    extends FormsStore<TEnrichmentRuleFormData>
    implements IEnrichmentRulesFormsStore
{
    constructor(
        protected creationFormViewModelBuilder: EnrichmentRuleFormBuilder,
        protected editFormViewModelBuilder: EnrichmentRuleFormBuilder
    ) {
        super();
    }

    updateAvailableEnrichmentTypes(id: string, types: EnrichmentType[]): void {
        const currentForm = this.forms.get(id) as EnrichmentRuleFormViewModel;

        currentForm.updateAvailableEnrichmentTypes(types);

        this.notify();
    }

    updateAvailableArtifactTypes(id: string, types: ArtifactType[]): void {
        const currentForm = this.forms.get(id) as EnrichmentRuleFormViewModel;

        currentForm.updateAvailableArtifactTypes(types);

        this.notify();
    }

    updateAvailableEntityTypes(id: string, types: ObservableEntityType[]): void {
        const currentForm = this.forms.get(id) as EnrichmentRuleFormViewModel;

        currentForm.updateAvailableEntityTypes(types);

        this.notify();
    }
}

export const EnrichmentRulesFormsStoreProvider: Provider = [
    EnrichmentRuleFormBuilder,
    {
        provide: EnrichmentRulesFormsStore,
        useFactory: (builder: EnrichmentRuleFormBuilder) => new EnrichmentRulesFormsStore(builder, builder),
        deps: [EnrichmentRuleFormBuilder]
    }
];
