<span *ngIf="format === SourcePreviewFormat.Text"
      class="pt-text-overflow"
      pt-title>
    {{ sourceName$ | async }}
</span>

<a *ngIf="format === SourcePreviewFormat.Link"
   class="mc-link_overflowed"
   mc-link
   pt-title
   [routerLink]="sourceLink$ | async">
   {{ sourceName$ | async }}
</a>
