<ng-container *transloco="let tUsers; read: 'users'">
    <div class="mc-form__row">
        <label class="mc-form__label">
            {{ tUsers('Users.ApiKey.Text.Description')}}
        </label>

        <mc-form-field #description [autoFocusedField]="description">
            <input mcInput
                   autocomplete="off"
                   [formControl]="form.getControl('description')">
        </mc-form-field>
    </div>

    <div class="api-key-form__permissions" *ngIf="form.isVisibleControl('isInheritPermissions')">
        <div class="mc-form__legend">{{ tUsers('Users.ApiKey.Text.Permissions') }}</div>

        <div class="mc-form__row">
            <mc-toggle [formControl]="form.getControl('isInheritPermissions')" (change)="togglePermissionsControl()">
                {{ tUsers('Users.ApiKeyForm.Label.InheritPermissions') }}
            </mc-toggle>
        </div>

        <div class="mc-form__row margin-top_12" *ngIf="form.isVisibleControl('permissions')">
            <mc-form-field>
                <mc-tree-select #select="mcTreeSelect"
                    [multiple]="true"
                    [hiddenItemsTextFormatter]="selectHiddenItemsTextFormatter"
                    [formControl]="form.getControl('permissions')"
                    [placeholder]="permissionsPlaceholder"
                    (selectionChange)="onPermissionsChange($event)">
                    <mc-tree-selection
                        [dataSource]="dataSource"
                        [treeControl]="treeControl"
                        class="api-key-form__tree-selection">
                        <mc-tree-option
                            class="api-key-form__permission-option"
                            #option
                            *mcTreeNodeDef="let node"
                            mcTreeNodePadding>
                            <div class="pt-text-overflow" pt-title>{{ treeControl.getViewValue(node) }}</div>
                        </mc-tree-option>

                        <mc-tree-option
                            class="api-key-form__permission-option"
                            #option
                            *mcTreeNodeDef="let node; when: isTreeNodeHasChild"
                            mcTreeNodePadding>
                            <div class="pt-text-overflow" pt-title>
                                <mc-pseudo-checkbox class="hidden"></mc-pseudo-checkbox>
                                <i mc-icon="mc-angle-down-S_16"
                                   [style.transform]="treeControl.isExpanded(node) ? '' : 'rotate(-90deg)'"
                                   mcTreeNodeToggle>
                                </i>
                                {{ treeControl.getViewValue(node) }}
                            </div>
                        </mc-tree-option>
                    </mc-tree-selection>

                    <mc-cleaner #mcSelectCleaner></mc-cleaner>
                </mc-tree-select>
            </mc-form-field>
        </div>
    </div>

    <div class="api-key-form__expires-at" *ngIf="form.isVisibleControl('isPerpetual')">
        <div class="mc-form__legend">{{ tUsers('Users.ApiKey.Text.ValidUntil') }}</div>

        <div class="mc-form__row">
            <mc-toggle [formControl]="form.getControl('isPerpetual')" (change)="toggleExpiresAtControls()">
                {{ tUsers('Users.ApiKey.Text.Perpetual') }}
            </mc-toggle>
        </div>
        <div class="layout-row margin-top_12"
             [class.hidden]="!form.isVisibleControl('expiresAt')">
            <mc-form-field class="api-key-form__datepicker"
                           (click)="datepicker.toggle()">
                <input
                    [formControl]="form.getControl('expiresAt')"
                    [placeholder]="'common.Common.Pseudo.Datepicker.Placeholder' | transloco"
                    [min]="minDate"
                    [mcDatepicker]="datepicker"
                    [mcDatepickerFilter]="datepickerFilter"
                    (dateChange)="onDateChange()"/>
                <i mc-icon="mc-calendar_16" mcSuffix></i>
                <mc-datepicker #datepicker></mc-datepicker>
            </mc-form-field>

            <mc-form-field class="api-key-form__timepicker margin-left_16">
                <i mcPrefix mc-icon="mc-clock_16"></i>
                <input mcTimepicker
                       [formControl]="form.getControl('expiresAt')"
                       [min]="minDate"
                       [placeholder]="'common.Common.Pseudo.Timepicker.Placeholder' | transloco: { format: 'short' }"
                       [format]="TimeFormats.HHmm">
            </mc-form-field>
        </div>
    </div>
</ng-container>
