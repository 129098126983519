import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AccountFacade } from '@pt-cybsi/store/account';

export interface ISessionExpiredRedirectPreventer {
    isPreventSessionExpiredRedirect: boolean;
}

@Injectable({ providedIn: 'root' })
export class SessionExpiredRedirectPreventersStore {
    get hasRedirectPreventer(): boolean {
        return this.preventers.some((preventer) => preventer.isPreventSessionExpiredRedirect);
    }

    isPreventRedirect$ = this.accountStoreFacade.isSessionExpired$.pipe(
        map((isSessionExpired) => isSessionExpired && this.hasRedirectPreventer)
    );

    private preventers: ISessionExpiredRedirectPreventer[] = [];

    constructor(private accountStoreFacade: AccountFacade) {}

    addPreventer(preventer: ISessionExpiredRedirectPreventer): void {
        this.preventers.push(preventer);
    }

    removePreventer(removedPreventer: ISessionExpiredRedirectPreventer): void {
        const indexRemovedPreventer = this.preventers.findIndex((preventer) => preventer === removedPreventer);

        this.preventers.splice(indexRemovedPreventer, 1);
    }
}
