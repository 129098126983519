import { IEditableResource, IPaginationResponse } from '../common';

import { IEnrichmentAnalyzer } from './analyzer';
import { IEnrichmentExternalDB } from './external-db';
import { IEnrichmentRule } from './rule';

export type TEnrichmentRulesResponse = IPaginationResponse<IEnrichmentRule[]>;
export type TEditableEnrichmentRuleResponse = IEditableResource<IEnrichmentRule>;

export type TEnrichmentAnalyzersResponse = IPaginationResponse<IEnrichmentAnalyzer[]>;
export type TEditableEnrichmentAnalyzerResponse = IEditableResource<IEnrichmentAnalyzer>;

export type TEnrichmentExternalDBsResponse = IPaginationResponse<IEnrichmentExternalDB[]>;
export type TEditableEnrichmentExternalDBResponse = IEditableResource<IEnrichmentExternalDB>;

export enum AddEnrichmentRuleErrorCode {
    DataSourceNotFound = 'DataSourceNotFound',
    MisconfiguredDataSource = 'MisconfiguredDataSource',
    InvalidRule = 'InvalidRule'
}

export enum UpdateEnrichmentRuleErrorCode {
    DataSourceNotFound = 'DataSourceNotFound',
    MisconfiguredDataSource = 'MisconfiguredDataSource',
    ImmutableValue = 'ImmutableValue',
    InvalidRule = 'InvalidRule'
}
