import { CommonModule } from '@angular/common';
import { NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { McCheckboxModule } from '@ptsecurity/mosaic/checkbox';
import { McPseudoCheckboxModule } from '@ptsecurity/mosaic/core';
import { McDividerModule } from '@ptsecurity/mosaic/divider';
import { McFormFieldModule } from '@ptsecurity/mosaic/form-field';
import { McInputModule } from '@ptsecurity/mosaic/input';
import { McRadioModule } from '@ptsecurity/mosaic/radio';
import { McSidepanelModule } from '@ptsecurity/mosaic/sidepanel';

import { DomainCoreAccountModule } from '@pt-cybsi/domain-core/account';
import { DomainCoreArtifactsModule } from '@pt-cybsi/domain-core/artifacts';
import { DomainCoreObservableEntitiesModule } from '@pt-cybsi/domain-core/observable-entities';
import { DomainCoreSourcesModule } from '@pt-cybsi/domain-core/sources';
import { SharedModule } from '@pt-cybsi/shared';

import {
    AnalyserFormComponent,
    EnrichmentRuleMetaComponent,
    ExternalDBFormComponent,
    AnalyserViewComponent,
    ExternalDbViewComponent,
    EnrichmentRulesGridComponent,
    EnrichmentTriggersFilterComponent,
    EnrichmentTypesFilterComponent,
    EnrichmentRulesFiltersPanelComponent,
    EnrichmentRuleFormComponent
} from './components';
import { EnrichmentRuleSidepanelComponent } from './containers';
import {
    TranslateEnrichmentTriggerTypePipe,
    TranslateEnrichmentTriggerTypesPipe,
    TranslateEnrichmentTypePipe,
    TranslateEnrichmentTypesPipe
} from './pipes';

const modules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    McDividerModule,
    McInputModule,
    McCheckboxModule,
    McPseudoCheckboxModule,
    McFormFieldModule,
    McSidepanelModule,
    McRadioModule,
    SharedModule,
    DomainCoreObservableEntitiesModule,
    DomainCoreAccountModule,
    DomainCoreArtifactsModule,
    DomainCoreSourcesModule,
    RouterModule
];

const components = [
    AnalyserFormComponent,
    EnrichmentRuleFormComponent,
    EnrichmentRuleMetaComponent,
    ExternalDBFormComponent,
    AnalyserViewComponent,
    ExternalDbViewComponent,
    EnrichmentRulesGridComponent,
    EnrichmentTriggersFilterComponent,
    EnrichmentTypesFilterComponent,
    EnrichmentRulesFiltersPanelComponent
];

const containers = [EnrichmentRuleSidepanelComponent];

const services = [] as Provider[];

const pipes = [
    TranslateEnrichmentTriggerTypePipe,
    TranslateEnrichmentTriggerTypesPipe,
    TranslateEnrichmentTypePipe,
    TranslateEnrichmentTypesPipe
];

@NgModule({
    imports: [...modules],
    declarations: [...components, ...containers, ...pipes],
    providers: [...services, ...pipes],
    exports: [...components, ...pipes]
})
export class DomainCoreEnrichmentModule {}
