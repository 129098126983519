import { Component, ElementRef, Input, Optional, ViewChild } from '@angular/core';
import { McDropdownTrigger } from '@ptsecurity/mosaic/dropdown';
import { McPopoverTrigger } from '@ptsecurity/mosaic/popover';

@Component({
    selector: 'filter-button',
    templateUrl: './filter-button.component.html',
    styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent {
    @Input() title: string;
    @Input() valueTitle: string;
    @Input() allowTooltip = true;

    @ViewChild('textContent', { read: ElementRef })
    textContent: ElementRef;

    get hasValueTitle(): boolean {
        return !!this.valueTitle;
    }

    get hasOverflowedText(): boolean {
        const el = this.textContent?.nativeElement as HTMLElement;

        return el?.scrollWidth > el?.offsetWidth;
    }

    get hasPopover(): boolean {
        return this.mcPopover !== undefined && this.mcPopover !== null;
    }

    get hasDropdown(): boolean {
        return this.mcDropdown !== undefined && this.mcDropdown !== null;
    }

    get isClosedPopover(): boolean {
        return this.hasPopover ? !this.mcPopover.isOpen : false;
    }

    get isClosedDropdown(): boolean {
        return this.hasDropdown ? !this.mcDropdown.opened : false;
    }

    get isActive(): boolean {
        return (this.hasPopover && !this.isClosedPopover) || (this.hasDropdown && !this.isClosedDropdown);
    }

    get isAllowTextContentTooltip(): boolean {
        return (
            this.hasValueTitle && this.hasOverflowedText && (this.hasPopover ? this.isClosedPopover : this.allowTooltip)
        );
    }

    get buttonText(): string {
        return this.hasValueTitle ? `${this.title}: ${this.valueTitle}` : this.title;
    }

    constructor(@Optional() private mcDropdown: McDropdownTrigger, @Optional() private mcPopover: McPopoverTrigger) {}
}
