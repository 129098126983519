import { Component, Input } from '@angular/core';

@Component({
    selector: 'labels-collection-item',
    templateUrl: './labels-collection-item.component.html',
    styleUrls: ['./labels-collection-item.component.scss']
})
export class LabelsCollectionItemComponent {
    @Input() value: string;
}
