import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { EnrichmentTriggerType, EnrichmentType } from '@pt-cybsi/api-interfaces';
import { TranslateArtifactTypesPipe } from '@pt-cybsi/domain-core/artifacts';
import { TranslateObservableEntityTypesPipe } from '@pt-cybsi/domain-core/observable-entities';
import { SourcesNavigationService } from '@pt-cybsi/domain-core/sources';
import { ToTimePeriodStringPipe } from '@pt-cybsi/shared';

import { getEnrichmentRulesSourcesTitle, getEnrichmentRulesSourcesCounterTitle } from '../../helpers';
import { TranslateEnrichmentTriggerTypesPipe, TranslateEnrichmentTypePipe } from '../../pipes';
import { IEnrichmentRuleMeta } from '../../types';

/**
 * @component EnrichmentRuleMeta
 *
 * @description Displays fields of an enrichment rule meta information
 *
 * @param meta - fields of meta information. Throttling interval should be in milliseconds
 *
 * @example
 * <enrichment-rule-meta [meta]="meta"></enrichment-rule-meta>
 */
@Component({
    selector: 'enrichment-rule-meta',
    templateUrl: './enrichment-rule-meta.component.html',
    styleUrls: ['./enrichment-rule-meta.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnrichmentRuleMetaComponent implements OnInit {
    @Input()
    meta: IEnrichmentRuleMeta;

    sourcesTitle: string;
    sourcesTitleCollection: string;

    get type(): string {
        return this.translateType.transform(this.meta.type);
    }

    get triggerTypes(): string {
        return this.translateTriggerTypes.transform(this.meta.triggerTypes).join(', ');
    }

    get artifactTypes(): string {
        return this.translateArtifactTypes.transform(this.meta.artifactTypes).join(', ');
    }

    get entityTypes(): string {
        return this.translateEntityTypes.transform(this.meta.entityTypes).join(', ');
    }

    get throttlingInterval(): string {
        return this.toTimePeriodString.transform(this.meta.throttlingInterval);
    }

    get hasArtifactTypes(): boolean {
        return this.meta.artifactTypes?.length > 0;
    }

    get hasEntityTypes(): boolean {
        return this.meta.entityTypes?.length > 0;
    }

    get hasThrottlingInterval(): boolean {
        return !!this.meta.throttlingInterval;
    }

    get isSourcesVisible(): boolean {
        return [
            EnrichmentType.ArtifactAnalysis,
            EnrichmentType.ExternalDBLookup,
            EnrichmentType.DNSLookup,
            EnrichmentType.WhoisLookup
        ].includes(this.meta.type);
    }

    get isTriggerParamsVisible(): boolean {
        return this.meta.triggerTypes.includes(EnrichmentTriggerType.OnRegistration);
    }

    constructor(
        private translateType: TranslateEnrichmentTypePipe,
        private translateTriggerTypes: TranslateEnrichmentTriggerTypesPipe,
        private translateArtifactTypes: TranslateArtifactTypesPipe,
        private translateEntityTypes: TranslateObservableEntityTypesPipe,
        private toTimePeriodString: ToTimePeriodStringPipe,
        private sourcesNavigationService: SourcesNavigationService
    ) {}

    ngOnInit(): void {
        this.sourcesTitle = getEnrichmentRulesSourcesTitle(this.meta.type);
        this.sourcesTitleCollection = getEnrichmentRulesSourcesCounterTitle(this.meta);
    }

    getSourceLink(id: string): string {
        return this.sourcesNavigationService.getPathOfSourceViewRoute(id);
    }
}
