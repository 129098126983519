import { createControlValidator } from '@pt-cybsi/shared';

export const DESCRIPTION_MAX_LENGTH = 3000;

export const validateDictionaryDescriptionLength = (description: string) =>
    description === undefined ||
    description === null ||
    description === '' ||
    description.length <= DESCRIPTION_MAX_LENGTH;

export const dictionaryDescriptionLengthValidator = createControlValidator(
    validateDictionaryDescriptionLength,
    'length'
);

export const SYNONYM_MIN_LENGTH = 1;
export const SYNONYM_MAX_LENGTH = 30;

export const validateDictionarySynonymLength = (synonym: string) =>
    synonym === undefined ||
    synonym === null ||
    synonym === '' ||
    (synonym.length >= SYNONYM_MIN_LENGTH && synonym.length <= SYNONYM_MAX_LENGTH);

export const validateDictionarySynonymFormat = (synonym: string) =>
    synonym === undefined || synonym === null || synonym === '' || /^[\sa-zA-Z0-9_:-]*$/.test(synonym);
