import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TConfidence } from '@pt-cybsi/api-interfaces';

export enum ContentWithConfidenceFormat {
    Text = 'Text',
    Interactive = 'Interactive'
}

/**
 * @component ContentWithConfidence
 *
 * @description
 * Component for displaying some content with confidence value
 *
 * @param confidence - confidence value
 * @param format - confidence displaying format from ContentWithConfidenceFormat (Text | Interactive)
 * @param confidenceClick - event, emitted by click to confidence. Working only in 'Interactive' format
 *
 * @example
 * ```html
 *
 * <content-with-confidence
 *       [confidence]="0.1"
 *       [format]="ContentWithConfidenceFormat.Interactive"
 *       (confidenceClick)="handleConfidenceClick()">
 *      <div class="layout-row">
 *          <span class="pt-text-overflow" pt-title>Some looooooooooong text value</span>
 *      </div>
 * </content-with-confidence>
 * ```
 */
@Component({
    selector: 'content-with-confidence',
    templateUrl: './content-with-confidence.component.html',
    styleUrls: ['./content-with-confidence.component.scss']
})
export class ContentWithConfidenceComponent {
    @Input() confidence: TConfidence;
    @Input() format: ContentWithConfidenceFormat;

    @Output() confidenceClick = new EventEmitter<void>();

    get isTextFormat(): boolean {
        return this.format === ContentWithConfidenceFormat.Text;
    }

    get isInteractiveFormat(): boolean {
        return this.format === ContentWithConfidenceFormat.Interactive;
    }
}
