import random from 'lodash/random';
import shuffle from 'lodash/shuffle';

export const MIN_LENGTH = 8;
export const MAX_LENGTH = 12;
const UPPER_LATIN_LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const LOWER_LATIN_LETTERS = 'abcdefghijklmnopqrstuvwxyz';
const DIGITS = '0123456789';
const SPECIAL_SYMBOLS = ' !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';
const ALL_SYMBOLS = [UPPER_LATIN_LETTERS, LOWER_LATIN_LETTERS, DIGITS, SPECIAL_SYMBOLS].join('');

const PASSWORD_REQUIREMENTS = [UPPER_LATIN_LETTERS, LOWER_LATIN_LETTERS, DIGITS, SPECIAL_SYMBOLS];

export function generatePassword(): string {
    const passwordLength = random(MIN_LENGTH, MAX_LENGTH);
    let password = '';

    PASSWORD_REQUIREMENTS.forEach((symbolsSet: string) => {
        password = password + getRandomSymbols(symbolsSet);
    });

    password = password + getRandomSymbols(ALL_SYMBOLS, passwordLength - PASSWORD_REQUIREMENTS.length);

    return shuffle(password).join('');
}

function getRandomSymbols(symbolsSet: string, length: number = 1): string {
    return Array(length)
        .fill(symbolsSet)
        .map((x: string) => x[Math.floor(Math.random() * x.length)])
        .join('');
}
