import {
    AttributeObservableEntity,
    IAttributeValuableFact,
    IRelation,
    IRelationValuableFact,
    RelationDirection
} from '@pt-cybsi/api-interfaces';

import { EntityFullInfoModel } from '../models';
import { IAttributeValuableFactView, IRelationValuableFactView, TEntityPreviewData } from '../types';

export interface IAttributeValuableFactViewMappingMetadata {
    attributeName: AttributeObservableEntity;
}

export interface IRelationValuableFactViewMappingMetadata {
    relation: IRelation;
    relationDirection: RelationDirection;
    entitiesPreview: TEntityPreviewData[];
}

export class ValuableFactViewMapper {
    static fromAttributeValuableFact(
        fact: IAttributeValuableFact,
        metadata: IAttributeValuableFactViewMappingMetadata
    ): IAttributeValuableFactView {
        return {
            attributeName: metadata.attributeName,
            dataSourceId: fact.dataSource.uuid,
            dataSourceConfidence: fact.dataSource.confidence,
            shareLevel: fact.shareLevel,
            seenAt: fact.seenAt,
            confidence: fact.confidence,
            finalConfidence: fact.finalConfidence,
            value: fact.value
        };
    }

    static fromAttributeValuableFacts(
        facts: IAttributeValuableFact[],
        metadata: IAttributeValuableFactViewMappingMetadata
    ): IAttributeValuableFactView[] {
        return facts.map((fact) => ValuableFactViewMapper.fromAttributeValuableFact(fact, metadata));
    }

    static fromRelationValuableFact(
        fact: IRelationValuableFact,
        metadata: IRelationValuableFactViewMappingMetadata
    ): IRelationValuableFactView {
        const relatedEntity =
            metadata.relationDirection === RelationDirection.Forward
                ? metadata.relation.targetEntity
                : metadata.relation.sourceEntity;

        const relatedEntityModel = EntityFullInfoModel.createFromRawData(relatedEntity);

        const value = metadata.entitiesPreview.find(
            (entityPreview) => entityPreview.localId === relatedEntityModel.getLocalId()
        );

        return {
            dataSourceId: fact.dataSource.uuid,
            dataSourceConfidence: fact.dataSource.confidence,
            shareLevel: fact.shareLevel,
            seenAt: fact.seenAt,
            confidence: fact.confidence,
            finalConfidence: fact.finalConfidence,
            value
        };
    }

    static fromRelationValuableFacts(
        facts: IRelationValuableFact[],
        metadata: IRelationValuableFactViewMappingMetadata
    ): IRelationValuableFactView[] {
        return facts.map((fact) => ValuableFactViewMapper.fromRelationValuableFact(fact, metadata));
    }
}
