<filter-button
    mcPopover
    mcPopoverClass="sources-tree-list-filter-popover"
    #popover="mcPopover"
    [title]="filterTitle"
    [valueTitle]="valueTitle | async"
    [mcPopoverHeader]="header"
    [mcPopoverContent]="content"
    [mcPopoverFooter]="footer"
    (mcPopoverVisibleChange)="handlePopoverVisibleChange($event)">
</filter-button>

<ng-template #header>
    <mc-form-field class="sources-tree-list-filter__search-form-field margin_16">
        <i mcPrefix mc-icon="mc-search_16" color="second"></i>
        <input type="text"
               mcInput
               autocomplete="off"
               [placeholder]="'sources.Sources.SearchFilter.Text.NamePlaceholder' | transloco"
               [(ngModel)]="searchValue"
               (ngModelChange)="onSearchChange($event)">
    </mc-form-field>
</ng-template>

<ng-template #content>
    <div *ngIf="isLoaderVisible$ | async" class="sources-tree-list-filter-popover__loader layout-row layout-align-center-center">
        <mc-progress-spinner mode="indeterminate"></mc-progress-spinner>
    </div>
    <div *ngIf="isErrorVisible$ | async" class="sources-tree-list-filter-popover__error mc-alert mc-alert_error margin-left_16 margin-right_16">
        <i mc-icon="mc-error_16" class="mc-alert__icon"></i>
        <div>
            <div>{{ 'sources.Sources.SourceSelectModal.Error.LoadingSources' | transloco }}</div>
            <button mc-link pseudo class="mc-link clear-button" (click)="reloadSources()">
                <span class="mc-link__text">{{ 'common.Common.Loading.Text.RetryButton' | transloco }}</span>
            </button>
        </div>
    </div>

    <div *ngIf="isSourcesLoaded$ | async">
        <div class="sources-tree-list-filter-popover__tree-container">
            <mc-tree-selection
                multiple="checkbox"
                [(ngModel)]="selectedSources"
                [dataSource]="dataSource"
                [treeControl]="treeControl">
                <mc-tree-option *mcTreeNodeDef="let node" mcTreeNodePadding [mcTreeNodePaddingIndent]="0">
                    <div class="pt-text-overflow" pt-title [id]="node.id" [innerHTML]="treeControl.getViewValue(node) | mcHighlight: treeControl.filterValue.value"></div>
                </mc-tree-option>

                <mc-tree-option *mcTreeNodeDef="let node; when: hasChild" mcTreeNodePadding [disabled]="true">
                    <mc-pseudo-checkbox class="hidden"></mc-pseudo-checkbox>

                    <mc-tree-node-toggle [node]="node"></mc-tree-node-toggle>

                    <div class="pt-text-overflow" pt-title [innerHTML]="treeControl.getViewValue(node) | mcHighlight: treeControl.filterValue.value"></div>
                </mc-tree-option>
            </mc-tree-selection>
        </div>

        <span *ngIf="isEmptySearchResult"
              class="sources-tree-list-filter-popover__empty text-disabled margin-left_16">
            {{ 'common.Common.Search.Title.NotFound' | transloco }}
        </span>
    </div>
</ng-template>

<ng-template #footer>
    <div class="flex-100 layout-row layout-align-space-between-center">
        <button mc-button
                color="second"
                class="sources-tree-list-filter-popover__reset"
                (click)="handleReset()">
            {{ 'common.Common.Pseudo.Button.Reset' | transloco }}
        </button>
        <button mc-button
                color="primary"
                (click)="handleApply()">
            {{ 'common.Common.Pseudo.Button.Apply' | transloco }}
        </button>
        <button mc-button
                color="second"
                class="margin-left_8"
                (click)="handleCancel()">
            {{ 'common.Common.Pseudo.Button.Cancel' | transloco }}
        </button>
    </div>
</ng-template>
