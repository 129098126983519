import {
    ObservableEntityType,
    TIndicatorAggregate,
    TTrustedAggregate,
    TThreatAggregate
} from '@pt-cybsi/api-interfaces';

import { EntityFullInfoModel, TEntityFullInfoModel } from '../models';
import {
    IDomainNamePreviewData,
    IEmailAddressPreviewData,
    IFilePreviewData,
    IIdentityPreviewData,
    IIpAddressPreviewData,
    IPhoneNumberPreviewData,
    IUrlPreviewData,
    TEntityPreviewData,
    ThreatLevel
} from '../types';

import { ThreatLevelMapper } from './threat-level.mapper';

export class EntityPreviewMapper {
    static toPreview(entity: TEntityFullInfoModel): TEntityPreviewData {
        if (entity.is(ObservableEntityType.File)) {
            return EntityPreviewMapper.toFilePreview(entity);
        } else if (entity.is(ObservableEntityType.Identity)) {
            return EntityPreviewMapper.toIdentityPreview(entity);
        } else if (entity.is(ObservableEntityType.IPAddress)) {
            return EntityPreviewMapper.toIpAddressPreview(entity);
        } else if (entity.is(ObservableEntityType.DomainName)) {
            return EntityPreviewMapper.toDomainNamePreview(entity);
        } else if (entity.is(ObservableEntityType.URL)) {
            return EntityPreviewMapper.toUrlPreview(entity);
        } else if (entity.is(ObservableEntityType.EmailAddress)) {
            return EntityPreviewMapper.toEmailAddressPreview(entity);
        } else if (entity.is(ObservableEntityType.PhoneNumber)) {
            return EntityPreviewMapper.toPhoneNumberPreview(entity);
        } else {
            return null;
        }
    }

    static toFilePreview(entity: EntityFullInfoModel<ObservableEntityType.File>): IFilePreviewData {
        const mainKey = entity.getMainKeys()[0];
        const secondaryKey = entity.getSecondaryKeys()[0];

        const hasExtraAttributes = !!secondaryKey;

        return {
            id: entity.getId(),
            localId: entity.getLocalId(),
            type: entity.getType(),
            name: entity.getName(),
            mainHash: mainKey,
            threatLevel: EntityPreviewMapper.getThreatLevel(entity),
            isIoC: EntityPreviewMapper.getIsIoC(entity),
            isTrusted: EntityPreviewMapper.getIsTrusted(entity),
            extraAttributes: hasExtraAttributes ? { additionalHash: secondaryKey } : null
        };
    }

    static toIdentityPreview(entity: EntityFullInfoModel<ObservableEntityType.Identity>): IIdentityPreviewData {
        const mainIdentifiers = entity.getMainKeys();

        const identifier = mainIdentifiers.reduce<IIdentityPreviewData['extraAttributes']['identifier']>(
            (result, key) => ({
                type: key.type,
                values: (result?.values || []).concat(key.value)
            }),
            null
        );

        const identityClassValues = entity.getIdentityClassValues();
        const identityClass = identityClassValues[0]?.value || null;

        const identitySectorsValues = entity.getIdentitySectorsValues();
        const identitySectors = identitySectorsValues.map(({ value }) => value.key);

        const hasExtraAttributes = !!identifier || !!identityClass || identitySectors.length > 0;

        return {
            id: entity.getId(),
            localId: entity.getLocalId(),
            type: entity.getType(),
            name: entity.getName(),
            extraAttributes: hasExtraAttributes ? { identifier, identityClass, identitySectors } : null
        };
    }

    static toIpAddressPreview(entity: EntityFullInfoModel<ObservableEntityType.IPAddress>): IIpAddressPreviewData {
        const geoData = entity.getGeoDataOfIpAddress();

        const asn = geoData?.asn;

        const country =
            !!geoData?.country || !!geoData?.countryCode
                ? {
                      name: geoData?.country,
                      code: geoData?.countryCode
                  }
                : null;

        const hasExtraAttributes = !!asn && !!country;

        return {
            id: entity.getId(),
            localId: entity.getLocalId(),
            type: entity.getType(),
            name: entity.getName(),
            threatLevel: EntityPreviewMapper.getThreatLevel(entity),
            isIoC: EntityPreviewMapper.getIsIoC(entity),
            isTrusted: EntityPreviewMapper.getIsTrusted(entity),
            extraAttributes: hasExtraAttributes ? { asn, country } : null
        };
    }

    static toDomainNamePreview(entity: EntityFullInfoModel<ObservableEntityType.DomainName>): IDomainNamePreviewData {
        const resolvedIpAddresses = EntityPreviewMapper.getResolvedIpAddressesOfDomain(entity);

        const hasExtraAttributes = resolvedIpAddresses?.items?.length > 0;

        return {
            id: entity.getId(),
            localId: entity.getLocalId(),
            type: entity.getType(),
            name: entity.getName(),
            threatLevel: EntityPreviewMapper.getThreatLevel(entity),
            isIoC: EntityPreviewMapper.getIsIoC(entity),
            isTrusted: EntityPreviewMapper.getIsTrusted(entity),
            extraAttributes: hasExtraAttributes ? { resolvedIpAddresses } : null
        };
    }

    static toUrlPreview(entity: EntityFullInfoModel<ObservableEntityType.URL>): IUrlPreviewData {
        return {
            id: entity.getId(),
            localId: entity.getLocalId(),
            type: entity.getType(),
            name: entity.getName(),
            threatLevel: EntityPreviewMapper.getThreatLevel(entity),
            isIoC: EntityPreviewMapper.getIsIoC(entity),
            isTrusted: EntityPreviewMapper.getIsTrusted(entity)
        };
    }

    static toEmailAddressPreview(
        entity: EntityFullInfoModel<ObservableEntityType.EmailAddress>
    ): IEmailAddressPreviewData {
        const displayNamesValues = entity.getEmailDisplayNamesValues();
        const displayNames = displayNamesValues.map(({ value }) => value);

        const hasExtraAttributes = displayNames.length > 0;

        return {
            id: entity.getId(),
            localId: entity.getLocalId(),
            type: entity.getType(),
            name: entity.getName(),
            threatLevel: EntityPreviewMapper.getThreatLevel(entity),
            isIoC: EntityPreviewMapper.getIsIoC(entity),
            isTrusted: EntityPreviewMapper.getIsTrusted(entity),
            extraAttributes: hasExtraAttributes ? { displayNames } : null
        };
    }

    static toPhoneNumberPreview(
        entity: EntityFullInfoModel<ObservableEntityType.PhoneNumber>
    ): IPhoneNumberPreviewData {
        const extensions = EntityPreviewMapper.getExtensionsOfPhoneNumber(entity);

        const hasExtraAttributes = extensions?.items?.length > 0;

        return {
            id: entity.getId(),
            localId: entity.getLocalId(),
            type: entity.getType(),
            name: entity.getName(),
            isIoC: EntityPreviewMapper.getIsIoC(entity),
            extraAttributes: hasExtraAttributes ? { extensions } : null
        };
    }

    private static getIsIoC<E extends TIndicatorAggregate['type']>(entity: EntityFullInfoModel<E>): boolean {
        const isIoCValues = entity.getIsIoCValues();

        return isIoCValues[0]?.value || false;
    }

    private static getIsTrusted<E extends TTrustedAggregate['type']>(entity: EntityFullInfoModel<E>): boolean {
        const isTrustedValues = entity.getIsTrustedValues();

        return isTrustedValues[0]?.value || false;
    }

    private static getThreatLevel<E extends TThreatAggregate['type']>(entity: EntityFullInfoModel<E>): ThreatLevel {
        if (entity.is(ObservableEntityType.File)) {
            const categoryValues = entity.getThreatCategoryValues();

            return ThreatLevelMapper.fromThreatCategory(categoryValues[0]?.value);
        } else {
            const categoryValues = entity.getRelatedThreatCategoryValues();

            return ThreatLevelMapper.fromRelatedThreatCategory(categoryValues[0]?.value);
        }
    }

    private static getResolvedIpAddressesOfDomain(
        entity: EntityFullInfoModel<ObservableEntityType.DomainName>
    ): IDomainNamePreviewData['extraAttributes']['resolvedIpAddresses'] {
        return entity
            .getResolvedIpAddressesOfDomain()
            .reduce<IDomainNamePreviewData['extraAttributes']['resolvedIpAddresses']>((result, relation) => {
                const ipAddress = EntityPreviewMapper.toIpAddressPreview(
                    relation.relatedEntity as EntityFullInfoModel<ObservableEntityType.IPAddress>
                );

                const items = (result?.items || []).concat(ipAddress);

                return {
                    relationType: relation.type,
                    relationDirection: relation.direction,
                    items
                };
            }, null);
    }

    private static getExtensionsOfPhoneNumber(
        entity: EntityFullInfoModel<ObservableEntityType.PhoneNumber>
    ): IPhoneNumberPreviewData['extraAttributes']['extensions'] {
        return entity
            .getExtensionsOfPhoneNumber()
            .reduce<IPhoneNumberPreviewData['extraAttributes']['extensions']>((result, relation) => {
                const phoneNumber = EntityPreviewMapper.toPhoneNumberPreview(
                    relation.relatedEntity as EntityFullInfoModel<ObservableEntityType.PhoneNumber>
                );

                const items = (result?.items || [])
                    .concat(phoneNumber)
                    .map(({ name, ...rest }) => ({ ...rest, name: name.split(',')[1] || name }));

                return {
                    relationType: relation.type,
                    relationDirection: relation.direction,
                    items
                };
            }, null);
    }
}
