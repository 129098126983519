<mc-modal-title>
    {{ 'entities.ObservableEntities.Pseudo.Text.Confidence' | transloco }} — {{ confidence$ | async | formatConfidence : ConfidenceFormat.Full }}
</mc-modal-title>
<mc-modal-body class="forecast-modal__body">
    <progress-container class="forecast-modal__progress-container" [loadingFlag]="isLoaderVisible$ | async">
        <div *ngIf="isErrorVisible$ | async" class="layout-padding-l">
            <error-alert [text]="'common.Common.Loading.Text.ErrorTitle' | transloco"
                         [hasRetry]="true"
                         (retry)="reload.emit()">
            </error-alert>
        </div>

        <valuable-facts-table
            *ngIf="isTableVisible$ | async"
            [type]="type"
            [facts]="valuableFacts$ | async">
        </valuable-facts-table>
    </progress-container>
</mc-modal-body>
