<limited-collection
    [renderStyle]="collectionRenderStyle"
    [itemTemplate]="itemTemplate"
    [actionsTemplate]="actionsTemplate"
    [collection]="collection"
    [collectionTitle]="collectionTitle"
    [limit]="collectionLimit"
    [isDefaultSidepanelOpenPrevented]="true"

    (showMore)="handleShowMore()">
</limited-collection>

<ng-template #editButtonTemplate>
    <button
        *ngIf="hasItems"
        mc-link
        pseudo
        class="mc-link clear-button"
        (click)='edit.emit()'>
        {{ 'common.Common.Pseudo.Button.Change' | transloco }}
    </button>

    <button
        *ngIf="!hasItems"
        class="labels-collection__add-button mc-badge mc-badge_default mc-badge_solid"
        (click)='edit.emit()'>
        <i class="pt-icons pt-icons-plus-circle_16 layout-margin-right-xs"></i>
        {{ 'common.Common.Pseudo.Button.AddTag' | transloco }}
    </button>
</ng-template>
