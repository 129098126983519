import { ShareLevel } from '../common';
import { IDataSource } from '../data-sources';

import { Role } from './roles';

export const LOCAL_AUTH_PROVIDER_ID = 'Cybsi';

export interface IUser {
    uuid: string;
    url?: string;
    login: string;
    fullName?: string;
    email?: string;
    isDisabled: boolean;
    authProviderID: string;
    dataSource: IDataSource | null;
}

export interface IUserFullInfo extends IUser {
    accessLevel: ShareLevel;
    permissions: string[];
    roles: { uuid: string; name: Role }[];
    passwordAuthEnabled: boolean;
}
