<span *ngIf="isTextFormat" class="pt-text-overflow layout-row-inline" pt-title>
    {{ dictionaryItem.key }}
</span>

<a *ngIf="isLinkFormat"
   mc-link
   class="pt-text-overflow"
   pt-title
   [routerLink]="getDictionaryItemEditRoute(dictionaryItem.uuid)">
    <span class="mc-link__text">{{ dictionaryItem.key }}</span>
</a>

<button *ngIf="isPseudoLinkFormat"
        class="clear-button pt-text-overflow"

        mc-link
        pseudo

        #popover="mcPopover"
        mcPopover
        mcPopoverClass="dictionary-item-preview-popover"
        [mcPopoverPlacement]="'bottomLeft'"
        [mcPopoverContent]="popoverContentTemplate"
        [mcPopoverFooter]="popoverFooterTemplate"
        [closeOnScroll]="true"
        (mcPopoverVisibleChange)="handlePopoverVisibleChange($event)"

        pt-title>
    <span class="mc-link__text">{{ dictionaryItem.key }}</span>
</button>

<ng-template #popoverContentTemplate>
    <mc-loader-overlay *ngIf="isLoadingData$ | async"></mc-loader-overlay>

    <ng-container *ngIf="isSuccessLoadingData$ | async">
        <ng-container *ngIf="hasLoadedData">
            <ng-container *transloco="let tDictionaries; read: 'dictionaries'">
                <div *ngIf="hasDescription">
                    <div class="dictionary-item-preview-popover__field-name layout-margin-bottom-3xs text-less-contrast">
                        {{ tDictionaries('Dictionaries.Item.Text.Description') }}
                    </div>
                    <div class="dictionary-item-preview-popover__description">{{ description }}</div>
                </div>
                <div *ngIf="!hasDescription">
                    {{ tDictionaries('Dictionaries.Item.Text.EmptyDescription') }}
                </div>
                <div *ngIf="hasSynonyms" class="layout-column layout-margin-top-l">
                    <div class="dictionary-item-preview-popover__field-name layout-margin-bottom-3xs text-less-contrast">
                        {{ tDictionaries('Dictionaries.Item.Text.Synonyms') }}
                    </div>
                    <div class="layout-row dictionary-item-preview-popover__synonyms layout-margin-bottom-xxs">
                        <dictionary-item-preview
                            *ngFor="let synonym of synonyms | slice:0:maxDisplayedSynonyms"
                            class="dictionary-item-preview-popover__synonym layout-margin-right-l"
                            [dictionaryItem]="synonym"
                            [format]="DictionaryItemPreviewFormat.Link">
                        </dictionary-item-preview>
                        <span *ngIf="isTotalSynonymsTextVisible" class="dictionary-item-preview-popover__total-synonyms">
                            {{ 'common.Common.Pseudo.Text.Total' | transloco : { count: synonyms.length } }}
                        </span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-container>

    <error-alert *ngIf="isFailureLoadingData$ | async"
                 [text]="'common.Common.Loading.Text.ErrorTitle' | transloco"
                 [hasRetry]="true"
                 (retry)="handleRetryLoadData()">
    </error-alert>
</ng-template>

<ng-template #popoverFooterTemplate>
    <div class="flex-100 layout-row layout-align-end-center">
        <a class="dictionary-item-preview-popover__go-to-button"
           mc-button
           color="primary"
           [routerLink]="getDictionaryItemEditRoute(dictionaryItem.uuid)">
            <span class="pt-text-overflow" pt-title>
                {{ 'common.Common.Pseudo.Text.GoTo' | transloco : { name: dictionaryItem.key } }}
            </span>
        </a>
    </div>
</ng-template>
