import { Injectable } from '@angular/core';
import castArray from 'lodash/castArray';
import chunk from 'lodash/chunk';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
    AggregateSection,
    IAggregatesRequestParams,
    IAggregateRequestParams,
    ObservableEntityType,
    TAggregate,
    TObservableEntityKey,
    TObservableEntityKeyType
} from '@pt-cybsi/api-interfaces';

import { BaseApiService } from './base-api.service';

const AGGREGATES_CHUNK_MAX_SIZE = 50;

@Injectable()
export class ObservableEntitiesApiService extends BaseApiService {
    static readonly urls = {
        entities: 'observable/entities',
        canonicalKey: 'observable/entity-canonical-key'
    };

    getCanonicalKey<
        EntityType extends ObservableEntityType,
        KeyType extends TObservableEntityKeyType,
        ResponseType = Extract<TObservableEntityKey, { type: KeyType }>
    >(entityType: EntityType, keyType: KeyType, key: string): Observable<ResponseType> {
        return this.get<ResponseType>(ObservableEntitiesApiService.urls.canonicalKey, {
            params: { entityType, keyType, key }
        });
    }

    getAggregate<T extends TAggregate>(uuid: string, params: IAggregateRequestParams): Observable<T> {
        const url = ObservableEntitiesApiService.urls.entities;

        return this.get<T>(`${url}/${uuid}`, { params });
    }

    getAggregates<T extends TAggregate>(
        uuid: string | string[],
        section: AggregateSection | AggregateSection[]
    ): Observable<T[]> {
        if (isEmpty(compact(uuid))) {
            return of([] as T[]);
        }

        const sections = castArray(section);
        const chunks = chunk(castArray(uuid), AGGREGATES_CHUNK_MAX_SIZE);

        let data: T[] = [];

        // загрузка по чанкам необходима из-за ограничений длины GET запроса
        const queryChunk = (uuidsChunk: string[]): Observable<T[]> => {
            const params: IAggregatesRequestParams = {
                section: sections,
                uuid: uuidsChunk
            };

            return this.getFullList<T>(ObservableEntitiesApiService.urls.entities, { params }).pipe(
                map(({ fullList: responseData }) => {
                    data = [...data, ...responseData];

                    return data;
                })
            );
        };

        return chunks.reduce<Observable<T[]>>(
            (accumulator, uuidsChunk) => accumulator.pipe(mergeMap(() => queryChunk(uuidsChunk))),
            of([] as T[])
        );
    }

    getEntitiesAggregatesMap<T extends TAggregate>(
        uuid: string | string[],
        section: AggregateSection | AggregateSection[]
    ): Observable<IEntitiesAggregatesMap> {
        return this.getAggregates<T>(uniq(uuid), section).pipe(
            map((aggregates) =>
                aggregates.reduce<IEntitiesAggregatesMap>(
                    (groups, aggregate) => ({
                        ...groups,
                        [aggregate.uuid]: aggregate
                    }),
                    {}
                )
            )
        );
    }
}

export interface IEntitiesAggregatesMap {
    [entityId: string]: TAggregate;
}
