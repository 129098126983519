export interface IVersion {
    apiVersion: string;
    serverVersion: string;
}

export interface IProductVersionJson {
    productVersion: string;
    backendVersion: string;
    webClientVersion: string;
    adapterVersion: string;
}
