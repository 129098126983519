import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnrichmentTriggerType } from '@pt-cybsi/api-interfaces';
import { AsyncState, FiltersPanelBaseItem, IListFilterItem } from '@pt-cybsi/shared';

import { TranslateEnrichmentTriggerTypesPipe } from '../../pipes';

export type TEnrichmentTriggersFilterItem = IListFilterItem<EnrichmentTriggerType>;

/**
 * @component Enrichment Triggers Filter
 *
 * @description
 * Used for filtering data by enrichment triggers. Can be put into a filters-panel component
 *
 * @param value - applied values of filter in `EnrichmentTriggerType[]` format
 * @param applyFilter - event, emitted after clicking apply filter button
 * @param resetFilter - event, emitted after clicking reset filter button
 * @param openFilter - event, emitted after opening filter
 * @param closeFilter - event, emitted after closing filter
 *
 * @example
 * ```html
 * <enrichment-triggers-filter
 *     [value]="value"
 *     (applyFilter)="handleApply($event)"
 *     (resetFilter)="handleReset()">
 *     (openFilter)="handleOpen()">
 *     (closeFilter)="handleClose()">
 * </enrichment-triggers-filter>
 * ```
 */
@Component({
    selector: 'enrichment-triggers-filter',
    templateUrl: './enrichment-triggers-filter.component.html',
    providers: [
        {
            provide: FiltersPanelBaseItem,
            useExisting: forwardRef(() => EnrichmentTriggersFilterComponent)
        }
    ]
})
export class EnrichmentTriggersFilterComponent extends FiltersPanelBaseItem implements OnInit, OnChanges {
    @Input() value: EnrichmentTriggerType[];

    @Output() applyFilter = new EventEmitter<EnrichmentTriggerType[]>();
    @Output() resetFilter = new EventEmitter<void>();
    @Output() openFilter = new EventEmitter<void>();
    @Output() closeFilter = new EventEmitter<void>();

    availableItems: TEnrichmentTriggersFilterItem[];
    filterState: AsyncState = AsyncState.Success;

    appliedValues$ = new BehaviorSubject<TEnrichmentTriggersFilterItem[]>([]);
    valueTitle$: Observable<string[]>;

    readonly title: string = this.translocoService.translate('enrichment.Enrichment.Pseudo.Text.TriggerType');

    constructor(
        private translocoService: TranslocoService,
        private translateTriggerTypes: TranslateEnrichmentTriggerTypesPipe
    ) {
        super();
    }

    ngOnInit(): void {
        this.availableItems = this.getAvailableItems();
        this.valueTitle$ = this.getValueTitle$();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const isValueChanged = changes.value?.currentValue !== changes.value?.previousValue;

        if (isValueChanged) {
            this.appliedValues$.next(
                this.value.map((enrichmentTrigger) => ({
                    value: enrichmentTrigger,
                    data: enrichmentTrigger
                }))
            );
        }
    }

    handleOpen(): void {
        this.openFilter.emit();
    }

    handleApply(value: TEnrichmentTriggersFilterItem[]) {
        this.applyFilter.emit(value.map((enrichmentTriggerItem) => enrichmentTriggerItem.data));
    }

    handleReset() {
        this.resetFilter.emit();
    }

    reset(): void {
        this.resetFilter.emit();
    }

    getAvailableItems(): TEnrichmentTriggersFilterItem[] {
        return Object.values(EnrichmentTriggerType).map((triggerType) => ({ value: triggerType, data: triggerType }));
    }

    private getValueTitle$(): Observable<string[]> {
        return this.appliedValues$.pipe(
            map((enrichmentTriggerTypes: TEnrichmentTriggersFilterItem[]) => {
                if (!enrichmentTriggerTypes) {
                    return [];
                }

                return this.translateTriggerTypes.transform(
                    enrichmentTriggerTypes.map((enrichmentTriggerType) => enrichmentTriggerType.data)
                );
            })
        );
    }
}
