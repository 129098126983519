<div class="mc-form__row">
    <label class="mc-form__label">
        {{ 'enrichment.Enrichment.AnalyserFormFields.Label.DashboardURL' | transloco }}
    </label>

    <mc-form-field class="analyser-form__dashboard-url">
        <entity-key-field
            [formControl]="form.getControl('dashboardURL')"
            [entityType]="dashboardURLEntityType"
            [keyType]="dashboardURLKeyType">
        </entity-key-field>
        <mc-hint *ngIf="form.hasErrors('dashboardURL')" color="error">
            <div *ngIf="form.hasError('dashboardURL', 'format')">
                {{ 'common.Common.Validation.Text.InvalidURL' | transloco }}
            </div>
        </mc-hint>
    </mc-form-field>
</div>

<div class="mc-form__row">
    <label class="mc-form__label">
        {{ 'artifacts.Artifacts.Pseudo.Text.ArtifactTypes' | transloco }}
    </label>

    <checkbox-group-field
        name="checkbox-group-field"
        [items]="availableArtifactTypes"
        [checkboxTemplate]="artifactTypeCheckboxTemplate"
        [formControl]="form.getControl('artifactTypes')">
        <mc-hint *ngIf="form.hasErrors('artifactTypes')" color="error">
            <div *ngIf="form.hasError('artifactTypes', 'required')">
                {{ 'enrichment.Enrichment.AnalyserValidation.Text.RequiredArtifactTypes' | transloco }}
            </div>
        </mc-hint>
    </checkbox-group-field>

    <ng-template #artifactTypeCheckboxTemplate let-control="checkboxControl" let-artifactType="data">
        <mc-checkbox [formControl]="control">{{ artifactType | translateArtifactType }}</mc-checkbox>
    </ng-template>
</div>

<div class="mc-form__row">
    <label class="mc-form__label">
        {{ 'enrichment.Enrichment.AnalyserFormFields.Label.ArtifactSizeLimit' | transloco }}
    </label>

    <mc-form-field class="analyser-form__artifact-size-limit">
        <input
            [formControl]="form.getControl('artifactSizeLimit')"
            mcInput
            integerInput
            type="number"
            step="1"
            autocomplete="off">
        <mc-hint *ngIf="form.hasErrors('artifactSizeLimit')" color="error">
            <div *ngIf="form.hasError('artifactSizeLimit', 'range')">
                {{ invalidArtifactSizeLimitMessage }}
            </div>
        </mc-hint>
    </mc-form-field>
</div>

<div class="mc-form__row">
    <label class="mc-form__label">
        {{ 'enrichment.Enrichment.FormFields.Label.TaskExecutionTimeout' | transloco }}
    </label>

    <mc-form-field
        mcFormFieldWithoutBorders
        cybsiFormFieldWithoutBorders
        class="analyser-form__task-execution-timeout">
        <time-period-field
            [formControl]="form.getControl('taskExecutionTimeout')"
            [periodType]="periodTaskExecutionTimeout"
            (periodTypeChanged)="handleChangePeriodTaskExecutionTimeout($event)"
        ></time-period-field>
        <mc-hint *ngIf="form.hasErrors('taskExecutionTimeout')" color="error">
            <div *ngIf="form.hasError('taskExecutionTimeout', 'range')">
                {{ getInvalidTaskExecutionTimeoutMessage(periodTaskExecutionTimeout) }}
            </div>
        </mc-hint>
    </mc-form-field>
</div>

<div class="mc-form__row">
    <label class="mc-form__label">
        {{ 'enrichment.Enrichment.FormFields.Label.TaskExecutionAttemptsCount' | transloco }}
    </label>

    <mc-form-field class="analyser-form__task-execution-attempts-count">
        <input
            [formControl]="form.getControl('taskExecutionAttemptsCount')"
            mcInput
            integerInput
            type="number"
            step="1"
            autocomplete="off">
            <mc-hint *ngIf="form.hasErrors('taskExecutionAttemptsCount')" color="error">
                <span *ngIf="form.hasError('taskExecutionAttemptsCount', 'range')">
                    {{ invalidTaskAttemptsCountMessage }}
                </span>
            </mc-hint>
    </mc-form-field>
</div>
