import { Component } from '@angular/core';
import { McModalRef } from '@ptsecurity/mosaic/modal';

@Component({
    selector: 'session-expired-modal',
    templateUrl: './session-expired-modal.component.html',
    styleUrls: ['./session-expired-modal.component.scss']
})
export class SessionExpiredModalComponent {
    constructor(private modalRef: McModalRef) {}

    closeModal() {
        this.modalRef.destroy();
    }
}
