import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { LicenseStatus } from '@pt-cybsi/api-interfaces';
import { LicenseMapper } from '@pt-cybsi/domain-core/license';
import { AsyncState } from '@pt-cybsi/shared';

import * as LicenseActions from './license.actions';
import * as LicenseSelectors from './license.selectors';

@Injectable()
export class LicenseFacade {
    isLoading$ = this.store
        .pipe(select(LicenseSelectors.getLicenseLoadingState))
        .pipe(map((state) => state === AsyncState.Loading));

    isLoadingSuccess$ = this.store
        .pipe(select(LicenseSelectors.getLicenseLoadingState))
        .pipe(map((state) => state === AsyncState.Success));

    isLoadingFailure$ = this.store
        .pipe(select(LicenseSelectors.getLicenseLoadingState))
        .pipe(map((state) => state === AsyncState.Failure));

    info$ = this.store.pipe(select(LicenseSelectors.getInfo));

    hasLicense$ = this.info$.pipe(map((info) => info !== null));

    hasActiveLicense$ = combineLatest([this.info$, this.hasLicense$]).pipe(
        map(([info, hasLicense]) => hasLicense && info.status.includes(LicenseStatus.Valid))
    );

    view$ = this.info$.pipe(map((info) => LicenseMapper.toView(info)));

    constructor(private store: Store) {}

    load() {
        this.store.dispatch(LicenseActions.loadLicense());
    }

    update() {
        this.store.dispatch(LicenseActions.updateLicense());
    }

    refresh() {
        this.store.dispatch(LicenseActions.refreshLicense());
    }
}
