export class ConfidenceModel {
    get value() {
        return this.confidence;
    }

    private readonly confidenceRoundFactor = 10;

    constructor(private confidence: number) {}

    static create(confidence: number): ConfidenceModel {
        return new ConfidenceModel(confidence);
    }

    round(accuracy: number = 1): ConfidenceModel {
        const factor = this.confidenceRoundFactor ** accuracy;

        return ConfidenceModel.create(Math.round(this.confidence * factor) / factor);
    }
}
