import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AccountEffects } from './state/account.effects';
import { AccountFacade } from './state/account.facade';
import * as fromAccount from './state/account.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(fromAccount.ACCOUNT_FEATURE_KEY, fromAccount.reducer),
        EffectsModule.forFeature([AccountEffects])
    ],
    providers: [AccountFacade]
})
export class StoreAccountModule {}
