<ng-container *ngIf="empty; else selectedTemplate">
    <i mc-icon="mc-upload-to-cloud_24" [color]="iconColor" class="select-file-field__icon"></i>
    <span>{{ 'common.Common.SelectFileField.Text.DropOrSelect' | transloco | translateCut: 0 }}</span>
    <button type="button" class="select-file-field__select clear-button" mc-link pseudo [disabled]="disabled" (click)="fileInput.click()">{{ 'common.Common.SelectFileField.Text.DropOrSelect' | transloco | translateCut: 1 }}</button>
</ng-container>

<input
    #fileInput
    type="file"
    class="select-file-field__input"
    [id]="inputId"
    [accept]="acceptedFiles"
    (change)="handleChangeInput($event)"
/>

<ng-template #selectedTemplate>
    <i mc-icon="pt-icons-file-empty_16" [color]="iconColor" class="pt-icons select-file-field__icon"></i>
    <span class="select-file-field__selected flex-grow pt-text-overflow" pt-title>{{ value.name }}</span>
    <button
        mc-button
        type="button"
        class="select-file-field__clear mc-button_transparent mc-second"
        [disabled]="disabled"
        (click)="handleResetInput()">
        <i mc-icon="pt-icons-close-circle_16" class="pt-icons "></i>
    </button>
 </ng-template>
