import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class DictionariesItemsNavigationService {
    static routes = {
        view: 'view/:dictionaryItemId'
    };

    readonly root = '/dictionary-items';

    constructor(private router: Router) {}

    goToViewPage(dictionaryItemId: string): void {
        const path = this.getPathOfViewRoute(dictionaryItemId);

        this.router.navigate([path]);
    }

    getPathOfViewRoute(dictionaryItemId: string): string {
        return `${this.root}/${DictionariesItemsNavigationService.routes.view.replace(
            ':dictionaryItemId',
            dictionaryItemId
        )}`;
    }
}
