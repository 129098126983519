import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

import { IDictionaryBrief, StaticDictionaryId } from '@pt-cybsi/api-interfaces';

/**
 * @pipe TranslateDictionaryName
 *
 * @description Converts dictionary to dictionary name
 */
@Pipe({
    name: 'translateDictionaryName'
})
export class TranslateDictionaryNamePipe implements PipeTransform {
    transform(dictionary: IDictionaryBrief, isCollection = false): string {
        switch (dictionary?.uuid) {
            case StaticDictionaryId.MalwareClasses:
                return translate(
                    isCollection
                        ? 'dictionaries.Dictionaries.List.Text.MalwareClasses'
                        : 'dictionaries.Dictionaries.Item.Text.MalwareClass'
                );

            case StaticDictionaryId.MalwareFamilies:
                return translate(
                    isCollection
                        ? 'dictionaries.Dictionaries.List.Text.MalwareFamilies'
                        : 'dictionaries.Dictionaries.Item.Text.MalwareFamily'
                );

            case StaticDictionaryId.DomainNameStatuses:
                return translate(
                    isCollection
                        ? 'dictionaries.Dictionaries.List.Text.DomainNameStatuses'
                        : 'dictionaries.Dictionaries.Item.Text.DomainNameStatus'
                );

            case StaticDictionaryId.IpAddressStatuses:
                return translate(
                    isCollection
                        ? 'dictionaries.Dictionaries.List.Text.IpAddressStatuses'
                        : 'dictionaries.Dictionaries.Item.Text.IpAddressStatus'
                );

            case StaticDictionaryId.Campaigns:
                return translate(
                    isCollection
                        ? 'dictionaries.Dictionaries.List.Text.Campaigns'
                        : 'dictionaries.Dictionaries.Item.Text.Campaign'
                );

            case StaticDictionaryId.ThreatActors:
                return translate(
                    isCollection
                        ? 'dictionaries.Dictionaries.List.Text.ThreatActors'
                        : 'dictionaries.Dictionaries.Item.Text.ThreatActor'
                );

            case StaticDictionaryId.CountryCodes:
                return translate(
                    isCollection
                        ? 'dictionaries.Dictionaries.List.Text.CountryCodes'
                        : 'dictionaries.Dictionaries.Item.Text.CountryCode'
                );

            case StaticDictionaryId.Vulnerabilities:
                return translate(
                    isCollection
                        ? 'dictionaries.Dictionaries.List.Text.Vulnerabilities'
                        : 'dictionaries.Dictionaries.Item.Text.Vulnerability'
                );

            case StaticDictionaryId.Sectors:
                return translate(
                    isCollection
                        ? 'dictionaries.Dictionaries.List.Text.Sectors'
                        : 'dictionaries.Dictionaries.Item.Text.Sector'
                );

            default:
                return dictionary?.name || '';
        }
    }
}
