import { ComponentStore } from '@ngrx/component-store';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { IServerError } from '@pt-cybsi/api-interfaces';
import {
    isAsyncStateFailure,
    isAsyncStateLoading,
    isAsyncStateSuccess,
    isFailureResponse,
    isSuccessResponse,
    toFailureResponse,
    toLoadingResponse,
    toSuccessResponse,
    TResponseWithState
} from '@pt-cybsi/shared';

import { IForecastView } from '../types';

export type TForecastState = TResponseWithState<IForecastView>;

const DEFAULT_STATE: TForecastState = toLoadingResponse(null);

export class ForecastStore extends ComponentStore<TForecastState> {
    loadingState$ = this.select((state) => state.state);
    isLoading$ = this.loadingState$.pipe(map((state) => isAsyncStateLoading(state)));
    isSuccessLoading$ = this.loadingState$.pipe(map((state) => isAsyncStateSuccess(state)));
    isFailureLoading$ = this.loadingState$.pipe(map((state) => isAsyncStateFailure(state)));

    data$ = this.select((state) => (isSuccessResponse(state) ? state.data : null));
    confidence$ = this.data$.pipe(map((data) => data?.confidence));
    facts$ = this.data$.pipe(map((data) => data?.facts));

    loadingError$ = this.select((state) => (isFailureResponse(state) ? state.data : null));

    readonly toLoading = this.updater((state) => ({
        ...state,
        ...toLoadingResponse(null)
    }));

    readonly toSuccessLoading = this.updater((state, data: IForecastView) => ({
        ...state,
        ...toSuccessResponse(data)
    }));

    readonly toFailureLoading = this.updater((state, error: IServerError) => ({
        ...state,
        ...toFailureResponse(error)
    }));

    readonly resetData = this.updater(() => DEFAULT_STATE);

    protected loadingCanceller$ = new Subject<void>();

    constructor() {
        super(DEFAULT_STATE);
    }

    cancelLoading(): void {
        this.loadingCanceller$.next();
    }
}
