import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { EnrichmentTriggerType } from '@pt-cybsi/api-interfaces';
import { createASCSortComparator } from '@pt-cybsi/shared';

@Pipe({
    name: 'translateEnrichmentTriggerType'
})
export class TranslateEnrichmentTriggerTypePipe implements PipeTransform {
    constructor(private translocoService: TranslocoService) {}

    transform(type: EnrichmentTriggerType): string {
        return this.translocoService.translate(`enrichment.Enrichment.TriggerType.Text.${type}`);
    }
}

@Pipe({
    name: 'translateEnrichmentTriggerTypes'
})
export class TranslateEnrichmentTriggerTypesPipe implements PipeTransform {
    private readonly sortComparator = createASCSortComparator<string>((item) => item);

    constructor(private translateEnrichmentTriggerType: TranslateEnrichmentTriggerTypePipe) {}

    transform(types: EnrichmentTriggerType[]): string[] {
        const translatedTypes = types.map((type) => this.translateEnrichmentTriggerType.transform(type));

        return translatedTypes.sort(this.sortComparator);
    }
}
