<mc-select
    #mcSelect
    [ngModel]="value"
    [disabled]="disabled"
    [required]="required"
    [placeholder]="'common.Common.Pseudo.Select.Placeholder' | transloco"
    (valueChange)="handleChangeValue($event)">
    <mc-option
        *ngFor="let level of levels$ | async"
        [value]="level">
        <i class="mc-icon_light" mc-icon [shareLevelIcon]="level"></i>
        {{ level }}
    </mc-option>
    <mc-select-trigger>
        <ng-container *ngIf="value">
            <i class="mc-icon_light" mc-icon [shareLevelIcon]="value"></i>
            {{ value }}
        </ng-container>
    </mc-select-trigger>
</mc-select>
