export enum HashSumType {
    MD5 = 'MD5',
    SHA1 = 'SHA1',
    SHA256 = 'SHA256'
}

export interface IHashSum {
    type: HashSumType;
    value: string;
}
