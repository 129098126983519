import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { IDataSource } from '@pt-cybsi/api-interfaces';
import { DataSourcesFacade } from '@pt-cybsi/store/data-sources';

import { DataSourceMapper } from '../../mappers';
import { SourceViewModel } from '../../models';
import { SourcesNavigationService } from '../../services';

export enum SourcePreviewFormat {
    Text = 'Text',
    Link = 'Link'
}

/**
 * @component SourcePreview
 *
 * @description
 * Display data-source name by rules described in [requirements](https://wiki.ptsecurity.com/pages/viewpage.action?pageId=90192444)
 *
 * @param sourceId - sourceId in dataSourcesStore
 * @param format - format of view from SourcePreviewFormat
 * @param isTypeShown - whether to show the type name, default = true
 *
 * @example
 * ```html
 * <source-preview [sourceId]="sourceId"></source-preview>
 * ```
 */
@Component({
    selector: 'source-preview',
    templateUrl: './source-preview.component.html',
    styleUrls: ['./source-preview.component.scss']
})
export class SourcePreviewComponent implements OnInit {
    @Input() format: SourcePreviewFormat = SourcePreviewFormat.Link;
    @Input() isTypeShown = true;
    @Input() set sourceId(value: string) {
        this.sourceId$.next(value);
    }

    SourcePreviewFormat = SourcePreviewFormat;

    sourceName$: Observable<string>;
    sourceLink$: Observable<string>;

    private sourceId$ = new BehaviorSubject<string>(null);

    constructor(
        private dataSourcesFacade: DataSourcesFacade,
        private sourcesNavigationService: SourcesNavigationService
    ) {}

    ngOnInit() {
        this.sourceName$ = this.sourceId$.pipe(
            filter(Boolean),
            switchMap((sourceId: string) => this.dataSourcesFacade.selectSource(sourceId)),
            map((dataSource: IDataSource) => {
                const sourceView = SourceViewModel.create(DataSourceMapper.toFullView(dataSource));

                if (this.isTypeShown) {
                    return sourceView.getFullName();
                } else {
                    return sourceView.getName();
                }
            })
        );

        this.sourceLink$ = this.sourceId$.pipe(
            map((sourceId: string) => this.sourcesNavigationService.getPathOfSourceViewRoute(sourceId))
        );
    }
}
