import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { LocationService, welcomeLocation } from '@pt-cybsi/core';
import { getBrowserTitle } from '@pt-cybsi/shared';
import { RouterFacade } from '@pt-cybsi/store/router';

@Component({
    selector: 'pt-cyber-security-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    isNavbarVisible$ = this.routerFacade.isNavbarVisible$;

    constructor(
        private location: Location,
        private locationService: LocationService,
        private routerFacade: RouterFacade,
        private titleService: Title
    ) {
        const path = location.path();

        locationService.saveEntryUrl(path);

        if ([welcomeLocation].indexOf(path) === -1) {
            locationService.redirectToWelcomePage();
        }
    }

    ngOnInit() {
        this.routerFacade.pageTitle$.subscribe({
            next: (pageTitle) => {
                const title = pageTitle || getBrowserTitle();

                this.titleService.setTitle(title);
            }
        });
    }
}
