import { IApiItemPreview, ObservableEntityType, ShareLevel } from '../common';
import { IDataSource } from '../data-sources';
import { TObservableEntity } from '../observable';

export enum ArtifactType {
    FileSample = 'FileSample',
    Archive = 'Archive',
    NetworkTraffic = 'NetworkTraffic'
}

export enum ArtifactArchiveType {
    Zip = 'ZIP'
}

export const ARTIFACT_TYPES = Object.values(ArtifactType);

export const ARTIFACT_ARCHIVE_TYPES: ArtifactArchiveType[] = Object.values(ArtifactArchiveType);

export interface IArtifactBase extends IApiItemPreview {
    types: ArtifactType[];
    dataSources: IDataSource[];
    shareLevels: ShareLevel[];
    content: IArtifactContent;

    // Deprecated
    type?: ArtifactType;
    // Deprecated
    dataSourceUUID?: string;
    // Deprecated
    fileName?: string;
    // Deprecated
    registeredAt?: string;
}

export interface IArtifact extends IArtifactBase {
    fileNames: string[];
    entities: TObservableEntity<ObservableEntityType.File>[];
}

export interface IArtifactContent {
    url: string;
    size: number;
    md5Hash?: string;
    sha1Hash?: string;
    sha256Hash: string;
    formatDescription?: string;
}
