import { CommonModule } from '@angular/common';
import { NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { McSelectModule } from '@ptsecurity/mosaic/select';

import { SharedModule } from '@pt-cybsi/shared';

import {
    ArtifactTypesFilterComponent,
    ArtifactTypesSelectComponent,
    ArtifactTypesSelectMcValidateDirective
} from './components';
import { TranslateArtifactTypePipe, TranslateArtifactTypesPipe } from './pipes';

// prettier-ignore
const modules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    McSelectModule,
    SharedModule,
];
// prettier-ignore
const components = [
    ArtifactTypesFilterComponent,
    ArtifactTypesSelectComponent,
];

// prettier-ignore
const directives = [
    ArtifactTypesSelectMcValidateDirective
];

const services = [] as Provider[];

// prettier-ignore
const pipes = [
    TranslateArtifactTypePipe,
    TranslateArtifactTypesPipe
];

@NgModule({
    imports: [...modules],
    declarations: [...components, ...directives, ...pipes],
    providers: [...services, ...pipes],
    exports: [...components, ...directives, ...pipes]
})
export class DomainCoreArtifactsModule {}
