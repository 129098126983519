import { IApiItemPreview, ShareLevel } from '../common';
import { IDataSource } from '../data-sources';
import { TObservationPreview } from '../observations';

export interface IReport<TObservation extends TObservationPreview = TObservationPreview> {
    uuid: string;
    url?: string;
    dataSource: IDataSource;
    reporter: IDataSource;
    externalID: string;
    title: string;
    analyzedArtifactUUID: string;
    description: string;
    externalRefs: string[];
    createdAt: string;
    registeredAt: string;
    updatedAt: string;
    rawDataUUID: string;
    publishedAt?: string;
    shareLevel: ShareLevel;
    labels: string[];
    observations: TObservation[];
    artifacts: IApiItemPreview[];
}

export type TReportBrief = Omit<IReport, 'observations' | 'artifacts'>;

export interface ISimilarReport {
    report: TReportBrief;
    correlation: { similarity: number };
}
