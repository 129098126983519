import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'flag-icon',
    templateUrl: './flag-icon.component.html'
})
export class FlagIconComponent implements OnInit {
    @Input() countryCode: string;

    get flagCode(): string {
        switch (this.countryCode) {
            case 'EN':
                return 'GB';

            default:
                return this.countryCode;
        }
    }

    flagClass: string;

    ngOnInit(): void {
        this.flagClass = `pt-flag-icon_${this.flagCode.toLowerCase()}_16`;
    }
}
