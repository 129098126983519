import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IDataSource, IPaginationParams } from '@pt-cybsi/api-interfaces';

import * as DataSourcesActions from './data-sources.actions';
import * as DataSourcesSelectors from './data-sources.selectors';

@Injectable()
export class DataSourcesFacade {
    loaded$ = this.store.pipe(select(DataSourcesSelectors.getDataSourcesLoaded));
    allDataSources$ = this.store.pipe(select(DataSourcesSelectors.getAllDataSources));
    currentCursor$ = this.store.pipe(select(DataSourcesSelectors.getDataSourcesCursor));

    constructor(private store: Store) {}

    selectSource = (sourceId: string): Observable<IDataSource> =>
        this.store.select(DataSourcesSelectors.getSourceById(sourceId));
    selectSources = (sourcesIds: string[]): Observable<IDataSource[]> =>
        this.store.select(DataSourcesSelectors.getSourcesByIds(sourcesIds));

    fetchDataSources(params?: IPaginationParams) {
        this.store.dispatch(DataSourcesActions.fetchDataSources({ params }));
    }

    syncDataSources(dataSources: IDataSource[]) {
        this.store.dispatch(DataSourcesActions.syncDataSources({ dataSources }));
    }
}
