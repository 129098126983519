import { Directive, Input } from '@angular/core';
import { McFormField } from '@ptsecurity/mosaic/form-field';

import { AutofocusFirstFieldDirective } from '../autofocus-first-field/autofocus-first-field.directive';

@Directive({
    selector: '[autoFocusedField]'
})
export class AutofocusedFieldDirective {
    @Input('autoFocusedField')
    set formField(formField: McFormField) {
        this._formField = formField;
        this.autofocusFirstFieldDirective.addFormField(formField);
    }

    private _formField: McFormField;

    constructor(private autofocusFirstFieldDirective: AutofocusFirstFieldDirective) {}
}
