import { Injectable, Provider } from '@angular/core';

import { IFormRepository, ValidateFormUseCase } from '@pt-cybsi/shared';

import { ExternalDBModel } from '../../models';
import { ExternalDBFormRepository, ExternalDBFormRepositoryProvider } from '../../repositories';

@Injectable()
export class ValidateExternalDBUseCase extends ValidateFormUseCase<ExternalDBModel> {
    constructor(repository: IFormRepository<ExternalDBModel>) {
        super(repository);
    }
}

export const ValidateExternalDBUseCaseProvider: Provider = [
    ExternalDBFormRepositoryProvider,
    {
        provide: ValidateExternalDBUseCase,
        useFactory: (repository: ExternalDBFormRepository) => new ValidateExternalDBUseCase(repository),
        deps: [ExternalDBFormRepository]
    }
];
