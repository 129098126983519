import { ElementRef, Injectable } from '@angular/core';

@Injectable()
export class ScrollToFormErrorService {
    constructor(private elementRef: ElementRef<HTMLElement>) {}

    scrollToError(): void {
        const errorAlertOrField = this.getErrorAlertOrField();

        if (errorAlertOrField) {
            errorAlertOrField.scrollIntoView();
        }
    }

    private getErrorAlertOrField(): HTMLElement {
        return this.elementRef.nativeElement.querySelector('.mc-alert_error, .ng-invalid:not(form)');
    }
}
