export const SOURCE_TYPE_MIN_LENGTH = 1;
export const SOURCE_TYPE_MAX_LENGTH = 250;

export const validateSourceTypeNameLength = (name: string) =>
    name === undefined ||
    name === null ||
    name === '' ||
    (name.length >= SOURCE_TYPE_MIN_LENGTH && name.length <= SOURCE_TYPE_MAX_LENGTH);

export const SOURCE_TYPE_SHORT_NAME_MIN_LENGTH = 1;
export const SOURCE_TYPE_SHORT_NAME_MAX_LENGTH = 250;

export const validateSourceTypeShortNameLength = (name: string) =>
    name === undefined ||
    name === null ||
    name === '' ||
    (name.length >= SOURCE_TYPE_SHORT_NAME_MIN_LENGTH && name.length <= SOURCE_TYPE_SHORT_NAME_MAX_LENGTH);

export const validateSourceTypeShortNameFormat = (name: string) =>
    name === undefined || name === null || /^\w+$/.test(name);
