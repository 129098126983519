import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import isEmpty from 'lodash/isEmpty';

import { IAbsoluteDataRangeQueryParams, IRelativeDataRangeQueryParams } from '@pt-cybsi/shared';

export type TDashboardQueryParams = Partial<IRelativeDataRangeQueryParams & IAbsoluteDataRangeQueryParams>;

@Injectable({ providedIn: 'root' })
export class StatisticsNavigationService {
    static routes = {
        dashboard: ''
    };

    readonly root = '/statistics';

    constructor(private router: Router) {}

    goToDashboardPage(queryParams?: TDashboardQueryParams): void {
        const path = this.getPathOfDashboardRoute();
        const hasQueryParams = !isEmpty(queryParams);

        const extras: NavigationExtras = hasQueryParams ? { queryParams } : undefined;

        this.router.navigate([path], extras);
    }

    getPathOfDashboardRoute(): string {
        return `${this.root}/${StatisticsNavigationService.routes.dashboard}`;
    }
}
