import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DataSourcesEffects } from './state/data-sources.effects';
import { DataSourcesFacade } from './state/data-sources.facade';
import * as fromDataSources from './state/data-sources.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(fromDataSources.DATA_SOURCES_FEATURE_KEY, fromDataSources.reducer),
        EffectsModule.forFeature([DataSourcesEffects])
    ],
    providers: [DataSourcesFacade]
})
export class StoreDataSourcesModule {}
