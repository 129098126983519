export const MIN_TASK_EXECUTION_TIMEOUT = 1000;
export const MAX_TASK_EXECUTION_TIMEOUT = 864000000;

export const validateTaskExecutionTimeout = (milliseconds: number) =>
    milliseconds === null ||
    milliseconds === undefined ||
    (milliseconds >= MIN_TASK_EXECUTION_TIMEOUT && milliseconds <= MAX_TASK_EXECUTION_TIMEOUT);

export const MAX_TASK_ATTEMPTS_COUNT = 1000;

// prettier-ignore
export const validateTaskExecutionAttemptsCount = (count: number) =>
    count === null ||
    count === undefined ||
    count >= 1 &&
    count <= MAX_TASK_ATTEMPTS_COUNT;
