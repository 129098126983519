<lazy-grid
    [agGridOptions]="agGridOptions"
    [columns]="columns"
    [data]="items"
    [state]="state"
    [errorTitle]="errorTitle"
    (loadMore)="loadMore.emit()">
</lazy-grid>

<ng-template #dictionaryItemCell let-data="params.data">
    <div class="dictionary-items-grid__dictionary-item-cell">
        <a mc-link pt-title class="mc-link_overflowed" [routerLink]="getDictionaryItemLink(data.id)">
            <span class="mc-link__text">{{ data.key }}</span>
        </a>
    </div>
</ng-template>
