import { Pipe, PipeTransform } from '@angular/core';

const defaultSortFn = (a: unknown, b: unknown) => {
    if (a < b) {
        return -1;
    }

    if (a > b) {
        return 1;
    }

    return 0;
};

@Pipe({
    name: 'sort'
})
export class SortPipe implements PipeTransform {
    transform(data: unknown[], sortFn: (a: unknown, b: unknown) => -1 | 0 | 1 = defaultSortFn): unknown[] {
        if (Array.isArray(data)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            return [...data].sort(sortFn);
        }

        return data;
    }
}
