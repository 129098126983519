import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, switchMapTo, tap, withLatestFrom } from 'rxjs/operators';

import { DataSourcesApiService } from '@pt-cybsi/api';
import { IDataSource } from '@pt-cybsi/api-interfaces';
import { PermissionsService, RootPermissionsService } from '@pt-cybsi/domain-core/account';
import { AllowedActionType, LegacyCommunicationService } from '@pt-cybsi/legacy-application';

import * as DataSourcesActions from './data-sources.actions';
import { DataSourcesFacade } from './data-sources.facade';

@Injectable()
export class DataSourcesEffects {
    fetchDataSources$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DataSourcesActions.fetchDataSources),
            switchMap((action) => {
                const hasViewDataSourcesPermissions = this.permissionsService.hasAllPermissions(
                    RootPermissionsService.viewSourcesPermissions
                );

                if (hasViewDataSourcesPermissions) {
                    return this.dataSourcesApiService.getAllSources(action.params).pipe(
                        map((response) => DataSourcesActions.fetchDataSourcesSuccess({ response })),
                        catchError(() => of(DataSourcesActions.fetchDataSourcesFailure))
                    );
                } else {
                    return of(
                        DataSourcesActions.fetchDataSourcesSuccess({ response: { currentCursor: '', fullList: [] } })
                    );
                }
            })
        )
    );

    updateLegacySources$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DataSourcesActions.syncDataSources),
                switchMapTo(this.dataSourcesFacade.allDataSources$),
                withLatestFrom(this.dataSourcesFacade.currentCursor$),
                tap(([dataSources, currentCursor]: [IDataSource[], string]) => {
                    this.legacyCommunicationService.sendDispatchActionMessageToChild({
                        type: AllowedActionType.SetDataSources,
                        storeName: 'data-sources',
                        apiResponse: {
                            data: dataSources,
                            currentCursor
                        }
                    });
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private dataSourcesApiService: DataSourcesApiService,
        private dataSourcesFacade: DataSourcesFacade,
        private legacyCommunicationService: LegacyCommunicationService,
        private permissionsService: PermissionsService
    ) {}
}
