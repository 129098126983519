<mc-select
    #mcSelect
    [multiple]="multiple"
    [ngModel]="value"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [required]="required"
    [hiddenItemsTextFormatter]="selectHiddenItemsTextFormatter"

    (focus)="handleFocus()"
    (valueChange)="handleChangeValue($event)">
    <mc-option
        *ngFor="let type of artifactTypes"
        [value]="type">
        {{ type | translateArtifactType }}
    </mc-option>
    <mc-cleaner #mcSelectCleaner></mc-cleaner>
</mc-select>
