import groupBy from 'lodash/groupBy';

import { IDataSource } from '@pt-cybsi/api-interfaces';

export interface ISourceTreeNode {
    children?: ISourceTreeNode[];
    name: string;
    id?: string;
}

export interface ISourceFlatTreeNode {
    id: string;
    name: string;
    level: number;
    expandable: boolean;
    parent: ISourceTreeNode;
}

export function buildSourcesTree(sources: IDataSource[]): ISourceTreeNode[] {
    const sourcesByTypeMap = groupBy(sources, (source) => source.type.longName);
    const data: ISourceTreeNode[] = [];

    Object.keys(sourcesByTypeMap).forEach((sourceTypeName) => {
        data.push({
            name: sourceTypeName,
            children: sourcesByTypeMap[sourceTypeName].map((source) => ({
                id: source.uuid,
                name: source.longName
            }))
        });
    });

    return data;
}

export function transformer(node: ISourceTreeNode, level: number, parent: ISourceFlatTreeNode): ISourceFlatTreeNode {
    return {
        id: node.id,
        name: node.name,
        level,
        parent,
        expandable: !!node.children
    };
}

export function getLevel(node: ISourceFlatTreeNode): number {
    return node.level;
}

export function isExpandable(node: ISourceFlatTreeNode): boolean {
    return node.expandable;
}

export function getChildren(node: ISourceTreeNode): ISourceTreeNode[] {
    return node.children;
}

export function getValue(node: ISourceTreeNode): string {
    return node.id;
}

export function getViewValue(node: ISourceTreeNode): string {
    return node.name;
}
