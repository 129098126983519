import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';

import {
    ArtifactType,
    IEditableResource,
    IEnrichmentAnalyzer,
    IEnrichmentAnalyzerCreateParams,
    IEnrichmentAnalyzerUpdateParams
} from '@pt-cybsi/api-interfaces';
import { SourceModel } from '@pt-cybsi/domain-core/sources';
import { TFormData } from '@pt-cybsi/shared';

import { AnalyserModel } from '../models';

const milliseconds = 1000;

export class AnalyserModelMapper {
    static toFormData(model: AnalyserModel): TAnalyserFormData {
        return { id: model.uid, ...model.props };
    }

    static fromFormData(data: TAnalyserFormData): AnalyserModel {
        return AnalyserModel.create({ ...data }, data.id);
    }

    static fromEditableAnalyser(analyser: IEditableResource<IEnrichmentAnalyzer>): AnalyserModel {
        const { eTag, data } = analyser;

        return AnalyserModel.create({
            serverId: data.uuid,
            eTag,
            sourceId: null,
            artifactTypes: data.artifactTypes,
            artifactSizeLimit: data.artifactSizeLimit,
            dashboardURL: data.dashboardURL,
            taskExecutionTimeout: data.taskExecutionTimeout ? data.taskExecutionTimeout * milliseconds : null,
            taskExecutionAttemptsCount: data.taskExecutionAttemptsCount
        });
    }

    static toRegisterParams(analyserModel: AnalyserModel, sourceModel: SourceModel): IEnrichmentAnalyzerCreateParams {
        const { artifactTypes, artifactSizeLimit, dashboardURL, taskExecutionTimeout, taskExecutionAttemptsCount } =
            analyserModel.props;

        const sourceProps = sourceModel.props;

        return {
            dataSourceUUID: sourceProps.serverId,
            artifactTypes,
            ...(isEmpty(dashboardURL) ? {} : { dashboardURL }),
            ...(isNil(artifactSizeLimit) ? {} : { artifactSizeLimit }),
            ...(isNil(taskExecutionTimeout) ? {} : { taskExecutionTimeout: taskExecutionTimeout / milliseconds }),
            ...(isNil(taskExecutionAttemptsCount) ? {} : { taskExecutionAttemptsCount })
        };
    }

    static toUpdateParams(analyserModel: AnalyserModel): IEnrichmentAnalyzerUpdateParams {
        const { artifactTypes, artifactSizeLimit, dashboardURL, taskExecutionTimeout, taskExecutionAttemptsCount } =
            analyserModel.props;

        return {
            ...(isUndefined(dashboardURL) ? {} : { dashboardURL: dashboardURL || null }),
            ...(isUndefined(artifactTypes) ? {} : { artifactTypes }),
            ...(isUndefined(artifactSizeLimit) ? {} : { artifactSizeLimit: artifactSizeLimit || null }),
            ...(isUndefined(taskExecutionTimeout)
                ? {}
                : { taskExecutionTimeout: taskExecutionTimeout / milliseconds || null }),
            ...(isUndefined(taskExecutionAttemptsCount)
                ? {}
                : { taskExecutionAttemptsCount: taskExecutionAttemptsCount || null })
        };
    }
}

export type TAnalyserFormData = TFormData<{
    serverId: string;
    eTag: string;
    sourceId: string;
    artifactTypes: ArtifactType[];
    artifactSizeLimit: number;
    dashboardURL: string;
    taskExecutionTimeout: number;
    taskExecutionAttemptsCount: number;
}>;
