import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { McModalRef } from '@ptsecurity/mosaic/modal';
import isEmpty from 'lodash/isEmpty';
import { Observable, throwError } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import { UsersApiService } from '@pt-cybsi/api';
import { DefaultErrorCodes, HttpStatus, IServerError } from '@pt-cybsi/api-interfaces';
import { DestroyService, FormMode, FormStateValue, ScrollToFormErrorService } from '@pt-cybsi/shared';

import { PasswordFormBuilder, PasswordFormViewModel } from '../../forms';

@Component({
    selector: 'password-modal',
    templateUrl: './password-modal.component.html',
    styleUrls: ['./password-modal.component.scss'],
    providers: [PasswordFormBuilder, ScrollToFormErrorService, DestroyService]
})
export class PasswordModalComponent implements OnInit {
    @Input() userId: string;
    @Input() eTag: string;

    form: PasswordFormViewModel;
    isCurrentUser: boolean;

    get formState(): FormStateValue {
        return this.form.state;
    }

    get hasRequiredEmptyFields(): boolean {
        return (
            (this.form.isVisibleControl('oldPassword') && isEmpty(this.form.getControl('oldPassword').value)) ||
            (this.form.isVisibleControl('newPassword') && isEmpty(this.form.getControl('newPassword').value))
        );
    }

    get isSubmitDisabled() {
        return this.form.isSending || this.form.isSaved || this.hasRequiredEmptyFields;
    }

    get isChangedError(): string {
        switch (this.form.savingError?.code) {
            case DefaultErrorCodes.ACTION_FORBIDDEN:
                return this.translocoService.translate('users.Users.PasswordModal.Error.Forbidden');
            case HttpStatus.PRECONDITION_FAILED:
                return this.translocoService.translate('users.Users.Edit.Error.AlreadyChanged');
            default:
                return this.translocoService.translate('users.Users.PasswordModal.Error.Default');
        }
    }

    constructor(
        private passwordFormBuilder: PasswordFormBuilder,
        private usersApiService: UsersApiService,
        private modalRef: McModalRef,
        private changeDetectorRef: ChangeDetectorRef,
        private translocoService: TranslocoService,
        private scrollToFormErrorService: ScrollToFormErrorService,
        private destroyed$: DestroyService
    ) {}

    ngOnInit() {
        this.isCurrentUser = !this.userId;

        this.initForm();
    }

    initForm(): void {
        this.form = this.passwordFormBuilder.build({
            mode: FormMode.Edit,
            state: FormStateValue.ReadyForInput,
            initialData: null,
            currentData: null,
            savingError: null
        });

        if (!this.isCurrentUser) {
            this.form.hideControls(['oldPassword']);
        }
    }

    closeModal(isSubmit: boolean = false): void {
        this.modalRef.close(isSubmit);
    }

    handleSubmit(): void {
        this.form.updateSavingError(null);
        this.form.updateState(FormStateValue.Validating);

        this.form
            .validate()
            .pipe(
                switchMap((isValid) => {
                    if (isValid) {
                        this.form.updateState(FormStateValue.ReadyForSave);
                        this.form.updateState(FormStateValue.Saving);

                        return this.updateUser();
                    } else {
                        this.form.updateState(FormStateValue.ValidatingFailure);

                        return throwError(null);
                    }
                }),
                take(1),
                takeUntil(this.destroyed$)
            )
            .subscribe({
                next: () => {
                    this.form.updateState(FormStateValue.Saved);
                    this.closeModal(true);
                },
                error: (errorResponse: HttpErrorResponse) => {
                    const error = errorResponse?.error as unknown;

                    if (error) {
                        this.form.updateState(FormStateValue.SavingFailure);
                        this.form.updateSavingError(error as IServerError);

                        setTimeout(() => this.scrollToFormErrorService.scrollToError());
                    }

                    this.changeDetectorRef.detectChanges();
                }
            });
    }

    private updateUser(): Observable<void> {
        if (this.isCurrentUser) {
            return this.usersApiService.updateCurrentUserPassword({
                oldPassword: this.form.currentData.oldPassword,
                newPassword: this.form.currentData.newPassword
            });
        }

        return this.usersApiService.updateUser(this.userId, this.eTag, { password: this.form.currentData.newPassword });
    }
}
