import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

import { RelatedThreatCategory } from '@pt-cybsi/api-interfaces';

/**
 * @pipe TranslateRelatedThreatCategoryValue
 *
 * @description Converts RelatedThreatCategory value to localized string
 */
@Pipe({
    name: 'translateRelatedThreatCategoryValue'
})
export class TranslateRelatedThreatCategoryValuePipe implements PipeTransform {
    transform(value: RelatedThreatCategory): string {
        return translate(
            value
                ? `entities.ObservableEntities.RelatedThreatCategory.Text.${value}`
                : 'entities.ObservableEntities.RelatedThreatCategory.Text.Unknown'
        );
    }
}
