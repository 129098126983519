import { Component, ContentChild, Directive, Input, ViewEncapsulation } from '@angular/core';

@Directive({ selector: '[progress-spinner]' })
export class ProgressSpinnerDirective {}

@Component({
    selector: 'progress-container,[progress-container]',
    templateUrl: './progress-container.component.html',
    styleUrls: ['./progress-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProgressContainerComponent {
    @ContentChild(ProgressSpinnerDirective, { static: true }) progressSpinner;

    @Input() loadingFlag = false;
    @Input() message: string;
}
