<div *ngIf="errorCode" class="error-view layout-column flex layout-align-start-center">
    <info-message class="error-view__content" [title]="errorTitleTranslationKey | transloco">
        <span *ngIf="hasDescription">
            <ng-container [ngSwitch]="errorDescriptionKey">
                <ng-container *ngSwitchCase="'Default'">
                    {{ 'errors.Errors.ErrorView.Text.Default' | transloco | translateCut:0 }}
                    <a id="error-page-refresh-button"
                       mc-link
                       class="mc-link mc-link_pseudo mc-text-only"
                       (click)="refreshPage()">
                        {{ 'errors.Errors.ErrorView.Text.Default' | transloco | translateCut:1 }}
                    </a>
                    {{ 'errors.Errors.ErrorView.Text.Default' | transloco | translateCut:2 }}
                </ng-container>
                <ng-container *ngSwitchCase="'RetryOrContactAdmin'">
                    <a id="error-page-retry-button"
                       mc-link
                       class="mc-link mc-link_pseudo mc-text-only"
                       (click)="retrySignIn()">
                        {{ 'errors.Errors.ErrorView.Text.RetryOrContactAdmin' | transloco | translateCut:0 }}
                    </a>
                    {{ 'errors.Errors.ErrorView.Text.RetryOrContactAdmin' | transloco | translateCut:1 }}
                </ng-container>
                <ng-container *ngSwitchCase="'RefreshOrContactAdmin'">
                    <a id="error-page-retry-button"
                       mc-link
                       class="mc-link mc-link_pseudo mc-text-only"
                       (click)="refreshPage()">
                        {{ 'errors.Errors.ErrorView.Text.RetryOrContactAdmin' | transloco | translateCut:0 }}
                    </a>
                    {{ 'errors.Errors.ErrorView.Text.RetryOrContactAdmin' | transloco | translateCut:1 }}
                </ng-container>
                <ng-container *ngSwitchCase="'ContactAdmin'">
                    {{ 'errors.Errors.ErrorView.Text.ContactAdmin' | transloco }}
                </ng-container>
                <ng-container *ngSwitchCase="'Refresh'">
                    {{ 'errors.Errors.ErrorView.Text.Refresh' | transloco | translateCut:0 }}
                    <a id="error-page-refresh-button"
                       mc-link
                       class="mc-link mc-link_pseudo mc-text-only"
                       (click)="refreshPage()">
                        {{ 'errors.Errors.ErrorView.Text.Refresh' | transloco | translateCut:1 }}
                    </a>
                    {{ 'errors.Errors.ErrorView.Text.Refresh' | transloco | translateCut:2 }}
                </ng-container>
            </ng-container>
        </span>
    </info-message>
</div>
