import { createAction, props } from '@ngrx/store';

import { IClientConfig } from '@pt-cybsi/api-interfaces';

export const fetchClientConfig = createAction('[Client Config] Fetch Config');

export const fetchClientConfigSuccess = createAction(
    '[Client Config] Fetch Config - Success',
    props<{ config: IClientConfig }>()
);

export const fetchClientConfigFailure = createAction('[Client Config] Fetch Config - Failure');
