import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, ROUTER_REQUEST, RouterNavigationAction, RouterNavigationPayload } from '@ngrx/router-store';
import { McModalService } from '@ptsecurity/mosaic/modal';
import { McSidepanelService } from '@ptsecurity/mosaic/sidepanel';
import { map, tap, withLatestFrom } from 'rxjs/operators';

import { welcomeLocation } from '@pt-cybsi/core';
import { AccountFacade } from '@pt-cybsi/store/account/facade';

import * as RouterActions from './router.actions';
import { RouterFacade } from './router.facade';
import { IMergedRouterState } from './router.models';

@Injectable()
export class RouterEffects {
    setLegacyMode$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                map((action: RouterNavigationAction<IMergedRouterState>) => action.payload),
                withLatestFrom(this.accountFacade.hasToken$, this.routerFacade.legacyUrl$),
                tap(([payload, hasToken, url]) => {
                    const isLegacyMode = hasToken ? this.calculateLegacyMode(payload) : false;

                    this.routerFacade.setIsLegacyMode(isLegacyMode);

                    if (isLegacyMode && payload.event.url !== url) {
                        this.routerFacade.setLegacyUrl(payload.event.url);
                    }
                })
            ),
        { dispatch: false }
    );

    setNavbarInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_REQUEST),
            map((action: RouterNavigationAction<IMergedRouterState>) => action.payload),
            map((payload) =>
                RouterActions.setIsNavbarVisible({ isNavbarVisible: payload.event.url !== welcomeLocation })
            )
        )
    );

    closeSidepanels$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                tap(() => {
                    if (this.sidepanelService.openedSidepanels?.length) {
                        this.sidepanelService.closeAll();
                    }
                })
            ),
        { dispatch: false }
    );

    closeModals$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ROUTER_REQUEST),
                tap(() => {
                    if (this.modalService.openModals?.length) {
                        this.modalService.closeAll();
                    }
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private routerFacade: RouterFacade,
        private accountFacade: AccountFacade,
        private modalService: McModalService,
        private sidepanelService: McSidepanelService
    ) {}

    private calculateLegacyMode(payload: RouterNavigationPayload<IMergedRouterState>): boolean {
        return !!payload.routerState?.data?.isLegacyRoute;
    }
}
