import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ColDef } from 'ag-grid-community';

import { LazyDataState, TemplateCellComponent } from '@pt-cybsi/shared';

import { DictionariesItemsNavigationService } from '../../services';

export interface IDictionaryItemsGridItem {
    id: string;
    key: string;
}

@Component({
    selector: 'dictionary-items-grid',
    templateUrl: './dictionary-items-grid.component.html',
    styleUrls: ['./dictionary-items-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DictionaryItemsGridComponent implements AfterViewInit {
    @Input() state: LazyDataState;

    @Input() items: IDictionaryItemsGridItem[];

    @Input() errorTitle: string;

    @Output() loadMore = new EventEmitter<void>();

    @ViewChild('dictionaryItemCell') dictionaryItemCellTemplate: TemplateRef<unknown>;

    columns: ColDef[] = [];
    agGridOptions = {
        getRowNodeId: (nodeData: IDictionaryItemsGridItem) => nodeData.id
    };

    constructor(
        private dictionariesItemsNavigationService: DictionariesItemsNavigationService,
        private translocoService: TranslocoService
    ) {}

    ngAfterViewInit(): void {
        this.columns = this.getGridColumnDefs();
    }

    getDictionaryItemLink(id: string) {
        return this.dictionariesItemsNavigationService.getPathOfViewRoute(id);
    }

    private getGridColumnDefs(): ColDef[] {
        return [
            {
                field: 'dictionary-item',
                headerName: this.translocoService.translate('dictionaries.Dictionaries.Items.Text.DictionaryItems'),
                flex: 1,
                cellClass: 'layout-padding-left-3xl',
                headerClass: 'layout-padding-left-3xl',
                cellRendererFramework: TemplateCellComponent,
                cellRendererParams: {
                    template: this.dictionaryItemCellTemplate
                }
            }
        ];
    }
}
