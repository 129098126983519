import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { IAuthProvider, IUserFullInfo } from '@pt-cybsi/api-interfaces';

export const refreshToken = createAction('[Account] Refresh Token');
export const setRefreshTokenError = createAction(
    '[Account] Set Refresh Token Error',
    props<{ response: HttpErrorResponse }>()
);
export const setToken = createAction('[Account] Set Token', props<{ token: string }>());
export const resetToken = createAction('[Account] Reset Token');

export const fetchUser = createAction('[Account] Fetch User');
export const fetchUserSuccess = createAction('[Account] Fetch User - Success', props<{ user: IUserFullInfo }>());
export const fetchUserFailure = createAction('[Account] Fetch User - Failure');
export const resetUser = createAction('[Account] Reset User');

export const fetchAuthProviders = createAction('[Account] Fetch Auth Providers');
export const fetchAuthProvidersSuccess = createAction(
    '[Account] Fetch Auth Providers - Success',
    props<{ providers: IAuthProvider[] }>()
);
export const fetchAuthProvidersFailure = createAction('[Account] Fetch Auth Providers - Failure');
