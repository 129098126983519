import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import flatten from 'lodash/flatten';
import { takeUntil } from 'rxjs/operators';

import { DestroyService } from '@pt-cybsi/shared';
import { AccountFacade } from '@pt-cybsi/store/account';

import { PermissionsService } from '../services';

export enum PermissionsCheckMode {
    All = 'all',
    Any = 'any'
}

@Directive({
    selector: '[hasPermissions]',
    providers: [DestroyService]
})
export class HasPermissionsDirective implements OnInit {
    permissions: string[] | string[][] = [];
    permissionsCheckMode = PermissionsCheckMode.All;
    noPermissionsTemplate: TemplateRef<unknown>;

    @Input()
    set hasPermissions(permissions: string[] | string[][]) {
        this.permissions = permissions;
        this.updateView();
    }

    @Input()
    set hasPermissionsElse(templateRef: TemplateRef<unknown>) {
        this.noPermissionsTemplate = templateRef;
    }

    @Input()
    set hasPermissionsCheckMode(checkMode: PermissionsCheckMode) {
        this.permissionsCheckMode = checkMode;
    }

    private isHidden = true;

    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<unknown>,
        private viewContainerRef: ViewContainerRef,
        private accountFacade: AccountFacade,
        private permissionsService: PermissionsService,
        private destroyed$: DestroyService
    ) {}

    ngOnInit() {
        this.accountFacade.user$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.updateView();
        });
    }

    private checkPermissions(): boolean {
        if (this.permissionsCheckMode === PermissionsCheckMode.Any) {
            return this.permissionsService.hasAnyPermissions(this.permissions);
        }

        return this.permissionsService.hasAllPermissions(flatten(this.permissions));
    }

    private updateView() {
        if (this.checkPermissions()) {
            if (this.isHidden) {
                this.isHidden = false;

                this.viewContainerRef.createEmbeddedView(this.templateRef);
            }
        } else {
            this.isHidden = true;

            if (this.noPermissionsTemplate === undefined) {
                this.viewContainerRef.clear();
            } else {
                this.viewContainerRef.createEmbeddedView(this.noPermissionsTemplate);
            }
        }
    }
}
