import { IPaginationParams, ObservableEntityType } from '../common';

import { AggregateSection } from './aggregate-section';
import { TObservableEntityKey, TObservableEntityKeyType } from './entity';
import { RelationDirection, RelationType } from './relation';

export interface ICreateObservableEntityParams {
    type: ObservableEntityType;
    keys: TObservableEntityKey[];
}

export interface ICanonicalKeyRequestParams {
    entityType: ObservableEntityType;
    keyType: TObservableEntityKeyType;
    key: string;
}

export interface IAggregatesRequestParams extends IPaginationParams {
    section?: AggregateSection[];
    uuid?: string[];
    type?: ObservableEntityType;
    keyType?: TObservableEntityKeyType;
    key?: string;
    forecastAt?: string;
}

export interface IAggregateRequestParams {
    section?: AggregateSection[];
    forecastAt?: string;
    valuableFacts?: boolean;
}

export interface IAttributeForecastRequestParams {
    forecastAt?: string;
}

export interface IRelationsRequestParams extends IPaginationParams {
    relatedEntityType?: ObservableEntityType[];
    direction?: RelationDirection;
    kind?: RelationType[];
    confidenceThreshold?: number;
    forecastAt?: string;
}

export interface IRelationForecastRequestParams {
    valuableFacts?: boolean;
    forecastAt?: string;
}

export interface IGetObservableEntityLabelsParams extends IPaginationParams {
    prefix: string;
}

export type TAddObservableEntityLabelsParams = string[];

export interface IRemoveObservableEntityLabelsParams {
    label: string[];
}
