import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { IServerError } from '@pt-cybsi/api-interfaces';

import { TFormConfiguration, TFormData, TFormViewModel } from '../forms';
import { IFormViewModelBuilder } from '../forms/form-view-model.builder';
import { FormStateValue } from '../models';
import { FormMode } from '../types';

/**
 * @store FormsStore
 *
 * @description
 * Abstract class for reactive stores that store concrete types of FormViewModel.
 * Provides methods for getting, adding, updating, and removing forms and notifying subscribers about changes.
 *
 * After extends required implement formViewModelBuilder field to provide a form view model factory
 */
export abstract class FormsStore<FormData extends TFormData<unknown>> {
    get forms$(): Observable<ReadonlyMap<string, TFormViewModel<FormData>>> {
        return this._forms$.asObservable();
    }

    protected forms: Map<string, TFormViewModel<FormData>> = new Map();

    protected abstract creationFormViewModelBuilder: IFormViewModelBuilder<FormData, TFormConfiguration<FormData>>;
    protected abstract editFormViewModelBuilder: IFormViewModelBuilder<FormData, TFormConfiguration<FormData>>;

    private _forms$: BehaviorSubject<ReadonlyMap<string, TFormViewModel<FormData>>> = new BehaviorSubject(this.forms);

    getForm<T extends TFormViewModel<FormData>>(id: string): T {
        return this.forms.get(id) as T;
    }

    addForm(mode: FormMode, data: FormData): void {
        const formViewModel = this.buildFormViewModel(mode, data);

        this.forms.set(data.id, formViewModel);

        this.notify();
    }

    deleteForm(id: string): void {
        this.forms.delete(id);

        this.notify();
    }

    getFormMode(id: string): FormMode {
        return this.forms.get(id).mode;
    }

    getState(id: string): FormStateValue {
        return this.forms.get(id).state;
    }

    updateState(id: string, state: FormStateValue): void {
        const currentForm = this.forms.get(id);

        currentForm.updateState(state);

        this.notify();
    }

    getInitialData(id: string): FormData {
        return this.forms.get(id).initialData;
    }

    getCurrentData(id: string): FormData {
        return this.forms.get(id).currentData;
    }

    updateInitialData(data: FormData): void {
        const currentForm = this.forms.get(data.id);

        currentForm.updateInitialData(data);

        this.notify();
    }

    updateCurrentData(data: FormData): void {
        const currentForm = this.forms.get(data.id);

        currentForm.updateCurrentData(data);

        this.notify();
    }

    updateSavingError(id: string, error: IServerError): void {
        const currentForm = this.forms.get(id);

        currentForm.updateSavingError(error);

        this.notify();
    }

    isTouched(id: string): boolean {
        const currentForm = this.forms.get(id);

        return currentForm.isTouched;
    }

    disable(id: string, fields?: (keyof FormData)[]): void {
        const currentForm = this.forms.get(id);

        currentForm.disable(fields);

        this.notify();
    }

    enable(id: string, fields?: (keyof FormData)[]): void {
        const currentForm = this.forms.get(id);

        currentForm.enable(fields);

        this.notify();
    }

    showControls(id: string, name: (keyof FormData)[]): void {
        const currentForm = this.forms.get(id);

        currentForm.showControls(name);

        this.notify();
    }

    hideControls(id: string, name: (keyof FormData)[]): void {
        const currentForm = this.forms.get(id);

        currentForm.hideControls(name);

        this.notify();
    }

    validate(id: string): Observable<boolean> {
        const currentForm = this.forms.get(id);

        return currentForm.validate();
    }

    selectForm$<T extends TFormViewModel<FormData>>(id: string): Observable<T> {
        return this.forms$.pipe(map((views) => views.get(id) as T));
    }

    selectFormState$(id: string): Observable<FormStateValue> {
        return this.selectForm$(id).pipe(
            map((formView) => formView?.state),
            filter((formState) => !!formState),
            distinctUntilChanged()
        );
    }

    isLoadingForm(id: string): Observable<boolean> {
        return this.selectFormState$(id).pipe(map((state) => state === FormStateValue.Initializing));
    }

    isReadyForm(id: string): Observable<boolean> {
        return this.selectForm$(id).pipe(map((form) => form.isReady));
    }

    isSavedForm(id: string): Observable<boolean> {
        return this.selectFormState$(id).pipe(map((state) => state === FormStateValue.Saved));
    }

    isDirtyForm(id: string): Observable<boolean> {
        const currentForm = this.forms.get(id);

        return currentForm.dirty$;
    }

    selectFormError$(id: string): Observable<IServerError> {
        return this.selectForm$(id).pipe(
            map((formView) => formView?.savingError),
            distinctUntilChanged()
        );
    }

    private buildFormViewModel(mode: FormMode, data: FormData): TFormViewModel<FormData> {
        const builder = mode === FormMode.Creation ? this.creationFormViewModelBuilder : this.editFormViewModelBuilder;

        return builder.build({
            mode,
            state: FormStateValue.Initializing,
            initialData: data,
            currentData: data,
            savingError: null
        });
    }

    protected notify(): void {
        this._forms$.next(this.forms);
    }
}
