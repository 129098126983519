import mapValues from 'lodash/mapValues';

export enum ParamType {
    ArrayType = 'array',
    BooleanType = 'boolean',
    NumberType = 'number'
}

const MAP_FUNCTIONS_BY_TYPE = {
    [ParamType.ArrayType]: (value: string | string[]): string[] => [].concat(value) as string[],
    [ParamType.BooleanType]: (value): boolean => value === 'true',
    [ParamType.NumberType]: (value): number => parseInt(value as string)
};

export function getTypedQueryParams<T>(
    queryParams: Record<string, string | string[] | boolean>,
    paramsTypeMap: Partial<Record<keyof T, ParamType>>
): T {
    return mapValues(queryParams, (value: string | string[], key: keyof T) => {
        if (paramsTypeMap[key] && MAP_FUNCTIONS_BY_TYPE[paramsTypeMap[key]]) {
            return MAP_FUNCTIONS_BY_TYPE[paramsTypeMap[key]](value);
        }

        return value;
    }) as unknown as T;
}
