import { IPaginationResponse } from '../common';

import { ISearchQuery } from './query-info';

export type TQueryListResponse = IPaginationResponse<ISearchQuery>;

export interface IQueryValidationResponse {
    errors: IQueryValidationResponseErrorAndWarning[];
    warnings: IQueryValidationResponseErrorAndWarning[];
}

export interface IQueryValidationResponseErrorAndWarning {
    code: SearchQueryValidationCode;
    message: string;
    position: IErrorAndWarningPosition;
    untilPosition: IErrorAndWarningPosition;
    details: IDetailsErrorAndWarning;
}

export interface IErrorAndWarningPosition {
    line: number;
    column: number;
    offset: number;
}

interface IDetailsErrorAndWarning {
    token: string;
    expected: (SearchQueryValidationDetailsExpected | string)[];
}

export enum SearchQueryValidationCode {
    CommentNotTerminated = 'CommentNotTerminated',
    IdentifierNotTerminated = 'IdentifierNotTerminated',
    InvalidCharacter = 'InvalidCharacter',
    InvalidEscapeSequence = 'InvalidEscapeSequence',
    InvalidIdentifier = 'InvalidIdentifier',
    InvalidNumber = 'InvalidNumber',
    StringNotTerminated = 'StringNotTerminated',
    UnexpectedToken = 'UnexpectedToken',
    AmbiguousIdentifierType = 'AmbiguousIdentifierType',
    AttributeNotFound = 'AttributeNotFound',
    DatasourceNotFound = 'DatasourceNotFound',
    DatasourceTypeNotFound = 'DatasourceTypeNotFound',
    DuplicatedSpecifier = 'DuplicatedSpecifier',
    EmptyEntityBody = 'EmptyEntityBody',
    EntityTypeNotFound = 'EntityTypeNotFound',
    InvalidOperation = 'InvalidOperation',
    InvalidValue = 'InvalidValue',
    InvalidValueType = 'InvalidValueType',
    NoValidRelations = 'NoValidRelations',
    RelationKindNotFound = 'RelationKindNotFound',
    UnsupportedAttribute = 'UnsupportedAttribute',
    UnsupportedExpression = 'UnsupportedExpression',
    EmptyExpressionResult = 'EmptyExpressionResult'
}

export enum SearchQueryValidationDetailsExpected {
    LogicOperator = 'LogicOperator',
    ValueCompareOperator = 'ValueCompareOperator',
    CommonSpec = 'CommonSpec',
    Query = 'Query',
    ContextBody = 'ContextBody',
    EndOfContext = 'EndOfContext',
    Scalar = 'Scalar',
    ArrayLit = 'ArrayLit',
    TypeSpec = 'TypeSpec',
    DataSourceList = 'DataSourceList',
    RelKindList = 'RelKindList',
    RelationKinds = 'RelationKinds',
    EndOfArray = 'EndOfArray',
    Identifier = 'Identifier',
    EOF = 'EOF',
    Float = 'Float',
    Comment = 'Comment',
    Integer = 'Integer',
    // eslint-disable-next-line id-blacklist
    String = 'String',
    // eslint-disable-next-line id-blacklist
    Boolean = 'Boolean'
}
