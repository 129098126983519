import { ApiResource, PermissionType } from '@pt-cybsi/api-interfaces';
import { buildPermissionString } from '@pt-cybsi/domain-core/account';

const Write = PermissionType.Write;
const Read = PermissionType.Read;

export class ObservableEntitiesPermissionsService {
    static readonly updateKeys = [buildPermissionString(ApiResource.Observable, [Write])];

    static readonly updateLabels = [buildPermissionString(ApiResource.Observable, [Write])];

    static readonly viewGraph = [
        buildPermissionString(ApiResource.Observable, [Read]),
        buildPermissionString(ApiResource.DataSources, [Read])
    ];
}
