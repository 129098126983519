import { buildFormConfiguration, FormViewModel } from '@pt-cybsi/shared';

import { TAnalyserFormData } from '../../mappers';

/**
 * @ViewModel AnalyserForm
 *
 * @description
 * Model of Angular Reactive Form with additional features for a analyser creation and edit form.
 * Contains extended information about a form state, a working mode, and a saving error.
 *
 * For creation FormViewModel need to create a factory `AnalyserFormBuilder`
 */
export class AnalyserFormViewModel extends FormViewModel<TAnalyserFormData, TAnalyserFormConfig> {}

const FormConfig = {
    id: {
        controlName: 'id',
        errorNames: null
    },
    sourceId: {
        controlName: 'sourceId',
        errorNames: null
    },
    serverId: {
        controlName: 'serverId',
        errorNames: null
    },
    eTag: {
        controlName: 'eTag',
        errorNames: null
    },
    artifactTypes: {
        controlName: 'artifactTypes',
        errorNames: {
            required: 'required'
        }
    },
    artifactSizeLimit: {
        controlName: 'artifactSizeLimit',
        errorNames: {
            range: 'range'
        }
    },
    dashboardURL: {
        controlName: 'dashboardURL',
        errorNames: {
            format: 'invalidKey'
        }
    },
    taskExecutionTimeout: {
        controlName: 'taskExecutionTimeout',
        errorNames: {
            range: 'range'
        }
    },
    taskExecutionAttemptsCount: {
        controlName: 'taskExecutionAttemptsCount',
        errorNames: {
            range: 'range'
        }
    }
} as const;

export type TAnalyserFormConfig = typeof FormConfig;

export const AnalyserFormConfig = buildFormConfiguration<TAnalyserFormData, TAnalyserFormConfig>(FormConfig);
