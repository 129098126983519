import { translate } from '@ngneat/transloco';
import groupBy from 'lodash/groupBy';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';

import { ApiResource } from '@pt-cybsi/api-interfaces';

export interface IPermissionTreeNode {
    children?: IPermissionTreeNode[];
    name: string;
    id?: string;
}

export interface IPermissionFlatTreeNode {
    id: string;
    name: string;
    level: number;
    parent: IPermissionFlatTreeNode;
    expandable: boolean;
}

const PERMISSIONS_SORT_PRIORITY = [
    ApiResource.Search,
    ApiResource.SearchFilters,
    ApiResource.StoredQuery,
    ApiResource.Observable,
    ApiResource.EntityView,
    ApiResource.Artifacts,
    ApiResource.Reports,
    ApiResource.Observations,
    ApiResource.RawReports,
    ApiResource.EnrichmentTasks,
    ApiResource.Feeds,
    ApiResource.FeedsData,
    ApiResource.ReputationLists,
    ApiResource.EnrichmentConfig,
    ApiResource.DataSources,
    ApiResource.Users,
    ApiResource.ApiKeys,
    ApiResource.Dictionaries
];

// TODO тест для этой функции
export function buildPermissionsTree(permissions: string[]): IPermissionTreeNode[] {
    const permissionsByResource = groupBy(permissions, (permission: string) => permission.split(':')[0]);
    const data: IPermissionTreeNode[] = [];

    Object.keys(permissionsByResource).forEach((permissionGroup) => {
        data.push({
            id: permissionGroup,
            name: translate(`users.Users.Pseudo.PermissionGroup.${permissionGroup}`),
            children: permissionsByResource[permissionGroup].map((permission) => ({
                id: permission,
                name: translate(`users.Users.Pseudo.Permission.${permission}`)
            }))
        });
    });

    const contentPermissionNodes = remove(
        data,
        (permissionTreeNode) =>
            permissionTreeNode.id === ApiResource.ArtifactsContent ||
            permissionTreeNode.id === ApiResource.ReputationListsContent
    );

    contentPermissionNodes.forEach((contentPermissionNode) => {
        const permissionGroupNodeForContent = data.find(
            (permissionTreeNode) => contentPermissionNode.id.indexOf(permissionTreeNode.id) !== -1
        );

        if (permissionGroupNodeForContent) {
            permissionGroupNodeForContent.children.push(contentPermissionNode);
        } else {
            const permissionGroupIdForContent = contentPermissionNode.id.replace('Content', '');

            data.push({
                id: permissionGroupIdForContent,
                name: translate(`users.Users.Pseudo.PermissionGroup.${permissionGroupIdForContent}`),
                children: [contentPermissionNode]
            });
        }
    });

    return sortBy(data, (permissionTreeNode) =>
        PERMISSIONS_SORT_PRIORITY.indexOf(permissionTreeNode.id as ApiResource)
    );
}

export function transformer(
    node: IPermissionTreeNode,
    level: number,
    parent: IPermissionFlatTreeNode
): IPermissionFlatTreeNode {
    return {
        id: node.id,
        name: node.name,
        level,
        parent,
        expandable: !!node.children
    };
}

export function getLevel(node: IPermissionFlatTreeNode): number {
    return node.level;
}

export function isExpandable(node: IPermissionFlatTreeNode): boolean {
    return node.expandable;
}

export function getChildren(node: IPermissionTreeNode): IPermissionTreeNode[] {
    return node.children;
}

export function getValue(node: IPermissionTreeNode): string {
    return node.id;
}

export function getViewValue(node: IPermissionTreeNode): string {
    return node.name;
}
