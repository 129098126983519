import { ApiResource, PermissionType } from '@pt-cybsi/api-interfaces';
import { buildPermissionString } from '@pt-cybsi/domain-core/account';

const Read = PermissionType.Read;
const Write = PermissionType.Write;

export class AnalyserPermissionsService {
    static readonly viewPermissions = [buildPermissionString(ApiResource.EnrichmentConfig, [Read])];

    static readonly createPermissions = [buildPermissionString(ApiResource.EnrichmentConfig, [Read, Write])];

    static readonly editPermissions = [buildPermissionString(ApiResource.EnrichmentConfig, [Read, Write])];
}
