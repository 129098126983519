import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { IRouterStateParams } from '@pt-cybsi/core';
import { ConfirmModalService, NotificationUnsavedChangesService } from '@pt-cybsi/shared';
import { RouterFacade } from '@pt-cybsi/store/router';

@Injectable({ providedIn: 'root' })
export class LeaveDirtyLegacyPageGuard implements CanDeactivate<unknown> {
    constructor(
        private router: Router,
        private routerFacade: RouterFacade,
        private confirmModalService: ConfirmModalService,
        private notificationUnsavedChangesService: NotificationUnsavedChangesService
    ) {}

    canDeactivate(): Observable<boolean> {
        const routerState: IRouterStateParams = this.router.getCurrentNavigation().extras.state;
        const isSkipDirtyChecking = routerState?.skipDirtyChecking || false;

        if (isSkipDirtyChecking) {
            return of(true);
        }

        const confirmFactory = () => this.confirmModalService.confirmLeaveForm().pipe(map((skip) => ({ skip })));

        return this.notificationUnsavedChangesService
            .notify(this.routerFacade.isChangedForm$.pipe(take(1)), confirmFactory)
            .pipe(map(({ skip: canDeactivate }) => canDeactivate));
    }
}
