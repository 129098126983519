import { Directionality } from '@angular/cdk/bidi';
import { Overlay, ScrollDispatcher } from '@angular/cdk/overlay';
import { Directive, ElementRef, HostListener, NgZone, OnInit, Provider, Self, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import {
    hasModifierKey,
    isCopy,
    isDigit,
    isHorizontalMovement,
    isSelectAll,
    isVerticalMovement,
    TAB
} from '@ptsecurity/cdk/keycodes';
import { McNumberInput } from '@ptsecurity/mosaic/input';
import { MC_TOOLTIP_SCROLL_STRATEGY, McWarningTooltipTrigger } from '@ptsecurity/mosaic/tooltip';
import { ConnectableObservable, Observable, of, Subject } from 'rxjs';
import { delay, publish, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ConfidenceFormat, ConfidenceModel, DestroyService, FormatConfidencePipe } from '@pt-cybsi/shared';

import {
    MAX_CONFIDENCE,
    MIN_CONFIDENCE,
    CONFIDENCE_ACCURACY,
    validateConfidenceAccuracy,
    validateConfidenceRange
} from '../../validators';

const McTooltipProvider: Provider = {
    provide: McWarningTooltipTrigger,
    useFactory: (
        overlay: Overlay,
        elementRef: ElementRef,
        ngZone: NgZone,
        scrollDispatcher: ScrollDispatcher,
        hostView: ViewContainerRef,
        scrollStrategy,
        direction: Directionality
    ) =>
        new McWarningTooltipTrigger(overlay, elementRef, ngZone, scrollDispatcher, hostView, scrollStrategy, direction),
    deps: [Overlay, ElementRef, NgZone, ScrollDispatcher, ViewContainerRef, MC_TOOLTIP_SCROLL_STRATEGY, Directionality]
};

/**
 * @directive ConfidenceInput
 *
 * @description
 * Form field using with number type `mcInput` for source confidence value
 * The passed value will be rounded to 3 numbers after the decimal point.
 * The passed value will be rounded to zero if it would be less than 0.001.
 *
 * @param id - Unique id of field. Optional, will be generated automatically
 * @param ngModel - confidence value
 * @param placeholder - Placeholder text. Optional, will be used empty text
 * @param disabled - Boolean value for marking the field as disabled. Optional, will be used `false`
 * @param required - Boolean value for marking the field as required. Optional, will be used `false`
 *
 * @example
 * ```html
 * <mc-form-field>
 *      <input
 *          confidenceInput
 *          mcInput
 *          type="number"
 *          [(ngModel)]="value"
 *          [disabled]="disabled"
 *          [required]="required"
 *          [placeholder]="placeholder">
 *      <mc-stepper></mc-stepper>
 * </mc-form-field>
 *
 * <mc-form-field>
 *      <input
 *          confidenceInput
 *          mcInput
 *          type="number"
 *          [formControl]="confidenceControl">
 *      <mc-stepper></mc-stepper>
 * </mc-form-field>
 * ```
 */
@Directive({
    selector: 'input[confidenceInput][mcInput][type="number"]',
    providers: [McTooltipProvider, DestroyService],
    host: {
        autocomplete: 'off',
        // Required for same parsing decimal symbol logic in Chrome and Firefox browsers
        // Source: https://medium.com/justeattakeaway-tech/solving-cross-browsers-localization-on-numeric-inputs-3f7aec57aaeb
        lang: 'ru'
    }
})
export class ConfidenceInputDirective implements OnInit {
    private lastInputValue: string;

    private showValidationMessage$: Subject<string> = new Subject();

    private showTooltipListener$ = this.showValidationMessage$.pipe(
        switchMap((message) => this.showValidationMessage(message)),
        takeUntil(this.destroyed$),
        publish()
    ) as ConnectableObservable<unknown>;

    private readonly validationMessages: Record<'range' | 'accuracy' | 'format', string> = {
        range: this.translocoService.translate('sources.Sources.Validation.Text.ConfidenceRange'),
        accuracy: this.translocoService.translate('sources.Sources.Validation.Text.ConfidenceAccuracy'),
        format: this.translocoService.translate('sources.Sources.Validation.Text.ConfidenceFormat')
    };

    constructor(
        @Self() private mcInput: McNumberInput,
        private ngControl: NgControl,
        private tooltip: McWarningTooltipTrigger,
        private translocoService: TranslocoService,
        private formatConfidence: FormatConfidencePipe,
        private destroyed$: DestroyService
    ) {
        this.mcInput.min = MIN_CONFIDENCE;
        this.mcInput.max = MAX_CONFIDENCE;
        this.mcInput.step = 0.1;
    }

    ngOnInit(): void {
        // Needs delay for rounding first initial model value,
        // because in OnInit `ngControl.value` is null
        setTimeout(() => {
            const parsedValue = this.roundConfidence(this.ngControl.value as number);

            this.ngControl.valueAccessor.writeValue(parsedValue);

            this.lastInputValue = parsedValue.toString();
        });

        this.showTooltipListener$.connect();
    }

    @HostListener('keydown', ['$event'])
    handleKeydown($event: KeyboardEvent) {
        const currentValue = (this.ngControl.value as string)?.toString() || '';

        const isModifier = hasModifierKey($event) && $event.key !== '_';
        const isNumber = isDigit($event);
        const isDecimalPoint = ['.', ','].indexOf($event.key) !== -1;
        const isNavigation = isVerticalMovement($event) || isHorizontalMovement($event);
        const isCopyEvent = isCopy($event);
        const isSelectAllEvent = isSelectAll($event);
        const isTab = $event.keyCode === TAB;

        const isValidKey =
            isNumber || isDecimalPoint || isNavigation || isModifier || isCopyEvent || isSelectAllEvent || isTab;

        if (!isValidKey) {
            $event.preventDefault();

            this.showValidationMessage$.next(this.validationMessages.format);

            return;
        }

        const hasDecimalPoint = /\.|,/.test(currentValue);

        if (isDecimalPoint && hasDecimalPoint) {
            $event.preventDefault();

            this.showValidationMessage$.next(this.validationMessages.range);

            return;
        }

        this.tooltip.hide();
    }

    @HostListener('input', ['$event'])
    handleInput($event: InputEvent) {
        const target = $event.target as HTMLInputElement;
        const targetValue = target.value;
        const inputValue = parseFloat(targetValue) || null;

        const isValidRange = validateConfidenceRange(inputValue);
        const isValidAccuracy = validateConfidenceAccuracy(inputValue);

        if (!isValidRange) {
            this.showValidationMessage$.next(this.validationMessages.range);
        } else if (!isValidAccuracy) {
            this.showValidationMessage$.next(this.validationMessages.accuracy);
        } else {
            this.lastInputValue = targetValue;

            this.tooltip.hide();
        }

        if (!isValidAccuracy || !isValidRange) {
            this.resetToPreviousValidValue(this.lastInputValue);
        }
    }

    private showValidationMessage(message: string): Observable<string> {
        return of(message).pipe(
            tap((tooltipText) => {
                this.tooltip.content = tooltipText;
                this.tooltip.show();
            }),
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            delay(3000),
            tap(() => this.tooltip.hide())
        );
    }

    private resetToPreviousValidValue(lastValidValue: string): void {
        const validValue = lastValidValue ? parseFloat(lastValidValue) : null;

        this.ngControl.control.setValue(validValue);
        this.ngControl.valueAccessor.writeValue(lastValidValue);
    }

    private roundConfidence(confidence: number): number {
        const formattedConfidence = this.formatConfidence.transform(confidence, ConfidenceFormat.Full);

        return parseFloat(formattedConfidence.replace(',', '.').replace('~', ''));
    }
}
