import { Component, Input, Inject, TemplateRef } from '@angular/core';
import { MC_SIDEPANEL_DATA } from '@ptsecurity/mosaic/sidepanel';

export interface IDefaultCollectionSidepanelData {
    collection: unknown[];
    collectionTitle: string;
    itemTemplate: TemplateRef<unknown>;
}

@Component({
    selector: 'default-collection-sidepanel',
    templateUrl: './default-collection-sidepanel.component.html',
    styleUrls: ['./default-collection-sidepanel.component.scss']
})
export class DefaultCollectionSidepanelComponent {
    @Input() collection: unknown[];
    @Input() collectionTitle: string;
    @Input() itemTemplate: TemplateRef<unknown>;

    constructor(@Inject(MC_SIDEPANEL_DATA) public data: IDefaultCollectionSidepanelData) {}

    trackByFn(index: number): number {
        return index;
    }
}
