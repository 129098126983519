<div class="layout-column flex sidepanel-container">
    <mc-sidepanel-header [closeable]="true">
        <div pt-title class="pt-text-overflow">{{ data.collectionTitle }}</div>
    </mc-sidepanel-header>
    <mc-sidepanel-body class="default-collection-sidepanel__body">
        <ul class="default-collection-sidepanel__list">
            <li class="default-collection-sidepanel__list-element"
                *ngFor="let item of data.collection trackBy: trackByFn">
                <ng-container *ngTemplateOutlet="data.itemTemplate; context: { item: item }"></ng-container>
            </li>
        </ul>
    </mc-sidepanel-body>
</div>
