import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { CustomRouterStateSerializer } from './custom-router-state-serializer';
import { RouterEffects } from './state/router.effects';
import { RouterFacade } from './state/router.facade';
import * as fromRouter from './state/router.reducer';

@NgModule({
    imports: [
        StoreRouterConnectingModule.forRoot({
            stateKey: fromRouter.ROUTER_FEATURE_KEY,
            serializer: CustomRouterStateSerializer
        }),
        StoreModule.forFeature(fromRouter.ROUTER_FEATURE_KEY, fromRouter.reducer),
        EffectsModule.forFeature([RouterEffects])
    ],
    providers: [RouterFacade]
})
export class StoreRouterModule {}
