import { IServerError, UploadLicenseErrorCode } from '@pt-cybsi/api-interfaces';
import { buildFormConfiguration, FormViewModel, TFormData } from '@pt-cybsi/shared';

/**
 * @ViewModel LicenseForm
 *
 * @description
 * Model of Angular Reactive Form with additional features for a license form.
 * Contains extended information about a form state, a working mode, and a saving error.
 *
 * For creation FormViewModel need to create a factory `LicenseFormBuilder`
 */
export class LicenseFormViewModel extends FormViewModel<TLicenseFormData, TLicenseFormConfig> {
    updateSavingError(error: IServerError): void {
        super.updateSavingError(error);

        if (this.savingError?.code === UploadLicenseErrorCode.LicenseFileMissing) {
            this.setError('file', 'licenseFileMissing');
            this.updateSavingError(null);
        }

        if (this.savingError?.code === UploadLicenseErrorCode.ExpiredLicense) {
            this.setError('file', 'expiredLicense');
            this.updateSavingError(null);
        }

        if (
            this.savingError?.code === UploadLicenseErrorCode.CorruptedLicense ||
            this.savingError?.code === UploadLicenseErrorCode.UnsupportedProduct
        ) {
            this.setError('file', 'invalidProductLicense');
            this.updateSavingError(null);
        }
    }
}

const FormConfig = {
    id: {
        controlName: 'id',
        errorNames: null
    },
    file: {
        controlName: 'file',
        errorNames: {
            required: 'required',
            invalidProductLicense: 'invalidProductLicense',
            expiredLicense: 'expiredLicense',
            licenseFileMissing: 'licenseFileMissing'
        }
    }
} as const;

export type TLicenseFormConfig = typeof FormConfig;

export const LicenseFormConfig = buildFormConfiguration<TLicenseFormData, TLicenseFormConfig>(FormConfig);

export type TLicenseFormData = TFormData<{ file: File }>;
