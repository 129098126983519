import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { AuthenticatedGuard } from '@pt-cybsi/domain-core/account';
import { LazyLoadedTranslationsGuard } from '@pt-cybsi/i18n';
import { LegacyContentDummyComponent } from '@pt-cybsi/legacy-application';

import { LeaveDirtyLegacyPageGuard } from './guards';

const routes: Routes = [
    {
        path: 'welcome',
        loadChildren: () =>
            import(/* webpackPrefetch: true */ '@pt-cybsi/feature/welcome').then(
                (module) => module.FeatureWelcomeModule
            )
    },
    {
        path: 'account',
        loadChildren: () =>
            import(/* webpackPrefetch: true */ '@pt-cybsi/feature/account').then(
                (module) => module.FeatureAccountModule
            ),
        canActivate: [LazyLoadedTranslationsGuard]
        /*data: {
           translateScope: 'users'
       }*/
    },
    {
        path: 'license',
        loadChildren: () =>
            import(/* webpackPrefetch: true */ '@pt-cybsi/feature/license').then(
                (module) => module.FeatureLicenseModule
            ),
        canActivate: [AuthenticatedGuard]
    },
    {
        path: 'statistics',
        canActivate: [AuthenticatedGuard, LazyLoadedTranslationsGuard],
        loadChildren: () =>
            import(/* webpackPrefetch: true */ '@pt-cybsi/feature/statistics').then(
                (module) => module.FeatureStatisticsModule
            )
        /*data: {
            translateScope: 'statistics'
        }*/
    },
    {
        path: 'enrichment-rules',
        canActivate: [AuthenticatedGuard],
        loadChildren: () =>
            import(/* webpackPrefetch: true */ '@pt-cybsi/feature/enrichment-rules').then(
                (module) => module.FeatureEnrichmentRulesModule
            )
    },
    {
        path: 'error',
        loadChildren: () =>
            import(/* webpackPrefetch: true */ '@pt-cybsi/feature/error').then((module) => module.FeatureErrorModule)
    },
    {
        path: 'replists',
        loadChildren: () =>
            import(/* webpackPrefetch: true */ '@pt-cybsi/feature/reputation-lists').then(
                (module) => module.FeatureReputationListsModule
            ),
        canActivate: [AuthenticatedGuard, LazyLoadedTranslationsGuard]
        /*data: {
            translateScope: 'replists'
        }*/
    },
    {
        path: 'sources',
        canActivate: [AuthenticatedGuard],
        loadChildren: () =>
            import(/* webpackPrefetch: true */ '@pt-cybsi/feature/sources').then(
                (module) => module.FeatureSourcesModule
            )
    },
    {
        path: 'users',
        loadChildren: () =>
            import(/* webpackPrefetch: true */ '@pt-cybsi/feature/users').then((module) => module.FeatureUsersModule),
        canActivate: [AuthenticatedGuard, LazyLoadedTranslationsGuard]
        /*data: {
            translateScope: 'users'
        }*/
    },
    {
        path: 'dictionaries',
        loadChildren: () =>
            import(/* webpackPrefetch: true */ '@pt-cybsi/feature/dictionaries').then(
                (module) => module.FeatureDictionariesModule
            ),
        canActivate: [AuthenticatedGuard, LazyLoadedTranslationsGuard]
        /*data: {
            translateScope: 'dictionaries'
        }*/
    },
    {
        path: 'dictionary-items',
        loadChildren: () =>
            import(/* webpackPrefetch: true */ '@pt-cybsi/feature/dictionary-items').then(
                (module) => module.FeatureDictionaryItemsModule
            ),
        canActivate: [AuthenticatedGuard, LazyLoadedTranslationsGuard]
        /*data: {
            translateScope: 'dictionaries'
        }*/
    },
    {
        path: 'objects/view/:id/graph',
        loadChildren: () =>
            import(/* webpackPrefetch: true */ '@pt-cybsi/feature/observable-entities').then(
                (m) => m.FeatureObservableEntitiesModule
            ),
        canActivate: [AuthenticatedGuard, LazyLoadedTranslationsGuard]
        /*data: {
            translateScope: 'dictionaries'
        }*/
    },
    {
        path: '**',
        component: LegacyContentDummyComponent,
        data: { isLegacyRoute: true },
        canActivate: [AuthenticatedGuard],
        canDeactivate: [LeaveDirtyLegacyPageGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            onSameUrlNavigation: 'reload',
            canceledNavigationResolution: 'computed',
            preloadingStrategy: PreloadAllModules
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
