import { Directive, Host, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { ShareLevel, SHARE_LEVELS } from '@pt-cybsi/api-interfaces';
import { DestroyService, ShareLevelSelectComponent } from '@pt-cybsi/shared';
import { AccountFacade } from '@pt-cybsi/store/account';

@Directive({
    selector: '[share-level-select-with-user]',
    providers: [DestroyService]
})
export class ShareLevelSelectWithUserDirective implements OnInit {
    constructor(
        @Host() private shareLevelComponent: ShareLevelSelectComponent,
        private accountStore: AccountFacade,
        private destroyed$: DestroyService
    ) {}

    ngOnInit(): void {
        this.accountStore.userAccessLevel$.pipe(takeUntil(this.destroyed$)).subscribe((accessLevel: ShareLevel) => {
            const maxAvailableShareLevel = SHARE_LEVELS.indexOf(accessLevel);
            const availableShareLevels: ShareLevel[] = SHARE_LEVELS.slice(0, maxAvailableShareLevel + 1);

            this.shareLevelComponent.updateAvailableLevels(availableShareLevels);
        });
    }
}
