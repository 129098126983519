import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import {
    AsyncValidationService,
    createControlValidator,
    IFormViewModelBuilder,
    TFormViewModelProps
} from '@pt-cybsi/shared';

import { TExternalDBFormData } from '../../mappers';
import { validateTaskExecutionAttemptsCount, validateTaskExecutionTimeout } from '../../validators';

import { TExternalDBFormConfig, ExternalDBFormConfig, ExternalDBFormViewModel } from './external-db-form.view-model';

/**
 * @factory ExternalDBFormBuilder
 *
 * @description
 * Provides a factory method to create ExternalDBFormViewModel.
 * Creates a reactive form of an external database form and wrap it in FormViewModel.
 */
@Injectable()
export class ExternalDBFormBuilder implements IFormViewModelBuilder<TExternalDBFormData, TExternalDBFormConfig> {
    formConfig = ExternalDBFormConfig;

    constructor(private fb: UntypedFormBuilder, private asyncValidationService: AsyncValidationService) {}

    build(props: TFormViewModelProps<TExternalDBFormData>): ExternalDBFormViewModel {
        const form = this.buildFormGroup();

        return new ExternalDBFormViewModel(props, form, this.formConfig, this.asyncValidationService);
    }

    private buildFormGroup(): UntypedFormGroup {
        const {
            id,
            sourceId,
            eTag,
            serverId,
            entityTypes,
            webPageURL,
            taskExecutionTimeout,
            taskExecutionAttemptsCount
        } = this.formConfig;

        const taskExecutionTimeoutValidator = createControlValidator(
            validateTaskExecutionTimeout,
            taskExecutionTimeout.errorNames.range
        );

        const taskExecutionAttemptsCountValidator = createControlValidator(
            validateTaskExecutionAttemptsCount,
            taskExecutionAttemptsCount.errorNames.range
        );

        const required = Validators.required;

        return this.fb.group({
            [id.controlName]: [undefined],

            [sourceId.controlName]: [undefined],

            [serverId.controlName]: [undefined],

            [eTag.controlName]: [undefined],

            [entityTypes.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ],

            [webPageURL.controlName]: [undefined],

            [taskExecutionTimeout.controlName]: [
                undefined,
                {
                    validators: [taskExecutionTimeoutValidator]
                }
            ],

            [taskExecutionAttemptsCount.controlName]: [
                undefined,
                {
                    validators: [taskExecutionAttemptsCountValidator]
                }
            ]
        });
    }
}
