import { IDataSource } from '@pt-cybsi/api-interfaces';
import { TValueObjectProps } from '@pt-cybsi/shared';

import { DataSourceViewFormat, IDataSourceFullView, IDataSourceInstanceView } from '../types';

import { DataSourceTypeMapper } from './data-source-type.mapper';

export class DataSourceMapper {
    static toInstanceView(dataSource: IDataSource): TValueObjectProps<IDataSourceInstanceView> {
        const typeView = DataSourceTypeMapper.toTypeView(dataSource.type);

        const name = dataSource.longName || typeView.name;

        return {
            format: DataSourceViewFormat.Instance,
            id: dataSource.uuid,
            name,
            confidence: dataSource.confidence
        };
    }

    static toFullView(dataSource: IDataSource): TValueObjectProps<IDataSourceFullView> {
        const typeView = DataSourceTypeMapper.toTypeView(dataSource.type);
        const instanceView = DataSourceMapper.toInstanceView(dataSource);

        return {
            format: DataSourceViewFormat.Full,
            type: { id: typeView.id, name: typeView.name, confidence: typeView.confidence },
            instance: { id: instanceView.id, name: instanceView.name, confidence: instanceView.confidence }
        };
    }
}
