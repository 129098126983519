import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LocationService } from '@pt-cybsi/core';
import { AccountFacade } from '@pt-cybsi/store/account';

import { PermissionsService } from '../services/permissions.service';

export interface IRoutePermissions {
    all?: string[];
    some?: string[];
}

@Injectable({
    providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
    constructor(
        private permissionsService: PermissionsService,
        private locationService: LocationService,
        private accountFacade: AccountFacade
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const permissions = route.data?.permissions as IRoutePermissions;

        if (!permissions || this.hasPermissions(permissions)) {
            return of(true);
        }

        return this.accountFacade.hasToken$.pipe(
            tap((hasToken) => {
                if (hasToken) {
                    this.locationService.redirectToForbiddenPage();
                } else {
                    this.locationService.redirectToSignInPage();
                }
            }),
            map(() => false)
        );
    }

    private hasPermissions(permissions: IRoutePermissions): boolean {
        const allRequiredPermissions = permissions.all;
        const anyRequiredPermissions = permissions.some;

        if (allRequiredPermissions?.length > 0) {
            return this.permissionsService.hasAllPermissions(allRequiredPermissions);
        } else if (anyRequiredPermissions?.length > 0) {
            return this.permissionsService.hasAnyPermissions(anyRequiredPermissions);
        }

        return true;
    }
}
