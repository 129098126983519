import { Component, Input } from '@angular/core';

import { AttributeObservableEntity, TAttributeValueType } from '@pt-cybsi/api-interfaces';
import { DictionaryItemPreviewFormat } from '@pt-cybsi/domain-core/dictionaries';
import { FileSizeUnit, getMinBytesOfFileSizeUnit } from '@pt-cybsi/shared';

/**
 * @component EntityAttributeValue
 *
 * @description
 * Component for displaying entity attribute value
 *
 * @param attributeName - Attribute name from AttributeObservableEntity enum
 * @param attributeValue - Attribute value of attribute
 *
 * @example
 * ```html
 *
 * <entity-attribute-value
 *       [attributeName]="AttributeObservableEntity.IsDelegated"
 *       [attributeValue]="true">
 * </entity-attribute-value>
 *
 * ```
 */
@Component({
    selector: 'entity-attribute-value',
    templateUrl: './entity-attribute-value.component.html',
    styleUrls: ['./entity-attribute-value.component.scss']
})
export class EntityAttributeValueComponent {
    @Input() attributeName: AttributeObservableEntity;
    @Input() attributeValue: TAttributeValueType;

    AttributeObservableEntity = AttributeObservableEntity;
    DictionaryItemPreviewFormat = DictionaryItemPreviewFormat;
    FileSizeUnit = FileSizeUnit;

    get isDisabledSizeTooltip(): boolean {
        const minValueForTooltipVisibility = getMinBytesOfFileSizeUnit(FileSizeUnit.KB);

        return this.attributeValue <= minValueForTooltipVisibility;
    }
}
