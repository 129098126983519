import { IAddItemToSynonymsParams, IApiItemPreview } from '@pt-cybsi/api-interfaces';
import { TFormData } from '@pt-cybsi/shared';

export type TAddSynonymFormData = TFormData<{
    serverId: string;
    synonym: string;
}>;

export class AddSynonymFormMapper {
    static toAddParams(dictionaryItem: IApiItemPreview): IAddItemToSynonymsParams {
        return {
            synonymUUID: dictionaryItem.uuid
        };
    }
}
