import { IArtifact } from '../artifacts';
import { TObservableEntity } from '../observable';

import { IObservationHeader } from './observation';
import { ObservationType } from './observation-type';

export interface IObservationArchive<EntityFormat extends TObservableEntity = TObservableEntity>
    extends IObservationHeader<ObservationType.Archive> {
    content: IObservationArchiveContent;
}

export interface IObservationArchiveContent {
    artifact: IArtifact;
    format: string;
    password: string;
    items: IObservationArchiveItem[];
}

export interface IObservationArchiveItem {
    path: string;
    artifact: IArtifact;
}
