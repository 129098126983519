import { Component } from '@angular/core';

@Component({
    selector: '[form-page-layout]',
    templateUrl: './form-page-layout.component.html',
    styleUrls: ['./form-page-layout.component.scss'],
    host: {
        class: 'full-height-container full-height-container-child'
    }
})
export class FormPageLayoutComponent {}
