import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

export enum LazyLoaderProgressState {
    Loading = 'Loading',
    Error = 'Error'
}

@Component({
    selector: 'lazy-loader-progress',
    templateUrl: './lazy-loader-progress.component.html',
    styleUrls: ['./lazy-loader-progress.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LazyLoaderProgressComponent {
    @Input()
    state: LazyLoaderProgressState;

    @Input()
    errorTitle: string;

    @Input()
    errorDescription: string;

    @Input()
    textReload: string;

    @Output()
    reload = new EventEmitter<void>();

    get isLoading(): boolean {
        return this.state === LazyLoaderProgressState.Loading;
    }

    get isError(): boolean {
        return this.state === LazyLoaderProgressState.Error;
    }

    get hasDescription(): boolean {
        return !!this.errorDescription;
    }

    get defaultErrorTitle(): string {
        return this.translocoService.translate('common.Common.Loading.Text.ErrorTitle');
    }

    get defaultTextReload(): string {
        return this.translocoService.translate('common.Common.Loading.Text.RetryButton');
    }

    constructor(private translocoService: TranslocoService) {}
}
