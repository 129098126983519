import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { createControlValidator, IFormViewModelBuilder, TFormViewModelProps } from '@pt-cybsi/shared';

import { validateUserEmailLength, validateUserNameLength } from '../../validators';

import {
    TCurrentUserFormConfig,
    TCurrentUserFormData,
    CurrentUserFormConfig,
    CurrentUserFormViewModel
} from './current-user-form.view-model';

@Injectable()
export class CurrentUserFormBuilder implements IFormViewModelBuilder<TCurrentUserFormData, TCurrentUserFormConfig> {
    formConfig = CurrentUserFormConfig;

    constructor(private fb: UntypedFormBuilder) {}

    build(props: TFormViewModelProps<TCurrentUserFormData>): CurrentUserFormViewModel {
        const form = this.buildFormGroup();

        return new CurrentUserFormViewModel(props, form, this.formConfig);
    }

    private buildFormGroup(): UntypedFormGroup {
        const { id, eTag, login, fullName, email, language } = this.formConfig;

        return this.fb.group({
            [id.controlName]: undefined,
            [eTag.controlName]: undefined,

            [login.controlName]: [
                undefined,
                {
                    validators: []
                }
            ],
            [fullName.controlName]: [
                undefined,
                {
                    validators: [createControlValidator(validateUserNameLength, fullName.errorNames.length)]
                }
            ],
            [email.controlName]: [
                undefined,
                {
                    validators: [createControlValidator(validateUserEmailLength, email.errorNames.length)]
                }
            ],
            [language.controlName]: [
                undefined,
                {
                    validators: []
                }
            ]
        });
    }
}
