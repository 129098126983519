import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IAuthProvider, IUserFullInfo } from '@pt-cybsi/api-interfaces';
import { AsyncState, DeprecatedUserMapper } from '@pt-cybsi/shared';

import { ACCOUNT_FEATURE_KEY, State } from './account.reducer';

export const getAccountState = createFeatureSelector<State>(ACCOUNT_FEATURE_KEY);

export const getAccountToken = createSelector(getAccountState, (state) => state.token);

export const hasAccountToken = createSelector(getAccountToken, (token) => !!token);

export const isTokenRefreshing = createSelector(getAccountState, (state) => state.isTokenRefreshing);

export const isSessionExpired = createSelector(getAccountState, (state) => state.isSessionExpired);

export const getUserInfo = createSelector(getAccountState, (state) => state.user);

export const getUserDisplayName = createSelector(
    getUserInfo,
    (user: IUserFullInfo) => user && DeprecatedUserMapper.toView(user).name
);

export const getUserAccessLevel = createSelector(getUserInfo, (user: IUserFullInfo) => user?.accessLevel);

const getAuthProvidersState = createSelector(getAccountState, (state) => state.providers);

export const getAuthProviders = createSelector(getAuthProvidersState, (state) => (state.data || []) as IAuthProvider[]);

export const getAuthProvidersById = createSelector(
    getAuthProviders,
    (providers: IAuthProvider[]): Record<string, string> => {
        const providersById: Record<string, string> = {};

        providers.forEach((provider) => {
            providersById[provider.id] = provider.name;
        });

        return providersById;
    }
);

export const isAuthProvidersLoading = createSelector(
    getAuthProvidersState,
    (state) => state.state === AsyncState.Loading
);

export const isAuthProvidersLoaded = createSelector(
    getAuthProvidersState,
    (state) => state.state === AsyncState.Success
);

export const isAuthProvidersLoadingFailure = createSelector(
    getAuthProvidersState,
    (state) => state.state === AsyncState.Failure
);
