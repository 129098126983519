import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

import { ThreatCategory } from '@pt-cybsi/api-interfaces';

/**
 * @pipe TranslateThreatCategoryValue
 *
 * @description Converts ThreatCategory value to localized string
 */
@Pipe({
    name: 'translateThreatCategoryValue'
})
export class TranslateThreatCategoryValuePipe implements PipeTransform {
    transform(value: ThreatCategory): string {
        return translate(
            value
                ? `entities.ObservableEntities.ThreatCategory.Text.${value}`
                : 'entities.ObservableEntities.ThreatCategory.Text.Unknown'
        );
    }
}
