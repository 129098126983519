<property-row *ngIf='analyser.dashboardURL' [title]="'enrichment.Enrichment.AnalyserView.Label.DashboardURL' | transloco">
    <span property-row-content>
        <a mc-link [href]="analyser.dashboardURL" target="_blank" class="analyser-view__url">
            <span pt-title class="mc-link__text pt-text-overflow">{{ analyser.dashboardURL }}</span>
            <i mc-icon class="pt-icons pt-icons-external-link_16"></i>
        </a>
    </span>
</property-row>

<property-row [title]="'artifacts.Artifacts.Pseudo.Text.ArtifactTypes' | transloco">
    <span property-row-content>
        <span *ngFor="let artifactType of analyser.artifactTypes | translateArtifactTypes" class='margin-right_16'>{{ artifactType }}</span>
    </span>
</property-row>

<property-row *ngIf='analyser.artifactSizeLimit' [title]="'enrichment.Enrichment.Pseudo.Label.ArtifactSizeLimit' | transloco">
    <span property-row-content>{{ 'enrichment.Enrichment.Pseudo.Text.FileSizeInBytes' | transloco: { count:  analyser.artifactSizeLimit } }}</span>
</property-row>

<property-row *ngIf='analyser.taskExecutionTimeout' [title]="'enrichment.Enrichment.AnalyserView.Label.TaskExecutionTimeout' | transloco">
    <span property-row-content>
        <span>{{ formattedTaskExecutionTimeout }}</span>
    </span>
</property-row>

<property-row *ngIf='analyser.taskExecutionAttemptsCount' [title]="'enrichment.Enrichment.Pseudo.Label.TaskExecutionAttemptsCount' | transloco">
    <span property-row-content>{{ analyser.taskExecutionAttemptsCount }}</span>
</property-row>

<property-row [title]="'enrichment.Enrichment.Pseudo.Label.Rules' | transloco">
    <async-data property-row-content
                errorTranslationKey="sources.Sources.ViewSource.Error.AnalyzerRules"
                [state]="rulesState"
                [successTemplate]="rulesTemplate"
                [successTemplateData]="rules"
                (reload)="handleRulesReload()">
    </async-data>

    <div property-row-actions>
        <ng-container *ngIf="hasEnrichmentRules">
            <ng-container *ngTemplateOutlet="addRuleTemplate"></ng-container>
        </ng-container>
    </div>
</property-row>

<ng-template #ruleTemplate let-rule="item">
    <span class="layout-row">
        <button mc-link pt-title (click)="onRuleClick(rule)" class="mc-link mc-link_pseudo clear-button pt-text-overflow">
            <span class="mc-link__text">{{ rule.name }}</span>
        </button>
        <status-badge *ngIf="rule.isDisabled" class="margin-left_8" statusTranslationKey="enrichment.Enrichment.Pseudo.Tag.Disabled"></status-badge>
    </span>
</ng-template>

<ng-template #rulesTemplate let-rules="data">
    <limited-collection
        *ngIf="hasEnrichmentRules"

        [collection]="rules"
        [collectionTitle]= "collectionTitle"
        [itemTemplate]="ruleTemplate">
    </limited-collection>

    <ng-container *ngIf="!hasEnrichmentRules">
        <ng-container *ngTemplateOutlet="addRuleTemplate"></ng-container>
    </ng-container>
</ng-template>

<ng-template #addRuleTemplate>
    <a *hasPermissions="createRulePermissions"
       class="mc-link clear-button"
       mc-link
       pseudo
       [routerLink]="addRuleLink"
       [queryParams]="addRuleLinkQueryParams">
        {{ 'common.Common.Pseudo.Button.Add' | transloco }}
    </a>
</ng-template>
