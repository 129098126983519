import { Component, Inject, EventEmitter } from '@angular/core';
import { MC_SIDEPANEL_DATA } from '@ptsecurity/mosaic/sidepanel';

import { IDefaultCollectionSidepanelData } from '../default-collection-sidepanel/default-collection-sidepanel.component';

export interface ILabelsCollectionSidepanelData extends IDefaultCollectionSidepanelData {
    editable: boolean;
    edit: EventEmitter<void>;
}

@Component({
    selector: 'labels-collection-sidepanel',
    templateUrl: './labels-collection-sidepanel.component.html',
    styleUrls: ['./labels-collection-sidepanel.component.scss'],
    host: {
        class: 'full-height-container full-height-container-child'
    }
})
export class LabelsCollectionSidepanelComponent {
    constructor(@Inject(MC_SIDEPANEL_DATA) public data: ILabelsCollectionSidepanelData) {}

    trackByFn(index: number): number {
        return index;
    }
}
