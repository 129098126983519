import { Injectable } from '@angular/core';
import { Observable, concat } from 'rxjs';

import {
    IAddItemToSynonymsParams,
    IApiItemPreview,
    IDictionary,
    IDictionaryBrief,
    IDictionaryItem,
    IDictionaryItemBrief,
    IDictionaryItemCreateParams,
    IDictionaryItemsGetParams,
    IDictionaryItemUpdateParams,
    IEditableResource,
    IPaginationParams,
    IPaginationResponse
} from '@pt-cybsi/api-interfaces';

import { BaseApiService, IFullListResponse } from './base-api.service';

@Injectable()
export class DictionariesApiService extends BaseApiService {
    static readonly urls = {
        dictionaries: 'dictionaries',
        dictionaryItems: 'dictionary-items'
    };

    getDictionaries(params: IPaginationParams = {}): Observable<IPaginationResponse<IDictionaryBrief[]>> {
        const url = DictionariesApiService.urls.dictionaries;

        return this.getPaginatedCollection(url, { params });
    }

    getAllDictionaries(): Observable<IFullListResponse<IDictionaryBrief>> {
        const url = DictionariesApiService.urls.dictionaries;

        return this.getFullList(url);
    }

    getDictionary(uuid: string): Observable<IDictionary> {
        const url = `${DictionariesApiService.urls.dictionaries}/${uuid}`;

        return this.get(url);
    }

    getEditableDictionary(uuid: string): Observable<IEditableResource<IDictionary>> {
        const url = `${DictionariesApiService.urls.dictionaries}/${uuid}`;

        return this.getEditableResource(url);
    }

    getDictionaryItems(
        uuid: string,
        params: IDictionaryItemsGetParams = {}
    ): Observable<IPaginationResponse<IDictionaryItemBrief[]>> {
        const url = `${DictionariesApiService.urls.dictionaries}/${uuid}/items`;

        return this.getPaginatedCollection(url, { params });
    }

    getAllDictionaryItems(
        uuid: string,
        params: IDictionaryItemsGetParams = {}
    ): Observable<IFullListResponse<IDictionaryItemBrief>> {
        const url = `${DictionariesApiService.urls.dictionaries}/${uuid}/items`;

        return this.getFullList(url, { params });
    }

    createDictionaryItem(params: IDictionaryItemCreateParams): Observable<IApiItemPreview> {
        const url = DictionariesApiService.urls.dictionaryItems;

        return this.post(url, params);
    }

    getDictionaryItem(uuid: string): Observable<IDictionaryItem> {
        const url = `${DictionariesApiService.urls.dictionaryItems}/${uuid}`;

        return this.get(url, this.addResolveRefsParam());
    }

    getEditableDictionaryItem(uuid: string): Observable<IEditableResource<IDictionaryItem>> {
        const url = `${DictionariesApiService.urls.dictionaryItems}/${uuid}`;

        return this.getEditableResource(url, this.addResolveRefsParam());
    }

    updateDictionaryItem(uuid: string, eTag: string, params: IDictionaryItemUpdateParams): Observable<void> {
        const url = `${DictionariesApiService.urls.dictionaryItems}/${uuid}`;

        return this.updateResource(url, eTag, params);
    }

    addDictionaryItemToSynonyms(uuid: string, params: IAddItemToSynonymsParams): Observable<void> {
        const url = `${DictionariesApiService.urls.dictionaryItems}/${uuid}/synonyms`;

        return this.put(url, params);
    }

    removeDictionaryItemFromSynonyms(uuid: string): Observable<void> {
        const url = `${DictionariesApiService.urls.dictionaryItems}/${uuid}/synonyms`;

        return this.delete(url);
    }

    removeAllSynonyms(uuids: string[]): Observable<void> {
        const urls = uuids.map((uuid) => `${DictionariesApiService.urls.dictionaryItems}/${uuid}/synonyms`);
        const observables: Observable<void>[] = urls.map((url) => this.delete(url));

        return concat(...observables);
    }
}
