import { Injectable, Provider } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IServerError } from '@pt-cybsi/api-interfaces';
import { IFormRepository, FormStateValue, IObservableUseCase } from '@pt-cybsi/shared';

import { SourceModel } from '../../models';
import { SourceFormRepository, SourceFormRepositoryProvider } from '../../repositories';

export interface IUpdateSourceParams {
    id: string;
}

export type TUpdatedSourceId = string;

@Injectable()
export class UpdateSourceUseCase implements IObservableUseCase<IUpdateSourceParams, TUpdatedSourceId> {
    constructor(private repository: IFormRepository<SourceModel>) {}

    execute(params: IUpdateSourceParams): Observable<TUpdatedSourceId> {
        const { id } = params;

        const source = this.repository.getCurrentData(id);
        const currentFormState = this.repository.getState(id);

        if (currentFormState.value === FormStateValue.Saved) {
            return of(source.props.serverId);
        }

        this.repository.resetSavingError(id);

        this.switchState(id, FormStateValue.Saving);

        const initialSource = this.repository.getInitialData(id);

        const sourceWithChangedData = source.diff(initialSource);

        return this.repository.save(sourceWithChangedData).pipe(
            tap(() => {
                this.switchState(id, FormStateValue.Saved);

                this.repository.disable(id);
            }),
            catchError((error: unknown) => {
                this.switchState(id, FormStateValue.SavingFailure);

                this.repository.updateSavingError(id, error as IServerError);

                return throwError(error);
            })
        );
    }

    private switchState(id: string, state: FormStateValue): void {
        const formState = this.repository.getState(id);

        const nextFormState = formState.to(state);

        this.repository.updateState(id, nextFormState);
    }
}

export const UpdateSourceUseCaseProvider: Provider = [
    SourceFormRepositoryProvider,
    {
        provide: UpdateSourceUseCase,
        useFactory: (repository: SourceFormRepository) => new UpdateSourceUseCase(repository),
        deps: [SourceFormRepository]
    }
];
