import { Injectable, Provider } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IServerError } from '@pt-cybsi/api-interfaces';
import { FormStateValue, IObservableUseCase } from '@pt-cybsi/shared';

import {
    EnrichmentRuleFormRepository,
    EnrichmentRuleFormRepositoryProvider,
    IEnrichmentRuleFormRepository
} from '../../repositories';

export interface IUpdateEnrichmentRuleParams {
    id: string;
}

export type TUpdatedEnrichmentRuleId = string;

@Injectable()
export class UpdateEnrichmentRuleUseCase
    implements IObservableUseCase<IUpdateEnrichmentRuleParams, TUpdatedEnrichmentRuleId>
{
    constructor(private repository: IEnrichmentRuleFormRepository) {}

    execute(params: IUpdateEnrichmentRuleParams): Observable<TUpdatedEnrichmentRuleId> {
        const { id } = params;

        const rule = this.repository.getCurrentData(id);
        const currentFormState = this.repository.getState(id);

        if (currentFormState.value === FormStateValue.Saved) {
            return of(rule.props.serverId);
        }

        this.repository.resetSavingError(id);

        this.switchState(id, FormStateValue.Saving);

        const initialRule = this.repository.getInitialData(id);

        const ruleWithChangedData = rule.diff(initialRule);

        return this.repository.save(ruleWithChangedData).pipe(
            tap(() => {
                this.switchState(id, FormStateValue.Saved);

                this.repository.disable(id);
            }),
            catchError((error: unknown) => {
                this.switchState(id, FormStateValue.SavingFailure);

                this.repository.updateSavingError(id, error as IServerError);

                return throwError(error);
            })
        );
    }

    private switchState(id: string, state: FormStateValue): void {
        const formState = this.repository.getState(id);

        const nextFormState = formState.to(state);

        this.repository.updateState(id, nextFormState);
    }
}

export const UpdateEnrichmentRuleUseCaseProvider: Provider = [
    EnrichmentRuleFormRepositoryProvider,
    {
        provide: UpdateEnrichmentRuleUseCase,
        useFactory: (repository: EnrichmentRuleFormRepository) => new UpdateEnrichmentRuleUseCase(repository),
        deps: [EnrichmentRuleFormRepository]
    }
];
