import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { McModalRef } from '@ptsecurity/mosaic/modal';
import { FlatTreeControl, McTreeFlatDataSource, McTreeFlattener } from '@ptsecurity/mosaic/tree';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { DataSourcesOrderType, HttpStatus, IDataSource } from '@pt-cybsi/api-interfaces';
import { AsyncState } from '@pt-cybsi/shared';
import { DataSourcesFacade } from '@pt-cybsi/store/data-sources';

import {
    buildSourcesTree,
    getChildren,
    getLevel,
    getValue,
    getViewValue,
    isExpandable,
    ISourceFlatTreeNode,
    ISourceTreeNode,
    transformer
} from '../../helpers';
import { SourcesSyncService } from '../../services';

@Component({
    selector: 'source-select-modal',
    templateUrl: './source-select-modal.component.html',
    styleUrls: ['./source-select-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SourceSelectModalComponent implements OnInit {
    @Input() selectedSourceId: string;
    @Input() selectSourceCallback: (sourceId: string) => Observable<unknown>;

    get isDisabledSelectBtn(): boolean {
        return (
            !this.selectedSourceIdInTree ||
            this.selectedSourceIdInTree === this.selectedSourceId ||
            this.selectSourceState$.value === AsyncState.Loading
        );
    }

    selectedSourceIdInTree: string;
    searchValue: string;
    treeControl: FlatTreeControl<ISourceFlatTreeNode>;
    treeFlattener: McTreeFlattener<ISourceTreeNode, ISourceFlatTreeNode>;
    dataSource: McTreeFlatDataSource<ISourceTreeNode, ISourceFlatTreeNode>;

    loadingSourcesState$ = new BehaviorSubject<AsyncState>(AsyncState.Loading);
    selectSourceState$ = new BehaviorSubject<AsyncState>(AsyncState.Success);
    errorHttpCode$ = new BehaviorSubject<HttpStatus | null>(null);

    isLoaderVisible$ = combineLatest([this.loadingSourcesState$, this.selectSourceState$]).pipe(
        map(
            ([loadingSourcesState, selectSourceState]: [AsyncState, AsyncState]) =>
                loadingSourcesState === AsyncState.Loading || selectSourceState === AsyncState.Loading
        )
    );
    isSourcesLoaded$ = this.loadingSourcesState$.pipe(map((state) => state === AsyncState.Success));
    isSourcesLoadingFailed$ = this.loadingSourcesState$.pipe(map((state) => state === AsyncState.Failure));
    isSelectSourceFailed$ = this.selectSourceState$.pipe(map((state) => state === AsyncState.Failure));
    isSelectedSourcePreconditionFailed$ = this.errorHttpCode$.pipe(
        map((state) => state === HttpStatus.PRECONDITION_FAILED)
    );
    isErrorVisible$ = combineLatest([this.isSourcesLoadingFailed$, this.isSelectSourceFailed$]).pipe(
        map(([isSourcesLoadingFailed, isSelectSourceFailed]) => isSourcesLoadingFailed || isSelectSourceFailed)
    );

    get isEmptySearchResult(): boolean {
        return this.searchValue && this.treeControl.filterModel.isEmpty();
    }

    constructor(
        private modalRef: McModalRef,
        private dataSourcesFacade: DataSourcesFacade,
        private sourcesSyncService: SourcesSyncService
    ) {}

    ngOnInit() {
        this.treeFlattener = new McTreeFlattener(transformer, getLevel, isExpandable, getChildren);
        this.treeControl = new FlatTreeControl<ISourceFlatTreeNode>(getLevel, isExpandable, getValue, getViewValue);
        this.dataSource = new McTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.loadSources();
    }

    onSearchChange(searchValue: string): void {
        this.treeControl.filterNodes(searchValue);
    }

    selectSource() {
        this.selectSourceState$.next(AsyncState.Loading);

        this.selectSourceCallback(this.selectedSourceIdInTree)
            .pipe(
                tap(() => {
                    this.selectSourceState$.next(AsyncState.Success);
                    this.closeModal();
                }),
                catchError((errorHttpStatus: HttpStatus | null) => {
                    if (errorHttpStatus === HttpStatus.PRECONDITION_FAILED) {
                        this.errorHttpCode$.next(HttpStatus.PRECONDITION_FAILED);
                    }

                    this.selectSourceState$.next(AsyncState.Failure);

                    return of({});
                }),
                take(1)
            )
            .subscribe();
    }

    reloadSources() {
        this.loadSources();
    }

    hideSelectSourcesError() {
        this.selectSourceState$.next(undefined);
    }

    closeModal() {
        this.modalRef.destroy();
    }

    hasChild(index: number, nodeData: ISourceFlatTreeNode) {
        return nodeData.expandable;
    }

    private loadSources(): void {
        this.loadingSourcesState$.next(AsyncState.Loading);

        this.sourcesSyncService
            .loadAndSyncSources({ orderBy: DataSourcesOrderType.FullName })
            .pipe(
                tap(() => {
                    this.loadingSourcesState$.next(AsyncState.Success);
                }),
                switchMap((sourcesIds: string[]) => this.dataSourcesFacade.selectSources(sourcesIds)),
                catchError(() => {
                    this.loadingSourcesState$.next(AsyncState.Failure);

                    return of([] as IDataSource[]);
                }),
                take(1)
            )
            .subscribe((sources: IDataSource[]) => {
                this.initTreeDataSources(sources);
            });
    }

    private initTreeDataSources(sources: IDataSource[]): void {
        this.dataSource.data = buildSourcesTree(sources);
        this.treeControl.expandAll();

        if (this.selectedSourceId) {
            this.selectedSourceIdInTree = this.selectedSourceId;

            this.scrollToElement(this.selectedSourceId);
        }
    }

    private scrollToElement(elementId: string) {
        setTimeout(() => {
            const nodeElement = document.getElementById(elementId);

            nodeElement?.scrollIntoView();
        });
    }
}
