import { Component, Input } from '@angular/core';

import { SourcesNavigationService } from '@pt-cybsi/domain-core/sources';
import { BrandingConfig, ConfidenceFormat } from '@pt-cybsi/shared';

import { isVerdictAttribute } from '../../helpers';
import { TValuableFactView } from '../../types';

export enum ValuableFactsTableType {
    Attribute = 'Attribute',
    Relation = 'Relation'
}

/**
 * @component ValuableFactsTable
 *
 * @description
 * Component for displaying valuable facts table of attribute or relation
 *
 * @param type - type of table from ValuableFactsTableType
 * @param facts - list of valuable fact in TValuableFactView[] format
 *
 * @example
 * ```html
 *
 * <valuable-facts-table
 *       [facts]="[]"
 *       [type]="ValuableFactsTableType.Attribute">
 * </valuable-facts-table>
 * ```
 */
@Component({
    selector: 'valuable-facts-table',
    templateUrl: './valuable-facts-table.component.html',
    styleUrls: ['./valuable-facts-table.component.scss']
})
export class ValuableFactsTableComponent {
    @Input() type: ValuableFactsTableType;
    @Input() facts: TValuableFactView[];

    readonly ConfidenceFormat = ConfidenceFormat;
    readonly ValuableFactsTableType = ValuableFactsTableType;
    readonly isVerdictAttribute = isVerdictAttribute;

    readonly brandName = BrandingConfig.BrandName;

    get isDateVisible(): boolean {
        return this.facts.some((fact) => !!fact.seenAt);
    }

    constructor(private sourcesNavigationService: SourcesNavigationService) {}

    getSourceLink(id: string): string {
        return this.sourcesNavigationService.getPathOfSourceViewRoute(id);
    }
}
