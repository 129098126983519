import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IClientConfig } from '@pt-cybsi/api-interfaces';

import { CLIENT_CONFIG_FEATURE_KEY, State } from './client-config.reducer';

export const getClientConfigState = createFeatureSelector<State>(CLIENT_CONFIG_FEATURE_KEY);

export const getClientConfigFetchState = createSelector(getClientConfigState, (state: State) => state.state);

export const getClientConfig = createSelector(getClientConfigState, (state: State) => state?.config);
export const getDashboardClientConfig = createSelector(getClientConfig, (config: IClientConfig) => config?.dashboard);
