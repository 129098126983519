import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on, Action } from '@ngrx/store';

import { AuthState, HttpStatusCode, IAuthProvider, IUserFullInfo } from '@pt-cybsi/api-interfaces';
import { TResponseWithState, toFailureResponse, toLoadingResponse, toSuccessResponse } from '@pt-cybsi/shared';

import * as AccountActions from './account.actions';
import { IRefreshTokenError } from './account.effects';

export const ACCOUNT_FEATURE_KEY = 'account';

export interface State {
    isTokenRefreshing: boolean;
    isSessionExpired: boolean;
    token: string;
    user: IUserFullInfo;
    providers: TResponseWithState<IAuthProvider[]>;
}

export interface AccountPartialState {
    readonly [ACCOUNT_FEATURE_KEY]: State;
}

export const initialState: State = {
    isTokenRefreshing: false,
    isSessionExpired: false,
    token: undefined,
    user: undefined,
    providers: toLoadingResponse([])
};

const isSessionExpiredByResponse = (response: HttpErrorResponse) => {
    const responseStatus = response.status;
    const responseData = response.error as IRefreshTokenError;
    const authState = responseData?.authState;

    const isSessionExpiredState = [AuthState.AuthenticationWait].includes(authState);

    return responseStatus === HttpStatusCode.NOT_FOUND || isSessionExpiredState;
};

const accountReducer = createReducer(
    initialState,
    on(AccountActions.refreshToken, (state) => ({
        ...state,
        isTokenRefreshing: true,
        isSessionExpired: false
    })),
    on(AccountActions.setRefreshTokenError, (state, { response }) => ({
        ...state,
        token: undefined,
        isTokenRefreshing: false,
        isSessionExpired: isSessionExpiredByResponse(response)
    })),
    on(AccountActions.setToken, (state, { token }) => ({
        ...state,
        token,
        isTokenRefreshing: false,
        isSessionExpired: false
    })),
    on(AccountActions.resetToken, (state) => ({
        ...state,
        token: undefined,
        isTokenRefreshing: false,
        isSessionExpired: false
    })),
    on(AccountActions.fetchUserSuccess, (state, { user }) => ({
        ...state,
        user
    })),
    on(AccountActions.fetchUserFailure, AccountActions.resetUser, (state) => ({ ...state, user: undefined })),
    on(AccountActions.fetchAuthProviders, (state) => ({
        ...state,
        providers: toLoadingResponse([])
    })),
    on(AccountActions.fetchAuthProvidersSuccess, (state, { providers }) => ({
        ...state,
        providers: toSuccessResponse(providers)
    })),
    on(AccountActions.fetchAuthProvidersFailure, (state) => ({
        ...state,
        providers: toFailureResponse(null)
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return accountReducer(state, action);
}
