import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { ObservableEntityType } from '@pt-cybsi/api-interfaces';
import { createASCSortComparator } from '@pt-cybsi/shared';

/**
 * @pipe TranslateObservableEntityType
 *
 * @description Converts observable entity type to localized string
 */
@Pipe({
    name: 'translateObservableEntityType'
})
export class TranslateObservableEntityTypePipe implements PipeTransform {
    constructor(private translocoService: TranslocoService) {}

    transform(type: ObservableEntityType): string {
        return this.translocoService.translate(`entities.ObservableEntities.Type.Text.${type}`);
    }
}

/**
 * @pipe TranslateObservableEntityTypes
 *
 * @description Converts observable entity types to ASC sorted localized strings array
 */
@Pipe({
    name: 'translateObservableEntityTypes'
})
export class TranslateObservableEntityTypesPipe implements PipeTransform {
    private readonly sortComparator = createASCSortComparator<string>((item) => item);

    constructor(private translateEntityType: TranslateObservableEntityTypePipe) {}

    transform(types: ObservableEntityType[]): string[] {
        const translatedTypes = types.map((type) => this.translateEntityType.transform(type));

        return translatedTypes.sort(this.sortComparator);
    }
}
