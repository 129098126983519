import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AccountNavigationService {
    static readonly routes = {
        view: '',
        edit: 'edit',
        signIn: 'signin'
    };

    readonly root = '/account';

    constructor(private router: Router) {}

    goToViewPage(): Promise<boolean> {
        return this.router.navigate([this.getPathOfViewRoute()]);
    }

    goToEditPage(): void {
        this.router.navigate([this.getPathOfEditRoute()]);
    }

    goToSignInPage(): void {
        this.router.navigate([this.getPathOfSignInRoute()]);
    }

    getPathOfViewRoute(): string {
        return `${this.root}`;
    }

    getPathOfEditRoute(): string {
        return `${this.root}/${AccountNavigationService.routes.edit}`;
    }

    getPathOfSignInRoute(): string {
        return `${this.root}/${AccountNavigationService.routes.signIn}`;
    }
}
