import { IRelationConfig, TAggregate, TObservableEntity } from '@pt-cybsi/api-interfaces';
import { Opaque } from '@pt-cybsi/core';

import { EntityFullInfoModel } from '../models';

export const RELATION_ID_SEPARATOR = '_' as const;

export type TRelationTypeId = Opaque<string, 'RelationTypeId'>;
export const RelationTypeId = (id: string) => id as TRelationTypeId;

export type TRelationLinkTypeId = Opaque<string, 'RelationLinkTypeId'>;
export const RelationLinkTypeId = (id: string) => id as TRelationLinkTypeId;

export type TRelationLinkId = Opaque<string, 'RelationLinkId'>;
export const RelationLinkId = (id: string) => id as TRelationLinkId;

export type TAvailableRelationEntityFormat = TObservableEntity | TAggregate | EntityFullInfoModel;

export interface IRelationMetadata<EntityFormat extends TAvailableRelationEntityFormat = TObservableEntity> {
    id: TRelationTypeId;
    config: IRelationConfig;
    sourceEntity: EntityFormat;
    targetEntity: EntityFormat;
    confidence?: number;
}
