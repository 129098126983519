import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'error-alert',
    templateUrl: './error-alert.component.html',
    styleUrls: ['./error-alert.component.scss']
})
export class ErrorAlertComponent {
    @Input() text: string;
    @Input() hasRetry: boolean;
    @Input() retryText: string;

    @Output() retry: EventEmitter<void> = new EventEmitter<void>();

    handleRetry(): void {
        this.retry.emit();
    }
}
