import { Injectable } from '@angular/core';
import { McModalRef, McModalService, ModalSize } from '@ptsecurity/mosaic/modal';
import { take } from 'rxjs/operators';

import { SessionExpiredModalComponent } from '../components';

@Injectable({ providedIn: 'root' })
export class SessionExpiredNotificationService {
    private notification: McModalRef;

    constructor(private mcModalService: McModalService) {}

    show(): void {
        if (this.notification) {
            return;
        }

        this.notification = this.mcModalService.open({
            mcComponent: SessionExpiredModalComponent,
            mcSize: ModalSize.Small,
            mcClosable: false,
            mcMaskClosable: false,
            mcCloseByESC: false,
            mcWrapClassName: 'session-expired-modal-wrapper'
        });

        this.notification.afterClose.pipe(take(1)).subscribe(() => {
            this.notification = null;
        });
    }
}
