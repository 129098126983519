import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, exhaustMap, filter, map, switchMap, take, tap } from 'rxjs/operators';

export enum AsyncValidationStatus {
    Idle = 'Idle',
    Pending = 'Pending',
    Complete = 'Complete'
}

@Injectable()
export class AsyncValidationService {
    get status$(): Observable<AsyncValidationStatus> {
        if (this.controls$.getValue().length === 0) {
            return of(AsyncValidationStatus.Complete);
        }

        return this.controls$.pipe(
            map(() => [...this.controlsStatuses.values()]),
            switchMap((statuses$) => combineLatest([...statuses$])),
            map((statuses) => {
                const hasPendingControl = statuses.indexOf(AsyncValidationStatus.Pending) !== -1;

                if (hasPendingControl) {
                    return AsyncValidationStatus.Pending;
                }

                return AsyncValidationStatus.Complete;
            })
        );
    }

    private controls$: BehaviorSubject<AbstractControl[]> = new BehaviorSubject([]);

    private controlsStatuses: Map<AbstractControl, BehaviorSubject<AsyncValidationStatus>> = new Map();

    registerValidator(
        control: AbstractControl,
        validator: AsyncValidatorFn
    ): { validation$: Observable<unknown>; validate(): void } {
        const controlStatus$ = new BehaviorSubject(AsyncValidationStatus.Idle);

        this.controls$.next([...this.controls$.value, control]);
        this.controlsStatuses.set(control, controlStatus$);

        const changeValue$ = control.valueChanges.pipe(
            distinctUntilChanged(),
            tap(() => controlStatus$.next(AsyncValidationStatus.Idle))
        );

        const triggerValidation$: Subject<void> = new Subject();

        let validationError: ValidationErrors;

        const existedValidators = control.validator ? [control.validator] : [];

        control.setValidators([...existedValidators, () => validationError]);

        const validation$ = changeValue$.pipe(
            tap(() => (validationError = null)),
            switchMap((value) =>
                triggerValidation$.pipe(
                    filter(() => !!value),
                    tap(() => controlStatus$.next(AsyncValidationStatus.Pending)),
                    exhaustMap(() => validator(control)),
                    tap((error) => (validationError = error)),
                    take(1),
                    tap(() => control.updateValueAndValidity({ emitEvent: false })),
                    tap(() => controlStatus$.next(AsyncValidationStatus.Complete))
                )
            )
        );

        return {
            validate: () => triggerValidation$.next(),
            validation$
        };
    }
}
