import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { LazyDataState } from '../../models';

@Component({
    selector: 'lazy-data-container',
    templateUrl: './lazy-data-container.component.html'
})
export class LazyDataContainerComponent {
    @Input() state: LazyDataState;
    @Input() errorTitle: string;
    @Input() errorDescription: string;
    @Input() textReload: string;

    @Output() reload = new EventEmitter<void>();

    get isLoaderVisible(): boolean {
        return this.state === LazyDataState.Loading || this.state === LazyDataState.Error;
    }
}
