import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { IFormViewModelBuilder, TFormViewModelProps } from '@pt-cybsi/shared';

import {
    PasswordFormConfig,
    PasswordFormViewModel,
    TPasswordFormConfig,
    TPasswordFormData
} from './password-form.view-model';

@Injectable()
export class PasswordFormBuilder implements IFormViewModelBuilder<TPasswordFormData, TPasswordFormConfig> {
    formConfig = PasswordFormConfig;

    constructor(private fb: UntypedFormBuilder) {}

    build(props: TFormViewModelProps<TPasswordFormData>): PasswordFormViewModel {
        const form = this.buildFormGroup();

        return new PasswordFormViewModel(props, form, this.formConfig);
    }

    private buildFormGroup(): UntypedFormGroup {
        const { id, oldPassword, newPassword } = this.formConfig;

        const required = Validators.required;

        return this.fb.group({
            [id.controlName]: undefined,

            [oldPassword.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ],
            [newPassword.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ]
        });
    }
}
