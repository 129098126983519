import { NgModule } from '@angular/core';

import { SharedModule } from '@pt-cybsi/shared';
import { StoreErrorModule } from '@pt-cybsi/store/error';

import { ErrorViewComponent } from './containers';

@NgModule({
    imports: [SharedModule, StoreErrorModule],
    declarations: [ErrorViewComponent],
    exports: [ErrorViewComponent]
})
export class FeatureErrorModule {}
