import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'property-row',
    templateUrl: './property-row.component.html',
    styleUrls: ['./property-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PropertyRowComponent {
    @Input() title: string;
}
