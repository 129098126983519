export interface IClientConfig {
    dashboard: IDashboardClientConfig;
}

export interface IDashboardClientConfig {
    dashboardLinkTemplate: string;
    panelItem: { [K in DashboardChartName]: { panelId: string } };
}

export enum DashboardChartName {
    ObjectsRegistrationsByTypes = 'object_registrations_by_type',
    ObjectsRegistrationsBySources = 'object_registrations_by_source',
    FactsOfAttributesRegistrations = 'attribute_fact_registrations',
    FactsOfRelationsRegistrations = 'relationship_fact_registrations',
    ReportsRegistrationsBySources = 'report_registrations_by_source',
    ObservationsRegistrationsByTypes = 'observation_registrations_by_type',
    ArtifactsRegistrationsByTypes = 'artifact_registrations_by_type',
    ArtifactsRegistrationsBySources = 'artifact_registrations_by_source',
    EnrichmentsStartedByTypes = 'enrichment_task_enqueued_by_type',
    EnrichmentsFinishedByTypes = 'enrichment_task_completed_by_type'
}
