import { Observable } from 'rxjs';

export const animate = (options: {
    update: (progress: number) => void;
    complete?: () => void;
    duration: number;
    canceller$: Observable<void>;
}) => {
    const { update, complete, duration, canceller$: destroyed$ } = options;

    const start = performance.now();

    let requestId: number;

    const cancellerSubscription = destroyed$.subscribe(() => {
        cancelAnimationFrame(requestId);
    });

    const animateStep = () => {
        const timePassed = performance.now() - start;

        let progress = timePassed / duration;

        if (progress > 1) {
            progress = 1;
        }

        update(progress);

        if (progress < 1) {
            requestId = requestAnimationFrame(animateStep);
        }

        if (progress === 1 && typeof complete === 'function') {
            complete();

            cancellerSubscription.unsubscribe();
        }
    };

    requestId = requestAnimationFrame(animateStep);
};
