export enum AddDictionaryItemErrorCode {
    DictionaryItemConflict = 'DictionaryItemConflict'
}

export enum AddDictionaryItemToSynonymsErrorCode {
    InvalidDictionary = 'InvalidDictionary',
    SynonymGroupConflict = 'SynonymGroupConflict',
    ItemAlreadyInSynonymGroup = 'ItemAlreadyInSynonymGroup',
    InvalidSynonym = 'InvalidSynonym',
    DictionaryItemNotFound = 'DictionaryItemNotFound'
}
