import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { IUser } from '@pt-cybsi/api-interfaces';

import { UsersNavigationService } from '../../services';

@Component({
    selector: 'user-preview',
    templateUrl: './user-preview.component.html',
    styleUrls: ['./user-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPreviewComponent implements OnInit {
    @Input() user!: IUser;

    userViewLink = '';

    get userName(): string {
        return this.user.fullName || this.user.login;
    }

    constructor(private usersNavigationService: UsersNavigationService) {}

    ngOnInit() {
        this.userViewLink = this.usersNavigationService.getPathOfViewRoute(this.user.uuid);
    }
}
