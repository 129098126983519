import { buildFormConfiguration, FormViewModel } from '@pt-cybsi/shared';

import { TExternalDBFormData } from '../../mappers';

/**
 * @ViewModel ExternalDBForm
 *
 * @description
 * Model of Angular Reactive Form with additional features for an external database creation and edit form.
 * Contains extended information about a form state, a working mode, and a saving error.
 *
 * For creation FormViewModel need to create a factory `ExternalDBFormBuilder`
 */
export class ExternalDBFormViewModel extends FormViewModel<TExternalDBFormData, TExternalDBFormConfig> {}

const FormConfig = {
    id: {
        controlName: 'id',
        errorNames: null
    },
    sourceId: {
        controlName: 'sourceId',
        errorNames: null
    },
    serverId: {
        controlName: 'serverId',
        errorNames: null
    },
    eTag: {
        controlName: 'eTag',
        errorNames: null
    },
    entityTypes: {
        controlName: 'entityTypes',
        errorNames: {
            required: 'required'
        }
    },
    webPageURL: {
        controlName: 'webPageURL',
        errorNames: {
            format: 'invalidKey'
        }
    },
    taskExecutionTimeout: {
        controlName: 'taskExecutionTimeout',
        errorNames: {
            range: 'range'
        }
    },
    taskExecutionAttemptsCount: {
        controlName: 'taskExecutionAttemptsCount',
        errorNames: {
            range: 'range'
        }
    }
} as const;

export type TExternalDBFormConfig = typeof FormConfig;

export const ExternalDBFormConfig = buildFormConfiguration<TExternalDBFormData, TExternalDBFormConfig>(FormConfig);
