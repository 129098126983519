import { Injectable, Provider } from '@angular/core';

import { ValidateFormUseCase } from '@pt-cybsi/shared';

import { EnrichmentRuleModel } from '../../models';
import {
    EnrichmentRuleFormRepository,
    EnrichmentRuleFormRepositoryProvider,
    IEnrichmentRuleFormRepository
} from '../../repositories';

@Injectable()
export class ValidateEnrichmentRuleUseCase extends ValidateFormUseCase<EnrichmentRuleModel> {
    constructor(repository: IEnrichmentRuleFormRepository) {
        super(repository);
    }
}

export const ValidateEnrichmentRuleUseCaseProvider: Provider = [
    EnrichmentRuleFormRepositoryProvider,
    {
        provide: ValidateEnrichmentRuleUseCase,
        useFactory: (repository: EnrichmentRuleFormRepository) => new ValidateEnrichmentRuleUseCase(repository),
        deps: [EnrichmentRuleFormRepository]
    }
];
