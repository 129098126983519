import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import isNil from 'lodash/isNil';
import { map } from 'rxjs/operators';

import { AsyncState } from '@pt-cybsi/shared';

import * as ClientConfigActions from './client-config.actions';
import * as ClientConfigSelectors from './client-config.selectors';

@Injectable()
export class ClientConfigStoreFacade {
    isInit$ = this.store.pipe(
        select(ClientConfigSelectors.getClientConfigFetchState),
        map((state) => state === AsyncState.Success || state === AsyncState.Failure)
    );

    dashboardClientConfig$ = this.store.pipe(select(ClientConfigSelectors.getDashboardClientConfig));

    isDashboardAvailable$ = this.dashboardClientConfig$.pipe(
        map((dashboardClientConfig) => !isNil(dashboardClientConfig))
    );

    constructor(private store: Store) {}

    fetchClientConfig() {
        this.store.dispatch(ClientConfigActions.fetchClientConfig());
    }
}
