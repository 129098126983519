import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IProductVersionJson, IVersion } from '@pt-cybsi/api-interfaces';

import { BaseApiService } from './base-api.service';

enum DocumentationType {
    ReleaseNotes = 'releasenotes',
    UserGuide = 'userguide'
}

@Injectable()
export class SystemInfoApiService extends BaseApiService {
    static readonly urls = {
        systemVersion: 'version',
        productVersion: 'product-version.json',
        docs: 'docs'
    };

    getSystemVersion(): Observable<IVersion> {
        return this.get<IVersion>(SystemInfoApiService.urls.systemVersion);
    }

    getProductVersion(): Observable<IProductVersionJson> {
        return this.http.get<IProductVersionJson>(SystemInfoApiService.urls.productVersion);
    }

    getUserGuideUrl() {
        return this.getUrlToDocumentation(DocumentationType.UserGuide);
    }

    getReleaseNotesUrl() {
        return this.getUrlToDocumentation(DocumentationType.ReleaseNotes);
    }

    hasDocumentation(url: string): Observable<boolean> {
        return this.http.head(url).pipe(
            map(() => true),
            catchError(() => of(false))
        );
    }

    private getUrlToDocumentation(type: DocumentationType): string {
        // пока документация только на русском, когда будет английская поменять на
        // ${SystemInfoApiService.urls.docs}/${this.localeService.currentLocale}/cybsi_${type}_${this.localeService.currentLang}.pdf
        return `${SystemInfoApiService.urls.docs}/ru-RU/cybsi_${type}_ru.pdf`;
    }
}
