import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { LOCAL_AUTH_PROVIDER_ID } from '@pt-cybsi/api-interfaces';
import { LocationService } from '@pt-cybsi/core';
import { AccountFacade } from '@pt-cybsi/store/account';

@Injectable({
    providedIn: 'root'
})
export class IsLocalProviderGuard implements CanActivate {
    private isLocalProvider: boolean;

    constructor(private accountFacade: AccountFacade, private locationService: LocationService) {
        this.accountFacade.user$.subscribe((user) => {
            this.isLocalProvider = user?.authProviderID === LOCAL_AUTH_PROVIDER_ID;
        });
    }

    canActivate(): boolean {
        if (this.isLocalProvider) {
            return true;
        }

        this.locationService.redirectToForbiddenPage();

        return false;
    }
}
