<input
    type="text"
    #mcInput
    mcInput
    [mcAutocomplete]="autocompleteTypes"
    [ngModel]="inputValue"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [required]="required"
    (focus)="handleInputFocus()"
    (blur)="handleInputBlur()"
    (ngModelChange)="handleInputChange($event)"/>
<mc-autocomplete
    *transloco="let tCommon; read: 'common'"
    #autocompleteTypes="mcAutocomplete"
    class="dictionary-item-autocomplete-field__options"
    [displayWith]="autocompleteDisplayFn"
    [openOnFocus]="true"
    (optionSelected)="handleSelectOption($event)"
    (closed)="handleCloseAutocomplete()"
    (opened)="handleOpenAutocomplete()">
    <mc-option
        *ngIf="(isLoaded$ | async) === false"
        [class.hidden]="(isLoadingFailed$ | async) === false"
        class="dictionary-item-autocomplete-field__error-option"
        [disabled]="true">
        <error-alert
            [text]="tCommon('Common.Loading.Text.ErrorTitle')"
            [hasRetry]="true"
            (retry)="handleRetry()">
        </error-alert>
    </mc-option>
    <mc-option
        *ngIf="isLoading$ | async"
        [disabled]="true">
        <mc-progress-spinner
            [mode]="'indeterminate'"
            class="dictionary-item-autocomplete-field__spinner">
        </mc-progress-spinner>
    </mc-option>
    <ng-container *ngIf="isLoaded$ | async">
        <mc-option *ngFor="let option of autocompleteOptions" [value]="option">
            {{ getAutocompleteOptionText(option) }}
        </mc-option>
    </ng-container>
</mc-autocomplete>
