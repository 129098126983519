import { AttributeObservableEntity } from './attributes';

export interface IFactOfAttribute<AttributeName extends AttributeObservableEntity, ValueType> {
    attributeName: AttributeName;
    value: ValueType;
    values: {
        confidence: number;
        value: ValueType;
    }[];
    hasConflicts: boolean;
}
