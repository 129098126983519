import { ObservableEntityType } from '../common';

import {
    IAttributeSearchQuery,
    IDomainSpecificSearchFilter,
    IFileSpecificSearchFilter,
    IIdentitySpecificSearchFilter,
    IUrlSpecificSearchFilter
} from './attribute-search';

export interface IFilterInfo {
    general: Partial<IAttributeSearchQuery>;
    identity?: IIdentitySpecificSearchFilter;
    file?: IFileSpecificSearchFilter;
    url?: IUrlSpecificSearchFilter;
    domainName?: IDomainSpecificSearchFilter;
}

export interface ISavedFilterInfo extends IFilterInfo {
    id: string;
    name: string;
    version: string;
    isLoading: boolean;
    isObjectsLoading?: boolean;
    isSourcesLoading?: boolean;
}

export interface IFilterCreationParams extends IFilterInfo {
    name: string;
}

export interface ICreateFilterResponse {
    id: string;
}

export interface ISearchFilterPreview {
    id: string;
    name: string;
}

export type TFiltersResponse = ISearchFilterPreview[];

export type IFilterResponse = ISearchFilterPreview & IFilterInfo;

export const SPECIFIC_FIELDS_TYPES: ObservableEntityType[] = [
    ObservableEntityType.File,
    ObservableEntityType.URL,
    ObservableEntityType.Identity
];
