import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ReputationListsNavigationService {
    static routes = {
        list: '',
        create: 'create',
        edit: 'edit/:reputationListId',
        view: 'view/:reputationListId'
    };

    readonly root = '/replists';

    constructor(private router: Router) {}

    goToViewPage(reputationListId: string): void {
        const path = this.getPathOfViewRoute(reputationListId);

        this.router.navigate([path]);
    }

    goToListPage(): void {
        const path = this.getPathOfListRoute();

        this.router.navigate([path]);
    }

    getPathOfViewRoute(reputationListId: string): string {
        return `${this.root}/${ReputationListsNavigationService.routes.view.replace(
            ':reputationListId',
            reputationListId
        )}`;
    }

    getPathOfListRoute(): string {
        return `${this.root}/${ReputationListsNavigationService.routes.list}`;
    }

    getPathOfEditRoute(reputationListId: string): string {
        return `${this.root}/${ReputationListsNavigationService.routes.edit.replace(
            ':reputationListId',
            reputationListId
        )}`;
    }

    getPathOfCreateRoute(): string {
        return `${this.root}/${ReputationListsNavigationService.routes.create}`;
    }
}
