import { Directive, ElementRef, HostBinding, HostListener } from '@angular/core';

@Directive({
    selector: '[pt-title]'
})
export class PtTitleDirective {
    @HostBinding('attr.title') title: string;

    private formatter: (title: string) => string = PtTitleDirective.defaultFormatter;

    constructor(private elementRef: ElementRef) {}

    static defaultFormatter(title: string): string {
        return title.replace(/\r?\n/g, ' ').replace(/\s+/g, ' ').trim();
    }

    @HostListener('mouseenter')
    handleMouseEnter() {
        this.updateTitle();
    }

    private updateTitle(): void {
        const element: HTMLElement = this.elementRef.nativeElement as HTMLElement;
        const canShowTitle = element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight;
        this.title = canShowTitle ? this.formatter(element.innerText) : undefined;
    }
}
