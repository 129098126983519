export const MIN_CONFIDENCE = 0;
export const MAX_CONFIDENCE = 1;

export const validateConfidenceRange = (confidence: number) =>
    confidence === undefined || confidence === null || (confidence >= MIN_CONFIDENCE && confidence <= MAX_CONFIDENCE);

export const CONFIDENCE_ACCURACY = 3;

export const validateConfidenceAccuracy = (confidence: number) =>
    confidence === undefined ||
    confidence === null ||
    Number.isInteger(
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        confidence * 10 ** CONFIDENCE_ACCURACY
    );
