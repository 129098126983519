<form class="mc-form-vertical"
      novalidate
      (ngSubmit)="handleSubmit()">
    <mc-modal-title>
        {{ 'users.Users.EditApiKeyModal.Title.EditApiKey' | transloco }}
    </mc-modal-title>
    <mc-modal-body>
        <progress-container [loadingFlag]="isInitializing || form.isSending">
            <div *ngIf="isInitializingFailed"
                 class="mc-alert mc-alert_error margin-bottom_16">
                <i mc-icon="mc-error_16" class="mc-alert__icon"></i>
                <div class="layout-column layout-align-start-start">
                    <div>{{ 'common.Common.Loading.Text.ErrorTitle' | transloco }}</div>
                    <button mc-link pseudo class="mc-link clear-button" (click)="reloadApiKey()">
                        <span class="mc-link__text">{{ 'common.Common.Loading.Text.RetryButton' | transloco }}</span>
                    </button>
                </div>
            </div>

            <div *ngIf="form.isSavingFailed"
                 class="mc-alert mc-alert_error margin-bottom_16">
                <i mc-icon="mc-error_16" class="mc-alert__icon"></i>
                <div *ngIf="isAlreadyChangedError; else defaultError" class="layout-column layout-align-start-start">
                    <div>{{ 'users.Users.EditApiKeyModal.Error.AlreadyChanged' | transloco }}</div>
                    <button mc-link pseudo class="mc-link clear-button" (click)="reloadApiKey()">
                        <span class="mc-link__text">{{ 'users.Users.EditApiKeyModal.Error.ReloadData' | transloco }}</span>
                    </button>
                </div>

                <ng-template #defaultError>{{ 'users.Users.EditApiKeyModal.Error.Save' | transloco }}</ng-template>
            </div>

            <api-key-form autoFocusFirstField *ngIf="form.isReady" [form]="form"></api-key-form>
        </progress-container>
    </mc-modal-body>
    <mc-modal-footer>
        <button mc-button color="second" type="button" class="revoke-button"
                [disabled]="isSubmitDisabled"
                (click)="revokeApiKey()">
            <i mc-icon="pt-icons-minus-circle_16" class="pt-icons"></i>
            {{ 'users.Users.EditApiKeyModal.Button.RevokeKey' | transloco }}
        </button>
        <button mc-button color="primary" type="submit" [disabled]="isSubmitDisabled">
            {{ 'common.Common.Pseudo.Button.Change' | transloco }}
        </button>
        <button mc-button color="second" type="button" (click)="closeModal()">
            {{ 'common.Common.Pseudo.Button.Cancel' | transloco }}
        </button>
    </mc-modal-footer>
</form>
