import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AccountNavigationService, SessionExpiredNotificationService } from '../services';
import { SessionExpiredRedirectPreventersStore } from '../stores';

@Injectable({ providedIn: 'root' })
export class CanSessionExpiredRedirectGuard implements CanDeactivate<unknown> {
    constructor(
        private accountNavigationService: AccountNavigationService,
        private sessionExpiredRedirectPreventersStore: SessionExpiredRedirectPreventersStore,
        private sessionExpiredNotificationService: SessionExpiredNotificationService
    ) {}

    canDeactivate(
        component: unknown,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): Observable<boolean> {
        const isSignInRedirect = nextState?.url === this.accountNavigationService.getPathOfSignInRoute();

        const canRedirect$ = this.sessionExpiredRedirectPreventersStore.isPreventRedirect$.pipe(
            map((isPreventRedirect) => !(isSignInRedirect && isPreventRedirect))
        );

        return canRedirect$.pipe(
            tap((canRedirect) => {
                if (!canRedirect) {
                    this.sessionExpiredNotificationService.show();
                }
            })
        );
    }
}
