import { Component, Input } from '@angular/core';

import { IRelationConfig, RelationDirection } from '@pt-cybsi/api-interfaces';

@Component({
    selector: 'relation-type-preview',
    templateUrl: './relation-type-preview.component.html',
    styleUrls: ['./relation-type-preview.component.scss']
})
export class RelationTypePreviewComponent {
    @Input() config: IRelationConfig;
    @Input() confidence: number;

    get isForward(): boolean {
        return this.config.direction === RelationDirection.Forward;
    }

    get isReverse(): boolean {
        return this.config.direction === RelationDirection.Reverse;
    }

    readonly separator = '·';
}
