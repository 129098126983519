import { Pipe, PipeTransform } from '@angular/core';
import compact from 'lodash/compact';

export const ARRAY_AS_STRING_DELIMITER = ', ';

@Pipe({
    name: 'arrayAsString'
})
export class ArrayAsStringPipe implements PipeTransform {
    transform(array: unknown[], delimiter = ARRAY_AS_STRING_DELIMITER): string {
        if (Array.isArray(array)) {
            return compact(array).join(delimiter);
        }

        return '';
    }
}
