import { IPaginationParams, Role, ShareLevel } from '@pt-cybsi/api-interfaces';

export interface IUsersGetParams extends IPaginationParams {
    query?: string;
    isDisabled?: boolean;
    uuid?: string[];
    providerID?: string[];
    dataSourceUUID?: string[];
}

export interface IUserCreateParams {
    login: string;
    fullName?: string;
    email?: string;
    password?: string;
    accessLevel: ShareLevel;
    roles: Role[];
}

export interface IUserUpdateParams {
    fullName?: string;
    email?: string;
    accessLevel?: ShareLevel;
    roles?: Role[];
    password?: string;
    dataSourceUUID?: string;
    isDisabled?: boolean;
}

export interface ICurrentUserUpdateParams {
    fullName?: string;
    email?: string;
}

export interface ICurrentUserPasswordUpdateParams {
    oldPassword: string;
    newPassword: string;
}
