<div class="limited-collection limited-collection_style_{{ collectionRenderStyle }}">
    <ul class="limited-collection__list">
        <li *ngFor="let item of collection | slice:0:itemsLimit trackBy trackByFn; let last = last;"
            class="limited-collection__item"
            [class.limited-collection__item_last]="last">
            <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item }"></ng-container>
            <span *ngIf="!last && hasItemSeparator">
                {{ collectionItemSeparator }}
            </span>
            <span *ngIf="last && isUIGridColumnRenderStyle && isNeedCutElement"
                  class="layout-left-margin">
                <span mc-link
                      pseudo
                      id="limited-collection-handle-click-by-show-more-button"
                      class="mc-button_transparent"
                      (click)="handleClickMore()"
                      [innerHTML]="'common.Common.LimitedCollection.Text.ShowAllButton' | transloco: { count: length }">
                </span>
            </span>
        </li>
        <li *ngIf="!isUIGridColumnRenderStyle && isNeedCutElement"
            class="limited-collection__overflow">
            <button
                id="limited-collection-handle-click-by-show-more-button"
                mc-link
                pseudo
                class="mc-link clear-button"
                (click)="handleClickMore()">
                {{ 'common.Common.LimitedCollection.Text.ShowAllButton' | transloco: { count: length } }}
            </button>
        </li>
        <li *ngIf="actionsTemplate" class="limited-collection__actions">
            <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
        </li>
    </ul>
</div>
