import { ObservableEntityType } from '../common';

import { TAggregateSection } from './aggregate-section';
import { IBaseObservableEntity } from './entity';

// prettier-ignore
export type TAggregate<OET = ObservableEntityType> =
    OET extends ObservableEntityType.DomainName ? TDomainNameAggregate :
    OET extends ObservableEntityType.IPAddress ? TIpAddressAggregate :
    OET extends ObservableEntityType.EmailAddress ? TEmailAddressAggregate :
    OET extends ObservableEntityType.URL ? TUrlAggregate :
    OET extends ObservableEntityType.PhoneNumber ? TPhoneNumberAggregate :
    OET extends ObservableEntityType.Identity ? TIdentityAggregate :
    OET extends ObservableEntityType.File ? TFileAggregate :
        | TDomainNameAggregate
        | TIpAddressAggregate
        | TEmailAddressAggregate
        | TUrlAggregate
        | TPhoneNumberAggregate
        | TIdentityAggregate
        | TFileAggregate;

export type TDomainNameAggregate = IAggregate<ObservableEntityType.DomainName>;
export type TIpAddressAggregate = IAggregate<ObservableEntityType.IPAddress>;
export type TEmailAddressAggregate = IAggregate<ObservableEntityType.EmailAddress>;
export type TUrlAggregate = IAggregate<ObservableEntityType.URL>;
export type TPhoneNumberAggregate = IAggregate<ObservableEntityType.PhoneNumber>;
export type TIdentityAggregate = IAggregate<ObservableEntityType.Identity>;
export type TFileAggregate = IAggregate<ObservableEntityType.File>;

export interface IAggregate<OET extends ObservableEntityType> extends IBaseObservableEntity<OET> {
    sections: TAggregateSection<OET>[];
}

export type TIndicatorAggregate = TAggregate<
    | ObservableEntityType.File
    | ObservableEntityType.IPAddress
    | ObservableEntityType.DomainName
    | ObservableEntityType.URL
    | ObservableEntityType.EmailAddress
    | ObservableEntityType.PhoneNumber
>;

export type TTrustedAggregate = TAggregate<
    | ObservableEntityType.File
    | ObservableEntityType.IPAddress
    | ObservableEntityType.DomainName
    | ObservableEntityType.URL
    | ObservableEntityType.EmailAddress
>;

export type TThreatAggregate = TAggregate<
    | ObservableEntityType.File
    | ObservableEntityType.IPAddress
    | ObservableEntityType.DomainName
    | ObservableEntityType.URL
    | ObservableEntityType.EmailAddress
>;
