import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export const collectStateChanges = <T>(
    stateChanges$: Observable<T>,
    isSameChanges: (previousChanges: T, currentChanges: T) => boolean = () => false
) => {
    const stateChanges = [] as T[];

    stateChanges$.pipe(distinctUntilChanged(isSameChanges)).subscribe((state) => stateChanges.push(state));

    return stateChanges;
};
