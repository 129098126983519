import { NgModule, Optional, SkipSelf } from '@angular/core';

import { SharedModule } from '@pt-cybsi/shared';
import { StoreRouterModule } from '@pt-cybsi/store/router';

import { LegacyContentDummyComponent } from './components';
import { LegacyApplicationContentComponent } from './containers';

@NgModule({
    imports: [SharedModule, StoreRouterModule],
    declarations: [LegacyApplicationContentComponent, LegacyContentDummyComponent],
    exports: [LegacyApplicationContentComponent, LegacyContentDummyComponent]
})
export class LegacyApplicationModule {
    constructor(@Optional() @SkipSelf() parentModule?: LegacyApplicationModule) {
        if (parentModule) {
            throw new Error('LegacyApplicationModule is already loaded. Import it in the AppModule only');
        }
    }
}
