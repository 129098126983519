import { Component, Input, TemplateRef } from '@angular/core';

import { SearchPageLayoutState } from '../../models';

@Component({
    selector: 'search-page-layout',
    templateUrl: './search-page-layout.component.html',
    styleUrls: ['./search-page-layout.component.scss']
})
export class SearchPageLayoutComponent {
    @Input()
    headerTemplate: TemplateRef<unknown>;

    @Input()
    filtersTemplate: TemplateRef<unknown>;

    @Input()
    errorsTemplate: TemplateRef<unknown>;

    @Input()
    resultsTemplate: TemplateRef<unknown>;

    @Input()
    emptyResultsTemplate: TemplateRef<unknown>;

    @Input()
    notFoundResultsTemplate: TemplateRef<unknown>;

    @Input()
    state: SearchPageLayoutState;

    get hasHeaderSlot(): boolean {
        return this.hasSlot(this.headerTemplate);
    }

    get hasFiltersSlot(): boolean {
        return this.hasSlot(this.filtersTemplate);
    }

    get hasErrorsSlot(): boolean {
        return this.hasSlot(this.errorsTemplate);
    }

    get hasResultsSlot(): boolean {
        return this.hasSlot(this.resultsTemplate);
    }

    get hasEmptyResultsSlot(): boolean {
        return this.hasSlot(this.emptyResultsTemplate);
    }

    get hasNotFoundResultsSlot(): boolean {
        return this.hasSlot(this.notFoundResultsTemplate);
    }

    get isEmptyResultsSlotVisible(): boolean {
        return this.hasEmptyResultsSlot && this.isMatchedState([SearchPageLayoutState.LoadingEmpty]);
    }

    get isNotFoundResultsSlotVisible(): boolean {
        return this.hasNotFoundResultsSlot && this.isMatchedState([SearchPageLayoutState.FilteringEmpty]);
    }

    get isFiltersSlotVisible(): boolean {
        return (
            this.hasFiltersSlot &&
            !this.isMatchedState([
                SearchPageLayoutState.Idle,
                SearchPageLayoutState.Loading,
                SearchPageLayoutState.LoadingEmpty
            ])
        );
    }

    get isErrorsSlotVisible(): boolean {
        return (
            this.hasErrorsSlot &&
            this.isMatchedState([SearchPageLayoutState.LoadingFailure, SearchPageLayoutState.FilteringFailure])
        );
    }

    get isResultsSlotVisible(): boolean {
        return (
            this.hasResultsSlot &&
            this.isMatchedState([SearchPageLayoutState.LoadingSuccess, SearchPageLayoutState.FilteringSuccess])
        );
    }

    get isProgressState(): boolean {
        return this.isMatchedState([SearchPageLayoutState.Loading, SearchPageLayoutState.Filtering]);
    }

    private hasSlot(content: TemplateRef<unknown>): boolean {
        return !!content;
    }

    private isMatchedState(states: SearchPageLayoutState[]): boolean {
        return states.indexOf(this.state) !== -1;
    }
}
