import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

import { IdentityClass } from '@pt-cybsi/api-interfaces';

/**
 * @pipe TranslateIdentityClassValue
 *
 * @description Converts IdentityClass value to localized string
 */
@Pipe({
    name: 'translateIdentityClassValue'
})
export class TranslateIdentityClassValuePipe implements PipeTransform {
    transform(value: IdentityClass): string {
        return translate(
            value
                ? `entities.ObservableEntities.IdentityClass.Text.${value}`
                : 'entities.ObservableEntities.IdentityClass.Text.Unknown'
        );
    }
}
