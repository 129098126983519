import { ViewModel } from '@pt-cybsi/shared';

import { DataSourceViewFormat, IDataSourceFullView, IDataSourceInstanceView, TDataSourceView } from '../types';

export class SourceViewModel<T extends TDataSourceView> extends ViewModel<T> {
    constructor(props: T) {
        super(props);
    }

    static create<CP extends TDataSourceView>(props: CP): SourceViewModel<CP> {
        return new SourceViewModel(props);
    }

    isFullView(): this is SourceViewModel<IDataSourceFullView> {
        return this.props.format === DataSourceViewFormat.Full;
    }

    isInstanceView(): this is SourceViewModel<IDataSourceInstanceView> {
        return this.props.format === DataSourceViewFormat.Instance;
    }

    getName(): string {
        if (this.isFullView()) {
            return this.props.instance.name;
        }

        if (this.isInstanceView()) {
            return this.props.name;
        }

        return '';
    }

    getFullName(): string {
        if (this.isFullView()) {
            return `${this.props.type.name}/${this.props.instance.name}`;
        }

        if (this.isInstanceView()) {
            return this.props.name;
        }

        return '';
    }
}
