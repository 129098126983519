<div #overflowedCollectionContainer class="overflowed-collection__container pt-text-overflow overflowed-collection_{{collectionType}}">
    <span #overflowedCollectionItems class="overflowed-collection__items">
        <span *ngFor="let item of collection trackBy trackByFn; let last = last;">
            <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item }"></ng-container>
            <span *ngIf="!last && hasItemSeparator" class="overflowed-collection__item-separator">
                {{ collectionItemSeparator }}
            </span>
        </span>
    </span>
</div>
<a #showAllLink *ngIf="isVisibleShowAllLink"
   mc-link
   pseudo
   id="overflowed-collection-handle-click-by-show-more-button"
   (click)="handleClickMore()"
   class="overflowed-collection__show-all"
   [innerHTML]="'common.Common.LimitedCollection.Text.ShowAllButton' | transloco: { count: length }">
</a>
