import {
    AttributeObservableEntity,
    IFactOfAttribute,
    IFileKey,
    IIdentityKey,
    ObservableEntityType,
    TAttributeValueType
} from '@pt-cybsi/api-interfaces';

/* eslint-disable @typescript-eslint/naming-convention */
export type TEntityMetadata =
    | TFileMetadata
    | TIdentityMetadata
    | TIpAddressMetadata
    | TDomainNameMetadata
    | TUrlMetadata
    | TEmailAddressMetadata
    | TPhoneNumberMetadata;

export type TFileMetadata = IBaseEntityMetadata<ObservableEntityType.File> & {
    keys: IFileKey[];
};

export type TIdentityMetadata = IBaseEntityMetadata<ObservableEntityType.Identity> & {
    keys: IIdentityKey[];
};

export type TIpAddressMetadata = IBaseEntityMetadata<ObservableEntityType.IPAddress> & {
    location: {
        name: string;
        code: string;
    };
};

export type TDomainNameMetadata = IBaseEntityMetadata<ObservableEntityType.DomainName>;

export type TUrlMetadata = IBaseEntityMetadata<ObservableEntityType.URL> & {
    fullUrl: string;
};

export type TEmailAddressMetadata = IBaseEntityMetadata<ObservableEntityType.EmailAddress>;

export type TPhoneNumberMetadata = IBaseEntityMetadata<ObservableEntityType.PhoneNumber>;

interface IBaseEntityMetadata<Type extends ObservableEntityType> {
    id: string;
    type: Type;
    verdictAttributes: IEntityMetadataAttribute[];
    attributes: IEntityMetadataAttribute[];
    labels: string[];
}

export interface IEntityMetadataAttribute {
    attributeName: AttributeObservableEntity;
    data: IFactOfAttribute<AttributeObservableEntity, TAttributeValueType>['values'];
}
