import {
    IRelation,
    IRelationConfig,
    IRelationLink,
    IRelationsRequestParams,
    IRelationStatistic,
    ObservableEntityType,
    RelationDirection,
    RelationType,
    TObservableEntity
} from '@pt-cybsi/api-interfaces';

import { isAggregate, isObservableEntity } from '../helpers';
import { EntityFullInfoModel, RelationModel } from '../models';
import {
    RelationLinkId,
    RelationLinkTypeId,
    RelationTypeId,
    RELATION_ID_SEPARATOR,
    TAvailableRelationEntityFormat,
    TRelationLinkId,
    TRelationLinkTypeId,
    TRelationTypeId
} from '../types';

export interface IRelationConfigFromStatisticMappingMetadata<
    EntityFormat extends TAvailableRelationEntityFormat = TObservableEntity
> {
    sourceEntity: EntityFormat;
}

export interface IRelationConfigFromLinkMappingMetadata<
    EntityFormat extends TAvailableRelationEntityFormat = TObservableEntity
> {
    sourceEntity: EntityFormat;
}

export interface IRelationConfigFromRelationMappingMetadata {
    direction: RelationDirection;
}

export interface IRelationConfigFromRelationModelMappingMetadata {
    sourceEntity: EntityFullInfoModel;
}

export interface IRelationConfigToRelationLinkIdMappingMetadata<
    EntityFormat extends TAvailableRelationEntityFormat = TObservableEntity
> {
    sourceEntity: EntityFormat;
    targetEntity: EntityFormat;
}

export class RelationConfigMapper {
    static fromRelationStatistic<EntityFormat extends TAvailableRelationEntityFormat = TObservableEntity>(
        statistic: IRelationStatistic,
        metadata: IRelationConfigFromStatisticMappingMetadata<EntityFormat>
    ): IRelationConfig {
        const { linkType } = statistic;

        return {
            direction: linkType.linkDirection,
            kind: linkType.relationKind,
            source: RelationConfigMapper.getEntityType(metadata.sourceEntity),
            target: linkType.relatedEntitiesType
        };
    }

    static fromRelationLink<EntityFormat extends TAvailableRelationEntityFormat = TObservableEntity>(
        relationLink: IRelationLink<EntityFormat>,
        metadata: IRelationConfigFromLinkMappingMetadata<EntityFormat>
    ): IRelationConfig {
        const { link } = relationLink;

        return {
            direction: link.direction,
            kind: link.relationKind,
            source: RelationConfigMapper.getEntityType(metadata.sourceEntity),
            target: RelationConfigMapper.getEntityType(link.relatedEntity)
        };
    }

    static fromRelation<EntityFormat extends TAvailableRelationEntityFormat = TObservableEntity>(
        relation: IRelation<EntityFormat>,
        metadata: IRelationConfigFromRelationMappingMetadata
    ): IRelationConfig {
        return {
            direction: metadata.direction,
            kind: relation.relationKind,
            source: RelationConfigMapper.getEntityType(relation.sourceEntity),
            target: RelationConfigMapper.getEntityType(relation.targetEntity)
        };
    }

    static fromRelationModel(
        relation: RelationModel,
        metadata: IRelationConfigFromRelationModelMappingMetadata
    ): IRelationConfig {
        return {
            direction: relation.direction,
            kind: relation.type,
            source: RelationConfigMapper.getEntityType(metadata.sourceEntity),
            target: RelationConfigMapper.getEntityType(relation.relatedEntity)
        };
    }

    static fromRelationLinkTypeId(id: TRelationLinkTypeId): IRelationConfig {
        const [source, kind, direction, target] = id.split(RELATION_ID_SEPARATOR);

        return {
            direction: direction as RelationDirection,
            kind: kind as RelationType,
            source: source as ObservableEntityType,
            target: target as ObservableEntityType
        };
    }

    static fromRelationLinkId(id: TRelationLinkId): IRelationConfig {
        const [source, sourceId, kind, direction, target, targetId] = id.split(RELATION_ID_SEPARATOR);

        return {
            direction: direction as RelationDirection,
            kind: kind as RelationType,
            source: source as ObservableEntityType,
            target: target as ObservableEntityType
        };
    }

    static toForward(config: IRelationConfig): IRelationConfig {
        if (config.direction === RelationDirection.Forward) {
            return config;
        }

        return {
            ...config,
            direction: RelationDirection.Forward,
            source: config.target,
            target: config.source
        };
    }

    static toReverse(config: IRelationConfig): IRelationConfig {
        if (config.direction === RelationDirection.Reverse) {
            return config;
        }

        return {
            ...config,
            direction: RelationDirection.Reverse,
            source: config.target,
            target: config.source
        };
    }

    static toRelationTypeId(config: IRelationConfig): TRelationTypeId {
        return RelationTypeId([config.source, config.kind, config.target].join(RELATION_ID_SEPARATOR));
    }

    static toRelationLinkTypeId(config: IRelationConfig): TRelationLinkTypeId {
        return RelationLinkTypeId(
            [config.source, config.kind, config.direction, config.target].join(RELATION_ID_SEPARATOR)
        );
    }

    static toRelationLinkId<EntityFormat extends TAvailableRelationEntityFormat = TObservableEntity>(
        config: IRelationConfig,
        metadata: IRelationConfigToRelationLinkIdMappingMetadata<EntityFormat>
    ): TRelationLinkId {
        return RelationLinkId(
            [
                config.source,
                this.getEntityId(metadata.sourceEntity),
                config.kind,
                config.direction,
                config.target,
                this.getEntityId(metadata.targetEntity)
            ].join(RELATION_ID_SEPARATOR)
        );
    }

    static toRelationRequestParams(config: IRelationConfig): IRelationsRequestParams {
        return {
            relatedEntityType: [config.target],
            direction: config.direction,
            kind: [config.kind]
        };
    }

    private static getEntityType(entity: TAvailableRelationEntityFormat): ObservableEntityType {
        if (entity instanceof EntityFullInfoModel) {
            return entity.getType();
        } else if (isAggregate(entity) || isObservableEntity(entity)) {
            return entity.type;
        }

        return null;
    }

    private static getEntityId(entity: TAvailableRelationEntityFormat): string {
        if (entity instanceof EntityFullInfoModel) {
            return entity.getId();
        } else if (isAggregate(entity) || isObservableEntity(entity)) {
            return entity.uuid;
        }

        return null;
    }
}
