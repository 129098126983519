import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { McSidepanelService, McSidepanelPosition } from '@ptsecurity/mosaic/sidepanel';

import { CollectionListStyle } from '../../types';
import {
    DefaultCollectionSidepanelComponent,
    IDefaultCollectionSidepanelData
} from '../default-collection-sidepanel/default-collection-sidepanel.component';

const DEFAULT_LIMIT = 7;

/**
 * @component LimitedCollection
 *
 * @description
 * Displays a collection of any items, based on the passed item template and data array.
 *
 * By default, only 7 items are displayed at a time, but this parameter can be customized by @Input limit:
 *
 * @param collection - Collection of any elements
 * @param collectionTitle - Title that wil be displayed in sidepanel
 * @param itemTemplate - Item template for collection and sidepanel
 * @param limit - Max amount of items displayed, rest will be shown in sidepanel
 * @param collectionItemSeparator - String, inserted between items
 * @param renderStyle - CollectionListStyle value for changing collection display style
 * @param isDefaultSidepanelOpenPrevented - Boolean value for disabling basic sidepanel
 * @param showMore - Event of show more click handling
 *
 *
 * @example
 *
 * ```html
 * <ng-template #customItemTemplate let-label="item">
 *     <default-collection-item [value]='label' (onClick)='onLabelClick()'></default-collection-item>
 * </ng-template>
 *
 * <limited-collection [collection]="collection"
 *                     [collectionTitle]="collectionTitle"
 *                     [itemTemplate]="customItemTemplate"
 *                     [limit]="limit">
 * </limited-collection>
 * ```
 *
 * If the number of elements exceeds the `limit`, pseudo link "All N" is displayed.
 * It opens the default sidepanel were all elements of the collection are shown.
 * If the default sidepanel is not suitable, you can disable it by `isDefaultSidepanelOpenPrevented` parameter
 * and pass `showMore` handler for the pseudo link. You will get the collection array, title, and item template,
 * so that you can display whole collection in any suitable way.
 *
 * ```html
 * <limited-collection [collection]="collection"
 *                     [collectionTitle]="collectionTitle"
 *                     [itemTemplate]="customItemTemplate"
 *                     [isDefaultSidepanelOpenPrevented]="true"
 *                     (showMore)="handleShowMore()">
 * </limited-collection>
 * ```
 *
 * When you using a components with inputs and outputs in collection item template,
 * remember that although all inputs will be taken from the data array passed to the collection
 * (`item` in `customItemTemplate` is an element of that array),
 * output handlers should be defined in the same component where the item template is defined.
 */
@Component({
    selector: 'limited-collection',
    templateUrl: './limited-collection.component.html',
    styleUrls: ['./limited-collection.component.scss']
})
export class LimitedCollectionComponent {
    @Input() collection: unknown[];
    @Input() collectionTitle: string;
    @Input() itemTemplate: TemplateRef<unknown>;
    @Input() actionsTemplate?: TemplateRef<unknown>;

    @Input() limit?: number;
    @Input() collectionItemSeparator?: string;
    @Input() renderStyle?: CollectionListStyle;

    @Input() isDefaultSidepanelOpenPrevented: boolean;

    @Output() showMore = new EventEmitter<void>();

    get collectionRenderStyle(): CollectionListStyle {
        return this.renderStyle || CollectionListStyle.PLAIN_TEXT;
    }

    get length(): number {
        return this.collection && this.collection.length;
    }

    get itemsLimit(): number {
        return this.limit || DEFAULT_LIMIT;
    }

    get isNeedCutElement(): boolean {
        return this.collection && this.collection.length > this.itemsLimit;
    }

    get isUIGridColumnRenderStyle(): boolean {
        return this.renderStyle === CollectionListStyle.UI_GRID_COLUMN;
    }

    get hasItemSeparator(): boolean {
        return this.collectionItemSeparator !== undefined && this.collectionItemSeparator.length > 0;
    }

    constructor(private sidepanelService: McSidepanelService) {}

    handleClickMore(): void {
        this.showMore.emit();

        if (this.isDefaultSidepanelOpenPrevented) {
            return;
        }

        this.sidepanelService.open<DefaultCollectionSidepanelComponent, IDefaultCollectionSidepanelData>(
            DefaultCollectionSidepanelComponent,
            {
                data: {
                    collection: this.collection,
                    collectionTitle: this.collectionTitle,
                    itemTemplate: this.itemTemplate
                },
                requiredBackdrop: true,
                position: McSidepanelPosition.Left,
                overlayPanelClass: 'sidepanel'
            }
        );
    }

    trackByFn(item, index: number): string {
        if (typeof item === 'object') {
            return `${JSON.stringify(item)}${index}`;
        }

        return `${item}${index}`;
    }
}
