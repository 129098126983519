import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { McSidepanelService, McSidepanelPosition } from '@ptsecurity/mosaic/sidepanel';

import { EnrichmentType, IEnrichmentAnalyzer, IEnrichmentRule } from '@pt-cybsi/api-interfaces';
import { TimePeriodService, TimePeriodType, AsyncState, ToTimePeriodStringPipe } from '@pt-cybsi/shared';

import { EnrichmentRuleSidepanelComponent, IEnrichmentRuleSidepanelData } from '../../containers';
import {
    ICreateRulePageQueryParams,
    EnrichmentRulesNavigationService,
    EnrichmentRulesPermissionsService
} from '../../services';

@Component({
    selector: 'analyser-view',
    templateUrl: './analyser-view.component.html',
    styleUrls: ['./analyser-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnalyserViewComponent implements OnInit {
    @Input() sourceId: string;

    @Input()
    get analyser(): IEnrichmentAnalyzer {
        return this._analyser;
    }

    set analyser(analyser: IEnrichmentAnalyzer) {
        this.formattedTaskExecutionTimeout = this.formatTaskExecutionTimeout(analyser.taskExecutionTimeout);

        this._analyser = analyser;
    }

    @Input() rules: IEnrichmentRule[];
    @Input() rulesState: AsyncState;

    @Output() rulesReload = new EventEmitter<void>();

    get hasEnrichmentRules(): boolean {
        return (this.rules || []).length > 0;
    }

    get collectionTitle(): string {
        return this.translocoService.translate('enrichment.Enrichment.RulesSidepanel.Title.EnrichmentRules', {
            count: (this.rules || []).length
        });
    }

    formattedTaskExecutionTimeout: string;
    createRulePermissions = EnrichmentRulesPermissionsService.createRulePermissions;

    addRuleLink: string;
    addRuleLinkQueryParams: ICreateRulePageQueryParams;

    private _analyser: IEnrichmentAnalyzer;

    constructor(
        private translocoService: TranslocoService,
        private timePeriodService: TimePeriodService,
        private sidepanelService: McSidepanelService,
        private toTimePeriodString: ToTimePeriodStringPipe,
        private enrichmentRulesNavigationService: EnrichmentRulesNavigationService
    ) {}

    ngOnInit() {
        this.addRuleLink = this.enrichmentRulesNavigationService.getPathOfCreateRoute();
        this.addRuleLinkQueryParams = {
            enrichmentType: EnrichmentType.ArtifactAnalysis,
            sourceId: this.sourceId
        };
    }

    formatTaskExecutionTimeout(taskExecutionTimeout: number = 0): string {
        const timeoutMs = this.timePeriodService.toMilliseconds({
            type: TimePeriodType.Seconds,
            value: taskExecutionTimeout
        });

        return this.toTimePeriodString.transform(timeoutMs);
    }

    onRuleClick(rule: IEnrichmentRule) {
        this.sidepanelService.open<EnrichmentRuleSidepanelComponent, IEnrichmentRuleSidepanelData>(
            EnrichmentRuleSidepanelComponent,
            {
                data: { rule },
                position: McSidepanelPosition.Left,
                overlayPanelClass: ['sidepanel', 'sidepanel_large']
            }
        );
    }

    handleRulesReload() {
        this.rulesReload.emit();
    }
}
