import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import isEmpty from 'lodash/isEmpty';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DataSourcesApiService } from '@pt-cybsi/api';
import {
    DataSourceErrorCode,
    HttpStatus,
    IApiItemPreview,
    IDataSourceCreateParams,
    IDataSourceUpdateParams,
    IServerError,
    TEditableDataSourceResponse
} from '@pt-cybsi/api-interfaces';
import { FormMode, FormSavingError, FormsStore, FormState, IFormRepository, isServerError } from '@pt-cybsi/shared';

import { SourcesFormsStore, SourcesFormsStoreProvider } from '../forms';
import { SourceModelMapper, TSourceFormData } from '../mappers';
import { SourceModel, SourceTypeModel } from '../models';
import { SourceFormSavingError } from '../types';

import { SourceTypeFormRepository, SourceTypeFormRepositoryProvider } from './source-type-form.repository';

@Injectable()
export class SourceFormRepository implements IFormRepository<SourceModel> {
    constructor(
        private sourceTypeFormRepository: IFormRepository<SourceTypeModel>,
        private apiService: IDataSourcesApiService,
        private store: FormsStore<TSourceFormData>
    ) {}

    add(mode: FormMode, data: SourceModel): void {
        const formData = SourceModelMapper.toFormData(data);

        this.store.addForm(mode, formData);
    }

    delete(id: string): void {
        this.store.deleteForm(id);
    }

    getState(id: string): FormState {
        const stateValue = this.store.getState(id);

        return FormState.create(stateValue);
    }

    updateState(id: string, state: FormState): void {
        this.store.updateState(id, state.value);
    }

    getInitialData(id: string): SourceModel {
        const data = this.store.getInitialData(id);

        return SourceModelMapper.fromFormData(data);
    }

    updateInitialData(data: SourceModel): void {
        const formData = SourceModelMapper.toFormData(data);

        this.store.updateInitialData(formData);
    }

    getCurrentData(id: string): SourceModel {
        const data = this.store.getCurrentData(id);

        return SourceModelMapper.fromFormData(data);
    }

    updateCurrentData(data: SourceModel): void {
        const formData = SourceModelMapper.toFormData(data);

        this.store.updateCurrentData(formData);
    }

    updateSavingError(id: string, error: IServerError): void {
        this.store.updateSavingError(id, error);
    }

    resetSavingError(id: string): void {
        this.store.updateSavingError(id, null);
    }

    disable(id: string, fields?: (keyof SourceModel['props'])[]): void {
        this.store.disable(id, fields);
    }

    enable(id: string, fields?: (keyof SourceModel['props'])[]): void {
        this.store.enable(id, fields);
    }

    showControls(id: string, names: (keyof SourceModel['props'])[]): void {
        this.store.showControls(id, names);
    }

    hideControls(id: string, names: (keyof SourceModel['props'])[]): void {
        this.store.hideControls(id, names);
    }

    isTouched(id: string): boolean {
        return this.store.isTouched(id);
    }

    validate(id: string): Observable<boolean> {
        return this.store.validate(id);
    }

    loadData(id: string): Observable<SourceModel> {
        return this.apiService
            .getEditableSource(id)
            .pipe(map((response) => SourceModelMapper.fromEditableApiData(response)));
    }

    save(model: SourceModel): Observable<string> {
        const formMode = this.store.getFormMode(model.uid);

        return formMode === FormMode.Creation ? this.register(model) : this.update(model);
    }

    private register(sourceModel: SourceModel): Observable<string> {
        const sourceTypeModel = this.sourceTypeFormRepository.getCurrentData(sourceModel.props.typeId);

        const params = SourceModelMapper.toRegisterParams(sourceModel, sourceTypeModel);

        return this.apiService.createSource(params).pipe(
            map((response) => response.uuid),
            catchError((response: unknown) => {
                const errorResponse = response as HttpErrorResponse;
                const error = errorResponse.error as unknown;

                if (isServerError(error) && error.code === DataSourceErrorCode.DuplicateDataSource) {
                    return throwError({ code: SourceFormSavingError.DuplicateShortName });
                }

                return throwError({ code: FormSavingError.Common });
            })
        );
    }

    private update(model: SourceModel): Observable<string> {
        const { serverId, eTag } = model.props;

        const params = SourceModelMapper.toUpdateParams(model);

        if (isEmpty(params)) {
            return of(serverId);
        }

        return this.apiService.updateSource(serverId, eTag, params).pipe(
            map(() => serverId),
            catchError((response: unknown) => {
                const errorResponse = response as HttpErrorResponse;
                const error = errorResponse.error as unknown;

                if (isServerError(error) && error.code === HttpStatus.PRECONDITION_FAILED) {
                    return throwError({ code: FormSavingError.AlreadyChanged });
                }

                return throwError({ code: FormSavingError.Common });
            })
        );
    }
}

export interface IDataSourcesApiService {
    getEditableSource(serverId: string): Observable<TEditableDataSourceResponse>;
    createSource(params: IDataSourceCreateParams): Observable<IApiItemPreview>;
    updateSource(uuid: string, eTag: string, params: IDataSourceUpdateParams): Observable<void>;
}

export const SourceFormRepositoryProvider: Provider = [
    SourceTypeFormRepositoryProvider,
    DataSourcesApiService,
    SourcesFormsStoreProvider,
    {
        provide: SourceFormRepository,
        useFactory: (
            repository: SourceTypeFormRepository,
            apiService: DataSourcesApiService,
            store: SourcesFormsStore
        ) => new SourceFormRepository(repository, apiService, store),
        deps: [SourceTypeFormRepository, DataSourcesApiService, SourcesFormsStore]
    }
];
