<lazy-grid
    *ngIf="!hasDetails"
    [agGridOptions]="agGridOptions"
    [columns]="columns"
    [data]="sourceTypes"
    [state]="state"
    [errorTitle]="errorTitle"
    (loadMore)="loadMore.emit()"
></lazy-grid>

<lazy-grid
    *ngIf="hasDetails"
    #lazyGrid
    [lazyGridAgGridDetails]="typeDetailsTemplate"
    [agGridOptions]="agGridOptions"
    [columns]="columns"
    [data]="sourceTypes"
    [state]="state"
    [errorTitle]="errorTitle"
    (loadMore)="loadMore.emit()"
></lazy-grid>

<ng-template #typeCell let-data="params.value">
    <div class="source-type-grid__source-type-cell">
        <a mc-link pt-title [routerLink]="getSourceTypeLink(data.id)" class="mc-link_overflowed">
            <span class="mc-link__text">{{ data.name }}</span>
        </a>
    </div>
</ng-template>

<ng-template #confidenceCell let-data="params.value">
    {{ data | formatConfidence:ConfidenceFormat.Full }}
</ng-template>
