import { Injectable } from '@angular/core';
import castArray from 'lodash/castArray';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
    IEntitiesAggregatesMap,
    IEntitiesRelationsMap,
    IRelationsMapRequestParams,
    ObservableEntitiesApiService,
    ObservableEntitiesRelationsApiService
} from '@pt-cybsi/api';
import {
    AggregateSection,
    IRelationsRequestParams,
    ObservableEntityType,
    RelationDirection,
    RelationType,
    TAggregate,
    TObservableEntity,
    TObservableEntityKey
} from '@pt-cybsi/api-interfaces';
import {
    EntityFullInfoModel,
    EntityPreviewMapper,
    TEntityFullInfoModel
} from '@pt-cybsi/domain-core/observable-entities';

import { EntityPreviewFormat, TEntityPreviewData } from '../types';

import { RelationsResolverService } from './relations-resolver.service';

@Injectable()
export class EntityPreviewDataService {
    constructor(
        private observableEntitiesApiService: ObservableEntitiesApiService,
        private observableEntitiesRelationsApiService: ObservableEntitiesRelationsApiService,
        private relationsResolverService: RelationsResolverService
    ) {}

    getPreviews(
        observableEntities: TObservableEntity | TObservableEntity[] | TAggregate | TAggregate[],
        previewFormat: EntityPreviewFormat
    ): Observable<TEntityPreviewData[]> {
        const entities = compact(castArray(observableEntities));

        if (entities.length === 0) {
            return of([] as TEntityPreviewData[]);
        }

        const entitiesParams: IEntityPreviewParams[] = entities.map((entity: TObservableEntity | TAggregate) => ({
            uuid: entity.uuid,
            type: entity.type,
            keys: entity.keys || []
        }));

        return this.getEntitiesModels(entitiesParams, previewFormat).pipe(
            map((models) => models.map((model) => EntityPreviewMapper.toPreview(model)))
        );
    }

    private getEntitiesModels(
        entitiesParams: IEntityPreviewParams[],
        previewFormat: EntityPreviewFormat
    ): Observable<TEntityFullInfoModel[]> {
        return forkJoin([
            this.getAggregates(entitiesParams, previewFormat),
            this.getRelations(entitiesParams, previewFormat)
        ]).pipe(
            map(([aggregates, relations]) =>
                entitiesParams.map((entityParams) => this.getEntityModel(entityParams, aggregates, relations))
            )
        );
    }

    private getEntityModel(
        entityParams: IEntityPreviewParams,
        aggregates: IEntitiesAggregatesMap,
        relations: IEntitiesRelationsMap
    ): TEntityFullInfoModel {
        const entityId: string = entityParams.uuid;
        const unregisteredAggregate: TAggregate = this.getUnregisteredAggregate(entityParams);

        const aggregate = aggregates[entityId] || unregisteredAggregate;
        const relationsOfAggregate = relations[entityId] || [];

        return EntityFullInfoModel.createFromRawData(aggregate, relationsOfAggregate);
    }

    private getAggregates(
        entitiesParams: IEntityPreviewParams[],
        previewFormat: EntityPreviewFormat
    ): Observable<IEntitiesAggregatesMap> {
        const entitiesIds = compact(entitiesParams.map((entityParams) => entityParams.uuid));
        const aggregateRequestParams = this.getAggregatesParams(entitiesParams, previewFormat);

        return this.observableEntitiesApiService.getEntitiesAggregatesMap(entitiesIds, aggregateRequestParams.section);
    }

    private getAggregatesParams(
        entitiesParams: IEntityPreviewParams[],
        previewFormat: EntityPreviewFormat
    ): IResourcesRequestParams['aggregate'] {
        const params = entitiesParams.map((entityParams) =>
            EntityPreviewDataRequestParams.getAggregateParams(entityParams.type, previewFormat)
        );

        return EntityPreviewDataRequestParams.mergeAggregateParams(params);
    }

    private getRelations(
        entitiesParams: IEntityPreviewParams[],
        previewFormat: EntityPreviewFormat
    ): Observable<IEntitiesRelationsMap> {
        const params = this.getRelationsParams(entitiesParams, previewFormat);

        if (params.length === 0) {
            return of({});
        }

        return this.observableEntitiesRelationsApiService.getEntitiesRelationsMap(params).pipe(
            switchMap((relationsMap: IEntitiesRelationsMap) => {
                const relationsEntitiesParams: IEntityPreviewParams[] = [];

                Object.values(relationsMap).forEach((entityRelations) => {
                    relationsEntitiesParams.push(
                        ...entityRelations.map((entityRelation) => entityRelation.link.relatedEntity)
                    );
                });

                const aggregateParams = this.getAggregatesParams(relationsEntitiesParams, EntityPreviewFormat.Short);

                return this.relationsResolverService.resolveMapOfRelations(relationsMap, aggregateParams.section);
            })
        );
    }

    private getRelationsParams(
        entitiesParams: IEntityPreviewParams[],
        previewFormat: EntityPreviewFormat
    ): IRelationsMapRequestParams[] {
        return entitiesParams
            .map((entityParams) => ({
                entityId: entityParams.uuid,
                relationsParams: EntityPreviewDataRequestParams.getRelationsParams(entityParams.type, previewFormat)
            }))
            .filter((relationsRequestParams) => !isEmpty(relationsRequestParams.relationsParams));
    }

    private getUnregisteredAggregate(entityParams: IEntityPreviewParams): TAggregate {
        return {
            uuid: null,
            url: '',
            sections: [],
            ...entityParams
        } as TAggregate;
    }
}

class EntityPreviewDataRequestParams {
    private static readonly resourcesParamsConfiguration: TResourcesRequestParamsConfiguration = {
        [ObservableEntityType.File]: {
            Common: {
                aggregate: {
                    section: [AggregateSection.AssociatedAttributes, AggregateSection.NaturalAttributes]
                },
                relations: []
            }
        },
        [ObservableEntityType.Identity]: {
            Common: {
                aggregate: {
                    section: [AggregateSection.NaturalAttributes]
                },
                relations: []
            }
        },
        [ObservableEntityType.IPAddress]: {
            Common: {
                aggregate: {
                    section: [AggregateSection.AssociatedAttributes, AggregateSection.NaturalAttributes]
                },
                relations: []
            },
            [EntityPreviewFormat.FullWithExtraAttributes]: {
                aggregate: {
                    section: [AggregateSection.GeoIP]
                }
            }
        },
        [ObservableEntityType.DomainName]: {
            Common: {
                aggregate: {
                    section: [AggregateSection.AssociatedAttributes, AggregateSection.NaturalAttributes]
                },
                relations: []
            },
            [EntityPreviewFormat.FullWithExtraAttributes]: {
                relations: [
                    {
                        relatedEntityType: [ObservableEntityType.IPAddress],
                        kind: [RelationType.ResolvesTo],
                        direction: RelationDirection.Forward,
                        limit: 10
                    }
                ]
            }
        },
        [ObservableEntityType.URL]: {
            Common: {
                aggregate: {
                    section: [AggregateSection.AssociatedAttributes, AggregateSection.NaturalAttributes]
                },
                relations: []
            }
        },
        [ObservableEntityType.EmailAddress]: {
            Common: {
                aggregate: {
                    section: [AggregateSection.AssociatedAttributes, AggregateSection.NaturalAttributes]
                },
                relations: []
            }
        },
        [ObservableEntityType.PhoneNumber]: {
            Common: {
                aggregate: {
                    section: [AggregateSection.AssociatedAttributes]
                },
                relations: []
            },
            [EntityPreviewFormat.FullWithExtraAttributes]: {
                relations: [
                    {
                        relatedEntityType: [ObservableEntityType.PhoneNumber],
                        kind: [RelationType.Contains],
                        direction: RelationDirection.Reverse,
                        limit: 10
                    }
                ]
            }
        }
    };

    static getAggregateParams(
        entityType: ObservableEntityType,
        previewFormat: EntityPreviewFormat
    ): IResourcesRequestParams['aggregate'] {
        const commonParams = this.resourcesParamsConfiguration[entityType].Common;
        const specificFormatParams = this.resourcesParamsConfiguration[entityType][previewFormat];

        const commonAggregateParams = commonParams.aggregate;
        const specificAggregateParams = specificFormatParams?.aggregate || {};

        const commonAggregateSectionsParams = commonAggregateParams.section || [];
        const specificAggregateSectionsParams = specificAggregateParams.section || [];

        return {
            section: [...commonAggregateSectionsParams, ...specificAggregateSectionsParams]
        };
    }

    static mergeAggregateParams(params: IResourcesRequestParams['aggregate'][]): IResourcesRequestParams['aggregate'] {
        const mergedParams = params.reduce(
            (mergedResult, paramsOfAggregate) => ({
                ...mergedResult,
                section: [...mergedResult.section, ...(paramsOfAggregate?.section || [])]
            }),
            { section: [] }
        );

        mergedParams.section = Array.from(new Set(mergedParams.section).values());

        return mergedParams;
    }

    static getRelationsParams(
        entityType: ObservableEntityType,
        previewFormat: EntityPreviewFormat
    ): IResourcesRequestParams['relations'] {
        const commonParams = this.resourcesParamsConfiguration[entityType].Common;
        const specificFormatParams = this.resourcesParamsConfiguration[entityType][previewFormat];

        const commonRelationsParams = commonParams.relations;
        const specificRelationsParams = specificFormatParams?.relations || [];

        return [...commonRelationsParams, ...specificRelationsParams];
    }
}

interface IEntityPreviewParams {
    uuid: string;
    type: ObservableEntityType;
    keys: TObservableEntityKey[]; // only for unregisteredAggregate
}

type TResourcesRequestParamsConfiguration = Record<
    ObservableEntityType,
    TResourcesRequestParamsByEntityPreviewDataFormat
>;

type TResourcesRequestParamsByEntityPreviewDataFormat = { Common: IResourcesRequestParams } & {
    [key in keyof typeof EntityPreviewFormat]?: Partial<IResourcesRequestParams>;
};

interface IResourcesRequestParams {
    aggregate: { section?: AggregateSection[] };
    relations: IRelationsRequestParams[];
}
