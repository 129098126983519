import {
    IdentityClass,
    IFileKey,
    ObservableEntityType,
    RelationDirection,
    RelationType,
    TIdentityKeyType
} from '@pt-cybsi/api-interfaces';

import { ThreatLevel } from './threat-level.types';

export enum EntityPreviewFormat {
    Short = 'Short',
    Full = 'Full',
    FullWithExtraAttributes = 'FullWithExtraAttributes'
}

export type TEntityPreviewData =
    | IFilePreviewData
    | IIdentityPreviewData
    | IIpAddressPreviewData
    | IDomainNamePreviewData
    | IUrlPreviewData
    | IEmailAddressPreviewData
    | IPhoneNumberPreviewData;

export interface IFilePreviewData {
    id: string;
    localId: string;
    type: ObservableEntityType.File;
    name: string;
    mainHash: IFileKey;
    threatLevel: ThreatLevel;
    isIoC: boolean;
    isTrusted: boolean;
    extraAttributes: {
        additionalHash: IFileKey;
    };
}

export interface IIdentityPreviewData {
    id: string;
    localId: string;
    type: ObservableEntityType.Identity;
    name: string;
    extraAttributes: {
        identifier: {
            type: TIdentityKeyType;
            values: string[];
        };
        identityClass: IdentityClass;
        identitySectors: string[];
    };
}

export interface IIpAddressPreviewData {
    id: string;
    localId: string;
    type: ObservableEntityType.IPAddress;
    name: string;
    threatLevel: ThreatLevel;
    isIoC: boolean;
    isTrusted: boolean;
    extraAttributes: {
        asn: number;
        country: {
            name: string;
            code: string;
        };
    };
}

export interface IDomainNamePreviewData {
    id: string;
    localId: string;
    type: ObservableEntityType.DomainName;
    name: string;
    threatLevel: ThreatLevel;
    isIoC: boolean;
    isTrusted: boolean;
    extraAttributes: {
        resolvedIpAddresses: {
            relationType: RelationType.ResolvesTo;
            relationDirection: RelationDirection.Forward;
            items: IIpAddressPreviewData[];
        };
    };
}

export interface IUrlPreviewData {
    id: string;
    localId: string;
    type: ObservableEntityType.URL;
    name: string;
    threatLevel: ThreatLevel;
    isIoC: boolean;
    isTrusted: boolean;
}

export interface IEmailAddressPreviewData {
    id: string;
    localId: string;
    type: ObservableEntityType.EmailAddress;
    name: string;
    threatLevel: ThreatLevel;
    isIoC: boolean;
    isTrusted: boolean;
    extraAttributes: {
        displayNames: string[];
    };
}

export interface IPhoneNumberPreviewData {
    id: string;
    localId: string;
    type: ObservableEntityType.PhoneNumber;
    name: string;
    isIoC: boolean;
    extraAttributes: {
        extensions: {
            relationType: RelationType.Contains;
            relationDirection: RelationDirection.Reverse;
            items: IPhoneNumberPreviewData[];
        };
    };
}
