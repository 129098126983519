import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StatisticsNavigationService } from './services';

// prettier-ignore
const modules = [
    CommonModule
];

// prettier-ignore
const services = [
    StatisticsNavigationService
];

@NgModule({
    imports: [...modules],
    providers: [...services]
})
export class DomainCoreStatisticsModule {}
