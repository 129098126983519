import { EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, filter, finalize, map, switchMap, take } from 'rxjs/operators';

import { IServerError } from '@pt-cybsi/api-interfaces';

import { FormStateValue, TViewModelProps, ViewModel } from '../models';
import { AsyncValidationService, AsyncValidationStatus } from '../services';
import { FormMode, TArgumentsType } from '../types';

interface IFormData {
    id: string;
}

export type TFormData<T> = T & IFormData;

export type TFormViewModelProps<FormData extends IFormData> = TViewModelProps<{
    mode: FormMode;
    state: FormStateValue;
    initialData: FormData;
    currentData: FormData;
    savingError: IServerError;
}>;

/**
 * @ViewModel Form
 *
 * @description
 * Model of Angular Reactive Form with additional features.
 * Contains extended information about a form state, a working mode, and a saving error.
 *
 * For creation FormViewModel need to create a factory with implementing `IFormViewModelBuilder` interface
 */
export class FormViewModel<
    FormData extends IFormData,
    FormConfig extends TFormConfiguration<FormData>
> extends ViewModel<TFormViewModelProps<FormData>> {
    dirty$ = new BehaviorSubject<boolean>(false);
    touched$ = new BehaviorSubject<boolean>(false);

    get mode(): FormMode {
        return this.props.mode;
    }

    get state(): FormStateValue {
        return this.props.state;
    }

    get initialData(): FormData {
        return this.props.initialData;
    }

    get currentData(): FormData {
        return this.form.getRawValue() as FormData;
    }

    get savingError(): IServerError {
        return this.props.savingError;
    }

    get isDisabled(): boolean {
        return this.form.disabled;
    }

    get isEnabled(): boolean {
        return this.form.enabled;
    }

    get isTouched(): boolean {
        return this.form.touched;
    }

    get isDirty(): boolean {
        return this.form.dirty;
    }

    get isReady(): boolean {
        return [FormStateValue.Initializing, FormStateValue.InitializingFailure].indexOf(this.state) === -1;
    }

    get isSending(): boolean {
        return [FormStateValue.Validating, FormStateValue.Saving].indexOf(this.state) !== -1;
    }

    get isSaved(): boolean {
        return this.state === FormStateValue.Saved;
    }

    get isSavingFailed(): boolean {
        return this.state === FormStateValue.SavingFailure;
    }

    private validation$: Observable<boolean>;
    private validators: Partial<Record<keyof FormData, ValidatorFn>> = {};
    private hiddenControls: (keyof FormData)[] = [];

    constructor(
        public props: TFormViewModelProps<FormData>,
        public form: UntypedFormGroup,
        public formConfig: FormConfig,

        private asyncValidationService?: AsyncValidationService
    ) {
        super(props);

        this.updateFormData(props.initialData);

        this.subscribeToTouchedEvent();
        this.subscribeToDirtyEvent();
    }

    updateState(state: FormStateValue): void {
        this.props.state = state;
    }

    updateInitialData(data: FormData): void {
        this.props.initialData = data;
    }

    updateCurrentData(data: FormData): void {
        this.props.currentData = data;

        this.updateFormData(data);
    }

    updateSavingError(error: IServerError): void {
        this.props.savingError = error;
    }

    validate(): Observable<boolean> {
        if (this.validation$) {
            return this.validation$;
        }

        this.form.updateValueAndValidity();

        this.validation$ = of(false).pipe(
            delay(1),
            switchMap(() =>
                isNil(this.asyncValidationService)
                    ? of(this.form.valid)
                    : this.asyncValidationService.status$.pipe(
                          filter((status) => status === AsyncValidationStatus.Complete),
                          delay(1),
                          map(() => this.form.valid),
                          take(1)
                      )
            ),
            take(1),
            finalize(() => (this.validation$ = null))
        );

        return this.validation$;
    }

    getControl(name: keyof FormData): UntypedFormControl {
        return this.form.get(this.formConfig[name].controlName) as UntypedFormControl;
    }

    getControlChanges$<T>(name: keyof FormData): Observable<T> {
        const control = this.getControl(name);

        return control.valueChanges.pipe(filter(() => this.isReady && this.isEnabledControl(name))) as Observable<T>;
    }

    getDisabledControlNames(): (keyof FormData)[] {
        return Object.entries(this.form.controls)
            .filter(([, control]) => control.disabled)
            .map(([name]) => name as keyof FormData);
    }

    getEnabledControlNames(): (keyof FormData)[] {
        return Object.entries(this.form.controls)
            .filter(([, control]) => control.enabled)
            .map(([name]) => name as keyof FormData);
    }

    setError<ControlName extends keyof FormData, ErrorName extends keyof this['formConfig'][ControlName]['errorNames']>(
        controlName: ControlName,
        errorName: ErrorName
    ): void {
        const control = this.getControl(controlName);
        const error = this.formConfig[controlName].errorNames[errorName as string];

        control.setErrors({ ...(control.errors || {}), [error]: true });
    }

    hasError<ControlName extends keyof FormData, ErrorName extends keyof this['formConfig'][ControlName]['errorNames']>(
        controlName: ControlName,
        errorName: ErrorName
    ): boolean {
        const control = this.getControl(controlName);
        const error = this.formConfig[controlName].errorNames[errorName as string];

        return control.errors?.[error] === true;
    }

    hasErrors<ControlName extends keyof FormData>(controlName: ControlName): boolean {
        const control = this.getControl(controlName);

        return control.errors !== null && Object.keys(control.errors).length > 0;
    }

    isDisabledControl(name: keyof FormData): boolean {
        return this.getControl(name).disabled;
    }

    isEnabledControl(name: keyof FormData): boolean {
        return this.getControl(name).enabled;
    }

    disable(fields?: (keyof FormData)[]): void {
        if (fields) {
            fields.forEach((field) => this.getControl(field).disable());
        } else {
            this.form.disable();
        }
    }

    enable(fields?: (keyof FormData)[]): void {
        if (fields) {
            fields.forEach((field) => this.getControl(field).enable());
        } else {
            this.form.enable();
        }
    }

    showControls(names: (keyof FormData)[]): void {
        this.hiddenControls = this.hiddenControls.filter((hiddenControl) => !names.includes(hiddenControl));

        // Need for turn on validation if field was hidden and now is visible
        this.enable(names);
    }

    hideControls(names: (keyof FormData)[]): void {
        this.hiddenControls = uniq([...this.hiddenControls, ...names]);

        // Need for turn off validation if field is hidden
        this.disable(this.hiddenControls);
    }

    isHiddenControl(name: keyof FormData): boolean {
        return this.hiddenControls.includes(name);
    }

    isVisibleControl(name: keyof FormData): boolean {
        return !this.isHiddenControl(name);
    }

    turnOffValidation(): void {
        Object.entries(this.form.controls).forEach(([controlName, control]) => {
            this.validators[controlName] = control.validator;

            control.clearValidators();
            control.updateValueAndValidity({ emitEvent: false });

            this.triggerMosaicValidation(control);
        });
    }

    turnOnValidation(): void {
        Object.entries(this.form.controls).forEach(([controlName, control]) => {
            const existedValidator = control.validator ? [control.validator] : [];
            const cachedValidator = this.validators[controlName]
                ? ([this.validators[controlName]] as ValidatorFn[])
                : [];

            control.setValidators([...existedValidator, ...cachedValidator]);
            control.updateValueAndValidity({ emitEvent: false });

            this.triggerMosaicValidation(control);
        });
    }

    isSuccessSavingMessageVisible(rootFormState?: FormStateValue): boolean {
        return rootFormState
            ? rootFormState === FormStateValue.SavingFailure && this.state === FormStateValue.Saved
            : this.state === FormStateValue.Saved;
    }

    isFailureSavingMessageVisible(rootFormState?: FormStateValue): boolean {
        return rootFormState
            ? rootFormState === FormStateValue.SavingFailure && this.state === FormStateValue.SavingFailure
            : this.state === FormStateValue.SavingFailure;
    }

    getChangedData(requiredKeys: string[] = []): Partial<FormData> {
        const comparedFormData = this.props.initialData;

        const requiredData = pick(this.currentData, requiredKeys);
        const optionalData = pickBy(this.currentData, (initialValue, key) => {
            const comparedValue = isNil(comparedFormData?.[key]) ? null : comparedFormData?.[key];

            return initialValue !== undefined && !isEqual(initialValue, comparedValue);
        });

        return { ...requiredData, ...optionalData };
    }

    private updateFormData(data: FormData): void {
        // Don't need emit a value changes event
        // for prevent notify subscribers after update form data through view model
        this.form.patchValue(data, { emitEvent: false, onlySelf: true });

        // Trigger a "required" validation because of updating form without notify subscribers
        Object.values(this.form.controls).forEach((control) => {
            this.triggerMosaicValidation(control);
        });
    }

    private triggerMosaicValidation(control: AbstractControl): void {
        (control.statusChanges as EventEmitter<unknown>).emit(control.status);
    }

    private subscribeToTouchedEvent(): void {
        const markAsTouched = this.form.markAsTouched.bind(this.form) as (
            ...args: TArgumentsType<AbstractControl['markAsTouched']>
        ) => void;

        const markAsUntouched = this.form.markAsUntouched.bind(this.form) as (
            ...args: TArgumentsType<AbstractControl['markAsUntouched']>
        ) => void;

        this.form.markAsTouched = (...args) => {
            markAsTouched(...args);

            this.touched$.next(true);
        };

        this.form.markAsUntouched = (...args) => {
            markAsUntouched(...args);

            this.touched$.next(false);
        };
    }

    private subscribeToDirtyEvent(): void {
        const markAsTouched = this.form.markAsDirty.bind(this.form) as (
            ...args: TArgumentsType<AbstractControl['markAsDirty']>
        ) => void;

        this.form.markAsDirty = (...args) => {
            markAsTouched(...args);

            this.dirty$.next(true);
        };
    }
}

export type TFormViewModel<FormData extends IFormData> = FormViewModel<FormData, TFormConfiguration<FormData>>;

export type TBaseFormViewModel = FormViewModel<IFormData, TFormConfiguration<IFormData>>;

export type TFormConfiguration<FormData extends IFormData> = Record<
    keyof FormData,
    { controlName: string; errorNames: { [errorName: string]: string } }
>;

export const buildFormConfiguration = <FormData extends IFormData, Config extends TFormConfiguration<FormData>>(
    configuration: Config
): Config => configuration;
