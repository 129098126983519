import { Component, HostBinding, Input } from '@angular/core';

import { ILicenseView, LicenseViewState } from '../../types';

@Component({
    selector: 'license-view-state[license]',
    templateUrl: './license-view-state.component.html',
    styleUrls: ['./license-view-state.component.scss'],
    host: {
        class: 'license-view-state'
    }
})
export class LicenseViewStateComponent {
    @Input() license: ILicenseView;

    @HostBinding('class.license-view-state_active')
    get isActiveLicense(): boolean {
        return this.license.state === LicenseViewState.Active;
    }

    @HostBinding('class.license-view-state_expires')
    get isExpiresLicense(): boolean {
        return this.license.state === LicenseViewState.Expires;
    }

    @HostBinding('class.license-view-state_inactive')
    get isInactiveLicense(): boolean {
        return this.license.state === LicenseViewState.Expired || this.license.state === LicenseViewState.Inactive;
    }
}
