import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LicenseEffects } from './state/license.effects';
import { LicenseFacade } from './state/license.facade';
import * as fromLicense from './state/license.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(fromLicense.LICENSE_STORE_FEATURE_KEY, fromLicense.reducer),
        EffectsModule.forFeature([LicenseEffects])
    ],
    providers: [LicenseFacade]
})
export class StoreLicenseModule {}
