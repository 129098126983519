import { IPaginationParams, TConfidence } from '../common';

export interface IDataSourceTypesGetParams extends IPaginationParams {
    longName?: string;
    orderBy?: DataSourceTypesOrderType;
}

export enum DataSourceTypesOrderType {
    ShortName = 'ShortName',
    LongName = 'LongName'
}

export interface IDataSourceTypeCreateParams {
    shortName: string;
    longName: string;
    manualConfidence?: number;
}

export interface IDataSourceTypeUpdateParams {
    longName?: string;
    manualConfidence?: TConfidence;
}

export interface IDataSourcesGetParams extends IPaginationParams {
    uuid?: string[];
    query?: string;
    typeUUID?: string[];
    orderBy?: DataSourcesOrderType;
}

export enum DataSourcesOrderType {
    UUID = 'UUID',
    FullName = 'FullName'
}

export interface IDataSourceCreateParams {
    typeUUID: string;
    name: string;
    longName: string;
    manualConfidence?: number;
}

export interface IDataSourceUpdateParams {
    longName?: string;
    manualConfidence?: TConfidence;
}
