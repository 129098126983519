import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import isEmpty from 'lodash/isEmpty';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DataSourcesApiService } from '@pt-cybsi/api';
import {
    DataSourceTypeErrorCode,
    HttpStatus,
    IApiItemPreview,
    IDataSourceTypeCreateParams,
    IDataSourceTypeUpdateParams,
    IServerError,
    TEditableDataSourceTypeResponse
} from '@pt-cybsi/api-interfaces';
import { FormMode, FormSavingError, FormsStore, FormState, IFormRepository, isServerError } from '@pt-cybsi/shared';

import { SourceTypesFormsStore, SourceTypesFormsStoreProvider } from '../forms';
import { SourceTypeModelMapper, TSourceTypeFormData } from '../mappers';
import { SourceTypeModel } from '../models';
import { SourceTypeFormSavingError } from '../types';

@Injectable()
export class SourceTypeFormRepository implements IFormRepository<SourceTypeModel> {
    constructor(private apiService: IDataSourceTypesApiService, private store: FormsStore<TSourceTypeFormData>) {}

    add(mode: FormMode, data: SourceTypeModel): void {
        const formData = SourceTypeModelMapper.toFormData(data);

        this.store.addForm(mode, formData);
    }

    delete(id: string): void {
        this.store.deleteForm(id);
    }

    getState(id: string): FormState {
        const stateValue = this.store.getState(id);

        return FormState.create(stateValue);
    }

    updateState(id: string, state: FormState): void {
        this.store.updateState(id, state.value);
    }

    getInitialData(id: string): SourceTypeModel {
        const data = this.store.getInitialData(id);

        return SourceTypeModelMapper.fromFormData(data);
    }

    updateInitialData(data: SourceTypeModel): void {
        const formData = SourceTypeModelMapper.toFormData(data);

        this.store.updateInitialData(formData);
    }

    getCurrentData(id: string): SourceTypeModel {
        const data = this.store.getCurrentData(id);

        return SourceTypeModelMapper.fromFormData(data);
    }

    updateCurrentData(data: SourceTypeModel): void {
        const formData = SourceTypeModelMapper.toFormData(data);

        this.store.updateCurrentData(formData);
    }

    updateSavingError(id: string, error: IServerError): void {
        this.store.updateSavingError(id, error);
    }

    resetSavingError(id: string): void {
        this.store.updateSavingError(id, null);
    }

    disable(id: string, fields?: (keyof SourceTypeModel['props'])[]): void {
        this.store.disable(id, fields);
    }

    enable(id: string, fields?: (keyof SourceTypeModel['props'])[]): void {
        this.store.enable(id, fields);
    }

    showControls(id: string, names: (keyof SourceTypeModel['props'])[]): void {
        this.store.showControls(id, names);
    }

    hideControls(id: string, names: (keyof SourceTypeModel['props'])[]): void {
        this.store.hideControls(id, names);
    }

    isTouched(id: string): boolean {
        return this.store.isTouched(id);
    }

    validate(id: string): Observable<boolean> {
        return this.store.validate(id);
    }

    loadData(serverId: string): Observable<SourceTypeModel> {
        return this.apiService
            .getEditableSourceType(serverId)
            .pipe(map((sourceType) => SourceTypeModelMapper.fromEditableApiData(sourceType)));
    }

    save(model: SourceTypeModel): Observable<string> {
        const formMode = this.store.getFormMode(model.uid);

        return formMode === FormMode.Creation ? this.registerType(model) : this.updateType(model);
    }

    private registerType(model: SourceTypeModel): Observable<string> {
        const params = SourceTypeModelMapper.toRegisterParams(model);

        return this.apiService.createSourceType(params).pipe(
            map((response) => response.uuid),
            catchError((response: unknown) => {
                const errorResponse = response as HttpErrorResponse;
                const error = errorResponse.error as unknown;

                if (isServerError(error) && error.code === DataSourceTypeErrorCode.DuplicateDataSourceType) {
                    return throwError({ code: SourceTypeFormSavingError.DuplicateShortName });
                }

                return throwError({ code: FormSavingError.Common });
            })
        );
    }

    private updateType(model: SourceTypeModel): Observable<string> {
        const { serverId, eTag } = model.props;

        const params = SourceTypeModelMapper.toUpdateParams(model);

        if (isEmpty(params)) {
            return of(serverId);
        }

        return this.apiService.updateSourceType(serverId, eTag, params).pipe(
            map(() => serverId),
            catchError((response: unknown) => {
                const errorResponse = response as HttpErrorResponse;
                const error = errorResponse.error as unknown;

                if (isServerError(error) && error.code === HttpStatus.PRECONDITION_FAILED) {
                    return throwError({ code: FormSavingError.AlreadyChanged });
                }

                return throwError({ code: FormSavingError.Common });
            })
        );
    }
}

export interface IDataSourceTypesApiService {
    getEditableSourceType(serverId: string): Observable<TEditableDataSourceTypeResponse>;
    createSourceType(params: IDataSourceTypeCreateParams): Observable<IApiItemPreview>;
    updateSourceType(uuid: string, eTag: string, params: IDataSourceTypeUpdateParams): Observable<void>;
}

export const SourceTypeFormRepositoryProvider: Provider = [
    DataSourcesApiService,
    SourceTypesFormsStoreProvider,
    {
        provide: SourceTypeFormRepository,
        useFactory: (apiService: DataSourcesApiService, sourceTypesFormsStore: SourceTypesFormsStore) =>
            new SourceTypeFormRepository(apiService, sourceTypesFormsStore),
        deps: [DataSourcesApiService, SourceTypesFormsStore]
    }
];
