import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { exhaustMap, filter, takeUntil } from 'rxjs/operators';

import { LocationService } from '@pt-cybsi/core';
import { SessionExpiredNotificationService } from '@pt-cybsi/domain-core/account';
import { ConfirmModalService, DestroyService } from '@pt-cybsi/shared';
import { RouterFacade } from '@pt-cybsi/store/router';
import * as legacy from 'legacy.conf';

import { LegacyCommunicationService } from '../../services';
import { LEGACY_FRAME_PROVIDER_TOKEN, LegacyFrameProvider } from '../../tokens/legacy-frame.token';
import { AllowedActionType, IDispatchAction } from '../../types/message';

@Component({
    selector: 'legacy-application-content',
    providers: [DestroyService],
    templateUrl: './legacy-application-content.component.html',
    styleUrls: ['./legacy-application-content.component.scss']
})
export class LegacyApplicationContentComponent {
    isFrameVisible: boolean;

    readonly defaultUrl: SafeResourceUrl;
    private readonly legacyUrlPrefix = legacy.urlPrefix;

    constructor(
        private domSanitizer: DomSanitizer,
        private confirmModalService: ConfirmModalService,
        private sessionExpiredNotificationService: SessionExpiredNotificationService,
        private routerFacade: RouterFacade,
        private legacyCommunicationService: LegacyCommunicationService,
        private locationService: LocationService,
        private store: Store,
        private router: Router,
        private window: Window,
        private destroyed$: DestroyService,
        @Inject(LEGACY_FRAME_PROVIDER_TOKEN) private legacyFrameProvider: LegacyFrameProvider
    ) {
        this.defaultUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.legacyUrlPrefix}`);

        this.legacyCommunicationService.dispatchAction$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((action: IDispatchAction) => {
                const mappedAction = this.mapAction(action);

                if (action.type === AllowedActionType.SetPageTitle && !this.isFrameVisible) {
                    return;
                }

                this.store.dispatch(mappedAction);
            });

        this.legacyCommunicationService.childAppBackdrop$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isBackdropShown: boolean) => {
                if (isBackdropShown) {
                    this.window.document.body.classList.add('with-backdrop');
                } else {
                    this.window.document.body.classList.remove('with-backdrop');
                }
            });

        this.legacyCommunicationService.openConfirmLeavePageModal$
            .pipe(
                exhaustMap(() => this.confirmModalService.confirmLeaveForm()),
                takeUntil(this.destroyed$)
            )
            .subscribe((result) => {
                this.legacyCommunicationService.sendConfirmationLeavePageResult(result);
            });

        this.legacyCommunicationService.openSessionExpiredWarning$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.sessionExpiredNotificationService.show();
        });

        this.legacyCommunicationService.reloadPage$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.locationService.reload();
        });

        this.legacyCommunicationService.setEntryUrl$.pipe(takeUntil(this.destroyed$)).subscribe((url: string) => {
            this.locationService.saveEntryUrl(url);
        });

        this.routerFacade.legacyUrl$.pipe(filter(Boolean), takeUntil(this.destroyed$)).subscribe((url: string) => {
            if (url !== this.router.url) {
                this.router.navigateByUrl(url);
            }

            this.setLegacyFrameUrl(url);
        });

        this.routerFacade.isLegacyMode$.pipe(takeUntil(this.destroyed$)).subscribe((isLegacyMode) => {
            this.isFrameVisible = isLegacyMode;

            if (!isLegacyMode) {
                this.legacyCommunicationService.sendLeavePage();

                this.setLegacyFrameUrl(this.router.url);
            }
        });
    }

    private setLegacyFrameUrl(url: string): void {
        // такая отправка урла в iframe позволяет избежать лишней записи в истории, в отличие от src='url'
        const fullUrl = `${this.legacyUrlPrefix}#${url}`;

        const currentLocation = this.legacyFrameProvider()?.contentWindow.location;

        currentLocation?.replace(fullUrl);
    }

    private mapAction(action: IDispatchAction): Action {
        let result = { ...action };

        if (action.type === AllowedActionType.SetUrl) {
            result = {
                ...action,
                legacyUrl: decodeURI(action.legacyUrl as string)
            };
        }

        return result;
    }
}
