<div class="property-row__header">
    <div *ngIf="title" class="property-row__title">
        {{ title }}
    </div>
    <div *ngIf="!title" class="property-row__title">
        <ng-content select="[property-row-title]"></ng-content>
    </div>
    <div class="property-row__actions">
        <ng-content select="[property-row-actions]"></ng-content>
    </div>
</div>
<div class="property-row__body">
    <ng-content select="[property-row-content]"></ng-content>
</div>
