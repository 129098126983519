import { ArtifactType, EnrichmentType, ObservableEntityType } from '@pt-cybsi/api-interfaces';
import { FakeFormRepository } from '@pt-cybsi/shared';

import { EnrichmentRuleModel } from '../models';
import { IEnrichmentRuleFormRepository } from '../repositories';

export class FakeEnrichmentRuleFormRepository
    extends FakeFormRepository<EnrichmentRuleModel>
    implements IEnrichmentRuleFormRepository
{
    updateAvailableEnrichmentTypes(id: string, types: EnrichmentType[]): void {
        return;
    }
    updateAvailableArtifactTypes(id: string, types: ArtifactType[]): void {
        return;
    }
    updateAvailableEntityTypes(id: string, types: ObservableEntityType[]): void {
        return;
    }
}
