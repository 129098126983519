import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    IUserFullInfo,
    IUser,
    TUsersListResponse,
    IUsersGetParams,
    IUserCreateParams,
    IApiItemPreview,
    IUserUpdateParams,
    TEditableUserFullInfoResponse,
    ICurrentUserUpdateParams,
    ICurrentUserPasswordUpdateParams
} from '@pt-cybsi/api-interfaces';

import { BaseApiService, IFullListResponse } from './base-api.service';

@Injectable()
export class UsersApiService extends BaseApiService {
    static readonly urls = {
        users: 'users',
        current: 'users/me',
        password: 'users/me/password'
    };

    getUsers(params: IUsersGetParams = {}): Observable<TUsersListResponse> {
        return this.getPaginatedCollection<IUser[]>(UsersApiService.urls.users, this.addResolveRefsParam({ params }));
    }

    getAllUsers(params: IUsersGetParams = {}): Observable<IFullListResponse<IUser>> {
        return this.getFullList(UsersApiService.urls.users, this.addResolveRefsParam({ params }));
    }

    getCurrentUser(): Observable<IUserFullInfo> {
        return this.get<IUserFullInfo>(UsersApiService.urls.current, this.addResolveRefsParam());
    }

    getUser(uuid: string): Observable<IUserFullInfo> {
        const url = `${UsersApiService.urls.users}/${uuid}`;

        return this.get(url, this.addResolveRefsParam());
    }

    create(params: IUserCreateParams): Observable<IApiItemPreview> {
        return this.post(UsersApiService.urls.users, params);
    }

    getEditableUser(uuid: string): Observable<TEditableUserFullInfoResponse> {
        const url = `${UsersApiService.urls.users}/${uuid}`;

        return this.getEditableResource(url, this.addResolveRefsParam());
    }

    updateUser(uuid: string, eTag: string, params: IUserUpdateParams) {
        const url = `${UsersApiService.urls.users}/${uuid}`;

        return this.updateResource(url, eTag, params);
    }

    getEditableCurrentUser(): Observable<TEditableUserFullInfoResponse> {
        return this.getEditableResource(UsersApiService.urls.current, this.addResolveRefsParam());
    }

    updateCurrentUser(params: ICurrentUserUpdateParams, eTag: string) {
        return this.updateResource(UsersApiService.urls.current, eTag, params);
    }

    updateCurrentUserPassword(params: ICurrentUserPasswordUpdateParams) {
        return this.put<void>(UsersApiService.urls.password, params);
    }
}
