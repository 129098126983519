import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import isEmpty from 'lodash/isEmpty';

interface IListQueryParams {
    query?: string;
    isDisabled?: boolean;
    providerID?: string[];
    dataSourceUUID?: string[];
}

@Injectable({ providedIn: 'root' })
export class UsersNavigationService {
    static readonly routes = {
        list: '',
        view: 'view/:userId',
        create: 'create',
        edit: 'edit/:userId'
    };

    readonly root = '/users';

    constructor(private router: Router) {}

    goToListPage(queryParams?: IListQueryParams): void {
        const path = this.getPathOfListRoute();
        const hasQueryParams = !isEmpty(queryParams);

        const extras: NavigationExtras = hasQueryParams ? { queryParams } : {};

        this.router.navigate([path], extras);
    }

    goToViewPage(userId: string): void {
        const path = this.getPathOfViewRoute(userId);

        this.router.navigate([path]);
    }

    goToCreatePage(): void {
        const path = this.getPathOfCreateRoute();

        this.router.navigate([path]);
    }

    goToEditPage(userId: string): void {
        const path = this.getPathOfEditRoute(userId);

        this.router.navigate([path]);
    }

    getPathOfListRoute(): string {
        return `${this.root}/${UsersNavigationService.routes.list}`;
    }

    getPathOfViewRoute(userId: string): string {
        return `${this.root}/${UsersNavigationService.routes.view.replace(':userId', userId)}`;
    }

    getPathOfCreateRoute(): string {
        return `${this.root}/${UsersNavigationService.routes.create}`;
    }

    getPathOfEditRoute(userId: string): string {
        return `${this.root}/${UsersNavigationService.routes.edit.replace(':userId', userId)}`;
    }
}
