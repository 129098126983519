import { DateTime } from 'luxon';

import { IAbsoluteDateRange, IRelativeDateRange, isRelativeDateRange, RelativeDateType, TDateRange } from '../types';

export class DateRangeMapper {
    static fromQueryParams(params: TDataRangeQueryParams): TDateRange {
        if (isRelativeDateRangeQueryParams(params)) {
            return DateRangeMapper.fromRelativeDateRangeQueryParams(params);
        } else {
            return DateRangeMapper.fromAbsoluteDateRangeQueryParams(params);
        }
    }

    static toQueryParams(range: TDateRange): TDataRangeQueryParams {
        if (isRelativeDateRange(range)) {
            return DateRangeMapper.toRelativeDateRangeQueryParams(range);
        } else {
            return DateRangeMapper.toAbsoluteDateRangeQueryParams(range);
        }
    }

    private static fromRelativeDateRangeQueryParams(params: IRelativeDataRangeQueryParams): IRelativeDateRange {
        const str = params?.last || '';

        const isValidFormat = RELATIVE_DATE_RANGE_STRING_FORMAT.test(str);

        if (!isValidFormat) {
            return null;
        }

        const rawType = str.substring(str.length - 1) as TRelativeDateRangeTypeString;
        const rawValue = str.substring(0, str.length - 1);

        const type = RELATIVE_DATE_TYPE_BY_STRING[rawType];
        const value = parseInt(rawValue);

        return type && value ? { type, value } : null;
    }

    private static toRelativeDateRangeQueryParams(range: IRelativeDateRange): IRelativeDataRangeQueryParams {
        const type = STRING_BY_RELATIVE_DATE_TYPE[range.type];

        return range.value ? { last: `${range.value}${type}` } : null;
    }

    private static fromAbsoluteDateRangeQueryParams(params: IAbsoluteDataRangeQueryParams): IAbsoluteDateRange {
        const from = params.from ? DateTime.fromISO(params.from) : null;
        const to = params.to ? DateTime.fromISO(params.to) : null;

        const range: IAbsoluteDateRange = {
            from: from && from.isValid ? from : null,
            to: to && to.isValid ? to : null
        };

        return range.from || range.to ? range : null;
    }

    private static toAbsoluteDateRangeQueryParams(range: IAbsoluteDateRange): IAbsoluteDataRangeQueryParams {
        const params: IAbsoluteDataRangeQueryParams = {
            from: range.from ? range.from.toISO() : null,
            to: range.to ? range.to.toISO() : null
        };

        return params.from || params.to ? params : null;
    }
}

export class RelativeDateRangeMapper {
    static toAbsoluteDateRange(range: IRelativeDateRange): IAbsoluteDateRange {
        switch (range.type) {
            case RelativeDateType.Days: {
                return { from: DateTime.now().minus({ days: range.value }) };
            }

            default: {
                return null;
            }
        }
    }
}

export interface IRelativeDataRangeQueryParams {
    last: string;
}

export interface IAbsoluteDataRangeQueryParams {
    from?: string;
    to?: string;
}

export type TDataRangeQueryParams = IRelativeDataRangeQueryParams | IAbsoluteDataRangeQueryParams;

export const isRelativeDateRangeQueryParams = (q: TDataRangeQueryParams): q is IRelativeDataRangeQueryParams =>
    q && 'last' in q;

export const isAbsoluteDateRangeQueryParams = (q: TDataRangeQueryParams): q is IAbsoluteDataRangeQueryParams =>
    q && ('from' in q || 'to' in q);

type TRelativeDateRangeTypeString = 'D';

const RELATIVE_DATE_TYPE_BY_STRING: Record<TRelativeDateRangeTypeString, RelativeDateType> = {
    D: RelativeDateType.Days
};

const STRING_BY_RELATIVE_DATE_TYPE: Record<RelativeDateType, TRelativeDateRangeTypeString> = {
    [RelativeDateType.Days]: 'D'
};

// Example: 7D, 30D, etc.
const RELATIVE_DATE_RANGE_STRING_FORMAT = new RegExp(
    `^[1-9]([0-9]+)?(${Object.keys(RELATIVE_DATE_TYPE_BY_STRING).join('|')})$`
);
