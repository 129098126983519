import { Injectable, Provider } from '@angular/core';

import { FormsStore } from '@pt-cybsi/shared';

import { TSourceTypeFormData } from '../../mappers';

import { SourceTypeFormBuilder } from './source-type-form.builder';

/**
 * @store SourceTypeCreationFormsStore
 *
 * @description
 * Reactive store for source type creation forms.
 */
@Injectable()
export class SourceTypesFormsStore extends FormsStore<TSourceTypeFormData> {
    constructor(
        protected creationFormViewModelBuilder: SourceTypeFormBuilder,
        protected editFormViewModelBuilder: SourceTypeFormBuilder
    ) {
        super();
    }
}

export const SourceTypesFormsStoreProvider: Provider = [
    SourceTypeFormBuilder,
    {
        provide: SourceTypesFormsStore,
        useFactory: (builder: SourceTypeFormBuilder) => new SourceTypesFormsStore(builder, builder),
        deps: [SourceTypeFormBuilder]
    }
];
