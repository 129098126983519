import { Clipboard } from '@angular/cdk/clipboard';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DateAdapter } from '@ptsecurity/cdk/datetime';
import { McModalRef } from '@ptsecurity/mosaic/modal';
import { DateTime } from 'luxon';
import { throwError } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import { ApiKeysApiService } from '@pt-cybsi/api';
import { ICreateApiKeyResponse, IServerError } from '@pt-cybsi/api-interfaces';
import { DestroyService, FormMode, FormStateValue } from '@pt-cybsi/shared';

import { ApiKeyFormBuilder, ApiKeyFormMapper, ApiKeyFormViewModel, TApiKeyFormData } from '../../forms';

enum ViewMode {
    EnterApiKeyData = 'EnterApiKeyData',
    CopyApiKey = 'CopyApiKey'
}

@Component({
    selector: 'create-api-key-modal',
    templateUrl: './create-api-key-modal.component.html',
    providers: [ApiKeyFormBuilder, DestroyService]
})
export class CreateApiKeyModalComponent implements OnInit {
    @Input() userId: string;
    @Input() userPermissions: string[];
    @Input() isCurrentUser: boolean;

    availableViewModes = ViewMode;
    viewMode = ViewMode.EnterApiKeyData;
    form: ApiKeyFormViewModel;
    generatedApiKey: string;

    get formState(): FormStateValue {
        return this.form.state;
    }

    get isSubmitInProgress() {
        return [FormStateValue.Validating, FormStateValue.Saving].indexOf(this.formState) !== -1;
    }

    get isSubmitDisabled() {
        return [FormStateValue.Validating, FormStateValue.Saving, FormStateValue.Saved].indexOf(this.formState) !== -1;
    }

    get isSavingFailed() {
        return this.formState === FormStateValue.SavingFailure;
    }

    constructor(
        private apiKeysApiService: ApiKeysApiService,
        private apiKeyFormBuilder: ApiKeyFormBuilder,
        private adapter: DateAdapter<DateTime>,
        private modalRef: McModalRef,
        private clipboard: Clipboard,
        private changeDetectorRef: ChangeDetectorRef,
        private destroyed$: DestroyService
    ) {}

    ngOnInit() {
        const formData: TApiKeyFormData = {
            id: 'api-key',
            description: null,
            expiresAt: null,
            isPerpetual: false,
            permissions: [],
            isInheritPermissions: true
        };

        this.form = this.apiKeyFormBuilder.build({
            mode: FormMode.Creation,
            state: FormStateValue.ReadyForInput,
            savingError: null,
            initialData: formData,
            currentData: formData
        });
    }

    handleSubmit() {
        this.form.updateState(FormStateValue.Validating);

        this.form
            .validate()
            .pipe(
                switchMap((isValid) => {
                    if (isValid) {
                        this.form.updateState(FormStateValue.ReadyForSave);
                        this.form.updateState(FormStateValue.Saving);
                        this.form.updateSavingError(null);

                        const createParams = ApiKeyFormMapper.toCreateParams(this.form.currentData);

                        return this.isCurrentUser
                            ? this.apiKeysApiService.createCurrentUserApiKey(createParams)
                            : this.apiKeysApiService.createApiKey(this.userId, createParams);
                    } else {
                        this.form.updateState(FormStateValue.ValidatingFailure);

                        return throwError(null);
                    }
                }),
                take(1),
                takeUntil(this.destroyed$)
            )
            .subscribe({
                next: (result: ICreateApiKeyResponse) => {
                    this.form.updateState(FormStateValue.Saved);

                    this.hideCloseButton();

                    return this.handleSubmitSuccess(result.key);
                },
                error: (error: HttpErrorResponse) => {
                    if (error) {
                        this.form.updateState(FormStateValue.SavingFailure);
                        this.form.updateSavingError(error?.error as IServerError);
                    }

                    this.changeDetectorRef.detectChanges();
                }
            });
    }

    handleSubmitSuccess(apiKey: string) {
        this.generatedApiKey = apiKey;
        this.viewMode = ViewMode.CopyApiKey;
        this.changeDetectorRef.detectChanges();
    }

    copyKeyAndClose() {
        this.clipboard.copy(this.generatedApiKey);
        this.closeModal();
    }

    closeModal() {
        this.modalRef.destroy(!!this.generatedApiKey);
    }

    private hideCloseButton(): void {
        this.modalRef.getInstance().mcClosable = false;
    }
}
