import { Injectable, Provider } from '@angular/core';

import { FormsStore } from '@pt-cybsi/shared';

import { TExternalDBFormData } from '../../mappers';

import { ExternalDBFormBuilder } from './external-db-form.builder';

/**
 * @store ExternalDBForms
 *
 * @description
 * Reactive store for external database forms.
 */
@Injectable()
export class ExternalDBFormsStore extends FormsStore<TExternalDBFormData> {
    constructor(
        protected creationFormViewModelBuilder: ExternalDBFormBuilder,
        protected editFormViewModelBuilder: ExternalDBFormBuilder
    ) {
        super();
    }
}

export const ExternalDBFormsStoreProvider: Provider = [
    ExternalDBFormBuilder,
    {
        provide: ExternalDBFormsStore,
        useFactory: (builder: ExternalDBFormBuilder) => new ExternalDBFormsStore(builder, builder),
        deps: [ExternalDBFormBuilder]
    }
];
