import { TObservableEntity } from '../observable';

import { IObservationHeader } from './observation';
import { ObservationType } from './observation-type';
import { TGetEntityType } from './utils';

export interface IObservationThreat<EntityFormat extends TObservableEntity = TObservableEntity>
    extends IObservationHeader<ObservationType.Threat> {
    content: IObservationThreatContent<EntityFormat>;
}

export interface IObservationThreatContent<EntityFormat extends TObservableEntity = TObservableEntity> {
    entity: TGetEntityType<EntityFormat>;
    name: string;
    category?: string;
    description?: string;
    rule?: IObservationThreatRule;
}

export interface IObservationThreatRule {
    name: string;
    body?: string;
    description?: string;
    updatedAt?: string;
}
