<copied-row
    *ngIf="value.value; else emptyHashTemplate"
    [copiedValue]="value.value"
    [copiedMessage]="'common.Common.HashSum.Text.CopiedMessage' | transloco">
    <span class="layout-margin-right-xxs flex-no-shrink text-less-contrast">{{ value.type | translateHashSumType }}</span>
    <span class="pt-text-overflow" pt-title>{{ value.value }}</span>
</copied-row>

<ng-template #emptyHashTemplate>
    <span class="layout-margin-right-xxs text-less-contrast">{{ value.type | translateHashSumType }}</span>
    <span class="text-less-contrast">—</span>
</ng-template>