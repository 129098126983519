export interface IOldDictionaries {
    identityClasses: string[];
    feedsPublishFormats: IFeedsPublishFormat[];
    malwareTypes: string[];
}

export interface IFeedsPublishFormat {
    name: string;
    shortName: string;
}
