import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { KeyValue } from '@angular/common';
import {
    Component,
    DoCheck,
    Input,
    OnChanges,
    OnDestroy,
    Optional,
    Self,
    ViewChild,
    OnInit,
    Directive
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import {
    CanDisable,
    CanDisableCtor,
    CanUpdateErrorState,
    CanUpdateErrorStateCtor,
    ErrorStateMatcher,
    mixinDisabled,
    mixinErrorState
} from '@ptsecurity/mosaic/core';
import { McFormFieldControl, McValidateDirective } from '@ptsecurity/mosaic/form-field';
import { McSelect } from '@ptsecurity/mosaic/select';

import { OBSERVABLE_ENTITY_TYPES, ObservableEntityType } from '@pt-cybsi/api-interfaces';
import { createASCSortComparator, McFieldBase, selectHiddenItemsTextFormatter } from '@pt-cybsi/shared';

import { TranslateObservableEntityTypePipe } from '../../pipes';

let nextUniqueId = 0;

const EntityTypesSelectMixinBase: CanDisableCtor & CanUpdateErrorStateCtor & typeof McFieldBase = mixinDisabled(
    mixinErrorState(McFieldBase)
);

/**
 * @component EntityTypesSelect
 *
 * @description
 * Form field for selecting a single or several types of observable entity.
 *
 * Types are sorted in alphabetical order.
 *
 * @param id - Unique id of field. Optional, will be generated automatically
 * @param ngModel - type or types array of observable entities
 * @param availableEntityTypes - array of available entity types for select options. Optional, will be used OBSERVABLE_ENTITY_TYPES
 * @param placeholder - Placeholder text. Optional, will be used empty text
 * @param multiple - Boolean value for marking the field as multiple. Optional, will be used `false`
 * @param disabled - Boolean value for marking the field as disabled. Optional, will be used `false`
 * @param required - Boolean value for marking the field as required. Optional, will be used `false`
 * @param focused - Boolean value for marking the field as focused. Optional, will be used `false`
 *
 * @example
 * ```html
 * <mc-form-field>
 *     <entity-types-select multiple
 *                          [(ngModel)]="value"
 *                          [id]="id"
 *                          [placeholder]="placeholder"
 *                          [disabled]="disabled"
 *                          [required]="required"
 *                          [focused]="focused">
 *     </entity-types-select>
 * </mc-form-field>
 *
 * <mc-form-field>
 *     <entity-types-select [(ngModel)]="value"
 *                          [id]="id"
 *                          [placeholder]="placeholder"
 *                          [disabled]="disabled"
 *                          [required]="required"
 *                          [focused]="focused">
 *     </entity-types-select>
 * </mc-form-field>
 * ```
 */
@Component({
    selector: 'entity-types-select',
    templateUrl: './entity-types-select.component.html',
    providers: [
        TranslateObservableEntityTypePipe,
        {
            provide: McFormFieldControl,
            useExisting: EntityTypesSelectComponent
        }
    ]
})
export class EntityTypesSelectComponent
    extends EntityTypesSelectMixinBase
    implements
        McFormFieldControl<ObservableEntityType[] | ObservableEntityType>,
        ControlValueAccessor,
        OnChanges,
        OnInit,
        DoCheck,
        OnDestroy,
        CanDisable,
        CanUpdateErrorState
{
    @Input()
    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value || this.uid;
        this.stateChanges.next();
    }

    @Input()
    get value(): ObservableEntityType[] | ObservableEntityType {
        return this._value;
    }

    set value(value: ObservableEntityType[] | ObservableEntityType) {
        if (value !== this.value) {
            this._value = value;
            this.stateChanges.next();
        }
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);

        if (this.focused) {
            this.focused = false;
            this.stateChanges.next();
        }
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);

        this.stateChanges.next();
    }

    @Input()
    get focused(): boolean {
        return this.mcSelect ? this.mcSelect.focused : this._focused;
    }

    set focused(value: boolean) {
        this._focused = coerceBooleanProperty(value);

        if (this.mcSelect) {
            this.mcSelect.focused = this._focused;
        }

        this.stateChanges.next();
    }

    @Input() multiple: boolean;
    @Input() placeholder: string;
    @Input() availableEntityTypes: ObservableEntityType[];

    get empty(): boolean {
        return this._value === null || this._value === undefined;
    }

    entityTypes: Record<ObservableEntityType, string>;

    errorState: boolean;
    controlType = 'entity-types-select';

    sortObjectTypesComparator = createASCSortComparator<KeyValue<ObservableEntityType, string>>((type) => type.value);
    selectHiddenItemsTextFormatter = selectHiddenItemsTextFormatter;

    onChange: (value: ObservableEntityType[] | ObservableEntityType) => void;
    onTouched: () => void;

    @ViewChild('mcSelect') mcSelect: McSelect;

    private _id: string;
    private _value: ObservableEntityType[] | ObservableEntityType;
    private _disabled = false;
    private _focused = false;
    private _required = false;

    private readonly uid = `entity-types-select-${nextUniqueId++}`;

    constructor(
        public defaultErrorStateMatcher: ErrorStateMatcher,
        private translateObservableEntityTypePipe: TranslateObservableEntityTypePipe,
        @Optional() public parentForm: NgForm,
        @Optional() public parentFormGroup: FormGroupDirective,
        @Self() public ngControl: NgControl
    ) {
        super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

        this.ngControl.valueAccessor = this;

        // eslint-disable-next-line no-self-assign
        this.id = this.id;
    }

    ngOnInit() {
        this.entityTypes = (this.availableEntityTypes || OBSERVABLE_ENTITY_TYPES).reduce(
            (entityTypes, entityType) => ({
                ...entityTypes,
                [entityType]: this.translateObservableEntityTypePipe.transform(entityType)
            }),
            {}
        ) as Record<ObservableEntityType, string>;
    }

    ngOnChanges() {
        this.stateChanges.next();
    }

    ngDoCheck() {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    ngOnDestroy() {
        this.stateChanges.complete();
    }

    handleChangeValue(value: ObservableEntityType[] | ObservableEntityType) {
        this.value = value;

        this.onChange(value);
    }

    onContainerClick(): void {
        this.focus();
    }

    focus(): void {
        if (!this.disabled) {
            this.focused = true;

            this.mcSelect.focus();
        }
    }

    handleFocus(): void {
        this.focus();
    }

    writeValue(value: ObservableEntityType[] | ObservableEntityType): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string[] | string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}

@Directive({
    selector: 'entity-types-select',
    exportAs: 'EntityTypesSelectMcValidate'
})
export class EntityTypesSelectMcValidateDirective extends McValidateDirective {}
