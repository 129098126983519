import { buildFormConfiguration, FormMode, FormViewModel } from '@pt-cybsi/shared';

import { TReputationListFormData } from './reputation-list-form.mapper';

/**
 * @ViewModel ReputationListForm
 *
 * @description
 * Model of Angular Reactive Form with additional features for a reputation list creation and edit form.
 * Contains extended information about a form state, a working mode, and a saving error.
 *
 * For creation FormViewModel need to create a factory `ReputationListFormBuilder`
 */
export class ReputationListFormViewModel extends FormViewModel<TReputationListFormData, TReputationListFormConfig> {
    setEditMode() {
        this.props.mode = FormMode.Edit;
    }
}

const FormConfig = {
    id: {
        controlName: 'id',
        errorNames: null
    },
    serverId: {
        controlName: 'serverId',
        errorNames: null
    },
    eTag: {
        controlName: 'eTag',
        errorNames: null
    },
    name: {
        controlName: 'name',
        errorNames: {
            required: 'required',
            length: 'length'
        }
    },
    shareLevel: {
        controlName: 'shareLevel',
        errorNames: {
            required: 'required'
        }
    },
    text: {
        controlName: 'text',
        errorNames: {
            required: 'required'
        }
    }
} as const;

export type TReputationListFormConfig = typeof FormConfig;

export const ReputationListFormConfig = buildFormConfiguration<TReputationListFormData, TReputationListFormConfig>(
    FormConfig
);
