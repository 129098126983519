import { DateTime } from 'luxon';

export enum LicenseViewState {
    Active = 'Active',
    Expires = 'Expires',
    Expired = 'Expired',
    Inactive = 'Inactive'
}

export interface ILicenseView {
    no: number;
    state: LicenseViewState;
    expiresAt: DateTime;
    updatedAt: DateTime | null;
}
