import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component,
    Directive,
    DoCheck,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Optional,
    Self
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import {
    CanDisable,
    CanDisableCtor,
    CanUpdateErrorState,
    CanUpdateErrorStateCtor,
    ErrorStateMatcher,
    mixinDisabled,
    mixinErrorState,
    ThemePalette
} from '@ptsecurity/mosaic/core';
import { McFormFieldControl, McValidateDirective } from '@ptsecurity/mosaic/form-field';

import { McFieldBase } from '../../helpers';

type TValue = File;

let nextUniqueId = 0;

const SelectFileFieldMixinBase: CanDisableCtor & CanUpdateErrorStateCtor & typeof McFieldBase = mixinDisabled(
    mixinErrorState(McFieldBase)
);

/**
 * @component SelectFileField
 *
 * @description
 * Form field for selecting file. Can be used with ReactiveFormsModule.
 *
 * @param id - Unique id of field. Optional, will be generated automatically
 * @param ngModel - Selected file.
 * @param accept - List of available for selection files formats.
 * @param disabled - Boolean value for marking the field as disabled. Optional, will be used `false`
 * @param required - Boolean value for marking the field as required. Optional, will be used `false`
 *
 * @example
 * ```html
 * <mc-form-field mcFormFieldWithoutBorders cybsiFormFieldWithoutBorders>
 *      <select-file-field name="file" [accept]="accept" [(ngModel)]="value" [disabled]="disabled" [required]="required"></select-file-field>
 * </mc-form-field>
 *
 * <mc-form-field mcFormFieldWithoutBorders cybsiFormFieldWithoutBorders>
 *      <select-file-field [formControl]="fileFormControl" [accept]="accept"></select-file-field>
 * </mc-form-field>
 * ```
 */
@Component({
    selector: 'select-file-field',
    templateUrl: './select-file-field.component.html',
    styleUrls: ['./select-file-field.component.scss'],
    providers: [{ provide: McFormFieldControl, useExisting: SelectFileFieldComponent }],
    host: {
        class: 'select-file-field',
        '[attr.id]': 'id',
        '[attr.disabled]': 'disabled || null',
        '[attr.required]': 'required || null'
    }
})
export class SelectFileFieldComponent
    extends SelectFileFieldMixinBase
    implements
        McFormFieldControl<TValue>,
        ControlValueAccessor,
        OnChanges,
        DoCheck,
        OnDestroy,
        CanDisable,
        CanUpdateErrorState
{
    @Input()
    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value || this.uid;
        this.stateChanges.next();
    }

    @Input()
    get value(): TValue {
        return this._value;
    }

    set value(value: TValue) {
        if (value !== this.value) {
            this._value = value;

            this.stateChanges.next();
        }
    }

    @Input() accept: string[] = [];

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);

        if (this.focused) {
            this.focused = false;
            this.stateChanges.next();
        }
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);

        this.stateChanges.next();
    }

    @HostBinding('class.select-file-field_dragover') dragover: boolean;

    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.disabled) {
            return;
        }

        this.dragover = true;
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.disabled) {
            return;
        }

        this.dragover = false;
    }

    @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (this.disabled) {
            return;
        }

        this.dragover = false;

        if (event.dataTransfer.files.length > 0) {
            const file = event.dataTransfer.files[0];

            if (this.isCorrectExtension(file)) {
                this.value = file;

                this.onChange(this.value);
            }
        }
    }

    get inputId(): string {
        return `${this.id}-input`;
    }

    get focused(): boolean {
        return this._focused;
    }

    set focused(value: boolean) {
        this._focused = coerceBooleanProperty(value);

        this.stateChanges.next();
    }

    get empty(): boolean {
        return this._value === null || this._value === undefined;
    }

    get acceptedFiles(): string {
        return this.accept.length > 0 ? this.accept.map((ext: string) => `.${ext}`).join(',') : '*/*';
    }

    @HostBinding('class.select-file-field_invalid')
    get isInvalid(): boolean {
        return this.ngControl.invalid;
    }

    get iconColor(): ThemePalette {
        return this.isInvalid && !this.dragover ? ThemePalette.Error : ThemePalette.Second;
    }

    placeholder: string;
    errorState: boolean;
    controlType = 'select-file-field';

    onChange: (value: TValue) => void;
    onTouched: () => void;

    private _id: string;
    private _value: TValue;
    private _disabled = false;
    private _focused = false;
    private _required = false;

    private readonly uid = `${this.controlType}-${nextUniqueId++}`;

    constructor(
        public defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() public parentForm: NgForm,
        @Optional() public parentFormGroup: FormGroupDirective,
        @Self() public ngControl: NgControl
    ) {
        super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

        this.ngControl.valueAccessor = this;

        // eslint-disable-next-line no-self-assign
        this.id = this.id;
    }

    ngOnChanges(): void {
        this.stateChanges.next();
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
    }

    handleFocusSelectButton(): void {
        this.focus();
        this.onTouched();
    }

    handleBlurSelectButton() {
        this.blur();
    }

    handleChangeInput($event: Event): void {
        const input: HTMLInputElement = $event.target as HTMLInputElement;

        if (input.files && input.files.length > 0) {
            const file = input.files[0];

            if (this.isCorrectExtension(file)) {
                this.value = file;

                this.onChange(this.value);
            }

            input.value = null;

            this.blur();
        }
    }

    handleResetInput(): void {
        this.value = null;
        this.onChange(this.value);
    }

    onContainerClick(): void {
        this.focus();
        this.onTouched();
    }

    focus(): void {
        if (!this.disabled) {
            this.focused = true;
        }
    }

    blur(): void {
        this.focused = false;
    }

    writeValue(value: TValue): void {
        this.value = value;
    }

    registerOnChange(fn: (value: TValue) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private isCorrectExtension(file: File): boolean {
        const fileExt: string = file.name.split('.').pop();

        return this.accept.length > 0 ? this.accept.includes(fileExt) : true;
    }
}

@Directive({
    selector: 'select-file-field',
    exportAs: 'SelectFileFieldMcValidate'
})
export class SelectFileFieldMcValidateDirective extends McValidateDirective {}
