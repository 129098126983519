import { createReducer, on, Action } from '@ngrx/store';

import { IClientConfig } from '@pt-cybsi/api-interfaces';
import { AsyncState } from '@pt-cybsi/shared';

import * as ClientConfigActions from './client-config.actions';

export const CLIENT_CONFIG_FEATURE_KEY = 'client-config';

export interface State {
    state: AsyncState;
    config: IClientConfig;
}

export interface IClientConfigState {
    readonly [CLIENT_CONFIG_FEATURE_KEY]: State;
}

export const initialState: State = {
    state: AsyncState.Loading,
    config: null
};

const clientConfigReducer = createReducer(
    initialState,

    on(ClientConfigActions.fetchClientConfigSuccess, (state, { config }) => ({
        ...state,
        config,
        state: AsyncState.Success
    })),

    on(ClientConfigActions.fetchClientConfigFailure, (state) => ({
        ...state,
        state: AsyncState.Failure
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return clientConfigReducer(state, action);
}
