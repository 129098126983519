import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import { SearchPageLayoutModel, SearchPageLayoutState } from '../models';

interface ISearchPageLayoutState {
    state: SearchPageLayoutState;
}

@Injectable()
export class SearchPageLayoutStore extends ComponentStore<ISearchPageLayoutState> {
    readonly layoutState$: Observable<SearchPageLayoutState> = this.select((state) => state.state);

    readonly isLoadingPart$: Observable<boolean> = this.select(
        this.layoutState$,
        (layoutState) =>
            [
                SearchPageLayoutState.Loading,
                SearchPageLayoutState.LoadingEmpty,
                SearchPageLayoutState.LoadingFailure,
                SearchPageLayoutState.LoadingSuccess
            ].indexOf(layoutState) !== -1
    );

    readonly setLoadingState = this.updater((state) => {
        const model = SearchPageLayoutModel.create(state.state);

        model.setLoadingState();

        return {
            ...state,
            state: model.currentState
        };
    });

    readonly setFilteringState = this.updater((state) => {
        const model = SearchPageLayoutModel.create(state.state);

        model.setFilteringState();

        return {
            ...state,
            state: model.currentState
        };
    });

    readonly setCompleteState = this.updater((state) => {
        const model = SearchPageLayoutModel.create(state.state);

        model.setCompleteState();

        return {
            ...state,
            state: model.currentState
        };
    });

    readonly setErrorState = this.updater((state) => {
        const model = SearchPageLayoutModel.create(state.state);

        model.setErrorState();

        return {
            ...state,
            state: model.currentState
        };
    });

    readonly setEmptyState = this.updater((state) => {
        const model = SearchPageLayoutModel.create(state.state);

        model.setEmptyState();

        return {
            ...state,
            state: model.currentState
        };
    });

    constructor() {
        super({ state: SearchPageLayoutModel.create().currentState });
    }
}
