import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { merge, Observable } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ObservableEntitiesRelationsApiService } from '@pt-cybsi/api';
import { DefaultErrorCodes, RelationDirection, RelationType } from '@pt-cybsi/api-interfaces';
import { SourcesSyncService } from '@pt-cybsi/domain-core/sources';
import { isServerError } from '@pt-cybsi/shared';

import { ForecastViewMapper } from '../mappers';
import { EntityPreviewDataService } from '../services';
import { EntityPreviewFormat } from '../types';

import { ForecastStore } from './forecast.store';

@Injectable()
export class RelationForecastStore extends ForecastStore {
    readonly load = this.effect(
        (
            params$: Observable<{
                sourceEntityId: string;
                relationKind: RelationType;
                targetEntityId: string;
                relationDirection: RelationDirection;
            }>
        ) =>
            params$.pipe(
                tap(() => this.toLoading()),
                switchMap(({ sourceEntityId, relationKind, targetEntityId, relationDirection }) =>
                    this.observableEntitiesRelationApiService
                        .getForecast(sourceEntityId, relationKind, targetEntityId)
                        .pipe(map((forecast) => ({ forecast, relationDirection })))
                ),
                switchMap(({ forecast, relationDirection }) =>
                    this.entityPreviewDataService
                        .getPreviews(
                            [forecast.relationship.sourceEntity, forecast.relationship.targetEntity],
                            EntityPreviewFormat.Full
                        )
                        .pipe(map((entitiesPreview) => ({ forecast, relationDirection, entitiesPreview })))
                ),
                tapResponse(
                    ({ forecast, relationDirection, entitiesPreview }) => {
                        const dataSources = forecast.valuableFacts.map((fact) => fact.dataSource);

                        this.sourcesSyncService.syncSources(dataSources);

                        this.toSuccessLoading(
                            ForecastViewMapper.fromRelationForecast(forecast, {
                                relationDirection,
                                entitiesPreview
                            })
                        );
                    },
                    (response: HttpErrorResponse) => {
                        const error = isServerError(response.error)
                            ? response.error
                            : { code: DefaultErrorCodes.UNKNOWN };

                        this.toFailureLoading(error);
                    }
                ),
                takeUntil(merge(this.destroy$, this.loadingCanceller$))
            )
    );

    constructor(
        private sourcesSyncService: SourcesSyncService,
        private entityPreviewDataService: EntityPreviewDataService,
        private observableEntitiesRelationApiService: ObservableEntitiesRelationsApiService
    ) {
        super();
    }
}
