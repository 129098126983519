import { ApiResource, PermissionType } from '@pt-cybsi/api-interfaces';
import { buildPermissionString } from '@pt-cybsi/domain-core/account';

const Read = PermissionType.Read;
const Write = PermissionType.Write;

export class ApiKeysPermissionsService {
    static readonly createUserApiKeyPermissions = [
        buildPermissionString(ApiResource.Users, [Write]),
        buildPermissionString(ApiResource.ApiKeys, [Write])
    ];

    static readonly viewUserApiKeysPermissions = [
        buildPermissionString(ApiResource.Users, [Read]),
        buildPermissionString(ApiResource.ApiKeys, [Read])
    ];

    static readonly editPermissions = [
        buildPermissionString(ApiResource.Users, [Write]),
        buildPermissionString(ApiResource.ApiKeys, [Write])
    ];
}
