import { HashSumType, IHashSum } from '../types';

export const HASH_PRIORITY = [HashSumType.SHA256, HashSumType.SHA1, HashSumType.MD5];

export const sortHashesByPriorityComparator = (hash1: IHashSum, hash2: IHashSum) => {
    const hash1Priority = HASH_PRIORITY.indexOf(hash1.type);
    const hash2Priority = HASH_PRIORITY.indexOf(hash2.type);

    if (hash1Priority > hash2Priority) {
        return 1;
    } else if (hash1Priority < hash2Priority) {
        return -1;
    } else {
        return 0;
    }
};
