import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IClientConfig } from '@pt-cybsi/api-interfaces';

import { BaseApiService } from './base-api.service';

@Injectable()
export class ClientConfigApiService extends BaseApiService {
    static readonly urls = {
        config: 'client-config.json'
    };

    getConfig(): Observable<IClientConfig> {
        return this.http.get<IClientConfig>(ClientConfigApiService.urls.config);
    }
}
