import { Component, Input } from '@angular/core';

import { AttributeObservableEntity, ObservableEntityType } from '@pt-cybsi/api-interfaces';

import { getEntityNameOrDefault } from '../../helpers';
import { ObservableEntitiesNavigationService } from '../../services';
import { EntityPreviewFormat, TEntityPreviewData, ThreatLevel } from '../../types';
import { EntityAttributeBadgeSize } from '../entity-attribute-badge/entity-attribute-badge.component';

export enum EntityPreviewRenderType {
    Text = 'text',
    Link = 'link',
    Heading = 'heading'
}
@Component({
    selector: 'entity-preview',
    templateUrl: './entity-preview.component.html',
    styleUrls: ['./entity-preview.component.scss']
})
export class EntityPreviewComponent {
    @Input() entity: TEntityPreviewData;
    @Input() format: EntityPreviewFormat = EntityPreviewFormat.Full;
    @Input() renderType: EntityPreviewRenderType = EntityPreviewRenderType.Link;

    readonly ObservableEntityType = ObservableEntityType;
    readonly AttributeObservableEntity = AttributeObservableEntity;
    readonly EntityAttributeBadgeSize = EntityAttributeBadgeSize;

    get isLinkEntity(): boolean {
        return this.renderType === EntityPreviewRenderType.Link && !!this.entityId;
    }

    get isTextEntity(): boolean {
        return !this.entityId || this.renderType !== EntityPreviewRenderType.Link;
    }

    get entityId(): string {
        return this.entity?.id;
    }

    get entityName(): string {
        return getEntityNameOrDefault(this.entity?.name);
    }

    get hasThreatLevel(): boolean {
        return this.threatLevel !== null;
    }

    get threatLevel(): ThreatLevel {
        if (this.entity && 'threatLevel' in this.entity) {
            return this.entity.threatLevel;
        }

        return null;
    }

    get isBadgesVisible(): boolean {
        return (
            this.isRenderTypeOneOf([EntityPreviewFormat.Full, EntityPreviewFormat.FullWithExtraAttributes]) &&
            this.hasBadges
        );
    }

    get hasBadges(): boolean {
        return this.isIoC || this.isTrusted;
    }

    get isIoC(): boolean {
        if (this.entity && 'isIoC' in this.entity) {
            return this.entity.isIoC;
        }

        return false;
    }

    get isTrusted(): boolean {
        if (this.entity && 'isTrusted' in this.entity) {
            return this.entity.isTrusted;
        }

        return false;
    }

    get isExtraAttributesVisible(): boolean {
        return this.isRenderTypeOneOf([EntityPreviewFormat.FullWithExtraAttributes]) && this.hasExtraAttributes;
    }

    get hasExtraAttributes(): boolean {
        return this.entityExtraAttributes !== null;
    }

    get entityExtraAttributes() {
        if (this.entity && 'extraAttributes' in this.entity) {
            return this.entity.extraAttributes;
        }

        return null;
    }

    constructor(private observableEntitiesNavigationService: ObservableEntitiesNavigationService) {}

    getEntityViewLink(entityId: string): string {
        return this.observableEntitiesNavigationService.getPathOfViewRoute(entityId);
    }

    isEntityTypeOf(entityType: ObservableEntityType): boolean {
        return this.entity.type === entityType;
    }

    private isRenderTypeOneOf(renderTypes: EntityPreviewFormat[]): boolean {
        return renderTypes.indexOf(this.format) !== -1;
    }
}
