import { createReducer, on, Action } from '@ngrx/store';

import { ILicense } from '@pt-cybsi/api-interfaces';
import {
    TResponseWithState,
    toFailureResponse,
    toLoadingResponse,
    toSuccessResponse,
    isSuccessResponse
} from '@pt-cybsi/shared';

import * as LicenseStoreActions from './license.actions';

export const LICENSE_STORE_FEATURE_KEY = 'license';

export type TLicenseStoreState = TResponseWithState<ILicense>;

export const initialState: TLicenseStoreState = toLoadingResponse(null as ILicense);

const licenseReducer = createReducer(
    initialState,
    on(
        LicenseStoreActions.loadLicense,
        LicenseStoreActions.updateLicense,
        LicenseStoreActions.refreshLicense,
        (state) => (isSuccessResponse(state) ? toLoadingResponse(state.data) : toLoadingResponse(null))
    ),
    on(LicenseStoreActions.loadLicenseSuccess, (state, { data }) => toSuccessResponse(data)),
    on(LicenseStoreActions.loadLicenseFailure, () => toFailureResponse<ILicense>(null))
);

export function reducer(state: TLicenseStoreState | undefined, action: Action) {
    return licenseReducer(state, action);
}
