import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { IPaginationResponse } from '@pt-cybsi/api-interfaces';

import { LazyDataState, SearchPageLayoutState } from '../models';
import { AbstractSearchPageDataService } from '../services';

import { LazyDataStateStore } from './lazy-data-state.store';
import { SearchPageLayoutStore } from './search-page-layout.store';

interface ISearchPageState<FilterParamsFormat, DataFormat> {
    filterParams: Partial<FilterParamsFormat>;
    cursor: string;
    data: DataFormat[];
}

@Injectable()
export class SearchPageStore<FilterParamsFormat, DataFormat> extends ComponentStore<
    ISearchPageState<FilterParamsFormat, DataFormat>
> {
    readonly filterParams$: Observable<Partial<FilterParamsFormat>> = this.select((state) => state.filterParams);

    readonly cursor$: Observable<string> = this.select((state) => state.cursor);

    readonly data$: Observable<DataFormat[]> = this.select((state) => state.data);

    readonly layoutState$: Observable<SearchPageLayoutState> = this.layoutStore.layoutState$;

    readonly gridState$: Observable<LazyDataState> = this.lazyDataStateStore.dataState$;

    readonly load = this.effect((effect$) =>
        effect$.pipe(
            tap(() => {
                this.layoutStore.setLoadingState();
                this.updateFilterParams(null);
            }),
            switchMap(() =>
                this.dataService.load().pipe(
                    tap((data) => data),
                    tapResponse(
                        (response) => {
                            this.saveResponse(response);
                            this.setLayoutCompleteState(response);
                            this.setLazyGridCompleteState(response);
                        },
                        () => this.layoutStore.setErrorState()
                    )
                )
            ),
            takeUntil(this.destroy$)
        )
    );

    readonly filter = this.effect<Partial<FilterParamsFormat>>((filterParams$) =>
        filterParams$.pipe(
            tap((filterParams) => {
                this.layoutStore.setFilteringState();
                this.updateFilterParams(filterParams);
            }),
            switchMap((filterParams) =>
                this.dataService.filter({ filterParams }).pipe(
                    tap((data) => data),
                    tapResponse(
                        (response) => {
                            this.saveResponse(response);
                            this.setLayoutCompleteState(response);
                            this.setLazyGridCompleteState(response);
                        },
                        () => this.layoutStore.setErrorState()
                    )
                )
            ),
            takeUntil(this.destroy$)
        )
    );

    readonly loadNextPage = this.effect((effect$) =>
        effect$.pipe(
            tap(() => this.lazyDataStateStore.setLoadingState()),
            withLatestFrom(this.filterParams$, this.cursor$),
            map(([, filterParams, cursor]) => ({ filterParams, cursor })),
            switchMap(({ filterParams, cursor }) =>
                this.dataService
                    .filter({
                        filterParams,
                        paginationParams: { cursor }
                    })
                    .pipe(
                        tapResponse(
                            (response) => {
                                this.concatResponse(response);
                                this.setLazyGridCompleteState(response);
                            },
                            () => this.lazyDataStateStore.setErrorState()
                        )
                    )
            ),
            takeUntil(this.destroy$)
        )
    );

    readonly refresh = this.effect((effect$) =>
        effect$.pipe(
            withLatestFrom(this.layoutStore.isLoadingPart$, this.filterParams$),
            map(([, isLoadingPart, filterParams]) => (isLoadingPart ? this.load() : this.filter(filterParams))),
            takeUntil(this.destroy$)
        )
    );

    readonly saveResponse = this.updater((state, response: IPaginationResponse<DataFormat[]>) => ({
        ...state,
        cursor: response.cursor,
        data: response.data
    }));

    readonly concatResponse = this.updater((state, response: IPaginationResponse<DataFormat[]>) => {
        const { cursor, data } = response;

        const currentData = state.data;

        return {
            ...state,
            cursor,
            data: currentData.concat(data)
        };
    });

    readonly updateFilterParams = this.updater((state, filterParams: Partial<FilterParamsFormat>) => ({
        ...state,
        filterParams: {
            ...filterParams
        }
    }));

    constructor(
        protected dataService: AbstractSearchPageDataService<FilterParamsFormat, DataFormat>,
        protected lazyDataStateStore: LazyDataStateStore,
        protected layoutStore: SearchPageLayoutStore
    ) {
        super({
            filterParams: null,
            cursor: null,
            data: []
        });
    }

    private setLazyGridCompleteState(response: IPaginationResponse<DataFormat[]>): void {
        const isEndReached = response.cursor === null;

        this.lazyDataStateStore.setCompleteState(isEndReached);
    }

    private setLayoutCompleteState(response: IPaginationResponse<DataFormat[]>): void {
        const isEmptyResponse = response.data.length === 0;

        if (isEmptyResponse) {
            this.layoutStore.setEmptyState();
        } else {
            this.layoutStore.setCompleteState();
        }
    }
}
