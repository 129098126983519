import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import isEmpty from 'lodash/isEmpty';

export interface IObservableEntitiesFilterParams {
    labels?: string[];
}

@Injectable()
export class ObservableEntitiesNavigationService {
    static routes = {
        list: '',
        view: 'view/:id'
    };

    private root = '/objects';

    constructor(private router: Router) {}

    goToViewPage(reputationListId: string): void {
        const path = this.getPathOfViewRoute(reputationListId);

        this.router.navigate([path]);
    }

    goToListPage(queryParams?: IObservableEntitiesFilterParams): void {
        const path = this.getPathOfListRoute();
        const hasQueryParams = !isEmpty(queryParams);

        const extras: NavigationExtras = hasQueryParams ? { queryParams } : undefined;

        this.router.navigate([path], extras);
    }

    getPathOfViewRoute(entityId: string): string {
        return `${this.root}/${ObservableEntitiesNavigationService.routes.view.replace(':id', entityId)}`;
    }

    getPathOfListRoute(): string {
        return `${this.root}/${ObservableEntitiesNavigationService.routes.list}`;
    }
}
