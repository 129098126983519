import { IUser } from '@pt-cybsi/api-interfaces';
import { ReputationListMapper } from '@pt-cybsi/feature/reputation-lists';

export class DeprecatedUserMapper {
    static toView(user: IUser): IUserView {
        return {
            id: user.uuid,
            name: user.fullName || user.login,
            isDisabled: user.isDisabled
        };
    }

    static toLoginView(user: IUser): IUserView {
        return {
            id: user.uuid,
            name: user.login,
            isDisabled: user.isDisabled
        };
    }
}

export interface IUserView {
    id: string;
    name: string;
    isDisabled: boolean;
}
