<div class="layout-column flex sidepanel-container">
    <mc-sidepanel-header [closeable]="true">
        <div pt-title class="pt-text-overflow">
            {{ 'users.Users.ApiKey.Text.CountPermissions' | transloco:{ count: data.permissions.length } }}
        </div>
    </mc-sidepanel-header>
    <mc-sidepanel-body>
        <mc-tree-selection
            [dataSource]="dataSource"
            [treeControl]="treeControl">
            <mc-tree-option *mcTreeNodeDef="let node" mcTreeNodePadding>
                <div class="pt-text-overflow" pt-title>
                    {{ treeControl.getViewValue(node) }}
                </div>
            </mc-tree-option>

            <mc-tree-option *mcTreeNodeDef="let node; when: isTreeNodeHasChild" mcTreeNodePadding>
                <div class="pt-text-overflow" pt-title>
                    <i mcTreeNodeToggle
                       mc-icon="mc-angle-down-S_16"
                       [style.transform]="treeControl.isExpanded(node) ? '' : 'rotate(-90deg)'">
                    </i>
                    {{ treeControl.getViewValue(node) }}
                </div>
            </mc-tree-option>
        </mc-tree-selection>
    </mc-sidepanel-body>
</div>

