import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component,
    Directive,
    DoCheck,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Self,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import {
    CanDisable,
    CanDisableCtor,
    CanUpdateErrorState,
    CanUpdateErrorStateCtor,
    ErrorStateMatcher,
    mixinDisabled,
    mixinErrorState
} from '@ptsecurity/mosaic/core';
import { McFormFieldControl, McValidateDirective } from '@ptsecurity/mosaic/form-field';
import { McSelect } from '@ptsecurity/mosaic/select';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject, timer } from 'rxjs';
import { catchError, debounce, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DataSourcesOrderType } from '@pt-cybsi/api-interfaces';
import { AsyncState, DestroyService, McFieldBase, selectHiddenItemsTextFormatter } from '@pt-cybsi/shared';

import { SourcesSyncService } from '../../services';
import { SourcePreviewFormat } from '../source-preview/source-preview.component';

let nextUniqueId = 0;

const SourcesSelectMixinBase: CanDisableCtor & CanUpdateErrorStateCtor & typeof McFieldBase = mixinDisabled(
    mixinErrorState(McFieldBase)
);

type TSourceSelectValue = string | string[];

export type TSourcesSelectLoadFn = (sourceName?: string) => Observable<string[]>;

/**
 * @component SourcesSelect
 *
 * @description
 * Form field for selecting a single or several sources.
 *
 * Sources are sorted in alphabetical order.
 *
 * @param id - Unique id of field. Optional, will be generated automatically
 * @param ngModel - type or types array of observable entities
 * @param placeholder - Placeholder text. Optional, will be used empty text
 * @param multiple - Boolean value for marking the field as multiple. Optional, will be used `false`
 * @param disabled - Boolean value for marking the field as disabled. Optional, will be used `false`
 * @param required - Boolean value for marking the field as required. Optional, will be used `false`
 * @param focused - Boolean value for marking the field as focused. Optional, will be used `false`
 * @param loadSourcesFunction - Function implemented loading sources process. Optional, will be used SourcesSyncService`
 *
 *
 * @example
 * ```html
 * <mc-form-field>
 *     <sources-select multiple
 *                     [(ngModel)]="value"
 *                     [id]="id"
 *                     [placeholder]="placeholder"
 *                     [disabled]="disabled"
 *                     [required]="required"
 *                     [focused]="focused"
 *                     [loadSourcesFunction]="loadSourcesFunction">
 *     </sources-select>
 * </mc-form-field>
 *
 * <mc-form-field>
 *     <sources-select [(ngModel)]="value"
 *                     [id]="id"
 *                     [placeholder]="placeholder"
 *                     [disabled]="disabled"
 *                     [required]="required"
 *                     [focused]="focused">
 *     </sources-select>
 * </mc-form-field>
 * ```
 */
@Component({
    selector: 'sources-select',
    templateUrl: './sources-select.component.html',
    styleUrls: ['./sources-select.component.scss'],
    // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None,
    providers: [
        DestroyService,
        {
            provide: McFormFieldControl,
            useExisting: SourcesSelectComponent
        }
    ]
})
export class SourcesSelectComponent
    extends SourcesSelectMixinBase
    implements
        McFormFieldControl<TSourceSelectValue>,
        ControlValueAccessor,
        OnChanges,
        OnInit,
        DoCheck,
        OnDestroy,
        CanDisable,
        CanUpdateErrorState
{
    @Input()
    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value || this.uid;
        this.stateChanges.next();
    }

    @Input()
    get value(): TSourceSelectValue {
        return this._value;
    }

    set value(value: TSourceSelectValue) {
        if (value !== this.value) {
            this._value = value;
            this.stateChanges.next();
        }
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);

        if (this.focused) {
            this.focused = false;
            this.stateChanges.next();
        }
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);

        this.stateChanges.next();
    }

    @Input()
    get focused(): boolean {
        return this.mcSelect ? this.mcSelect.focused : this._focused;
    }

    set focused(value: boolean) {
        this._focused = coerceBooleanProperty(value);

        if (this.mcSelect) {
            this.mcSelect.focused = this._focused;
        }

        this.stateChanges.next();
    }

    @Input() multiple: boolean;
    @Input() placeholder: string;
    @Input() loadSourcesFunction: TSourcesSelectLoadFn;

    @ViewChild('mcSelect') mcSelect: McSelect;

    get empty(): boolean {
        return this._value === null || this._value === undefined;
    }

    get searchData(): string {
        return (this.searchControl.value as string) || '';
    }

    get isNotFoundData(): boolean {
        return this.searchData.length > 0 && this.availableSources.length === 0;
    }

    get isEmptyData(): boolean {
        return this.searchData.length === 0 && this.availableSources.length === 0;
    }

    SourcePreviewFormat = SourcePreviewFormat;

    availableSources: string[] = [];
    availableSources$: BehaviorSubject<string[]> = new BehaviorSubject([] as string[]);

    errorState: boolean;
    controlType = 'sources-select';

    selectHiddenItemsTextFormatter = selectHiddenItemsTextFormatter;

    onChange: (value: TSourceSelectValue) => void;
    onTouched: () => void;

    searchControl: UntypedFormControl = new UntypedFormControl();

    loadingSourcesState$: BehaviorSubject<AsyncState> = new BehaviorSubject(AsyncState.Loading);
    isOpened$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    retryLoadData$: Subject<void> = new Subject();

    filterData$: Observable<string> = this.searchControl.valueChanges.pipe(
        startWith(''),
        map((value: string) => value || ''),
        debounce((value) =>
            value.length > 0
                ? // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                  timer(250)
                : timer(0)
        )
    );

    startLoading$: Observable<unknown> = merge(this.isOpened$, this.filterData$, this.retryLoadData$);

    loadingData$: Observable<string[]> = this.startLoading$.pipe(
        switchMap(() => this.isOpened$),
        filter((isOpened) => isOpened),
        switchMap(() => this.loadSources(this.searchControl.value as string)),
        takeUntil(this.destroyed$)
    );

    isLoading$: Observable<boolean> = this.loadingSourcesState$.pipe(map((state) => state === AsyncState.Loading));

    isLoaded$: Observable<boolean> = this.loadingSourcesState$.pipe(map((state) => state === AsyncState.Success));

    isLoadingFailure$: Observable<boolean> = this.loadingSourcesState$.pipe(
        map((state) => state === AsyncState.Failure)
    );

    isNotFoundData$: Observable<boolean> = combineLatest([
        this.isOpened$,
        this.isLoaded$,
        this.filterData$,
        this.availableSources$
    ]).pipe(
        map(
            ([isOpened, isLoaded, filterData, availableSources]) =>
                isOpened && isLoaded && filterData.length > 0 && availableSources.length === 0
        )
    );

    isEmptyData$: Observable<boolean> = combineLatest([
        this.isOpened$,
        this.isLoaded$,
        this.filterData$,
        this.availableSources$
    ]).pipe(
        map(
            ([isOpened, isLoaded, filterData, availableSources]) =>
                isOpened && isLoaded && filterData.length === 0 && availableSources.length === 0
        )
    );

    isLoaderVisible$: Observable<boolean> = combineLatest([this.isLoading$, this.isOpened$]).pipe(
        map(([isLoading, isOpened]) => isLoading || !isOpened)
    );

    isErrorVisible$: Observable<boolean> = combineLatest([this.isLoadingFailure$, this.isOpened$]).pipe(
        map(([isLoadingFailure, isOpened]) => isLoadingFailure && isOpened)
    );

    isDataHidden$: Observable<boolean> = combineLatest([this.isOpened$, this.isLoading$, this.isLoadingFailure$]).pipe(
        map(([isOpened, isLoading, isLoadingFailure]) => isLoading || isLoadingFailure || !isOpened)
    );

    private _id: string;
    private _value: TSourceSelectValue;
    private _disabled = false;
    private _focused = false;
    private _required = false;

    private readonly uid = `${this.controlType}-${nextUniqueId++}`;

    private defaultLoadSourcesFunction: TSourcesSelectLoadFn = (sourceName?: string): Observable<string[]> =>
        this.sourcesSyncService.loadAndSyncSources({
            ...(sourceName ? { query: sourceName } : {}),
            orderBy: DataSourcesOrderType.FullName
        });

    constructor(
        public defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() public parentForm: NgForm,
        @Optional() public parentFormGroup: FormGroupDirective,
        @Self() public ngControl: NgControl,
        private sourcesSyncService: SourcesSyncService,
        private destroyed$: DestroyService
    ) {
        super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

        this.ngControl.valueAccessor = this;

        // eslint-disable-next-line no-self-assign
        this.id = this.id;
    }

    ngOnInit(): void {
        this.loadingData$.subscribe((sources) => {
            this.availableSources$.next(sources);
        });

        setTimeout(() => {
            this.availableSources$.next(this.value ? ([] as string[]).concat(this.value) : ([] as string[]));
        });
    }

    ngOnChanges(): void {
        this.stateChanges.next();
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
    }

    handleOpenedChange(isOpened: boolean): void {
        this.isOpened$.next(isOpened);
    }

    handleChangeValue(value: TSourceSelectValue): void {
        this.value = value;

        this.onChange(value);
    }

    handleRetryLoadData(): void {
        this.retryLoadData$.next();
    }

    onContainerClick(): void {
        this.focus();
    }

    focus(): void {
        if (!this.disabled) {
            this.focused = true;

            this.mcSelect.focus();
        }
    }

    handleFocus(): void {
        this.focus();
    }

    writeValue(value: TSourceSelectValue): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string[] | string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    private loadSources(sourceName?: string): Observable<string[]> {
        const isClosed$ = this.isOpened$.pipe(filter((isOpened) => !isOpened));

        this.loadingSourcesState$.next(AsyncState.Loading);

        const load = this.loadSourcesFunction || this.defaultLoadSourcesFunction;

        return load(sourceName).pipe(
            tap(() => this.loadingSourcesState$.next(AsyncState.Success)),
            catchError(() => {
                this.loadingSourcesState$.next(AsyncState.Failure);

                return of([] as string[]);
            }),
            takeUntil(isClosed$)
        );
    }
}

@Directive({
    selector: 'sources-select',
    exportAs: 'SourcesSelectMcValidate'
})
export class SourcesSelectMcValidateDirective extends McValidateDirective {}
