import { IDataSourceType, IDataSourceTypeShortInfo } from '@pt-cybsi/api-interfaces';

import { DataSourceViewFormat, IDataSourceTypeView } from '../types';

export class DataSourceTypeMapper {
    static toTypeView(type: IDataSourceType | IDataSourceTypeShortInfo): IDataSourceTypeView {
        const name = type.longName;

        return {
            format: DataSourceViewFormat.Type,
            id: type.uuid,
            name,
            confidence: type.confidence
        };
    }
}
