import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ClientConfigEffects } from './state/client-config.effects';
import { ClientConfigStoreFacade } from './state/client-config.facade';
import * as fromClientConfig from './state/client-config.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(fromClientConfig.CLIENT_CONFIG_FEATURE_KEY, fromClientConfig.reducer),
        EffectsModule.forFeature([ClientConfigEffects])
    ],
    providers: [ClientConfigStoreFacade]
})
export class StoreClientConfigModule {}
