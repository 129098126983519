import { Observable, of } from 'rxjs';

import { IServerError } from '@pt-cybsi/api-interfaces';

import { FormState, TBaseModel } from '../../models';
import { FormMode } from '../../types';
import { IFormRepository } from '../form.repository';

/* eslint-disable @typescript-eslint/no-unused-vars */
export class FakeFormRepository<FormData extends TBaseModel> implements IFormRepository<FormData> {
    loadData(serverId: string): Observable<FormData> {
        return of(null as FormData);
    }

    add(mode: FormMode, data: FormData): void {
        return;
    }

    delete(formId: string): void {
        return;
    }

    getState(formId: string): FormState {
        return null;
    }

    updateState(formId: string, state: FormState): void {
        return;
    }

    getInitialData(formId: string): FormData {
        return null;
    }

    updateInitialData(data: FormData): void {
        return;
    }

    getCurrentData(formId: string): FormData {
        return null;
    }

    updateCurrentData(data: FormData): void {
        return;
    }

    updateSavingError(formId: string, error: IServerError): void {
        return;
    }

    resetSavingError(formId: string): void {
        return;
    }

    isTouched(id: string): boolean {
        return false;
    }

    disable(formId: string, fields?: (keyof FormData['props'])[]): void {
        return;
    }

    enable(formId: string, fields?: (keyof FormData['props'])[]): void {
        return;
    }

    showControls(id: string, names: (keyof FormData['props'])[]): void {
        return;
    }

    hideControls(id: string, names: (keyof FormData['props'])[]): void {
        return;
    }

    validate(id: string): Observable<boolean> {
        return of(false);
    }

    save(data: FormData): Observable<string> {
        return of('fake-id');
    }
}
