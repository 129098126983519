import { IServerError } from '@pt-cybsi/api-interfaces';

import { AsyncState, TResponseWithState } from '../types';

export const toLoadingResponse = <T>(data: T): TResponseWithState<T> => ({ state: AsyncState.Loading, data });

export const toSuccessResponse = <T>(response: T): TResponseWithState<T> => ({
    state: AsyncState.Success,
    data: response
});

export const toFailureResponse = <T>(error: IServerError): TResponseWithState<T> => ({
    state: AsyncState.Failure,
    data: error
});

export const isSuccessResponse = <T, R extends TResponseWithState<T>>(
    response: R
): response is Extract<R, { state: AsyncState.Success }> => response.state === AsyncState.Success;

export const isFailureResponse = <T, R extends TResponseWithState<T>>(
    response: R
): response is Extract<R, { state: AsyncState.Failure }> => response.state === AsyncState.Failure;

export const isLoadingResponse = <T, R extends TResponseWithState<T>>(
    response: R
): response is Extract<R, { state: AsyncState.Loading }> => response.state === AsyncState.Loading;
