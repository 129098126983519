<filters-panel [isResetDisabled]="isResetDisabled">
    <text-filter
        class="enrichment-rules-filter-panel__name"
        filters-panel-item
        [value]="filtersParams.name"
        [placeholder]="'enrichment.Enrichment.RulesGrid.Text.Name' | transloco"
        (applyFilter)="applyFilter({ name: $event })"
        (resetFilter)="resetFilter('name')">
    </text-filter>
    <enrichment-types-filter
        filters-panel-item

        [value]="filtersParams.enrichmentType"

        (applyFilter)="applyFilter({ enrichmentType: $event })"
        (resetFilter)="resetFilter('enrichmentType')">
    </enrichment-types-filter>
    <entity-types-filter
        filters-panel-item

        [value]="filtersParams.entityType"

        (applyFilter)="applyFilter({ entityType: $event })"
        (resetFilter)="resetFilter('entityType')">
    </entity-types-filter>
    <artifact-types-filter
        filters-panel-item

        [value]="filtersParams.artifactType"

        (applyFilter)="applyFilter({ artifactType: $event })"
        (resetFilter)="resetFilter('artifactType')">
    </artifact-types-filter>
    <enrichment-triggers-filter
        filters-panel-item

        [value]="filtersParams.triggerType"

        (applyFilter)="applyFilter({ triggerType: $event })"
        (resetFilter)="resetFilter('triggerType')">
    </enrichment-triggers-filter>
    <sources-filter
        filters-panel-item

        [title]="'enrichment.Enrichment.Pseudo.Text.TriggerSources' | transloco"
        [value]="filtersParams.triggerDataSourceUUID"

        (applyFilter)="applyFilter({ triggerDataSourceUUID: $event })"
        (resetFilter)="resetFilter('triggerDataSourceUUID')">
    </sources-filter>
    <sources-filter
        filters-panel-item

        [title]="'common.Common.Pseudo.Text.Sources' | transloco"
        [value]="filtersParams.dataSourceUUID"

        (applyFilter)="applyFilter({ dataSourceUUID: $event })"
        (resetFilter)="resetFilter('dataSourceUUID')">
    </sources-filter>
    <status-filter
        filters-panel-item

        [value]="filtersParams.isDisabled"
        [isValueReversed]='true'
        translationKeyBase="enrichment.Enrichment.Status.Text"

        (applyFilter)="applyFilter({ isDisabled: $event })"
        (resetFilter)="resetFilter('isDisabled')">
    </status-filter>
</filters-panel>
