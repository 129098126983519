import { Component, EventEmitter, Input, Output } from '@angular/core';
import { translate } from '@ngneat/transloco';

import {
    AttributeObservableEntity,
    ObservableEntityType,
    TAttributeValueType,
    TConfidence
} from '@pt-cybsi/api-interfaces';
import { PermissionsService } from '@pt-cybsi/domain-core/account';

import { getAttributeCollectionTitle, isCollectionAttribute } from '../../helpers';
import {
    IObservableEntitiesFilterParams,
    ObservableEntitiesNavigationService,
    ObservableEntitiesPermissionsService
} from '../../services';
import {
    IEntityMetadataAttribute,
    TEntityMetadata,
    TFileMetadata,
    TIdentityMetadata,
    TIpAddressMetadata
} from '../../types';
import { ContentWithConfidenceFormat } from '../content-with-confidence/content-with-confidence.component';

export enum EntityMetadataCardFormat {
    Default = 'Default',
    Interactive = 'Interactive'
}

export interface IShowValuableFactsEvent {
    attributeName: AttributeObservableEntity;
    attributeValue: TAttributeValueType;
    confidence: TConfidence;
}

const DEFAULT_CARD_FORMAT = EntityMetadataCardFormat.Default;
const MAX_FILE_KEYS = 3;

/**
 * @component EntityMetadataCard
 *
 * @description
 * Component for displaying an entity metadata
 *
 * @param format - display format from EntityMetadataCardFormat
 * @param data - entity data in TEntityMetadata format
 * @param editable - toggle editable mode
 * @param updateKeys - event, emitted by clicking on edit entity keys button
 * @param showValuableFacts - event, emitted by clicking on confidence of attribute value
 * @param updateLabels - event, emitted by clicking edit entity labels button
 *
 * @example
 * ```html
 *
 * <entity-metadata-card
 *       [format]="EntityMetadataCardFormat.IsDelegated"
 *       [data]="entityMetadata"
 *       (updateKeys)="handleUpdateKeys()"
 *       (showValuableFacts)="handleShowValuableFacts($event)"
 *       (updateLabels)="handleUpdateLabels()">
 * </entity-metadata-card>
 *
 * ```
 */
@Component({
    selector: 'entity-metadata-card',
    templateUrl: './entity-metadata-card.component.html'
})
export class EntityMetadataCardComponent {
    @Input() format: EntityMetadataCardFormat;
    @Input() data: TEntityMetadata;
    @Input() editable: boolean;

    @Output() updateKeys = new EventEmitter<void>();
    @Output() showValuableFacts = new EventEmitter<IShowValuableFactsEvent>();
    @Output() updateLabels = new EventEmitter<void>();

    readonly ContentWithConfidenceFormat = ContentWithConfidenceFormat;

    readonly updateKeysPermissions = ObservableEntitiesPermissionsService.updateKeys;
    readonly updateLabelsPermissions = ObservableEntitiesPermissionsService.updateLabels;

    readonly searchByLabelLink = this.observableEntitiesNavigationService.getPathOfListRoute();

    get cardFormat(): EntityMetadataCardFormat {
        return this.format || DEFAULT_CARD_FORMAT;
    }

    get isInteractiveFormat(): boolean {
        return this.cardFormat === EntityMetadataCardFormat.Interactive;
    }

    get hasUpdateKeysPermissions(): boolean {
        return this.permissionsService.hasAllPermissions(this.updateKeysPermissions);
    }

    get isEditableLabels(): boolean {
        return (
            this.editable &&
            this.isInteractiveFormat &&
            this.permissionsService.hasAllPermissions(this.updateLabelsPermissions)
        );
    }

    get contentWithConfidenceFormat(): ContentWithConfidenceFormat {
        return this.isInteractiveFormat ? ContentWithConfidenceFormat.Interactive : ContentWithConfidenceFormat.Text;
    }

    get isFile(): boolean {
        return this.data.type === ObservableEntityType.File;
    }

    get isIdentity(): boolean {
        return this.data.type === ObservableEntityType.Identity;
    }

    get isIpAddress(): boolean {
        return this.data.type === ObservableEntityType.IPAddress;
    }

    get isUrl(): boolean {
        return this.data.type === ObservableEntityType.URL;
    }

    get isVerdictAttributesSectionVisible(): boolean {
        return this.data.verdictAttributes.length > 0 && this.data.verdictAttributes.some(this.hasAttributeValues);
    }

    get isKeysSectionVisible(): boolean {
        return this.isFile || this.isIdentity;
    }

    get isFullUrlSectionVisible(): boolean {
        return this.isUrl;
    }

    get isAttributesSectionVisible(): boolean {
        return this.data.attributes.length > 0;
    }

    get hasKeys(): boolean {
        return (this.isFile || this.isIdentity) && (this.data as TFileMetadata | TIdentityMetadata).keys.length > 0;
    }

    get isUpdateKeysButtonVisible(): boolean {
        switch (this.data.type) {
            case ObservableEntityType.File:
                return (
                    this.editable &&
                    this.isInteractiveFormat &&
                    this.hasUpdateKeysPermissions &&
                    this.data.keys.length < MAX_FILE_KEYS
                );

            case ObservableEntityType.Identity:
                return this.editable && this.isInteractiveFormat && this.hasUpdateKeysPermissions;

            default:
                return false;
        }
    }

    get hasLocationData(): boolean {
        return (this.data as TIpAddressMetadata).location !== null;
    }

    get isLabelsSectionVisible(): boolean {
        return this.isEditableLabels || this.data.labels.length > 0;
    }

    get keysSectionTitle(): string {
        switch (this.data.type) {
            case ObservableEntityType.File:
                return translate('common.Common.HashSum.Text.Title');

            case ObservableEntityType.Identity:
                return translate('entities.ObservableEntities.Identity.Text.Keys');

            default:
                return '';
        }
    }

    constructor(
        private permissionsService: PermissionsService,
        private observableEntitiesNavigationService: ObservableEntitiesNavigationService
    ) {}

    handleShowValuableFacts(
        attributeName: AttributeObservableEntity,
        valueData: IEntityMetadataAttribute['data'][number]
    ): void {
        this.showValuableFacts.emit({
            attributeName,
            attributeValue: valueData.value,
            confidence: valueData.confidence
        });
    }

    isCollectionAttribute(attribute: IEntityMetadataAttribute): boolean {
        return isCollectionAttribute(attribute.attributeName);
    }

    isInteractiveVerdict(attribute: IEntityMetadataAttribute): boolean {
        switch (attribute.attributeName) {
            case AttributeObservableEntity.ThreatCategory:
            case AttributeObservableEntity.RelatedThreatCategory:
                return this.isInteractiveFormat && attribute.data[0].value !== null;

            default:
                return this.isInteractiveFormat;
        }
    }

    isLocationSectionVisible(attribute: IEntityMetadataAttribute): boolean {
        return this.isIpAddress && attribute.attributeName === AttributeObservableEntity.ASN;
    }

    hasAttributeValues(attribute: IEntityMetadataAttribute): boolean {
        return attribute.data.length > 0;
    }

    getAttributeCollectionTitle(attribute: IEntityMetadataAttribute): string {
        return getAttributeCollectionTitle(attribute.attributeName, this.data.type, attribute.data.length);
    }

    getSearchByLabelLinkQueryParams(label: string): IObservableEntitiesFilterParams {
        return { labels: [label] };
    }
}
