import { ArtifactArchiveType, ArtifactType, ShareLevel } from '@pt-cybsi/api-interfaces';

export interface IArtifactTypeRequestParams {
    file: Blob | File;
}

export interface IArtifactsRequestParams {
    type?: ArtifactType;
    dataSourceUUID?: string[];
    fileUUID?: string;
    hash?: string;
}

export interface IArtifactRequestBody {
    type?: ArtifactType;
    shareLevel?: ShareLevel;
    file?: Blob | File;
}

export interface IArtifactContentRequestParams {
    archive?: ArtifactArchiveType;
    password?: string;
}
