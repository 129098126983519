import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

import { FormStateValue } from '../../models';

@Component({
    selector: 'form-fields-group',
    templateUrl: './form-fields-group.component.html',
    styleUrls: ['./form-fields-group.component.scss'],
    // Temporaly, while all forms not use new forms architecture from an add-sources-form
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'form-fields-group'
    }
})
export class FormFieldsGroupComponent {
    @Input() formState: FormStateValue;

    @HostBinding('class.form-fields-group_progress')
    get isProgressState(): boolean {
        return [FormStateValue.Validating, FormStateValue.Saving].indexOf(this.formState) !== -1;
    }
}
