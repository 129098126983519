<div class="identity-keys__group">
    <div class="layout-margin-bottom-xxs text-less-contrast">{{ nicHandlesKeyTitle }}</div>

    <limited-collection
        *ngIf="hasNicHandles; else emptyKeyTemplate"
        [collection]="getNicHandleKeysValues() | sort"
        [collectionTitle]="nicHandlesCollectionTitle"
        [itemTemplate]="nicHandleCollectionItemTemplate">
    </limited-collection>

    <ng-template #nicHandleCollectionItemTemplate let-item="item">
        <default-collection-item [value]="item"></default-collection-item>
    </ng-template>
</div>

<div class="identity-keys__group layout-column">
    <div class="layout-margin-bottom-xxs text-less-contrast">{{ ianaIdKeyTitle }}</div>
    <div *ngIf="hasIanaId; else emptyKeyTemplate" class="layout-row-inline pt-text-overflow" pt-title>{{ getIanaIdKeyValue() }}</div>
</div>

<ng-template #emptyKeyTemplate>
    <span class="text-less-contrast">—</span>
</ng-template>