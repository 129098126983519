import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IOldDictionaries } from '@pt-cybsi/api-interfaces';

import { addApiHeader } from '../helpers';

import { BaseApiService } from './base-api.service';

@Injectable()
export class OldDictionariesApiService extends BaseApiService {
    static readonly url = 'api/dictionaries';

    getDictionaries() {
        return this.http.get<IOldDictionaries>(OldDictionariesApiService.url, {
            headers: addApiHeader(new HttpHeaders(), 1)
        });
    }
}
