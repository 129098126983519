import { asyncScheduler, Observable, of, timer } from 'rxjs';
import { expand, mapTo } from 'rxjs/operators';

export function getDelay(backoffDelay: number, maxInterval: number): number {
    return Math.min(backoffDelay, maxInterval);
}

export function exponentialBackoffDelay(iteration: number, initialInterval: number): number {
    // eslint-disable-next-line no-magic-numbers
    return Math.pow(2, iteration) * initialInterval;
}

export interface ITruncatedExponentialBackoffConfig {
    maxBackoff: number;
    delay: number;
}
export const ENRICHMENT_API_MAX_BACKOFF = 32000;
export const ENRICHMENT_API_MAX_INTERVAL = 1000;

const DEFAULT_TEB_CONFIG: ITruncatedExponentialBackoffConfig = {
    maxBackoff: ENRICHMENT_API_MAX_BACKOFF,
    delay: ENRICHMENT_API_MAX_INTERVAL
};

export function intervalBackoff(config: ITruncatedExponentialBackoffConfig = DEFAULT_TEB_CONFIG): Observable<number> {
    const { delay, maxBackoff } = config;

    return of(0, asyncScheduler).pipe(
        expand((iteration) =>
            timer(getDelay(exponentialBackoffDelay(iteration, delay), maxBackoff)).pipe(mapTo(iteration + 1))
        )
    );
}

export const voidOf = () => of(undefined as void);
