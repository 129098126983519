import { Pipe, PipeTransform } from '@angular/core';

import { IDictionaryBrief } from '@pt-cybsi/api-interfaces';
import { TranslateDictionaryNamePipe } from '@pt-cybsi/domain-core/dictionaries';
import { createASCSortComparator } from '@pt-cybsi/shared';

@Pipe({
    name: 'translateDictionariesName'
})
export class TranslateDictionariesNamePipe implements PipeTransform {
    private readonly sortComparator = createASCSortComparator<IDictionaryBrief>((item) =>
        this.translateDictionaryName.transform(item, true)
    );

    constructor(private translateDictionaryName: TranslateDictionaryNamePipe) {}

    transform(dictionaries: IDictionaryBrief[]): IDictionaryBrief[] {
        const translatedDictionaries = dictionaries.map((dictionary) => ({
            ...dictionary,
            name: this.translateDictionaryName.transform(dictionary, true)
        }));

        return translatedDictionaries.sort(this.sortComparator);
    }
}
