import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

import {
    ArtifactType,
    EnrichmentTriggerType,
    EnrichmentType,
    IEditableResource,
    IEnrichmentRule,
    IEnrichmentRuleCreateParams,
    IEnrichmentRuleUpdateParams,
    ObservableEntityType
} from '@pt-cybsi/api-interfaces';
import { TFormData } from '@pt-cybsi/shared';

import { EnrichmentRuleModel } from '../models';

const milliseconds = 1000;

export class EnrichmentRuleModelMapper {
    static toFormData(model: EnrichmentRuleModel): TEnrichmentRuleFormData {
        return { id: model.uid, ...model.props };
    }

    static fromFormData(data: TEnrichmentRuleFormData): EnrichmentRuleModel {
        return EnrichmentRuleModel.create({ ...data }, data.id);
    }

    static fromEditableRule(enrichmentRule: IEditableResource<IEnrichmentRule>): EnrichmentRuleModel {
        const { eTag, data: rule } = enrichmentRule;

        return EnrichmentRuleModel.create({
            serverId: rule.uuid,
            eTag,
            isBuiltin: rule.isBuiltin,
            isDisabled: rule.isDisabled,
            name: rule.name,
            triggers: rule.triggers || [],
            sources: (rule.dataSources || []).map((source) => source.uuid),
            enrichment: rule.enrichment,
            triggerSources: (rule.triggerDataSources || []).map((source) => source.uuid),
            artifactTypes: rule.artifactTypes || [],
            entityTypes: rule.entityTypes || [],
            throttlingInterval: rule.throttlingInterval * milliseconds
        });
    }

    static toRegisterParams(model: EnrichmentRuleModel): IEnrichmentRuleCreateParams {
        const {
            name,
            triggers,
            sources: dataSourceUUIDs,
            enrichment,
            triggerSources: triggerDataSourceUUIDs,
            artifactTypes,
            entityTypes,
            throttlingInterval
        } = model.props;

        return {
            name,
            enrichment,
            triggers,
            dataSourceUUIDs,

            ...(isEmpty(triggerDataSourceUUIDs) ? {} : { triggerDataSourceUUIDs }),
            ...(isEmpty(artifactTypes) ? {} : { artifactTypes }),
            ...(isEmpty(entityTypes) ? {} : { entityTypes }),
            ...(isNil(throttlingInterval) ? {} : { throttlingInterval: throttlingInterval / milliseconds })
        };
    }

    static toUpdateParams(model: EnrichmentRuleModel): IEnrichmentRuleUpdateParams {
        const {
            name,
            triggers,
            sources: dataSourceUUIDs,
            enrichment,
            triggerSources: triggerDataSourceUUIDs,
            artifactTypes,
            entityTypes,
            throttlingInterval
        } = model.props;

        return {
            ...(isUndefined(name) ? {} : { name }),
            ...(isUndefined(enrichment) ? {} : { enrichment }),
            ...(isUndefined(triggers) ? {} : { triggers }),
            ...(isUndefined(dataSourceUUIDs) ? {} : { dataSourceUUIDs }),
            ...(isUndefined(triggerDataSourceUUIDs) ? {} : { triggerDataSourceUUIDs }),
            ...(isUndefined(artifactTypes)
                ? {}
                : {
                      artifactTypes: artifactTypes?.length ? artifactTypes : null
                  }),
            ...(isUndefined(entityTypes)
                ? {}
                : {
                      entityTypes: entityTypes?.length ? entityTypes : null
                  }),
            ...(isUndefined(throttlingInterval)
                ? {}
                : {
                      throttlingInterval: isNull(throttlingInterval) ? null : throttlingInterval / milliseconds
                  })
        };
    }
}

export type TEnrichmentRuleFormData = TFormData<{
    serverId: string;
    eTag: string;
    enrichment: EnrichmentType;
    name: string;
    isDisabled: boolean;
    isBuiltin: boolean;
    triggers: EnrichmentTriggerType[];
    artifactTypes: ArtifactType[];
    entityTypes: ObservableEntityType[];
    sources: string[];
    triggerSources: string[];
    throttlingInterval: number;
}>;
