export enum EnrichmentType {
    DNSLookup = 'DNSLookup',
    WhoisLookup = 'WhoisLookup',
    ExternalDBLookup = 'ExternalDBLookup',
    ArtifactDownload = 'ArtifactDownload',
    ArtifactAnalysis = 'ArtifactAnalysis',
    ArchiveUnpack = 'ArchiveUnpack'
}

export const ENRICHMENT_TYPES = Object.values(EnrichmentType);

export enum EnrichmentTriggerType {
    OnRegistration = 'OnRegistration',
    OnDemand = 'OnDemand'
}

export const ENRICHMENT_TRIGGER_TYPES = Object.values(EnrichmentTriggerType);

export const ENRICHMENT_RULES_TYPES = [
    EnrichmentType.WhoisLookup,
    EnrichmentType.DNSLookup,
    EnrichmentType.ExternalDBLookup,
    EnrichmentType.ArtifactAnalysis
];
