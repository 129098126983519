import { IPaginationParams } from '@pt-cybsi/api-interfaces';

export interface IDictionaryItemsGetParams extends IPaginationParams {
    prefix?: string;
    substring?: string;
}

export interface IDictionaryCreateParams {
    name: string;
    isClosed: boolean;
}

export interface IDictionaryItemCreateParams {
    dictionaryUUID: string;
    key: string;
    description?: string;
}

export interface IDictionaryItemUpdateParams {
    description?: string;
}

export interface IAddItemToSynonymsParams {
    synonymUUID: string;
}
