<form *transloco="let tUsers; read: 'users'"
    class="mc-form-vertical"
    novalidate
    (ngSubmit)="handleSubmit()">
    <mc-modal-title>
        <span>{{ isCurrentUser
                     ? tUsers('Users.PasswordModal.Title.ChangePassword')
                     : tUsers('Users.PasswordModal.Title.NewPassword') }}
        </span>
    </mc-modal-title>

    <mc-modal-body class="password-modal__body">
        <progress-container [loadingFlag]="form.isSending">
            <div *ngIf="form.isSavingFailed"
                 class="mc-alert mc-alert_error margin-bottom_16 margin-right_16">
                <i mc-icon="mc-error_16" class="mc-alert__icon"></i>
                <div class="layout-column layout-align-start-start">
                    <div>{{ isChangedError }}</div>
                </div>
            </div>

            <password-form [form]="form"></password-form>
        </progress-container>
    </mc-modal-body>

    <mc-modal-footer>
        <button mc-button color="primary" type="submit" [disabled]="isSubmitDisabled">
            {{ isCurrentUser
                   ? ('common.Common.Pseudo.Button.Save' | transloco)
                   : tUsers('Users.PasswordModal.Button.Set') }}
        </button>
        <button mc-button color="second" type="button" (click)="closeModal()">
            {{ 'common.Common.Pseudo.Button.Cancel' | transloco }}
        </button>
    </mc-modal-footer>
</form>
