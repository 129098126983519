import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { McSidepanelPosition, McSidepanelService } from '@ptsecurity/mosaic/sidepanel';

import {
    DefaultCollectionSidepanelComponent,
    IDefaultCollectionSidepanelData
} from '../default-collection-sidepanel/default-collection-sidepanel.component';

const THRESHOLD_OVERFLOWED_TEXT = 2; // (width overflowed text) - 2px

interface ICollectionOutData {
    collection: unknown[];
    collectionTitle: string;
    itemTemplate: TemplateRef<unknown>;
}

export enum OverflowedCollectionType {
    Text = 'text',
    Link = 'link'
}

@Component({
    selector: 'overflowed-collection',
    templateUrl: './overflowed-collection.component.html',
    styleUrls: ['./overflowed-collection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverflowedCollectionComponent implements AfterViewInit, OnDestroy {
    @Input() collection: unknown[];
    @Input() collectionType: OverflowedCollectionType = OverflowedCollectionType.Text;
    @Input() collectionTitle: string;
    @Input() itemTemplate: TemplateRef<unknown>;
    @Input() collectionItemSeparator = ', ';
    @Input() isDefaultSidepanelOpenPrevented: boolean;

    @Output() showMore = new EventEmitter<ICollectionOutData>();

    @ViewChild('showAllLink', { read: ElementRef }) showAllLinkElement: ElementRef<HTMLElement>;
    @ViewChild('overflowedCollectionContainer', { read: ElementRef })
    overflowedCollectionContainerElement: ElementRef<HTMLElement>;
    @ViewChild('overflowedCollectionItems', { read: ElementRef })
    overflowedCollectionItemsElement: ElementRef<HTMLElement>;

    private resizeObserver: ResizeObserver;
    private widthShowAllLink: number;
    isVisibleShowAllLink = true;

    get length(): number {
        return this.collection && this.collection.length;
    }

    get hasItemSeparator(): boolean {
        return this.collectionItemSeparator !== undefined && this.collectionItemSeparator.length > 0;
    }

    get hasCollection(): boolean {
        return !!this.collection.length;
    }

    private toggleShowAllLink(isShow: boolean): void {
        this.isVisibleShowAllLink = isShow;
    }

    private get isHiddenShowAllLink(): boolean {
        return this.isVisibleShowAllLink;
    }

    constructor(private sidepanelService: McSidepanelService, private cdr: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.widthShowAllLink = Math.ceil(this.showAllLinkElement.nativeElement.clientWidth);

        this.toggleShowAllLink(this.isNeedShowAllButton());
        this.cdr.detectChanges();

        this.initResizeObserver();
        this.startResizeObserving();
    }

    ngOnDestroy() {
        this.stopResizeObserving();
    }

    trackByFn(item, index: number): string {
        if (typeof item === 'object') {
            return `${JSON.stringify(item)}${index}`;
        }

        return `${item}${index}`;
    }

    handleClickMore() {
        if (this.showMore) {
            this.showMore.emit({
                collection: this.collection,
                collectionTitle: this.collectionTitle,
                itemTemplate: this.itemTemplate
            });

            if (this.isDefaultSidepanelOpenPrevented) {
                return;
            }
        }

        this.sidepanelService.open<DefaultCollectionSidepanelComponent, IDefaultCollectionSidepanelData>(
            DefaultCollectionSidepanelComponent,
            {
                data: {
                    collection: this.collection,
                    collectionTitle: this.collectionTitle,
                    itemTemplate: this.itemTemplate
                },
                requiredBackdrop: true,
                position: McSidepanelPosition.Left,
                overlayPanelClass: 'sidepanel'
            }
        );
    }

    private isNeedShowAllButton(): boolean {
        if (!this.hasCollection || this.length === 1) {
            return false;
        }

        const actualTextContainerWidth = Math.floor(
            this.overflowedCollectionContainerElement.nativeElement.clientWidth
        );

        const availableWidth = this.isHiddenShowAllLink
            ? actualTextContainerWidth
            : actualTextContainerWidth + this.widthShowAllLink;

        const actualOverflowedTextWidth = Math.floor(this.overflowedCollectionItemsElement.nativeElement.offsetWidth);

        return availableWidth < actualOverflowedTextWidth - THRESHOLD_OVERFLOWED_TEXT;
    }

    private initResizeObserver(): void {
        this.resizeObserver = new ResizeObserver(() => {
            this.toggleShowAllLink(this.isNeedShowAllButton());
            this.cdr.detectChanges();
        });
    }

    private startResizeObserving(): void {
        this.resizeObserver.observe(this.overflowedCollectionContainerElement.nativeElement);
    }

    private stopResizeObserving(): void {
        this.resizeObserver.disconnect();
    }
}
