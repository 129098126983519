import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, ContentChild, ElementRef, NgZone, TemplateRef, ViewChild } from '@angular/core';
import { McMeasureScrollbarService } from '@ptsecurity/mosaic/core';

@Component({
    selector: 'view-page-container',
    templateUrl: './view-page-container.component.html',
    styleUrls: ['./view-page-container.component.scss']
})
export class ViewPageContainerComponent implements AfterViewInit {
    @ViewChild('body', { read: ElementRef }) bodyElementRef: ElementRef<HTMLElement>;
    @ViewChild('contentWrapper', { read: ElementRef }) contentWrapperElementRef: ElementRef<HTMLElement>;

    @ContentChild('sidebar') sidebar: TemplateRef<unknown>;

    get compensatedContentWidth(): number {
        return this.contentWidth - this.mcMeasureScrollbarService.scrollBarWidth;
    }

    get compensatedSidebarWidth(): number {
        return this.sidebarWidth - this.mcMeasureScrollbarService.scrollBarWidth;
    }

    get bodyGridTemplate(): string {
        const sidebarWidth = this.sidebar ? this.sidebarWidth : this.compensatedSidebarWidth;

        const scrollBarWidth = `${this.mcMeasureScrollbarService.scrollBarWidth}px`;

        return `1fr minmax(0, ${this.compensatedContentWidth}px) ${scrollBarWidth} minmax(0, ${sidebarWidth}px) 1fr`;
    }

    get maxContentWidth(): string {
        return this.sidebar ? `${this.compensatedContentWidth}px` : 'auto';
    }

    get scrollbarCompensation(): string {
        return this.sidebar ? '0' : `-${this.mcMeasureScrollbarService.scrollBarWidth}px`;
    }

    private readonly contentWidth = 796;
    private readonly sidebarWidth = 644;

    constructor(
        private ngZone: NgZone,
        private scrollDispatcher: ScrollDispatcher,
        private mcMeasureScrollbarService: McMeasureScrollbarService
    ) {}

    ngAfterViewInit(): void {
        this.scrollDispatcher.register(
            new CdkScrollable(
                this.sidebar ? this.contentWrapperElementRef : this.bodyElementRef,
                this.scrollDispatcher,
                this.ngZone
            )
        );
    }
}
