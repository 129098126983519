import { DateTime } from 'luxon';

import { ILicense } from '@pt-cybsi/api-interfaces';

import { getLicenseState } from '../helpers';
import { ILicenseView, LicenseViewState } from '../types/license-view';

export class LicenseMapper {
    static toView(license: ILicense): ILicenseView {
        if (!license) {
            return {
                no: null,
                state: LicenseViewState.Inactive,
                expiresAt: null,
                updatedAt: null
            };
        }

        const state = getLicenseState(license);

        const expiresAt = DateTime.fromISO(license.expiresAt);
        const updatedAt = license.updatedAt ? DateTime.fromISO(license.updatedAt) : null;

        return {
            no: license.no,
            state,
            expiresAt,
            updatedAt
        };
    }
}
