<div *transloco="let tAccount; read: 'account'" class="mc-alert mc-alert_warning mc-alert_dismissible">
    <div>
        <header>{{ tAccount('Account.SessionExpiredModal.Title.SessionExpired') }}</header>
        {{ tAccount('Account.SessionExpiredModal.Text.NeedToSignIn') | translateCut:0 }}
        <a mc-link class="mc-link" href="/" target="_blank">
            <span class="mc-link__text">{{ tAccount('Account.SessionExpiredModal.Text.NeedToSignIn') | translateCut:1 }}</span>
            <i mc-icon="mc-new-tab_16"></i>
        </a>{{ tAccount('Account.SessionExpiredModal.Text.NeedToSignIn') | translateCut:2 }}
    </div>
    <button mc-button class="mc-button_transparent mc-alert__close" (click)="closeModal()">
        <i class="mc-icon_light" mc-icon="mc-close-M_16" [color]="'second'"></i>
    </button>
</div>
