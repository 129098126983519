import { EventEmitter } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';

import { LazyDataState } from '../../models';

export interface ILazyGridProps {
    state: LazyDataState;
    agGridOptions: Partial<GridOptions>;
    columns: ColDef[];
    data: unknown[];
    errorTitle: string;
    errorDescription: string;
    textReload: string;
    loadMore: EventEmitter<void>;
}
