import range from 'lodash/range';
import { of } from 'rxjs';

import { IDataSource } from '@pt-cybsi/api-interfaces';
import { DataSourcesFacade } from '@pt-cybsi/store/data-sources';

import { createDataSourceMock } from './data-source.mock';

export const createFakeDataSourcesStoreFacade = (
    params: { dataSources: IDataSource[] } | { countSources: number } = { countSources: 10 }
) => {
    const dataSources =
        'dataSources' in params
            ? params.dataSources
            : range(params.countSources).map((sourceId) => createDataSourceMock(sourceId));

    return class implements Partial<DataSourcesFacade> {
        loaded$ = of(true);
        allDataSources$ = of(dataSources);
        currentCursor$ = of(null);

        selectSource = (sourceId: string) => of(dataSources.find((dataSource) => dataSource.uuid === sourceId));

        selectSources = (sourcesIds: string[]) =>
            of(dataSources.filter((dataSource) => sourcesIds.includes(dataSource.uuid)));

        fetchDataSources() {
            of();
        }

        syncDataSources() {
            of();
        }
    };
};
