<ng-container *transloco="let tUsers; read: 'users'">
    <div class="mc-form__row">
        <label class="mc-form__label">
            {{ 'common.Common.Pseudo.Text.Login' | transloco }}
        </label>

        <mc-form-field>
            <input mcInput
                   autocomplete="off"
                   [formControl]="form.getControl('login')">
        </mc-form-field>
    </div>
    <div class="mc-form__row">
        <label class="mc-form__label">
            {{ tUsers('Users.Pseudo.Text.Name') }}
        </label>

        <mc-form-field #fullName [autoFocusedField]="fullName">
            <input mcInput
                   autocomplete="off"
                   [formControl]="form.getControl('fullName')">
            <mc-hint *ngIf="form.hasErrors('fullName')" color="error">
                <div *ngIf="form.hasError('fullName', 'length')">{{ invalidFullNameLengthMessage }}</div>
            </mc-hint>
        </mc-form-field>
    </div>
    <div class="mc-form__row">
        <label class="mc-form__label">
            {{ tUsers('Users.Pseudo.Text.Email') }}
        </label>

        <mc-form-field>
            <input mcInput [formControl]="form.getControl('email')">
            <mc-hint *ngIf="form.hasErrors('email')" color="error">
                <div *ngIf="form.hasError('email', 'length')">{{ invalidEmailLengthMessage }}</div>
            </mc-hint>
        </mc-form-field>
    </div>

    <div class="cybsi-subheading padding-top-bottom_16">{{ tUsers('Users.Pseudo.Text.Interface') }}</div>

    <div class="mc-form__row current-user-form__language-field">
        <label class="mc-form__label">
            {{ tUsers('Users.Pseudo.Text.Language') }}
        </label>

        <mc-form-field *transloco="let tCommon; read: 'common'">
            <mc-select [formControl]="form.getControl('language')">
                <mc-option *ngFor="let locale of availableLocales" [value]="locale">
                    {{ tCommon('Common.Language.Text.' + locale) }}
                </mc-option>
            </mc-select>
        </mc-form-field>
    </div>
</ng-container>
