<ng-container *transloco="let tUsers; read: 'users'">
    <div class="layout-row">
        <div *ngIf="authProviderName"
             class="mc-badge mc-badge_default mc-badge_solid mc-badge_transparent margin-bottom_16">
            <div class="pt-text-overflow" pt-title>
                {{ tUsers('Users.Pseudo.Text.Authentication') + ': ' + authProviderName }}
            </div>
        </div>
    </div>

    <div class="mc-form__row">
        <label class="mc-form__label">
            {{ 'common.Common.Pseudo.Text.Login' | transloco }}
        </label>

        <mc-form-field #login [autoFocusedField]="login">
            <input mcInput
                   autocomplete="off"
                   [formControl]="form.getControl('login')">
            <mc-hint *ngIf="form.hasErrors('login')" color="error">
                <div *ngIf="form.hasError('login', 'length')">{{ invalidLoginLengthMessage }}</div>
                <div *ngIf="form.hasError('login', 'pattern')">{{ tUsers('Users.UserForm.Hint.LoginPattern') }}</div>
                <div *ngIf="form.hasError('login', 'unique')">{{ tUsers('Users.UserForm.ValidationError.DuplicateLogin') }}</div>
            </mc-hint>
            <mc-hint *ngIf="isModeCreation && !form.hasErrors('login')"  color="second">
                {{ tUsers('Users.UserForm.Hint.LoginPattern') }}
            </mc-hint>
        </mc-form-field>
    </div>
    <div class="mc-form__row">
        <label class="mc-form__label">
            {{ tUsers('Users.Pseudo.Text.Name') }}
        </label>

        <mc-form-field #fullName [autoFocusedField]="fullName">
            <input mcInput
                   autocomplete="off"
                   [formControl]="form.getControl('fullName')">
            <mc-hint *ngIf="form.hasErrors('fullName')" color="error">
                <div *ngIf="form.hasError('fullName', 'length')">{{ invalidFullNameLengthMessage }}</div>
            </mc-hint>
        </mc-form-field>
    </div>
    <div class="mc-form__row">
        <label class="mc-form__label">
            {{ tUsers('Users.Pseudo.Text.Email') }}
        </label>

        <mc-form-field>
            <input mcInput [formControl]="form.getControl('email')">
            <mc-hint *ngIf="form.hasErrors('email')" color="error">
                <div *ngIf="form.hasError('email', 'length')">{{ invalidEmailLengthMessage }}</div>
            </mc-hint>
        </mc-form-field>
    </div>
    <div *ngIf="isModeCreation" class="mc-form__row">
        <label class="mc-form__label">
            {{ 'common.Common.Pseudo.Text.Password' | transloco }}
        </label>

        <div class="layout-row layout-align-start-start">
            <mc-form-field class="user-form__password-field">
                <input #passwordInput mcInputPassword [formControl]="form.getControl('password')">
                <mc-password-toggle #passwordToggle
                    [mcTooltipNotHidden]="'common.Common.Pseudo.Text.PasswordHide' | transloco"
                    [mcTooltipHidden]="'common.Common.Pseudo.Text.PasswordShow' | transloco">
                </mc-password-toggle>

                <mc-password-hint [rule]="PasswordRules.Length" [min]="passwordLengthRequirements.min" [max]="passwordLengthRequirements.max">
                    {{ invalidPasswordLengthMessage }}
                </mc-password-hint>
                <mc-password-hint [rule]="PasswordRules.UpperLatin">{{ tUsers('Users.UserForm.PasswordHint.UpperLatin') }}</mc-password-hint>
                <mc-password-hint [rule]="PasswordRules.LowerLatin">{{ tUsers('Users.UserForm.PasswordHint.LowerLatin') }}</mc-password-hint>
                <mc-password-hint [rule]="PasswordRules.Digit">{{ tUsers('Users.UserForm.PasswordHint.Digit') }}</mc-password-hint>
                <mc-password-hint [rule]="PasswordRules.LatinAndSpecialSymbols">{{ tUsers('Users.UserForm.PasswordHint.LatinAndSpecialSymbols') }}</mc-password-hint>
            </mc-form-field>

            <div class="user-form__generate-password-container layout-row layout-align-start-center">
                <button mc-link pseudo type="button" class="clear-button" (click)="handleGeneratePassword()">
                    {{ tUsers('Users.UserForm.Button.Generate') }}
                </button>
            </div>
        </div>

    </div>
    <div class="mc-form__row">
        <label class="mc-form__label">
            {{ 'common.Common.Pseudo.Text.ShareLevel' | transloco }}
        </label>

        <mc-form-field #accessLevel [autoFocusedField]="accessLevel" class="user-form__access-level-field">
            <share-level-select [formControl]="form.getControl('accessLevel')"></share-level-select>
        </mc-form-field>
    </div>
    <div class="mc-form__row">
        <label class="mc-form__label">
            {{ tUsers('Users.Pseudo.Text.Roles') }}
        </label>

        <mc-form-field>
            <mc-select multiple
                       [formControl]="form.getControl('roles')"
                       [hiddenItemsTextFormatter]="selectHiddenItemsTextFormatter">
                <mc-option *ngFor="let role of availableRoles" [value]="role.id">
                    {{ role.name }}
                </mc-option>
                <mc-cleaner #mcSelectCleaner></mc-cleaner>
            </mc-select>
        </mc-form-field>
    </div>
</ng-container>

