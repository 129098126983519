import { Component, Input } from '@angular/core';

import { SignInFormViewModel } from '../../forms/sign-in';

@Component({
    selector: 'sign-in-form',
    templateUrl: './sign-in-form.component.html',
    styleUrls: ['./sign-in-form.component.scss']
})
export class SignInFormComponent {
    @Input() form: SignInFormViewModel;
}
