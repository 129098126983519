import { Injectable } from '@angular/core';

import { ILicense } from '@pt-cybsi/api-interfaces';

import { BaseApiService } from './base-api.service';

@Injectable()
export class LicenseApiService extends BaseApiService {
    static readonly urls = {
        upload: 'license',
        info: 'license/info'
    };

    getInfo() {
        return this.get<ILicense>(LicenseApiService.urls.info);
    }

    upload(license: File) {
        return this.put<void>(LicenseApiService.urls.upload, license, {
            headers: {
                'Content-Type': 'application/octet-stream'
            }
        });
    }
}
