import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { McButtonModule } from '@ptsecurity/mosaic/button';
import { McButtonToggleModule } from '@ptsecurity/mosaic/button-toggle';
import { McHighlightModule } from '@ptsecurity/mosaic/core';
import { McDatepickerModule } from '@ptsecurity/mosaic/datepicker';
import { McDividerModule } from '@ptsecurity/mosaic/divider';
import { McDropdownModule } from '@ptsecurity/mosaic/dropdown';
import { McFormFieldModule } from '@ptsecurity/mosaic/form-field';
import { McIconModule } from '@ptsecurity/mosaic/icon';
import { McInputModule } from '@ptsecurity/mosaic/input';
import { McLinkModule } from '@ptsecurity/mosaic/link';
import { McListModule } from '@ptsecurity/mosaic/list';
import { McPopoverModule } from '@ptsecurity/mosaic/popover';
import { McProgressBarModule } from '@ptsecurity/mosaic/progress-bar';
import { McProgressSpinnerModule } from '@ptsecurity/mosaic/progress-spinner';
import { McSelectModule } from '@ptsecurity/mosaic/select';
import { McSidepanelModule } from '@ptsecurity/mosaic/sidepanel';
import { McToolTipModule } from '@ptsecurity/mosaic/tooltip';
import { McTreeModule } from '@ptsecurity/mosaic/tree';
import { McTreeSelectModule } from '@ptsecurity/mosaic/tree-select';
import { AgGridModule } from 'ag-grid-angular';

import {
    DonutChartComponent,
    HighchartComponent,
    HistoryBackButtonComponent,
    LazyGridComponent,
    LazyGridLoaderComponent,
    LazyLoaderProgressComponent,
    PageContentHeaderComponent,
    ProgressContainerComponent,
    PropertyRowComponent,
    TemplateCellComponent,
    ViewPageContainerComponent,
    SearchPageLayoutComponent,
    InfoMessageComponent,
    FormPageLayoutComponent,
    NotificationsPortalComponent,
    NotificationMessageComponent,
    AgGridDetailsComponent,
    TimePeriodFieldComponent,
    LimitedCollectionComponent,
    DefaultCollectionSidepanelComponent,
    DefaultCollectionItemComponent,
    AsyncDataComponent,
    ShareLevelSelectComponent,
    FiltersPanelComponent,
    FiltersPanelItemDirective,
    FilterButtonComponent,
    ListFilterComponent,
    DropdownFilterComponent,
    FormFieldsGroupComponent,
    StatusBadgeComponent,
    StatusFilterComponent,
    TextFilterComponent,
    CheckboxGroupFieldComponent,
    OverflowedCollectionComponent,
    FlagIconComponent,
    LazyDataContainerComponent,
    ErrorAlertComponent,
    ShareLevelSelectMcValidateDirective,
    CheckboxGroupFieldMcValidateDirective,
    TimePeriodFieldMcValidateDirective,
    DateRangeFieldComponent,
    DateRangeFieldMcValidateDirective,
    SelectFileFieldComponent,
    SelectFileFieldMcValidateDirective,
    CopiedRowComponent,
    HashSumComponent,
    LabelsCollectionComponent,
    LabelsCollectionSidepanelComponent,
    LabelsCollectionItemComponent
} from './components';
import {
    AgGridScrollToBodyEndDirective,
    IntegerInputDirective,
    CopyClipboardDirective,
    LazyGridAgGridDetailsDirective,
    PtTitleDirective,
    CybsiFormFieldWithoutBordersDirective,
    ShareLevelIconDirective,
    AutofocusDirective,
    InfinityScrollConsumerDirective,
    AutofocusFirstFieldDirective,
    AutofocusedFieldDirective
} from './directives';
import { LeaveDirtyPageGuard } from './guards';
import {
    ArrayAsStringPipe,
    FilterPipe,
    FormattedDatePipe,
    FormatBytesPipe,
    FormatConfidencePipe,
    SortPipe,
    ToStringDateRangePipe,
    ToTimePeriodStringPipe,
    TranslateCutPipe,
    TranslateHashSumTypePipe
} from './pipes';
import {
    NotificationUnsavedChangesService,
    NotificationsService,
    ConfirmModalService,
    TimePeriodService,
    PermissionsParserService
} from './services';

const components = [
    ProgressContainerComponent,
    TemplateCellComponent,
    ViewPageContainerComponent,
    PageContentHeaderComponent,
    HistoryBackButtonComponent,
    LazyLoaderProgressComponent,
    LazyGridComponent,
    LazyDataContainerComponent,
    SearchPageLayoutComponent,
    PropertyRowComponent,
    InfoMessageComponent,
    FormPageLayoutComponent,
    CopiedRowComponent,
    DonutChartComponent,
    HashSumComponent,
    HighchartComponent,
    NotificationsPortalComponent,
    NotificationMessageComponent,
    TimePeriodFieldComponent,
    LimitedCollectionComponent,
    LabelsCollectionItemComponent,
    LabelsCollectionComponent,
    LabelsCollectionSidepanelComponent,
    DefaultCollectionSidepanelComponent,
    DefaultCollectionItemComponent,
    AsyncDataComponent,
    ShareLevelSelectComponent,
    FiltersPanelComponent,
    FilterButtonComponent,
    FormFieldsGroupComponent,
    ListFilterComponent,
    DropdownFilterComponent,
    StatusBadgeComponent,
    StatusFilterComponent,
    SelectFileFieldComponent,
    TextFilterComponent,
    CheckboxGroupFieldComponent,
    OverflowedCollectionComponent,
    FlagIconComponent,
    ErrorAlertComponent,
    DateRangeFieldComponent
];

const directives = [
    PtTitleDirective,
    AgGridScrollToBodyEndDirective,
    CopyClipboardDirective,
    LazyGridAgGridDetailsDirective,
    CybsiFormFieldWithoutBordersDirective,
    InfinityScrollConsumerDirective,
    IntegerInputDirective,
    ShareLevelIconDirective,
    FiltersPanelItemDirective,
    AutofocusDirective,
    ShareLevelSelectMcValidateDirective,
    CheckboxGroupFieldMcValidateDirective,
    TimePeriodFieldMcValidateDirective,
    SelectFileFieldMcValidateDirective,
    DateRangeFieldMcValidateDirective,
    AutofocusFirstFieldDirective,
    AutofocusedFieldDirective
];

const pipes = [
    ArrayAsStringPipe,
    FilterPipe,
    FormattedDatePipe,
    FormatBytesPipe,
    FormatConfidencePipe,
    SortPipe,
    ToStringDateRangePipe,
    ToTimePeriodStringPipe,
    TranslateCutPipe,
    TranslateHashSumTypePipe
];

// prettier-ignore
const services = [
    NotificationsService,
    NotificationUnsavedChangesService,
    ConfirmModalService,
    TimePeriodService,
    PermissionsParserService
];

const guards = [LeaveDirtyPageGuard];

// prettier-ignore
const AgGridRenderers = [
    TemplateCellComponent,
    LazyGridLoaderComponent,
    AgGridDetailsComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ScrollingModule,
        TranslocoModule,
        McProgressBarModule,
        McProgressSpinnerModule,
        McButtonModule,
        McButtonToggleModule,
        McDatepickerModule,
        McDropdownModule,
        McIconModule,
        McLinkModule,
        McFormFieldModule,
        McListModule,
        McSelectModule,
        McInputModule,
        McToolTipModule,
        McPopoverModule,
        McSidepanelModule,
        RouterModule,
        McDividerModule,
        McTreeModule,
        McTreeSelectModule,
        McHighlightModule,
        ScrollingModule,
        AgGridModule.withComponents([...AgGridRenderers])
    ],
    declarations: [...directives, ...components, ...pipes, ...AgGridRenderers],
    providers: [...guards, ...services, ...pipes],
    exports: [
        CommonModule,
        TranslocoModule,
        McButtonModule,
        McIconModule,
        McLinkModule,
        ...directives,
        ...components,
        ...pipes,
        ...AgGridRenderers
    ]
})
export class SharedModule {}
