/* eslint-disable @typescript-eslint/naming-convention */
export interface IBrandingConfig {
    DocumentationVisibility: boolean;
    SoftwareCompany: string;
    LogoBrandLongName: string;
    LogoBrandName: string;
    BrandLongName: string;
    BrandName: string;
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
export const BrandingConfig: IBrandingConfig = (window as any).BrandingConfig || {};
