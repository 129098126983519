import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import { IUserCreateParams, IUserUpdateParams } from '@pt-cybsi/api-interfaces';
import { TUserFormData } from '@pt-cybsi/domain-core/users';

export class UserFormMapper {
    static toCreateParams(formData: TUserFormData): IUserCreateParams {
        return {
            login: formData.login,
            fullName: formData.fullName || null,
            email: formData.email || null,
            password: formData.password || undefined,
            accessLevel: formData.accessLevel,
            roles: formData.roles
        };
    }

    static toUpdateParams(formData: Partial<TUserFormData>): IUserUpdateParams {
        const { fullName, email, accessLevel, roles } = formData;

        return {
            ...(isUndefined(fullName) ? {} : { fullName: fullName || null }),
            ...(isUndefined(email) ? {} : { email: email || null }),
            ...(isEmpty(accessLevel) ? {} : { accessLevel }),
            ...(isEmpty(roles) ? {} : { roles })
        };
    }
}
