<div class="entity-preview entity-preview_type_{{ renderType }}">
    <div class="entity-preview__main">
        <span *ngIf="hasThreatLevel" class="entity-preview__threat-level entity-preview__threat-level_{{ threatLevel }}"></span>
        <a *ngIf="isLinkEntity"
           class="pt-text-overflow"
           mc-link
           pt-title
           [routerLink]="getEntityViewLink(entityId)">
            <span class="mc-link__text">{{ entityName }}</span>
        </a>
        <div *ngIf="isTextEntity"
             class="entity-preview__entity pt-text-overflow"
             pt-title
             [innerText]="entityName">
        </div>

        <div *ngIf="isBadgesVisible"
             class="entity-preview__badges layout-row">
            <entity-attribute-badge *ngIf="isIoC"
                                    class="entity-preview__badge"
                                    [attributeName]="AttributeObservableEntity.IsIoC"
                                    [size]="EntityAttributeBadgeSize.Mini">
            </entity-attribute-badge>
            <entity-attribute-badge *ngIf="isTrusted"
                                    class="entity-preview__badge"
                                    [attributeName]="AttributeObservableEntity.IsTrusted"
                                    [size]="EntityAttributeBadgeSize.Mini">
            </entity-attribute-badge>
        </div>
    </div>
    <div *ngIf="isExtraAttributesVisible"
         class="entity-preview__extra-attributes-wrapper">
        <!-- TODO перенести компоненты для отображения дополнительных атрибутов
        <file-preview-extra-attributes *ngIf="isEntityTypeOf(ObservableEntityType.File)"
                                       class="entity-preview__extra-attributes"
                                       attributes="entityExtraAttributes">
        </file-preview-extra-attributes>

        <identity-preview-extra-attributes *ngIf="isEntityTypeOf(ObservableEntityType.Identity)"
                                           class="entity-preview__extra-attributes"
                                           attributes="entityExtraAttributes">
        </identity-preview-extra-attributes>

        <ip-address-preview-extra-attributes *ngIf="isEntityTypeOf(ObservableEntityType.IPAddress)"
                                             class="entity-preview__extra-attributes"
                                             attributes="entityExtraAttributes">
        </ip-address-preview-extra-attributes>

        <domain-name-preview-extra-attributes *ngIf="isEntityTypeOf(ObservableEntityType.DomainName)"
                                              class="entity-preview__extra-attributes"
                                              entity-id="entityId"
                                              attributes="entityExtraAttributes">
        </domain-name-preview-extra-attributes>

        <email-address-preview-extra-attributes *ngIf="isEntityTypeOf(ObservableEntityType.EmailAddress)"
                                                class="entity-preview__extra-attributes"
                                                attributes="entityExtraAttributes">
        </email-address-preview-extra-attributes>

        <phone-number-preview-extra-attributes *ngIf="isEntityTypeOf(ObservableEntityType.PhoneNumber)"
                                               class="entity-preview__extra-attributes"
                                               entity-id="entityId"
                                               attributes="entityExtraAttributes">
        </phone-number-preview-extra-attributes>
        -->
    </div>
</div>
