import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    AttributeNameMapper,
    AttributeObservableEntity,
    IAttributeForecastRequestParams,
    IAttributeValueForecast
} from '@pt-cybsi/api-interfaces';

import { BaseApiService } from './base-api.service';

@Injectable()
export class ObservableEntitiesAttributesApiService extends BaseApiService {
    static readonly urls = {
        forecast: (entityId: string, attributeName: AttributeObservableEntity): string =>
            `observable/entities/${entityId}/attributes/${AttributeNameMapper.toPathValue(attributeName)}`
    };

    getForecast(
        entityId: string,
        attributeName: AttributeObservableEntity,
        params: IAttributeForecastRequestParams = {}
    ): Observable<IAttributeValueForecast> {
        const url = ObservableEntitiesAttributesApiService.urls.forecast(entityId, attributeName);

        return this.get<IAttributeValueForecast>(url, this.addResolveRefsParam({ params }));
    }
}
