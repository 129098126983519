import { IApiItemPreview } from '../common';
import { IUser } from '../users';

export interface ISearchQueryBrief extends IApiItemPreview {
    name: string;
}

export interface ISearchQuery extends ISearchQueryBrief {
    author: IUser;
    text: string;
}
