import { Directive, Input, OnDestroy, OnInit } from '@angular/core';

import { ISessionExpiredRedirectPreventer, SessionExpiredRedirectPreventersStore } from '../stores';

@Directive({
    selector: '[preventSessionExpiredRedirect]'
})
export class PreventSessionExpiredRedirectDirective implements ISessionExpiredRedirectPreventer, OnInit, OnDestroy {
    @Input('preventSessionExpiredRedirect') isPreventSessionExpiredRedirect: boolean;

    constructor(private preventersStore: SessionExpiredRedirectPreventersStore) {}

    ngOnInit(): void {
        this.preventersStore.addPreventer(this);
    }

    ngOnDestroy(): void {
        this.preventersStore.removePreventer(this);
    }
}
