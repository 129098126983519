import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { merge, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { ObservableEntitiesApiService } from '@pt-cybsi/api';
import {
    AGGREGATE_SECTIONS,
    DefaultErrorCodes,
    IServerError,
    ObservableEntityType,
    TAggregate
} from '@pt-cybsi/api-interfaces';
import {
    AsyncState,
    DestroyService,
    isAsyncStateFailure,
    isAsyncStateLoading,
    isAsyncStateSuccess,
    isFailureResponse,
    isServerError,
    isSuccessResponse,
    toFailureResponse,
    toLoadingResponse,
    toSuccessResponse,
    TResponseWithState
} from '@pt-cybsi/shared';

import { EntityMetadataMapper, EntityPreviewMapper } from '../mappers';
import { EntityFullInfoModel } from '../models';
import { TEntityMetadata, TEntityPreviewData } from '../types';

type TEntityId = string;
type TEntitiesViewState = Record<TEntityId, TResponseWithState<TAggregate>>;

const DEFAULT_STATE: TEntitiesViewState = {};

@Injectable()
export class EntityViewStore extends ComponentStore<TEntitiesViewState> {
    readonly load = this.effect((entityId$: Observable<TEntityId>) =>
        entityId$.pipe(
            tap((entityId) => {
                this.cancelLoading(entityId);

                this.toLoading(entityId);
            }),
            mergeMap((entityId) =>
                this.observableEntitiesApiService.getAggregate(entityId, { section: AGGREGATE_SECTIONS }).pipe(
                    tapResponse(
                        (response) => {
                            this.toSuccessLoading({ entityId, data: response });
                        },
                        (response: HttpErrorResponse) => {
                            const error = isServerError(response.error)
                                ? response.error
                                : { code: DefaultErrorCodes.UNKNOWN };

                            this.toFailureLoading({ entityId, error });
                        }
                    ),
                    takeUntil(merge(this.destroy$, this.getCanceler(entityId)))
                )
            )
        )
    );

    readonly update = this.effect((entityId$: Observable<TEntityId>) =>
        entityId$.pipe(
            tap((entityId) => {
                this.cancelLoading(entityId);
            }),
            mergeMap((entityId) =>
                this.observableEntitiesApiService.getAggregate(entityId, { section: AGGREGATE_SECTIONS }).pipe(
                    tap((response) => this.toSuccessLoading({ entityId, data: response })),
                    catchError((response: unknown) => of(response)),
                    takeUntil(merge(this.destroy$, this.getCanceler(entityId)))
                )
            )
        )
    );

    readonly reset = this.updater(() => DEFAULT_STATE);

    private readonly toLoading = this.updater((state, entityId: TEntityId) => ({
        ...state,
        [entityId]: toLoadingResponse(null)
    }));

    private readonly toSuccessLoading = this.updater((state, payload: { entityId: TEntityId; data: TAggregate }) => ({
        ...state,
        [payload.entityId]: toSuccessResponse(payload.data)
    }));

    private readonly toFailureLoading = this.updater(
        (state, payload: { entityId: TEntityId; error: IServerError }) => ({
            ...state,
            [payload.entityId]: toFailureResponse(payload.error)
        })
    );

    private readonly cancelers = new Map<TEntityId, Subject<void>>();

    constructor(
        private observableEntitiesApiService: ObservableEntitiesApiService,
        private destroyed$: DestroyService
    ) {
        super(DEFAULT_STATE);
    }

    getEntityState$(entityId: TEntityId): Observable<TEntitiesViewState[TEntityId]> {
        return this.select((state) => this.selectEntityState(state, entityId));
    }

    getLoadingState$(entityId: TEntityId): Observable<AsyncState> {
        return this.getEntityState$(entityId).pipe(map((state) => this.selectLoadingState(state)));
    }

    isLoading$(entityId: TEntityId): Observable<boolean> {
        return this.getLoadingState$(entityId).pipe(map(isAsyncStateLoading));
    }

    isSuccessLoading$(entityId: TEntityId): Observable<boolean> {
        return this.getLoadingState$(entityId).pipe(map(isAsyncStateSuccess));
    }

    isFailureLoading$(entityId: TEntityId): Observable<boolean> {
        return this.getLoadingState$(entityId).pipe(map(isAsyncStateFailure));
    }

    getLoadingError$(entityId: TEntityId): Observable<IServerError> {
        return this.getEntityState$(entityId).pipe(map((state) => this.selectLoadingError(state)));
    }

    getAggregate$(entityId: TEntityId): Observable<TAggregate> {
        return this.getEntityState$(entityId).pipe(map((state) => this.selectAggregate(state)));
    }

    getModel$(entityId: TEntityId): Observable<EntityFullInfoModel> {
        return this.getAggregate$(entityId).pipe(
            map((aggregate) => (aggregate ? EntityFullInfoModel.createFromRawData(aggregate) : null))
        );
    }

    getType$(entityId: TEntityId): Observable<ObservableEntityType> {
        return this.getAggregate$(entityId).pipe(map((aggregate) => (aggregate ? aggregate.type : null)));
    }

    getPreview$(entityId: TEntityId): Observable<TEntityPreviewData> {
        return this.getModel$(entityId).pipe(map((model) => (model ? EntityPreviewMapper.toPreview(model) : null)));
    }

    getMetadata$(entityId: TEntityId): Observable<TEntityMetadata> {
        return this.getModel$(entityId).pipe(map((model) => (model ? EntityMetadataMapper.fromModel(model) : null)));
    }

    cancelLoading(entityId: TEntityId): void {
        this.getCanceler(entityId).next();
    }

    private selectEntityState(state: TEntitiesViewState, entityId: TEntityId): TEntitiesViewState[TEntityId] {
        return state[entityId] || toLoadingResponse(null);
    }

    private selectLoadingState(entityState: TEntitiesViewState[TEntityId]): AsyncState {
        return entityState.state;
    }

    private selectAggregate(entityState: TEntitiesViewState[TEntityId]): TAggregate {
        if (isSuccessResponse(entityState)) {
            return entityState.data;
        }

        return null;
    }

    private selectLoadingError(entityState: TEntitiesViewState[TEntityId]): IServerError {
        if (isFailureResponse(entityState)) {
            return entityState.data;
        }

        return null;
    }

    private getCanceler(entityId: TEntityId): Subject<void> {
        if (!this.cancelers.has(entityId)) {
            this.cancelers.set(entityId, new Subject());
        }

        return this.cancelers.get(entityId);
    }
}
