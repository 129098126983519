import { Directive, EventEmitter, Output, ElementRef } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { BodyScrollEvent, Events } from 'ag-grid-community';
import { takeUntil, filter, switchMap } from 'rxjs/operators';

import { gridEvent$, isGridEndScrollPosition } from '../../helpers';
import { DestroyService } from '../../services';

@Directive({
    selector: '[agGridScrollToBodyEnd]',
    providers: [DestroyService]
})
export class AgGridScrollToBodyEndDirective {
    @Output('agGridScrollToBodyEnd') scrollToEnd = new EventEmitter<BodyScrollEvent>();

    constructor(private hostAsElement: ElementRef, private host: AgGridAngular, private destroy$: DestroyService) {
        this.host.gridReady
            .pipe(
                switchMap(() => gridEvent$<BodyScrollEvent>(this.host.api, Events.EVENT_BODY_SCROLL)),
                filter((event) => event.direction === 'vertical'),
                takeUntil(this.destroy$)
            )
            .subscribe((event) => {
                if (isGridEndScrollPosition(this.hostAsElement.nativeElement as Element)) {
                    this.scrollToEnd.emit(event);
                }
            });
    }
}
