import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

import { HashSumType } from '../types';

@Pipe({
    name: 'translateHashSumType'
})
export class TranslateHashSumTypePipe implements PipeTransform {
    transform(value: HashSumType): string {
        return translate(`common.Common.HashSum.Text.${value}`);
    }
}
