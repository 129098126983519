export interface ILicense {
    no: number;
    expiresAt: string;
    updatedAt: string | null;
    status: LicenseStatus[];
}

export enum LicenseStatus {
    Valid = 'Valid',
    Expired = 'Expired',
    Inactive = 'Inactive'
}
