import { IPaginationParams, ObservableEntityType, ShareLevel, TConfidence } from '../common';
import { AttributeObservableEntity, RelationType, TAttributeValueType, TObservableEntity } from '../observable';

import { ObservationType } from './observation-type';

export enum ObservationFilterType {
    Actual = 'Actual'
}

export interface IObservationFilterParams extends IPaginationParams {
    entityUUID?: string;
    reporterUUID?: string | string[];
    dataSourceUUID?: string | string[];
    artifactUUID?: string;
    filter?: ObservationFilterType;
}

export interface IObservationsSearchParams extends IPaginationParams {
    type?: ObservationType[];
    dataSourceUUID?: string[];
    reporterUUID?: string[];
    shareLevel?: ShareLevel;
    seenBefore?: string;
    seenAfter?: string;
    registeredBefore?: string;
    registeredAfter?: string;
    reportUUID?: string;
    entityUUID?: string;
}

export interface ICreateGenericObservationParams {
    shareLevel: ShareLevel;
    seenAt: string;
    content: ICreateObservationGenericContent;
}

export interface ICreateObservationGenericContent {
    entityRelationships: ICreateGenericEntityRelation[];
    entityAttributeValues: ICreateGenericEntityAttribute[];
}

export interface ICreateGenericEntityRelation {
    source: TCreateGenericObservationEntity;
    target: TCreateGenericObservationEntity;
    kind: RelationType;
    confidence: TConfidence;
}

export interface ICreateGenericEntityAttribute {
    attributeName: AttributeObservableEntity;
    value: TAttributeValueType;
    entity: TCreateGenericObservationEntity;
    confidence: TConfidence;
}

export type TCreateGenericObservationEntity<EntityType = ObservableEntityType> =
    | Pick<TObservableEntity<EntityType>, 'type' | 'keys'>
    | Pick<TObservableEntity<EntityType>, 'uuid'>;
