import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

export interface INotificationUnsavedChangesResult {
    skip: boolean;
}

@Injectable({ providedIn: 'root' })
export class NotificationUnsavedChangesService {
    private activeNotification$: Observable<INotificationUnsavedChangesResult>;

    notify(
        hasUnsavedChanges$: Observable<boolean>,
        confirmFactory: () => Observable<INotificationUnsavedChangesResult>
    ): Observable<INotificationUnsavedChangesResult> {
        return hasUnsavedChanges$.pipe(
            switchMap((hasUnsavedChanges) => {
                if (this.activeNotification$) {
                    return this.activeNotification$;
                }

                if (!hasUnsavedChanges) {
                    return of({ skip: true });
                }

                this.activeNotification$ = confirmFactory().pipe(tap(() => (this.activeNotification$ = null)));

                return this.activeNotification$;
            })
        );
    }
}
