import { Unpacked } from '@pt-cybsi/core';

import { ObservableEntityType } from '../common';

import { AggregateSection, IAggregateSection, TAggregateSectionDataByEntityType } from './aggregate-section';
import { AttributeObservableEntity, TAttributeValueType } from './attributes';
import { IFactOfAttribute } from './fact-of-attribute';

export type TAssociatedAttributesSection<OET = ObservableEntityType> = IAggregateSection<
    AggregateSection.AssociatedAttributes,
    TFactOfAssociatedAttribute<OET>[]
>;

// prettier-ignore
export type TFactOfAssociatedAttribute<OET = ObservableEntityType> =
    OET extends ObservableEntityType.DomainName ? TFactOfAssociatedAttributeDomainName :
    OET extends ObservableEntityType.IPAddress ? TFactOfAssociatedAttributeIpAddress :
    OET extends ObservableEntityType.EmailAddress ? TFactOfAssociatedAttributeEmailAddress :
    OET extends ObservableEntityType.URL ? TFactOfAssociatedAttributeUrl :
    OET extends ObservableEntityType.PhoneNumber ? TFactOfAssociatedAttributePhoneNumber :
    OET extends ObservableEntityType.File ? TFactOfAssociatedAttributeFile :
        | TFactOfAssociatedAttributeDomainName
        | TFactOfAssociatedAttributeIpAddress
        | TFactOfAssociatedAttributeEmailAddress
        | TFactOfAssociatedAttributeUrl
        | TFactOfAssociatedAttributePhoneNumber
        | TFactOfAssociatedAttributeFile;

export type TAssociatedAttribute = TFactOfAssociatedAttribute['attributeName'];

export type TFactOfAssociatedAttributeByEntityType<
    OET extends ObservableEntityType,
    AttributeName extends TAssociatedAttribute
> = Extract<
    Unpacked<TAggregateSectionDataByEntityType<OET, AggregateSection.AssociatedAttributes>>,
    { attributeName: AttributeName }
>;

export type TAssociatedAttributeValue<
    OET extends ObservableEntityType,
    AttributeName extends TAssociatedAttribute
> = TFactOfAssociatedAttributeByEntityType<OET, AttributeName>['value'];

export type TAssociatedAttributeValues<
    OET extends ObservableEntityType,
    AttributeName extends TAssociatedAttribute
> = TFactOfAssociatedAttributeByEntityType<OET, AttributeName>['values'];

export type TFactOfAssociatedAttributeDomainName =
    | TFactOfExploitedVulnerabilities
    | TFactOfTargetedSectors
    | TFactOfRelatedThreatCategory
    | TFactOfRelatedMalwareFamilies
    | TFactOfNodeRole
    | TFactOfIsTrusted
    | TFactOfIsDGA
    | TFactOfIsIoC
    | TFactOfThreatActors
    | TFactOfCampaigns
    | TFactOfAffectedCountries;

export type TFactOfAssociatedAttributeIpAddress =
    | TFactOfExploitedVulnerabilities
    | TFactOfTargetedSectors
    | TFactOfRelatedThreatCategory
    | TFactOfRelatedMalwareFamilies
    | TFactOfNodeRole
    | TFactOfIsTrusted
    | TFactOfIsIoC
    | TFactOfThreatActors
    | TFactOfCampaigns
    | TFactOfAffectedCountries;

export type TFactOfAssociatedAttributeEmailAddress =
    | TFactOfExploitedVulnerabilities
    | TFactOfTargetedSectors
    | TFactOfRelatedThreatCategory
    | TFactOfRelatedMalwareFamilies
    | TFactOfIsTrusted
    | TFactOfIsIoC
    | TFactOfThreatActors
    | TFactOfCampaigns
    | TFactOfAffectedCountries;

export type TFactOfAssociatedAttributeUrl =
    | TFactOfExploitedVulnerabilities
    | TFactOfTargetedSectors
    | TFactOfRelatedThreatCategory
    | TFactOfRelatedMalwareFamilies
    | TFactOfIsTrusted
    | TFactOfIsIoC
    | TFactOfThreatActors
    | TFactOfCampaigns
    | TFactOfAffectedCountries;

export type TFactOfAssociatedAttributeFile =
    | TFactOfExploitedVulnerabilities
    | TFactOfTargetedSectors
    | TFactOfThreatCategory
    | TFactOfMalwareClasses
    | TFactOfMalwareFamilies
    | TFactOfIsTrusted
    | TFactOfIsIoC
    | TFactOfThreatActors
    | TFactOfCampaigns
    | TFactOfAffectedCountries;

export type TFactOfAssociatedAttributePhoneNumber = TFactOfIsIoC;

export type TFactOfNodeRole = IFactOfAttribute<
    AttributeObservableEntity.NodeRoles,
    TAttributeValueType<AttributeObservableEntity.NodeRoles>
>;

export type TFactOfIsTrusted = IFactOfAttribute<
    AttributeObservableEntity.IsTrusted,
    TAttributeValueType<AttributeObservableEntity.IsTrusted>
>;

export type TFactOfIsDGA = IFactOfAttribute<
    AttributeObservableEntity.IsDGA,
    TAttributeValueType<AttributeObservableEntity.IsDGA>
>;

export type TFactOfIsIoC = IFactOfAttribute<
    AttributeObservableEntity.IsIoC,
    TAttributeValueType<AttributeObservableEntity.IsIoC>
>;

export type TFactOfMalwareClasses = IFactOfAttribute<
    AttributeObservableEntity.MalwareClasses,
    TAttributeValueType<AttributeObservableEntity.MalwareClasses>
>;

export type TFactOfMalwareFamilies = IFactOfAttribute<
    AttributeObservableEntity.MalwareFamilies,
    TAttributeValueType<AttributeObservableEntity.MalwareFamilies>
>;

export type TFactOfRelatedMalwareFamilies = IFactOfAttribute<
    AttributeObservableEntity.RelatedMalwareFamilies,
    TAttributeValueType<AttributeObservableEntity.RelatedMalwareFamilies>
>;

export type TFactOfThreatCategory = IFactOfAttribute<
    AttributeObservableEntity.ThreatCategory,
    TAttributeValueType<AttributeObservableEntity.ThreatCategory>
>;

export type TFactOfRelatedThreatCategory = IFactOfAttribute<
    AttributeObservableEntity.RelatedThreatCategory,
    TAttributeValueType<AttributeObservableEntity.RelatedThreatCategory>
>;

export type TFactOfThreatActors = IFactOfAttribute<
    AttributeObservableEntity.ThreatActors,
    TAttributeValueType<AttributeObservableEntity.ThreatActors>
>;

export type TFactOfCampaigns = IFactOfAttribute<
    AttributeObservableEntity.Campaigns,
    TAttributeValueType<AttributeObservableEntity.Campaigns>
>;

export type TFactOfAffectedCountries = IFactOfAttribute<
    AttributeObservableEntity.AffectedCountries,
    TAttributeValueType<AttributeObservableEntity.AffectedCountries>
>;

export type TFactOfExploitedVulnerabilities = IFactOfAttribute<
    AttributeObservableEntity.ExploitedVulnerabilities,
    TAttributeValueType<AttributeObservableEntity.ExploitedVulnerabilities>
>;

export type TFactOfTargetedSectors = IFactOfAttribute<
    AttributeObservableEntity.TargetedSectors,
    TAttributeValueType<AttributeObservableEntity.TargetedSectors>
>;

export const ASSOCIATED_ATTRIBUTES: TAssociatedAttribute[] = [
    AttributeObservableEntity.IsDGA,
    AttributeObservableEntity.IsIoC,
    AttributeObservableEntity.IsTrusted,
    AttributeObservableEntity.NodeRoles,
    AttributeObservableEntity.MalwareClasses,
    AttributeObservableEntity.MalwareFamilies,
    AttributeObservableEntity.RelatedMalwareFamilies,
    AttributeObservableEntity.ThreatCategory,
    AttributeObservableEntity.RelatedThreatCategory,
    AttributeObservableEntity.ThreatActors,
    AttributeObservableEntity.Campaigns,
    AttributeObservableEntity.AffectedCountries,
    AttributeObservableEntity.ExploitedVulnerabilities,
    AttributeObservableEntity.TargetedSectors
];
