import includes from 'lodash/includes';
import values from 'lodash/values';

import { BadRequestErrorCodes, InternalServerErrorCodes, IServerError } from '@pt-cybsi/api-interfaces';

export const isServerError = (error: unknown): error is IServerError =>
    (error as IServerError)?.code !== undefined && (error as IServerError)?.code !== null;

export const isInternalServerError = (status: number): boolean => includes(values(InternalServerErrorCodes), status);

export const isBadRequestServerError = (status: number): boolean => includes(values(BadRequestErrorCodes), status);

export const getLocaleServerErrorByStatusCode = (status: number): string => `${status}_ERROR`;
