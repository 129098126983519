export const SOURCE_MIN_LENGTH = 1;
export const SOURCE_MAX_LENGTH = 250;

export const validateSourceNameLength = (name: string) =>
    name === undefined ||
    name === null ||
    name === '' ||
    (name.length >= SOURCE_MIN_LENGTH && name.length <= SOURCE_MAX_LENGTH);

export const SOURCE_SHORT_NAME_MIN_LENGTH = 1;
export const SOURCE_SHORT_NAME_MAX_LENGTH = 250;

export const validateSourceShortNameLength = (name: string) =>
    name === undefined ||
    name === null ||
    name === '' ||
    (name.length >= SOURCE_SHORT_NAME_MIN_LENGTH && name.length <= SOURCE_SHORT_NAME_MAX_LENGTH);

export const validateSourceShortNameFormat = (name: string) =>
    name === undefined || name === null || /^\w+$/.test(name);
