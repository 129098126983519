import { IEditableResource, IPaginationResponse } from '../common';

import { IDataSource, IDataSourceType } from './data-source';

export type TDataSourceTypesResponse = IPaginationResponse<IDataSourceType[]>;
export type TEditableDataSourceTypeResponse = IEditableResource<IDataSourceType>;

export enum DataSourceTypeErrorCode {
    DuplicateDataSourceType = 'DuplicateDataSourceType'
}

export type TDataSourcesResponse = IPaginationResponse<IDataSource[]>;
export type TEditableDataSourceResponse = IEditableResource<IDataSource>;

export enum DataSourceErrorCode {
    DuplicateDataSource = 'DuplicateDataSource',
    DataSourceTypeNotFound = 'DataSourceTypeNotFound'
}
