<source-types-grid
    [state]="state"
    [sourceTypes]="sourceTypes"
    [errorTitle]="'sources.Sources.Pseudo.Text.ErrorLoadingSources' | transloco"
    [hasDetails]="true"
    [typeDetailsTemplate]="detailsTemplate"
    [expandedTypes]="expandedTypes"
    [expanded]="expanded"
    (loadMore)="loadMore.emit()"
    (toggleDetails)="toggleDetails.emit($event)"
></source-types-grid>
