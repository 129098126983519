import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { MC_SIDEPANEL_DATA } from '@ptsecurity/mosaic/sidepanel';
import { FlatTreeControl, McTreeFlatDataSource, McTreeFlattener } from '@ptsecurity/mosaic/tree';

import {
    buildPermissionsTree,
    getChildren,
    getLevel,
    getValue,
    getViewValue,
    IPermissionFlatTreeNode,
    IPermissionTreeNode,
    isExpandable,
    transformer
} from '@pt-cybsi/domain-core/users';

@Component({
    selector: 'permissions-sidepanel',
    templateUrl: './permissions-sidepanel.component.html',
    styleUrls: ['./permissions-sidepanel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionsSidepanelComponent implements OnInit {
    @Input() permissions: string[] = [];

    treeControl: FlatTreeControl<IPermissionFlatTreeNode>;
    treeFlattener: McTreeFlattener<IPermissionTreeNode, IPermissionFlatTreeNode>;
    dataSource: McTreeFlatDataSource<IPermissionTreeNode, IPermissionFlatTreeNode>;

    constructor(@Inject(MC_SIDEPANEL_DATA) public data: { permissions: string[] }) {}

    ngOnInit() {
        this.initPermissionsTree();
    }

    isTreeNodeHasChild(_index: number, nodeData: IPermissionFlatTreeNode) {
        return nodeData.expandable;
    }

    private initPermissionsTree() {
        this.treeFlattener = new McTreeFlattener(transformer, getLevel, isExpandable, getChildren);
        this.treeControl = new FlatTreeControl<IPermissionFlatTreeNode>(getLevel, isExpandable, getValue, getViewValue);
        this.dataSource = new McTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.dataSource.data = buildPermissionsTree(this.data.permissions);
    }
}
