import { IDictionaryItemBrief } from '@pt-cybsi/api-interfaces';

export interface IDictionaryItemView {
    uuid: string;
    dictionaryId: string;
    dictionaryName: string;
    isClosedDictionary: boolean;
    name: string;
    description: string;
    synonyms: IDictionaryItemBrief[];
}
