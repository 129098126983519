import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, ModuleWithProviders } from '@angular/core';
import {
    TranslocoModule,
    TRANSLOCO_LOADER,
    TRANSLOCO_CONFIG,
    TranslocoConfig,
    translocoConfig
} from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';

import { TranslocoProviderComponent } from './components';
import { defaultTranslocoConfig, AVAILABLE_LOCALES } from './i18n-config';
import { TRANSLATION_CACHE_KEY, TranslocoHttpLoader } from './transloco-http-loader';

@NgModule({
    imports: [
        HttpClientModule,
        TranslocoModule,
        TranslocoMessageFormatModule.forRoot({ locales: AVAILABLE_LOCALES }),
        CommonModule
    ],
    declarations: [TranslocoProviderComponent],
    exports: [TranslocoModule, TranslocoProviderComponent],
    providers: [{ provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }]
})
export class I18nModule {
    static forRoot(config: Partial<TranslocoConfig> = {}, cacheKey: string): ModuleWithProviders<I18nModule> {
        return {
            ngModule: I18nModule,
            providers: [
                {
                    provide: TRANSLOCO_CONFIG,
                    useValue: translocoConfig({
                        ...defaultTranslocoConfig,
                        ...config
                    })
                },
                {
                    provide: TRANSLATION_CACHE_KEY,
                    useValue: cacheKey
                }
            ]
        };
    }
}
