import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { IFormViewModelBuilder, TFormViewModelProps } from '@pt-cybsi/shared';

import { ApiKeyFormConfig, ApiKeyFormViewModel, TApiKeyFormConfig, TApiKeyFormData } from './api-key-form.view-model';

@Injectable()
export class ApiKeyFormBuilder implements IFormViewModelBuilder<TApiKeyFormData, TApiKeyFormConfig> {
    formConfig = ApiKeyFormConfig;

    constructor(private fb: UntypedFormBuilder) {}

    build(props: TFormViewModelProps<TApiKeyFormData>): ApiKeyFormViewModel {
        const form = this.buildFormGroup();

        return new ApiKeyFormViewModel(props, form, this.formConfig);
    }

    private buildFormGroup(): UntypedFormGroup {
        const { id, description, expiresAt, isPerpetual, permissions, isInheritPermissions } = this.formConfig;

        return this.fb.group({
            [id.controlName]: undefined,

            [description.controlName]: undefined,
            [expiresAt.controlName]: [
                undefined,
                {
                    validators: [Validators.required, Validators.min]
                }
            ],
            [isPerpetual.controlName]: undefined,
            [permissions.controlName]: [
                undefined,
                {
                    validators: [Validators.required]
                }
            ],
            [isInheritPermissions.controlName]: undefined
        });
    }
}
