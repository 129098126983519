import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { TranslocoLoaderData } from '@ngneat/transloco/lib/transloco.loader';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const TRANSLATION_CACHE_KEY = new InjectionToken<string>('TRANSLATION_CACHE_KEY');

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private httpClient: HttpClient, @Inject(TRANSLATION_CACHE_KEY) private cacheKey: string) {}

    getTranslation(path: string, data?: TranslocoLoaderData): Observable<Translation> {
        const locale = path.split('/').reverse()[0];
        const translationPath = `/assets/i18n/${locale}/${data?.scope ? data.scope : 'server'}.json`;

        return this.httpClient
            .get<Translation>(translationPath, { params: { b: this.cacheKey } })
            .pipe(catchError(() => of({})));
    }
}
