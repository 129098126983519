import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FormMode, FormStateValue } from '@pt-cybsi/shared';

import { LicenseFormConfig, LicenseFormViewModel, TLicenseFormData } from './license-form.view-model';

@Injectable()
export class LicenseFormBuilder {
    formConfig = LicenseFormConfig;

    constructor(private fb: UntypedFormBuilder) {}

    build(data: TLicenseFormData): LicenseFormViewModel {
        const form = this.buildFormGroup();

        return new LicenseFormViewModel(
            {
                mode: FormMode.Creation,
                state: FormStateValue.Initializing,
                initialData: data,
                currentData: data,
                savingError: null
            },
            form,
            this.formConfig
        );
    }

    private buildFormGroup(): UntypedFormGroup {
        const { id, file } = this.formConfig;

        return this.fb.group({
            [id.controlName]: undefined,
            [file.controlName]: [
                undefined,
                {
                    validators: [Validators.required]
                }
            ]
        });
    }
}
