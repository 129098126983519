import { PermissionType, ApiResource } from '@pt-cybsi/api-interfaces';

import { buildPermissionString, RootPermissionsService } from '../services/root-permissions.service';

const Read = PermissionType.Read;
const Write = PermissionType.Write;

export type TPermissionRoute =
    | 'search'
    | 'reports'
    | 'feeds'
    | 'statistics'
    | 'artifacts'
    | 'reputation-lists'
    | 'sources'
    | 'enrichment-rules'
    | 'users'
    | 'registration-generic-observation'
    | 'dictionaries';

export const NAVBAR_ROUTE_URLS_MAP: Record<TPermissionRoute, string> = {
    search: '/objects',
    reports: '/reports',
    artifacts: '/artifacts',
    'reputation-lists': '/replists',
    sources: '/sources',
    'enrichment-rules': '/enrichment-rules',
    users: '/users',
    feeds: '/feeds',
    dictionaries: '/dictionaries',
    statistics: '/statistics',
    'registration-generic-observation': '/observations/registration/generic/'
};

export const MAIN_ROUTE_PERMISSIONS: Record<TPermissionRoute, string[]> = {
    // Пути из нового приложения
    'reputation-lists': RootPermissionsService.viewReplistsPermissions,
    sources: RootPermissionsService.viewSourcesPermissions,
    'enrichment-rules': RootPermissionsService.viewEnrichmentRulesPermissions,
    users: RootPermissionsService.viewUsersPermissions,
    dictionaries: RootPermissionsService.viewDictionariesPermissions,
    statistics: RootPermissionsService.viewStatisticsPermissions,

    // Пути из старого приложения
    search: [
        buildPermissionString(ApiResource.Observable, [Read]),
        buildPermissionString(ApiResource.Search, [Read]),
        buildPermissionString(ApiResource.SearchFilters, [Read, Write]),
        buildPermissionString(ApiResource.DataSources, [Read])
    ],
    reports: [
        buildPermissionString(ApiResource.Reports, [Read]),
        buildPermissionString(ApiResource.Observable, [Read]),
        buildPermissionString(ApiResource.DataSources, [Read])
    ],
    artifacts: [
        buildPermissionString(ApiResource.Artifacts, [Read]),
        buildPermissionString(ApiResource.DataSources, [Read])
    ],
    feeds: [
        buildPermissionString(ApiResource.Feeds, [Read]),
        buildPermissionString(ApiResource.SearchFilters, [Read]),
        buildPermissionString(ApiResource.DataSources, [Read])
    ],
    // prettier-ignore
    'registration-generic-observation': [
        buildPermissionString(ApiResource.Observations, [Write])
    ]
};
