import { IPaginationParams } from '../common';

export interface IQueryRequestParams {
    queryUUID: string;
}

export interface IQueryListRequestParams extends IPaginationParams {
    userUUID?: string;
}

export interface IQuerySaveBody {
    name: string;
    text: string;
}

export enum QueryCompatibility {
    Search = 'Search',
    Replist = 'Replist'
}

export interface IQueryValidateBody {
    text: string;
    compatibility: QueryCompatibility;
}
