export enum SearchPageLayoutState {
    Idle = 'Idle',

    Loading = 'Loading',
    LoadingSuccess = 'LoadingSuccess',
    LoadingEmpty = 'LoadingEmpty',
    LoadingFailure = 'LoadingFailure',

    Filtering = 'Filtering',
    FilteringSuccess = 'FilteringSuccess',
    FilteringEmpty = 'FilteringEmpty',
    FilteringFailure = 'FilteringFailure'
}

interface ISearchPageLayoutTransitions {
    [SearchPageLayoutState.Loading]: {
        complete: SearchPageLayoutState.LoadingSuccess;
        failure: SearchPageLayoutState.LoadingFailure;
        empty: SearchPageLayoutState.LoadingEmpty;
    };
    [SearchPageLayoutState.Filtering]: {
        complete: SearchPageLayoutState.FilteringSuccess;
        failure: SearchPageLayoutState.FilteringFailure;
        empty: SearchPageLayoutState.FilteringEmpty;
    };
}

export class SearchPageLayoutModel {
    get currentState(): SearchPageLayoutState {
        return this.state;
    }

    get isLoadingStage(): boolean {
        return (
            [
                SearchPageLayoutState.Loading,
                SearchPageLayoutState.LoadingEmpty,
                SearchPageLayoutState.LoadingSuccess,
                SearchPageLayoutState.LoadingFailure
            ].indexOf(this.state) !== -1
        );
    }

    get isFilteringStage(): boolean {
        return (
            [
                SearchPageLayoutState.Filtering,
                SearchPageLayoutState.FilteringEmpty,
                SearchPageLayoutState.FilteringSuccess,
                SearchPageLayoutState.FilteringFailure
            ].indexOf(this.state) !== -1
        );
    }

    private readonly transitions: ISearchPageLayoutTransitions = {
        [SearchPageLayoutState.Loading]: {
            complete: SearchPageLayoutState.LoadingSuccess,
            failure: SearchPageLayoutState.LoadingFailure,
            empty: SearchPageLayoutState.LoadingEmpty
        },
        [SearchPageLayoutState.Filtering]: {
            complete: SearchPageLayoutState.FilteringSuccess,
            failure: SearchPageLayoutState.FilteringFailure,
            empty: SearchPageLayoutState.FilteringEmpty
        }
    };

    constructor(private state: SearchPageLayoutState) {}

    static create(state?: SearchPageLayoutState): SearchPageLayoutModel {
        return new SearchPageLayoutModel(state || SearchPageLayoutState.Idle);
    }

    setLoadingState(): void {
        this.state = SearchPageLayoutState.Loading;
    }

    setFilteringState(): void {
        this.state = SearchPageLayoutState.Filtering;
    }

    setFilteringSuccessState(): void {
        this.state = SearchPageLayoutState.FilteringSuccess;
    }

    setProgressState(): void {
        if (this.isFilteringCompleteState(this.state)) {
            this.setFilteringState();
        } else {
            this.setLoadingState();
        }
    }

    setCompleteState(): void {
        if (this.isProgressState(this.state)) {
            this.state = this.transitions[this.state].complete;
        }
    }

    setEmptyState(): void {
        if (this.isProgressState(this.state)) {
            this.state = this.transitions[this.state].empty;
        }
    }

    setErrorState(): void {
        if (this.isProgressState(this.state)) {
            this.state = this.transitions[this.state].failure;
        }
    }

    private isProgressState(
        state: SearchPageLayoutState
    ): state is SearchPageLayoutState.Loading | SearchPageLayoutState.Filtering {
        return [SearchPageLayoutState.Loading, SearchPageLayoutState.Filtering].indexOf(state) !== -1;
    }

    private isFilteringCompleteState(
        state: SearchPageLayoutState
    ): state is
        | SearchPageLayoutState.FilteringEmpty
        | SearchPageLayoutState.FilteringSuccess
        | SearchPageLayoutState.FilteringFailure {
        return (
            [
                SearchPageLayoutState.FilteringEmpty,
                SearchPageLayoutState.FilteringSuccess,
                SearchPageLayoutState.FilteringFailure
            ].indexOf(state) !== -1
        );
    }
}
