import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import {
    CanDisableCtor,
    CanUpdateErrorStateCtor,
    ErrorStateMatcher,
    mixinDisabled,
    mixinErrorState
} from '@ptsecurity/mosaic/core';
import { Subject } from 'rxjs';
import { McFieldBase } from './form-control.helpers';

export interface INewItem {
    isNew: true;
}

export interface IExistedItem {
    isNew: false;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export type TAutocompletedItem = INewItem | IExistedItem;

export const isNewAutocompletedItem = (type: TAutocompletedItem): type is INewItem => type.isNew;

export const isNewItem = (type: TAutocompletedItem): type is IExistedItem => type.isNew;

// tslint:disable-next-line:naming-convention
export const AutocompleteFieldMixinBase: CanDisableCtor & CanUpdateErrorStateCtor & typeof McFieldBase = mixinDisabled(
    mixinErrorState(McFieldBase)
);
