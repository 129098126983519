import { Injectable } from '@angular/core';
import { CanDeactivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { IRouterStateParams } from '@pt-cybsi/core';

import { ConfirmModalService, NotificationUnsavedChangesService } from '../services';

export interface IDirtyPageComponent {
    isDirty$: Observable<boolean>;
    textContentConfirmLeaveForm?: string;
}

@Injectable({ providedIn: 'root' })
export class LeaveDirtyPageGuard implements CanDeactivate<IDirtyPageComponent> {
    constructor(
        private router: Router,
        private confirmModalService: ConfirmModalService,
        private notificationUnsavedChangesService: NotificationUnsavedChangesService
    ) {}

    canDeactivate(component: IDirtyPageComponent): Observable<boolean> {
        if (!component.isDirty$) {
            throw new Error(
                'Component of page with "LeaveDirtyPage" guard should implement "IDirtyPageComponent" interface'
            );
        }

        const routerState: IRouterStateParams = this.router.getCurrentNavigation().extras.state;
        const isSkipDirtyChecking = routerState?.skipDirtyChecking || false;

        if (isSkipDirtyChecking) {
            return of(true);
        }

        const confirmFactory = () =>
            this.confirmModalService
                .confirmLeaveForm(component?.textContentConfirmLeaveForm)
                .pipe(map((skip) => ({ skip })));

        return this.notificationUnsavedChangesService
            .notify(component.isDirty$.pipe(take(1)), confirmFactory)
            .pipe(map(({ skip: canDeactivate }) => canDeactivate));
    }
}
