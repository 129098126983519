import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    Component,
    Directive,
    DoCheck,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { McAutocomplete, McAutocompleteSelectedEvent } from '@ptsecurity/mosaic/autocomplete';
import { CanDisable, CanUpdateErrorState, ErrorStateMatcher } from '@ptsecurity/mosaic/core';
import { McFormFieldControl, McValidateDirective } from '@ptsecurity/mosaic/form-field';
import { McInput } from '@ptsecurity/mosaic/input';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DataSourcesApiService } from '@pt-cybsi/api';
import { DataSourceTypesOrderType, IDataSourceType } from '@pt-cybsi/api-interfaces';
import { DestroyService, IExistedItem, INewItem, AutocompleteFieldMixinBase } from '@pt-cybsi/shared';

export interface INewSourceType extends INewItem {
    value: { name: string };
}

export interface IExistedSourceType extends IExistedItem {
    value: IDataSourceType;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export type TAutocompletedSourceType = INewSourceType | IExistedSourceType;

const isNewSourceType = (type: TAutocompletedSourceType): type is INewSourceType => type.isNew;

let nextUniqueId = 0;

/**
 * @component SourceTypeAutocompleteField
 *
 * @description
 * Form field for entering source type name and select type from autocompleted data
 *
 * @param id - unique id of field. Optional, will be generated automatically
 * @param ngModel - name of source type
 * @param placeholder - placeholder text. Optional, will be used empty text
 * @param disabled - boolean value for marking the field as disabled. Optional, will be used `false`
 * @param required - boolean value for marking the field as required. Optional, will be used `false`
 * @param selectType - event, emitted after selection source type from autocompleted data
 * @param clear - event, emitted after removed name from field
 *
 * @example
 * ```html
 * <mc-form-field>
 *      <source-type-autocomplete-field
 *          [(ngModel)]="sourceTypeName"
 *          (selectType)="handleSelectSourceType($event)"
 *          (clear)="handleClear()">
 *      </source-type-autocomplete-field>
 * </mc-form-field>
 *
 * <mc-form-field>
 *      <source-type-autocomplete-field
 *          [formControl]="sourceTypeNameControl"
 *          (selectType)="handleSelectSourceType($event)"
 *          (clear)="handleClear()">
 *      </source-type-autocomplete-field>
 * </mc-form-field>
 *
 * <mc-form-field>
 *      <source-type-autocomplete-field
 *          [id]="'some-id'"
 *          [placeholder]="'Enter a name of source type'"
 *          [(ngModel)]="sourceTypeName"
 *          [required]="true"
 *          [disabled]="true"
 *          (selectType)="handleSelectSourceType($event)"
 *          (clear)="handleClear()">
 *      </source-type-autocomplete-field>
 * </mc-form-field>
 * ```
 */
@Component({
    selector: 'source-type-autocomplete-field',
    templateUrl: './source-type-autocomplete-field.component.html',
    styleUrls: ['./source-type-autocomplete-field.component.scss'],
    providers: [
        DestroyService,
        {
            provide: McFormFieldControl,
            useExisting: SourceTypeAutocompleteFieldComponent
        }
    ],
    // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
    encapsulation: ViewEncapsulation.None,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[attr.id]': 'id',
        '[attr.disabled]': 'disabled || null',
        '[attr.required]': 'required || null'
    }
})
export class SourceTypeAutocompleteFieldComponent
    extends AutocompleteFieldMixinBase
    implements
        McFormFieldControl<string>,
        ControlValueAccessor,
        OnInit,
        OnChanges,
        DoCheck,
        OnDestroy,
        CanDisable,
        CanUpdateErrorState
{
    @Input()
    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value || this.uid;
        this.stateChanges.next();
    }

    @Input()
    get value(): string {
        return this._value;
    }

    set value(value: string) {
        if (value !== this.value) {
            if (this.inputValue === undefined) {
                this.inputValue = value;
            }

            this._value = value;
            this.stateChanges.next();
        }
    }

    @Input()
    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);

        if (this.focused) {
            this.focused = false;
            this.stateChanges.next();
        }
    }

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: boolean) {
        this._required = coerceBooleanProperty(value);

        this.stateChanges.next();
    }

    @Input()
    get focused(): boolean {
        return this.mcInput ? this.mcInput.focused : this._focused;
    }

    set focused(value: boolean) {
        this._focused = coerceBooleanProperty(value);

        if (this.mcInput) {
            this.mcInput.focused = this._focused;
        }

        this.stateChanges.next();
    }

    @Input() placeholder: string;

    @Output() selectType: EventEmitter<TAutocompletedSourceType> = new EventEmitter<TAutocompletedSourceType>();
    @Output() clear: EventEmitter<void> = new EventEmitter<void>();

    get empty(): boolean {
        return this._value === null || this._value === undefined;
    }

    errorState: boolean;
    controlType = 'source-type-autocomplete-field';

    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isLoaded$: Observable<boolean> = this.isLoading$.pipe(map((isLoading) => !isLoading));

    inputValue: string;
    autocompleteOptions: TAutocompletedSourceType[] = [];

    onChange: (value: string) => void;
    onTouched: () => void;

    @ViewChild('mcInput', { read: McInput }) mcInput: McInput;

    @ViewChild('autocompleteTypes', { read: McAutocomplete }) autocompleteTypes: McAutocomplete;

    private _id: string;
    private _value: string;
    private _disabled = false;
    private _focused = false;
    private _required = false;

    private loadAutocompleteOptions$: Subject<{ name: string }> = new Subject();
    private closeAutocomplete$: Subject<void> = new Subject();

    private readonly uid = `${this.controlType}-${nextUniqueId++}`;

    private createLabel: string = this.translocoService.translate('common.Common.Autocomplete.Text.Create');

    constructor(
        public defaultErrorStateMatcher: ErrorStateMatcher,
        @Optional() public parentForm: NgForm,
        @Optional() public parentFormGroup: FormGroupDirective,
        @Self() public ngControl: NgControl,
        private dataSourcesApiService: DataSourcesApiService,
        private translocoService: TranslocoService,
        private destroyed$: DestroyService
    ) {
        super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

        this.ngControl.valueAccessor = this;

        // eslint-disable-next-line no-self-assign
        this.id = this.id;

        this.autocompleteDisplayFn = this.autocompleteDisplayFn.bind(this);
    }

    ngOnInit(): void {
        this.loadAutocompleteOptions$
            .pipe(
                tap(() => this.toLoadingState()),

                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                debounceTime(250),

                switchMap(({ name }) =>
                    name.length > 0
                        ? this.getAutocompleteOptions(name).pipe(takeUntil(this.closeAutocomplete$))
                        : of([] as TAutocompletedSourceType[])
                ),

                tap((options) => this.toLoadedState(options)),

                // Need delay for selectFirstItem
                switchMap((options) => (options.length > 0 ? of(options).pipe(delay(1)) : of(options))),

                filter(() => this.autocompleteTypes.isOpen),

                takeUntil(this.destroyed$)
            )
            .subscribe((options) => {
                if (options.length > 0) {
                    this.selectFirstItem();
                } else {
                    this.clear.emit();
                }
            });
    }

    ngOnChanges(): void {
        this.stateChanges.next();
    }

    ngDoCheck(): void {
        if (this.ngControl) {
            this.updateErrorState();
        }
    }

    ngOnDestroy(): void {
        this.stateChanges.complete();
    }

    handleInputChange(value: string | TAutocompletedSourceType): void {
        if (typeof value === 'string') {
            this.value = value;
            this.loadAutocompleteOptions$.next({ name: value.trim() });
        } else {
            this.value = this.getTypeName(value);
            this.mcInput.value = this.value;
        }

        this.onChange(this.value);
    }

    handleInputFocus(): void {
        if (!this.disabled) {
            this.focused = true;
        }
    }

    handleInputBlur(): void {
        this.focused = false;

        this.validateControl();
    }

    handleSelectOption($event: McAutocompleteSelectedEvent): void {
        const option = $event.option.value as TAutocompletedSourceType;

        this.selectType.emit(option);

        this.validateControl();
    }

    handleOpenAutocomplete(): void {
        const isLoading = this.isLoading$.getValue();
        const hasSelectedOption = this.autocompleteTypes.options.find((option) => option.selected);

        if (!isLoading && !hasSelectedOption) {
            this.selectFirstItem(false);
        }
    }

    handleCloseAutocomplete(): void {
        const isLoading = this.isLoading$.getValue();

        const options = isLoading
            ? this.value
                ? [this.createNewTypeAutocompleteOption(this.value)]
                : []
            : this.autocompleteOptions;

        this.toLoadedState(options);

        const selectedOption = this.autocompleteTypes.options.find((option) => option.selected);

        if (!selectedOption && this.autocompleteOptions.length > 0) {
            this.selectType.emit(this.autocompleteOptions[0]);
        }

        this.closeAutocomplete$.next();
    }

    onContainerClick(): void {
        this.focus();
    }

    focus(): void {
        if (!this.disabled) {
            this.focused = true;

            this.mcInput.focus();
        }
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    autocompleteDisplayFn(option: TAutocompletedSourceType | string): string {
        if (typeof option === 'string') {
            return option;
        }

        return (option && this.getTypeName(option)) || '';
    }

    getAutocompleteOptionText(option: TAutocompletedSourceType): string {
        return isNewSourceType(option) ? `${this.createLabel}: ${this.getTypeName(option)}` : this.getTypeName(option);
    }

    getTypeName(option: TAutocompletedSourceType): string {
        return isNewSourceType(option) ? option.value.name : option.value.longName;
    }

    private selectFirstItem(emit: boolean = true): void {
        const firstItem = this.autocompleteTypes.options.first;

        firstItem.select();

        if (emit) {
            this.autocompleteTypes.emitSelectEvent(firstItem);
        }
    }

    private getAutocompleteOptions(name: string): Observable<TAutocompletedSourceType[]> {
        return this.dataSourcesApiService
            .getAllSourceTypes({
                longName: name,
                orderBy: DataSourceTypesOrderType.LongName
            })
            .pipe(
                map(({ fullList }) => {
                    const options = fullList.map<TAutocompletedSourceType>((dataSourceType) => ({
                        isNew: false,
                        value: dataSourceType
                    }));

                    const nameOfFirstOption = options.length > 0 ? this.getTypeName(options[0]) : null;

                    const isFullMatchByName = nameOfFirstOption && name === nameOfFirstOption;

                    return isFullMatchByName ? options : [this.createNewTypeAutocompleteOption(name), ...options];
                })
            );
    }

    private toLoadingState(): void {
        this.isLoading$.next(true);
        this.autocompleteOptions = [];
    }

    private toLoadedState(data: TAutocompletedSourceType[]): void {
        this.isLoading$.next(false);
        this.autocompleteOptions = data;
    }

    private createNewTypeAutocompleteOption(name: string): INewSourceType {
        return { isNew: true, value: { name } };
    }

    private validateControl(): void {
        if (this.ngControl && this.ngControl.control) {
            const control = this.ngControl.control;

            control.updateValueAndValidity({ emitEvent: false });
            (control.statusChanges as EventEmitter<string>).emit(control.status);
        }
    }
}

@Directive({
    selector: 'source-type-autocomplete-field',
    exportAs: 'SourceTypeAutocompleteFieldMcValidate'
})
export class SourceTypeAutocompleteFieldMcValidateDirective extends McValidateDirective {}
