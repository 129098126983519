<mc-sidepanel-header [closeable]="true">
    <div pt-title class="pt-text-overflow">{{ data.collectionTitle }}</div>
</mc-sidepanel-header>
<mc-sidepanel-body class="layout-padding-l">
    <ul class="labels-collection-sidepanel__list">
        <li class="labels-collection-sidepanel__list-item"
            *ngFor="let item of data.collection trackBy: trackByFn">
            <ng-container *ngTemplateOutlet="data.itemTemplate; context: { item: item }"></ng-container>
        </li>
    </ul>
</mc-sidepanel-body>
<mc-sidepanel-footer class="default-collection-sidepanel__footer">
    <mc-sidepanel-actions class="default-collection-sidepanel__actions" align="right">
        <button *ngIf="data.editable" mc-button color="primary" (click)="data.edit.emit()">
            {{ 'common.Common.Pseudo.Button.Change' | transloco }}
        </button>
        <button mc-button mc-sidepanel-close>
            {{ 'common.Common.Pseudo.Button.Close' | transloco }}
        </button>
    </mc-sidepanel-actions>
</mc-sidepanel-footer>
