import { createReducer, on, Action } from '@ngrx/store';

import * as ErrorActions from './error.actions';

export const ERROR_FEATURE_KEY = 'error';

export interface State {
    code: string;
}

export interface ErrorPartialState {
    readonly [ERROR_FEATURE_KEY]: State;
}

export const initialState: State = {
    code: undefined
};

const errorReducer = createReducer(
    initialState,
    on(ErrorActions.setError, (state, { code }) => ({
        ...state,
        code
    })),
    on(ErrorActions.resetError, (state) => ({
        ...state,
        code: undefined
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return errorReducer(state, action);
}
