import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { IDataSource } from '@pt-cybsi/api-interfaces';

import * as DataSourcesActions from './data-sources.actions';

export const DATA_SOURCES_FEATURE_KEY = 'dataSources';

export interface State extends EntityState<IDataSource> {
    loaded: boolean;
    currentCursor: string;
}

export interface DataSourcesPartialState {
    readonly [DATA_SOURCES_FEATURE_KEY]: State;
}

export const dataSourcesAdapter: EntityAdapter<IDataSource> = createEntityAdapter<IDataSource>({
    selectId: (dataSource) => dataSource?.uuid
});

export const initialState: State = dataSourcesAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    currentCursor: undefined
});

const dataSourcesReducer = createReducer(
    initialState,
    on(DataSourcesActions.fetchDataSources, (state) => ({
        ...state,
        loaded: false
    })),
    on(DataSourcesActions.fetchDataSourcesSuccess, (state, { response }) =>
        dataSourcesAdapter.setAll(response.fullList, { ...state, loaded: true, currentCursor: response.currentCursor })
    ),
    on(DataSourcesActions.syncDataSources, (state, { dataSources }) =>
        dataSourcesAdapter.upsertMany(dataSources, {
            ...state
        })
    )
);

export function reducer(state: State | undefined, action: Action) {
    return dataSourcesReducer(state, action);
}
