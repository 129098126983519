<div class="form-page-layout__header">
    <div class="form-page-layout__header-container">
        <ng-content select="[form-page-layout-header]"></ng-content>
    </div>
</div>

<div class="form-page-layout__body" cdkScrollable>
    <div class="form-page-layout__body-container">
        <ng-content select="[form-page-layout-body]"></ng-content>
    </div>
</div>

<div class="form-page-layout__footer">
    <div class="form-page-layout__footer-container">
        <ng-content select="[form-page-layout-footer]"></ng-content>
    </div>
</div>
