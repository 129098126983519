import { ObservableEntityType, IApiItemPreview } from '../common';

// prettier-ignore
export type TObservableEntity<OET = ObservableEntityType> =
    OET extends ObservableEntityType.DomainName ? TDomainNameObservableEntity:
    OET extends ObservableEntityType.IPAddress ? TIpAddressObservableEntity:
    OET extends ObservableEntityType.EmailAddress ? TEmailAddressObservableEntity:
    OET extends ObservableEntityType.URL ? TUrlObservableEntity:
    OET extends ObservableEntityType.PhoneNumber ? TPhoneNumberObservableEntity:
    OET extends ObservableEntityType.Identity ? TIdentityObservableEntity:
    OET extends ObservableEntityType.File ? TFileObservableEntity:
        | TDomainNameObservableEntity
        | TIpAddressObservableEntity
        | TEmailAddressObservableEntity
        | TUrlObservableEntity
        | TPhoneNumberObservableEntity
        | TIdentityObservableEntity
        | TFileObservableEntity;

export type TDomainNameObservableEntity = IBaseObservableEntity<ObservableEntityType.DomainName>;
export type TIpAddressObservableEntity = IBaseObservableEntity<ObservableEntityType.IPAddress>;
export type TEmailAddressObservableEntity = IBaseObservableEntity<ObservableEntityType.EmailAddress>;
export type TUrlObservableEntity = IBaseObservableEntity<ObservableEntityType.URL>;
export type TPhoneNumberObservableEntity = IBaseObservableEntity<ObservableEntityType.PhoneNumber>;
export type TIdentityObservableEntity = IBaseObservableEntity<ObservableEntityType.Identity>;
export type TFileObservableEntity = IBaseObservableEntity<ObservableEntityType.File>;

export interface IBaseObservableEntity<OET extends ObservableEntityType> extends IApiItemPreview {
    type: OET;
    keys: TObservableEntityKey<OET>[];
}

// prettier-ignore
export type TObservableEntityKey<OET = ObservableEntityType> =
        OET extends ObservableEntityType.DomainName ? IBaseEntityKey :
        OET extends ObservableEntityType.IPAddress ? IBaseEntityKey :
        OET extends ObservableEntityType.EmailAddress ? IBaseEntityKey :
        OET extends ObservableEntityType.URL ? IBaseEntityKey :
        OET extends ObservableEntityType.PhoneNumber ? IBaseEntityKey :
        OET extends ObservableEntityType.Identity ? IIdentityKey :
        OET extends ObservableEntityType.File ? IFileKey :
            | IBaseEntityKey
            | IIdentityKey
            | IFileKey;

export interface IBaseEntityKey {
    type: TBaseEntityKeyType;
    value: string;
}
export interface IIdentityKey {
    type: TIdentityKeyType;
    value: string;
}
export interface IFileKey {
    type: TFileKeyType;
    value: string;
}

export type TBaseEntityKeyType = 'String';
export type TIdentityKeyType = 'IANAID' | 'NICHandle';
export type TFileKeyType = 'MD5Hash' | 'SHA1Hash' | 'SHA256Hash';

export const FILE_KEY_TYPES: TFileKeyType[] = ['MD5Hash', 'SHA1Hash', 'SHA256Hash'];

export type TObservableEntityKeyType = TBaseEntityKeyType | TIdentityKeyType | TFileKeyType;

export const MaxCountKeysOfEntity = {
    [ObservableEntityType.File]: 3,
    [ObservableEntityType.Identity]: Infinity,
    [ObservableEntityType.IPAddress]: 1,
    [ObservableEntityType.DomainName]: 1,
    [ObservableEntityType.URL]: 1,
    [ObservableEntityType.EmailAddress]: 1,
    [ObservableEntityType.PhoneNumber]: 1
};
