<div *ngIf="isLoading" class="sources-tree-grid-details__spinner">
    <mc-progress-spinner mode="indeterminate"></mc-progress-spinner>
</div>

<div *ngIf="isEmpty" class="sources-tree-grid-details__empty-message">
    <span>{{ 'sources.Sources.Pseudo.Text.EmptySources' | transloco }}</span>
</div>

<div *ngIf="isFailure" class="sources-tree-grid-details__error-message">
    <i class="mc-icon_light margin-right_4" mc-icon="mc-error_16"></i>
    <span class="text-error">{{ 'sources.Sources.Pseudo.Text.ErrorLoadingSources' | transloco }}</span>
</div>

<sources-grid
    *ngIf="hasSources"
    [state]="Completed"
    [sources]="sources"
></sources-grid>
