import includes from 'lodash/includes';
import uniqueId from 'lodash/uniqueId';

import {
    ArtifactType,
    ENRICHMENT_TYPES,
    EnrichmentTriggerType,
    EnrichmentType,
    ObservableEntityType
} from '@pt-cybsi/api-interfaces';
import { Model } from '@pt-cybsi/shared';

interface IEnrichmentRuleModelProps {
    serverId: string;
    eTag: string;
    enrichment: EnrichmentType;
    name: string;
    isDisabled: boolean;
    isBuiltin: boolean;
    triggers: EnrichmentTriggerType[];
    artifactTypes: ArtifactType[];
    entityTypes: ObservableEntityType[];
    sources: string[];
    triggerSources: string[];
    throttlingInterval: number;
}

/**
 * @model EnrichmentRule
 *
 * @description
 * Model for working with an enrichment rule data on the client in the domain layer.
 */
export class EnrichmentRuleModel extends Model<IEnrichmentRuleModelProps> {
    constructor(props: IEnrichmentRuleModelProps, uid?: string) {
        super(props, uid);
    }

    static create(props: IEnrichmentRuleModelProps, uid?: string): EnrichmentRuleModel {
        return new EnrichmentRuleModel({ ...props }, uid);
    }

    static createNew(): EnrichmentRuleModel {
        return new EnrichmentRuleModel({
            serverId: null,
            eTag: null,
            enrichment: null,
            name: null,
            isDisabled: null,
            isBuiltin: false,
            triggers: [],
            artifactTypes: [],
            entityTypes: [],
            sources: [],
            triggerSources: [],
            throttlingInterval: null
        });
    }

    hasTrigger(triggerType: EnrichmentTriggerType): boolean {
        return includes(this.props.triggers, triggerType);
    }

    getAvailableEnrichmentTypes(): EnrichmentType[] {
        return this.props.isBuiltin
            ? ENRICHMENT_TYPES
            : [EnrichmentType.ArtifactAnalysis, EnrichmentType.ExternalDBLookup];
    }

    getAvailableArtifactTypes(): ArtifactType[] {
        switch (this.props.enrichment) {
            case EnrichmentType.ArtifactAnalysis:
            case EnrichmentType.ArtifactDownload:
            case EnrichmentType.ArchiveUnpack:
                return [ArtifactType.FileSample, ArtifactType.NetworkTraffic, ArtifactType.Archive];

            default:
                return [];
        }
    }

    getAvailableObservableEntityTypes(): ObservableEntityType[] {
        switch (this.props.enrichment) {
            case EnrichmentType.DNSLookup:
            case EnrichmentType.WhoisLookup:
                return [ObservableEntityType.IPAddress, ObservableEntityType.DomainName];

            case EnrichmentType.ExternalDBLookup:
                return [
                    ObservableEntityType.IPAddress,
                    ObservableEntityType.DomainName,
                    ObservableEntityType.URL,
                    ObservableEntityType.Identity,
                    ObservableEntityType.EmailAddress,
                    ObservableEntityType.PhoneNumber,
                    ObservableEntityType.File
                ];

            default:
                return [];
        }
    }

    diff(comparedModel: EnrichmentRuleModel): EnrichmentRuleModel {
        const changedProps = this.getChangedProps(comparedModel, ['serverId', 'eTag']);

        return EnrichmentRuleModel.create(changedProps, this._uid);
    }

    protected generateId(): string {
        return uniqueId('enrichment-rule-');
    }
}
