import { Component, EventEmitter, Input, Output } from '@angular/core';
import { McSidepanelPosition, McSidepanelService } from '@ptsecurity/mosaic/sidepanel';

import { PermissionsService } from '@pt-cybsi/domain-core/account';

import { ApiKeysPermissionsService } from '../../services';
import { ITableApiKeyData } from '../../types';
import { PermissionsSidepanelComponent } from '../permissions-sidepanel/permissions-sidepanel.component';

@Component({
    selector: 'user-api-keys-table',
    templateUrl: './user-api-keys-table.component.html',
    styleUrls: ['./user-api-keys-table.component.scss']
})
export class UserApiKeysTableComponent {
    @Input() apiKeys: ITableApiKeyData[];
    @Input() isCurrentUser: boolean;

    @Output() editKey = new EventEmitter<ITableApiKeyData>();

    columns = [
        { title: 'Description', class: 'user-api-keys-table__description' },
        { title: 'Permissions', class: 'user-api-keys-table__permissions' },
        { title: 'Issued', class: 'user-api-keys-table__date-cell' },
        { title: 'Used', class: 'user-api-keys-table__date-cell' },
        { title: 'ValidUntil', class: 'user-api-keys-table__date-cell' }
    ];

    readonly hasEditApiKeyPermissions = this.permissionsService.hasAllPermissions(
        ApiKeysPermissionsService.editPermissions
    );

    constructor(private permissionsService: PermissionsService, private sidepanelService: McSidepanelService) {}

    trackByApiKeyId(_index: number, apiKey: ITableApiKeyData): string {
        return apiKey.id;
    }

    isEditButtonVisible(apiKey: ITableApiKeyData): boolean {
        return !apiKey.revoked && (this.hasEditApiKeyPermissions || this.isCurrentUser);
    }

    handleClickMore(permissions: string[]) {
        this.sidepanelService.open<PermissionsSidepanelComponent, { permissions: string[] }>(
            PermissionsSidepanelComponent,
            {
                data: {
                    permissions
                },
                requiredBackdrop: true,
                position: McSidepanelPosition.Left,
                overlayPanelClass: 'sidepanel'
            }
        );
    }
}
