import { UntypedFormGroup } from '@angular/forms';
import { Observable, of, pipe } from 'rxjs';
import { delay, filter, map, switchMap, takeWhile } from 'rxjs/operators';

import { AsyncValidationStatus } from '../services/async-validation.service';

export const awaitFormValidation = (form: UntypedFormGroup, asyncValidation$?: Observable<AsyncValidationStatus>) =>
    pipe(
        delay(1),
        // eslint-disable-next-line rxjs/no-ignored-takewhile-value
        takeWhile(() => form.valid),
        switchMap(() =>
            asyncValidation$
                ? asyncValidation$.pipe(
                      filter((status) => status === AsyncValidationStatus.Complete),
                      delay(1),
                      map(() => form.valid)
                  )
                : of(form.valid)
        )
    );
