import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

import { FormattedDatePipe } from '@pt-cybsi/shared';

import { getDaysToExpiredLicense } from '../helpers';
import { ILicenseView, LicenseViewState } from '../types';

@Pipe({
    name: 'translateLicenseViewState'
})
export class TranslateLicenseViewStatePipe implements PipeTransform {
    constructor(private formattedDatePipe: FormattedDatePipe) {}

    transform(license: ILicenseView): string {
        switch (license.state) {
            case LicenseViewState.Active: {
                return translate('license.License.ViewState.Text.Active', {
                    expiredAt: this.formattedDatePipe.transform(license.expiresAt.toISO(), {
                        format: 'absoluteLongDate',
                        run: 'sync'
                    })
                });
            }

            case LicenseViewState.Expires: {
                return translate('license.License.ViewState.Text.Expires', {
                    days: getDaysToExpiredLicense(license.expiresAt)
                });
            }

            default: {
                return translate('license.License.Pseudo.Text.Inactive');
            }
        }
    }
}
