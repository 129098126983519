import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ErrorEffects } from './state/error.effects';
import { ErrorFacade } from './state/error.facade';
import * as fromError from './state/error.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(fromError.ERROR_FEATURE_KEY, fromError.reducer),
        EffectsModule.forFeature([ErrorEffects])
    ],
    providers: [ErrorFacade]
})
export class StoreErrorModule {}
