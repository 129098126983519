import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ColDef, GridOptions } from 'ag-grid-community';

import { ConfidenceFormat, LazyDataState, TemplateCellComponent } from '@pt-cybsi/shared';

import { SourcesNavigationService } from '../../services';

export type TSourcesGridItemId = string;

export interface ISourcesGridItem {
    id: TSourcesGridItemId;
    source: {
        id: TSourcesGridItemId;
        name: string;
    };
    confidence: number;
}

/**
 * @component SourcesGrid
 *
 * @param state state of lazy-grid
 * @param sources list of sources for lazy-grid in a `ISourcesGridItem[]` format
 * @param errorTitle message displayed on 'error' state of lazy-grid
 * @param loadMore event triggered by lazy-grid, when grid scrollbar reaches end of grid and state is 'Pending'
 *
 * @description Is used for display grid of sources. For grid used lazy-grid component.
 *
 * @example
 * Live examples can be viewed in Storybook
 *
 * Example of template:
 * ```html
 *  <sources-grid
 *      [state]="state"
 *      [sources]="sources"
 *      [errorTitle]="errorTitle"
 *      (loadMore)="handleLoadMore()"
 *  ></sources-grid>
 * ```
 */
@Component({
    selector: 'sources-grid',
    templateUrl: './sources-grid.component.html',
    styleUrls: ['./sources-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SourcesGridComponent implements AfterViewInit {
    @Input()
    state: LazyDataState;

    @Input()
    sources: ISourcesGridItem[];

    @Input()
    errorTitle: string;

    @Output()
    loadMore = new EventEmitter<void>();

    @ViewChild('sourceCell')
    sourceCellTemplate: TemplateRef<unknown>;

    @ViewChild('confidenceCell')
    confidenceCellTemplate: TemplateRef<unknown>;

    columns: ColDef[] = [];

    agGridOptions: GridOptions = {
        getRowNodeId: (nodeData: ISourcesGridItem) => nodeData.id
    };

    ConfidenceFormat = ConfidenceFormat;

    constructor(
        private translocoService: TranslocoService,
        private sourcesNavigationService: SourcesNavigationService
    ) {}

    ngAfterViewInit(): void {
        this.columns = this.getGridColumnDefs();
    }

    getSourceLink(sourceId: TSourcesGridItemId): string {
        return this.sourcesNavigationService.getPathOfSourceViewRoute(sourceId);
    }

    private getGridColumnDefs(): ColDef[] {
        return [
            {
                field: 'source',
                headerName: this.translocoService.translate('sources.Sources.Pseudo.Text.Source'),
                width: 400,
                cellRendererFramework: TemplateCellComponent,
                cellRendererParams: {
                    template: this.sourceCellTemplate
                }
            },
            {
                field: 'confidence',
                headerName: this.translocoService.translate('sources.Sources.Pseudo.Text.Confidence'),
                flex: 1,
                cellRendererFramework: TemplateCellComponent,
                cellRendererParams: {
                    template: this.confidenceCellTemplate
                }
            }
        ];
    }
}
