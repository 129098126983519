<ng-container *transloco="let tUsers; read: 'users'">
    <div *ngIf="user.isDisabled" class="margin-bottom_24">
        <status-badge
            statusTranslationKey="common.Common.User.Text.Disabled">
        </status-badge>
    </div>

    <property-row [title]="tUsers('Users.Pseudo.Text.Authentication')">
        <div property-row-content pt-title class="pt-text-overflow">{{ user.authProviderName }}</div>
    </property-row>
    <property-row [title]="'common.Common.Pseudo.Text.ShareLevel' | transloco">
    <span class="layout-row layout-align-start-center" property-row-content>
        <i mc-icon class="mc-icon_light margin-right_4" [shareLevelIcon]="user.accessLevel"></i>
        <span>{{ user.accessLevel }}</span>
    </span>
    </property-row>
    <property-row *ngIf="isCurrentUser" [title]="'common.Common.Pseudo.Text.Login' | transloco">
        <div property-row-content pt-title class="pt-text-overflow">{{ user.login }}</div>
    </property-row>
    <property-row *ngIf="user.fullName" [title]="tUsers('Users.Pseudo.Text.Name')">
        <div property-row-content pt-title class="pt-text-overflow">{{ user.fullName }}</div>
    </property-row>
    <property-row *ngIf="user.email" [title]="tUsers('Users.Pseudo.Text.Email')">
        <div property-row-content pt-title class="pt-text-overflow">{{ user.email }}</div>
    </property-row>
    <property-row [title]="'common.Common.Pseudo.Text.Password' | transloco">
        <div class="layout-row layout-align-start-baseline" property-row-content>
            <div class="layout-row layout-align-start-center">
                <i *ngIf="user.passwordAuthEnabled" mc-icon="mc-check_16" class="mc-icon_light icon_success margin-right_4"></i>
                <span>{{ user.passwordAuthEnabled
                            ? tUsers('Users.ViewPage.Text.PasswordInstalled')
                            : tUsers('Users.ViewPage.Text.PasswordNotInstalled')
                      }}
                </span>
                <button *ngIf="isEditButtonVisible"
                        mc-link pseudo
                        class="mc-link clear-button margin-left_16"
                        (click)="handleChangePassword()">
                    {{ user.passwordAuthEnabled
                        ? tUsers(newPasswordKey)
                        : tUsers('Users.ViewPage.Text.SetPassword')
                    }}
                </button>
            </div>
        </div>
    </property-row>
    <property-row *ngIf="user.rolesNames.length" [title]="tUsers('Users.Pseudo.Text.Roles')">
        <limited-collection
            property-row-content
            [collection]="user.rolesNames | translateRoles"
            [itemTemplate]="roleTemplate"
            [collectionTitle]= "tUsers('Users.ViewPage.Text.CountRoles', { count: user.roles.length })">
        </limited-collection>
    </property-row>

    <property-row *ngIf="isSourceVisible" [title]="tUsers('Users.Pseudo.Text.RelatedSource')">
        <div class="layout-row" property-row-content>
            <source-preview [sourceId]="user.dataSourceId"></source-preview>
        </div>
    </property-row>

    <property-row *ngIf="isSourceWithActionsVisible" [title]="tUsers('Users.Pseudo.Text.RelatedSource')">
        <div class="layout-row layout-align-start-center" property-row-content>
            <mc-progress-spinner
                *ngIf="isChangingSource"
                class="user-body__spinner"
                mode="indeterminate">
            </mc-progress-spinner>

            <ng-container *ngIf="hasSource && isReadySource; else noDataSource">
                <source-preview [sourceId]="user.dataSourceId" class="user-body__source-preview layout-margin-right-l"></source-preview>
                <div *ngIf="hasChangeUserDataSourcePermissions" class="user-body__update-source-buttons">
                    <button mc-link pseudo
                            class="mc-link clear-button layout-row layout-align-start-center margin-right_16"
                            (click)="handleChangeSource()">
                        <i mc-icon class="pt-icons pt-icons-edit_16 mc-icon_light"></i>
                        <span pt-title class="mc-link__text">{{ tUsers('Users.Pseudo.Text.Edit') }}</span>
                    </button>
                    <button mc-link pseudo
                            class="mc-link clear-button layout-row layout-align-start-center"
                            (click)="deleteUserSource()">
                        <i mc-icon class="pt-icons pt-icons-delete_16 mc-icon_light"></i>
                        <span pt-title class="mc-link__text">{{ tUsers('Users.ViewPage.Text.DeleteSource') }}</span>
                    </button>
                </div>
            </ng-container>
            <ng-template #noDataSource>
                <button *ngIf="!isChangingSource && hasChangeUserDataSourcePermissions" mc-link pseudo
                        class="mc-link clear-button"
                        (click)="handleChangeSource()">
                    <span pt-title class="mc-link__text">{{ tUsers('Users.ViewPage.Text.Select') }}</span>
                </button>
            </ng-template>
        </div>
    </property-row>

    <ng-template #roleTemplate let-role="item">
        <default-collection-item [value]="role"></default-collection-item>
    </ng-template>
</ng-container>
