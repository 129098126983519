import { Unpacked } from '@pt-cybsi/core';

import { ObservableEntityType } from '../common';

import { AggregateSection, IAggregateSection, TAggregateSectionDataByEntityType } from './aggregate-section';
import { AttributeObservableEntity, TAttributeValueType } from './attributes';
import { IFactOfAttribute } from './fact-of-attribute';

export type TNaturalAttributesSection<OET = ObservableEntityType> = IAggregateSection<
    AggregateSection.NaturalAttributes,
    TFactOfNaturalAttribute<OET>[]
>;

// prettier-ignore
export type TFactOfNaturalAttribute<OET = ObservableEntityType> =
    OET extends ObservableEntityType.IPAddress ? TFactOfNaturalAttributeIpAddress :
    OET extends ObservableEntityType.DomainName ? TFactOfNaturalAttributeDomainName :
    OET extends ObservableEntityType.EmailAddress ? TFactOfNaturalAttributeEmailAddress :
    OET extends ObservableEntityType.Identity ? TFactOfNaturalAttributeIdentity:
    OET extends ObservableEntityType.File ? TFactOfNaturalAttributeFile :
        | TFactOfNaturalAttributeIpAddress
        | TFactOfNaturalAttributeEmailAddress
        | TFactOfNaturalAttributeIdentity
        | TFactOfNaturalAttributeFile;

export type TNaturalAttribute = TFactOfNaturalAttribute['attributeName'];

export type TFactOfNaturalAttributeByEntityType<
    OET extends ObservableEntityType,
    AttributeName extends TNaturalAttribute
> = Extract<
    Unpacked<TAggregateSectionDataByEntityType<OET, AggregateSection.NaturalAttributes>>,
    { attributeName: AttributeName }
>;

export type TNaturalAttributeValue<
    OET extends ObservableEntityType,
    AttributeName extends TNaturalAttribute
> = TFactOfNaturalAttributeByEntityType<OET, AttributeName>['value'];

export type TNaturalAttributeValues<
    OET extends ObservableEntityType,
    AttributeName extends TNaturalAttribute
> = TFactOfNaturalAttributeByEntityType<OET, AttributeName>['values'];

export type TFactOfNaturalAttributeIpAddress =
    | TFactOfIpAddressRegionalInternetRegistry
    | TFactOfIpAddressASN
    | TFactOfIpAddressStatuses;

export type TFactOfNaturalAttributeDomainName = TFactOfDomainNameIsDelegated | TFactOfDomainNameStatuses;

export type TFactOfNaturalAttributeFile = TFactOfFileSize | TFactOfFileNames | TFactOfFileMalwareNames;

export type TFactOfNaturalAttributeIdentity = TFactOfIdentityClass | TFactOfIdentityNames | TFactOfIdentitySectors;

export type TFactOfNaturalAttributeEmailAddress = TFactOfEmailAddressDisplayNames;

export type TFactOfIpAddressRegionalInternetRegistry = IFactOfAttribute<
    AttributeObservableEntity.RegionalInternetRegistry,
    TAttributeValueType<AttributeObservableEntity.RegionalInternetRegistry>
>;

export type TFactOfIpAddressASN = IFactOfAttribute<
    AttributeObservableEntity.ASN,
    TAttributeValueType<AttributeObservableEntity.ASN>
>;

export type TFactOfIpAddressStatuses = IFactOfAttribute<
    AttributeObservableEntity.Statuses,
    TAttributeValueType<AttributeObservableEntity.Statuses>
>;

export type TFactOfDomainNameIsDelegated = IFactOfAttribute<
    AttributeObservableEntity.IsDelegated,
    TAttributeValueType<AttributeObservableEntity.IsDelegated>
>;

export type TFactOfDomainNameStatuses = IFactOfAttribute<
    AttributeObservableEntity.Statuses,
    TAttributeValueType<AttributeObservableEntity.Statuses>
>;

export type TFactOfFileNames = IFactOfAttribute<
    AttributeObservableEntity.Names,
    TAttributeValueType<AttributeObservableEntity.Names>
>;

export type TFactOfFileSize = IFactOfAttribute<
    AttributeObservableEntity.Size,
    TAttributeValueType<AttributeObservableEntity.Size>
>;

export type TFactOfFileMalwareNames = IFactOfAttribute<
    AttributeObservableEntity.MalwareNames,
    TAttributeValueType<AttributeObservableEntity.MalwareNames>
>;

export type TFactOfIdentityNames = IFactOfAttribute<
    AttributeObservableEntity.Names,
    TAttributeValueType<AttributeObservableEntity.Names>
>;

export type TFactOfIdentityClass = IFactOfAttribute<
    AttributeObservableEntity.Class,
    TAttributeValueType<AttributeObservableEntity.Class>
>;

export type TFactOfIdentitySectors = IFactOfAttribute<
    AttributeObservableEntity.Sectors,
    TAttributeValueType<AttributeObservableEntity.Sectors>
>;

export type TFactOfEmailAddressDisplayNames = IFactOfAttribute<
    AttributeObservableEntity.DisplayNames,
    TAttributeValueType<AttributeObservableEntity.DisplayNames>
>;

export const NATURAL_ATTRIBUTES: TNaturalAttribute[] = [
    AttributeObservableEntity.Size,
    AttributeObservableEntity.Names,
    AttributeObservableEntity.Class,
    AttributeObservableEntity.Sectors,
    AttributeObservableEntity.DisplayNames,
    AttributeObservableEntity.RegionalInternetRegistry,
    AttributeObservableEntity.ASN,
    AttributeObservableEntity.IsDelegated,
    AttributeObservableEntity.Statuses,
    AttributeObservableEntity.MalwareNames
];
