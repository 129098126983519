import flatten from 'lodash/flatten';

import { ApiResource, PermissionType } from '@pt-cybsi/api-interfaces';

export function parsePermissions(permissions: string[]): string[] {
    return flatten(
        permissions.map((permission) => {
            const permissionStructure = permission.split(':');
            const apiResource: ApiResource = permissionStructure[0] as ApiResource;
            const permissionTypes: PermissionType[] = permissionStructure[1].split('') as PermissionType[];

            // prettier-ignore
            return permissionTypes.length > 1
                ? permissionTypes.map((type) => `${apiResource}:${type}`)
                : permission;
        })
    );
}
