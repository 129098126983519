import isEmpty from 'lodash/isEmpty';

import {
    ObservableEntityType,
    TSection,
    AggregateSection,
    TFactOfAssociatedAttribute,
    AttributeObservableEntity,
    TFactOfNaturalAttribute,
    TAggregate,
    TIdentityKeyType,
    TFileKeyType
} from '@pt-cybsi/api-interfaces';

import { getUniqObservableEntityId } from '../helpers';

const FILE_KEY_TYPES_BY_PRIORITY: TFileKeyType[] = ['SHA256Hash', 'SHA1Hash', 'MD5Hash'];
const IDENTITY_KEY_TYPES_BY_PRIORITY: TIdentityKeyType[] = ['NICHandle', 'IANAID'];

export class AggregateModel<EntityType extends ObservableEntityType = ObservableEntityType> {
    get serverId(): string {
        return this.aggregate.uuid || null;
    }

    get uniqId(): string {
        return getUniqObservableEntityId(this.aggregate);
    }

    get type(): TAggregate<EntityType>['type'] {
        return this.aggregate.type;
    }

    get keys(): TAggregate<EntityType>['keys'] {
        return this.aggregate.keys;
    }

    get raw(): TAggregate<EntityType> {
        return this.aggregate;
    }

    constructor(private aggregate: TAggregate<EntityType>) {}

    private static isMatchedWithEntityType<ET extends ObservableEntityType>(
        model: AggregateModel,
        entityType: ET
    ): model is AggregateModel<ET> {
        return model.aggregate.type === entityType;
    }

    getMainKeys(): this['keys'] {
        return this.getKeysByPriorities()[0] || [];
    }

    getSecondaryKeys(): this['keys'] {
        return this.getKeysByPriorities()[1] || [];
    }

    findFactOfNaturalAttribute<
        AttributeName extends AttributeObservableEntity,
        Fact extends Extract<TFactOfNaturalAttribute<EntityType>, { attributeName: AttributeName }>
    >(attributeName: AttributeName): Fact | undefined {
        const attributes = this.findSection(AggregateSection.NaturalAttributes);

        const facts = attributes?.data || [];

        const isMatchedFactOfAttribute = (item: Fact): item is Fact => item.attributeName === attributeName;

        return facts.find(isMatchedFactOfAttribute);
    }

    findFactOfAssociatedAttribute<
        AttributeName extends AttributeObservableEntity,
        Fact extends Extract<TFactOfAssociatedAttribute<EntityType>, { attributeName: AttributeName }>
    >(attributeName: AttributeName): Fact | undefined {
        const attributes = this.findSection(AggregateSection.AssociatedAttributes);

        const facts = attributes?.data || [];

        const isMatchedFactOfAttribute = (item: Fact): item is Fact => item.attributeName === attributeName;

        return facts.find(isMatchedFactOfAttribute);
    }

    findSection<SectionName extends AggregateSection, Section extends TSection<SectionName>>(
        sectionName: SectionName
    ): Section | undefined {
        const sections = this.aggregate.sections as Section[];

        if (isEmpty(sections)) {
            return undefined;
        }

        const findPredicate = (s: Section): s is Section => s.name === sectionName;

        return sections.find(findPredicate);
    }

    getKeysByPriorities(): this['keys'][] {
        if (this.is(ObservableEntityType.File)) {
            const keys = this.keys;

            const groupKeysByPriority = FILE_KEY_TYPES_BY_PRIORITY.map((keyType) =>
                keys.filter((key) => key.type === keyType)
            );

            return groupKeysByPriority.filter((group) => group.length > 0);
        } else if (this.is(ObservableEntityType.Identity)) {
            const keys = this.keys;

            const groupKeysByPriority = IDENTITY_KEY_TYPES_BY_PRIORITY.map((keyType) =>
                keys.filter((key) => key.type === keyType)
            );

            return groupKeysByPriority.filter((group) => group.length > 0);
        } else {
            return [this.aggregate.keys];
        }
    }

    is<ET extends ObservableEntityType>(entityType: ET): this is AggregateModel<ET> {
        return AggregateModel.isMatchedWithEntityType(this, entityType);
    }
}

export type TAggregateModel =
    | AggregateModel<ObservableEntityType.File>
    | AggregateModel<ObservableEntityType.Identity>
    | AggregateModel<ObservableEntityType.IPAddress>
    | AggregateModel<ObservableEntityType.DomainName>
    | AggregateModel<ObservableEntityType.URL>
    | AggregateModel<ObservableEntityType.EmailAddress>
    | AggregateModel<ObservableEntityType.PhoneNumber>;
