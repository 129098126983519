import { HttpHeaders, HttpRequest } from '@angular/common/http';

export const API_VERSION_HEADER_NAME = 'Accept';
export const API_V1_CONTENT_TYPE = 'application/vnd.ptsecurity.app-v1';
export const API_V2_CONTENT_TYPE = 'application/vnd.ptsecurity.app-v2';

export const isApiRequest = (request: HttpRequest<unknown>): boolean => {
    const apiVersionHeader = request.headers.get(API_VERSION_HEADER_NAME);

    return apiVersionHeader && (apiVersionHeader === API_V2_CONTENT_TYPE || apiVersionHeader === API_V1_CONTENT_TYPE);
};

export const addApiHeader = (headers: HttpHeaders, apiVersion: 1 | 2 = 2): HttpHeaders =>
    headers.append(API_VERSION_HEADER_NAME, apiVersion === 2 ? API_V2_CONTENT_TYPE : API_V1_CONTENT_TYPE);
