<button
    mc-button
    color="primary"
    class="mc-button_transparent"
    [class.mc-active]="isActive"
    [mcTooltip]="valueTitle"
    [mcTooltipDisabled]="!isAllowTextContentTooltip">
    <span #textContent class="filter-button__text pt-text-overflow">
        {{ buttonText }}
    </span>
    <i mc-icon="mc-angle-down-S_16" class="filter-button__arrow"></i>
</button>
