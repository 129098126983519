import uniqueId from 'lodash/uniqueId';

import { Model } from '@pt-cybsi/shared';

import { SourceTypeModel } from './source-type.model';

interface ISourceModelProps {
    typeId: string;
    analyserId: string;
    externalDBId: string;
    serverId: string;
    eTag: string;
    name: string;
    shortName: string;
    confidence: number;
}

export class SourceModel extends Model<ISourceModelProps> {
    get isNew(): boolean {
        return this.props.serverId === null;
    }

    get isMatchWithTypeConfidence(): boolean {
        return this.props.confidence === null;
    }

    get isAnalyser(): boolean {
        return this.props.analyserId !== null;
    }

    get isExternalDB(): boolean {
        return this.props.externalDBId !== null;
    }

    constructor(props: ISourceModelProps, uid?: string) {
        super(props, uid);
    }

    static create(props: ISourceModelProps, uid?: string): SourceModel {
        return new SourceModel({ ...props }, uid);
    }

    static createNew(type?: SourceTypeModel): SourceModel {
        return new SourceModel({
            typeId: type?.uid || null,
            analyserId: null,
            externalDBId: null,
            serverId: null,
            eTag: null,
            name: null,
            shortName: null,
            confidence: null
        });
    }

    useTypeConfidence(): void {
        this.props.confidence = null;
    }

    useCustomConfidence(confidence: number): void {
        this.props.confidence = confidence;
    }

    addAnalyser(analyserId: string): void {
        this.props.analyserId = analyserId;
    }

    addExternalDB(externalDBId: string): void {
        this.props.externalDBId = externalDBId;
    }

    removeAnalyser(): void {
        this.props.analyserId = null;
    }

    removeExternalDB(): void {
        this.props.externalDBId = null;
    }

    diff(comparedModel: SourceModel): SourceModel {
        const changedProps = this.getChangedProps(comparedModel, [
            'serverId',
            'eTag',
            'typeId',
            'analyserId',
            'externalDBId'
        ]);

        return SourceModel.create(changedProps, this._uid);
    }

    protected generateId(): string {
        return uniqueId('source-');
    }
}
