import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { fromEvent, Observable, timer } from 'rxjs';
import { startWith, switchMapTo, takeUntil } from 'rxjs/operators';

import { DestroyService } from '../../services/destroy.service';

export class NotificationMessageParams {
    type?: NotificationType;
    timeout?: number | false;
    closable?: boolean;
    icon?: string;
    text: string;
}

@Component({
    selector: 'notification-message',
    templateUrl: './notification-message.component.html',
    styleUrls: ['./notification-message.component.scss']
})
export class NotificationMessageComponent {
    @Output() closeMessage = new EventEmitter<void>();

    get cssClassOfType(): string {
        return this.cssClassesByType[this.params.type];
    }

    private readonly cssClassesByType = {
        [NotificationType.Error]: 'mc-alert_error',
        [NotificationType.Warning]: 'mc-alert_warning',
        [NotificationType.Success]: 'mc-alert_success'
    };

    constructor(
        private element: ElementRef,
        private destroyed$: DestroyService,
        readonly params: NotificationMessageParams
    ) {
        if (this.params.timeout) {
            this.initClosingByTimeout(this.params.timeout);
        }
    }

    handleClickClose(): void {
        this.closeMessage.emit();
    }

    private initClosingByTimeout(timeout: number): void {
        const startTimer: () => Observable<number> = () =>
            timer(timeout).pipe(takeUntil(fromEvent(this.element.nativeElement as HTMLElement, 'mouseenter')));

        fromEvent(this.element.nativeElement as HTMLElement, 'mouseleave')
            .pipe(startWith('init'), switchMapTo(startTimer()), takeUntil(this.destroyed$))
            .subscribe(() => {
                this.closeMessage.emit();
            });
    }
}

export enum NotificationType {
    Error = 'Error',
    Warning = 'Warning',
    Success = 'Success'
}
