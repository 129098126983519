/*
Похоже взято отсюда https://github.com/ag-grid/ag-grid-angular/issues/206#issuecomment-394106165
Аналогичный подход описан тут https://blog.angularindepth.com/easier-embedding-of-angular-ui-in-ag-grid-52db93b73884
*/

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'ag-template-cell',
    template: ` <ng-container *ngTemplateOutlet="template; context: templateContext"></ng-container> `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateCellComponent implements OnInit, ICellRendererAngularComp {
    @Input() template: TemplateRef<unknown>;

    params: ICellRendererParams;

    get templateContext() {
        return {
            $implicit: this.params.value,
            params: this.params
        };
    }

    constructor(public element: ElementRef) {}

    ngOnInit(): void {
        if (this.params && (this.params as any).setElement) {
            (this.params as any).setElement(this.params.node.id, this.element.nativeElement);
        }
    }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.template = (params as any).template;
    }

    refresh(): boolean {
        return false;
    }
}
