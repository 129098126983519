import { Pipe, PipeTransform } from '@angular/core';
import trimEnd from 'lodash/trimEnd';

const SEPARATOR = '|';

@Pipe({
    name: 'translateCut'
})
export class TranslateCutPipe implements PipeTransform {
    transform(value: string, index: number): string {
        const splitted: string[] = value ? value.split(SEPARATOR) : undefined;
        const phrase: string = splitted ? splitted[index] : undefined;

        return phrase ? trimEnd(phrase) : '';
    }
}
