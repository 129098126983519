import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { McModalRef, McModalService, ModalSize } from '@ptsecurity/mosaic/modal';
import { DateTime } from 'luxon';
import { Observable, throwError } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import { ApiKeysApiService } from '@pt-cybsi/api';
import { HttpStatus, IApiKey, IEditableResource, IServerError, IUpdateApiKeyData } from '@pt-cybsi/api-interfaces';
import { DestroyService, FormMode, FormStateValue } from '@pt-cybsi/shared';

import { ApiKeyFormBuilder, ApiKeyFormMapper, ApiKeyFormViewModel, TApiKeyFormData } from '../../forms';

@Component({
    selector: 'edit-api-key-modal',
    templateUrl: './edit-api-key-modal.component.html',
    styleUrls: ['./edit-api-key-modal.component.scss'],
    providers: [ApiKeyFormBuilder, DestroyService]
})
export class EditApiKeyModalComponent implements OnInit {
    @Input() apiKeyId: string;
    @Input() isCurrentUser: boolean;

    form: ApiKeyFormViewModel = this.apiKeyFormBuilder.build({
        mode: FormMode.Edit,
        state: FormStateValue.Initializing,
        savingError: null,
        initialData: null,
        currentData: null
    });
    eTag: string;

    get formState(): FormStateValue {
        return this.form.state;
    }

    get isInitializing(): boolean {
        return this.formState === FormStateValue.Initializing;
    }

    get isInitializingFailed(): boolean {
        return this.formState === FormStateValue.InitializingFailure;
    }

    get isSubmitDisabled() {
        return (
            [FormStateValue.Validating, FormStateValue.Saving, FormStateValue.Saved].indexOf(this.formState) !== -1 ||
            !this.form.isReady
        );
    }

    get isAlreadyChangedError() {
        return this.form.savingError?.code === HttpStatus.PRECONDITION_FAILED;
    }

    constructor(
        private apiKeysApiService: ApiKeysApiService,
        private apiKeyFormBuilder: ApiKeyFormBuilder,
        private modalRef: McModalRef,
        private modalService: McModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private destroyed$: DestroyService
    ) {}

    ngOnInit(): void {
        this.loadApiKey();
    }

    loadApiKey() {
        this.form.updateState(FormStateValue.Initializing);

        this.getEditableApiKey()
            .pipe(takeUntil(this.destroyed$))
            .subscribe({
                next: (editableApiKey) => {
                    this.eTag = editableApiKey.eTag;

                    const formData: TApiKeyFormData = {
                        id: 'api-key',
                        description: editableApiKey.data.description,
                        // Если не установить значение, то эти поля будут invalid
                        expiresAt: DateTime.now(),
                        isPerpetual: false,
                        permissions: [],
                        isInheritPermissions: true
                    };

                    this.form.updateInitialData(formData);
                    this.form.updateCurrentData(formData);
                    this.form.hideControls(
                        Object.keys(formData).filter(
                            (formKey) => formKey !== 'description'
                        ) as (keyof TApiKeyFormData)[]
                    );

                    this.form.updateState(FormStateValue.ReadyForInput);

                    this.changeDetectorRef.detectChanges();
                },
                error: () => {
                    this.form.updateState(FormStateValue.InitializingFailure);
                    this.changeDetectorRef.detectChanges();
                }
            });
    }

    reloadApiKey() {
        this.loadApiKey();
    }

    handleSubmit() {
        this.form.updateState(FormStateValue.Validating);

        this.form
            .validate()
            .pipe(
                switchMap((isValid) => {
                    if (isValid) {
                        this.form.updateState(FormStateValue.ReadyForSave);
                        this.form.updateState(FormStateValue.Saving);
                        this.form.updateSavingError(null);

                        const updateParams = ApiKeyFormMapper.toUpdateParams(this.form.currentData);

                        return this.updateApiKey(updateParams);
                    } else {
                        this.form.updateState(FormStateValue.ValidatingFailure);

                        return throwError(null);
                    }
                }),
                take(1),
                takeUntil(this.destroyed$)
            )
            .subscribe({
                next: () => {
                    this.form.updateState(FormStateValue.Saved);
                    this.closeModal(true);
                },
                error: (error: HttpErrorResponse) => {
                    if (error) {
                        this.form.updateState(FormStateValue.SavingFailure);
                        this.form.updateSavingError(error?.error as IServerError);
                    }

                    this.changeDetectorRef.detectChanges();
                }
            });
    }

    revokeApiKey() {
        this.modalService.confirm({
            mcSize: ModalSize.Small,
            mcMaskClosable: false,
            mcContent: translate('users.Users.EditApiKeyModal.Text.ConfirmRevokeKey'),
            mcOkText: translate('users.Users.EditApiKeyModal.Button.RevokeKey'),
            mcCancelText: translate('common.Common.Pseudo.Button.Cancel'),
            mcOnOk: () => {
                this.form.updateState(FormStateValue.Saving);

                this.form.updateSavingError(null);

                this.updateApiKey({ revoked: true })
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe({
                        next: () => {
                            this.form.updateState(FormStateValue.Saved);
                            this.closeModal(true);
                        },
                        error: (error: HttpErrorResponse) => {
                            this.form.updateState(FormStateValue.SavingFailure);
                            this.form.updateSavingError(error?.error as IServerError);

                            this.changeDetectorRef.detectChanges();
                        }
                    });
            }
        });
    }

    closeModal(apiKeyWasChanged: boolean = false) {
        this.modalRef.destroy(apiKeyWasChanged);
    }

    private updateApiKey(updateData: IUpdateApiKeyData): Observable<void> {
        return this.isCurrentUser
            ? this.apiKeysApiService.updateCurrentUserApiKey(this.apiKeyId, this.eTag, updateData)
            : this.apiKeysApiService.updateApiKey(this.apiKeyId, this.eTag, updateData);
    }

    private getEditableApiKey(): Observable<IEditableResource<IApiKey>> {
        return this.isCurrentUser
            ? this.apiKeysApiService.getCurrentUserEditableApiKey(this.apiKeyId)
            : this.apiKeysApiService.getEditableApiKey(this.apiKeyId);
    }
}
