import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import isNil from 'lodash/isNil';
import { Observable, of, Subject } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';

import { EnrichmentApiService } from '@pt-cybsi/api';
import {
    ArtifactType,
    ENRICHMENT_TRIGGER_TYPES,
    EnrichmentTriggerType,
    EnrichmentType,
    IDataSource,
    ObservableEntityType
} from '@pt-cybsi/api-interfaces';
import {
    DataSourceMapper,
    SourcesNavigationService,
    SourcesSyncService,
    SourceViewModel,
    TSourcesSelectLoadFn
} from '@pt-cybsi/domain-core/sources';
import { createASCSortComparator, DestroyService, TimePeriodService, TimePeriodType } from '@pt-cybsi/shared';

import { EnrichmentRuleFormViewModel } from '../../forms';
import { getEnrichmentRulesSourcesTitle } from '../../helpers';
import { TranslateEnrichmentTriggerTypePipe, TranslateEnrichmentTypePipe } from '../../pipes';
import { EnrichmentRuleFormSavingError } from '../../types';
import { ENRICHMENT_RULE_MAX_LENGTH, ENRICHMENT_RULE_MIN_LENGTH } from '../../validators';

interface ICachedSource {
    sourceId: string;
    sourceName: string;
}

@Component({
    selector: 'enrichment-rule-form',
    templateUrl: './enrichment-rule-form.component.html',
    styleUrls: ['./enrichment-rule-form.component.scss'],
    providers: [DestroyService],
    host: {
        class: 'mc-form-vertical'
    }
})
export class EnrichmentRuleFormComponent implements OnInit {
    @Input() form: EnrichmentRuleFormViewModel;

    get isBuiltin(): boolean {
        return this.form.getControl('isBuiltin').value as boolean;
    }

    get enrichmentType(): EnrichmentType {
        return this.form.getControl('enrichment').value as EnrichmentType;
    }

    get builtinLabelKey(): string {
        return this.isBuiltin
            ? 'enrichment.Enrichment.Pseudo.Text.Builtin'
            : 'enrichment.Enrichment.Pseudo.Text.NotBuiltin';
    }

    get hasEnrichmentTypeRequiredErrors(): boolean {
        return this.rootForm.submitted && this.form.hasError('enrichment', 'required');
    }

    get hasMisconfiguredDataSourceError(): boolean {
        return (
            this.form.isFailureSavingMessageVisible() &&
            this.form.savingError?.code === EnrichmentRuleFormSavingError.MisconfiguredDataSource
        );
    }

    get artifactOrObjectCount(): number {
        return this.form.getControl('artifactTypes').value?.length || this.form.getControl('entityTypes').value?.length;
    }

    get misconfiguredDataSourceErrorMessage(): boolean {
        switch (this.enrichmentType) {
            case EnrichmentType.ArchiveUnpack:
            case EnrichmentType.ArtifactAnalysis:
            case EnrichmentType.ArtifactDownload:
                return this.translocoService.translate(
                    `enrichment.Enrichment.RuleForm.Error.ArtifactAnalysisMisconfiguredDataSource`,
                    { count: this.artifactOrObjectCount }
                );
            default:
                return this.translocoService.translate(
                    `enrichment.Enrichment.RuleForm.Error.ExternalDBLookupMisconfiguredDataSource`,
                    { count: this.artifactOrObjectCount }
                );
        }
    }

    get availableArtifactTypes(): ArtifactType[] {
        return this.form.availableArtifactTypes;
    }

    get availableEntityTypes(): ObservableEntityType[] {
        return this.form.availableEntityTypes;
    }

    get availableEnrichmentTypes(): EnrichmentType[] {
        return this.form.availableEnrichmentTypes;
    }

    get selectedDataSources(): string[] {
        return this.form.getControl('sources').value;
    }

    get sourcesLabel(): string {
        return getEnrichmentRulesSourcesTitle(this.enrichmentType);
    }

    availableTriggers: EnrichmentTriggerType[] = ENRICHMENT_TRIGGER_TYPES;

    invalidNameLengthMessage: string = this.getInvalidLengthMessage(
        ENRICHMENT_RULE_MIN_LENGTH,
        ENRICHMENT_RULE_MAX_LENGTH
    );

    periodThrottlingInterval: TimePeriodType;

    sourcesSelectLoadFn: TSourcesSelectLoadFn = (sourceName?: string) =>
        this.loadSources().pipe(
            map((sources) =>
                (sourceName || '').length === 0
                    ? sources.map((source) => source.sourceId)
                    : sources
                          .filter((source) => source.sourceName.toLowerCase().includes(sourceName.toLowerCase()))
                          .map((source) => source.sourceId)
            )
        );

    enrichmentTypeSortComparator = createASCSortComparator<EnrichmentType>((item) =>
        this.translateEnrichmentTypePipe.transform(item)
    );

    triggerSortComparator = createASCSortComparator<EnrichmentTriggerType>((item) =>
        this.translateEnrichmentTriggerTypePipe.transform(item)
    );

    private sourcesCache$: Observable<ICachedSource[]>;
    private resetSourcesCache$: Subject<void> = new Subject();

    constructor(
        private rootForm: NgForm,
        private translocoService: TranslocoService,
        private enrichmentApiService: EnrichmentApiService,
        private sourcesSyncService: SourcesSyncService,
        private sourcesNavigationService: SourcesNavigationService,
        private timePeriodService: TimePeriodService,
        private translateEnrichmentTypePipe: TranslateEnrichmentTypePipe,
        private translateEnrichmentTriggerTypePipe: TranslateEnrichmentTriggerTypePipe,

        private destroyed$: DestroyService
    ) {}

    ngOnInit(): void {
        this.initThrottlingIntervalField();

        this.form
            .getControl('enrichment')
            .valueChanges.pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.resetSources();
            });
    }

    handleChangePeriodThrottlingInterval(periodType: TimePeriodType): void {
        this.periodThrottlingInterval = periodType;
    }

    getSourceLink(id: string): string {
        return this.sourcesNavigationService.getPathOfSourceViewRoute(id);
    }

    private initThrottlingIntervalField(): void {
        const throttlingInterval = this.form.currentData.throttlingInterval;

        if (isNil(throttlingInterval)) {
            this.periodThrottlingInterval = TimePeriodType.Hours;
        } else {
            const period = this.timePeriodService.toMaxEntirePeriod(throttlingInterval, [
                TimePeriodType.Seconds,
                TimePeriodType.Minutes,
                TimePeriodType.Hours
            ]);

            this.periodThrottlingInterval = period?.type;
        }
    }

    private loadSources(): Observable<ICachedSource[]> {
        if (!this.sourcesCache$) {
            const sourceSortComparator = createASCSortComparator<ICachedSource>(({ sourceName }) => sourceName);

            this.sourcesCache$ = this.loadSourcesByEnrichmentType(this.enrichmentType).pipe(
                map((sources) =>
                    sources
                        .map((source) => {
                            const sourceView = SourceViewModel.create(DataSourceMapper.toFullView(source));

                            return {
                                sourceId: source.uuid,
                                sourceName: sourceView.getFullName()
                            };
                        })
                        .sort(sourceSortComparator)
                ),
                takeUntil(this.resetSourcesCache$),
                shareReplay({ bufferSize: 1, refCount: true })
            );
        }

        return this.sourcesCache$;
    }

    private resetSources(): void {
        this.resetSourcesCache$.next();
        this.sourcesCache$ = null;
    }

    private loadSourcesByEnrichmentType(enrichmentType: EnrichmentType): Observable<IDataSource[]> {
        switch (enrichmentType) {
            case EnrichmentType.ArtifactAnalysis:
                return this.enrichmentApiService.getAllAnalyzers().pipe(
                    map(({ fullList: analysers }) => analysers.map((analyser) => analyser.dataSource)),
                    tap((dataSources: IDataSource[]) => this.sourcesSyncService.syncSources(dataSources))
                );

            case EnrichmentType.ExternalDBLookup:
                return this.enrichmentApiService.getAllExternalDBs().pipe(
                    map(({ fullList: externalDBs }) => externalDBs.map((externalDB) => externalDB.dataSource)),
                    tap((dataSources: IDataSource[]) => this.sourcesSyncService.syncSources(dataSources))
                );

            default:
                return of([] as IDataSource[]);
        }
    }

    private getInvalidLengthMessage(min: number, max: number): string {
        return min > 1
            ? this.translocoService.translate('common.Common.Validation.Text.StringLengthBetween', { min, max })
            : this.translocoService.translate('common.Common.Validation.Text.StringLengthMax', { max });
    }
}
