import { ShareLevel, IPaginationParams } from '../common';

export interface IReputationListRequestParams {
    replistUUID: string;
}

export type TReputationListsRequestParams = IPaginationParams;

export interface IReputationListRequestBody {
    isEnabled: boolean;
    queryUUID: string;
    shareLevel: ShareLevel;
}
