import { IApiItemPreview, ObservableEntityType } from '../common';
import { IDataSource } from '../data-sources';

export interface IEnrichmentExternalDB extends IApiItemPreview {
    entityTypes: ObservableEntityType[];
    webPageURL: string;
    taskExecutionTimeout: number;
    taskExecutionAttemptsCount: number;
    dataSource: IDataSource;
}
