import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { ArtifactType } from '@pt-cybsi/api-interfaces';
import { createASCSortComparator } from '@pt-cybsi/shared';

/**
 * @pipe TranslateArtifactType
 *
 * @description Converts artifact type to localized string
 */
@Pipe({
    name: 'translateArtifactType'
})
export class TranslateArtifactTypePipe implements PipeTransform {
    constructor(private translocoService: TranslocoService) {}

    transform(type: ArtifactType): string {
        return this.translocoService.translate(`artifacts.Artifacts.Type.Text.${type}`);
    }
}

/**
 * @pipe TranslateArtifactTypes
 *
 * @description Converts artifact types to ASC sorted localized strings array
 */
@Pipe({
    name: 'translateArtifactTypes'
})
export class TranslateArtifactTypesPipe implements PipeTransform {
    private readonly sortComparator = createASCSortComparator<string>((item) => item);

    constructor(private translateArtifactType: TranslateArtifactTypePipe) {}

    transform(types: ArtifactType[]): string[] {
        const translatedTypes = types.map((type) => this.translateArtifactType.transform(type));

        return translatedTypes.sort(this.sortComparator);
    }
}
