import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ObservableEntityType } from '@pt-cybsi/api-interfaces';
import { TranslateObservableEntityTypesPipe } from '@pt-cybsi/domain-core/observable-entities';
import { AsyncState, FiltersPanelBaseItem, IListFilterItem } from '@pt-cybsi/shared';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type TEntityTypesFilterItem = IListFilterItem<ObservableEntityType>;

/**
 * @component Entity Types Filter
 *
 * @description
 * Used for filtering data by entity types. Can be put into a filters-panel component
 *
 * @param value - applied values of filter in `ObservableEntityType[]` format
 * @param applyFilter - event, emitted after clicking apply filter button
 * @param resetFilter - event, emitted after clicking reset filter button
 * @param openFilter - event, emitted after opening filter
 * @param closeFilter - event, emitted after closing filter
 *
 * @example
 * ```html
 * <entity-types-filter
 *     [value]="value"
 *     (applyFilter)="handleApply($event)"
 *     (resetFilter)="handleReset()">
 *     (openFilter)="handleOpen()">
 *     (closeFilter)="handleClose()">
 * </entity-types-filter>
 * ```
 */
@Component({
    selector: 'entity-types-filter',
    templateUrl: './entity-types-filter.component.html',
    providers: [
        {
            provide: FiltersPanelBaseItem,
            useExisting: forwardRef(() => EntityTypesFilterComponent)
        }
    ]
})
export class EntityTypesFilterComponent extends FiltersPanelBaseItem implements OnInit, OnChanges {
    @Input() value: ObservableEntityType[];

    @Output() applyFilter = new EventEmitter<ObservableEntityType[]>();
    @Output() resetFilter = new EventEmitter<void>();
    @Output() openFilter = new EventEmitter<void>();
    @Output() closeFilter = new EventEmitter<void>();

    availableItems: TEntityTypesFilterItem[];
    filterState: AsyncState = AsyncState.Success;

    appliedValues$ = new BehaviorSubject<TEntityTypesFilterItem[]>([]);
    valueTitle$: Observable<string[]>;

    readonly title: string = this.translocoService.translate('entities.ObservableEntities.Pseudo.Text.Type');

    constructor(
        private translocoService: TranslocoService,
        private translateObservableEntityTypes: TranslateObservableEntityTypesPipe
    ) {
        super();
    }

    ngOnInit(): void {
        this.availableItems = this.getAvailableItems();
        this.valueTitle$ = this.getValueTitle$();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const isValueChanged = changes.value?.currentValue !== changes.value?.previousValue;

        if (isValueChanged) {
            this.appliedValues$.next(
                this.value.map((entityType) => ({
                    value: entityType,
                    data: entityType
                }))
            );
        }
    }

    handleOpen(): void {
        this.openFilter.emit();
    }

    handleApply(value: TEntityTypesFilterItem[]) {
        this.applyFilter.emit(value.map((entityTypeItem) => entityTypeItem.data));
    }

    handleReset() {
        this.resetFilter.emit();
    }

    reset(): void {
        this.resetFilter.emit();
    }

    getAvailableItems(): TEntityTypesFilterItem[] {
        return Object.values(ObservableEntityType).map((type) => ({ value: type, data: type }));
    }

    private getValueTitle$(): Observable<string[]> {
        return this.appliedValues$.pipe(
            map((entityTypes: TEntityTypesFilterItem[]) => {
                if (!entityTypes) {
                    return [];
                }

                return this.translateObservableEntityTypes.transform(entityTypes.map((entityType) => entityType.data));
            })
        );
    }
}
