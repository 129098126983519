import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoEvents } from '@ngneat/transloco/lib/types';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LazyLoadedTranslationsGuard implements CanActivate {
    private loadedScopes = new Set<string>();

    constructor(private translocoService: TranslocoService) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        let scopeNames = route.data?.translateScope as string | string[];

        if (!scopeNames) {
            return true;
        }

        if (typeof scopeNames === 'string') {
            scopeNames = [scopeNames];
        }

        if (!scopeNames.find((scopeItem) => !this.loadedScopes.has(scopeItem))) {
            return true;
        }

        scopeNames.forEach((scopeItem) => {
            if (!this.loadedScopes.has(scopeItem)) {
                const path = `${scopeItem}/${this.translocoService.getActiveLang()}`;
                this.translocoService.load(path).subscribe().unsubscribe();
            }
        });

        return this.translocoService.events$.pipe(
            switchMap((event: TranslocoEvents) => {
                if (event.type === 'translationLoadSuccess' && event.payload.scope) {
                    this.loadedScopes.add(event.payload.scope);
                }
                if (!(scopeNames as string[]).find((scopeItem) => !this.loadedScopes.has(scopeItem))) {
                    return of(true);
                }
            })
        );
    }
}
