import { FormState, TBaseModel } from '../models';

export interface IFormsGroup {
    [index: string]: TBaseModel | TBaseModel[];
}

export interface IFormsGroupRepository<Forms extends IFormsGroup> {
    getState(): FormState;
    updateState(state: FormState): void;

    getFormData(): Forms;
}
