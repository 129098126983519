<div class="filters-panel flex layout-row layout-align-start-start">
    <div class="filters-panel__filters flex layout-row">
        <ng-content select='[filters-panel-item]'></ng-content>
    </div>
    <div id="filters-panel-reset-button-container"
         class="filters-panel__reset-all layout-row layout-align-start-center">
        <button mc-button
                color="primary"
                class=" mc-button_transparent filters-panel__reset-all-button"
                mcTooltip="{{'common.Common.Pseudo.Button.ClearAll' | transloco}}"
                mcPlacement="bottom"
                [disabled]="isResetDisabled"
                (click)="handleClickResetAll()">
            <i mc-icon="mc-close-circle_16"></i>
        </button>
    </div>
</div>

