import { ObservableEntityType, TDNSWhoisObservableEntityType } from '../common';
import { RegionalInternetRegistry, TObservableEntity } from '../observable';

import { IObservationHeader } from './observation';
import { ObservationType } from './observation-type';
import { TGetEntityType } from './utils';

export interface IObservationWhoisLookup<
    EntityFormat extends TObservableEntity = TObservableEntity,
    OET = TDNSWhoisObservableEntityType
> extends IObservationHeader<ObservationType.WhoisLookup> {
    type: ObservationType.WhoisLookup;
    content: IObservationWhoisLookupContent<EntityFormat, OET>;
}

export interface IObservationWhoisLookupContent<
    EntityFormat extends TObservableEntity = TObservableEntity,
    OET = TDNSWhoisObservableEntityType
> {
    entity: TGetEntityType<EntityFormat, OET>;
    status: WhoisStatus;
    data: TWhoisLookupData<EntityFormat, OET> | null;
    rawDataUUID: string;
}

export type TWhoisLookupData<
    EntityFormat extends TObservableEntity = TObservableEntity,
    OET = TDNSWhoisObservableEntityType
> = OET extends ObservableEntityType.DomainName
    ? IDomainNameWhoisLookupData<EntityFormat>
    : OET extends ObservableEntityType.IPAddress
    ? IIpWhoisLookupData
    : IIpWhoisLookupData | IDomainNameWhoisLookupData<EntityFormat>;

export interface IDomainNameWhoisLookupData<EntityFormat extends TObservableEntity = TObservableEntity>
    extends IWhoisLookupCommonFields {
    status: string[];
    domainName: TGetEntityType<EntityFormat, ObservableEntityType.DomainName>;
    domainNameID: string;
    registrarContact: IWhoisContact;
    adminContacts: IWhoisContact[];
    technicalContacts: IWhoisContact[];
    nameservers: TGetEntityType<EntityFormat, ObservableEntityType.DomainName | ObservableEntityType.IPAddress>[];
}

export interface IIpWhoisLookupData extends IWhoisLookupCommonFields {
    status: string;
    asn: number;
    regionalInternetRegistry: RegionalInternetRegistry;
}

export interface IWhoisLookupCommonFields {
    registeredAt: string;
    updatedAt: string;
    expiresAt: string;
    registrantContacts: IWhoisContact[];
}

export interface IWhoisContact {
    identity?: TObservableEntity<ObservableEntityType.Identity>;
    name?: string;
    organization?: string;
    address?: string;
    url?: string;
    city?: string;
    zip?: string;
    state?: string;
    country?: string;
    countryCode?: string;
    phone?: string;
    fax?: string;
    email?: string;
    registeredAt?: string;
    updatedAt?: string;
}

export enum WhoisStatus {
    OK = 'OK',
    NotFound = 'NotFound'
}
