<mc-navbar *transloco="let tCommon; read: 'common'">
    <mc-navbar-container position="left" class="flex">
        <mc-navbar-brand data-e2e-id="Main.Top.PTCybsi"
                         (click)="redirectToFirstAvailablePage()">
            <mc-navbar-logo>
                <img src="/assets/branding/images/logo.svg" width="32" height="32">
            </mc-navbar-logo>
            <mc-navbar-title>{{ tCommon('Common.Pseudo.Text.AppName') }}</mc-navbar-title>
        </mc-navbar-brand>
        <div class="layout-row flex" #menuItemsContainer *ngIf="isMenuItemsVisible$ | async">
            <div #collapsibleMenuItemsContainer class="layout-row">
                <ng-container *ngFor="let menuItemConfig of menuItemsConfig | keyvalue:sortMenuItems">
                    <a *hasPermissions="menuItemConfig.value.permissions"
                       id="menu-item-{{menuItemConfig.key}}"
                       mc-navbar-item
                       [routerLink]="menuItemConfig.value.routerLink"
                       routerLinkActive="mc-active"
                       data-e2e-id="{{menuItemConfig.value.e2eId}}">
                        {{ menuItemConfig.value.text }}
                    </a>
                </ng-container>
            </div>

            <mc-navbar-item
                id="more-navbar-items"
                #collapsedMenuItemsTrigger="mcDropdownTrigger"
                [mcDropdownTriggerFor]="collapsedMenuItemsDropdown"
                [mcDropdownTriggerData]="{ collapsedMenuItems: collapsedMenuItems }"
                [class.mc-active]="collapsedMenuItemsTrigger.opened || isActiveMenuItemCollapsed"
                [class.hidden]="collapsedMenuItems.length === 0">
                <mc-navbar-title>{{ tCommon('Common.Pseudo.Text.More') }}</mc-navbar-title>
            </mc-navbar-item>

            <mc-dropdown #collapsedMenuItemsDropdown xPosition="before" hasBackdrop="true">
                <ng-template mcDropdownContent let-collapsedMenuItems="collapsedMenuItems">
                    <a *ngFor="let menuItem of collapsedMenuItems"
                       mc-dropdown-item
                       routerLink="{{ menuItemsConfig[menuItem.id].routerLink }}"
                       routerLinkActive="mc-selected"
                       class="dropdown-link"
                       data-e2e-id="{{ menuItemsConfig[menuItem.id].e2eId }}">
                        {{ menuItemsConfig[menuItem.id].text }}
                    </a>
                </ng-template>
            </mc-dropdown>
        </div>
    </mc-navbar-container>
    <mc-navbar-container *ngIf="hasNotToken$ | async" position="right">
        <mc-navbar-item
            #languageDropdownTrigger="mcDropdownTrigger"
            class="layout-row layout-align-start-center"
            [mcDropdownTriggerFor]="languageDropdown"
            [class.mc-active]="languageDropdownTrigger.opened"
            data-e2e-id="Main.Top.Language">
            <mc-navbar-title>
                <i mc-icon class="pt-icons pt-icons-asset-website_16 margin-right_4"></i>
                <span>{{ tCommon('Common.Language.Text.' + currentLocale) }}</span>
            </mc-navbar-title>
        </mc-navbar-item>
    </mc-navbar-container>
    <mc-navbar-container *ngIf="hasToken$ | async" position="right">
        <mc-navbar-item
            #systemInfoDropdownTrigger="mcDropdownTrigger"
            class="layout-row layout-align-start-center"
            [mcDropdownTriggerFor]="systemInfoDropdown"
            [class.mc-active]="systemInfoDropdownTrigger.opened || isSystemRouteActive"
            data-e2e-id="Main.Top.Reference">
            <i mc-icon class="pt-icons pt-icons-question-circle_16"></i>
        </mc-navbar-item>
        <mc-navbar-item
            #userActionsDropdownTrigger="mcDropdownTrigger"
            class="layout-row layout-align-start-center"
            [mcDropdownTriggerFor]="userActionsDropdown"
            [class.mc-active]="userActionsDropdownTrigger.opened || isAccountRouteActive"
            data-e2e-id="Main.Top.Profile">
            <i mc-icon class="pt-icons pt-icons-user_16"></i>
        </mc-navbar-item>
    </mc-navbar-container>
</mc-navbar>

<mc-dropdown #languageDropdown xPosition="before" hasBackdrop="true">
    <div class="language-dropdown" *transloco="let tCommon; read: 'common'">
        <div class="mc-dropdown__group-header">{{ tCommon('Common.NavBar.DropdownGroupHeader.Language') }}</div>
        <button *ngFor="let locale of availableLocales"
                mc-dropdown-item
                class="language-dropdown-item"
                [class.language-selected]="locale === currentLocale"
                (click)="changeLocale(locale)"
                attr.data-e2e-id="Main.Top.Language.{{ locale }}">
            <i *ngIf="locale === currentLocale"
               mc-icon="mc-circle-8_16">
            </i>
            <span>{{ tCommon('Common.Language.Text.' + locale) }}</span>
        </button>
    </div>
</mc-dropdown>

<mc-dropdown #userActionsDropdown xPosition="before" hasBackdrop="true">
    <div class="user-dropdown" *transloco="let tCommon; read: 'common'">
        <a class="dropdown-link" mc-dropdown-item
           routerLink="{{ viewAccountRoute }}"
           routerLinkActive="mc-selected">
            <div class="title-item-dropdown">
                <i mc-icon class="pt-icons pt-icons-user_16"></i>
                <div class="title-dropdown pt-text-overflow">{{ userTitle$ | async }}</div>
            </div>
            <div *ngIf="userSubTitle$ | async as userSubTitle" class="caption-dropdown mc-dropdown-item__caption" data-e2e-id="Main.Top.Profile.UserName">
                <div class="pt-text-overflow" pt-title>
                    {{ userSubTitle }}
                </div>
            </div>
        </a>
        <mc-divider></mc-divider>
        <div mc-dropdown-item [mcDropdownTriggerFor]="languageItems">
            <div class="title-item-dropdown">
                <i mc-icon class="pt-icons pt-icons-asset-website_16"></i>
                <div>{{ tCommon('Common.NavBar.DropdownGroupHeader.Language') }}</div>
            </div>
            <div class="caption-dropdown mc-dropdown-item__caption" data-e2e-id="Main.Top.Profile.Language">
                <div class="pt-text-overflow" pt-title>
                    <span>{{ tCommon('Common.Language.Text.' + currentLocale) }}</span>
                </div>
            </div>
        </div>
        <mc-divider></mc-divider>
        <div mc-dropdown-item (click)="openSignOutModal()">
            <div class="title-item-dropdown">
                <i mc-icon class="pt-icons pt-icons-export_16"></i>
                <div class="mc-dropdown__group-header_small"
                     data-e2e-id="Main.Top.Profile.SignOut">
                    {{ tCommon('Common.NavBar.DropdownAction.SignOut') }}
                </div>
            </div>
        </div>
    </div>
</mc-dropdown>

<mc-dropdown #languageItems="mcDropdown">
    <div class="user-dropdown" *transloco="let tCommon; read: 'common'">
        <button *ngFor="let locale of availableLocales"
                mc-dropdown-item
                class="language-dropdown-item"
                [class.language-selected]="locale === currentLocale"
                (click)="changeLocale(locale)"
                attr.data-e2e-id="Main.Top.Profile.Language.{{ locale }}">
            <i *ngIf="locale === currentLocale"
               mc-icon="mc-circle-8_16">
            </i>
            <span>{{ tCommon('Common.Language.Text.' + locale) }}</span>
        </button>
    </div>
</mc-dropdown>

<mc-dropdown #systemInfoDropdown xPosition="before" hasBackdrop="true">
    <div *ngIf="versions">
        <div class="system-info-dropdown" *transloco="let tSystem; read: 'system'">
            <div class="mc-dropdown__group-header layout-row layout-align-space-between-center">
                <span class="mc-body_strong">{{ tSystem('System.SystemInfo.Text.ProductName') }}</span>
                <span *ngIf="versions.system; else failedFetchSystemVersion"
                      class="mc-body text-disabled">
                    {{ versions.system }}
                </span>
                <ng-template #failedFetchSystemVersion>
                    <span
                        class="system-info-error-icon mc-icon_light"
                        mc-icon="mc-error_16"
                        mcTooltip="{{ tSystem('System.SystemInfo.Text.FailedFetchVersion') }}"
                        mcPlacement="bottom">
                    </span>
                </ng-template>
            </div>
            <mc-divider></mc-divider>
            <button mc-dropdown-item (click)="openSystemInfoModal()">{{ tSystem('System.SystemInfo.Text.About') }}</button>
            <a mc-dropdown-item
               class="dropdown-link"
               [routerLink]="licenseRoute"
               routerLinkActive="mc-selected">
                <div class="layout-row layout-align-space-between-center">
                    <span class="layout-margin-right-s">{{ 'common.Common.Pseudo.Text.License' | transloco }}</span>
                    <license-view-state *ngIf="isLicenseViewStateVisible$ | async" [license]="licenseView$ | async"></license-view-state>
                </div>
            </a>
            <a *ngIf="hasReleaseNotes$ | async"
               mc-dropdown-item
               class="dropdown-link layout-row layout-align-space-between-center"
               [href]="releaseNotesUrl"
               target="_blank">
                <div class="layout-row layout-align-space-between-center">
                    <span>{{ tSystem('System.SystemInfo.Text.ReleaseNotes') }}</span>
                    <span class="dropdown-file-badge mc-badge mc-badge_default mc-badge_solid mc-badge_transparent mc-badge_caps margin-left_16">{{'common.Common.Pseudo.Text.PDF' | transloco}}</span>
                </div>
            </a>
            <a *ngIf="hasUserGuide$ | async"
               mc-dropdown-item
               class="dropdown-link layout-row layout-align-space-between-center"
               [href]="userGuideUrl"
               target="_blank">
                <div class="layout-row layout-align-space-between-center">
                    <span>{{ tSystem('System.SystemInfo.Text.UserGuide') }}</span>
                    <span class="dropdown-file-badge mc-badge mc-badge_default mc-badge_solid mc-badge_transparent mc-badge_caps margin-left_16">{{'common.Common.Pseudo.Text.PDF' | transloco}}</span>
                </div>
            </a>
        </div>
    </div>
</mc-dropdown>
