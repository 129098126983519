import { Injectable } from '@angular/core';
import find from 'lodash/find';

import { ApiResource, PermissionType } from '@pt-cybsi/api-interfaces';
import { PermissionsParserService } from '@pt-cybsi/shared';
import { AccountFacade } from '@pt-cybsi/store/account';

import { MAIN_ROUTE_PERMISSIONS, NAVBAR_ROUTE_URLS_MAP, TPermissionRoute } from '../helpers';

export interface IPermission {
    resource: ApiResource;
    types: PermissionType[];
}

@Injectable({
    providedIn: 'root'
})
export class PermissionsService implements IPermissionsService {
    parsedUserPermissions: IPermission[] = [];

    constructor(private accountFacade: AccountFacade) {
        this.accountFacade.user$.subscribe((user) => {
            this.parsedUserPermissions = user?.permissions?.length
                ? PermissionsParserService.parsePermissions(user.permissions)
                : [];
        });
    }

    hasAllPermissions(permissions: string[]): boolean {
        return permissions.every((permission) => this.hasPermission(permission));
    }

    hasAnyPermissions(permissions: string[] | string[][]): boolean {
        return permissions.some((permission) =>
            Array.isArray(permission)
                ? this.hasAllPermissions(permission as string[])
                : this.hasPermission(permission as string)
        );
    }

    hasPermission(permission: string): boolean {
        const parsedPermission: IPermission = PermissionsParserService.parse(permission);

        return this.parsedUserPermissions.some((parsedAvailablePermission) => {
            const isResourceAvailable: boolean = parsedPermission.resource === parsedAvailablePermission.resource;
            const hasPermissionTypes: boolean = parsedPermission.types.every(
                (checkedType) => parsedAvailablePermission.types.indexOf(checkedType) >= 0
            );

            return isResourceAvailable && hasPermissionTypes;
        });
    }

    getFirstAvailableUrl(): string {
        return find(NAVBAR_ROUTE_URLS_MAP, (url, routeName) =>
            this.hasAllPermissions(MAIN_ROUTE_PERMISSIONS[routeName as TPermissionRoute])
        );
    }
}

export interface IPermissionsService {
    hasAllPermissions(permissions: string[]): boolean;

    hasAnyPermissions(permissions: string[]): boolean;

    hasPermission(permission: string): boolean;
}
