export enum FileSizeUnit {
    B = 'B',
    KB = 'KB',
    MB = 'MB',
    GB = 'GB',
    TB = 'TB',
    PB = 'PB'
}

const units = Object.keys(FileSizeUnit) as FileSizeUnit[];

export interface IFileSize {
    value: number;
    unit: FileSizeUnit;
}

interface INumberToFileSizeOptions {
    unit?: FileSizeUnit;
    units?: FileSizeUnit[];
}

const DEAFULT_NUMBER_TO_FILE_SIZE_OPTIONS: INumberToFileSizeOptions = {
    unit: FileSizeUnit.B,
    units
};

export const convertBytesToFileSize = (
    bytes: number,
    options: INumberToFileSizeOptions = DEAFULT_NUMBER_TO_FILE_SIZE_OPTIONS
): IFileSize => {
    const convertingOptions = {
        ...DEAFULT_NUMBER_TO_FILE_SIZE_OPTIONS,
        ...options,
        units: options.units?.length > 0 ? options.units : DEAFULT_NUMBER_TO_FILE_SIZE_OPTIONS.units
    };

    const unit = convertingOptions.unit || findMaxFileSizeUnit(bytes, convertingOptions.units);

    return {
        unit,
        value: bytes / getMinBytesOfFileSizeUnit(unit)
    };
};

const COUNT_OF_BYTES_IN_NEXT_RANK = 1000;

export const getFileSizeUnitIndex = (unit: FileSizeUnit): number => units.findIndex((u) => u === unit);

export const getMinBytesOfFileSizeUnit = (unit: FileSizeUnit): number => {
    const unitIndex = getFileSizeUnitIndex(unit);

    return Math.pow(COUNT_OF_BYTES_IN_NEXT_RANK, unitIndex);
};

export const findMaxFileSizeUnit = (bytes: number, unitsRange: FileSizeUnit[] = units): FileSizeUnit => {
    let result = FileSizeUnit.B;

    if (bytes === 0) {
        return result;
    }

    const sortedRanges = unitsRange === units ? units : units.filter((defaultUnit) => unitsRange.includes(defaultUnit));

    for (const unit of sortedRanges) {
        const unitIndex = sortedRanges.findIndex((u) => u === unit);

        const currentUnit = sortedRanges[unitIndex];
        const nextUnit = sortedRanges[unitIndex + 1];

        const minCurrentUnitValue = getMinBytesOfFileSizeUnit(currentUnit);
        const minNextUnitValue = getMinBytesOfFileSizeUnit(nextUnit);

        const isValueInCurrentUnitRange = bytes >= minCurrentUnitValue && bytes < minNextUnitValue;

        if (isValueInCurrentUnitRange || !nextUnit) {
            result = currentUnit;
            break;
        }
    }

    return result;
};
