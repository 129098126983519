import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { DataSourcesApiService, IFullListResponse } from '@pt-cybsi/api';
import { IDataSource, IDataSourcesGetParams } from '@pt-cybsi/api-interfaces';
import { PermissionsService } from '@pt-cybsi/domain-core/account';
import { DataSourcesFacade } from '@pt-cybsi/store/data-sources';

import { SourcesPermissionsService } from './sources-permissions.service';

/**
 *   Service for synchronizing sources in store.
 *   Use it whenever you need to get sources instead of DataSourcesApiService.
 */
@Injectable({ providedIn: 'root' })
export class SourcesSyncService {
    constructor(
        private dataSourcesApiService: DataSourcesApiService,
        private dataSourcesFacade: DataSourcesFacade,
        private permissionsService: PermissionsService
    ) {}

    /**
     * @description Synchronize given sources with sources store
     */
    syncSources(sources: IDataSource[]) {
        const notEmptySources = (sources || []).filter(Boolean);

        this.dataSourcesFacade.syncDataSources(notEmptySources);
    }

    /**
     * @description Synchronize sources getting by params from the server with sources store
     * @return Observable with ids of synced sources
     */
    loadAndSyncSources(params: IDataSourcesGetParams = {}): Observable<string[]> {
        if (this.permissionsService.hasAllPermissions(SourcesPermissionsService.viewPermissions)) {
            return this.dataSourcesApiService.getAllSources(params).pipe(
                map((dataSourcesResponse: IFullListResponse<IDataSource>) => dataSourcesResponse.fullList),
                tap((sources) => this.syncSources(sources)),
                map((sources) => sources.map((source) => source.uuid))
            );
        }

        return of([] as string[]);
    }
}
