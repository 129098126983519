import { Injectable } from '@angular/core';
import flatten from 'lodash/flatten';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    IPaginationResponse,
    IRelationForecast,
    IRelationForecastRequestParams,
    IRelationLink,
    IRelationsRequestParams,
    IRelationStatistic,
    RelationType
} from '@pt-cybsi/api-interfaces';

import { BaseApiService } from './base-api.service';

@Injectable()
export class ObservableEntitiesRelationsApiService extends BaseApiService {
    static readonly urls = {
        links: (entityId: string): string => `observable/entities/${entityId}/links`,

        forecast: (sourceEntityId: string, relationKind: RelationType, targetEntityId: string): string =>
            `observable/relationships/${sourceEntityId}/${relationKind}/${targetEntityId}`,

        statistic: (entityId: string): string => `observable/entities/${entityId}/link-type-statistic`
    };

    getRelations(entityId: string, params: IRelationsRequestParams): Observable<IPaginationResponse<IRelationLink[]>> {
        const url = ObservableEntitiesRelationsApiService.urls.links(entityId);

        return this.getPaginatedCollection<IRelationLink[]>(url, { params });
    }

    getRelationsFullList(entityId: string, params: IRelationsRequestParams = {}): Observable<IRelationLink[]> {
        const url = ObservableEntitiesRelationsApiService.urls.links(entityId);

        return this.getFullList<IRelationLink>(url, { params }).pipe(map(({ fullList }) => fullList));
    }

    getEntitiesRelationsMap(params: IRelationsMapRequestParams[]): Observable<IEntitiesRelationsMap> {
        return forkJoin(
            params.map(({ entityId, relationsParams }) => this.getEntityRelationsMap(entityId, relationsParams))
        ).pipe(
            map(
                (entitiesRelationsLinksArray) =>
                    Object.assign({}, ...entitiesRelationsLinksArray) as IEntitiesRelationsMap
            )
        );
    }

    getForecast(
        sourceEntityId: string,
        relationKind: RelationType,
        targetEntityId: string,
        params: IRelationForecastRequestParams = {}
    ): Observable<IRelationForecast> {
        const url = ObservableEntitiesRelationsApiService.urls.forecast(sourceEntityId, relationKind, targetEntityId);

        return this.get<IRelationForecast>(url, this.addResolveRefsParam({ params }));
    }

    getStatistic(entityId: string): Observable<IRelationStatistic[]> {
        const url = ObservableEntitiesRelationsApiService.urls.statistic(entityId);

        return this.get<IRelationStatistic[]>(url);
    }

    private getEntityRelationsMap(
        entityId: string,
        relationsParams: IRelationsRequestParams[]
    ): Observable<IEntitiesRelationsMap> {
        return forkJoin(
            relationsParams.map((relationParams) => this.getRelationsFullList(entityId, relationParams))
        ).pipe(map((entityRelationsLinks) => ({ [entityId]: flatten(entityRelationsLinks) })));
    }
}

export interface IEntitiesRelationsMap {
    [entityId: string]: IRelationLink[];
}

export interface IRelationsMapRequestParams {
    entityId: string;
    relationsParams: IRelationsRequestParams[];
}
