import { Injectable, Provider } from '@angular/core';

import { EnrichmentTriggerType } from '@pt-cybsi/api-interfaces';
import { IUseCase } from '@pt-cybsi/shared';

import {
    EnrichmentRuleFormRepository,
    EnrichmentRuleFormRepositoryProvider,
    IEnrichmentRuleFormRepository
} from '../../repositories';

export interface IChangeEnrichmentTriggersUseCaseParams {
    ruleId: string;
    triggers: EnrichmentTriggerType[];
}

@Injectable()
export class ChangeEnrichmentTriggersUseCase implements IUseCase<IChangeEnrichmentTriggersUseCaseParams> {
    constructor(private enrichmentRuleFormRepository: IEnrichmentRuleFormRepository) {}

    execute(params: IChangeEnrichmentTriggersUseCaseParams): void {
        const { ruleId, triggers } = params;

        const rule = this.enrichmentRuleFormRepository.getCurrentData(ruleId);

        const hasRegistrationTrigger = triggers.includes(EnrichmentTriggerType.OnRegistration);

        rule.props.triggers = triggers;
        rule.props.throttlingInterval = hasRegistrationTrigger ? rule.props.throttlingInterval : null;

        rule.props.triggerSources = hasRegistrationTrigger ? rule.props.triggerSources : [];

        if (hasRegistrationTrigger) {
            this.enrichmentRuleFormRepository.showControls(rule.uid, ['triggerSources', 'throttlingInterval']);
        } else {
            this.enrichmentRuleFormRepository.hideControls(rule.uid, ['triggerSources', 'throttlingInterval']);
        }

        this.enrichmentRuleFormRepository.updateCurrentData(rule);
    }
}

export const ChangeEnrichmentTriggersUseCaseProvider: Provider = [
    EnrichmentRuleFormRepositoryProvider,
    {
        provide: ChangeEnrichmentTriggersUseCase,
        useFactory: (enrichmentRuleFormRepository: EnrichmentRuleFormRepository) =>
            new ChangeEnrichmentTriggersUseCase(enrichmentRuleFormRepository),
        deps: [EnrichmentRuleFormRepository]
    }
];
