export interface IServerError {
    code: string;
    details?: unknown;
    message?: string;
    debugInfo?: { error: string; stackTrace: string[] };
}

export enum InternalServerErrorCodes {
    Unavailable = 502,
    Timeout = 504
}

export enum BadRequestErrorCodes {
    PayloadTooLarge = 413
}

export enum DefaultErrorCodes {
    UNAUTHORIZED = 'UNAUTHORIZED',
    UNKNOWN = 'UNKNOWN',
    VIEW_FORBIDDEN = 'VIEW_FORBIDDEN',
    ACTION_FORBIDDEN = 'ACTION_FORBIDDEN',
    EMPTY_PERMISSIONS = 'EMPTY_PERMISSIONS',
    NOT_FOUND = 'NOT_FOUND'
}

export enum AuthenticationErrorCodes {
    UserAlreadyLoggedIn = 'UserAlreadyLoggedIn', // статус от бекенда
    EmptyProviders = 'EmptyProviders', // статус от бекенда
    UnknownProvider = 'UnknownProvider', // статус от бекенда
    InvalidToken = 'InvalidToken', // статус от клиента
    ProvidersRequestFailure = 'ProvidersRequestFailure', // статус от клиента
    LicenseRequestFailure = 'LicenseRequestFailure', // статус от клиента
    BadSessionRequest = 'BadSessionRequest', // статус от клиента
    SignOut = 'SignOut', // статус от клиента
    Impossible = 'Impossible', // статус от клиента
    Default = 'Default' // статус от клиента
}

export enum FieldErrors {
    INVALID_VALUE = 'INVALID_VALUE',
    INVALID_VALUE_FORMAT = 'INVALID_VALUE_FORMAT',
    INVALID_VALUE_LENGTH = 'INVALID_VALUE_LENGTH',
    EMPTY_VALUE = 'EMPTY_VALUE',
    UNALLOWED_VALUE = 'UNALLOWED_VALUE',
    UUID_MALFORMED = 'UUID_MALFORMED'
}
