<div class="loader layout-column layout-align-center-center flex" *ngIf="loadingFlag">
    <div class="loader loader-backdrop">
        <ng-content select="[progress-spinner]"></ng-content>
    </div>
    <div class="flex layout-column layout-align-center-center">
        <mc-progress-spinner
            class="loader-spinner"
            *ngIf="!progressSpinner"
            mode="indeterminate"
            color="primary">
        </mc-progress-spinner>
        <div class="margin-top_10" *ngIf="message">
            {{message}}
        </div>
    </div>
</div>

<ng-content select=":not([progress-spinner]"></ng-content>
