import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AuthApiService, UsersApiService } from '@pt-cybsi/api';
import {
    AuthenticationErrorCodes,
    AuthState,
    HttpStatusCode,
    IAuthProvider,
    IAuthSession,
    IUserFullInfo
} from '@pt-cybsi/api-interfaces';
import { LocationService } from '@pt-cybsi/core';

import * as AccountActions from './account.actions';
import { AccountFacade } from './account.facade';

export interface IRefreshTokenError {
    authState: AuthState;
}

@Injectable()
export class AccountEffects {
    refreshToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.refreshToken),
            switchMap(() =>
                this.authApiService.getSession().pipe(
                    map((response: IAuthSession) => {
                        const { state } = response;

                        if (state === AuthState.Authenticated) {
                            return AccountActions.setToken({ token: response.token.accessToken });
                        }

                        const refreshTokenError: IRefreshTokenError = { authState: state };

                        throw new HttpErrorResponse({
                            status: HttpStatusCode.OK,
                            error: refreshTokenError
                        });
                    }),
                    catchError((response: HttpErrorResponse) => of(AccountActions.setRefreshTokenError({ response })))
                )
            )
        )
    );

    setRefreshTokenError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AccountActions.setRefreshTokenError),
                withLatestFrom(this.accountFacade.isSessionExpired$),
                tap(([action, isSessionExpired]) => {
                    const responseStatus = action.response.status;

                    switch (responseStatus) {
                        case HttpStatusCode.OK:
                        case HttpStatusCode.NOT_FOUND:
                            if (isSessionExpired) {
                                this.locationService.redirectToSignInPage();
                            } else {
                                this.locationService.redirectToErrorPage(AuthenticationErrorCodes.Default);
                            }

                            break;

                        case HttpStatusCode.BAD_REQUEST:
                            this.locationService.redirectToErrorPage(AuthenticationErrorCodes.BadSessionRequest);
                            break;

                        case HttpStatusCode.INTERNAL_SERVER_ERROR:
                        case HttpStatusCode.BAD_GATEWAY:
                        default:
                            this.locationService.redirectToErrorPage(AuthenticationErrorCodes.Default);
                    }
                })
            ),
        { dispatch: false }
    );

    loadUserInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.fetchUser),
            switchMap(() =>
                this.usersApiService.getCurrentUser().pipe(
                    map((response: IUserFullInfo) => AccountActions.fetchUserSuccess({ user: response })),
                    catchError(() => {
                        this.locationService.redirectToErrorPage(AuthenticationErrorCodes.Default);

                        return of(AccountActions.fetchUserFailure());
                    })
                )
            )
        )
    );

    loadAuthProviders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AccountActions.fetchAuthProviders),
            switchMap(() =>
                this.authApiService.getProviders().pipe(
                    map((response: IAuthProvider[]) =>
                        AccountActions.fetchAuthProvidersSuccess({ providers: response })
                    ),
                    catchError(() => of(AccountActions.fetchAuthProvidersFailure()))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private accountFacade: AccountFacade,
        private authApiService: AuthApiService,
        private usersApiService: UsersApiService,
        private locationService: LocationService
    ) {}
}
