<lazy-grid
    [agGridOptions]="agGridOptions"
    [columns]="columns"
    [data]="enrichmentRules"
    [state]="state"
    [errorTitle]="'enrichment.Enrichment.Pseudo.Text.ErrorLoadingEnrichmentRulesTitle' | transloco"
    (loadMore)="loadMore.emit()"
></lazy-grid>

<ng-template #ruleCell let-data="params.value">
    <div class="enrichment-rules-grid__rule-cell">
        <a mc-link pt-title [routerLink]="getEnrichmentRuleLink(data.id)"
           class="enrichment-rules-grid__rule-link mc-link_overflowed margin-right_8">
            <span pt-title class="mc-link__text">{{ data.name }}</span>
        </a>
        <status-badge
            *ngIf="data.isDisabled"
            statusTranslationKey='enrichment.Enrichment.Pseudo.Tag.Disabled'
            class="enrichment-rules-grid__rule-status">
        </status-badge>
    </div>
</ng-template>

<ng-template #enrichmentTypeCell let-data="params.value">
    <div *ngIf="data" pt-title class="pt-text-overflow">{{ data | translateEnrichmentType }}</div>
</ng-template>

<ng-template #typesCell let-data="params.value">
    <div *ngIf="data.artifactTypes" pt-title class="pt-text-overflow">
        {{ data.artifactTypes | translateArtifactTypes | arrayAsString }}
    </div>
    <div *ngIf="data.entityTypes" pt-title class="pt-text-overflow">
        {{ data.entityTypes | translateObservableEntityTypes | arrayAsString }}
    </div>
</ng-template>

<ng-template #triggerTypeCell let-data="params.value">
    <div *ngIf="data" pt-title class="pt-text-overflow">
        {{ data | translateEnrichmentTriggerTypes | arrayAsString }}
    </div>
</ng-template>

<ng-template #sourcesCell let-sources="params.value">
    <limited-collection
        class="enrichment-rules-grid__sources"
        [renderStyle]="sourcesCollectionStyle"
        [limit]="1"
        [itemTemplate]="sourceTemplate"
        [collection]="sources"
        [collectionTitle]="'enrichment.Enrichment.SidePanel.Title.CountSources' | transloco: {count: sources.length}"
        [collectionItemSeparator]="">
    </limited-collection>
</ng-template>

<ng-template #triggerSourcesCell let-sources="params.value">

    <limited-collection
        class="enrichment-rules-grid__sources"
        [renderStyle]="sourcesCollectionStyle"
        [limit]="1"
        [itemTemplate]="sourceTemplate"
        [collection]="sources"
        [collectionTitle]="'enrichment.Enrichment.SidePanel.Title.CountTriggerSources' | transloco: {count: sources.length}"
        [collectionItemSeparator]="">
    </limited-collection>
</ng-template>

<ng-template #sourceTemplate let-sourceId="item">
    <source-preview [sourceId]="sourceId"></source-preview>
</ng-template>
