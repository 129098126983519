import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { McPseudoCheckboxModule } from '@ptsecurity/mosaic/core';
import { McDatepickerModule } from '@ptsecurity/mosaic/datepicker';
import { McFormFieldModule } from '@ptsecurity/mosaic/form-field';
import { McInputModule } from '@ptsecurity/mosaic/input';
import { McModalModule } from '@ptsecurity/mosaic/modal';
import { McProgressSpinnerModule } from '@ptsecurity/mosaic/progress-spinner';
import { McSelectModule } from '@ptsecurity/mosaic/select';
import { McSidepanelModule } from '@ptsecurity/mosaic/sidepanel';
import { McTableModule } from '@ptsecurity/mosaic/table';
import { McTagsModule } from '@ptsecurity/mosaic/tags';
import { McTimepickerModule } from '@ptsecurity/mosaic/timepicker';
import { McToggleModule } from '@ptsecurity/mosaic/toggle';
import { McTreeModule } from '@ptsecurity/mosaic/tree';
import { McTreeSelectModule } from '@ptsecurity/mosaic/tree-select';

import { DomainCoreAccountModule } from '@pt-cybsi/domain-core/account';
import { DomainCoreSourcesModule } from '@pt-cybsi/domain-core/sources';
import { SharedModule } from '@pt-cybsi/shared';

import {
    ApiKeyFormComponent,
    UserFormComponent,
    UserPreviewComponent,
    PasswordFormComponent,
    CurrentUserFormComponent,
    CreateApiKeyModalComponent,
    EditApiKeyModalComponent,
    PasswordModalComponent,
    UserApiKeysTableComponent,
    UserBodyComponent,
    PermissionsSidepanelComponent
} from './components';
import { UserApiKeysComponent } from './containers';
import { TranslateRolePipe, TranslateRolesPipe, TranslatePermissionPipe, TranslatePermissionsPipe } from './pipes';
import { UsersNavigationService } from './services';

const modules = [
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    McFormFieldModule,
    McInputModule,
    McSelectModule,
    McToggleModule,
    McDatepickerModule,
    McTimepickerModule,
    McTreeModule,
    McTreeSelectModule,
    McTreeModule,
    McSidepanelModule,
    McPseudoCheckboxModule,
    McTagsModule,
    McTableModule,
    McModalModule,
    DomainCoreSourcesModule,
    DomainCoreAccountModule,
    McProgressSpinnerModule
];

const components = [
    ApiKeyFormComponent,
    UserFormComponent,
    UserPreviewComponent,
    PasswordFormComponent,
    CurrentUserFormComponent,
    CreateApiKeyModalComponent,
    EditApiKeyModalComponent,
    PasswordModalComponent,
    UserApiKeysTableComponent,
    UserBodyComponent,
    PermissionsSidepanelComponent
];

// prettier-ignore
const containers = [
    UserApiKeysComponent
];

// prettier-ignore
const services = [
    UsersNavigationService
];

// prettier-ignore
const pipes = [
    TranslateRolePipe,
    TranslateRolesPipe,
    TranslatePermissionPipe,
    TranslatePermissionsPipe
];

@NgModule({
    imports: [...modules],
    declarations: [...components, ...containers, ...pipes],
    providers: [...services, ...pipes],
    exports: [...components, ...containers, ...pipes]
})
export class DomainCoreUsersModule {}
