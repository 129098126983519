import { IApiKey } from '@pt-cybsi/api-interfaces';

import { parsePermissions } from '../helpers';
import { ITableApiKeyData } from '../types';

export class TableApiKeyDataMapper {
    static fromApiKeys(apiKeys: IApiKey[]): ITableApiKeyData[] {
        return apiKeys.map(TableApiKeyDataMapper.fromApiKey);
    }

    static fromApiKey(apiKey: IApiKey): ITableApiKeyData {
        return {
            ...apiKey,
            permissions: apiKey.permissions ? parsePermissions(apiKey.permissions) : null,
            isExpired: apiKey.expiresAt ? isApiKeyExpired(apiKey.expiresAt) : false
        };
    }
}

const isApiKeyExpired = (date: string): boolean => new Date(date).getTime() < new Date().getTime();
