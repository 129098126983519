import { Directive, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[integerInput]'
})
export class IntegerInputDirective {
    constructor(@Self() public ngControl: NgControl) {}

    @HostListener('paste', ['$event'])
    handlePaste($event: ClipboardEvent): void {
        $event.preventDefault();

        const value = $event.clipboardData.getData('text');
        const parsedValue = parseInt(value.replace(/\D+/g, ''));

        this.ngControl.valueAccessor.writeValue(parsedValue);
    }

    @HostListener('keydown', ['$event'])
    handleKeyDown($event: KeyboardEvent): void {
        if ($event.key === '.' || $event.key === ',') {
            $event.preventDefault();
        }
    }
}
