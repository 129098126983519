import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import {
    createChangedControlValidator,
    createControlValidator,
    IFormViewModelBuilder,
    TFormViewModelProps
} from '@pt-cybsi/shared';

import { TSourceTypeFormData } from '../../mappers';
import {
    validateConfidenceAccuracy,
    validateConfidenceRange,
    validateSourceTypeNameLength,
    validateSourceTypeShortNameFormat,
    validateSourceTypeShortNameLength
} from '../../validators';

import { SourceTypeFormConfig, SourceTypeFormViewModel, TSourceTypeFormConfig } from './source-type-form.view-model';

/**
 * @factory SourceTypeFormBuilder
 *
 * @description
 * Provides a factory method to create SourceTypeFormViewModel.
 * Creates a reactive form of source type form and wrap it in FormViewModel.
 */
@Injectable()
export class SourceTypeFormBuilder implements IFormViewModelBuilder<TSourceTypeFormData, TSourceTypeFormConfig> {
    formConfig = SourceTypeFormConfig;

    constructor(private fb: UntypedFormBuilder) {}

    build(props: TFormViewModelProps<TSourceTypeFormData>): SourceTypeFormViewModel {
        const form = this.buildFormGroup();

        return new SourceTypeFormViewModel(props, form, this.formConfig);
    }

    private buildFormGroup(): UntypedFormGroup {
        const { id, eTag, serverId, name, shortName, confidence } = this.formConfig;

        const nameLengthValidator = createControlValidator(validateSourceTypeNameLength, name.errorNames.length);

        const shortNameLengthValidator = createControlValidator(
            validateSourceTypeShortNameLength,
            shortName.errorNames.length
        );

        const shortNameFormatValidator = createControlValidator(
            validateSourceTypeShortNameFormat,
            shortName.errorNames.format
        );

        const confidenceRangeValidator = createControlValidator(validateConfidenceRange, confidence.errorNames.range);

        const confidenceAccuracyValidator = createChangedControlValidator(
            validateConfidenceAccuracy,
            confidence.errorNames.accuracy
        );

        const required = Validators.required;

        return this.fb.group({
            [id.controlName]: [undefined],
            [serverId.controlName]: [undefined],
            [eTag.controlName]: [undefined],
            [name.controlName]: [
                undefined,
                {
                    validators: [required, nameLengthValidator]
                }
            ],
            [shortName.controlName]: [
                undefined,
                {
                    validators: [required, shortNameLengthValidator, shortNameFormatValidator]
                }
            ],
            [confidence.controlName]: [
                undefined,
                {
                    validators: [required, confidenceRangeValidator, confidenceAccuracyValidator]
                }
            ]
        });
    }
}
