import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import {
    AsyncValidationService,
    createControlValidator,
    IFormViewModelBuilder,
    TFormViewModelProps
} from '@pt-cybsi/shared';

import { TAnalyserFormData } from '../../mappers';
import {
    validateArtifactSizeLimit,
    validateTaskExecutionAttemptsCount,
    validateTaskExecutionTimeout
} from '../../validators';

import { AnalyserFormConfig, AnalyserFormViewModel, TAnalyserFormConfig } from './analyser-form.view-model';

/**
 * @factory AnalyserFormBuilder
 *
 * @description
 * Provides a factory method to create AnalyserFormViewModel.
 * Creates a reactive form of analyser form and wrap it in FormViewModel.
 */
@Injectable()
export class AnalyserFormBuilder implements IFormViewModelBuilder<TAnalyserFormData, TAnalyserFormConfig> {
    formConfig = AnalyserFormConfig;

    constructor(private fb: UntypedFormBuilder, private asyncValidationService: AsyncValidationService) {}

    build(props: TFormViewModelProps<TAnalyserFormData>): AnalyserFormViewModel {
        const form = this.buildFormGroup();

        return new AnalyserFormViewModel(props, form, this.formConfig, this.asyncValidationService);
    }

    private buildFormGroup(): UntypedFormGroup {
        const {
            id,
            sourceId,
            eTag,
            serverId,
            artifactTypes,
            artifactSizeLimit,
            dashboardURL,
            taskExecutionTimeout,
            taskExecutionAttemptsCount
        } = this.formConfig;

        const artifactSizeLimitValidator = createControlValidator(
            validateArtifactSizeLimit,
            artifactSizeLimit.errorNames.range
        );

        const taskExecutionTimeoutValidator = createControlValidator(
            validateTaskExecutionTimeout,
            taskExecutionTimeout.errorNames.range
        );

        const taskExecutionAttemptsCountValidator = createControlValidator(
            validateTaskExecutionAttemptsCount,
            taskExecutionAttemptsCount.errorNames.range
        );

        const required = Validators.required;

        return this.fb.group({
            [id.controlName]: [undefined],

            [sourceId.controlName]: [undefined],

            [serverId.controlName]: [undefined],

            [eTag.controlName]: [undefined],

            [artifactTypes.controlName]: [
                undefined,
                {
                    validators: [required]
                }
            ],

            [artifactSizeLimit.controlName]: [
                undefined,
                {
                    validators: [artifactSizeLimitValidator]
                }
            ],

            [dashboardURL.controlName]: [undefined],

            [taskExecutionTimeout.controlName]: [
                undefined,
                {
                    validators: [taskExecutionTimeoutValidator]
                }
            ],

            [taskExecutionAttemptsCount.controlName]: [
                undefined,
                {
                    validators: [taskExecutionAttemptsCountValidator]
                }
            ]
        });
    }
}
