export enum LazyDataState {
    Pending = 'Pending',
    Loading = 'Loading',
    Error = 'Error',
    Complete = 'Complete'
}

export class LazyDataStateModel {
    get currentState(): LazyDataState {
        return this.state;
    }

    constructor(private state: LazyDataState) {}

    static create(state?: LazyDataState): LazyDataStateModel {
        return new LazyDataStateModel(state || LazyDataState.Pending);
    }

    setLoadingState(): void {
        this.state = LazyDataState.Loading;
    }

    setCompleteState(isEndData: boolean): void {
        this.state = isEndData ? LazyDataState.Complete : LazyDataState.Pending;
    }

    setErrorState(): void {
        this.state = LazyDataState.Error;
    }

    resetState(): void {
        this.state = LazyDataState.Pending;
    }
}
