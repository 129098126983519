import { Injectable, Provider } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IServerError } from '@pt-cybsi/api-interfaces';
import { IFormRepository, FormStateValue, IObservableUseCase } from '@pt-cybsi/shared';

import { SourceTypeModel } from '../../models';
import { SourceTypeFormRepository, SourceTypeFormRepositoryProvider } from '../../repositories';

export interface IUpdateSourceTypeParams {
    id: string;
}

export type TUpdatedSourceTypeId = string;

@Injectable()
export class UpdateSourceTypeUseCase implements IObservableUseCase<IUpdateSourceTypeParams, TUpdatedSourceTypeId> {
    constructor(private repository: IFormRepository<SourceTypeModel>) {}

    execute(params: IUpdateSourceTypeParams): Observable<TUpdatedSourceTypeId> {
        const { id } = params;

        const sourceType = this.repository.getCurrentData(id);
        const currentFormState = this.repository.getState(id);

        if (currentFormState.value === FormStateValue.Saved) {
            return of(sourceType.props.serverId);
        }

        this.repository.resetSavingError(id);

        this.switchState(id, FormStateValue.Saving);

        const initialSourceType = this.repository.getInitialData(id);

        const sourceTypeWithChangedData = sourceType.diff(initialSourceType);

        return this.repository.save(sourceTypeWithChangedData).pipe(
            tap(() => {
                this.switchState(id, FormStateValue.Saved);

                this.repository.disable(id);
            }),
            catchError((error: unknown) => {
                this.switchState(id, FormStateValue.SavingFailure);

                this.repository.updateSavingError(id, error as IServerError);

                return throwError(error);
            })
        );
    }

    private switchState(id: string, state: FormStateValue): void {
        const formState = this.repository.getState(id);

        const nextFormState = formState.to(state);

        this.repository.updateState(id, nextFormState);
    }
}

export const UpdateSourceTypeUseCaseProvider: Provider = [
    SourceTypeFormRepositoryProvider,
    {
        provide: UpdateSourceTypeUseCase,
        useFactory: (repository: SourceTypeFormRepository) => new UpdateSourceTypeUseCase(repository),
        deps: [SourceTypeFormRepository]
    }
];
