import { Component, Input } from '@angular/core';
import { translate } from '@ngneat/transloco';

import { CurrentUserFormViewModel } from '@pt-cybsi/domain-core/users';
import { AVAILABLE_LOCALES } from '@pt-cybsi/i18n';

import { EMAIL_MAX_LENGTH, EMAIL_MIN_LENGTH, NAME_MAX_LENGTH } from '../../validators';

@Component({
    selector: 'current-user-form',
    templateUrl: './current-user-form.component.html',
    styleUrls: ['./current-user-form.component.scss']
})
export class CurrentUserFormComponent {
    @Input() form: CurrentUserFormViewModel;

    availableLocales = AVAILABLE_LOCALES;

    invalidFullNameLengthMessage = translate('common.Common.Validation.Text.StringLengthMax', {
        max: NAME_MAX_LENGTH
    });
    invalidEmailLengthMessage = translate('common.Common.Validation.Text.StringLengthBetween', {
        min: EMAIL_MIN_LENGTH,
        max: EMAIL_MAX_LENGTH
    });
}
