import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { McModalService } from '@ptsecurity/mosaic/modal';
import throttle from 'lodash/throttle';
import { combineLatest, merge } from 'rxjs';
import { delay, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';

import { IUserFullInfo } from '@pt-cybsi/api-interfaces';
import { PermissionsService } from '@pt-cybsi/domain-core/account';
import { AsyncState, DestroyService, InfinityScrollConsumerDirective, LazyDataState } from '@pt-cybsi/shared';

import { CreateApiKeyModalComponent, EditApiKeyModalComponent } from '../../components';
import { ApiKeysPermissionsService } from '../../services';
import { UserApiKeysStore } from '../../stores';
import { ITableApiKeyData } from '../../types';

@Component({
    selector: 'user-api-keys',
    templateUrl: './user-api-keys.component.html',
    providers: [DestroyService]
})
export class UserApiKeysComponent implements OnInit {
    @Input() user: IUserFullInfo;
    @Input() isCurrentUser: boolean;

    @ViewChild('userApiKeysTable', { read: ElementRef }) userApiKeysTable: ElementRef<HTMLElement>;

    loadingState$ = this.usersApiKeysStore.loadingState$;
    lazyLoadingState$ = this.usersApiKeysStore.lazyLoadingState$;

    isLoading$ = this.loadingState$.pipe(map((loadingState) => loadingState === AsyncState.Loading));
    isApiKeysTableVisible$ = combineLatest([this.loadingState$, this.usersApiKeysStore.data$]).pipe(
        map(([loadingState, data]) => loadingState === AsyncState.Success && data.length > 0)
    );
    isErrorLoadingVisible$ = this.loadingState$.pipe(map((loadingState) => loadingState === AsyncState.Failure));
    isLazyLoadingAvailable$ = combineLatest([this.loadingState$, this.lazyLoadingState$]).pipe(
        map(
            ([loadingState, lazyLoadingState]) =>
                loadingState === AsyncState.Success && lazyLoadingState === LazyDataState.Pending
        )
    );

    apiKeys$ = this.usersApiKeysStore.data$;

    autoLoadMore$ = merge(this.loadingState$, this.lazyLoadingState$);

    onLoadMore$ = merge(this.infinityScrollConsumer.infinityScroll, this.autoLoadMore$).pipe(
        // waiting render new table slice and after that check availability for load next slice
        delay(1),
        withLatestFrom(this.isLazyLoadingAvailable$),
        filter(([, isLazyLoadingAvailable]) => isLazyLoadingAvailable && this.infinityScrollConsumer.isInTriggerArea),
        takeUntil(this.destroyed$)
    );

    createApiKeyPermissions = this.permissionsService.hasAllPermissions(
        ApiKeysPermissionsService.createUserApiKeyPermissions
    );

    constructor(
        private infinityScrollConsumer: InfinityScrollConsumerDirective,
        private modalService: McModalService,
        private usersApiKeysStore: UserApiKeysStore,
        private permissionsService: PermissionsService,
        private destroyed$: DestroyService
    ) {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        this.handleLoadMore = throttle(this.handleLoadMore.bind(this), 100);
    }

    ngOnInit(): void {
        this.usersApiKeysStore.load({ userId: this.user.uuid });

        this.onLoadMore$.subscribe(() => {
            this.handleLoadMore();
        });
    }

    handleLoadMore(): void {
        this.usersApiKeysStore.loadNextPage({ userId: this.user.uuid });
    }

    openCreateApiKeyModal() {
        const modalRef = this.modalService.open<CreateApiKeyModalComponent>({
            mcComponent: CreateApiKeyModalComponent,
            mcComponentParams: {
                userId: this.user.uuid,
                userPermissions: this.user.permissions,
                isCurrentUser: this.isCurrentUser
            }
        });

        modalRef.afterClose.subscribe((needReload: boolean) => {
            if (needReload) {
                this.reload();
            }
        });
    }

    openEditKeyModal(apiKey: ITableApiKeyData) {
        const modalRef = this.modalService.open<EditApiKeyModalComponent>({
            mcComponent: EditApiKeyModalComponent,
            mcComponentParams: {
                apiKeyId: apiKey.id,
                isCurrentUser: this.isCurrentUser
            }
        });

        modalRef.afterClose.subscribe((needReload: boolean) => {
            if (needReload) {
                this.reload();
            }
        });
    }

    reload(): void {
        this.usersApiKeysStore.load({ userId: this.user.uuid });
    }
}
