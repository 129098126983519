import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    IApiItemPreview,
    IEditableResource,
    ISearchQuery,
    IQuerySaveBody,
    IQueryValidationResponse,
    QueryCompatibility
} from '@pt-cybsi/api-interfaces';

import { BaseApiService } from './base-api.service';

@Injectable()
export class StoredQueryApiService extends BaseApiService {
    static readonly urls = {
        storedQueries: 'search/stored-queries',
        validation: 'search/query'
    };

    getStoredQuery(uuid: string): Observable<IEditableResource<ISearchQuery>> {
        const url = `${StoredQueryApiService.urls.storedQueries}/${uuid}`;

        return this.getEditableResource(url, this.addResolveRefsParam());
    }

    validate(queryText: string, compatibility: QueryCompatibility): Observable<IQueryValidationResponse> {
        const url = StoredQueryApiService.urls.validation;

        return this.put<IQueryValidationResponse>(url, { text: queryText, compatibility });
    }

    createStoredQuery(params: IQuerySaveBody): Observable<IApiItemPreview> {
        const url = StoredQueryApiService.urls.storedQueries;

        return this.post<IApiItemPreview>(url, params);
    }

    changeStoredQuery(uuid: string, params: Partial<IQuerySaveBody>, eTag: string): Observable<void> {
        const url = `${StoredQueryApiService.urls.storedQueries}/${uuid}`;

        return this.updateResource(url, eTag, params);
    }
}
