import { createAction, props } from '@ngrx/store';

import { IFullListResponse } from '@pt-cybsi/api';
import { IDataSource, IPaginationParams } from '@pt-cybsi/api-interfaces';

export const fetchDataSources = createAction('[DataSources] Fetch DataSources', props<{ params: IPaginationParams }>());

export const fetchDataSourcesSuccess = createAction(
    '[DataSources] Fetch DataSources - Success',
    props<{ response: IFullListResponse<IDataSource> }>()
);

export const fetchDataSourcesFailure = createAction('[DataSources] Fetch DataSources - Failure');

export const syncDataSources = createAction('[DataSources] Sync DataSources', props<{ dataSources: IDataSource[] }>());
