import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EnrichmentType } from '@pt-cybsi/api-interfaces';
import { FiltersPanelBaseItem, IListFilterItem, AsyncState } from '@pt-cybsi/shared';

import { TranslateEnrichmentTypesPipe } from '../../pipes';

export type TEnrichmentTypesFilterItem = IListFilterItem<EnrichmentType>;

/**
 * @component Enrichment Types Filter
 *
 * @description
 * Used for filtering data by enrichment type. Can be put into a filters-panel component
 *
 * @param value - applied values of filter in `EnrichmentType[]` format
 * @param applyFilter - event, emitted after clicking apply filter button
 * @param resetFilter - event, emitted after clicking reset filter button
 * @param openFilter - event, emitted after opening filter
 * @param closeFilter - event, emitted after closing filter
 *
 * @example
 * ```html
 * <enrichment-types-filter
 *     [value]="value"
 *     (applyFilter)="handleApply($event)"
 *     (resetFilter)="handleReset()">
 *     (openFilter)="handleOpen()">
 *     (closeFilter)="handleClose()">
 * </enrichment-types-filter>
 * ```
 */
@Component({
    selector: 'enrichment-types-filter',
    templateUrl: './enrichment-types-filter.component.html',
    providers: [
        {
            provide: FiltersPanelBaseItem,
            useExisting: forwardRef(() => EnrichmentTypesFilterComponent)
        }
    ]
})
export class EnrichmentTypesFilterComponent extends FiltersPanelBaseItem implements OnInit, OnChanges {
    @Input() value: EnrichmentType[];

    @Output() applyFilter = new EventEmitter<EnrichmentType[]>();
    @Output() resetFilter = new EventEmitter<void>();
    @Output() openFilter = new EventEmitter<void>();
    @Output() closeFilter = new EventEmitter<void>();

    availableItems: TEnrichmentTypesFilterItem[];
    filterState: AsyncState = AsyncState.Success;

    appliedValues$ = new BehaviorSubject<TEnrichmentTypesFilterItem[]>([]);
    valueTitle$: Observable<string[]>;

    readonly title: string = this.translocoService.translate('enrichment.Enrichment.Pseudo.Text.Type');

    constructor(
        private translocoService: TranslocoService,
        private translateEnrichmentTypes: TranslateEnrichmentTypesPipe
    ) {
        super();
    }

    ngOnInit(): void {
        this.availableItems = this.getAvailableItems();
        this.valueTitle$ = this.getValueTitle$();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const isValueChanged = changes.value?.currentValue !== changes.value?.previousValue;

        if (isValueChanged) {
            this.appliedValues$.next(
                this.value.map((enrichmentType) => ({
                    value: enrichmentType,
                    data: enrichmentType
                }))
            );
        }
    }

    handleOpen(): void {
        this.openFilter.emit();
    }

    handleApply(value: TEnrichmentTypesFilterItem[]) {
        this.applyFilter.emit(value.map((enrichmentTypeItem) => enrichmentTypeItem.data));
    }

    handleReset() {
        this.resetFilter.emit();
    }

    reset(): void {
        this.resetFilter.emit();
    }

    private getAvailableItems(): TEnrichmentTypesFilterItem[] {
        return Object.values(EnrichmentType).map((type) => ({ value: type, data: type }));
    }

    private getValueTitle$(): Observable<string[]> {
        return this.appliedValues$.pipe(
            map((enrichmentTypes: TEnrichmentTypesFilterItem[]) => {
                if (!enrichmentTypes) {
                    return [];
                }

                return this.translateEnrichmentTypes.transform(
                    enrichmentTypes.map((enrichmentType) => enrichmentType.data)
                );
            })
        );
    }
}
