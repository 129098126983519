import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { LazyDataState } from '@pt-cybsi/shared';

import { ISourceTypesGridItem, TSourceTypesGridItemId } from '../source-types-grid/source-types-grid.component';

export interface ISourcesTreeGridToggleDetailsEvent {
    id: TSourceTypesGridItemId;
    expanded: boolean;
}

/**
 * @component SourcesTreeGrid
 *
 * @param state state of lazy-grid
 * @param sourceTypes list of source types for lazy-grid in a `ISourceTypesGridItem[]` format
 * @param expanded flag for expanding all rows after initialization
 * @param expandedTypes list of rows ids, that should be expanded
 * @param loadMore event triggered by lazy-grid, when grid scrollbar reaches end of grid and state is 'Pending'
 * @param toggleDetails event triggered by lazy-grid, when user expands a grouped row.
 * In args providing `$event` object with `id` a row and `expanded` flag.
 *
 * @description Is used for display grid of sources in tree format.
 * In details section displayed a list of sources.
 *
 * @example
 * Live examples can be viewed in Storybook
 *
 * Example of template:
 * ```html
 *  <sources-tree-grid
 *      [state]="state"
 *      [sourceTypes]="sourceTypes"
 *      [expandedTypes]="expandedTypes"
 *      (loadMore)="handleLoadMore()"
 *      (toggleDetails)="handleToggleDetails($event)"
 *  ></source-tree-grid>
 *  <ng-template #detailsTemplate let-triggers="triggers">
 *      <sources-tree-grid-details
 *          [state]="detailsState"
 *          [sources]="sources"
 *          [detailsTriggers]="triggers"
 *      ></sources-tree-grid-details>
 *  </ng-template>
 * ```
 */
@Component({
    selector: 'sources-tree-grid',
    templateUrl: './sources-tree-grid.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SourcesTreeGridComponent {
    @Input()
    state: LazyDataState;

    @Input()
    sourceTypes: ISourceTypesGridItem[];

    @Input()
    detailsTemplate: TemplateRef<unknown>;

    @Input()
    expandedTypes: TSourceTypesGridItemId[];

    @Input()
    expanded: boolean;

    @Output()
    loadMore = new EventEmitter<void>();

    @Output()
    toggleDetails = new EventEmitter<ISourcesTreeGridToggleDetailsEvent>();
}
