import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { EnrichmentType } from '@pt-cybsi/api-interfaces';
import { createASCSortComparator } from '@pt-cybsi/shared';

@Pipe({
    name: 'translateEnrichmentType'
})
export class TranslateEnrichmentTypePipe implements PipeTransform {
    constructor(private translocoService: TranslocoService) {}

    transform(type: EnrichmentType): string {
        return this.translocoService.translate(`enrichment.Enrichment.Type.Text.${type}`);
    }
}

@Pipe({
    name: 'translateEnrichmentTypes'
})
export class TranslateEnrichmentTypesPipe implements PipeTransform {
    private readonly sortComparator = createASCSortComparator<string>((item) => item);

    constructor(private translateEnrichmentType: TranslateEnrichmentTypePipe) {}

    transform(types: EnrichmentType[]): string[] {
        const translatedTypes = types.map((type) => this.translateEnrichmentType.transform(type));

        return translatedTypes.sort(this.sortComparator);
    }
}
