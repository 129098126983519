import uniqueId from 'lodash/uniqueId';

import { ObservableEntityType } from '@pt-cybsi/api-interfaces';
import { SourceModel } from '@pt-cybsi/domain-core/sources';
import { Model } from '@pt-cybsi/shared';

interface IExternalDBModelProps {
    serverId: string;
    eTag: string;
    sourceId: string;
    entityTypes: ObservableEntityType[];
    webPageURL: string;
    taskExecutionTimeout: number;
    taskExecutionAttemptsCount: number;
}

/**
 * @model ExternalDB
 *
 * @description
 * Model for working with an external database data on the client in the domain layer.
 */
export class ExternalDBModel extends Model<IExternalDBModelProps> {
    get isNew(): boolean {
        return this.props.serverId === null;
    }

    constructor(props: IExternalDBModelProps, uid?: string) {
        super(props, uid);
    }

    static create(props: IExternalDBModelProps, uid?: string): ExternalDBModel {
        return new ExternalDBModel({ ...props }, uid);
    }

    static createNew(source: SourceModel): ExternalDBModel {
        return new ExternalDBModel({
            serverId: null,
            eTag: null,
            sourceId: source.uid,
            entityTypes: [],
            webPageURL: null,
            taskExecutionTimeout: null,
            taskExecutionAttemptsCount: null
        });
    }

    diff(comparedModel: ExternalDBModel): ExternalDBModel {
        const changedProps = this.getChangedProps(comparedModel, ['serverId', 'eTag', 'sourceId']);

        return ExternalDBModel.create(changedProps, this._uid);
    }

    protected generateId(): string {
        return uniqueId('external-db-');
    }
}
