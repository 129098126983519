import castArray from 'lodash/castArray';

import {
    ObservableEntityType,
    AttributeObservableEntity,
    AggregateSection,
    TAggregate,
    RelationDirection as ApiRelationDirection,
    RelationType as ApiRelationType,
    IRelationLink,
    TAggregateSectionDataByEntityType,
    TObservableEntity,
    TAttributeValuesType
} from '@pt-cybsi/api-interfaces';
import { Unpacked } from '@pt-cybsi/core';

import { RelationModelMapper } from '../mappers';

import { AggregateModel } from './aggregate.model';
import { RelationModel } from './relation.model';

export class EntityFullInfoModel<EntityType extends ObservableEntityType = ObservableEntityType> {
    constructor(public aggregate: AggregateModel<EntityType>, public links: RelationModel[] = []) {}

    static createFromRawData<EntityType extends ObservableEntityType = ObservableEntityType>(
        aggregateOrEntity: TAggregate<EntityType> | TObservableEntity<EntityType>,
        relations: IRelationLink[] = []
    ): EntityFullInfoModel<EntityType> {
        const aggregate = {
            sections: [],
            ...aggregateOrEntity
        } as TAggregate<EntityType>;

        return new EntityFullInfoModel(
            new AggregateModel<EntityType>(aggregate),
            relations.map(RelationModelMapper.fromRelationLink)
        );
    }

    private static isMatchedWithEntityType<ET extends ObservableEntityType>(
        model: EntityFullInfoModel,
        entityType: ET
    ): model is EntityFullInfoModel<ET> {
        return model.aggregate.type === entityType;
    }

    getId(): this['aggregate']['serverId'] {
        return this.aggregate.serverId;
    }

    getLocalId(): string {
        return this.aggregate.uniqId;
    }

    getType(): this['aggregate']['type'] {
        return this.aggregate.type;
    }

    getKeys(): this['aggregate']['keys'] {
        return this.aggregate.keys;
    }

    getMainKeys(): this['aggregate']['keys'] {
        return this.aggregate.getMainKeys();
    }

    getSecondaryKeys(): this['aggregate']['keys'] {
        return this.aggregate.getSecondaryKeys();
    }

    getName(): string | null {
        if (this.is(ObservableEntityType.File)) {
            const mainKey = this.getMainKeys()[0];

            return mainKey.value;
        } else if (this.is(ObservableEntityType.Identity)) {
            const factOfNames = this.aggregate.findFactOfNaturalAttribute(AttributeObservableEntity.Names);

            const identityName = factOfNames?.values[0]?.value;

            return identityName || null;
        } else {
            const mainKey = this.getMainKeys()[0];

            return mainKey.value;
        }
    }

    getNodeRoleValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.NodeRoles> {
        const factOfNodeRole = this.aggregate.findFactOfAssociatedAttribute(AttributeObservableEntity.NodeRoles);

        return factOfNodeRole?.values || [];
    }

    getRegionalInternetRegistryValues(): TAttributeValuesType<
        EntityType,
        AttributeObservableEntity.RegionalInternetRegistry
    > {
        const factOfRegionalInternetRegistry = this.aggregate.findFactOfNaturalAttribute(
            AttributeObservableEntity.RegionalInternetRegistry
        );

        return factOfRegionalInternetRegistry?.values || [];
    }

    getASNValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.ASN> {
        const factOfASN = this.aggregate.findFactOfNaturalAttribute(AttributeObservableEntity.ASN);

        return factOfASN?.values || [];
    }

    getIsIoCValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.IsIoC> {
        const factOfIsIoC = this.aggregate.findFactOfAssociatedAttribute(AttributeObservableEntity.IsIoC);

        return factOfIsIoC?.values || [];
    }

    getIsDGAValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.IsDGA> {
        const factOfIsDGA = this.aggregate.findFactOfAssociatedAttribute(AttributeObservableEntity.IsDGA);

        return factOfIsDGA?.values || [];
    }

    getIsTrustedValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.IsTrusted> {
        const factOfIsTrusted = this.aggregate.findFactOfAssociatedAttribute(AttributeObservableEntity.IsTrusted);

        return factOfIsTrusted?.values || [];
    }

    getIsDelegatedValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.IsDelegated> {
        const factOfIsDelegated = this.aggregate.findFactOfNaturalAttribute(AttributeObservableEntity.IsDelegated);

        return factOfIsDelegated?.values || [];
    }

    getLabels(): TLabelsValue<EntityType> {
        const labelsSection = this.aggregate.findSection(AggregateSection.Labels);

        return labelsSection?.data?.labels || [];
    }

    getIdentityClassValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.Class> {
        const factOfClass = this.aggregate.findFactOfNaturalAttribute(AttributeObservableEntity.Class);

        return factOfClass?.values || [];
    }

    getNamesValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.Names> {
        const factOfNames = this.aggregate.findFactOfNaturalAttribute(AttributeObservableEntity.Names);

        return factOfNames?.values || [];
    }

    getMalwareNamesValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.MalwareNames> {
        const factOfMalwareNames = this.aggregate.findFactOfNaturalAttribute(AttributeObservableEntity.MalwareNames);

        return factOfMalwareNames?.values || [];
    }

    getSizeValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.Size> {
        const factOfNames = this.aggregate.findFactOfNaturalAttribute(AttributeObservableEntity.Size);

        return factOfNames?.values || [];
    }

    getMalwareClassesValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.MalwareClasses> {
        const factOfMalwareClasses = this.aggregate.findFactOfAssociatedAttribute(
            AttributeObservableEntity.MalwareClasses
        );

        return factOfMalwareClasses?.values || [];
    }

    getMalwareFamiliesValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.MalwareFamilies> {
        const factOfMalwareFamilies = this.aggregate.findFactOfAssociatedAttribute(
            AttributeObservableEntity.MalwareFamilies
        );

        return factOfMalwareFamilies?.values || [];
    }

    getRelatedMalwareFamiliesValues(): TAttributeValuesType<
        EntityType,
        AttributeObservableEntity.RelatedMalwareFamilies
    > {
        const factOfRelatedMalwareFamilies = this.aggregate.findFactOfAssociatedAttribute(
            AttributeObservableEntity.RelatedMalwareFamilies
        );

        return factOfRelatedMalwareFamilies?.values || [];
    }

    getStatusesValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.Statuses> {
        const factOfStatuses = this.aggregate.findFactOfNaturalAttribute(AttributeObservableEntity.Statuses);

        return factOfStatuses?.values || [];
    }

    getCampaignsValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.Campaigns> {
        const factOfStatuses = this.aggregate.findFactOfAssociatedAttribute(AttributeObservableEntity.Campaigns);

        return factOfStatuses?.values || [];
    }

    getThreatActorsValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.ThreatActors> {
        const factOfStatuses = this.aggregate.findFactOfAssociatedAttribute(AttributeObservableEntity.ThreatActors);

        return factOfStatuses?.values || [];
    }

    getAffectedCountriesValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.AffectedCountries> {
        const factOfStatuses = this.aggregate.findFactOfAssociatedAttribute(
            AttributeObservableEntity.AffectedCountries
        );

        return factOfStatuses?.values || [];
    }

    getExploitedVulnerabilitiesValues(): TAttributeValuesType<
        EntityType,
        AttributeObservableEntity.ExploitedVulnerabilities
    > {
        const factOfStatuses = this.aggregate.findFactOfAssociatedAttribute(
            AttributeObservableEntity.ExploitedVulnerabilities
        );

        return factOfStatuses?.values || [];
    }

    getTargetedSectorsValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.TargetedSectors> {
        const factOfStatuses = this.aggregate.findFactOfAssociatedAttribute(AttributeObservableEntity.TargetedSectors);

        return factOfStatuses?.values || [];
    }

    getIdentitySectorsValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.Sectors> {
        const factOfSectors = this.aggregate.findFactOfNaturalAttribute(AttributeObservableEntity.Sectors);

        return factOfSectors?.values || [];
    }

    getGeoDataOfIpAddress(): TGeoData<EntityType> {
        const geoIpSection = this.aggregate.findSection(AggregateSection.GeoIP);

        const geoIp = geoIpSection?.data;

        const hasAsn = typeof geoIp?.asn === 'number';
        const hasCountryCode = !!geoIp?.countryCode;
        const hasCountry = !!geoIp?.country;

        return hasAsn || hasCountryCode || hasCountry ? geoIp : null;
    }

    getResolvedIpAddressesOfDomain(): RelationModel<ApiRelationType.ResolvesTo, ApiRelationDirection.Forward>[] {
        return this.findLinks(ApiRelationType.ResolvesTo, ApiRelationDirection.Forward);
    }

    getEmailDisplayNamesValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.DisplayNames> {
        const factOfDisplayNames = this.aggregate.findFactOfNaturalAttribute(AttributeObservableEntity.DisplayNames);

        return factOfDisplayNames?.values || [];
    }

    getThreatCategoryValues(): TAttributeValuesType<EntityType, AttributeObservableEntity.ThreatCategory> {
        const factOfThreatCategory = this.aggregate.findFactOfAssociatedAttribute(
            AttributeObservableEntity.ThreatCategory
        );

        const entityTypesWithAttribute = [ObservableEntityType.File];

        const values = factOfThreatCategory?.values || [];

        const hasValues = values.length > 0;
        const isSupportedAttribute = entityTypesWithAttribute.indexOf(this.aggregate.type) !== -1;

        if (!isSupportedAttribute) {
            return [];
        }

        return hasValues ? values : [{ confidence: 0, value: null }];
    }

    getRelatedThreatCategoryValues(): TAttributeValuesType<
        EntityType,
        AttributeObservableEntity.RelatedThreatCategory
    > {
        const factOfRelatedThreatCategory = this.aggregate.findFactOfAssociatedAttribute(
            AttributeObservableEntity.RelatedThreatCategory
        );

        const entityTypesWithAttribute = [
            ObservableEntityType.DomainName,
            ObservableEntityType.IPAddress,
            ObservableEntityType.EmailAddress,
            ObservableEntityType.URL
        ];

        const values = factOfRelatedThreatCategory?.values || [];

        const hasValues = values.length > 0;
        const isSupportedAttribute = entityTypesWithAttribute.indexOf(this.aggregate.type) !== -1;

        if (!isSupportedAttribute) {
            return [];
        }

        return hasValues ? values : [{ confidence: 0, value: null }];
    }

    getExtensionsOfPhoneNumber(): RelationModel<ApiRelationType.Contains, ApiRelationDirection.Reverse>[] {
        return this.findLinks(ApiRelationType.Contains, ApiRelationDirection.Reverse);
    }

    findLinks<
        RelationType extends ApiRelationType,
        RelationDirection extends ApiRelationDirection,
        Relation extends RelationModel<RelationType, RelationDirection>
    >(relationType: RelationType, relationDirection: RelationDirection): Relation[] | undefined {
        const findPredicate = (relation: Unpacked<EntityFullInfoModel<EntityType>['links']>): relation is Relation =>
            relation.is(relationType, relationDirection);

        return this.links.filter(findPredicate);
    }

    is<ET extends ObservableEntityType>(entityType: ET): this is EntityFullInfoModel<ET> {
        return EntityFullInfoModel.isMatchedWithEntityType(this, entityType);
    }

    addRelation(relation: RelationModel | RelationModel[]) {
        const relations = castArray(relation);

        this.links = this.links.filter(
            (currentLink) => !relations.some((addedRelation) => addedRelation.isEqual(currentLink))
        );

        this.links.push(...relations);
    }

    deleteRelation(deletedLink: RelationModel | RelationModel[]) {
        const deletedLinks: RelationModel[] = castArray(deletedLink);
        const newLinks = [];

        this.links.forEach((link) => {
            if (!deletedLinks.some((deletedLink) => deletedLink.isEqual(link))) {
                newLinks.push(link);
            }
        });

        this.links = newLinks;
    }

    deleteAllRelations() {
        this.links = [];
    }
}

/* eslint-disable @typescript-eslint/naming-convention */
export type TEntityFullInfoModel =
    | EntityFullInfoModel<ObservableEntityType.File>
    | EntityFullInfoModel<ObservableEntityType.Identity>
    | EntityFullInfoModel<ObservableEntityType.IPAddress>
    | EntityFullInfoModel<ObservableEntityType.DomainName>
    | EntityFullInfoModel<ObservableEntityType.URL>
    | EntityFullInfoModel<ObservableEntityType.EmailAddress>
    | EntityFullInfoModel<ObservableEntityType.PhoneNumber>;

type TLabelsValue<EntityType extends ObservableEntityType> = TAggregateSectionDataByEntityType<
    EntityType,
    AggregateSection.Labels
>['labels'];

type TGeoData<EntityType extends ObservableEntityType> = TAggregateSectionDataByEntityType<
    EntityType,
    AggregateSection.GeoIP
>;
