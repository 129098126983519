<form class="mc-form-vertical"
      novalidate
      (ngSubmit)="handleSubmit()">
    <mc-modal-title>
        {{ (viewMode === availableViewModes.EnterApiKeyData
            ? 'users.Users.CreateApiKeyModal.Title.CreateApiKey'
            : 'users.Users.CreateApiKeyModal.Title.CreatedApiKey') | transloco
        }}
    </mc-modal-title>
    <mc-modal-body>
        <div *ngIf="viewMode === availableViewModes.EnterApiKeyData">
            <progress-container [loadingFlag]="isSubmitInProgress">
                <div *ngIf="isSavingFailed"
                     class="mc-alert mc-alert_error margin-bottom_16">
                    <i mc-icon="mc-error_16" class="mc-alert__icon"></i>
                    {{ 'users.Users.CreateApiKeyModal.Error.Default' | transloco }}
                </div>

                <api-key-form autoFocusFirstField [form]="form" [userPermissions]="userPermissions"></api-key-form>
            </progress-container>
        </div>
        <div *ngIf="viewMode === availableViewModes.CopyApiKey">
            <div class="mc-alert mc-alert_warning margin-bottom_16">
                <i mc-icon="mc-error_16" class="mc-alert__icon"></i>
                {{ 'users.Users.CreateApiKeyModal.Text.CloseWarning' | transloco }}
            </div>

            <mc-form-field>
                <input mcInput
                       disabled
                       [ngModel]="generatedApiKey"
                       [ngModelOptions]="{ standalone: true }">
            </mc-form-field>
        </div>
    </mc-modal-body>
    <mc-modal-footer>
        <div *ngIf="viewMode === availableViewModes.EnterApiKeyData">
            <button mc-button color="primary" type="submit" [disabled]="isSubmitDisabled">
                {{ 'common.Common.Pseudo.Button.Create' | transloco }}
            </button>
            <button mc-button color="second" type="button" (click)="closeModal()">
                {{ 'common.Common.Pseudo.Button.Cancel' | transloco }}
            </button>
        </div>
        <div *ngIf="viewMode === availableViewModes.CopyApiKey">
            <button mc-button color="primary" type="button" (click)="copyKeyAndClose()">
                {{ 'users.Users.CreateApiKeyModal.Button.CopyAndClose' | transloco }}
            </button>
        </div>
    </mc-modal-footer>
</form>
