import isUndefined from 'lodash/isUndefined';

import { IEditableResource, IUserFullInfo, IUserUpdateParams } from '@pt-cybsi/api-interfaces';
import { Locale } from '@pt-cybsi/i18n';

import { TCurrentUserFormData } from './current-user-form.view-model';

export class CurrentUserFormMapper {
    static fromEditableUser(
        editableUser: IEditableResource<IUserFullInfo>,
        currentLocale: Locale
    ): TCurrentUserFormData {
        const { eTag, data: user } = editableUser;

        return {
            id: 'edit-current-user',
            eTag,
            login: user.login,
            fullName: user.fullName,
            email: user.email,
            language: currentLocale
        };
    }

    static toUpdateParams(formData: Partial<TCurrentUserFormData>): IUserUpdateParams {
        const { fullName, email } = formData;

        return {
            ...(isUndefined(fullName) ? {} : { fullName: fullName || null }),
            ...(isUndefined(email) ? {} : { email: email || null })
        };
    }
}
