import { translate } from '@ngneat/transloco';

import { EnrichmentType } from '@pt-cybsi/api-interfaces';

import { IEnrichmentRuleMeta } from '../types';

export const getEnrichmentRulesSourcesTitle = (enrichmentType: EnrichmentType) =>
    translate(`enrichment.Enrichment.RuleSources.Text.${enrichmentType}`);

export const getEnrichmentRulesSourcesCounterTitle = (meta: IEnrichmentRuleMeta) =>
    translate(`enrichment.Enrichment.SidePanel.Title.Count${meta.type}`, { count: meta.dataSources.length });
