import { Inject, Injectable } from '@angular/core';
import { TranslocoService, getBrowserCultureLang } from '@ngneat/transloco';
import { MC_DATE_FORMATS, McDateFormats } from '@ptsecurity/cdk/datetime';
import { DateFormatter } from '@ptsecurity/mosaic/core';
import { Settings } from 'luxon';

import { Locale, AVAILABLE_LOCALES, DEFAULT_LOCALE, LOCALES_DATE_INPUT_FORMAT } from './i18n-config';

/**
 * Service for getting current locale and changing it
 */
@Injectable({ providedIn: 'root' })
export class LocaleService {
    currentLang;

    get shortLocale(): string {
        return this.currentLocale.split('-').shift();
    }

    get shortCountryLocale(): string {
        return this.currentLocale.split('-').pop().toLowerCase();
    }

    get currentLocale(): Locale {
        return this._currentLocale;
    }

    set currentLocale(locale: Locale) {
        this._currentLocale = this.isAvailableLocale(locale) ? locale : DEFAULT_LOCALE;

        this.currentLang = this._currentLocale.split('-')[0];

        window.localStorage.setItem('locale', this._currentLocale);
        this.dateFormatter.setLocale(this.shortLocale);
        this.dateFormats.dateInput = LOCALES_DATE_INPUT_FORMAT[this._currentLocale];
        Settings.defaultLocale = this.shortLocale;

        this.translocoService.setActiveLang(this._currentLocale);
    }

    private _currentLocale: Locale;

    constructor(
        private translocoService: TranslocoService,
        private dateFormatter: DateFormatter<unknown>,
        @Inject(MC_DATE_FORMATS) private dateFormats: McDateFormats
    ) {
        this.currentLocale = (window.localStorage.getItem('locale') || getBrowserCultureLang()) as Locale;
    }

    private isAvailableLocale(locale: string): boolean {
        return AVAILABLE_LOCALES.find((availableLocale) => availableLocale === locale) !== undefined;
    }
}
