import { Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

export class AsyncRequestCache<T> {
    get hasCache(): boolean {
        return this.cache$ !== null;
    }

    get request$(): Observable<T> {
        return this.cache$;
    }

    private cache$: Observable<T> = null;
    private resetCache$: Subject<void> = new Subject();

    updateCache(request$: Observable<T>): Observable<T> {
        if (this.hasCache) {
            this.reset();
        }

        this.cache$ = request$.pipe(takeUntil(this.resetCache$), shareReplay({ bufferSize: 1, refCount: true }));

        return this.cache$;
    }

    reset(): void {
        this.resetCache$.next();

        this.cache$ = null;
    }
}
