import { ApiResource, PermissionType } from '@pt-cybsi/api-interfaces';

const Read = PermissionType.Read;

export const buildPermissionString = (resource: ApiResource, types: PermissionType[]) =>
    `${resource}:${types.join('')}`;

export class RootPermissionsService {
    // prettier-ignore
    static readonly viewReplistsPermissions = [
        buildPermissionString(ApiResource.ReputationLists, [Read])
    ];

    // prettier-ignore
    static readonly viewSourcesPermissions = [
        buildPermissionString(ApiResource.DataSources, [Read])
    ];

    // prettier-ignore
    static readonly viewEnrichmentRulesPermissions = [
        buildPermissionString(ApiResource.DataSources, [Read]),
        buildPermissionString(ApiResource.EnrichmentConfig, [Read])
    ];

    // prettier-ignore
    static readonly viewUsersPermissions = [
        buildPermissionString(ApiResource.Users, [Read])
    ];

    static readonly viewDictionariesPermissions = [buildPermissionString(ApiResource.Dictionaries, [Read])];

    static readonly viewStatisticsPermissions = [
        buildPermissionString(ApiResource.DataSources, [Read]),
        buildPermissionString(ApiResource.Observable, [Read])
    ];
}
