import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { McModalService } from '@ptsecurity/mosaic/modal';
import { combineLatest, Observable } from 'rxjs';
import { delay, map, take, takeUntil } from 'rxjs/operators';

import { LocationService } from '@pt-cybsi/core';
import {
    AccountNavigationService,
    MAIN_ROUTE_PERMISSIONS,
    NAVBAR_ROUTE_URLS_MAP,
    SignOutModalComponent
} from '@pt-cybsi/domain-core/account';
import { DictionariesNavigationService, DictionariesPermissionsService } from '@pt-cybsi/domain-core/dictionaries';
import { EnrichmentRulesNavigationService, EnrichmentRulesPermissionsService } from '@pt-cybsi/domain-core/enrichment';
import { LicenseNavigationService } from '@pt-cybsi/domain-core/license';
import {
    ReputationListsNavigationService,
    ReputationListsPermissionsService
} from '@pt-cybsi/domain-core/reputation-lists';
import { SourcesNavigationService, SourcesPermissionsService } from '@pt-cybsi/domain-core/sources';
import { StatisticsNavigationService } from '@pt-cybsi/domain-core/statistics';
import { UsersNavigationService, UsersPermissionsService } from '@pt-cybsi/domain-core/users';
import { IComponentsVersions, SystemInfoModalComponent, SystemInfoService } from '@pt-cybsi/feature/system';
import { AVAILABLE_LOCALES, Locale, LocaleService } from '@pt-cybsi/i18n';
import { ConfirmModalService, DestroyService } from '@pt-cybsi/shared';
import { AccountFacade } from '@pt-cybsi/store/account';
import { ClientConfigStoreFacade } from '@pt-cybsi/store/client-config';
import { LicenseFacade } from '@pt-cybsi/store/license';
import { RouterFacade } from '@pt-cybsi/store/router';

interface ICollapsedMenuItem {
    id: string;
    width: number;
}
interface IMenuItemConfig {
    e2eId: string;
    routerLink: string;
    text: string;
    permissions: string[];
}

function getElementWidth(element: HTMLElement | Element): number {
    return element.getBoundingClientRect().width;
}

const HIDDEN_CLASS_NAME = 'hidden';

@Component({
    selector: 'nav-bar',
    providers: [DestroyService],
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('menuItemsContainer', { read: ElementRef }) menuItemsContainer: ElementRef<HTMLElement>;
    @ViewChild('collapsibleMenuItemsContainer', { read: ElementRef })
    collapsibleMenuItemsContainer: ElementRef<HTMLElement>;

    isChangedForm: boolean;
    user$ = this.accountFacade.user$;
    hasToken$ = this.accountFacade.hasToken$;
    hasNotToken$ = this.accountFacade.hasNotToken$;
    userTitle$ = this.user$.pipe(map((user) => (user?.fullName ? user.fullName : user?.login)));
    userSubTitle$ = this.user$.pipe(map((user) => (user?.fullName && user?.login ? user.login : null)));

    isLicenseViewStateVisible$ = this.licenseFacade.isLoadingSuccess$.pipe(
        // Delay for fix NG0100 error
        delay(1)
    );
    licenseView$ = this.licenseFacade.view$;

    isMenuItemsVisible$ = combineLatest([this.hasToken$, this.accountFacade.hasUserWithPermissions$]).pipe(
        map(([hasToken, hasUserWithPermissions]) => hasToken && hasUserWithPermissions)
    );

    availableLocales = AVAILABLE_LOCALES;
    currentLocale = this.localeService.currentLocale;

    collapsedMenuItems: ICollapsedMenuItem[] = [];
    menuItemsConfig: Record<string, IMenuItemConfig>;

    viewAccountRoute = this.accountNavigationService.getPathOfViewRoute();
    editAccountRoute = this.accountNavigationService.getPathOfEditRoute();
    licenseRoute = this.licenseNavigationService.getPathOfViewRoute();

    private resizeObserver: ResizeObserver;

    get hasReleaseNotes$(): Observable<boolean> {
        return this.systemInfoService.hasReleaseNotes$;
    }

    get hasUserGuide$(): Observable<boolean> {
        return this.systemInfoService.hasUserGuide$;
    }

    get releaseNotesUrl(): string {
        return this.systemInfoService.releaseNotesUrl;
    }

    get userGuideUrl(): string {
        return this.systemInfoService.userGuideUrl;
    }

    get versions(): IComponentsVersions {
        return this.systemInfoService.versions;
    }

    get isActiveMenuItemCollapsed(): boolean {
        return this.collapsedMenuItems.some((collapsedMenuItem) =>
            window.location.hash.includes(this.menuItemsConfig[collapsedMenuItem.id].routerLink)
        );
    }

    get isAccountRouteActive(): boolean {
        return [this.viewAccountRoute, this.editAccountRoute].some((accountRoute) =>
            window.location.hash.includes(accountRoute)
        );
    }

    get isSystemRouteActive(): boolean {
        return window.location.hash.includes(this.licenseRoute);
    }

    constructor(
        private accountFacade: AccountFacade,
        private routerFacade: RouterFacade,
        private licenseFacade: LicenseFacade,
        private clientConfigFacade: ClientConfigStoreFacade,
        private modalService: McModalService,
        private systemInfoService: SystemInfoService,
        private confirmModalService: ConfirmModalService,
        private licenseNavigationService: LicenseNavigationService,
        private sourcesNavigationService: SourcesNavigationService,
        private enrichmentRulesNavigationService: EnrichmentRulesNavigationService,
        private usersNavigationService: UsersNavigationService,
        private reputationListsNavigationService: ReputationListsNavigationService,
        private accountNavigationService: AccountNavigationService,
        private dictionariesNavigationService: DictionariesNavigationService,
        private statisticsNavigationService: StatisticsNavigationService,
        private localeService: LocaleService,
        private locationService: LocationService,
        private changeDetectorRef: ChangeDetectorRef,
        private window: Window,
        private destroyed$: DestroyService
    ) {}

    ngOnInit() {
        this.initMenuItems();

        this.routerFacade.isChangedForm$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isChangedForm) => (this.isChangedForm = isChangedForm));
    }

    ngAfterViewInit() {
        this.initResizeObserver();
        this.startResizeObserving();
    }

    ngOnDestroy() {
        this.stopResizeObserving();
    }

    redirectToFirstAvailablePage() {
        this.locationService.redirectToFirstAvailablePage();
    }

    changeLocale(locale: Locale) {
        if (locale === this.currentLocale) {
            return;
        }

        if (this.isChangedForm) {
            this.confirmModalService
                .confirmLeaveForm()
                .pipe(take(1))
                .subscribe((isConfirm) => {
                    if (isConfirm) {
                        this.updateLocale(locale);
                    }
                });
        } else {
            this.updateLocale(locale);
        }
    }

    updateLocale(locale: Locale) {
        this.localeService.currentLocale = locale;
        this.window.location.reload();
    }

    openSignOutModal() {
        this.modalService.open({
            mcComponent: SignOutModalComponent,
            mcMaskClosable: true,
            mcCloseByESC: true
        });
    }

    openSystemInfoModal(): void {
        this.modalService.open({
            mcComponent: SystemInfoModalComponent
        });
    }

    sortMenuItems() {
        return 0;
    }

    private initMenuItems(): void {
        this.clientConfigFacade.isDashboardAvailable$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isDashboardAvailable) => {
                const statisticsMenuItem: IMenuItemConfig = {
                    e2eId: 'Main.Top.Statistics',
                    routerLink: this.statisticsNavigationService.root,
                    text: translate('common.Common.Pseudo.Text.Statistics'),
                    permissions: MAIN_ROUTE_PERMISSIONS.statistics
                };

                this.menuItemsConfig = {
                    objects: {
                        e2eId: 'Main.Top.Objects',
                        routerLink: NAVBAR_ROUTE_URLS_MAP.search,
                        text: translate('common.Common.Pseudo.Text.Objects'),
                        permissions: MAIN_ROUTE_PERMISSIONS.search
                    },
                    reports: {
                        e2eId: 'Main.Top.Reports',
                        routerLink: NAVBAR_ROUTE_URLS_MAP.reports,
                        text: translate('common.Common.Pseudo.Text.Reports'),
                        permissions: MAIN_ROUTE_PERMISSIONS.reports
                    },
                    artifacts: {
                        e2eId: 'Main.Top.Artifacts',
                        routerLink: NAVBAR_ROUTE_URLS_MAP.artifacts,
                        text: translate('common.Common.Pseudo.Text.Artifacts'),
                        permissions: MAIN_ROUTE_PERMISSIONS.artifacts
                    },
                    reputationLists: {
                        e2eId: 'Main.Top.ReputationLists',
                        routerLink: this.reputationListsNavigationService.root,
                        text: translate('common.Common.Pseudo.Text.ReputationLists'),
                        permissions: ReputationListsPermissionsService.viewListsPermissions
                    },
                    sources: {
                        e2eId: 'Main.Top.Sources',
                        routerLink: this.sourcesNavigationService.root,
                        text: translate('common.Common.Pseudo.Text.Sources'),
                        permissions: SourcesPermissionsService.viewListPermissions
                    },
                    users: {
                        e2eId: 'Main.Top.Users',
                        routerLink: this.usersNavigationService.root,
                        text: translate('common.Common.Pseudo.Text.Users'),
                        permissions: UsersPermissionsService.viewListPermissions
                    },
                    enrichmentRules: {
                        e2eId: 'Main.Top.EnrichmentRules',
                        routerLink: this.enrichmentRulesNavigationService.root,
                        text: translate('common.Common.Pseudo.Text.EnrichmentRules'),
                        permissions: EnrichmentRulesPermissionsService.viewRulesPermissions
                    },
                    feeds: {
                        e2eId: 'Main.Top.Feeds',
                        routerLink: NAVBAR_ROUTE_URLS_MAP.feeds,
                        text: translate('common.Common.Pseudo.Text.Feeds'),
                        permissions: MAIN_ROUTE_PERMISSIONS.feeds
                    },
                    ...(isDashboardAvailable ? { statistics: statisticsMenuItem } : {}),
                    dictionaries: {
                        e2eId: 'Main.Top.Dictionaries',
                        routerLink: this.dictionariesNavigationService.root,
                        text: translate('common.Common.Pseudo.Text.Dictionaries'),
                        permissions: DictionariesPermissionsService.viewListPermissions
                    }
                };
            });
    }

    private initResizeObserver(): void {
        this.resizeObserver = new ResizeObserver(() => {
            this.checkOverflowedMenuItems();
        });
    }

    private startResizeObserving(): void {
        if (this.menuItemsContainer) {
            this.resizeObserver.observe(this.menuItemsContainer.nativeElement);
        }
    }

    private stopResizeObserving(): void {
        this.resizeObserver.disconnect();
    }

    private checkOverflowedMenuItems() {
        const collapsibleMenuItemsContainer = this.collapsibleMenuItemsContainer?.nativeElement;

        if (!collapsibleMenuItemsContainer) {
            return;
        }

        const collapsibleMenuItemsContainerWidth = getElementWidth(collapsibleMenuItemsContainer);
        let visibleMenuItemsWidth = 0;

        if (collapsibleMenuItemsContainer.childElementCount) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < collapsibleMenuItemsContainer.children.length; i++) {
                if (!collapsibleMenuItemsContainer.children[i].classList.contains(HIDDEN_CLASS_NAME)) {
                    visibleMenuItemsWidth += getElementWidth(collapsibleMenuItemsContainer.children[i]);
                }
            }
        }

        if (Math.round(visibleMenuItemsWidth) > Math.round(collapsibleMenuItemsContainerWidth)) {
            const lastVisibleChildIndex =
                collapsibleMenuItemsContainer.childElementCount - this.collapsedMenuItems.length - 1;
            const lastVisibleChild = collapsibleMenuItemsContainer.children[lastVisibleChildIndex];

            this.collapsedMenuItems.unshift({
                id: lastVisibleChild.id.replace('menu-item-', ''),
                width: getElementWidth(lastVisibleChild)
            });
            this.changeDetectorRef.detectChanges();

            lastVisibleChild.classList.add(HIDDEN_CLASS_NAME);

            // в данном месте без timeout в 100c, неправильно расчитывается ширина collapsibleMenuItemsContainer
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            setTimeout(() => this.checkOverflowedMenuItems(), 100);
        } else {
            const hiddenMenuItems = collapsibleMenuItemsContainer.getElementsByClassName(HIDDEN_CLASS_NAME);

            if (hiddenMenuItems.length) {
                const moreItemsWidth =
                    hiddenMenuItems.length > 1
                        ? getElementWidth(this.menuItemsContainer.nativeElement.querySelector('#more-navbar-items'))
                        : 0;
                const menuItemsContainerWidth = getElementWidth(this.menuItemsContainer.nativeElement);

                if (
                    this.collapsedMenuItems[0].width + visibleMenuItemsWidth + moreItemsWidth <
                    menuItemsContainerWidth
                ) {
                    this.collapsedMenuItems.shift();
                    this.changeDetectorRef.detectChanges();

                    hiddenMenuItems.item(0).classList.remove(HIDDEN_CLASS_NAME);

                    this.checkOverflowedMenuItems();
                }
            }
        }
    }
}
