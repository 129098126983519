import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentFactoryResolver, ComponentRef, Injectable, Injector, StaticProvider } from '@angular/core';
import { first } from 'rxjs/operators';

import {
    NotificationMessageComponent,
    NotificationMessageParams,
    NotificationType
} from '../components/notification-message/notification-message.component';
import { NotificationsPortalComponent } from '../components/notifications-portal/notifications-portal.component';

import { DestroyService } from './destroy.service';

const DEFAULT_MESSAGE_PARAMS: Partial<NotificationMessageParams> = {
    type: NotificationType.Error,
    closable: true,
    timeout: 4000
};

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    private overlayRef: OverlayRef;
    private portalRef: ComponentRef<NotificationsPortalComponent>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private overlay: Overlay,
        private parentInjector: Injector
    ) {
        this.initOverlay();
    }

    show(params: NotificationMessageParams): void {
        this.destroyOverlay();
        this.initOverlay();

        const messageInjector = this.createMessageInjector({
            ...DEFAULT_MESSAGE_PARAMS,
            ...params
        });
        const messageComponent = this.createMessageComponent(messageInjector);

        messageComponent.instance.closeMessage.pipe(first()).subscribe(() => messageComponent.destroy());

        this.addMessage(messageComponent);
    }

    private addMessage(component: ComponentRef<NotificationMessageComponent>): void {
        this.portalRef.instance.container.insert(component.hostView);
    }

    private createOverlayRef(): OverlayRef {
        return this.overlay.create({
            positionStrategy: this.getPositionStrategy()
        });
    }

    private getPositionStrategy() {
        return this.overlay.position().global().top('24px').centerHorizontally();
    }

    private createPortalRef(overlayRef: OverlayRef): ComponentRef<NotificationsPortalComponent> {
        this.portalRef = overlayRef.attach(new ComponentPortal(NotificationsPortalComponent));

        this.portalRef.hostView.detectChanges();

        return this.portalRef;
    }

    private createMessageInjector(params: NotificationMessageParams): Injector {
        const tokens: StaticProvider[] = [
            {
                provide: NotificationMessageParams,
                useValue: params
            },
            {
                provide: DestroyService,
                useClass: DestroyService
            }
        ];

        return Injector.create({
            providers: tokens,
            parent: this.parentInjector
        });
    }

    private createMessageComponent(messageInjector: Injector): ComponentRef<NotificationMessageComponent> {
        const factory = this.componentFactoryResolver.resolveComponentFactory(NotificationMessageComponent);

        return factory.create(messageInjector);
    }

    private initOverlay(): void {
        this.overlayRef = this.createOverlayRef();
        this.portalRef = this.createPortalRef(this.overlayRef);
    }

    private destroyOverlay(): void {
        this.portalRef.destroy();
        this.overlayRef.dispose();
    }
}
