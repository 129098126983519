import castArray from 'lodash/castArray';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { delay, filter, switchMap, take } from 'rxjs/operators';

import { AsyncState } from '../types';

export const isAsyncStateIdle = (state: AsyncState): state is AsyncState.Idle => state === AsyncState.Idle;
export const isAsyncStateLoading = (state: AsyncState): state is AsyncState.Loading => state === AsyncState.Loading;
export const isAsyncStateSuccess = (state: AsyncState): state is AsyncState.Success => state === AsyncState.Success;
export const isAsyncStateFailure = (state: AsyncState): state is AsyncState.Failure => state === AsyncState.Failure;

export const awaitAsyncStateComplete =
    <T>(
        getState: (source: T) => Observable<AsyncState> | Observable<AsyncState>[],
        options?: {
            onFailure?: (source: T, states: AsyncState[]) => Observable<void>;
        }
    ) =>
    (source$: Observable<T>) =>
        source$.pipe(
            switchMap((source) =>
                combineLatest(castArray(getState(source))).pipe(
                    filter((states) => states.every((state) => !isAsyncStateLoading(state))),
                    delay(1),
                    take(1),
                    switchMap((states) => {
                        const hasFailureState = states.some(isAsyncStateFailure);

                        if (hasFailureState) {
                            return options?.onFailure
                                ? options.onFailure(source, states).pipe(switchMap(() => EMPTY))
                                : EMPTY;
                        }

                        return of(source);
                    })
                )
            )
        );
