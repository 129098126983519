import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import isNil from 'lodash/isNil';

import { ArtifactType, ObservableEntityType, TBaseEntityKeyType } from '@pt-cybsi/api-interfaces';
import { TranslateArtifactTypePipe } from '@pt-cybsi/domain-core/artifacts';
import { createASCSortComparator, TimePeriodService, TimePeriodType } from '@pt-cybsi/shared';

import { AnalyserFormViewModel } from '../../forms';
import { MAX_ARTIFACT_SIZE_LIMIT, MAX_TASK_ATTEMPTS_COUNT, MAX_TASK_EXECUTION_TIMEOUT } from '../../validators';

@Component({
    selector: 'analyser-form',
    templateUrl: './analyser-form.component.html',
    styleUrls: ['./analyser-form.component.scss'],
    host: {
        class: 'mc-form-vertical'
    }
})
export class AnalyserFormComponent implements OnInit {
    @Input() form: AnalyserFormViewModel;

    get artifactTypesControl(): AbstractControl {
        return this.form.getControl('artifactTypes');
    }

    availableArtifactTypes: ArtifactType[] = [];

    periodTaskExecutionTimeout: TimePeriodType;

    dashboardURLEntityType: ObservableEntityType = ObservableEntityType.URL;
    dashboardURLKeyType: TBaseEntityKeyType = 'String';

    invalidArtifactSizeLimitMessage: string = this.translocoService.translate(
        'enrichment.Enrichment.AnalyserValidation.Text.InvalidArtifactSizeLimit',
        {
            count: MAX_ARTIFACT_SIZE_LIMIT
        }
    );

    invalidTaskAttemptsCountMessage: string = this.translocoService.translate(
        'enrichment.Enrichment.Validation.Text.InvalidTaskExecutionAttemptsCount',
        {
            maxCount: MAX_TASK_ATTEMPTS_COUNT
        }
    );

    private artifactTypesSortComparator = createASCSortComparator<ArtifactType>((item) =>
        this.translateArtifactTypePipe.transform(item)
    );

    private invalidTaskExecutionTimeoutTranslateKeys = {
        [TimePeriodType.Seconds]: 'enrichment.Enrichment.Validation.Text.InvalidTaskExecutionSecondsTimeout',
        [TimePeriodType.Minutes]: 'enrichment.Enrichment.Validation.Text.InvalidTaskExecutionMinutesTimeout',
        [TimePeriodType.Hours]: 'enrichment.Enrichment.Validation.Text.InvalidTaskExecutionHoursTimeout'
    };

    constructor(
        private translocoService: TranslocoService,
        private timePeriodService: TimePeriodService,
        private translateArtifactTypePipe: TranslateArtifactTypePipe
    ) {}

    ngOnInit(): void {
        const taskExecutionTimeout = this.form.currentData.taskExecutionTimeout;

        if (isNil(taskExecutionTimeout)) {
            this.periodTaskExecutionTimeout = TimePeriodType.Minutes;
        } else {
            const period = this.timePeriodService.toMaxEntirePeriod(taskExecutionTimeout, [
                TimePeriodType.Seconds,
                TimePeriodType.Minutes,
                TimePeriodType.Hours
            ]);

            this.periodTaskExecutionTimeout = period?.type;
        }

        this.availableArtifactTypes = this.getAvailableArtifactTypes();
    }

    handleChangePeriodTaskExecutionTimeout(periodType: TimePeriodType): void {
        this.periodTaskExecutionTimeout = periodType;
    }

    getInvalidTaskExecutionTimeoutMessage(periodType: TimePeriodType): string {
        const currentMaxPeriod = this.timePeriodService.toPeriod(MAX_TASK_EXECUTION_TIMEOUT, periodType, [
            TimePeriodType.Seconds,
            TimePeriodType.Minutes,
            TimePeriodType.Hours
        ]);

        const translateKey = this.invalidTaskExecutionTimeoutTranslateKeys[currentMaxPeriod.type];

        return this.translocoService.translate(translateKey, { maxTimeout: currentMaxPeriod.value });
    }

    private getAvailableArtifactTypes(): ArtifactType[] {
        return Object.values(ArtifactType).sort(this.artifactTypesSortComparator);
    }
}
