import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import isEmpty from 'lodash/isEmpty';

interface IListQueryParams {
    name?: string;
}

@Injectable({ providedIn: 'root' })
export class SourcesNavigationService {
    static routes = {
        list: '',
        viewType: 'types/view/:typeId',
        view: 'view/:sourceId',
        create: 'create',
        editType: 'types/edit/:typeId',
        edit: 'edit/:sourceId'
    };

    readonly root: string = '/sources';

    constructor(private router: Router) {}

    goToListPage(queryParams?: IListQueryParams): void {
        const path = this.getPathOfListRoute();
        const hasQueryParams = !isEmpty(queryParams);

        const extras: NavigationExtras = hasQueryParams ? { queryParams } : undefined;

        this.router.navigate([path], extras);
    }

    goToSourceTypeViewPage(sourceId: string): void {
        const path = this.getPathOfSourceTypeViewRoute(sourceId);

        this.router.navigate([path]);
    }

    goToSourceViewPage(sourceId: string): void {
        const path = this.getPathOfSourceViewRoute(sourceId);

        this.router.navigate([path]);
    }

    getPathOfListRoute(): string {
        return `${this.root}/${SourcesNavigationService.routes.list}`;
    }

    getPathOfSourceTypeViewRoute(sourceId: string): string {
        return `${this.root}/${SourcesNavigationService.routes.viewType.replace(':typeId', sourceId)}`;
    }

    getPathOfSourceViewRoute(sourceId: string): string {
        return `${this.root}/${SourcesNavigationService.routes.view.replace(':sourceId', sourceId)}`;
    }

    getPathOfSourceTypeEditRoute(sourceId: string): string {
        return `${this.root}/${SourcesNavigationService.routes.editType.replace(':typeId', sourceId)}`;
    }

    getPathOfSourceCreationRoute(): string {
        return `${this.root}/${SourcesNavigationService.routes.create}`;
    }

    getPathOfSourceEditRoute(sourceId: string): string {
        return `${this.root}/${SourcesNavigationService.routes.edit.replace(':sourceId', sourceId)}`;
    }
}
