import {
    RelationType as ApiRelationType,
    RelationDirection as ApiRelationDirection,
    RelationDirection
} from '@pt-cybsi/api-interfaces';

import { RelationMetadataMapper } from '../mappers';

import { EntityFullInfoModel } from './entity-full-info.model';

export class RelationModel<
    RelationType extends ApiRelationType = ApiRelationType,
    RelationDirection extends ApiRelationDirection = ApiRelationDirection
> {
    constructor(
        public type: RelationType,
        public direction: RelationDirection,
        public relatedEntity: EntityFullInfoModel,
        public confidence?: number
    ) {}

    private static isMatchedWithRelationParams<RT extends ApiRelationType, RD extends ApiRelationDirection>(
        model: RelationModel,
        type: RT,
        direction: RD
    ): model is RelationModel<RT, RD> {
        return model.type === type && model.direction === direction;
    }

    is<RT extends ApiRelationType, RD extends ApiRelationDirection>(
        type: RT,
        direction: RD
    ): this is RelationModel<RT, RD> {
        return RelationModel.isMatchedWithRelationParams(this, type, direction);
    }

    isEqual(relationModel: RelationModel) {
        return (
            this.type === relationModel.type &&
            this.direction === relationModel.direction &&
            this.relatedEntity.getId() === relationModel.relatedEntity.getId()
        );
    }
}
