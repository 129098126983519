import { Pipe, PipeTransform } from '@angular/core';

import { LocaleService } from '@pt-cybsi/i18n';

import { convertBytesToFileSize, FileSizeUnit } from '../helpers';

export interface IFormatBytesOptions {
    accuracy?: number;
    unit?: FileSizeUnit;
    withUnits?: boolean;
}

const DEFAULT_OPTIONS: IFormatBytesOptions = {
    accuracy: 2,
    unit: null,
    withUnits: true
};

/**
 * @pipe FormatBytes
 *
 * @description Format and convert bytes to string value
 */
@Pipe({
    name: 'formatBytes'
})
export class FormatBytesPipe implements PipeTransform {
    constructor(private localeService: LocaleService) {}

    transform(value: number, options: IFormatBytesOptions = DEFAULT_OPTIONS) {
        const formatOptions = { ...DEFAULT_OPTIONS, ...options };

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const factor = 10 ** formatOptions.accuracy;

        const size = convertBytesToFileSize(value, { unit: formatOptions.unit });
        const roundedSize = Math.round(size.value * factor) / factor;
        const localizedSize = roundedSize.toLocaleString(this.localeService.currentLocale);

        return formatOptions.withUnits ? `${localizedSize} ${size.unit}` : localizedSize;
    }
}
