import { ObservableEntityType } from '../common';

export interface IReputationListStatsParams {
    replistUUID: string;
}

export interface IReputationListStats {
    entityCount: number;
    entityTypeDistribution: IEntityTypeDistribution[];
}

export interface IEntityTypeDistribution {
    entityType: ObservableEntityType;
    count: number;
}
