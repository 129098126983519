export const LOGIN_MAX_LENGTH = 250;
export const NAME_MAX_LENGTH = 250;
export const EMAIL_MIN_LENGTH = 3;
export const EMAIL_MAX_LENGTH = 254;
export const LOGIN_PATTERN = /^[a-zA-Z0-9_-]+$/;

const validateLength = (value: string, minLength: number | null, maxLength: number | null) =>
    value === undefined ||
    value === null ||
    value === '' ||
    ((minLength ? value.length >= minLength : true) && (maxLength ? value.length <= maxLength : true));

export const validateUserLoginLength = (login: string) => validateLength(login, null, LOGIN_MAX_LENGTH);

export const validateUserLoginPattern = (value: string) =>
    value === undefined || value === null || value === '' || LOGIN_PATTERN.test(value);

export const validateUserNameLength = (name: string) => validateLength(name, null, NAME_MAX_LENGTH);

export const validateUserEmailLength = (email: string) => validateLength(email, EMAIL_MIN_LENGTH, EMAIL_MAX_LENGTH);
