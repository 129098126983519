import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ISourceTypesGridItem } from '@pt-cybsi/domain-core/sources';
import { IDetailsTemplateContext } from '@pt-cybsi/shared';

import { ISourcesGridItem } from '../sources-grid/sources-grid.component';

export enum SourceTreeGridDetailsState {
    Loading = 'Loading',
    Success = 'Success',
    Failure = 'Failure'
}

/**
 * @component SourcesTreeGridDetails
 *
 * @param state state of details state
 * @param sources list of sources for lazy-grid in a `ISourcesGridItem[]` format
 * @param detailsTriggers triggers of details wrapper. Needed for a updating details section height after updating component props
 *
 * @description Is used only inside SourceTreeGrid component for display list of sources in a details section
 *
 * @example
 * Live examples can be viewed in Storybook for SourceTreeGrid component
 *
 * Example of template:
 * ```html
 *  <sources-tree-grid-details
 *      [state]="detailsState"
 *      [sources]="sources"
 *      [detailsTriggers]="triggers"
 *  ></sources-tree-grid-details>
 * ```
 */
@Component({
    selector: 'sources-tree-grid-details',
    templateUrl: './sources-tree-grid-details.component.html',
    styleUrls: ['./sources-tree-grid-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SourcesTreeGridDetailsComponent implements OnChanges {
    @Input()
    state: SourceTreeGridDetailsState;

    @Input()
    sources: ISourcesGridItem[];

    @Input()
    detailsTriggers: IDetailsTemplateContext<ISourceTypesGridItem>['triggers'];

    get isLoading(): boolean {
        return this.state === SourceTreeGridDetailsState.Loading;
    }

    get isSuccess(): boolean {
        return this.state === SourceTreeGridDetailsState.Success;
    }

    get isFailure(): boolean {
        return this.state === SourceTreeGridDetailsState.Failure;
    }

    get isEmpty(): boolean {
        return this.isSuccess && this.sources.length === 0;
    }

    get hasSources(): boolean {
        return this.isSuccess && this.sources.length > 0;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const isStateChanged =
            !changes.state?.isFirstChange() &&
            changes.state?.currentValue &&
            changes.state.currentValue !== changes.state.previousValue;

        const isSourcesChanged =
            !changes.sources?.isFirstChange() &&
            changes.sources?.currentValue &&
            changes.sources.currentValue !== changes.sources.previousValue;

        if (isStateChanged || isSourcesChanged) {
            this.detailsTriggers.updateHeight();
        }
    }
}
