<filter-button
    [title]="title"
    [valueTitle]="appliedValueTitle"

    mcPopover
    mcPopoverClass="list-filter-popover"
    #popover="mcPopover"
    [mcPopoverContent]="content"
    [mcPopoverFooter]="footer"
    (mcPopoverVisibleChange)="handlePopoverVisibleChange($event)">
</filter-button>

<ng-template #header>
    <mc-form-field>
        <input
            mcInput
            type="text"
            autocomplete="off"

            [ngModel]="searchString"
            (ngModelChange)="handleSearch($event)">
    </mc-form-field>
</ng-template>

<ng-template #content>
    <div *ngIf="isLoadingState" class="list-filter-popover__loader layout-row layout-align-center-center">
        <mc-progress-spinner mode="indeterminate"></mc-progress-spinner>
    </div>

    <ng-container *ngIf="isSuccessState">
        <mc-list-selection
            *ngIf="hasAvailableItems"
            multiple="checkbox"
            [(ngModel)]="selectedValues"
            (selectionChange)="handleSelectionChange($event)">

            <mc-list-option
                *ngFor="let item of availableItems"
                [value]="item.value">
                <ng-container *ngTemplateOutlet="itemTemplate; context: getItemTemplateContext(item)"></ng-container>
            </mc-list-option>
        </mc-list-selection>

        <span *ngIf="!hasAvailableItems" class="list-filter-popover__empty">
            {{ 'common.Common.Search.Title.NotFound' | transloco }}
        </span>
    </ng-container>

    <div *ngIf="isFailureState" class="list-filter-popover__error">
        <div class="mc-alert mc-alert_error">
            <i mc-icon="mc-error_16" class="mc-alert__icon"></i>
            <div>
                <span>{{ 'common.Common.Loading.Text.ErrorTitle' | transloco }}</span>
                <button
                    mc-link
                    pseudo
                    class="mc-link clear-button"
                    (click)="handleRetry()">
                    <span class="mc-link__text">
                        {{ 'common.Common.Loading.Text.RetryButton' | transloco }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #footer>
    <div class="flex-100 layout-row layout-align-space-between-center">
        <button mc-button
                color="second"
                class="list-filter-popover__reset"
                (click)="handleReset()">
            {{ 'common.Common.Pseudo.Button.Reset' | transloco }}
        </button>
        <button mc-button
                color="primary"
                (click)="handleApply()">
            {{ 'common.Common.Pseudo.Button.Apply' | transloco }}
        </button>
        <button mc-button
                color="second"
                class="margin-left_8"
                (click)="handleCancel()">
            {{ 'common.Common.Pseudo.Button.Cancel' | transloco }}
        </button>
    </div>
</ng-template>
