import { InjectionToken } from '@angular/core';

function getIframe(): HTMLIFrameElement {
    const node = document.querySelector('#legacyApplicationFrame');

    return node as HTMLIFrameElement;
}

export type LegacyFrameProvider = () => HTMLIFrameElement;

export const LEGACY_FRAME_PROVIDER_TOKEN = new InjectionToken<LegacyFrameProvider>('Legacy iframe provider token', {
    providedIn: 'root',
    factory: () => getIframe
});
