import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { TimePeriodType, TimePeriodService } from '../services';

const defaultAvailablePeriod: TimePeriodType[] = [TimePeriodType.Seconds, TimePeriodType.Minutes, TimePeriodType.Hours];

/**
 * @pipe ToTimePeriodString
 *
 * @description Converts milliseconds to localized string of time period
 */
@Pipe({
    name: 'toTimePeriodString'
})
export class ToTimePeriodStringPipe implements PipeTransform {
    private readonly translatePeriodTypeKeys = {
        [TimePeriodType.Seconds]: 'common.Common.Pseudo.Text.PluralSeconds',
        [TimePeriodType.Minutes]: 'common.Common.Pseudo.Text.PluralMinutes',
        [TimePeriodType.Hours]: 'common.Common.Pseudo.Text.PluralHours'
    };

    constructor(private translocoService: TranslocoService, private timePeriodService: TimePeriodService) {}

    transform(milliseconds: number, availablePeriods: TimePeriodType[] = defaultAvailablePeriod): string {
        const period = this.timePeriodService.toMaxEntirePeriod(milliseconds, availablePeriods);

        if (!period) {
            return '';
        }

        const { type, value } = period;

        const translatedType: string = this.translocoService.translate(this.translatePeriodTypeKeys[type], {
            count: value
        });

        return `${value} ${translatedType.toLowerCase()}`;
    }
}
