<table mc-table *transloco="let tUsers; read: 'users'">
    <thead>
        <tr>
            <th *ngFor="let column of columns" [class]="column.class">
                {{ tUsers('Users.ApiKey.Text.' + column.title) }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let apiKey of apiKeys; trackBy: trackByApiKeyId"
            [class.user-api-keys-table__gray-row]="apiKey.revoked || apiKey.isExpired">
            <td class="user-api-keys-table__description layout-row layout-align-start-center">
                <span pt-title *ngIf="apiKey.description" class="pt-text-overflow padding-right_8">{{ apiKey.description }}</span>
                <status-badge
                    *ngIf="apiKey.revoked"
                    statusTranslationKey="users.Users.ApiKey.Badge.Revoked">
                </status-badge>
                <status-badge
                    *ngIf="apiKey.isExpired"
                    [class.margin-left_4]="apiKey.revoked && apiKey.isExpired"
                    statusTranslationKey="users.Users.ApiKey.Badge.Overdue">
                </status-badge>
            </td>
            <td class="user-api-keys-table__permissions">
                <div class="layout-row">
                    <a *ngIf="apiKey.permissions?.length > 1"
                        mc-link
                        pseudo
                        pt-title
                        class="mc-link_overflowed"
                        (click)="handleClickMore(apiKey.permissions)">
                        <span class="mc-link__text">{{ tUsers('Users.ApiKey.Text.ShortCountPermissions', { count: apiKey.permissions.length }) }}</span>
                    </a>
                    <span *ngIf="!apiKey.permissions" pt-title class="pt-text-overflow">
                        {{ tUsers('Users.ApiKey.Text.InheritedPermissions') }}
                    </span>
                    <span *ngIf="apiKey.permissions?.length === 1" pt-title class="pt-text-overflow">
                        {{ apiKey.permissions | translatePermissions }}
                    </span>
                </div>
            </td>
            <td class="user-api-keys-table__date-cell">
                {{ apiKey.createdAt | formattedDate: { run: 'async' } | async }}
            </td>
            <td class="user-api-keys-table__date-cell">
                {{ apiKey.lastUsedAt | formattedDate: { format: 'absoluteLongDateTimeWithSeconds', run: 'async' } | async }}
            </td>
            <td class="user-api-keys-table__date-cell" *ngIf="apiKey.expiresAt">
                {{ apiKey.expiresAt | formattedDate: { run: 'async' } | async }}
            </td>
            <td class="user-api-keys-table__date-cell" *ngIf="!apiKey.expiresAt">
                {{ tUsers('Users.ApiKey.Text.Perpetual') }}
            </td>

            <div class="edit-api-key" *ngIf="isEditButtonVisible(apiKey)">
                <button class="edit-button mc-icon-button"
                        mc-button
                        mcTooltip="{{ tUsers('Users.Pseudo.Text.Edit') }}"
                        mcPlacement="top"
                        color="second"
                        (click)="editKey.emit(apiKey)">
                    <i mc-icon class="pt-icons pt-icons-edit_16 mc-icon_light"></i>
                </button>
            </div>
        </tr>
    </tbody>
</table>
