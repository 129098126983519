import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IHashSum } from '../../types';

/**
 * @component HashSum
 *
 * @description
 * Component for displaying hash sum value with type and copy button
 *
 * @param value - Hash sum in `IHashSum` format
 *
 * @example
 * ```html
 *
 * <hash-sum [value]="{ type: HashSumType.MD5, value: '029a595b91e7423444159029b7d0fa03' }"></hash-sum>
 *
 * ```
 */
@Component({
    selector: 'hash-sum',
    styleUrls: ['./hash-sum.component.scss'],
    templateUrl: './hash-sum.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HashSumComponent {
    @Input() value: IHashSum;
}
