import {
    IObservationHeader,
    ObservableEntityType,
    ObservationType,
    TDNSWhoisObservableEntityType,
    TGetEntityType,
    TObservableEntity
} from '@pt-cybsi/api-interfaces';

export interface IObservationDNSLookup<EntityFormat extends TObservableEntity = TObservableEntity>
    extends IObservationHeader<ObservationType.DNSLookup> {
    content: IObservationDNSLookupContent<EntityFormat>;
}

export interface IObservationDNSLookupContent<EntityFormat extends TObservableEntity = TObservableEntity> {
    entity: TGetEntityType<EntityFormat, TDNSWhoisObservableEntityType>;
    status: DNSLookupStatus;
    answer: TDNSRecord<EntityFormat>[] | null;
    authority: TDNSRecord<EntityFormat>[] | null;
    additional: TDNSRecord<EntityFormat>[] | null;
}

export type TDNSRecord<EntityFormat extends TObservableEntity = TObservableEntity> =
    | IDNSRecord<DNSRecordType.A, EntityFormat>
    | IDNSRecord<DNSRecordType.AAAA, EntityFormat>
    | IDNSRecord<DNSRecordType.CNAME, EntityFormat>
    | IDNSRecord<DNSRecordType.MX, EntityFormat>
    | IDNSRecord<DNSRecordType.NS, EntityFormat>
    | IDNSRecord<DNSRecordType.PTR, EntityFormat>
    | IDNSRecord<DNSRecordType.SOA, EntityFormat>
    | IDNSRecord<DNSRecordType.TXT, EntityFormat>;

export interface IDNSRecord<RecordType = DNSRecordType, EntityFormat extends TObservableEntity = TObservableEntity> {
    type: RecordType;
    ttl: number;
    entity: TGetEntityType<EntityFormat>;
    data: TDNSRecordData<RecordType, EntityFormat>;
}

export type TDNSRecordData<
    RecordType = DNSRecordType,
    EntityFormat extends TObservableEntity = TObservableEntity
> = RecordType extends DNSRecordType.TXT
    ? IDNSTXTData
    : RecordType extends DNSRecordType.MX
    ? IDNSMXData<EntityFormat>
    : RecordType extends DNSRecordType.SOA
    ? IDNSSOAData<EntityFormat>
    : RecordType extends Exclude<DNSRecordType, DNSRecordType.MX | DNSRecordType.TXT | DNSRecordType.SOA>
    ? IDNSCommonData<EntityFormat>
    : IDNSTXTData | IDNSMXData<EntityFormat> | IDNSSOAData<EntityFormat> | IDNSCommonData<EntityFormat>;

export interface IDNSMXData<EntityFormat extends TObservableEntity = TObservableEntity>
    extends IDNSCommonData<EntityFormat> {
    preference: number;
}

export interface IDNSTXTData {
    text: string[];
}

export interface IDNSSOAData<EntityFormat extends TObservableEntity = TObservableEntity> {
    primary: TGetEntityType<EntityFormat, ObservableEntityType.DomainName>;
    hostmaster: TGetEntityType<EntityFormat, ObservableEntityType.EmailAddress>;
    serial: number;
    refresh: number;
    retry: number;
    expire: number;
    minimum: number;
}

export interface IDNSCommonData<EntityFormat extends TObservableEntity = TObservableEntity> {
    name: TGetEntityType<EntityFormat>;
}

export enum DNSRecordType {
    A = 'A',
    AAAA = 'AAAA',
    MX = 'MX',
    TXT = 'TXT',
    NS = 'NS',
    SOA = 'SOA',
    PTR = 'PTR',
    CNAME = 'CNAME'
}

export enum DNSLookupStatus {
    OK = 'OK',
    NXDomain = 'NXDomain'
}
