<span class="entity-attribute-badge entity-attribute-badge_{{ badgeModifier }} mc-badge mc-badge_{{ badgeSize }} mc-badge_solid">
    <entity-attribute-value
        class="entity-attribute-badge__value"
        [attributeName]="attributeName"
        [attributeValue]="attributeValue">
    </entity-attribute-value>
    <span *ngIf="hasConfidence"
          class="entity-attribute-badge__confidence layout-margin-left-s"
          [mcTooltip]="'entities.ObservableEntities.Pseudo.Text.Confidence' | transloco">{{ confidence | formatConfidence }}</span>
</span>
