<form [formGroup]="filtersForm" (ngSubmit)="handleApplyFilters()">
    <mc-form-field class="text-filter"
                   [mcTooltip]="'common.Common.Validation.Text.StringLengthMax' | transloco: { max: valueMaxLength }"
                   #tooltip="mcTooltip"
                   mcTrigger="manual"
                   mcPlacement="top">
        <i mcPrefix mc-icon="mc-search_16" color="second"></i>
        <input
            [formControl]="textFilterControl"
            mcInput
            autocomplete="off"
            [placeholder]="placeholder"
            (input)="handleInput($event)">
        <i mc-icon
           mcSuffix class="pt-icons pt-icons-child-link_16 enter-icon"
           color="second"
           (click)="handleApplyFilters()">
        </i>
    </mc-form-field>
</form>
