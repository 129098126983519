import { createAction, props } from '@ngrx/store';

export const setIsLegacyMode = createAction('[Router] set isLegacyMode', props<{ isLegacyMode: boolean }>());

export const setLegacyUrl = createAction('[Router] set legacy url', props<{ legacyUrl: string }>());

export const setIsNavbarVisible = createAction('[Router] set isNavbarVisible', props<{ isNavbarVisible: boolean }>());

export const setIsChangedForm = createAction('[Router] set isChangedForm', props<{ isChangedForm: boolean }>());

export const setPageTitle = createAction('[Router] set page title', props<{ pageTitle: string }>());
