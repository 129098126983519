import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NotificationsService } from '../../services';
import { NotificationType } from '../notification-message/notification-message.component';

/**
 * @component CopiedRow
 *
 * @description
 * Component for displaying value with copy button for copy it
 *
 * @param copiedValue - Value, which will be copied
 * @param copiedMessage - Message, which will displayed by notification alert. Optional.
 *
 * @example
 * ```html
 *
 * <copied-row [copiedValue]="'some-value'" [copiedMessage]="''some-value' is copied'">
 *      <span>some-value</span>
 * </copied-row>
 *
 * ```
 */
@Component({
    selector: 'copied-row',
    styleUrls: ['./copied-row.component.scss'],
    templateUrl: './copied-row.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopiedRowComponent {
    @Input() copiedValue: string;
    @Input() copiedMessage: string;

    constructor(private notificationsService: NotificationsService) {}

    handleCopy(): void {
        if (this.copiedMessage) {
            this.notificationsService.show({
                type: NotificationType.Success,
                closable: false,
                text: this.copiedMessage
            });
        }
    }
}
