import { ObservableEntityType } from '../common';

import {
    TAssociatedAttribute,
    TAssociatedAttributesSection,
    TAssociatedAttributeValues
} from './associated-attributes-section';
import { AttributeObservableEntity } from './attributes';
import { TGeoIPSection } from './geo-ip-section';
import { TLabelsSection } from './labels-section';
import { TNaturalAttribute, TNaturalAttributesSection, TNaturalAttributeValues } from './natural-attributes-section';
import { TThreatSection } from './threat-section';

export enum AggregateSection {
    Threat = 'Threat',
    Labels = 'Labels',
    NaturalAttributes = 'NaturalAttributes',
    AssociatedAttributes = 'AssociatedAttributes',
    GeoIP = 'GeoIP'
}

export const AGGREGATE_SECTIONS = Object.keys(AggregateSection).map<AggregateSection>(
    (section) => AggregateSection[section]
);

// prettier-ignore
export type TAggregateSection<OET = ObservableEntityType> =
    OET extends ObservableEntityType.DomainName ? TDomainNameAggregateSection :
    OET extends ObservableEntityType.IPAddress ? TIpAddressAggregateSection :
    OET extends ObservableEntityType.EmailAddress ? TEmailAddressAggregateSection :
    OET extends ObservableEntityType.URL ? TUrlAggregateSection :
    OET extends ObservableEntityType.PhoneNumber ? TPhoneNumberAggregateSection :
    OET extends ObservableEntityType.Identity ? TIdentityAggregateSection :
    OET extends ObservableEntityType.File ? TFileAggregateSection :
        | TDomainNameAggregateSection
        | TIpAddressAggregateSection
        | TEmailAddressAggregateSection
        | TUrlAggregateSection
        | TPhoneNumberAggregateSection
        | TIdentityAggregateSection
        | TFileAggregateSection;

export type TAggregateSectionDataByEntityType<
    OET extends ObservableEntityType,
    SectionName extends AggregateSection
> = Extract<TAggregateSection<OET>, { name: SectionName }>['data'];

// prettier-ignore
export type TSection<SectionName = AggregateSection> =
    SectionName extends AggregateSection.AssociatedAttributes ? TAssociatedAttributesSection :
    SectionName extends AggregateSection.NaturalAttributes ? TNaturalAttributesSection :
    SectionName extends AggregateSection.Threat ? TThreatSection :
    SectionName extends AggregateSection.GeoIP ? TGeoIPSection :
    SectionName extends AggregateSection.Labels ? TLabelsSection :
        | TAssociatedAttributesSection
        | TNaturalAttributesSection
        | TLabelsSection
        | TThreatSection
        | TGeoIPSection;

export interface IAggregateSection<SectionType extends AggregateSection, SectionData> {
    name: SectionType;
    data: SectionData;
}

export type TDomainNameAggregateSection =
    | TAssociatedAttributesSection<ObservableEntityType.DomainName>
    | TNaturalAttributesSection<ObservableEntityType.DomainName>
    | TLabelsSection
    | TThreatSection;

export type TIpAddressAggregateSection =
    | TAssociatedAttributesSection<ObservableEntityType.IPAddress>
    | TNaturalAttributesSection<ObservableEntityType.IPAddress>
    | TGeoIPSection
    | TLabelsSection
    | TThreatSection;

export type TEmailAddressAggregateSection =
    | TAssociatedAttributesSection<ObservableEntityType.EmailAddress>
    | TNaturalAttributesSection<ObservableEntityType.EmailAddress>
    | TLabelsSection
    | TThreatSection;

export type TUrlAggregateSection =
    | TAssociatedAttributesSection<ObservableEntityType.URL>
    | TNaturalAttributesSection<ObservableEntityType.URL>
    | TLabelsSection
    | TThreatSection;

export type TPhoneNumberAggregateSection =
    | TAssociatedAttributesSection<ObservableEntityType.PhoneNumber>
    | TLabelsSection;

export type TIdentityAggregateSection = TNaturalAttributesSection<ObservableEntityType.Identity> | TLabelsSection;

export type TFileAggregateSection =
    | TAssociatedAttributesSection<ObservableEntityType.File>
    | TNaturalAttributesSection<ObservableEntityType.File>
    | TLabelsSection
    | TThreatSection;

// prettier-ignore
export type TAttributeValuesType<
    OET extends ObservableEntityType,
    AttributeName extends AttributeObservableEntity
> =
    AttributeName extends TAssociatedAttribute ? TAssociatedAttributeValues<OET, AttributeName> :
    AttributeName extends TNaturalAttribute ? TNaturalAttributeValues<OET, AttributeName> :
        never
