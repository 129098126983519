import { translate } from '@ngneat/transloco';

import {
    AttributeObservableEntity,
    IDictionaryItemBrief,
    ObservableEntityType,
    TAttributeValueType,
    TCollectionAttribute,
    TVerdictAttribute
} from '@pt-cybsi/api-interfaces';

export const getObservableEntityAttributeId = (
    attributeName: AttributeObservableEntity,
    entityType?: ObservableEntityType
): string => {
    const entitySpecificAttributes = [AttributeObservableEntity.Names, AttributeObservableEntity.Statuses];

    const isEntitySpecificAttribute = !!entityType && entitySpecificAttributes.includes(attributeName);

    const prefix = isEntitySpecificAttribute ? entityType.toString() : 'Common';

    return `${prefix}-${attributeName}`;
};

export const isCollectionAttribute = (
    attributeName: AttributeObservableEntity
): attributeName is TCollectionAttribute =>
    [
        AttributeObservableEntity.Names,
        AttributeObservableEntity.DisplayNames,
        AttributeObservableEntity.Sectors,
        AttributeObservableEntity.NodeRoles,
        AttributeObservableEntity.Statuses,
        AttributeObservableEntity.MalwareClasses,
        AttributeObservableEntity.MalwareFamilies,
        AttributeObservableEntity.RelatedMalwareFamilies,
        AttributeObservableEntity.MalwareNames,
        AttributeObservableEntity.TargetedSectors,
        AttributeObservableEntity.ExploitedVulnerabilities,
        AttributeObservableEntity.AffectedCountries,
        AttributeObservableEntity.Campaigns,
        AttributeObservableEntity.ThreatActors
    ].includes(attributeName);

export const isVerdictAttribute = (attributeName: AttributeObservableEntity): attributeName is TVerdictAttribute =>
    [
        AttributeObservableEntity.IsIoC,
        AttributeObservableEntity.IsTrusted,
        AttributeObservableEntity.IsDGA,
        AttributeObservableEntity.IsDelegated,
        AttributeObservableEntity.ThreatCategory,
        AttributeObservableEntity.RelatedThreatCategory
    ].includes(attributeName);

export const compareAttributeValues = <Attribute extends AttributeObservableEntity>(
    attributeName: Attribute,
    leftValue: TAttributeValueType<Attribute>,
    rightValue: TAttributeValueType<Attribute>
): boolean => {
    switch (attributeName) {
        case AttributeObservableEntity.Sectors:
        case AttributeObservableEntity.Statuses:
        case AttributeObservableEntity.MalwareClasses:
        case AttributeObservableEntity.MalwareFamilies:
        case AttributeObservableEntity.RelatedMalwareFamilies:
        case AttributeObservableEntity.TargetedSectors:
        case AttributeObservableEntity.ExploitedVulnerabilities:
        case AttributeObservableEntity.AffectedCountries:
        case AttributeObservableEntity.Campaigns:
        case AttributeObservableEntity.ThreatActors:
            return (leftValue as IDictionaryItemBrief).uuid === (rightValue as IDictionaryItemBrief).uuid;

        default:
            return leftValue === rightValue;
    }
};

export const getLocalizedAttributeName = (
    attributeName: AttributeObservableEntity,
    entityType: ObservableEntityType
): string => {
    const attributeId = getObservableEntityAttributeId(attributeName, entityType);

    return translate(attributeNameTranslationKeys[attributeId] || '');
};

export const getAttributeCollectionTitle = (
    attributeName: AttributeObservableEntity,
    entityType: ObservableEntityType,
    count: number
): string => {
    const attributeId = getObservableEntityAttributeId(attributeName, entityType);

    return translate(attributeCollectionTitleTranslationKeys[attributeId] || '', { count });
};

const attributeNameTranslationKeys: Record<string, string> = {
    [getObservableEntityAttributeId(AttributeObservableEntity.Names, ObservableEntityType.File)]:
        'entities.ObservableEntities.AttributeName.Text.FileNames',

    [getObservableEntityAttributeId(AttributeObservableEntity.Names, ObservableEntityType.Identity)]:
        'entities.ObservableEntities.AttributeName.Text.IdentityNames',

    [getObservableEntityAttributeId(AttributeObservableEntity.Statuses, ObservableEntityType.IPAddress)]:
        'entities.ObservableEntities.AttributeName.Text.IpAddressStatuses',

    [getObservableEntityAttributeId(AttributeObservableEntity.Statuses, ObservableEntityType.DomainName)]:
        'entities.ObservableEntities.AttributeName.Text.DomainNameStatuses',

    [getObservableEntityAttributeId(AttributeObservableEntity.ThreatCategory)]:
        'entities.ObservableEntities.AttributeName.Text.ThreatCategory',

    [getObservableEntityAttributeId(AttributeObservableEntity.RelatedThreatCategory)]:
        'entities.ObservableEntities.AttributeName.Text.ThreatCategory',

    [getObservableEntityAttributeId(AttributeObservableEntity.IsIoC)]:
        'entities.ObservableEntities.AttributeName.Text.IsIoC',

    [getObservableEntityAttributeId(AttributeObservableEntity.IsDGA)]:
        'entities.ObservableEntities.AttributeName.Text.IsDGA',

    [getObservableEntityAttributeId(AttributeObservableEntity.IsTrusted)]:
        'entities.ObservableEntities.AttributeName.Text.IsTrusted',

    [getObservableEntityAttributeId(AttributeObservableEntity.IsDelegated)]:
        'entities.ObservableEntities.AttributeName.Text.IsDelegated',

    [getObservableEntityAttributeId(AttributeObservableEntity.Size)]:
        'entities.ObservableEntities.AttributeName.Text.Size',

    [getObservableEntityAttributeId(AttributeObservableEntity.ASN)]:
        'entities.ObservableEntities.AttributeName.Text.ASN',

    [getObservableEntityAttributeId(AttributeObservableEntity.RegionalInternetRegistry)]:
        'entities.ObservableEntities.AttributeName.Text.RegionalInternetRegistry',

    [getObservableEntityAttributeId(AttributeObservableEntity.Class)]:
        'entities.ObservableEntities.AttributeName.Text.Class',

    [getObservableEntityAttributeId(AttributeObservableEntity.DisplayNames)]:
        'entities.ObservableEntities.AttributeName.Text.DisplayNames',

    [getObservableEntityAttributeId(AttributeObservableEntity.NodeRoles)]:
        'entities.ObservableEntities.AttributeName.Text.NodeRoles',

    [getObservableEntityAttributeId(AttributeObservableEntity.Sectors)]:
        'entities.ObservableEntities.AttributeName.Text.Sectors',

    [getObservableEntityAttributeId(AttributeObservableEntity.MalwareNames)]:
        'entities.ObservableEntities.AttributeName.Text.MalwareNames',

    [getObservableEntityAttributeId(AttributeObservableEntity.MalwareClasses)]:
        'entities.ObservableEntities.AttributeName.Text.MalwareClasses',

    [getObservableEntityAttributeId(AttributeObservableEntity.MalwareFamilies)]:
        'entities.ObservableEntities.AttributeName.Text.MalwareFamilies',

    [getObservableEntityAttributeId(AttributeObservableEntity.RelatedMalwareFamilies)]:
        'entities.ObservableEntities.AttributeName.Text.RelatedMalwareFamilies',

    [getObservableEntityAttributeId(AttributeObservableEntity.Campaigns)]:
        'entities.ObservableEntities.AttributeName.Text.Campaigns',

    [getObservableEntityAttributeId(AttributeObservableEntity.ThreatActors)]:
        'entities.ObservableEntities.AttributeName.Text.ThreatActors',

    [getObservableEntityAttributeId(AttributeObservableEntity.AffectedCountries)]:
        'entities.ObservableEntities.AttributeName.Text.AffectedCountries',

    [getObservableEntityAttributeId(AttributeObservableEntity.ExploitedVulnerabilities)]:
        'entities.ObservableEntities.AttributeName.Text.ExploitedVulnerabilities',

    [getObservableEntityAttributeId(AttributeObservableEntity.TargetedSectors)]:
        'entities.ObservableEntities.AttributeName.Text.TargetedSectors'
};

const attributeCollectionTitleTranslationKeys: Record<string, string> = {
    [getObservableEntityAttributeId(AttributeObservableEntity.Names, ObservableEntityType.File)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.FileNames',

    [getObservableEntityAttributeId(AttributeObservableEntity.Names, ObservableEntityType.Identity)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.IdentityNames',

    [getObservableEntityAttributeId(AttributeObservableEntity.Statuses, ObservableEntityType.IPAddress)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.IpAddressStatuses',

    [getObservableEntityAttributeId(AttributeObservableEntity.Statuses, ObservableEntityType.DomainName)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.DomainNameStatuses',

    [getObservableEntityAttributeId(AttributeObservableEntity.DisplayNames)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.DisplayNames',

    [getObservableEntityAttributeId(AttributeObservableEntity.NodeRoles)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.NodeRoles',

    [getObservableEntityAttributeId(AttributeObservableEntity.Sectors)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.Sectors',

    [getObservableEntityAttributeId(AttributeObservableEntity.MalwareNames)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.MalwareNames',

    [getObservableEntityAttributeId(AttributeObservableEntity.MalwareClasses)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.MalwareClasses',

    [getObservableEntityAttributeId(AttributeObservableEntity.MalwareFamilies)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.MalwareFamilies',

    [getObservableEntityAttributeId(AttributeObservableEntity.RelatedMalwareFamilies)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.RelatedMalwareFamilies',

    [getObservableEntityAttributeId(AttributeObservableEntity.Campaigns)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.Campaigns',

    [getObservableEntityAttributeId(AttributeObservableEntity.ThreatActors)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.ThreatActors',

    [getObservableEntityAttributeId(AttributeObservableEntity.AffectedCountries)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.AffectedCountries',

    [getObservableEntityAttributeId(AttributeObservableEntity.ExploitedVulnerabilities)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.ExploitedVulnerabilities',

    [getObservableEntityAttributeId(AttributeObservableEntity.TargetedSectors)]:
        'entities.ObservableEntities.AttributeName.CollectionTitle.Sectors'
};
