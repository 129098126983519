import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { DefaultErrorCodes } from '@pt-cybsi/api-interfaces';
import { ErrorFacade } from '@pt-cybsi/store/error';

import { IRouterStateParams } from '../types';

export const rootLocation = '';
export const welcomeLocation = '/welcome';
export const signInLocation = '/account/signin';
export const errorLocation = '/error';
export const notContentPages = [rootLocation, welcomeLocation, signInLocation, errorLocation];

@Injectable({ providedIn: 'root' })
export class LocationService {
    private firstAvailablePageUrl: string;

    constructor(
        private window: Window,
        private location: Location,
        private router: Router,
        private errorFacade: ErrorFacade
    ) {}

    get entryUrl(): string {
        return sessionStorage.getItem('entryUrl');
    }

    saveEntryUrl(url: string) {
        if (notContentPages.indexOf(url) === -1) {
            sessionStorage.setItem('entryUrl', url);
        }
    }

    resetEntryUrl(): void {
        sessionStorage.removeItem('entryUrl');
    }

    saveFirstAvailablePageUrl(url: string): void {
        this.firstAvailablePageUrl = url;
    }

    reload() {
        this.window.location.reload();
    }

    redirectToEntryUrl() {
        if (this.entryUrl) {
            this.router.navigateByUrl(this.entryUrl);
            sessionStorage.removeItem('entryUrl');
        } else {
            this.redirectToFirstAvailablePage();
        }
    }

    redirectToFirstAvailablePage() {
        if (this.router.url === this.firstAvailablePageUrl || this.router.url === signInLocation) {
            this.window.location.reload();

            return;
        }

        if (this.firstAvailablePageUrl) {
            this.router.navigateByUrl(this.firstAvailablePageUrl);
        } else {
            this.redirectToForbiddenPage();
        }
    }

    redirectToWelcomePage() {
        this.router.navigateByUrl(welcomeLocation, { skipLocationChange: true });
    }

    redirectToSignInPage(state: IRouterStateParams = {}) {
        this.saveEntryUrl(this.location.path());

        this.router.navigateByUrl(signInLocation, { state });
    }

    redirectToErrorPage(error: string = DefaultErrorCodes.UNKNOWN) {
        this.errorFacade.setError(error);
        this.router.navigateByUrl(`${errorLocation}`);
    }

    redirectToForbiddenPage() {
        this.redirectToErrorPage(DefaultErrorCodes.EMPTY_PERMISSIONS);
    }
}
