export const ENRICHMENT_RULE_MIN_LENGTH = 1;
export const ENRICHMENT_RULE_MAX_LENGTH = 250;

export const validateEnrichmentRuleNameLength = (name: string) =>
    name === undefined ||
    name === null ||
    name === '' ||
    (name.length >= ENRICHMENT_RULE_MIN_LENGTH && name.length <= ENRICHMENT_RULE_MAX_LENGTH);

export const MIN_ENRICHMENT_RULE_THROTTLING_INTERVAL = 3600000;

// prettier-ignore
export const validateMinEnrichmentRuleThrottlingInterval = (interval: number) =>
    interval === null ||
    interval === undefined ||
    interval >= MIN_ENRICHMENT_RULE_THROTTLING_INTERVAL;
