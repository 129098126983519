<div *ngIf="isLoading" class="async-data_loading">
    <div class="async-data_loader async-data_loader_small">
        <mc-progress-spinner
            class="async-data__spinner"
            mode="indeterminate">
        </mc-progress-spinner>
    </div>
</div>

<div *ngIf="isFailure && errorTranslationKey" class="async-data__error-message">
    <div class='async-data__error-alert'>
        <div class="mc-alert mc-alert_error">
            <i mc-icon="mc-error_16" class="mc-alert__icon"></i>
            <div>
                <span>{{ errorTranslationKey | transloco | translateCut:0 }}</span>
                <button mc-link pseudo class="mc-link clear-button" (click)="handleReload()">
                    <span class="mc-link__text">{{ errorTranslationKey | transloco | translateCut:1 }}</span>
                </button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isSuccess">
    <ng-container *ngTemplateOutlet="successTemplate; context: { data: successTemplateData }"></ng-container>
</div>
