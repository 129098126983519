import { TValueObjectProps, ValueObject } from './value-object';

export enum FormStateValue {
    Initializing = 'Initializing',
    InitializingFailure = 'InitializingFailure',
    ReadyForInput = 'ReadyForInput',
    Validating = 'Validating',
    ValidatingFailure = 'ValidatingFailure',
    ReadyForSave = 'ReadyForSave',
    Saving = 'Saving',
    SavingFailure = 'SavingFailure',
    Saved = 'Saved'
}

type TFormStateProps = TValueObjectProps<{
    value: FormStateValue;
}>;

export class FormState extends ValueObject<TFormStateProps> {
    get value(): FormStateValue {
        return this.props.value;
    }

    private readonly transitions: Record<FormStateValue, FormStateValue[]> = {
        [FormStateValue.Initializing]: [FormStateValue.InitializingFailure, FormStateValue.ReadyForInput],

        [FormStateValue.InitializingFailure]: [FormStateValue.Initializing],

        [FormStateValue.ReadyForInput]: [FormStateValue.Validating, FormStateValue.ReadyForSave],

        [FormStateValue.Validating]: [FormStateValue.ValidatingFailure, FormStateValue.ReadyForSave],

        [FormStateValue.ValidatingFailure]: [FormStateValue.Validating],

        [FormStateValue.ReadyForSave]: [FormStateValue.Validating, FormStateValue.Saving],

        [FormStateValue.Saving]: [FormStateValue.SavingFailure, FormStateValue.Saved],

        [FormStateValue.SavingFailure]: [FormStateValue.Validating],

        [FormStateValue.Saved]: []
    };

    private get nextAvailableStateValues(): FormStateValue[] {
        return this.transitions[this.value];
    }

    constructor(props: TFormStateProps) {
        super(props);
    }

    static create(value?: FormStateValue): FormState {
        return new FormState({ value: value || FormStateValue.Initializing });
    }

    to(nextStateValue: FormStateValue): FormState {
        if (this.isAvailableSwitching(nextStateValue)) {
            return FormState.create(nextStateValue);
        }

        this.throwSwitchError(nextStateValue);
    }

    private isAvailableSwitching(nextStateValue: FormStateValue): boolean {
        return this.transitions[this.value].indexOf(nextStateValue) !== -1;
    }

    private throwSwitchError(nextStateValue: FormStateValue): void {
        const availableStates = this.nextAvailableStateValues;

        if (availableStates.length > 0) {
            throw Error(
                `The form state ${nextStateValue} can be set, if the current state is ${availableStates.join(
                    ' | '
                )}, but a current state is ${this.value}`
            );
        } else {
            throw Error(
                `The form state ${nextStateValue} can't be set, because it has not available next states for a current state ${this.value}`
            );
        }
    }
}
