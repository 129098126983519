<mc-button-toggle-group [disabled]="disabled" [value]="toggleGroupModel">
    <ng-container *ngFor="let item of fieldItems">
        <ng-container [ngSwitch]="item.type">
            <mc-button-toggle
                *ngSwitchCase="DateRangeFieldItemType.Relative"

                [value]="item"
                (change)="handleSelectRelativeItem($event)">
                {{ getFieldItemText(item) }}
            </mc-button-toggle>

            <mc-button-toggle
                *ngSwitchCase="DateRangeFieldItemType.Absolute"

                [value]="item"
                (change)="handleSelectAbsoluteItem($event)"

                #absoluteDateRangePopover="mcPopover"
                mcPopover
                mcPopoverClass="absolute-date-range-popover"
                mcPopoverPlacement="bottom"
                [mcPopoverContent]="absoluteItemContent"
                [mcPopoverFooter]="absoluteItemFooter"
                (mcPopoverVisibleChange)="handleAbsoluteDateRangePopoverVisibleChange($event)">
                {{ getFieldItemText(item) }}
            </mc-button-toggle>
        </ng-container>

    </ng-container>
</mc-button-toggle-group>

<ng-template #absoluteItemContent>
    <div class="layout-row mc-group">
        <mc-form-field class="absolute-date-range-popover__field mc-group-item" (click)="dateFromPicker.toggle()">
            <mc-datepicker #dateFromPicker [maxDate]="toDatePickerModel"></mc-datepicker>

            <input
                [(ngModel)]="fromDatePickerModel"
                [placeholder]="'common.Common.Pseudo.Datepicker.Placeholder' | transloco"
                [mcDatepicker]="dateFromPicker"/>
            <i mc-icon="mc-calendar_16" mcSuffix></i>
        </mc-form-field>

        <mc-form-field class="absolute-date-range-popover__field mc-group-item" (click)="dateToPicker.toggle()">
            <mc-datepicker #dateToPicker [minDate]="fromDatePickerModel"></mc-datepicker>

            <input
                [(ngModel)]="toDatePickerModel"
                [placeholder]="'common.Common.Pseudo.Datepicker.Placeholder' | transloco"
                [mcDatepicker]="dateToPicker"/>
            <i mc-icon="mc-calendar_16" mcSuffix></i>
        </mc-form-field>
    </div>
</ng-template>

<ng-template #absoluteItemFooter>
    <div class="flex-100 layout-row layout-align-end">
        <button mc-button
                color="primary"
                [disabled]="isApplyAbsoluteRangeDisabled"
                (click)="handleApplyAbsoluteRange()">
            {{ 'common.Common.Pseudo.Button.Apply' | transloco }}
        </button>
        <button mc-button
                color="second"
                class="margin-left_8"
                (click)="handleCancelAbsoluteRange()">
            {{ 'common.Common.Pseudo.Button.Cancel' | transloco }}
        </button>
    </div>
</ng-template>
