<mc-progress-bar
    *ngIf="isProgressState"
    mode="indeterminate"
    class="search-page-layout__loader">
</mc-progress-bar>

<div *ngIf="hasHeaderSlot"
     class="search-page-layout__header layout-row layout-align-start-center layout-padding-left-l">
    <ng-content *ngTemplateOutlet="headerTemplate"></ng-content>
</div>
<div *ngIf="isFiltersSlotVisible"
     class="search-page-layout__filters">
    <ng-content *ngTemplateOutlet="filtersTemplate"></ng-content>
</div>
<div class="search-page-layout__body">
    <div *ngIf="isErrorsSlotVisible"
         class="search-page-layout__errors">
        <ng-content *ngTemplateOutlet="errorsTemplate"></ng-content>
    </div>
    <div *ngIf="isResultsSlotVisible"
         class="search-page-layout__results">
        <ng-content *ngTemplateOutlet="resultsTemplate"></ng-content>
    </div>
    <ng-container *ngIf="isEmptyResultsSlotVisible">
        <mc-divider></mc-divider>
        <div class="search-page-layout__empty-results">
            <ng-content *ngTemplateOutlet="emptyResultsTemplate"></ng-content>
        </div>
    </ng-container>
    <div *ngIf="isNotFoundResultsSlotVisible"
         class="search-page-layout__not-found-results">
        <ng-content *ngTemplateOutlet="notFoundResultsTemplate"></ng-content>
    </div>
</div>
