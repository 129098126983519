import { Pipe, PipeTransform } from '@angular/core';
import repeat from 'lodash/repeat';

import { LocaleService, Locale } from '@pt-cybsi/i18n';

import { ConfidenceModel } from '../models';

export enum ConfidenceFormat {
    Preview = 'Preview',
    Full = 'Full'
}

@Pipe({
    name: 'formatConfidence'
})
export class FormatConfidencePipe implements PipeTransform {
    private accuracyByFormat: Record<ConfidenceFormat, number> = {
        [ConfidenceFormat.Preview]: 1,
        [ConfidenceFormat.Full]: 3
    };

    constructor(private localeService: LocaleService) {}

    transform(confidence: number, format: ConfidenceFormat = ConfidenceFormat.Preview): string {
        if (confidence === 0) {
            return '0';
        }

        const confidenceModel = ConfidenceModel.create(confidence);

        const accuracy = this.accuracyByFormat[format] || 1;

        const { value: roundedConfidence } = confidenceModel.round(accuracy);

        if (roundedConfidence === 0) {
            const zeros = repeat('0', accuracy);
            const separator = this.localeService.currentLocale === Locale.RuRu ? ',' : '.';

            return `~ 0${separator}${zeros}`;
        }

        return roundedConfidence.toLocaleString(this.localeService.currentLocale);
    }
}
