export const DICTIONARY_ITEM_MIN_LENGTH = 1;
export const DICTIONARY_ITEM_MAX_LENGTH = 30;

export const validateDictionaryItemNameLength = (dictionaryItemName: string) =>
    dictionaryItemName === undefined ||
    dictionaryItemName === null ||
    dictionaryItemName === '' ||
    (dictionaryItemName.length >= DICTIONARY_ITEM_MIN_LENGTH &&
        dictionaryItemName.length <= DICTIONARY_ITEM_MAX_LENGTH);

export const validateDictionaryItemNameFormat = (dictionaryItemName: string) =>
    dictionaryItemName === undefined ||
    dictionaryItemName === null ||
    dictionaryItemName === '' ||
    /^[a-zA-Z0-9_\-:\s]+$/.test(dictionaryItemName);
