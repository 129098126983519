import { Component, Input } from '@angular/core';

import {
    AttributeObservableEntity,
    TAttributeValueType,
    RelatedThreatCategory,
    ThreatCategory
} from '@pt-cybsi/api-interfaces';

import { ThreatLevelMapper } from '../../mappers';

export enum EntityAttributeBadgeSize {
    Default = 'default',
    Mini = 'mini'
}

export type EntityBadgeAttribute =
    | AttributeObservableEntity.IsIoC
    | AttributeObservableEntity.IsTrusted
    | AttributeObservableEntity.IsDGA
    | AttributeObservableEntity.IsDelegated
    | AttributeObservableEntity.ThreatCategory
    | AttributeObservableEntity.RelatedThreatCategory;

const DEFAULT_SIZE = EntityAttributeBadgeSize.Default;

/**
 * @component EntityAttributeBadge
 *
 * @description
 * Component for displaying an entity attribute as a badge
 *
 * @param attributeName - Attribute name from EntityBadgeAttribute type
 * @param attributeValue - Attribute value of attribute
 * @param size - Badge size from EntityAttributeBadgeSize, EntityAttributeBadgeSize.Mini by default
 * @param confidence - Confidence of fact about attribute. Optional
 *
 * @example
 * ```html
 *
 * <entity-attribute-badge
 *       [attributeName]="EntityBadgeAttribute.IsDelegated"
 *       [attributeValue]="true"
 *       [size]="EntityAttributeBadgeSize.Default"
 *       [confidence]="0.3">
 * </entity-attribute-badge>
 *
 * ```
 */
@Component({
    selector: 'entity-attribute-badge',
    templateUrl: './entity-attribute-badge.component.html',
    styleUrls: ['./entity-attribute-badge.component.scss']
})
export class EntityAttributeBadgeComponent {
    @Input() attributeName: EntityBadgeAttribute;
    @Input() attributeValue: TAttributeValueType<EntityBadgeAttribute>;
    @Input() size: EntityAttributeBadgeSize;
    @Input() confidence: number;

    get badgeSize(): string {
        return this.size || DEFAULT_SIZE;
    }

    get badgeModifier(): string {
        switch (this.attributeName) {
            case AttributeObservableEntity.ThreatCategory:
                return `${this.attributeName}-${ThreatLevelMapper.fromThreatCategory(
                    this.attributeValue as ThreatCategory
                )}`;

            case AttributeObservableEntity.RelatedThreatCategory:
                return `${this.attributeName}-${ThreatLevelMapper.fromRelatedThreatCategory(
                    this.attributeValue as RelatedThreatCategory
                )}`;

            default:
                return this.attributeName;
        }
    }

    get hasConfidence(): boolean {
        const hasConfidenceValue = typeof this.confidence === 'number' && this.confidence >= 0;

        switch (this.attributeName) {
            case AttributeObservableEntity.ThreatCategory:
            case AttributeObservableEntity.RelatedThreatCategory:
                return this.attributeValue && hasConfidenceValue;

            default:
                return hasConfidenceValue;
        }
    }
}
