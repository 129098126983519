import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { FormStateValue, TBaseModel } from '../models';
import { IFormRepository } from '../repositories';
import { IObservableUseCase } from '../types';

export interface IValidateFormParams {
    id: string;
}

export class ValidateFormUseCase<Model extends TBaseModel> implements IObservableUseCase<IValidateFormParams, boolean> {
    constructor(private formRepository: IFormRepository<Model>) {}

    execute(params: IValidateFormParams): Observable<boolean> {
        const { id } = params;

        const currentState = this.formRepository.getState(id);

        if (currentState.value === FormStateValue.Saved) {
            return of(true);
        }

        this.switchState(id, FormStateValue.Validating);

        return this.formRepository.validate(id).pipe(
            tap((isValid) => {
                const nextFormState = isValid ? FormStateValue.ReadyForSave : FormStateValue.ValidatingFailure;

                this.switchState(id, nextFormState);
            }),
            catchError((error: unknown) => {
                this.switchState(id, FormStateValue.ValidatingFailure);

                return throwError(error);
            })
        );
    }

    private switchState(id: string, state: FormStateValue): void {
        const formState = this.formRepository.getState(id);

        const nextFormState = formState.to(state);

        this.formRepository.updateState(id, nextFormState);
    }
}
