import { Injectable, Provider } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { IServerError } from '@pt-cybsi/api-interfaces';
import { FormStateValue, IFormRepository, IObservableUseCase } from '@pt-cybsi/shared';

import { AnalyserModel } from '../../models';
import { AnalyserFormRepository, AnalyserFormRepositoryProvider } from '../../repositories';

export interface IRegisterAnalyserParams {
    id: string;
}

export type TRegisteredAnalyserId = string;

@Injectable()
export class RegisterAnalyserUseCase implements IObservableUseCase<IRegisterAnalyserParams, TRegisteredAnalyserId> {
    constructor(private repository: IFormRepository<AnalyserModel>) {}

    execute(params: IRegisterAnalyserParams): Observable<TRegisteredAnalyserId> {
        const { id } = params;

        const analyser = this.repository.getCurrentData(id);
        const currentFormState = this.repository.getState(id);

        if (currentFormState.value === FormStateValue.Saved) {
            return of(analyser.props.serverId);
        }

        this.repository.resetSavingError(id);

        this.switchState(id, FormStateValue.Saving);

        return this.repository.save(analyser).pipe(
            tap((serverId: string) => {
                this.switchState(id, FormStateValue.Saved);

                this.updateAnalyser(id, serverId);

                this.repository.disable(id);
            }),
            catchError((error: unknown) => {
                this.switchState(id, FormStateValue.SavingFailure);

                this.repository.updateSavingError(id, error as IServerError);

                return throwError(error);
            })
        );
    }

    private updateAnalyser(id: string, serverId: string): void {
        const analyser = this.repository.getCurrentData(id);

        analyser.props.serverId = serverId;

        this.repository.updateCurrentData(analyser);
    }

    private switchState(id: string, state: FormStateValue): void {
        const formState = this.repository.getState(id);

        const nextFormState = formState.to(state);

        this.repository.updateState(id, nextFormState);
    }
}

export const RegisterAnalyserUseCaseProvider: Provider = [
    AnalyserFormRepositoryProvider,
    {
        provide: RegisterAnalyserUseCase,
        useFactory: (repository: AnalyserFormRepository) => new RegisterAnalyserUseCase(repository),
        deps: [AnalyserFormRepository]
    }
];
