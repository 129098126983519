import { Injectable } from '@angular/core';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import { Observable } from 'rxjs';

import {
    IApiItemPreview,
    IDataSource,
    IDataSourceAssociatedObjects,
    IDataSourceCreateParams,
    IDataSourceLinks,
    IDataSourcesGetParams,
    IDataSourceType,
    IDataSourceTypeCreateParams,
    IDataSourceTypesGetParams,
    IDataSourceTypeUpdateParams,
    IDataSourceUpdateParams,
    TDataSourcesResponse,
    TDataSourceTypesResponse,
    TEditableDataSourceResponse,
    TEditableDataSourceTypeResponse
} from '@pt-cybsi/api-interfaces';

import { BaseApiService, IFullListResponse } from './base-api.service';

@Injectable()
export class DataSourcesApiService extends BaseApiService {
    static readonly urls = {
        dataSources: 'data-sources',
        dataSourceTypes: 'data-source-types'
    };

    getSourceTypes(params: IDataSourceTypesGetParams): Observable<TDataSourceTypesResponse> {
        const url = DataSourcesApiService.urls.dataSourceTypes;

        return this.getPaginatedCollection(url, { params });
    }

    getAllSourceTypes(params: IDataSourceTypesGetParams): Observable<IFullListResponse<IDataSourceType>> {
        return this.getFullList<IDataSourceType>(DataSourcesApiService.urls.dataSourceTypes, { params });
    }

    getSourceType(uuid: string): Observable<IDataSourceType> {
        const url = `${DataSourcesApiService.urls.dataSourceTypes}/${uuid}`;

        return this.get(url);
    }

    getEditableSourceType(uuid: string): Observable<TEditableDataSourceTypeResponse> {
        const url = `${DataSourcesApiService.urls.dataSourceTypes}/${uuid}`;

        return this.getEditableResource(url);
    }

    createSourceType(params: IDataSourceTypeCreateParams): Observable<IApiItemPreview> {
        const url = DataSourcesApiService.urls.dataSourceTypes;

        return this.post(url, params);
    }

    updateSourceType(uuid: string, eTag: string, params: IDataSourceTypeUpdateParams): Observable<void> {
        const url = `${DataSourcesApiService.urls.dataSourceTypes}/${uuid}`;

        return this.updateResource(url, eTag, params);
    }

    getSources(params: IDataSourcesGetParams): Observable<TDataSourcesResponse> {
        const url = DataSourcesApiService.urls.dataSources;

        return this.getPaginatedCollection(url, {
            params: this.normalizeDataSourcesGetParams(params)
        });
    }

    getAllSources(params: IDataSourcesGetParams): Observable<IFullListResponse<IDataSource>> {
        return this.getFullList<IDataSource>(DataSourcesApiService.urls.dataSources, {
            params: this.normalizeDataSourcesGetParams(params)
        });
    }

    getSource(uuid: string): Observable<IDataSource> {
        const url = `${DataSourcesApiService.urls.dataSources}/${uuid}`;

        return this.get(url);
    }

    getSourceLinks(uuid: string): Observable<IDataSourceLinks> {
        const url = `${DataSourcesApiService.urls.dataSources}/${uuid}/links`;

        return this.get(url);
    }

    getAssociatedObjects(uuid: string): Observable<IDataSourceAssociatedObjects> {
        const url = `${DataSourcesApiService.urls.dataSources}/${uuid}/links`;

        return this.get(url, this.addResolveRefsParam());
    }

    getEditableSource(uuid: string): Observable<TEditableDataSourceResponse> {
        const url = `${DataSourcesApiService.urls.dataSources}/${uuid}`;

        return this.getEditableResource(url);
    }

    createSource(params: IDataSourceCreateParams): Observable<IApiItemPreview> {
        const url = DataSourcesApiService.urls.dataSources;

        return this.post(url, params);
    }

    updateSource(uuid: string, eTag: string, params: IDataSourceUpdateParams): Observable<void> {
        const url = `${DataSourcesApiService.urls.dataSources}/${uuid}`;

        return this.updateResource(url, eTag, params);
    }

    private normalizeDataSourcesGetParams(params: IDataSourcesGetParams): IDataSourcesGetParams {
        if (!params) {
            return params;
        }

        return omitBy(params, isEmpty);
    }
}
