import { NgModule, Optional, SkipSelf } from '@angular/core';
import { DateAdapter, MC_DATE_FORMATS, MC_DATE_LOCALE } from '@ptsecurity/cdk/datetime';
import { LuxonDateAdapter, MC_LUXON_DATE_FORMATS } from '@ptsecurity/mosaic-luxon-adapter/adapter';
import { DateFormatter } from '@ptsecurity/mosaic/core';

import { AppLocaleService, LocationService } from './services';

@NgModule({
    providers: [
        AppLocaleService,
        LocationService,
        { provide: Window, useValue: window },
        { provide: DateAdapter, useClass: LuxonDateAdapter },
        { provide: DateFormatter, deps: [DateAdapter, MC_DATE_LOCALE] },
        {
            provide: MC_DATE_FORMATS,
            useFactory: () => ({ ...MC_LUXON_DATE_FORMATS })
        }
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }
}
