import { EnrichmentType, IEnrichmentRule } from '@pt-cybsi/api-interfaces';

import { IEnrichmentRulesGridItem } from '../components';

export class EnrichmentRuleMapper {
    static toGridItem(rule: IEnrichmentRule): IEnrichmentRulesGridItem {
        const id = rule.uuid;

        return {
            id,
            name: {
                id,
                name: rule.name,
                isDisabled: rule.isDisabled
            },
            types: this.getEnrichmentTypes(rule),
            enrichmentType: rule.enrichment,
            triggers: rule.triggers,
            triggerDataSourceIds: (rule.triggerDataSources || []).map((dataSource) => dataSource.uuid),
            dataSourceIds: (rule.dataSources || []).map((dataSource) => dataSource.uuid)
        };
    }

    static toGridItems(rules: IEnrichmentRule[]): IEnrichmentRulesGridItem[] {
        return rules.map<IEnrichmentRulesGridItem>((rule) => EnrichmentRuleMapper.toGridItem(rule));
    }

    private static getEnrichmentTypes(rule: IEnrichmentRule) {
        return this.isArtifactEnrichment(rule)
            ? { artifactTypes: rule.artifactTypes }
            : { entityTypes: rule.entityTypes };
    }

    private static isArtifactEnrichment(rule: IEnrichmentRule): boolean {
        return [
            EnrichmentType.ArtifactAnalysis,
            EnrichmentType.ArtifactDownload,
            EnrichmentType.ArchiveUnpack
        ].includes(rule.enrichment);
    }
}
