import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import * as AccountActions from './account.actions';
import * as AccountSelectors from './account.selectors';

@Injectable()
export class AccountFacade {
    isTokenRefreshing$ = this.store.pipe(select(AccountSelectors.isTokenRefreshing));
    isSessionExpired$ = this.store.pipe(select(AccountSelectors.isSessionExpired));
    hasToken$ = this.store.pipe(select(AccountSelectors.hasAccountToken));
    hasNotToken$ = this.hasToken$.pipe(map((hasToken) => !hasToken));
    token$ = this.store.pipe(select(AccountSelectors.getAccountToken));
    user$ = this.store.pipe(select(AccountSelectors.getUserInfo));
    hasUserWithPermissions$ = this.user$.pipe(map((user) => (user?.permissions || []).length > 0));
    userDisplayName$ = this.store.pipe(select(AccountSelectors.getUserDisplayName));
    userAccessLevel$ = this.store.pipe(select(AccountSelectors.getUserAccessLevel));
    authProviders$ = this.store.pipe(select(AccountSelectors.getAuthProviders));
    authProvidersById$ = this.store.pipe(select(AccountSelectors.getAuthProvidersById));
    isAuthProvidersLoading$ = this.store.pipe(select(AccountSelectors.isAuthProvidersLoading));
    isAuthProvidersLoaded$ = this.store.pipe(select(AccountSelectors.isAuthProvidersLoaded));
    isAuthProvidersLoadingFailure$ = this.store.pipe(select(AccountSelectors.isAuthProvidersLoadingFailure));

    constructor(private store: Store) {}

    refreshToken() {
        this.store.dispatch(AccountActions.refreshToken());
    }

    fetchUser() {
        this.store.dispatch(AccountActions.fetchUser());
    }

    reset() {
        this.store.dispatch(AccountActions.resetToken());
        this.store.dispatch(AccountActions.resetUser());
    }

    fetchAuthProviders(): void {
        this.store.dispatch(AccountActions.fetchAuthProviders());
    }
}
