<mc-modal-title>
    {{ 'account.Account.SignOutModal.Title.SignOut' | transloco }}
</mc-modal-title>
<mc-modal-body>
    {{ 'account.Account.SignOutModal.Text.SignOut' | transloco }}
</mc-modal-body>
<div mc-modal-footer>
    <button mc-button color="primary"
            [ngClass]="{ 'mc-progress': isSignOutProgress }"
            [disabled]="isSignOutProgress"
            (click)="signOut()">{{ 'account.Account.SignOutModal.Button.SignOut' | transloco }}</button>
    <button mc-button autofocus (click)="closeModal()">{{ 'common.Common.Pseudo.Button.Cancel' | transloco }}</button>
</div>
