import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LocationService } from '@pt-cybsi/core';
import { AccountFacade } from '@pt-cybsi/store/account';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {
    constructor(private accountFacade: AccountFacade, private locationService: LocationService) {}

    canActivate(): Observable<boolean> {
        return this.accountFacade.hasToken$.pipe(
            tap((hasToken: boolean) => {
                if (!hasToken) {
                    this.locationService.redirectToSignInPage();
                }
            })
        );
    }
}
