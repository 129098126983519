import { Component, Input, Output, EventEmitter } from '@angular/core';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import { IEnrichmentRulesFilterParams } from '../../services';

const DEFAULT_FILTERS_PARAMS: IEnrichmentRulesFilterParams = {
    enrichmentType: [],
    entityType: [],
    artifactType: [],
    triggerType: [],
    dataSourceUUID: [],
    triggerDataSourceUUID: [],
    isDisabled: null,
    name: ''
};

@Component({
    selector: 'enrichment-rules-filters-panel',
    templateUrl: './enrichment-rules-filters-panel.component.html',
    styleUrls: ['./enrichment-rules-filters-panel.component.scss']
})
export class EnrichmentRulesFiltersPanelComponent {
    @Input()
    set filtersParams(filterParams: IEnrichmentRulesFilterParams) {
        this.isResetDisabled = isEmpty(filterParams);

        this.filters = {
            ...DEFAULT_FILTERS_PARAMS,
            ...filterParams
        };
    }

    get filtersParams(): IEnrichmentRulesFilterParams {
        return this.filters;
    }

    @Output() filtersChanged = new EventEmitter<IEnrichmentRulesFilterParams>();

    isResetDisabled = true;

    private filters: IEnrichmentRulesFilterParams = DEFAULT_FILTERS_PARAMS;

    applyFilter(value: IEnrichmentRulesFilterParams) {
        this.filters = {
            ...this.filters,
            ...value
        };

        this.filtersChanged.emit(this.getNotDefaultFilters(this.filters));
    }

    resetFilter(fieldName: keyof IEnrichmentRulesFilterParams) {
        this.filters = {
            ...this.filters,
            [fieldName]: DEFAULT_FILTERS_PARAMS[fieldName]
        };

        this.filtersChanged.emit(this.getNotDefaultFilters(this.filters));
    }

    private getNotDefaultFilters(filters: IEnrichmentRulesFilterParams): IEnrichmentRulesFilterParams {
        return omitBy(
            filters,
            (filterValue, filterKey) =>
                filterValue === DEFAULT_FILTERS_PARAMS[filterKey] || (Array.isArray(filterValue) && !filterValue.length)
        );
    }
}
