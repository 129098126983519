import { IServerError } from '@pt-cybsi/api-interfaces';
import { buildFormConfiguration, FormViewModel } from '@pt-cybsi/shared';

import { TSourceTypeFormData } from '../../mappers';
import { SourceTypeFormSavingError } from '../../types';

/**
 * @ViewModel SourceTypeForm
 *
 * @description
 * Model of Angular Reactive Form with additional features for a source type creation and edit form.
 * Contains extended information about a form state, a working mode, and a saving error.
 *
 * For creation FormViewModel need to create a factory `SourceTypeFormBuilder`
 */
export class SourceTypeFormViewModel extends FormViewModel<TSourceTypeFormData, TSourceTypeFormConfig> {
    updateSavingError(error: IServerError): void {
        super.updateSavingError(error);

        if (this.savingError?.code === SourceTypeFormSavingError.DuplicateShortName) {
            this.setError('shortName', 'unique');
        }
    }
}

const FormConfig = {
    id: {
        controlName: 'id',
        errorNames: null
    },
    serverId: {
        controlName: 'serverId',
        errorNames: null
    },
    eTag: {
        controlName: 'eTag',
        errorNames: null
    },
    name: {
        controlName: 'name',
        errorNames: {
            required: 'required',
            length: 'length'
        }
    },
    shortName: {
        controlName: 'shortName',
        errorNames: {
            required: 'required',
            length: 'length',
            format: 'format',
            unique: 'unique'
        }
    },
    confidence: {
        controlName: 'confidence',
        errorNames: {
            required: 'required',
            range: 'range',
            accuracy: 'accuracy'
        }
    }
} as const;

export type TSourceTypeFormConfig = typeof FormConfig;

export const SourceTypeFormConfig = buildFormConfiguration<TSourceTypeFormData, TSourceTypeFormConfig>(FormConfig);
