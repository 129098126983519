import {
    AttributeObservableEntity,
    IAttributeValueForecast,
    IRelationForecast,
    RelationDirection,
    TAttributeValueType
} from '@pt-cybsi/api-interfaces';

import { compareAttributeValues, isCollectionAttribute } from '../helpers';
import { IForecastView, TEntityPreviewData } from '../types';

import { ValuableFactViewMapper } from './valuable-fact-view.mapper';

export interface IAttributeForecastViewMappingMetadata<Attribute extends AttributeObservableEntity> {
    attributeName: Attribute;
    attributeValue: TAttributeValueType<Attribute>;
}

export interface IRelationForecastViewMappingMetadata {
    relationDirection: RelationDirection;
    entitiesPreview: TEntityPreviewData[];
}

export class ForecastViewMapper {
    static fromAttributeValueForecast<Attribute extends AttributeObservableEntity>(
        forecast: IAttributeValueForecast<Attribute>,
        metadata: IAttributeForecastViewMappingMetadata<Attribute>
    ): IForecastView {
        const { attributeName, attributeValue } = metadata;

        const value = isCollectionAttribute(attributeName)
            ? forecast.values.find((attributeValueData) =>
                  compareAttributeValues(attributeName, attributeValueData.value, attributeValue)
              )
            : forecast.values[0];

        const valuableFacts = value?.valuableFacts || [];

        return {
            confidence: value.confidence,
            facts: ValuableFactViewMapper.fromAttributeValuableFacts(valuableFacts, { attributeName })
        };
    }

    static fromRelationForecast(
        forecast: IRelationForecast,
        metadata: IRelationForecastViewMappingMetadata
    ): IForecastView {
        return {
            confidence: forecast.confidence,
            facts: ValuableFactViewMapper.fromRelationValuableFacts(forecast.valuableFacts, {
                ...metadata,
                relation: forecast.relationship
            })
        };
    }
}
