export enum SnapshotUpdateStatus {
    Pending = 'Pending',
    Waiting = 'Waiting',
    Running = 'Running',
    Inactive = 'Inactive'
}

export interface ISnapshotStatus {
    updatedAt?: Date;
    updateStatus?: SnapshotUpdateStatus;
    numberInQueue?: number;
    pendingTime?: Date;
    averageUpdateDuration?: string;
    estimatedUpdateTime?: Date;
}
