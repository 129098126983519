import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    AfterViewInit,
    Output,
    ViewChild,
    TemplateRef
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ColDef, GridOptions, RowClassParams } from 'ag-grid-community';

import { ArtifactType, EnrichmentTriggerType, EnrichmentType, ObservableEntityType } from '@pt-cybsi/api-interfaces';
import { SourcesNavigationService } from '@pt-cybsi/domain-core/sources';
import { CollectionListStyle, LazyDataState, TemplateCellComponent } from '@pt-cybsi/shared';

import { EnrichmentRulesNavigationService } from '../../services';

export type TEnrichmentRulesGridItemId = string;

export interface IEnrichmentRulesGridItem {
    id: TEnrichmentRulesGridItemId;
    name: {
        id: TEnrichmentRulesGridItemId;
        name: string;
        isDisabled: boolean;
    };
    types: {
        artifactTypes?: ArtifactType[];
        entityTypes?: ObservableEntityType[];
    };
    enrichmentType: EnrichmentType;
    triggers: EnrichmentTriggerType[];
    dataSourceIds: string[];
    triggerDataSourceIds: string[];
}

/**
 * @component EnrichmentRulesGrid
 *
 * @param state state of lazy-grid
 * @param enrichmentRules list of enrichment rules for lazy-grid in a `IEnrichmentRulesGridItem[]` format
 * @param loadMore event triggered by lazy-grid, when grid scrollbar reaches end of grid and state is 'Pending'
 *
 * @description Is used for display grid of enrichment rules. For grid used lazy-grid component.
 *
 * @example
 * Live examples can be viewed in Storybook
 *
 * Example of template:
 * ```html
 *  <enrichment-rules-grid
 *      [state]="state"
 *      [enrichmentRules]="enrichmentRules"
 *      (loadMore)="handleLoadMore()"
 *  ></enrichment-rules-grid>
 * ```
 */
@Component({
    selector: 'enrichment-rules-grid',
    templateUrl: './enrichment-rules-grid.component.html',
    styleUrls: ['./enrichment-rules-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnrichmentRulesGridComponent implements AfterViewInit {
    @Input() state: LazyDataState;

    @Input() enrichmentRules: IEnrichmentRulesGridItem[];

    @Output() loadMore = new EventEmitter<void>();

    @ViewChild('ruleCell') ruleCellTemplate: TemplateRef<unknown>;

    @ViewChild('typesCell') typesCellTemplate: TemplateRef<unknown>;

    @ViewChild('enrichmentTypeCell') enrichmentTypeCellTemplate: TemplateRef<unknown>;

    @ViewChild('triggerTypeCell') triggerTypeCellTemplate: TemplateRef<unknown>;

    @ViewChild('triggerSourcesCell') triggerSourcesCellTemplate: TemplateRef<unknown>;

    @ViewChild('sourcesCell') sourcesCellTemplate: TemplateRef<unknown>;

    columns: ColDef[] = [];

    agGridOptions: GridOptions = {
        rowBuffer: 300,
        suppressColumnVirtualisation: true,
        getRowNodeId: (nodeData: IEnrichmentRulesGridItem) => nodeData.id,
        getRowClass: (row: RowClassParams) => {
            if (row?.data?.name?.isDisabled) {
                return 'ag-row-disabled';
            } else if (row?.data?.isLoader) {
                return 'row-loader';
            }
        }
    };

    sourcesCollectionStyle = CollectionListStyle.ONE_LINE;

    constructor(
        private translocoService: TranslocoService,
        private enrichmentRulesNavigationService: EnrichmentRulesNavigationService,
        private sourcesNavigationService: SourcesNavigationService
    ) {}

    ngAfterViewInit() {
        this.columns = this.getGridColumnDefs();
    }

    getEnrichmentRuleLink(ruleId: TEnrichmentRulesGridItemId): string {
        return this.enrichmentRulesNavigationService.getPathOfViewRoute(ruleId);
    }

    getSourceLink(id: string): string {
        return this.sourcesNavigationService.getPathOfSourceViewRoute(id);
    }

    private getGridColumnDefs(): ColDef[] {
        return [
            {
                field: 'name',
                headerName: this.translocoService.translate('enrichment.Enrichment.RulesGrid.Text.Name'),
                flex: 1,
                maxWidth: 450,
                minWidth: 276,
                cellRendererFramework: TemplateCellComponent,
                cellRendererParams: {
                    template: this.ruleCellTemplate
                }
            },
            {
                field: 'enrichmentType',
                headerName: this.translocoService.translate('enrichment.Enrichment.Pseudo.Text.Type'),
                flex: 1,
                minWidth: 172,
                maxWidth: 250,
                cellRendererFramework: TemplateCellComponent,
                cellRendererParams: {
                    template: this.enrichmentTypeCellTemplate
                }
            },
            {
                field: 'types',
                headerName: this.translocoService.translate('enrichment.Enrichment.RulesGrid.Text.Types'),
                flex: 1,
                minWidth: 150,
                cellRendererFramework: TemplateCellComponent,
                cellRendererParams: {
                    template: this.typesCellTemplate
                }
            },
            {
                field: 'triggers',
                headerName: this.translocoService.translate('enrichment.Enrichment.Pseudo.Text.TriggerType'),
                flex: 1,
                minWidth: 150,
                maxWidth: 260,
                cellRendererFramework: TemplateCellComponent,
                cellRendererParams: {
                    template: this.triggerTypeCellTemplate
                }
            },
            {
                field: 'triggerDataSourceIds',
                headerName: this.translocoService.translate('enrichment.Enrichment.Pseudo.Text.TriggerSources'),
                flex: 1,
                minWidth: 250,
                cellRendererFramework: TemplateCellComponent,
                cellRendererParams: {
                    template: this.triggerSourcesCellTemplate
                }
            },
            {
                field: 'dataSourceIds',
                headerName: this.translocoService.translate('common.Common.Pseudo.Text.Sources'),
                flex: 1,
                minWidth: 250,
                cellRendererFramework: TemplateCellComponent,
                cellRendererParams: {
                    template: this.sourcesCellTemplate
                }
            }
        ];
    }
}
