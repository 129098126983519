import { Component } from '@angular/core';
import { McModalRef } from '@ptsecurity/mosaic/modal';
import { Observable, BehaviorSubject } from 'rxjs';

import { IComponentsVersions, SystemInfoService } from '../../services';

@Component({
    selector: 'system-info-modal',
    templateUrl: './system-info-modal.component.html',
    styleUrls: ['./system-info-modal.component.scss']
})
export class SystemInfoModalComponent {
    get versions(): IComponentsVersions {
        return this.systemInfoService.versions;
    }

    get hasReleaseNotes$(): Observable<boolean> {
        return this.systemInfoService.hasReleaseNotes$;
    }

    get hasUserGuide$(): Observable<boolean> {
        return this.systemInfoService.hasUserGuide$;
    }

    get releaseNotesUrl(): string {
        return this.systemInfoService.releaseNotesUrl;
    }

    get userGuideUrl(): string {
        return this.systemInfoService.userGuideUrl;
    }

    get hasDocumentation$(): BehaviorSubject<boolean> {
        return this.systemInfoService.hasDocumentation$;
    }

    get isReloadingVersions$(): BehaviorSubject<boolean> {
        return this.systemInfoService.isReloadingVersions$;
    }

    constructor(private modalRef: McModalRef, private systemInfoService: SystemInfoService) {}

    closeModal() {
        this.modalRef.destroy();
    }

    reloadVersion() {
        this.systemInfoService.reloadVersions();
    }
}
